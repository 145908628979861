import Storage from "./img/Strorages.png";
import Farewell from "./img/Farewell.png";
import Switches1 from "./img/Switches.png";
import Wifi from "./img/Wifi Ass.png";
import Cctv from "./img/Cctv.png";
import Laptop from "./img/Laptop.png";
import Cables1 from "./img/fiber.png";
import Videos from "./img/cables.png";

import Firewall from "./SVG/Firewall";
import Switches from "./SVG/Switches";
import Storages from "./SVG/Storage";
import Cables from "./SVG/Cables";
import Laptops from "./SVG/Laptop";
import Biometric from "./SVG/Cctv";
import Cloud from "../SVGs/MainServiceSVG/ITManaged3";
import Video from "../SVGs/MainServiceSVG/ITPower2";
const ProductData = [
  {
    no: "01",
    id: "Firewall",
    productname: "NEXT GEN FIREWALL",
    productclassname: "firewall",
    img: <Firewall />,
    logoimg: Farewell,
    description: [
      " Securing the digital frontier, a firewall stands as the stalwart guardian in the realm of Information Technology. Acting as a vigilant gatekeeper, it meticulously regulates data traffic based on predefined security rules. Employing packet inspection, access control, and stateful inspection, firewalls thwart cyber threats, ensuring the confidentiality of sensitive information. Essential for compliance with industry standards, these digital sentinels champion the cause of robust cybersecurity. NXT Skills will have your back as we suggest the best-of-the-best firewalls, ensuring you lead in your expertise fields with fortified digital defense.",
    ],
  },
  {
    no: "02",
    id: "Enterprise-Switches",
    productname: "Enterprise Switches",
    productclassname: "enterprise-switches",
    img: <Switches />,
    logoimg: Switches1,
    description:
      "Navigating the digital landscape, enterprise switches emerge as pivotal architects in Information Technology. Acting as intelligent traffic directors, these switches efficiently route data within complex networks, optimizing performance. Employing advanced features such as VLANs and Quality of Service (QoS), enterprise switches enhance network segmentation and prioritize critical data streams. Essential for seamless operations, these digital orchestrators play a key role in network efficiency. With NXT Skills, we recommend cutting-edge enterprise switches, ensuring your networks operate at peak performance, fortified against potential disruptions. Elevate your expertise with our curated selection for robust and efficient network management.",
  },
  {
    no: "03",
    id: "Cloud-managed-wifi-access-points",
    productname: "Cloud managed wifi access points",
    productclassname: "cloud-managed-wifi-access-points",
    img: <Cloud />,
    logoimg: Wifi,
    description:
      "In the dynamic realm of connectivity, cloud-managed WiFi access points emerge as indispensable architects of seamless networking. Serving as beacons of connectivity, these access points are centrally managed through the cloud, ensuring swift adaptability and real-time control. With features like remote monitoring and automatic updates, they guarantee optimal performance and scalability. Essential for modern digital ecosystems, cloud-managed WiFi access points enhance user experiences while simplifying network administration. Partnering with NXT Skills, we recommend cutting-edge solutions, empowering you to lead in your expertise fields with resilient, cloud-driven WiFi connectivity that evolves with your dynamic networking needs. Elevate your network management capabilities with our curated selection for enhanced digital experiences.",
  },
  {
    no: "04",
    id: " Video-conferencing",
    productname: " Video conferencing",
    productclassname: " video-conferencing",
    img: <Video />,
    logoimg: Videos,
    description:
      "In the dynamic landscape of modern communication, video conferencing emerges as a transformative force, serving as a nexus for collaborative endeavors. Functioning as virtual meeting spaces, these platforms dissolve physical boundaries, enabling face-to-face interactions from disparate locations. With features such as high-fidelity video and clear audio, video conferencing propels real-time collaboration, elevating team synergy and overall productivity. Indispensable for contemporary work environments, these platforms facilitate seamless remote meetings, presentations, and collaborative sessions. Partnering with NXT Skills, we recommend state-of-the-art video conferencing solutions, empowering you to excel in your field with immersive and dependable communication tools. Enhance your virtual meetings with our carefully curated selection for an elevated and fluid collaborative experience.",
  },
  {
    no: "05",
    id: "Storages",
    productname: "Storages - NAS, SAN, and cloud storage",
    productclassname: "storages",
    img: <Storages />,
    logoimg: Storage,
    description:
      "In the intricate realm of data management, storage solutions such as NAS (Network Attached Storage), SAN (Storage Area Network), and cloud storage play pivotal roles as custodians of digital assets. NAS serves as a user-friendly repository, seamlessly integrating into networks for efficient file sharing. SAN, with its dedicated network, optimizes high-performance data access for critical applications. Cloud storage, a cornerstone in modern data architectures, provides scalable and accessible storage resources. Essential for diverse storage needs, these solutions ensure data integrity and accessibility. Partnering with NXT Skills, we recommend cutting-edge storage solutions tailored to your requirements, empowering you to navigate the evolving data landscape with precision and reliability. Elevate your data management with our curated selection for secure, scalable, and seamlessly integrated storage solutions.",
  },
  {
    no: "06",
    id: "Fiber-and-cables",
    productname: "Fiber and cables",
    productclassname: "fiber-and-cables",
    img: <Cables />,
    logoimg: Cables1,
    description:
      "In the intricate realm of digital interconnection, fibers and cables emerge as the backbone, intricately weaving the fabric of modern information exchange. Fiber optics, utilizing slender strands of glass for rapid data transmission, forms the bedrock of high-speed networks, guaranteeing swift and dependable communication channels. Meanwhile, traditional cables, known for their resilience and versatility, establish tangible links within local networks, creating pathways for seamless data exchange. As indispensable conduits in the digital landscape, these mediums ensure fluid connectivity, enabling the essential flow of information crucial for contemporary operations. Teaming up with NXT Skills, we recommend cutting-edge fiber and cable solutions, tailoring connectivity to your specific requirements, and ensuring a robust and efficient digital infrastructure. Elevate your connectivity experience with our meticulously curated selection for optimal performance and reliability in the ever-evolving realm of data transmission.",
  },
  {
    no: "07",
    id: "Laptop-desktop-and-IT-Peripherals",
    productname: "Laptop, desktop, and IT Peripherals",
    productclassname: "laptop-desktop",
    img: <Laptops />,
    logoimg: Laptop,
    description:
      "In the ever-evolving domain of computing, laptops and desktops emerge as the stalwart pillars, driving the heartbeat of personal and professional digital landscapes. Laptops, epitomizing portability and adaptability, serve as nimble powerhouses for on-the-move productivity, encapsulating a sleek fusion of performance and convenience. Meanwhile, desktops assert themselves as steadfast engines, delivering robust computing solutions tailored for resource-intensive applications, embodying a blend of reliability and customizable prowess. Complementing these computing cornerstones, IT peripherals, including printers, keyboards, and mice, play indispensable roles, enriching user interfaces and facilitating tangible, output-driven interactions. Collaborating with NXT Skills, we offer a one-stop solution for all hardware upgrades and IT peripherals, providing the best-of-the-best solutions meticulously curated to meet your distinct needs, ensuring an elevated and seamless computing experience in the ever-changing landscape of technology.",
  },
  {
    no: "08",
    id: "CCTV-And-Biometrics",
    productname: "CCTV And Biometrics",
    productclassname: "cctv-and-biometrics",
    img: <Biometric />,
    logoimg: Cctv,
    description:
      "In the Dynamic Real of Security Solutions, CCTV and Biometrics Stand as the Cornerstones, Safeguarding the Pulse of Personal and Professional Security Landscapes. CCTV Systems, Exemplifying Vigilance and Surveillance, Serve as Watchful Guardians for Comprehensive Security, Offering a Harmonious Integration of Precision and Accessibility. Meanwhile, Biometric Solutions Assert Themselves as Unyielding Protectors, Delivering Robust Authentication Tailored for Secure Environments, Embodying a Fusion of Reliability and Customizable Security Measures. Complementing These Security Pillars, Access Control Systems, Including Card Readers and Security Software, Play Indispensable Roles, Enhancing Security Protocols and Facilitating Controlled Access Interactions.    ",
  },
];
export default ProductData;

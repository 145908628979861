import "./App.css";
import NavBar from "./Components/NavBar";
import "./Pages/Pages.css";
import { Routes, Route, Link } from "react-router-dom";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import Services from "./Pages/Services";
import Blog from "./Pages/Blog";
import TopNav from "./Components/TopNav";
import Footer from "./Components/Footer";
import ScrollToTop from "./Components/ScrollToTop";
import ServiceSingle from "./ServicePage/SingleService";
import SingleProduct from "./Products/SingleProduct";
import Gallery from "./Pages/Gallery";
import Products from "./Pages/Products";
import Brands from "./Pages/Brands";
import Careers from "./Pages/Careers";

function App() {
  return (
    <>
      <div className="main-app-container">
        <ScrollToTop />
        <TopNav />
        <NavBar />
        <Routes>
          <Route exact path="/" Component={Home} />
          <Route exact path="/About-us" Component={AboutUs} />
          <Route exact path="/Contact-us" Component={ContactUs} />
          <Route exact path="/Blog" Component={Blog} />
          <Route exact path="/Services" Component={Services} />
          <Route exact path="/Careers" Component={Careers} />
          <Route exact path="/Gallery" Component={Gallery} />
          <Route exact path="/Gallery" Component={Gallery} />
          <Route exact path="/Products" Component={Products} />
          <Route exact path="/Brands" Component={Brands} />
          <Route exact path="/Products/:productId" Component={SingleProduct} />
          <Route exact path="/Services/:serviceId" Component={ServiceSingle} />

          <Route path="*" Component={Home} />
        </Routes>
        <Footer />
        <Link
          to="https://wa.me/+917892902882"
          target="_blank"
          className="fixed-whatsapp-icon"
        >
          <div className="fixed-whatsapp-icon-sub">
            <i className="fa-brands fa-square-whatsapp fa-2xl"></i>
          </div>
        </Link>
      </div>
    </>
  );
}

export default App;

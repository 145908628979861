import * as React from "react";
import "./MainServicesSvg.css";
const SvgComponent = () => (
  <svg
    className="animated"
    id="freepik_stories-security"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 750 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlnssvgjs="http://svgjs.com/svgjs"
  >
    <g
      id="freepik--background-complete--inject-2"
      className="animable animator-active"
      style={{
        transformOrigin: "375.6px 218.44px",
      }}
    >
      <path
        d="M244.2,148.3H117.56V61.67H244.2Zm-125.64-1H243.2V62.67H118.56Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "180.88px 104.985px",
        }}
        id="elz71ula2bmbn"
        className="animable"
      />
      <path
        d="M127.46,67.25a2.52,2.52,0,1,1-2.52-2.52A2.52,2.52,0,0,1,127.46,67.25Z"
        style={{
          fill: "rgb(166, 166, 166)",
          transformOrigin: "124.94px 67.25px",
        }}
        id="el5v2vczzof9t"
        className="animable"
      />
      <path
        d="M137.75,67.25a2.52,2.52,0,1,1-2.52-2.52A2.52,2.52,0,0,1,137.75,67.25Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "135.23px 67.25px",
        }}
        id="el7lkngx6dst3"
        className="animable"
      />
      <path
        d="M148.05,67.25a2.53,2.53,0,1,1-2.52-2.52A2.52,2.52,0,0,1,148.05,67.25Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "145.52px 67.26px",
        }}
        id="el97iwh1tx6f"
        className="animable"
      />
      <rect
        x={118.06}
        y={71.68}
        width={125.64}
        height={1}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "180.88px 72.18px",
        }}
        id="el32jn9jaf8vq"
        className="animable"
      />
      <polygon
        points="658.51 375.21 657.51 375.21 657.51 249.39 684.24 212.91 684.24 144.73 685.24 144.73 685.24 213.23 658.51 249.72 658.51 375.21"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "671.375px 259.97px",
        }}
        id="el35pjgq3siuf"
        className="animable"
      />
      <path
        d="M684.74,145.27a2.88,2.88,0,1,1,2.88-2.87A2.87,2.87,0,0,1,684.74,145.27Zm0-4.75a1.88,1.88,0,1,0,1.88,1.88A1.88,1.88,0,0,0,684.74,140.52Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "684.74px 142.39px",
        }}
        id="elyofg20anv4p"
        className="animable"
      />
      <polygon
        points="665.4 375.21 664.4 375.21 664.4 253.8 664.49 253.66 691.13 217.31 691.13 169.73 692.13 169.73 692.13 217.64 665.4 254.12 665.4 375.21"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "678.265px 272.47px",
        }}
        id="elerrymcmqg8"
        className="animable"
      />
      <path
        d="M691.63,170.27a2.88,2.88,0,1,1,2.87-2.87A2.88,2.88,0,0,1,691.63,170.27Zm0-4.75a1.88,1.88,0,1,0,1.87,1.88A1.88,1.88,0,0,0,691.63,165.52Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "691.62px 167.39px",
        }}
        id="el24iz6ako8vn"
        className="animable"
      />
      <rect
        x={650.88}
        y={208.8}
        width={1}
        height={166.41}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "651.38px 292.005px",
        }}
        id="elng3seigo0x"
        className="animable"
      />
      <path
        d="M651.38,209.3a2.88,2.88,0,1,1,2.87-2.87A2.88,2.88,0,0,1,651.38,209.3Zm0-4.75a1.88,1.88,0,1,0,1.87,1.88A1.88,1.88,0,0,0,651.38,204.55Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "651.37px 206.42px",
        }}
        id="elx4xdscbmwq7"
        className="animable"
      />
      <polygon
        points="645.49 375.21 644.49 375.21 644.49 290.76 626.35 256.53 626.35 195.05 627.35 195.05 627.35 256.29 645.49 290.52 645.49 375.21"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "635.92px 285.13px",
        }}
        id="elx98srstzep"
        className="animable"
      />
      <path
        d="M626.84,195.55a2.88,2.88,0,1,1,2.88-2.88A2.88,2.88,0,0,1,626.84,195.55Zm0-4.75a1.88,1.88,0,1,0,1.88,1.87A1.87,1.87,0,0,0,626.84,190.8Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "626.84px 192.67px",
        }}
        id="elae1tmdjmf8"
        className="animable"
      />
      <polygon
        points="639.14 375.21 638.14 375.21 638.14 294.43 616.75 256.59 616.75 216.04 617.75 216.04 617.75 256.32 639.14 294.17 639.14 375.21"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "627.945px 295.625px",
        }}
        id="el0falvxaqz7e5"
        className="animable"
      />
      <path
        d="M617.25,216.54a2.88,2.88,0,1,1,2.87-2.87A2.88,2.88,0,0,1,617.25,216.54Zm0-4.75a1.88,1.88,0,1,0,1.87,1.88A1.88,1.88,0,0,0,617.25,211.79Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "617.24px 213.66px",
        }}
        id="eliqxuqpdjy4"
        className="animable"
      />
      <polygon
        points="96.1 375.21 95.1 375.21 95.1 309.71 68.37 273.23 68.37 234.73 69.37 234.73 69.37 272.9 96.1 309.39 96.1 375.21"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "82.235px 304.97px",
        }}
        id="elv18arb576cm"
        className="animable"
      />
      <path
        d="M68.87,235.27a2.88,2.88,0,1,1,2.87-2.87A2.87,2.87,0,0,1,68.87,235.27Zm0-4.75a1.88,1.88,0,1,0,1.87,1.88A1.88,1.88,0,0,0,68.87,230.52Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "68.86px 232.39px",
        }}
        id="el3ou5tqaw64b"
        className="animable"
      />
      <polygon
        points="89.21 375.21 88.21 375.21 88.21 314.12 61.48 277.64 61.48 259.73 62.48 259.73 62.48 277.31 89.21 313.8 89.21 375.21"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "75.345px 317.47px",
        }}
        id="elgxul6m7ayqd"
        className="animable"
      />
      <path
        d="M62,260.27a2.88,2.88,0,1,1,2.88-2.87A2.88,2.88,0,0,1,62,260.27Zm0-4.75a1.88,1.88,0,1,0,1.88,1.88A1.88,1.88,0,0,0,62,255.52Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "62px 257.39px",
        }}
        id="ell7mytluv5r"
        className="animable"
      />
      <rect
        x={101.73}
        y={268.8}
        width={1}
        height={106.41}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "102.23px 322.005px",
        }}
        id="elonw842cuhrf"
        className="animable"
      />
      <path
        d="M102.23,269.3a2.88,2.88,0,1,1,2.88-2.87A2.88,2.88,0,0,1,102.23,269.3Zm0-4.75a1.88,1.88,0,1,0,1.88,1.88A1.88,1.88,0,0,0,102.23,264.55Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "102.23px 266.42px",
        }}
        id="el9ejd5aeb5ha"
        className="animable"
      />
      <polygon
        points="109.12 375.21 108.12 375.21 108.12 320.52 108.17 320.4 126.26 286.29 126.26 225.05 127.26 225.05 127.26 286.53 127.21 286.64 109.12 320.76 109.12 375.21"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "117.69px 300.13px",
        }}
        id="elqsm99wauz5t"
        className="animable"
      />
      <path
        d="M126.76,225.55a2.88,2.88,0,1,1,2.88-2.88A2.88,2.88,0,0,1,126.76,225.55Zm0-4.75a1.88,1.88,0,1,0,1.88,1.87A1.87,1.87,0,0,0,126.76,220.8Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "126.76px 222.67px",
        }}
        id="elcn8pki5cquo"
        className="animable"
      />
      <polygon
        points="115.47 375.21 114.47 375.21 114.47 324.17 114.53 324.05 135.86 286.32 135.86 246.04 136.86 246.04 136.86 286.59 136.79 286.7 115.47 324.43 115.47 375.21"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "125.665px 310.625px",
        }}
        id="elczbxuw8k95p"
        className="animable"
      />
      <path
        d="M136.36,246.54a2.88,2.88,0,1,1,2.87-2.87A2.88,2.88,0,0,1,136.36,246.54Zm0-4.75a1.88,1.88,0,1,0,1.87,1.88A1.88,1.88,0,0,0,136.36,241.79Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "136.35px 243.66px",
        }}
        id="elo9ai8wy6jha"
        className="animable"
      />
      <path
        d="M686,127.75l7-11.25-3.44-2.13a3.13,3.13,0,0,1-1.46-3q.08-1,.06-2a3.13,3.13,0,0,1,1.64-2.85l3.57-1.92L687,93l-3.57,1.92a3.15,3.15,0,0,1-3.29-.22c-.54-.37-1.1-.72-1.68-1a3.13,3.13,0,0,1-1.66-2.85l.13-4.06-13.23-.41-.13,4.07a3.14,3.14,0,0,1-1.84,2.74c-.3.14-.59.28-.87.44s-.57.31-.85.48a3.14,3.14,0,0,1-3.29,0l-3.47-2.15-7,11.25,3.45,2.15a3.12,3.12,0,0,1,1.46,3,18.52,18.52,0,0,0-.06,2,3.16,3.16,0,0,1-1.65,2.86L645.94,115l6.26,11.67,3.57-1.91a3.15,3.15,0,0,1,3.29.21,18.07,18.07,0,0,0,1.68,1,3.13,3.13,0,0,1,1.66,2.85l-.12,4.06,13.23.41.13-4.06a3.12,3.12,0,0,1,1.84-2.73l.87-.44.85-.49a3.16,3.16,0,0,1,3.3,0Zm-11.2-8.35a10.85,10.85,0,1,1,4.44-14.69A10.85,10.85,0,0,1,674.75,119.4Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "669.655px 109.835px",
        }}
        id="el1qws5lnpuyj"
        className="animable"
      />
      <path
        d="M77.18,227.76l8-4.64-1.42-2.46a2.19,2.19,0,0,1,.08-2.31q.38-.58.69-1.2a2.21,2.21,0,0,1,2-1.22h2.84v-9.28H86.53a2.21,2.21,0,0,1-2-1.22q-.31-.62-.69-1.2a2.19,2.19,0,0,1-.08-2.31l1.42-2.46-8-4.64-1.42,2.47a2.17,2.17,0,0,1-2,1.08H72.35a2.19,2.19,0,0,1-2-1.07l-1.43-2.48-8,4.64,1.42,2.47a2.19,2.19,0,0,1-.08,2.31,12.71,12.71,0,0,0-.69,1.19,2.21,2.21,0,0,1-2,1.22H56.7v9.28h2.84a2.21,2.21,0,0,1,2,1.22,12.71,12.71,0,0,0,.69,1.19,2.21,2.21,0,0,1,.08,2.32l-1.42,2.46,8,4.64,1.43-2.47a2.2,2.2,0,0,1,2-1.08c.22,0,.45,0,.68,0s.46,0,.69,0a2.21,2.21,0,0,1,2,1.08ZM73,218.89a7.6,7.6,0,1,1,7.61-7.6A7.59,7.59,0,0,1,73,218.89Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "73.035px 211.29px",
        }}
        id="el36036lce4v6"
        className="animable"
      />
      <path
        d="M645.08,135.89l-4.63-8L638,129.28a2.21,2.21,0,0,1-2.32-.08,11.7,11.7,0,0,0-1.2-.69,2.21,2.21,0,0,1-1.22-2v-2.84H624v2.84a2.21,2.21,0,0,1-1.22,2,13,13,0,0,0-1.21.69,2.19,2.19,0,0,1-2.31.08l-2.46-1.42-4.63,8,2.47,1.42a2.23,2.23,0,0,1,1.08,2.05c0,.23,0,.46,0,.68s0,.46,0,.69a2.19,2.19,0,0,1-1.08,2l-2.47,1.43,4.63,8,2.47-1.42a2.19,2.19,0,0,1,2.31.08q.59.38,1.2.69a2.21,2.21,0,0,1,1.22,2v2.84h9.27v-2.84a2.21,2.21,0,0,1,1.22-2q.62-.32,1.2-.69a2.19,2.19,0,0,1,2.31-.08l2.47,1.42,4.63-8-2.47-1.42a2.2,2.2,0,0,1-1.07-2c0-.23,0-.46,0-.69s0-.45,0-.68a2.22,2.22,0,0,1,1.08-2.05ZM636.21,140a7.6,7.6,0,1,1-7.59-7.6A7.59,7.59,0,0,1,636.21,140Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "628.635px 139.995px",
        }}
        id="elosrvxdw2o5"
        className="animable"
      />
    </g>
    <g
      id="freepik--Plant--inject-2"
      className="animable"
      style={{
        transformOrigin: "102.232px 301.868px",
      }}
    >
      <path
        d="M98.5,299.07c-6.39-13.13-17.57-19.87-30.17-16.14s-14.55,21.29-5.15,25.73S82,300.14,87,297.48,98.5,299.07,98.5,299.07Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "77.8953px 295.744px",
        }}
        id="el5owa7qgs2se"
        className="animable"
      />
      <path
        d="M104.68,334c0-1.58-.09-3.17-.15-4.75q-.16-4.74-.34-9.48C104,314,103.36,308.34,101,303a26,26,0,0,0-10.13-11.68A20.92,20.92,0,0,0,85.5,289a12.4,12.4,0,0,0-6.22-3.42,25.35,25.35,0,0,0-8.86-.22.09.09,0,0,0,0,.17,21.17,21.17,0,0,1,8.38.59,17.82,17.82,0,0,1,3.38,1.32,15.7,15.7,0,0,1,2,1.24,17.11,17.11,0,0,0-10.37.95,8.29,8.29,0,0,0-2.1-.18,12.57,12.57,0,0,0-2.73.39,20.6,20.6,0,0,0-5.18,2.27c-.09.05,0,.19.09.14a19.54,19.54,0,0,1,5.05-1.91,17.72,17.72,0,0,1,2.6-.38A14,14,0,0,1,73,290a16.74,16.74,0,0,0-8.14,8.12c0,.1.1.18.14.08a15.33,15.33,0,0,1,10.38-8.32c.28-.07.56-.11.85-.16A11,11,0,0,0,76,291c-.08.64-.19,1.27-.32,1.9a32.1,32.1,0,0,1-1,3.85,30.92,30.92,0,0,1-1.53,3.72,17.6,17.6,0,0,1-2.13,3.6c-.08.09.07.2.13.1.76-1.19,1.64-2.28,2.33-3.51A26,26,0,0,0,75.21,297a24.45,24.45,0,0,0,1.12-3.93c.14-.71.25-1.42.33-2.14a4.74,4.74,0,0,0,.08-1.3,17.23,17.23,0,0,1,4.48-.1c-.56,3.14-1.16,6.4-2.92,9.12-.06.09.1.18.15.08a36.92,36.92,0,0,0,2.16-4.48,16.63,16.63,0,0,0,1.08-4.66,20.5,20.5,0,0,1,7.4,2.48,24.45,24.45,0,0,1,9.85,10.77,40.76,40.76,0,0,1,3.65,15.44c.42,6.72.51,13.47,1.18,20.16,0,.44.73.59.82.09A24,24,0,0,0,104.68,334Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "84.2548px 311.958px",
        }}
        id="elmr49rvfglkc"
        className="animable"
      />
      <path
        d="M89.92,290.28a7.46,7.46,0,0,0-1.46-1.34c-.52-.43-1.06-.85-1.62-1.23A11.89,11.89,0,0,0,83.21,286c-.1,0-.14.14,0,.16A9.45,9.45,0,0,1,86.75,288c.54.39,1,.81,1.55,1.25a8.7,8.7,0,0,0,1.47,1.24A.12.12,0,0,0,89.92,290.28Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "86.5465px 288.257px",
        }}
        id="el8483agu9p1h"
        className="animable"
      />
      <path
        d="M69.53,295a37.93,37.93,0,0,0-2.93,3.88,22.73,22.73,0,0,0-2.18,4.32c0,.05,0,.07.07,0a51.07,51.07,0,0,1,5.16-8.11C69.72,295.08,69.59,295,69.53,295Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "67.0451px 299.123px",
        }}
        id="eli4gdxj7jm6d"
        className="animable"
      />
      <path
        d="M83.27,293.74a9.48,9.48,0,0,1-.72,2.38,15.72,15.72,0,0,1-1.22,2.1s0,.09.08.06a8.5,8.5,0,0,0,2-4.5C83.45,293.68,83.29,293.64,83.27,293.74Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "82.3732px 295.984px",
        }}
        id="elmv0a8hhxfv"
        className="animable"
      />
      <path
        d="M103.79,299.4s9-11.37,26.51-7.34c16.59,3.83,24.25,34.26,7.48,37.9s-13.81-24.52-19.92-29.3C113.37,297.16,106.92,297.91,103.79,299.4Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "125.482px 310.74px",
        }}
        id="elmbf1xcvg65"
        className="animable"
      />
      <path
        d="M143.78,308.68a33,33,0,0,0-3.6-2.76,15.25,15.25,0,0,0-3.83-1.91,23,23,0,0,0-2.28-2.93,22.51,22.51,0,0,0-5.27-4.2l.52,0a25.63,25.63,0,0,1,2.77,0,15.43,15.43,0,0,1,5.39,1.34c.1,0,.17-.11.07-.15a24.71,24.71,0,0,0-5.38-1.62,15.57,15.57,0,0,0-2.73-.24c-.49,0-1,0-1.46.09l-.28,0a23.6,23.6,0,0,0-5-1.85l.49-.13a25,25,0,0,1,2.59-.43A20.73,20.73,0,0,1,131,294c.11,0,.14-.15,0-.16a33.27,33.27,0,0,0-5.28-.21,21.22,21.22,0,0,0-4.39.57,24.19,24.19,0,0,0-3.18-.37c-6.09-.25-12.73,2-16.48,7s-4.48,11.69-5.14,17.74a134.46,134.46,0,0,0-.67,25.37c0,.5.74.41.76-.06.23-7.18.48-14.35,1.17-21.5.31-3.32.67-6.64,1.22-9.92a29.36,29.36,0,0,1,2.4-8.16,14.86,14.86,0,0,1,5.9-6.34,20,20,0,0,1,8.34-2.57,22.49,22.49,0,0,1,7.84.74,5,5,0,0,0,0,1.54,9.5,9.5,0,0,0,.38,1.91,10.51,10.51,0,0,0,.73,1.69c.33.6.73,1.14,1.1,1.71,0,.09.2,0,.13-.1a7.87,7.87,0,0,1-1.35-3.44,13.11,13.11,0,0,1-.21-1.78c0-.45,0-.9,0-1.35a23.14,23.14,0,0,1,7.63,4c.42.34.83.7,1.23,1.07a8.16,8.16,0,0,0-1.48,1.81A17.5,17.5,0,0,0,130,306a23.58,23.58,0,0,0-1.62,6.07.09.09,0,0,0,.17,0,17.62,17.62,0,0,1,2-5.75,24.53,24.53,0,0,1,1.55-2.56c.3-.42.6-.84.92-1.24.17-.21.36-.43.52-.67a24.21,24.21,0,0,1,4.95,7h0a11.84,11.84,0,0,0-2.05,2.94,26.48,26.48,0,0,0-1.63,3.5,58,58,0,0,0-2.09,7.11.09.09,0,0,0,.17,0,30.29,30.29,0,0,1,2.47-7c.52-1.09,1.08-2.17,1.66-3.23a31.93,31.93,0,0,0,1.64-3.05,22.43,22.43,0,0,1,2,8.08c0,.11.17.1.17,0a28.23,28.23,0,0,0-3.92-12.41c1.06.45,2.13.89,3.16,1.44a18.1,18.1,0,0,1,3.63,2.55C143.75,308.88,143.86,308.75,143.78,308.68Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "119.678px 318.94px",
        }}
        id="elxf9vtoaitw"
        className="animable"
      />
      <path
        d="M131,309.32c-.53,1.73-1.19,3.4-1.66,5.14a19.85,19.85,0,0,0-.77,5.13,0,0,0,0,0,.08,0,50,50,0,0,1,2.5-10.23C131.18,309.26,131,309.21,131,309.32Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "129.862px 314.424px",
        }}
        id="elh5u3prp7opr"
        className="animable"
      />
      <path
        d="M142,305.05a9.74,9.74,0,0,0-2.32-1.66,16.92,16.92,0,0,0-2.67-.94c-.1,0-.14.14,0,.16a10.48,10.48,0,0,1,2.57,1.06,18.74,18.74,0,0,1,2.38,1.46C142,305.17,142.09,305.09,142,305.05Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "139.479px 303.795px",
        }}
        id="el75l7nkdz66m"
        className="animable"
      />
      <path
        d="M112.39,284.21s1.67-20.42,16.85-27c12.68-5.53,19.94,8.72,11,17.44C133,281.67,121.34,278.48,112.39,284.21Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "128.298px 270.09px",
        }}
        id="el82my4dp6ju8"
        className="animable"
      />
      <path
        d="M137.41,261.12a64.06,64.06,0,0,0-6.75,3.2,49.72,49.72,0,0,0-5.25,3.48c.25-.51.47-1.06.73-1.56a26.13,26.13,0,0,1,1.29-2.31,21.39,21.39,0,0,1,3.34-4.23c.08-.08,0-.19-.12-.12a34.31,34.31,0,0,0-3.52,4.1,22.87,22.87,0,0,0-1.44,2.31,8.91,8.91,0,0,0-1,2.35c-.06.05-.12.08-.17.13a50.1,50.1,0,0,0-7.25,7.09c0-.2.08-.4.13-.58.29-1,.6-2,.92-3a25.18,25.18,0,0,1,2.75-6c.06-.09-.09-.17-.15-.08a47.4,47.4,0,0,0-3.18,5.81,23,23,0,0,0-1,3,13.67,13.67,0,0,0-.49,2.05c-.66.84-1.31,1.69-1.93,2.56-6.51,9.28-9.79,20.5-10.57,31.73a96.64,96.64,0,0,0-.17,10.07,62,62,0,0,0,.89,10.09c0,.21.37.15.37-.06-.08-2.95-.38-5.89-.44-8.84s0-5.92.28-8.87a82.35,82.35,0,0,1,3.32-17.38,62.18,62.18,0,0,1,7.72-16.33c.64-.13,1.27-.3,1.9-.47s1.52-.39,2.27-.6a21.87,21.87,0,0,0,4.23-1.55.08.08,0,1,0-.08-.14,13.89,13.89,0,0,1-4.3,1.48c-1.18.24-2.42.39-3.61.66,1.1-1.6,2.27-3.15,3.53-4.64a26.42,26.42,0,0,0,8.64-.64,35.91,35.91,0,0,0,4.42-1.38c1.52-.58,3-1.34,4.46-2,.12-.05,0-.22-.06-.16a25.3,25.3,0,0,1-8.76,2.87,55,55,0,0,1-8.19.68c1.08-1.24,2.19-2.45,3.38-3.58,4-3.8,8.72-7.29,14-9.11C137.56,261.25,137.51,261.08,137.41,261.12Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "120.536px 295.449px",
        }}
        id="elvoz4s3ewygr"
        className="animable"
      />
      <path
        d="M125.05,261.19a22.92,22.92,0,0,0-1.86,2.72,15.23,15.23,0,0,0-1.34,3.17,0,0,0,0,0,.06,0c.51-1,.94-2,1.45-3a15.62,15.62,0,0,1,1.81-2.76C125.24,261.22,125.12,261.1,125.05,261.19Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "123.521px 264.119px",
        }}
        id="el6osokmfl7sl"
        className="animable"
      />
      <path
        d="M139.17,268a15.72,15.72,0,0,1-3.11,1.3,31.51,31.51,0,0,1-3.17.85,0,0,0,0,0,0,.09,15.87,15.87,0,0,0,3.28-.72,28.35,28.35,0,0,0,3.08-1.38C139.35,268.08,139.26,267.93,139.17,268Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "136.092px 269.111px",
        }}
        id="elqupccgm94u"
        className="animable"
      />
      <path
        d="M100.58,280.54c-.09.88-2.29-7.42-15.13-14.78-11.66-6.69-25.58-24.1-14.15-35.09C80.53,221.8,104.84,241.14,100.58,280.54Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "84.058px 254.575px",
        }}
        id="elohap2eassw"
        className="animable"
      />
      <path
        d="M109.42,328.11a227.79,227.79,0,0,0-2-25.82,116,116,0,0,0-5.42-23.1c-1-2.7-2-5.36-3.23-8a19.46,19.46,0,0,0,0-3.06c-.07-1.31-.19-2.61-.31-3.91s-.25-2.61-.4-3.91-.44-2.6-.49-3.9c0-.11-.19-.09-.16,0a28.52,28.52,0,0,1,.46,4.13c.1,1.26.17,2.52.21,3.79s0,2.52,0,3.79c0,.45,0,.94,0,1.42-1.21-2.55-2.5-5.06-3.9-7.51.87-5.32-.26-10.88-1.35-16.09,0-.1-.18-.05-.16.05a45.63,45.63,0,0,1,.85,8.25c0,2.19-.06,4.37-.06,6.56-.31-.53-.62-1.05-.94-1.57q-2.49-4.08-5.33-8a12.36,12.36,0,0,0-.12-3.79,30.6,30.6,0,0,0-.93-4.14,42.34,42.34,0,0,0-1.42-4c-.56-1.35-1.3-2.63-1.84-4,0-.11-.21,0-.15.07a19.27,19.27,0,0,1,1.61,4,37.19,37.19,0,0,1,1.05,4c.27,1.36.49,2.71.68,4.08.13.9.21,1.84.36,2.75a97.55,97.55,0,0,0-9-10.46c-.07-.08-.18,0-.11.12a118.69,118.69,0,0,1,13,17.75c.14.24.28.48.41.72-.7-.44-1.51-.8-2.18-1.17-1.24-.69-2.49-1.37-3.74-2l-3.64-2c-.62-.35-1.24-.71-1.87-1A15,15,0,0,1,77.08,251c-.09-.07-.2.08-.1.14,1.24.68,2.33,1.69,3.53,2.45s2.42,1.5,3.66,2.19c2.28,1.28,4.76,2.19,7,3.51,1.56,2.73,3,5.53,4.32,8.38-1.36-.88-2.76-1.7-4.17-2.5-.91-.51-1.82-1-2.74-1.49A13.34,13.34,0,0,1,86,262.1c-.08-.07-.19,0-.12.12a24.15,24.15,0,0,0,4.94,3.44c1.65,1,3.31,1.89,5,2.73q2.06,4.51,3.71,9.19c5.41,15.3,7.39,31.54,8.34,47.68.29,4.81.43,9.61.49,14.43s-.19,9.62-.18,14.42c0,.15.23.18.25,0,.58-4.26.68-8.6.87-12.9S109.52,332.48,109.42,328.11Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "93.2082px 294.744px",
        }}
        id="elfa2td17bbcc"
        className="animable"
      />
      <path
        d="M86.09,258.84a63.79,63.79,0,0,1-6.82-3.53,73,73,0,0,1-6.48-4.11s-.07,0,0,.06A55.88,55.88,0,0,0,79,255.61,61.4,61.4,0,0,0,86,259C86.11,259,86.19,258.88,86.09,258.84Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "79.4473px 255.1px",
        }}
        id="elgx4fpvjc92v"
        className="animable"
      />
      <path
        d="M89.81,241.54c-.19-.75-.42-1.48-.67-2.21a.08.08,0,0,0,0-.08c-.16-.42-.33-.83-.5-1.24l-.06-.15c0-.08-.07-.15-.1-.23s-.05,0,0,0,.08.28.13.43a2,2,0,0,0,.06.23A60.25,60.25,0,0,1,90.57,247c0,.09.15.09.15,0A29,29,0,0,0,89.81,241.54Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "89.5848px 242.331px",
        }}
        id="el8vhfmt26zll"
        className="animable"
      />
      <polygon
        points="122.99 331.21 122.07 342.07 119.27 375.19 89.22 375.19 86.42 342.07 85.5 331.21 122.99 331.21"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "104.245px 353.2px",
        }}
        id="elrfdx704l02k"
        className="animable"
      />
      <polygon
        points="122.99 331.21 122.07 342.07 86.42 342.07 85.5 331.21 122.99 331.21"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "104.245px 336.64px",
        }}
        id="elae3l990ny1"
        className="animable"
      />
      <rect
        x={82.56}
        y={326.86}
        width={43.38}
        height={10.42}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "104.25px 332.07px",
        }}
        id="el4okgj2tkwbm"
        className="animable"
      />
    </g>
    <g
      id="freepik--Floor--inject-2"
      className="animable"
      style={{
        transformOrigin: "375px 375.19px",
      }}
    >
      <polygon
        points="50.72 375.19 131.79 374.95 212.86 374.86 375 374.69 537.14 374.86 618.21 374.95 699.28 375.19 618.21 375.44 537.14 375.52 375 375.69 212.86 375.52 131.79 375.43 50.72 375.19"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "375px 375.19px",
        }}
        id="elmim394v6v1k"
        className="animable"
      />
    </g>
    <g
      id="freepik--Device--inject-2"
      className="animable"
      style={{
        transformOrigin: "374.16px 264.46px",
      }}
    >
      <rect
        x={138.36}
        y={84.08}
        width={471.59}
        height={315.46}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "374.155px 241.81px",
        }}
        id="el5yvif8ajpsi"
        className="animable"
      />
      <rect
        x={141.79}
        y={87.93}
        width={464.73}
        height={307.76}
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "374.155px 241.81px",
        }}
        id="el9wqeen36m78"
        className="animable"
      />
      <rect
        x={76.94}
        y={423.19}
        width={594.43}
        height={21.65}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "374.155px 434.015px",
        }}
        id="el8e49vtp929r"
        className="animable"
      />
      <polygon
        points="76.94 423.19 138.37 399.54 609.95 399.54 671.38 423.19 76.94 423.19"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "374.16px 411.365px",
        }}
        id="el65pny8wr89o"
        className="animable"
      />
      <polygon
        points="143.85 414.8 183.49 399.54 564.83 399.54 604.47 414.8 143.85 414.8"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "374.16px 407.17px",
        }}
        id="el75x41a8swk9"
        className="animable"
      />
      <rect
        x={168.06}
        y={111.31}
        width={412.21}
        height={261}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "374.165px 241.81px",
        }}
        id="elx68rul71m2p"
        className="animable"
      />
      <rect
        x={167.71}
        y={111.31}
        width={412.56}
        height={10.04}
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "373.99px 116.33px",
        }}
        id="elyg1c8ekt5xc"
        className="animable"
      />
      <path
        d="M175.69,116.33a2.49,2.49,0,1,1-2.49-2.49A2.49,2.49,0,0,1,175.69,116.33Z"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "173.2px 116.33px",
        }}
        id="el3w193h480ru"
        className="animable"
      />
      <path
        d="M185.86,116.33a2.49,2.49,0,1,1-2.49-2.49A2.48,2.48,0,0,1,185.86,116.33Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "183.37px 116.33px",
        }}
        id="elt3yq9ji6gtq"
        className="animable"
      />
      <path
        d="M196,116.33a2.49,2.49,0,1,1-2.49-2.49A2.48,2.48,0,0,1,196,116.33Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "193.51px 116.33px",
        }}
        id="elv90ho7qfuh9"
        className="animable"
      />
      <rect
        x={572.95}
        y={121.35}
        width={7.32}
        height={250.95}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "576.61px 246.825px",
        }}
        id="el4urnwk4lx39"
        className="animable"
      />
      <path
        d="M576.6,208.35h0a1.89,1.89,0,0,1-1.89-1.89V124.71a1.89,1.89,0,0,1,1.89-1.89h0a1.89,1.89,0,0,1,1.89,1.89v81.75A1.89,1.89,0,0,1,576.6,208.35Z"
        style={{
          fill: "rgb(166, 166, 166)",
          transformOrigin: "576.6px 165.585px",
        }}
        id="elvvxbkuq5bi"
        className="animable"
      />
    </g>
    <g
      id="freepik--Password--inject-2"
      className="animable"
      style={{
        transformOrigin: "375px 208.805px",
      }}
    >
      <rect
        x={227.42}
        y={186.44}
        width={295.16}
        height={44.74}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "375px 208.81px",
        }}
        id="ellq54jt4atpf"
        className="animable"
      />
      <path
        d="M523.08,231.67H226.92V185.94H523.08Zm-295.16-1H522.08V186.94H227.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "375px 208.805px",
        }}
        id="eljn0rgnvhz1"
        className="animable"
      />
      <path
        d="M253.48,198.56a24.25,24.25,0,0,1,1.17,5.12,40.57,40.57,0,0,1,0,10.25,24.42,24.42,0,0,1-1.18,5.12,23.81,23.81,0,0,1-1.18-5.12,41.2,41.2,0,0,1,0-10.25A24.92,24.92,0,0,1,253.48,198.56Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "253.473px 208.805px",
        }}
        id="elgxp0w33o3kw"
        className="animable"
      />
      <path
        d="M263.72,208.8A23.54,23.54,0,0,1,258.6,210a40,40,0,0,1-5.12.32,38.15,38.15,0,0,1-5.12-.32,23.76,23.76,0,0,1-5.13-1.18,25,25,0,0,1,5.13-1.18,39.88,39.88,0,0,1,5.12-.32,41.91,41.91,0,0,1,5.12.33A24.11,24.11,0,0,1,263.72,208.8Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "253.475px 208.82px",
        }}
        id="elmz7w16f06kp"
        className="animable"
      />
      <path
        d="M260.72,201.56a24.43,24.43,0,0,1-2.79,4.45,39,39,0,0,1-3.39,3.85,40.16,40.16,0,0,1-3.85,3.4,24.51,24.51,0,0,1-4.46,2.79,24.51,24.51,0,0,1,2.79-4.46,40.16,40.16,0,0,1,3.4-3.85,41.47,41.47,0,0,1,3.85-3.39A24.77,24.77,0,0,1,260.72,201.56Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "253.475px 208.805px",
        }}
        id="elokj5u5x2n9e"
        className="animable"
      />
      <path
        d="M260.72,216.05a24.77,24.77,0,0,1-4.45-2.79,40.16,40.16,0,0,1-3.85-3.4A38.87,38.87,0,0,1,249,206a24.51,24.51,0,0,1-2.79-4.46,24,24,0,0,1,4.46,2.79,40.07,40.07,0,0,1,7.24,7.25A24.43,24.43,0,0,1,260.72,216.05Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "253.465px 208.795px",
        }}
        id="el8v20t9ww052"
        className="animable"
      />
      <path
        d="M293.42,198.56a24.25,24.25,0,0,1,1.17,5.12,42,42,0,0,1,.33,5.12,40.15,40.15,0,0,1-.32,5.13,25.07,25.07,0,0,1-1.18,5.12,23.81,23.81,0,0,1-1.18-5.12,38.41,38.41,0,0,1-.32-5.13,40.11,40.11,0,0,1,.32-5.12A24.28,24.28,0,0,1,293.42,198.56Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "293.42px 208.805px",
        }}
        id="elm879afesuai"
        className="animable"
      />
      <path
        d="M303.66,208.8a23.68,23.68,0,0,1-5.12,1.18,40.11,40.11,0,0,1-5.12.32,38.41,38.41,0,0,1-5.13-.32,23.81,23.81,0,0,1-5.12-1.18,25.07,25.07,0,0,1,5.12-1.18,40.15,40.15,0,0,1,5.13-.32,42,42,0,0,1,5.12.33A24.25,24.25,0,0,1,303.66,208.8Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "293.415px 208.8px",
        }}
        id="el4belide2h6"
        className="animable"
      />
      <path
        d="M300.66,201.56a24.43,24.43,0,0,1-2.79,4.45,39,39,0,0,1-3.39,3.85,40.16,40.16,0,0,1-3.85,3.4,24.51,24.51,0,0,1-4.46,2.79,24.51,24.51,0,0,1,2.79-4.46,40.16,40.16,0,0,1,3.4-3.85,39,39,0,0,1,3.85-3.39A24.43,24.43,0,0,1,300.66,201.56Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "293.415px 208.805px",
        }}
        id="elajw094vvyuk"
        className="animable"
      />
      <path
        d="M300.66,216.05a24.43,24.43,0,0,1-4.45-2.79,37.87,37.87,0,0,1-3.85-3.4A38.87,38.87,0,0,1,289,206a24.51,24.51,0,0,1-2.79-4.46,24,24,0,0,1,4.46,2.79,40.07,40.07,0,0,1,7.24,7.25A24.43,24.43,0,0,1,300.66,216.05Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "293.435px 208.795px",
        }}
        id="el1bf4sdl8i6s"
        className="animable"
      />
      <path
        d="M333.35,198.56a23.68,23.68,0,0,1,1.18,5.12,40.11,40.11,0,0,1,.32,5.12,38.41,38.41,0,0,1-.32,5.13,23.81,23.81,0,0,1-1.18,5.12,24.39,24.39,0,0,1-1.17-5.12,40.16,40.16,0,0,1-.33-5.13,42,42,0,0,1,.33-5.12A24.25,24.25,0,0,1,333.35,198.56Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "333.35px 208.805px",
        }}
        id="elkm3ahyzaqr"
        className="animable"
      />
      <path
        d="M343.6,208.8a23.81,23.81,0,0,1-5.12,1.18,40.15,40.15,0,0,1-5.13.32,38.36,38.36,0,0,1-5.12-.32,23.68,23.68,0,0,1-5.12-1.18,24.92,24.92,0,0,1,5.12-1.18,40.11,40.11,0,0,1,5.12-.32,42.07,42.07,0,0,1,5.13.33A24.39,24.39,0,0,1,343.6,208.8Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "333.355px 208.8px",
        }}
        id="elccbh8duic4"
        className="animable"
      />
      <path
        d="M340.6,201.56a24.43,24.43,0,0,1-2.79,4.45,39,39,0,0,1-3.39,3.85,40.16,40.16,0,0,1-3.85,3.4,24.51,24.51,0,0,1-4.46,2.79,24,24,0,0,1,2.79-4.46,41.47,41.47,0,0,1,3.39-3.85,40.34,40.34,0,0,1,3.86-3.39A24.43,24.43,0,0,1,340.6,201.56Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "333.355px 208.805px",
        }}
        id="el0c7hstp4ilrv"
        className="animable"
      />
      <path
        d="M340.6,216.05a24.43,24.43,0,0,1-4.45-2.79,39.1,39.1,0,0,1-3.86-3.4A40.1,40.1,0,0,1,328.9,206a24,24,0,0,1-2.79-4.46,24,24,0,0,1,4.46,2.79,40.07,40.07,0,0,1,7.24,7.25A24.43,24.43,0,0,1,340.6,216.05Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "333.355px 208.795px",
        }}
        id="elea2o9vvo9wk"
        className="animable"
      />
      <path
        d="M373.29,198.56a24.28,24.28,0,0,1,1.18,5.12,41.2,41.2,0,0,1,0,10.25,23.81,23.81,0,0,1-1.18,5.12,24.42,24.42,0,0,1-1.18-5.12,40.15,40.15,0,0,1-.32-5.13,42,42,0,0,1,.33-5.12A24.25,24.25,0,0,1,373.29,198.56Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "373.29px 208.805px",
        }}
        id="elxtpyhuhob7a"
        className="animable"
      />
      <path
        d="M383.54,208.8a23.81,23.81,0,0,1-5.12,1.18,40.15,40.15,0,0,1-5.13.32,38.36,38.36,0,0,1-5.12-.32,23.68,23.68,0,0,1-5.12-1.18,24.92,24.92,0,0,1,5.12-1.18,40.11,40.11,0,0,1,5.12-.32,42.07,42.07,0,0,1,5.13.33A24.39,24.39,0,0,1,383.54,208.8Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "373.295px 208.8px",
        }}
        id="elaimmfd1ryx4"
        className="animable"
      />
      <path
        d="M380.54,201.56a24.43,24.43,0,0,1-2.79,4.45,39.13,39.13,0,0,1-3.4,3.85,37.87,37.87,0,0,1-3.85,3.4,24.43,24.43,0,0,1-4.45,2.79,23.46,23.46,0,0,1,2.79-4.46,40.06,40.06,0,0,1,3.39-3.85,41.47,41.47,0,0,1,3.85-3.39A24.86,24.86,0,0,1,380.54,201.56Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "373.295px 208.805px",
        }}
        id="elizp7tyog5do"
        className="animable"
      />
      <path
        d="M380.54,216.05a24.86,24.86,0,0,1-4.46-2.79,40.16,40.16,0,0,1-3.85-3.4,38.77,38.77,0,0,1-3.39-3.84,23.46,23.46,0,0,1-2.79-4.46,23.89,23.89,0,0,1,4.45,2.79,37.78,37.78,0,0,1,3.85,3.39,40.44,40.44,0,0,1,3.4,3.86A24.43,24.43,0,0,1,380.54,216.05Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "373.295px 208.805px",
        }}
        id="eloqkinbwmj3p"
        className="animable"
      />
      <path
        d="M413.23,198.56a24.92,24.92,0,0,1,1.18,5.12,41.2,41.2,0,0,1,0,10.25,23.81,23.81,0,0,1-1.18,5.12,24.42,24.42,0,0,1-1.18-5.12,40.57,40.57,0,0,1,0-10.25A24.25,24.25,0,0,1,413.23,198.56Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "413.227px 208.805px",
        }}
        id="eljpasbyophbk"
        className="animable"
      />
      <path
        d="M423.48,208.8a23.76,23.76,0,0,1-5.13,1.18,39.88,39.88,0,0,1-5.12.32,38.26,38.26,0,0,1-5.12-.32A23.54,23.54,0,0,1,403,208.8a24.77,24.77,0,0,1,5.12-1.18,40,40,0,0,1,5.12-.32,41.78,41.78,0,0,1,5.12.33A24.34,24.34,0,0,1,423.48,208.8Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "413.24px 208.8px",
        }}
        id="ela1kmst8dxr"
        className="animable"
      />
      <path
        d="M420.48,201.56a23.93,23.93,0,0,1-2.8,4.45,40.95,40.95,0,0,1-7.24,7.25,24.43,24.43,0,0,1-4.45,2.79,23.46,23.46,0,0,1,2.79-4.46,40.06,40.06,0,0,1,3.39-3.85,41.47,41.47,0,0,1,3.85-3.39A24.51,24.51,0,0,1,420.48,201.56Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "413.235px 208.805px",
        }}
        id="eljremjeimmya"
        className="animable"
      />
      <path
        d="M420.48,216.05a24.51,24.51,0,0,1-4.46-2.79,40.16,40.16,0,0,1-3.85-3.4,38.77,38.77,0,0,1-3.39-3.84,23.46,23.46,0,0,1-2.79-4.46,23.89,23.89,0,0,1,4.45,2.79,37.78,37.78,0,0,1,3.85,3.39,39,39,0,0,1,3.39,3.86A23.93,23.93,0,0,1,420.48,216.05Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "413.235px 208.805px",
        }}
        id="elc2wlfqfycn"
        className="animable"
      />
      <path
        d="M453.17,198.56a24.25,24.25,0,0,1,1.17,5.12,40.57,40.57,0,0,1,0,10.25,24.42,24.42,0,0,1-1.18,5.12,23.81,23.81,0,0,1-1.18-5.12,41.2,41.2,0,0,1,0-10.25A24.92,24.92,0,0,1,453.17,198.56Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "453.163px 208.805px",
        }}
        id="elg568uo1xkm6"
        className="animable"
      />
      <path
        d="M463.41,208.8a23.54,23.54,0,0,1-5.12,1.18,40,40,0,0,1-5.12.32,38.15,38.15,0,0,1-5.12-.32,23.76,23.76,0,0,1-5.13-1.18,25,25,0,0,1,5.13-1.18,39.88,39.88,0,0,1,5.12-.32,41.91,41.91,0,0,1,5.12.33A24.11,24.11,0,0,1,463.41,208.8Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "453.165px 208.8px",
        }}
        id="eliranfby0uyi"
        className="animable"
      />
      <path
        d="M460.41,201.56a23.38,23.38,0,0,1-2.79,4.45,37.78,37.78,0,0,1-3.39,3.85,40.16,40.16,0,0,1-3.85,3.4,24.77,24.77,0,0,1-4.45,2.79,23.94,23.94,0,0,1,2.78-4.46,40.95,40.95,0,0,1,7.25-7.24A24.77,24.77,0,0,1,460.41,201.56Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "453.17px 208.805px",
        }}
        id="el3fdd4f14uiy"
        className="animable"
      />
      <path
        d="M460.41,216.05a24.77,24.77,0,0,1-4.45-2.79,41.86,41.86,0,0,1-7.25-7.24,23.94,23.94,0,0,1-2.78-4.46,24.23,24.23,0,0,1,4.45,2.79,40.06,40.06,0,0,1,3.85,3.39,39,39,0,0,1,3.39,3.86A23.38,23.38,0,0,1,460.41,216.05Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "453.17px 208.805px",
        }}
        id="el5xr2sgkqj7v"
        className="animable"
      />
    </g>
    <g
      id="freepik--Padlock--inject-2"
      className="animable"
      style={{
        transformOrigin: "375.005px 248.29px",
      }}
    >
      <path
        d="M425.47,199.76v54.46H410.08V199.76c0-20.71-15.73-37.55-35.08-37.55s-35.08,16.84-35.08,37.55v54.46H324.53V199.76C324.53,170.1,347.17,146,375,146S425.47,170.1,425.47,199.76Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "375px 200.11px",
        }}
        id="elmk4omlkfl5o"
        className="animable"
      />
      <polygon
        points="324.53 213.92 339.92 214.54 339.92 254.22 324.53 254.22 324.53 213.92"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "332.225px 234.07px",
        }}
        id="elsrgw8aenadp"
        className="animable"
      />
      <polygon
        points="425.47 218.03 425.47 254.22 410.08 254.22 410.08 217.4 425.47 218.03"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "417.775px 235.81px",
        }}
        id="elgicf6vic9y"
        className="animable"
      />
      <rect
        x={304.81}
        y={223.21}
        width={140.39}
        height={127.37}
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "375.005px 286.895px",
        }}
        id="elklezl02y7o"
        className="animable"
      />
      <g id="elaavwgyh29is">
        <rect
          x={375}
          y={223.21}
          width={70.19}
          height={127.37}
          style={{
            opacity: 0.1,
            transformOrigin: "410.095px 286.895px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M380.94,291.56l5.87,27.87H361.87l5.88-27.87a12.47,12.47,0,1,1,13.19,0Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "374.342px 293.968px",
        }}
        id="el1d3s54y0fp1"
        className="animable"
      />
    </g>
    <g
      id="freepik--Shield--inject-2"
      className="animable"
      style={{
        transformOrigin: "283.98px 322.01px",
      }}
    >
      <path
        d="M334.22,291.51s-7.34,58.76-19.4,72c-14.48,15.86-25,13.54-30.84,21.72h0c-5.84-8.19-16.35-5.86-30.85-21.73-12.06-13.23-19.39-72-19.39-72C265.52,276.08,284,258.79,284,258.79h0C284.55,259.33,302.93,276.33,334.22,291.51Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "283.98px 322.01px",
        }}
        id="elimp04kc6n0a"
        className="animable"
      />
      <path
        d="M284,258.8V385.22h0c-5.84-8.19-16.35-5.86-30.85-21.73-12.06-13.23-19.39-72-19.39-72C265.52,276.08,284,258.79,284,258.79Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "258.88px 322.005px",
        }}
        id="el3gglufiohh"
        className="animable"
      />
      <path
        d="M326.43,296.25s-6.2,49.63-16.39,60.81c-12.25,13.42-21.13,11.45-26.07,18.36-4.94-6.91-13.82-4.94-26.06-18.36-10.19-11.18-16.38-60.81-16.38-60.81,26.85-13,42.44-27.65,42.44-27.65S299.58,283.21,326.43,296.25Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "283.98px 322.01px",
        }}
        id="el7cj6086xw8u"
        className="animable"
      />
      <path
        d="M241.54,296.25s6.2,49.63,16.39,60.81C270.16,370.47,279,368.51,284,375.4V268.62C283.51,269.06,268,283.41,241.54,296.25Z"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "262.77px 322.01px",
        }}
        id="el481tanqw0w3"
        className="animable"
      />
      <g id="elu8ps7e0udkg">
        <circle
          cx={283.98}
          cy={322.01}
          r={19.68}
          style={{
            fill: "rgb(255, 199, 39)",
            transformOrigin: "283.98px 322.01px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="281.74 334.8 272.97 325.68 276.22 322.55 281.05 327.58 291.47 311.6 295.25 314.06 281.74 334.8"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "284.11px 323.2px",
        }}
        id="eluyyn6sjtfko"
        className="animable"
      />
    </g>
    <g
      id="freepik--Key--inject-2"
      className="animable"
      style={{
        transformOrigin: "261.615px 379.265px",
      }}
    >
      <g id="elf1idm8hskoo">
        <path
          d="M283.37,324.46h12.18a0,0,0,0,1,0,0V466.74a4.9,4.9,0,0,1-4.9,4.9h-2.38a4.9,4.9,0,0,1-4.9-4.9V324.46A0,0,0,0,1,283.37,324.46Z"
          style={{
            fill: "rgb(255, 199, 39)",
            transformOrigin: "289.46px 398.05px",
            transform: "rotate(-78.95deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elnhzgewm1y5">
        <rect
          x={219.57}
          y={377}
          width={12.19}
          height={17.18}
          style={{
            opacity: 0.1,
            transformOrigin: "225.665px 385.59px",
            transform: "rotate(-78.95deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M165,362.74,160.89,384a27.65,27.65,0,1,0,54.28,10.59l4.14-21.21A27.65,27.65,0,0,0,165,362.74Zm45.59,5.31c-1.23,6.31-6.74,10.86-13.55,11.95,5.9,3.59,9.3,9.86,8.06,16.17-1.64,8.44-10.95,13.74-20.78,11.82s-16.44-10.33-14.79-18.76c1.23-6.31,6.74-10.86,13.56-12-5.91-3.57-9.3-9.86-8.07-16.17,1.65-8.45,10.94-13.73,20.76-11.81S212.28,359.61,210.63,368.05Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "190.1px 378.677px",
        }}
        id="el9txjuwvrtqo"
        className="animable"
      />
      <g id="elfcpb7pm9fsk">
        <rect
          x={206.15}
          y={381.67}
          width={27.69}
          height={5.62}
          rx={2.26}
          style={{
            fill: "rgb(255, 199, 39)",
            transformOrigin: "219.995px 384.48px",
            transform: "rotate(-78.95deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eldixxmuq83p5">
        <rect
          x={211.66}
          y={382.75}
          width={27.69}
          height={5.62}
          rx={2.26}
          style={{
            fill: "rgb(255, 199, 39)",
            transformOrigin: "225.505px 385.56px",
            transform: "rotate(-78.95deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="324.69 393.62 328.85 394.43 331.2 382.35 334.76 383.04 333.53 389.34 337.68 390.15 338.91 383.85 342.06 384.46 340.11 394.42 344.27 395.24 346.22 385.28 350.68 386.15 348.09 399.45 352.25 400.26 354.85 386.96 359.07 387.79 355.75 404.79 318.59 397.53 321.9 380.53 327.04 381.53 324.69 393.62"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "338.83px 392.66px",
        }}
        id="el3e6674lfi1j"
        className="animable"
      />
    </g>
    <g
      id="freepik--Character--inject-2"
      className="animable"
      style={{
        transformOrigin: "508.954px 270.886px",
      }}
    >
      <path
        d="M468,331.35l-27.25,22c-5.23,4.18-9.74,7.94-11.89,10.11l0,0a4.87,4.87,0,0,0-1.31,1.68s0,0,0,.06c.19,2.39,32.39,45.16,34,46.27s4.93-3.43,5-6.08-4.93-24.55-4.93-24.55l27-22.26Z"
        style={{
          fill: "rgb(201, 95, 80)",
          transformOrigin: "458.085px 371.496px",
        }}
        id="elvgyb00pc1ri"
        className="animable"
      />
      <path
        d="M446.31,348.86l-5.59,4.51c-5.23,4.18-9.74,7.94-11.89,10.11l0,0a9,9,0,0,0-1.31,1.68s0,0,0,.06c.19,2.39,32.39,45.16,34,46.27s4.93-3.43,5-6.08-4.93-24.55-4.93-24.55l5.74-4.51Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "447.425px 380.261px",
        }}
        id="elsjm7b1mf4gc"
        className="animable"
      />
      <path
        d="M461.49,411.52c-1.63-1.11-33.82-43.87-34-46.28-.09-.91,5.87-6,13.24-11.86l.24-.19L462,380.55l-.47.34s5,21.88,4.93,24.55S463.11,412.64,461.49,411.52Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "446.975px 382.442px",
        }}
        id="el3u3anw2nfw7"
        className="animable"
      />
      <path
        d="M462.78,409.79c-5.25-7.34-27.11-36.45-32.86-43.31,0-.06-.08,0,0,0,5,7.39,27.14,36.33,32.79,43.37C462.79,410,462.9,410,462.78,409.79Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "446.359px 388.205px",
        }}
        id="eln1n5eh6tue"
        className="animable"
      />
      <path
        d="M463.22,383.27c-1.61-3.23-4.65-6.38-8.31-7-.14,0-.17.17-.07.25a49.59,49.59,0,0,1,8,7A.23.23,0,0,0,463.22,383.27Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "459.011px 379.933px",
        }}
        id="elk4p55loqeu"
        className="animable"
      />
      <path
        d="M463.53,386.25c-1.61-3.24-4.66-6.38-8.31-7-.14,0-.17.18-.08.25a49.72,49.72,0,0,1,8,7A.23.23,0,0,0,463.53,386.25Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "459.327px 382.928px",
        }}
        id="el7y0ih4y4v16"
        className="animable"
      />
      <path
        d="M463.83,389.22c-1.6-3.23-4.65-6.37-8.3-7-.14,0-.18.18-.08.25a50.08,50.08,0,0,1,8,7A.22.22,0,0,0,463.83,389.22Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "459.631px 385.896px",
        }}
        id="elhuzss16ws2o"
        className="animable"
      />
      <path
        d="M482.7,305.13c-1.93,9.53-41.46,47.54-41.46,47.54l27.56,31.81s56.12-56,53.19-77.07c-2.55-18.31-30-91.94-30-91.94H460.41S484.59,295.76,482.7,305.13Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "481.67px 299.975px",
        }}
        id="elu1y68avxiaq"
        className="animable"
      />
      <path
        d="M482.7,305.13c-1.93,9.53-41.46,47.54-41.46,47.54l27.56,31.81s56.12-56,53.19-77.07c-2.55-18.31-30-91.94-30-91.94H460.41S484.59,295.76,482.7,305.13Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "481.67px 299.975px",
        }}
        id="el847fn3b3nkh"
        className="animable"
      />
      <path
        d="M471.93,376.92c-3.66-4.56-2.54-3.3-6.25-7.82-1.82-2.21-13.77-16.72-15.83-18.49,0,0-.12,0-.09.08,1.38,2.33,13.58,16.63,15.44,18.81,3.8,4.44,2.76,3.13,6.6,7.53C471.88,377.11,472,377,471.93,376.92Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "460.853px 363.834px",
        }}
        id="el26ct41x9krp"
        className="animable"
      />
      <path
        d="M486.63,300.49c-.46-2.38-1-4.75-1.47-7.12q-1.55-7.26-3.27-14.47-3.42-14.31-7.43-28.46c-2.69-9.46-5.54-18.91-8.83-28.19-.4-1.15-.82-2.29-1.24-3.43a.07.07,0,0,0-.14,0c2.85,9.3,5.78,18.57,8.44,27.91s5.11,18.68,7.43,28.08c1.16,4.72,2.24,9.46,3.3,14.2.52,2.33,1,4.67,1.52,7a50.66,50.66,0,0,1,1.33,7.22,11.08,11.08,0,0,1-1.86,6.5c-1.19,1.94-2.58,3.76-3.93,5.59-2.7,3.66-28.44,32.35-29.27,33.13-.07.06,0,.15.1.09,3.45-3.1,25.29-26.74,28.06-30.4,1.38-1.81,2.71-3.64,4-5.5a21.64,21.64,0,0,0,3.14-5.53A13.07,13.07,0,0,0,486.63,300.49Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "469.09px 283.655px",
        }}
        id="el0r4pp53kezf"
        className="animable"
      />
      <path
        d="M486.56,215.47l-5.74,75.35c-5.48-25.79-20.41-75.35-20.41-75.35Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "473.485px 253.145px",
        }}
        id="el1inlw4u7sky"
        className="animable"
      />
      <path
        d="M473,370.61s6.83,33.24,6.83,33.27c.09,6.69.29,12.56.67,15.59a.11.11,0,0,0,0,0,4.57,4.57,0,0,0,.52,2.06s0,0,0,0c2,1.32,55.53,2.15,57.41,1.55s.32-6-1.74-7.68-22.4-11.17-22.4-11.17l-7.16-33.22Z"
        style={{
          fill: "rgb(201, 95, 80)",
          transformOrigin: "506.137px 396.944px",
        }}
        id="elqbckwps4cu"
        className="animable"
      />
      <path
        d="M538.48,423.17c-1.87.6-55.4-.23-57.41-1.55,0,0,0,0,0,0a4.76,4.76,0,0,1-.52-2.06.13.13,0,0,1,0-.06c-.39-3-.58-8.88-.68-15.58l-1.18-5.79H513l1.34,6.22s20.35,9.49,22.42,11.18S540.37,422.58,538.48,423.17Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "509.013px 410.749px",
        }}
        id="eln39upom6mii"
        className="animable"
      />
      <path
        d="M538.48,423.17c-1.88.6-55.4-.23-57.41-1.55-.78-.49-1.11-8.3-1.24-17.74v-.3l34.53.15,0,.59s20.34,9.48,22.4,11.17S540.36,422.58,538.48,423.17Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "509.58px 413.474px",
        }}
        id="eltluncg6389m"
        className="animable"
      />
      <path
        d="M537.91,421.09c-9-.36-45.43-1-54.37-.64-.07,0-.07.05,0,.06,8.93.55,45.34.86,54.37.73C538.1,421.23,538.1,421.1,537.91,421.09Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "510.77px 420.807px",
        }}
        id="ell2u8lfud9xh"
        className="animable"
      />
      <path
        d="M517.24,404.47c-3.54-.72-7.89-.24-10.65,2.25-.1.09,0,.24.15.21a49.65,49.65,0,0,1,10.48-2A.23.23,0,0,0,517.24,404.47Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "512.004px 405.549px",
        }}
        id="el9801wg8ow0s"
        className="animable"
      />
      <path
        d="M519.77,406.05c-3.53-.72-7.89-.24-10.64,2.25-.1.1,0,.25.15.21a49.68,49.68,0,0,1,10.47-2A.23.23,0,0,0,519.77,406.05Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "514.54px 407.131px",
        }}
        id="elszccpdkmcmj"
        className="animable"
      />
      <path
        d="M522.31,407.64c-3.54-.72-7.89-.24-10.64,2.24-.11.1,0,.25.15.22a50.34,50.34,0,0,1,10.47-2A.22.22,0,0,0,522.31,407.64Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "517.077px 408.719px",
        }}
        id="elrfj8keoc68"
        className="animable"
      />
      <path
        d="M489,215.47a129.51,129.51,0,0,0-2.72,15.78c-.65,6.62-1.48,12.48-2.36,13.21s-6,4-7.68,5c0,0,15.58,47.24,19.6,63,5.83,22.82,19.79,91.31,19.79,91.31h-37.2s-11.31-65.19-16.26-86.93c-4.24-18.63-20.48-74.52-20.48-74.52A32.44,32.44,0,0,1,443,215.47S489,215.45,489,215.47Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "477.512px 309.616px",
        }}
        id="el6kf40c5cuqf"
        className="animable"
      />
      <path
        d="M514.27,396.57c-5.85,0-4.17-.1-10-.07-2.86,0-21.66.14-24.32.63a.06.06,0,0,0,0,.12c2.68.4,21.47-.06,24.33-.13,5.85-.15,4.17-.17,10-.38A.09.09,0,0,0,514.27,396.57Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "497.113px 396.953px",
        }}
        id="elz3ddbksdm2k"
        className="animable"
      />
      <path
        d="M486.53,242.59a71.16,71.16,0,0,0-10.26,6.87.13.13,0,0,0,.14.21c3.48-2.18,7-4.3,10.39-6.67A.25.25,0,0,0,486.53,242.59Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "481.556px 246.124px",
        }}
        id="elbc1ajkkmf5m"
        className="animable"
      />
      <path
        d="M483.69,216.89c0-.13-.26-.12-.24,0a125,125,0,0,1,1.31,12.55c.13,1.94.23,12.79-3.22,12.53-.58,0-1-.54-1.17-1.37a13.63,13.63,0,0,1-.06-3.32c0-2.28,0-4.55-.18-6.83A61.67,61.67,0,0,0,478.05,218a.11.11,0,0,0-.22,0,116.62,116.62,0,0,1,1.68,17.48c0,2.83-.73,7.2,2.22,7.17s3.83-7.28,3.89-9.23A64.35,64.35,0,0,0,483.69,216.89Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "481.728px 229.723px",
        }}
        id="ela8il1p1u07j"
        className="animable"
      />
      <path
        d="M481.67,242.27a18,18,0,0,0-.4,2.31,5.29,5.29,0,0,0,0,1.86.13.13,0,0,0,.24,0,6,6,0,0,0,.5-1.88c.15-.72.31-1.43.44-2.16A.38.38,0,0,0,481.67,242.27Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "481.821px 244.23px",
        }}
        id="elpb32ajt51ni"
        className="animable"
      />
      <path
        d="M447,242.08a36.64,36.64,0,0,0,9.42-1.78,22.27,22.27,0,0,0,9.14-5.64,18,18,0,0,0,3.51-4.91c0-.07.14,0,.12.06a16.34,16.34,0,0,1-2.48,4.91,18.81,18.81,0,0,1-4,3.84,21,21,0,0,1-10.23,3.95,16.38,16.38,0,0,1-5.5-.08C446.69,242.39,446.77,242.1,447,242.08Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "457.993px 236.213px",
        }}
        id="elv4naiexig4t"
        className="animable"
      />
      <path
        d="M474.08,376.07c-.09-.86-.21-1.72-.33-2.58-.23-1.64-.51-3.29-.77-4.93-.5-3.31-1.07-6.61-1.66-9.9-1.16-6.57-2.51-13.12-3.93-19.64-.81-3.68-1.74-7.32-2.73-11-.06-.26-.42-.13-.38.11.6,3.34,1.18,6.67,1.86,10s1.36,6.61,2,9.92c1.3,6.54,2.51,13.11,3.81,19.65.36,1.85.7,3.7,1.06,5.55.18.92.36,1.85.56,2.76s.48,1.82.63,2.74c0,.09.15.05.14,0C474.2,377.86,474.19,377,474.08,376.07Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "469.309px 353.34px",
        }}
        id="eld7dgwoqzi1a"
        className="animable"
      />
      <path
        d="M499.74,326.64c-1.24-6.17-2.91-12.26-4.59-18.33-3.38-12.2-7.36-24.23-11.33-36.26q-1.72-5.19-3.44-10.39c-.57-1.73-3.37-11.08-3.72-12a.08.08,0,0,0-.15,0c.81,3.18,1.91,7.82,2.84,11s1.92,6.21,2.89,9.3c1.9,6.09,3.83,12.18,5.67,18.28,3.65,12.07,7.27,24.14,10.23,36.4.83,3.44,1.66,6.87,2.41,10.32s1.41,6.91,2.13,10.36c0,.1.18.07.17,0C502.23,339.06,501,332.84,499.74,326.64Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "489.68px 297.496px",
        }}
        id="elshwdn6wlvyo"
        className="animable"
      />
      <path
        d="M476.61,358.93c-2.05-11.77-4.5-23.48-7.22-35.12s-5.77-23.32-9.12-34.85-7.06-23-11-34.3a143,143,0,0,1-5.05-16.31c-1.23-5.5-1.85-11.45-.57-17a27,27,0,0,1,1.27-4.13c0-.09-.1-.17-.14-.08-4.47,10.43-1.57,21.83,1.84,32.11,1.86,5.63,3.85,11.22,5.7,16.86s3.69,11.6,5.43,17.44c3.43,11.57,6.62,23.21,9.47,34.93s5.4,23.52,7.64,35.38,4.13,23.85,6.05,35.8c.25,1.56.44,3.12.65,4.68,0,.09.14.09.13,0C480.21,382.49,478.65,370.69,476.61,358.93Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "462.094px 305.754px",
        }}
        id="el6f1vojcfsh9"
        className="animable"
      />
      <path
        d="M475.85,166.08c4.38,16,17,35.3,23,38,5.22,2.32,29.44-6.64,38.58-12.46,2.6-1.66-8.15-16.63-11.28-15.59-10.23,3.41-21.36,8.45-22.22,8.16-1.74-.59-12.6-13.91-20.51-22.87C474.55,151.25,474.11,159.71,475.85,166.08Z"
        style={{
          fill: "rgb(201, 95, 80)",
          transformOrigin: "506.435px 180.559px",
        }}
        id="eln8ivmfonjd"
        className="animable"
      />
      <path
        d="M554.7,178.05c2.06-.86,17.91-1.36,17.91-4.8s-20.18-2.48-20.18-2.48Z"
        style={{
          fill: "rgb(201, 95, 80)",
          transformOrigin: "562.52px 174.348px",
        }}
        id="el9qaj6yj7fc5"
        className="animable"
      />
      <path
        d="M524.3,178.45c2.77-3.44,5.21-7.15,7.62-7.83,1.66-.47,19.61.3,22.41.13s12.81.09,13,2.55c.33,3.47-11.51,3.44-12.77,5.22-4.37,6.13-21,15.2-21,15.2Z"
        style={{
          fill: "rgb(201, 95, 80)",
          transformOrigin: "545.818px 182.093px",
        }}
        id="elx0lclwspvt"
        className="animable"
      />
      <path
        d="M567.64,173.35a2.75,2.75,0,0,0-1.15-1.55,8.57,8.57,0,0,0-4.28-1.05,40.16,40.16,0,0,0-6.58-.05s0,.07,0,.08c1.65,0,3.28,0,4.92.18a19.72,19.72,0,0,1,4.45.68,3.62,3.62,0,0,1,1.71,1A1.42,1.42,0,0,1,567,174c-.47,1.51-3.46,2-4.84,2.27-1.8.4-3.67.75-5.48,1.12-.08,0,0,.14,0,.12,1.88-.37,3.86-.51,5.75-.85a9.41,9.41,0,0,0,4.4-1.6A1.75,1.75,0,0,0,567.64,173.35Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "561.642px 174.05px",
        }}
        id="elln1nlgaq3ce"
        className="animable"
      />
      <path
        d="M523.61,176.33l14.2,20.79s-22,11-30.42,10.83c-8-.19-10.91-1.37-13.44-3.76-1.57-1.48-10.48-14.07-17.75-35-6.1-17.54,2.93-15,11-5.24,4,4.84,16.84,19.27,17.48,19.22S523.61,176.33,523.61,176.33Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "506.003px 182.13px",
        }}
        id="elu504pk0uesc"
        className="animable"
      />
      <path
        d="M519.24,179.71c2.13,3.08,4.17,6.22,6.18,9.38,1,1.54,4.68,7.84,5.75,9.63,0,.07-.15.19-.2.12-1.11-1.58-5.12-7.87-6.09-9.43-2-3.19-3.89-6.39-5.73-9.64A.05.05,0,0,1,519.24,179.71Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "525.155px 189.274px",
        }}
        id="elo0t9p8h8gm8"
        className="animable"
      />
      <g id="elzxivom5wtva">
        <path
          d="M511.65,207.42a18.33,18.33,0,0,1-4.26.53c-8-.19-10.91-1.37-13.44-3.76-1.56-1.48-10.48-14.06-17.74-35a29.54,29.54,0,0,1-2-9.07c0-.19,0-.39,0-.57a4.53,4.53,0,0,1,0-.53,4.3,4.3,0,0,1,.07-.43,3.09,3.09,0,0,1,.88-1.72C480.13,163.53,503.43,195,511.65,207.42Z"
          style={{
            opacity: 0.1,
            transformOrigin: "492.926px 182.41px",
          }}
          className="animable"
        />
      </g>
      <rect
        x={509.94}
        y={167.8}
        width={53.84}
        height={2.89}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "536.86px 169.245px",
        }}
        id="elewbae481tk"
        className="animable"
      />
      <polygon
        points="563.78 167.8 563.78 170.69 531.08 170.69 533.29 167.8 563.78 167.8"
        style={{
          fill: "rgb(166, 166, 166)",
          transformOrigin: "547.43px 169.245px",
        }}
        id="eltkbs0dqw8pl"
        className="animable"
      />
      <polygon
        points="535.67 170.69 571.68 170.69 590.42 138.87 554.41 138.87 535.67 170.69"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "563.045px 154.78px",
        }}
        id="elmaocq2gcg9a"
        className="animable"
      />
      <path
        d="M561.7,154.76c-.67,1.47-.18,3,1.1,3.46a3.06,3.06,0,0,0,3.54-1.87c.67-1.47.18-3-1.1-3.46A3.07,3.07,0,0,0,561.7,154.76Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "564.02px 155.556px",
        }}
        id="elsohvqh47ank"
        className="animable"
      />
      <path
        d="M439.53,226.43c.13.14,56.37-.26,56.88-.84.7-.82-12.62-61.62-18.47-69.09-1.25-1.6-5.33-2-8.79-1.54a15.57,15.57,0,0,0-2.66.57l-.09,0a6.44,6.44,0,0,0-1.95,1c-9.55,7.79-17.38,34.75-19.19,42.84C442.26,212.71,439.06,225.9,439.53,226.43Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "467.96px 190.62px",
        }}
        id="elyvu8fk72e3"
        className="animable"
      />
      <path
        d="M465.8,155.67s3.67,25,4.54,31.52,0,40.75,0,40.75l-35.29,3s7.4-22.76,7.47-24.2c0,0,5.85-27.35,11.89-38.64A34.14,34.14,0,0,1,465.8,155.67Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "452.888px 193.305px",
        }}
        id="elxrhqvgaffk"
        className="animable"
      />
      <path
        d="M477.55,156.11s4.77,25.32,5.19,27.41,6.53,43.24,6.53,43.24l8.83.19-4.79-18.8S483.06,160,477.55,156.11Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "487.825px 191.53px",
        }}
        id="eld0kv7a1bnge"
        className="animable"
      />
      <path
        d="M475.56,164.2c-2.68.31-7.76-3.42-9.18-7.37-.09-.28.71-2.85,1.53-6,.49-1.93,1-4.08,1.31-6.08.06-.41,12.06,5,12.06,5a32.08,32.08,0,0,0-2.43,7.48,4.86,4.86,0,0,0,0,.84v.14C478.81,159.82,478.44,163.88,475.56,164.2Z"
        style={{
          fill: "rgb(201, 95, 80)",
          transformOrigin: "473.827px 154.473px",
        }}
        id="el36bouupoutm"
        className="animable"
      />
      <path
        d="M478.81,158.09a.71.71,0,0,1,0,.14,9,9,0,0,1-1.29-.14c-7.35-1.33-7.84-10.88-7.85-13.28,2.18.67,11.62,5,11.62,5a32.24,32.24,0,0,0-2.43,7.49A5.91,5.91,0,0,0,478.81,158.09Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "475.48px 151.52px",
        }}
        id="el5heetwn3bdt"
        className="animable"
      />
      <path
        d="M469.36,128.8c-1.93,4.3-1.19,17.5,1.62,20.77,4.08,4.75,11.57,6.1,15.81,1,4.11-4.93,2.85-22.12-.37-25.18C481.68,120.89,472.2,122.46,469.36,128.8Z"
        style={{
          fill: "rgb(201, 95, 80)",
          transformOrigin: "478.835px 138.317px",
        }}
        id="el69ufqwm4h5a"
        className="animable"
      />
      <path
        d="M479.08,138.15s-.07,0-.06.08c.07.89,0,1.91-.78,2.25,0,0,0,.06,0,.05C479.22,140.34,479.29,139,479.08,138.15Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "478.708px 139.341px",
        }}
        id="elzz84gkao6mp"
        className="animable"
      />
      <path
        d="M478.24,137.26c-1.43,0-1.4,2.84-.07,2.87S479.44,137.29,478.24,137.26Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "478.162px 138.695px",
        }}
        id="elnmohk5fyxx"
        className="animable"
      />
      <path
        d="M484.56,138.1s.07.05.07.08c0,.89.11,1.92.92,2.2,0,0,0,.06,0,.05C484.56,140.31,484.4,139,484.56,138.1Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "485.028px 139.266px",
        }}
        id="elbcsxrkc3lrl"
        className="animable"
      />
      <path
        d="M485.34,137.17c1.43-.13,1.58,2.74.25,2.86S484.14,137.27,485.34,137.17Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "485.508px 138.6px",
        }}
        id="el60k03y3cdk6"
        className="animable"
      />
      <path
        d="M477.09,136c.39-.11.73-.27,1.11-.41a1.87,1.87,0,0,0,1.05-.63.61.61,0,0,0-.1-.73,1.52,1.52,0,0,0-1.53-.14,2.22,2.22,0,0,0-1.26,1A.65.65,0,0,0,477.09,136Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "477.813px 134.981px",
        }}
        id="elkxn408edu9"
        className="animable"
      />
      <path
        d="M486.44,134.75a11.33,11.33,0,0,1-1.15-.28,1.94,1.94,0,0,1-1.11-.51.62.62,0,0,1,0-.75,1.53,1.53,0,0,1,1.5-.3,2.24,2.24,0,0,1,1.36.83A.65.65,0,0,1,486.44,134.75Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "485.602px 133.791px",
        }}
        id="elciq0ex4xanr"
        className="animable"
      />
      <path
        d="M478.79,146.38c.21.21.43.49.75.52a2.35,2.35,0,0,0,1-.24s.05,0,0,.05a1.21,1.21,0,0,1-1.13.49.94.94,0,0,1-.71-.77C478.69,146.37,478.75,146.35,478.79,146.38Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "479.631px 146.788px",
        }}
        id="elwxfgt2e9zr"
        className="animable"
      />
      <path
        d="M479.69,143.71a3,3,0,0,0,2,1.32,3.63,3.63,0,0,0,1.09,0,.71.71,0,0,0,.21,0l.19,0a.22.22,0,0,0,.18-.2h0a.28.28,0,0,0,0-.09h0v-.08c0-.69-.07-1.73-.07-1.73.25.12,1.54.68,1.51.32a46,46,0,0,0-1.28-9.16.08.08,0,0,0-.16,0c.09,2.85.68,5.67.82,8.54a5.39,5.39,0,0,0-1.44-.42c-.1,0,.19,2,.16,2.32v0a4.2,4.2,0,0,1-3.15-.93C479.71,143.58,479.64,143.64,479.69,143.71Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "482.237px 139.541px",
        }}
        id="elugbqm56a75c"
        className="animable"
      />
      <path
        d="M482.41,144.73a3.67,3.67,0,0,1-1.42,1.13,1.66,1.66,0,0,1-1,.07c-.71-.18-.73-.83-.61-1.39a3.86,3.86,0,0,1,.31-.86A4.68,4.68,0,0,0,482.41,144.73Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "480.867px 144.828px",
        }}
        id="eluxethk0hxoh"
        className="animable"
      />
      <path
        d="M481,145.86a1.66,1.66,0,0,1-1,.07c-.71-.18-.73-.83-.61-1.39A1.69,1.69,0,0,1,481,145.86Z"
        style={{
          fill: "rgb(255, 155, 188)",
          transformOrigin: "480.167px 145.258px",
        }}
        id="elfr1952t3ffo"
        className="animable"
      />
      <path
        d="M468.26,140.21s-3.19-7.92-2.1-12c.49-1.85,3.26-2.7,3.26-2.7a4.32,4.32,0,0,1,2-4.06c2.57-1.74,7.89-.39,7.89-.39s4.32-4.48,8.94-1.67.48,7.89.48,7.89,1.4,2.87-3.37,4.16-13.14-2.11-13.14-2.11a31.31,31.31,0,0,1-1.63,9.12A2.83,2.83,0,0,1,468.26,140.21Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "478.184px 129.337px",
        }}
        id="el71fkyxdyxrv"
        className="animable"
      />
      <path
        d="M480.19,121.25a9,9,0,0,0-3.38-1.21,9.88,9.88,0,0,0-3.21,0,5.29,5.29,0,0,0-4.3,3.31,3.67,3.67,0,0,0,.25,2.85s.07,0,.05,0c-.91-1.61,0-3.71,1.42-4.7a8,8,0,0,1,5.54-1.08,15.32,15.32,0,0,1,3.58,1A.06.06,0,0,0,480.19,121.25Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "474.687px 123.054px",
        }}
        id="el6etw3jjq5i6"
        className="animable"
      />
      <path
        d="M491.43,122.33a4.24,4.24,0,0,0-2.49-3.17,7.23,7.23,0,0,0-4.71-.6,9.2,9.2,0,0,0-4.29,2.21s0,.07,0,0a8.09,8.09,0,0,1,8.11-1.6,4,4,0,0,1,2.11,6.33,8.25,8.25,0,0,1-3.7,2.67s0,.07,0,.05a8.54,8.54,0,0,0,3.61-2.18A4.8,4.8,0,0,0,491.43,122.33Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "485.691px 123.314px",
        }}
        id="eltrvkjw27jm"
        className="animable"
      />
      <path
        d="M486.44,131.83a15.18,15.18,0,0,1-6.4.07,31.18,31.18,0,0,1-6.4-2c-1.2-.5-2.38-1.05-3.55-1.62a0,0,0,1,0,0,.05,33.36,33.36,0,0,0,6.36,3.16,17,17,0,0,0,6.47,1,14.31,14.31,0,0,0,3.56-.64S486.47,131.82,486.44,131.83Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "478.285px 130.392px",
        }}
        id="elcf8no9qq3s7"
        className="animable"
      />
      <path
        d="M471.28,139.87s-2.48-4.49-4.49-3.64-.45,7.13,1.69,8.2a2.29,2.29,0,0,0,3.17-.9Z"
        style={{
          fill: "rgb(201, 95, 80)",
          transformOrigin: "468.754px 140.432px",
        }}
        id="el2gvbmhg1h06"
        className="animable"
      />
      <path
        d="M467.28,138s0,0,0,.06a5.72,5.72,0,0,1,2.58,3.89,1.26,1.26,0,0,0-1.83-.54s0,.1,0,.09a1.41,1.41,0,0,1,1.49.65,7.86,7.86,0,0,1,.6,1.32c.06.15.32.1.28-.07v0C470.65,141.4,469.4,138.45,467.28,138Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "468.856px 140.78px",
        }}
        id="elthofls19kkj"
        className="animable"
      />
      <path
        d="M458.71,161.43C444.55,177.33,433.9,199.72,434,205c.14,5.8,7.52,26.23,14.55,35.83.91,1.23,6.39.71,11-1.84,4.89-2.69,8.9-7.49,7.94-8.92-3.61-5.37-12.16-26.17-12.08-29.42.07-3,7.08-21.42,9.75-32.07C467.77,158.33,465.3,154,458.71,161.43Z"
        style={{
          fill: "rgb(201, 95, 80)",
          transformOrigin: "450.816px 199.583px",
        }}
        id="elfowio7hey96"
        className="animable"
      />
      <path
        d="M440,239.24l26.37-12.88S456,203.22,456,200.72s6.82-22.06,9.39-31.1,1.58-19.2-9.68-5.53-21.1,34-22.22,39.4S440,239.24,440,239.24Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "449.971px 198.332px",
        }}
        id="elakq1petperd"
        className="animable"
      />
      <path
        d="M457.32,194.08q.81-2.57,1.68-5.13c.65-1.91,1.32-3.81,2-5.7s1.45-3.65,2.19-5.47c0-.13.28,0,.24.09-.88,2.43-1.7,4.89-2.52,7.35.62-1.12,1.24-2.23,1.9-3.33a.09.09,0,0,1,.15.08c-.74,1.36-1.52,2.69-2.31,4-.19.59-.39,1.19-.59,1.78-1.11,3.3-2.17,6.62-3.12,10a11.32,11.32,0,0,0-.47,2.3,6.9,6.9,0,0,0,.39,2.62c.48,1.55,1,3.08,1.56,4.6,1.1,3.07,2.3,6.09,3.55,9.09,1.43,3.42,3.06,6.79,4.68,10.12,0,0-1,.26-1,.28s-.08,0-.06-.05.62-.33.64-.33c-1.65-2.88-3.22-5.71-4.61-8.74s-2.6-6-3.71-9.14c-.57-1.6-1.12-3.2-1.62-4.82a9,9,0,0,1-.45-4.71C456.21,197.31,456.81,195.69,457.32,194.08Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "461.18px 202.246px",
        }}
        id="elfsfvderigs"
        className="animable"
      />
      <path
        d="M439.55,231.81a29.16,29.16,0,0,1,2.88-1.36c.86-.4,1.71-.81,2.58-1.2,1.88-.85,3.76-1.71,5.65-2.55s3.77-1.69,5.66-2.51,3.75-1.7,5.67-2.41c.07,0,.12.08.06.12-1.75,1.08-3.62,2-5.46,2.89s-3.65,1.75-5.5,2.58-3.79,1.64-5.7,2.41c-.92.37-1.85.71-2.79,1.06a24.46,24.46,0,0,1-3,1.07A.06.06,0,0,1,439.55,231.81Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "450.806px 226.845px",
        }}
        id="elv9wv5oml7o"
        className="animable"
      />
    </g>
    <defs>
      <filter id="active" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter id="hover" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#ff0000" floodOpacity={0.5} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
        <feColorMatrix
          type="matrix"
          values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
        />
      </filter>
    </defs>
  </svg>
);
export default SvgComponent;

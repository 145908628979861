import * as React from "react";
import "./MainServicesSvg.css";
const SvgComponent = () => (
  <svg
    className="animated"
    id="freepik_stories-cyber-attack"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlnssvgjs="http://svgjs.com/svgjs"
  >
    <g
      id="freepik--background-complete--inject-2"
      className="animable"
      style={{
        transformOrigin: "235.292px 160.143px",
      }}
    >
      <path
        d="M55.9,212.37a9.34,9.34,0,0,1,1.48-5.21,4.59,4.59,0,0,1,8.09,0,10,10,0,0,1,0,10.42,4.59,4.59,0,0,1-8.09,0A9.39,9.39,0,0,1,55.9,212.37Zm8.61,0a7.24,7.24,0,0,0-1.05-3.88,2.54,2.54,0,0,0-2-1.33,2.63,2.63,0,0,0-2.07,1.33,7.24,7.24,0,0,0-1,3.88,7.17,7.17,0,0,0,1,3.86,2.63,2.63,0,0,0,2.07,1.35,2.55,2.55,0,0,0,2-1.35A7.17,7.17,0,0,0,64.51,212.37Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "61.4165px 212.37px",
        }}
        id="eluwj6jshdyci"
        className="animable"
      />
      <path
        d="M75.8,205.94V218.8a1.21,1.21,0,0,1-1.22,1.2,1.18,1.18,0,0,1-1.2-1.2V208.08l-.87.5a1.11,1.11,0,0,1-.61.17,1.22,1.22,0,0,1-.63-2.24L74,204.92s.06,0,.08,0,.07,0,.11-.07l.13,0a.15.15,0,0,1,.09,0,.27.27,0,0,1,.15,0h.24s.07,0,.09,0a.25.25,0,0,1,.13,0,.15.15,0,0,1,.09,0c.06,0,.08.07.13.07a.23.23,0,0,0,.09.06l.08.07a.19.19,0,0,1,.09.09l.07.08.08.09,0,0s0,0,0,.07a.21.21,0,0,1,0,.11s0,.08,0,.11,0,.08,0,.11a.17.17,0,0,0,0,.15Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "73.2592px 212.42px",
        }}
        id="eltkeomtfbktk"
        className="animable"
      />
      <path
        d="M83.1,205.94V218.8a1.21,1.21,0,0,1-1.22,1.2,1.18,1.18,0,0,1-1.2-1.2V208.08l-.87.5a1.11,1.11,0,0,1-.61.17,1.22,1.22,0,0,1-.63-2.24l2.68-1.59s.06,0,.08,0,.07,0,.11-.07l.13,0a.16.16,0,0,1,.09,0,.27.27,0,0,1,.15,0h.24s.07,0,.09,0a.22.22,0,0,1,.13,0,.15.15,0,0,1,.09,0c.06,0,.08.07.13.07a.23.23,0,0,0,.09.06l.08.07a.19.19,0,0,1,.09.09l.07.08.08.09,0,0s0,0,0,.07a.21.21,0,0,1,0,.11s0,.08,0,.11,0,.08,0,.11a.17.17,0,0,0,0,.15Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "80.5592px 212.42px",
        }}
        id="el8ixkoyzab3p"
        className="animable"
      />
      <path
        d="M90.4,205.94V218.8a1.21,1.21,0,0,1-1.22,1.2,1.18,1.18,0,0,1-1.2-1.2V208.08l-.87.5a1.11,1.11,0,0,1-.61.17,1.22,1.22,0,0,1-.63-2.24l2.68-1.59s.06,0,.08,0,.07,0,.11-.07l.13,0a.16.16,0,0,1,.09,0,.27.27,0,0,1,.15,0h.24s.07,0,.09,0a.22.22,0,0,1,.13,0,.15.15,0,0,1,.09,0c.06,0,.08.07.13.07a.23.23,0,0,0,.09.06L90,205a.19.19,0,0,1,.09.09l.07.08.08.09,0,0s0,0,0,.07a.21.21,0,0,1,0,.11s0,.08,0,.11,0,.08,0,.11a.17.17,0,0,0,.05.15Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "87.8592px 212.42px",
        }}
        id="elk2id6stoz8"
        className="animable"
      />
      <path
        d="M93.17,212.37a9.34,9.34,0,0,1,1.48-5.21,4.58,4.58,0,0,1,8.08,0,10,10,0,0,1,0,10.42,4.58,4.58,0,0,1-8.08,0A9.39,9.39,0,0,1,93.17,212.37Zm8.6,0a7.24,7.24,0,0,0-1-3.88,2.54,2.54,0,0,0-2-1.33,2.66,2.66,0,0,0-2.07,1.33,7.24,7.24,0,0,0-1,3.88,7.17,7.17,0,0,0,1,3.86,2.66,2.66,0,0,0,2.07,1.35,2.55,2.55,0,0,0,2-1.35A7.17,7.17,0,0,0,101.77,212.37Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "98.6815px 212.37px",
        }}
        id="el00pldmfrd8zs"
        className="animable"
      />
      <path
        d="M108.64,212.37a9.34,9.34,0,0,1,1.48-5.21,4.59,4.59,0,0,1,8.09,0,10,10,0,0,1,0,10.42,4.59,4.59,0,0,1-8.09,0A9.39,9.39,0,0,1,108.64,212.37Zm8.61,0a7.24,7.24,0,0,0-1-3.88,2.54,2.54,0,0,0-2-1.33,2.66,2.66,0,0,0-2.07,1.33,7.24,7.24,0,0,0-1,3.88,7.17,7.17,0,0,0,1,3.86,2.66,2.66,0,0,0,2.07,1.35,2.55,2.55,0,0,0,2-1.35A7.17,7.17,0,0,0,117.25,212.37Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "114.157px 212.37px",
        }}
        id="el9gzt02yai1w"
        className="animable"
      />
      <path
        d="M124.11,212.37a9.42,9.42,0,0,1,1.48-5.21,4.59,4.59,0,0,1,8.09,0,10,10,0,0,1,0,10.42,4.59,4.59,0,0,1-8.09,0A9.47,9.47,0,0,1,124.11,212.37Zm8.61,0a7.24,7.24,0,0,0-1.05-3.88,2.54,2.54,0,0,0-2-1.33,2.63,2.63,0,0,0-2.07,1.33,7.24,7.24,0,0,0-1,3.88,7.17,7.17,0,0,0,1,3.86,2.63,2.63,0,0,0,2.07,1.35,2.55,2.55,0,0,0,2-1.35A7.17,7.17,0,0,0,132.72,212.37Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "129.627px 212.37px",
        }}
        id="elc102y6ql9ln"
        className="animable"
      />
      <path
        d="M144,205.94V218.8a1.21,1.21,0,0,1-1.22,1.2,1.18,1.18,0,0,1-1.2-1.2V208.08l-.87.5a1.11,1.11,0,0,1-.61.17,1.22,1.22,0,0,1-.63-2.24l2.68-1.59s.06,0,.08,0,.07,0,.11-.07l.13,0a.16.16,0,0,1,.09,0,.27.27,0,0,1,.15,0H143s.07,0,.09,0a.22.22,0,0,1,.13,0,.15.15,0,0,1,.09,0c.06,0,.09.07.13.07a.23.23,0,0,0,.09.06l.08.07a.19.19,0,0,1,.09.09l.07.08.08.09,0,0s0,0,0,.07a.21.21,0,0,1,0,.11s0,.08,0,.11,0,.08,0,.11a.2.2,0,0,0,0,.15Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "141.459px 212.42px",
        }}
        id="eldrlrbyitsbm"
        className="animable"
      />
      <path
        d="M148.4,212.37a9.42,9.42,0,0,1,1.48-5.21,4.59,4.59,0,0,1,8.09,0,10,10,0,0,1,0,10.42,4.59,4.59,0,0,1-8.09,0A9.47,9.47,0,0,1,148.4,212.37Zm8.61,0a7.24,7.24,0,0,0-1-3.88,2.54,2.54,0,0,0-2-1.33,2.63,2.63,0,0,0-2.07,1.33,7.24,7.24,0,0,0-1.05,3.88,7.17,7.17,0,0,0,1.05,3.86,2.63,2.63,0,0,0,2.07,1.35,2.55,2.55,0,0,0,2-1.35A7.17,7.17,0,0,0,157,212.37Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "153.917px 212.37px",
        }}
        id="elinntxbf23ai"
        className="animable"
      />
      <path
        d="M60.49,232.09V245a1.21,1.21,0,0,1-1.22,1.2,1.18,1.18,0,0,1-1.2-1.2V234.23l-.87.5a1.13,1.13,0,0,1-.61.18,1.22,1.22,0,0,1-.63-2.25l2.68-1.59s.06,0,.08,0,.07-.05.11-.07l.13,0a.15.15,0,0,1,.09,0,.39.39,0,0,1,.15,0h.24a.15.15,0,0,0,.09,0,.25.25,0,0,1,.13,0,.15.15,0,0,1,.09,0c.06.05.08.07.13.07a.23.23,0,0,0,.09.06.5.5,0,0,0,.08.07c.05,0,.07.07.09.09a.28.28,0,0,1,.06.08l.09.09,0,0s0,.05,0,.07a.25.25,0,0,1,0,.11s0,.09,0,.11,0,.09,0,.11a.17.17,0,0,0,.05.15Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "57.9417px 238.596px",
        }}
        id="elfplg20f067w"
        className="animable"
      />
      <path
        d="M63.26,238.52a9.34,9.34,0,0,1,1.48-5.21,4.59,4.59,0,0,1,8.08,0,10,10,0,0,1,0,10.42,4.58,4.58,0,0,1-8.08,0A9.34,9.34,0,0,1,63.26,238.52Zm8.6,0a7.24,7.24,0,0,0-1-3.88,2.54,2.54,0,0,0-2-1.33,2.64,2.64,0,0,0-2.07,1.33,7.24,7.24,0,0,0-1,3.88,7.17,7.17,0,0,0,1,3.86,2.65,2.65,0,0,0,2.07,1.35,2.55,2.55,0,0,0,2-1.35A7.17,7.17,0,0,0,71.86,238.52Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "68.7715px 238.526px",
        }}
        id="elovgie0cdma"
        className="animable"
      />
      <path
        d="M83.15,232.09V245a1.21,1.21,0,0,1-1.22,1.2,1.18,1.18,0,0,1-1.2-1.2V234.23l-.87.5a1.08,1.08,0,0,1-.61.18,1.22,1.22,0,0,1-.63-2.25l2.68-1.59s.07,0,.09,0,.06-.05.11-.07l.13,0a.12.12,0,0,1,.08,0,.44.44,0,0,1,.16,0h.24a.12.12,0,0,0,.08,0,.22.22,0,0,1,.13,0,.13.13,0,0,1,.09,0c.07.05.09.07.13.07l.09.06s.07,0,.09.07.06.07.08.09a.19.19,0,0,1,.07.08l.09.09,0,0s0,.05,0,.07a.25.25,0,0,1,0,.11s0,.09,0,.11,0,.09,0,.11a.19.19,0,0,0,0,.15Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "80.6017px 238.595px",
        }}
        id="elcwqlmitu4y"
        className="animable"
      />
      <path
        d="M85.92,238.52a9.34,9.34,0,0,1,1.48-5.21,4.6,4.6,0,0,1,8.09,0,10,10,0,0,1,0,10.42,4.59,4.59,0,0,1-8.09,0A9.34,9.34,0,0,1,85.92,238.52Zm8.61,0a7.24,7.24,0,0,0-1-3.88,2.54,2.54,0,0,0-2-1.33,2.63,2.63,0,0,0-2.06,1.33,7.15,7.15,0,0,0-1,3.88,7.08,7.08,0,0,0,1,3.86,2.63,2.63,0,0,0,2.06,1.35,2.55,2.55,0,0,0,2-1.35A7.17,7.17,0,0,0,94.53,238.52Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "91.4365px 238.526px",
        }}
        id="elw0dwvs0b4fj"
        className="animable"
      />
      <path
        d="M101.39,238.52a9.42,9.42,0,0,1,1.49-5.21,4.59,4.59,0,0,1,8.08,0,10,10,0,0,1,0,10.42,4.58,4.58,0,0,1-8.08,0A9.42,9.42,0,0,1,101.39,238.52Zm8.61,0a7.15,7.15,0,0,0-1-3.88,2.54,2.54,0,0,0-2-1.33,2.63,2.63,0,0,0-2.07,1.33,7.24,7.24,0,0,0-1,3.88,7.17,7.17,0,0,0,1,3.86,2.63,2.63,0,0,0,2.07,1.35,2.55,2.55,0,0,0,2-1.35A7.08,7.08,0,0,0,110,238.52Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "106.907px 238.526px",
        }}
        id="el8wv8brecnem"
        className="animable"
      />
      <path
        d="M116.87,238.52a9.34,9.34,0,0,1,1.48-5.21,4.59,4.59,0,0,1,8.08,0,10,10,0,0,1,0,10.42,4.58,4.58,0,0,1-8.08,0A9.34,9.34,0,0,1,116.87,238.52Zm8.6,0a7.24,7.24,0,0,0-1-3.88,2.54,2.54,0,0,0-2-1.33,2.66,2.66,0,0,0-2.07,1.33,7.24,7.24,0,0,0-1,3.88,7.17,7.17,0,0,0,1,3.86,2.66,2.66,0,0,0,2.07,1.35,2.55,2.55,0,0,0,2-1.35A7.17,7.17,0,0,0,125.47,238.52Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "122.382px 238.526px",
        }}
        id="elytgquxwng3i"
        className="animable"
      />
      <path
        d="M136.76,232.09V245a1.21,1.21,0,0,1-1.22,1.2,1.18,1.18,0,0,1-1.2-1.2V234.23l-.87.5a1.08,1.08,0,0,1-.61.18,1.22,1.22,0,0,1-.63-2.25l2.68-1.59s.07,0,.09,0,.06-.05.11-.07l.13,0a.12.12,0,0,1,.08,0,.44.44,0,0,1,.16,0h.24a.12.12,0,0,0,.08,0,.22.22,0,0,1,.13,0,.13.13,0,0,1,.09,0c.07.05.09.07.13.07l.09.06s.07,0,.09.07.06.07.08.09a.19.19,0,0,1,.07.08l.09.09,0,0s0,.05,0,.07a.25.25,0,0,1,0,.11s0,.09,0,.11,0,.09,0,.11a.19.19,0,0,0,0,.15Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "134.212px 238.595px",
        }}
        id="elcmdz3sudq4t"
        className="animable"
      />
      <path
        d="M139.53,238.52a9.34,9.34,0,0,1,1.48-5.21,4.6,4.6,0,0,1,8.09,0,10,10,0,0,1,0,10.42,4.59,4.59,0,0,1-8.09,0A9.34,9.34,0,0,1,139.53,238.52Zm8.61,0a7.24,7.24,0,0,0-1-3.88,2.54,2.54,0,0,0-2-1.33,2.63,2.63,0,0,0-2.07,1.33,7.24,7.24,0,0,0-1.05,3.88,7.17,7.17,0,0,0,1.05,3.86,2.63,2.63,0,0,0,2.07,1.35,2.55,2.55,0,0,0,2-1.35A7.17,7.17,0,0,0,148.14,238.52Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "145.047px 238.526px",
        }}
        id="eli0gwit6mohj"
        className="animable"
      />
      <path
        d="M159.43,232.09V245a1.21,1.21,0,0,1-1.22,1.2A1.18,1.18,0,0,1,157,245V234.23l-.87.5a1.13,1.13,0,0,1-.61.18,1.22,1.22,0,0,1-.63-2.25l2.68-1.59.08,0c.05,0,.07-.05.11-.07l.13,0a.15.15,0,0,1,.09,0,.39.39,0,0,1,.15,0h.24a.15.15,0,0,0,.09,0,.25.25,0,0,1,.13,0,.16.16,0,0,1,.09,0c.06.05.08.07.13.07l.08.06.09.07s.07.07.09.09a.28.28,0,0,1,.06.08l.09.09,0,0s0,.05,0,.07a.25.25,0,0,1,0,.11s0,.09,0,.11,0,.09,0,.11a.17.17,0,0,0,.05.15Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "156.877px 238.596px",
        }}
        id="el9qjoiyjlesh"
        className="animable"
      />
      <path
        d="M56,264.67a9.28,9.28,0,0,1,1.48-5.2,4.58,4.58,0,0,1,8.08,0,10,10,0,0,1,0,10.41,4.58,4.58,0,0,1-8.08,0A9.34,9.34,0,0,1,56,264.67Zm8.6,0a7.21,7.21,0,0,0-1-3.87,2.5,2.5,0,0,0-2-1.33,2.59,2.59,0,0,0-2.07,1.33,7.64,7.64,0,0,0,0,7.73,2.65,2.65,0,0,0,2.07,1.35,2.55,2.55,0,0,0,2-1.35A7.14,7.14,0,0,0,64.56,264.67Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "61.5099px 264.675px",
        }}
        id="ely3vausag8gr"
        className="animable"
      />
      <path
        d="M71.43,264.67a9.28,9.28,0,0,1,1.48-5.2,4.58,4.58,0,0,1,8.08,0,10,10,0,0,1,0,10.41,4.58,4.58,0,0,1-8.08,0A9.34,9.34,0,0,1,71.43,264.67Zm8.61,0A7.2,7.2,0,0,0,79,260.8a2.5,2.5,0,0,0-2-1.33,2.61,2.61,0,0,0-2.07,1.33,7.7,7.7,0,0,0,0,7.73A2.66,2.66,0,0,0,77,269.88a2.55,2.55,0,0,0,2-1.35A7.14,7.14,0,0,0,80,264.67Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "76.9399px 264.675px",
        }}
        id="elvpvidn27gk"
        className="animable"
      />
      <path
        d="M91.33,258.25V271.1a1.21,1.21,0,0,1-1.22,1.2,1.18,1.18,0,0,1-1.2-1.2V260.38l-.88.5a1.07,1.07,0,0,1-.6.18,1.22,1.22,0,0,1-.64-2.25l2.68-1.59s.07,0,.09,0,.07,0,.11-.07l.13,0a.15.15,0,0,1,.09,0,.39.39,0,0,1,.15,0h.24a.13.13,0,0,0,.09,0,.28.28,0,0,1,.13,0,.09.09,0,0,1,.08,0c.07,0,.09.06.14.06l.08.07.09.06s.06.07.09.09a.23.23,0,0,1,.06.09l.09.08,0,.05s0,0,0,.06,0,.07,0,.11,0,.09,0,.11,0,.09,0,.11a.17.17,0,0,0,0,.15Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "88.7769px 264.72px",
        }}
        id="eljb2fx5j2lq"
        className="animable"
      />
      <path
        d="M94.09,264.67a9.44,9.44,0,0,1,1.48-5.2,4.59,4.59,0,0,1,8.09,0,10,10,0,0,1,0,10.41,4.59,4.59,0,0,1-8.09,0A9.5,9.5,0,0,1,94.09,264.67Zm8.61,0a7.2,7.2,0,0,0-1-3.87,2.5,2.5,0,0,0-2-1.33,2.58,2.58,0,0,0-2.07,1.33,7.64,7.64,0,0,0,0,7.73,2.63,2.63,0,0,0,2.07,1.35,2.55,2.55,0,0,0,2-1.35A7.14,7.14,0,0,0,102.7,264.67Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "99.6053px 264.675px",
        }}
        id="el5rkg24we8j6"
        className="animable"
      />
      <path
        d="M109.57,264.67a9.28,9.28,0,0,1,1.48-5.2,4.58,4.58,0,0,1,8.08,0,10,10,0,0,1,0,10.41,4.58,4.58,0,0,1-8.08,0A9.34,9.34,0,0,1,109.57,264.67Zm8.6,0a7.21,7.21,0,0,0-1-3.87,2.51,2.51,0,0,0-2-1.33A2.58,2.58,0,0,0,113,260.8a7.64,7.64,0,0,0,0,7.73,2.63,2.63,0,0,0,2.07,1.35,2.56,2.56,0,0,0,2-1.35A7.14,7.14,0,0,0,118.17,264.67Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "115.08px 264.675px",
        }}
        id="elw83s7nriup"
        className="animable"
      />
      <path
        d="M129.46,258.25V271.1a1.21,1.21,0,0,1-1.22,1.2,1.18,1.18,0,0,1-1.2-1.2V260.38l-.87.5a1.08,1.08,0,0,1-.61.18,1.22,1.22,0,0,1-.63-2.25l2.68-1.59s.07,0,.09,0,.06,0,.11-.07l.13,0a.12.12,0,0,1,.08,0,.44.44,0,0,1,.16,0h.24a.12.12,0,0,0,.08,0,.22.22,0,0,1,.13,0s.07,0,.09,0,.09.06.13.06l.09.07.09.06s.06.07.08.09a.17.17,0,0,1,.07.09l.09.08,0,.05s0,0,0,.06,0,.07,0,.11,0,.09.05.11,0,.09,0,.11a.19.19,0,0,0,0,.15Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "126.912px 264.72px",
        }}
        id="el703g3dnks34"
        className="animable"
      />
      <path
        d="M136.76,258.25V271.1a1.21,1.21,0,0,1-1.22,1.2,1.18,1.18,0,0,1-1.2-1.2V260.38l-.87.5a1.08,1.08,0,0,1-.61.18,1.22,1.22,0,0,1-.63-2.25l2.68-1.59s.07,0,.09,0,.06,0,.11-.07l.13,0a.12.12,0,0,1,.08,0,.44.44,0,0,1,.16,0h.24a.12.12,0,0,0,.08,0,.22.22,0,0,1,.13,0s.07,0,.09,0,.09.06.13.06l.09.07.09.06s.06.07.08.09a.17.17,0,0,1,.07.09l.09.08,0,.05s0,0,0,.06,0,.07,0,.11,0,.09,0,.11,0,.09,0,.11a.19.19,0,0,0,0,.15Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "134.212px 264.72px",
        }}
        id="elm4lsof8c26"
        className="animable"
      />
      <path
        d="M139.53,264.67a9.28,9.28,0,0,1,1.48-5.2,4.59,4.59,0,0,1,8.09,0,10,10,0,0,1,0,10.41,4.59,4.59,0,0,1-8.09,0A9.34,9.34,0,0,1,139.53,264.67Zm8.61,0a7.2,7.2,0,0,0-1-3.87,2.5,2.5,0,0,0-2-1.33A2.58,2.58,0,0,0,143,260.8a7.64,7.64,0,0,0,0,7.73,2.63,2.63,0,0,0,2.07,1.35,2.55,2.55,0,0,0,2-1.35A7.14,7.14,0,0,0,148.14,264.67Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "145.045px 264.675px",
        }}
        id="elah5shihrt"
        className="animable"
      />
      <path
        d="M159.43,258.25V271.1a1.21,1.21,0,0,1-1.22,1.2,1.18,1.18,0,0,1-1.2-1.2V260.38l-.87.5a1.13,1.13,0,0,1-.61.18,1.22,1.22,0,0,1-.63-2.25l2.68-1.59s.06,0,.08,0,.07,0,.11-.07l.13,0a.15.15,0,0,1,.09,0,.39.39,0,0,1,.15,0h.24a.15.15,0,0,0,.09,0,.25.25,0,0,1,.13,0,.12.12,0,0,1,.09,0c.06,0,.08.06.13.06l.08.07.09.06s.07.07.09.09a.23.23,0,0,1,.06.09l.09.08,0,.05s0,0,0,.06,0,.07,0,.11,0,.09,0,.11,0,.09,0,.11a.17.17,0,0,0,.05.15Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "156.882px 264.721px",
        }}
        id="eli3m4cnrjzp"
        className="animable"
      />
      <path
        d="M56,189.2A9.34,9.34,0,0,1,57.44,184a4.58,4.58,0,0,1,8.08,0,10,10,0,0,1,0,10.42,4.58,4.58,0,0,1-8.08,0A9.39,9.39,0,0,1,56,189.2Zm8.6,0a7.24,7.24,0,0,0-1-3.88,2.54,2.54,0,0,0-2-1.33,2.64,2.64,0,0,0-2.07,1.33,7.24,7.24,0,0,0-1.05,3.88,7.17,7.17,0,0,0,1.05,3.86,2.67,2.67,0,0,0,2.07,1.35,2.57,2.57,0,0,0,2-1.35A7.17,7.17,0,0,0,64.56,189.2Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "61.4909px 189.21px",
        }}
        id="elv7j4ikyrcdg"
        className="animable"
      />
      <path
        d="M71.43,189.2A9.34,9.34,0,0,1,72.91,184,4.58,4.58,0,0,1,81,184a10,10,0,0,1,0,10.42,4.58,4.58,0,0,1-8.08,0A9.39,9.39,0,0,1,71.43,189.2Zm8.61,0A7.24,7.24,0,0,0,79,185.32,2.54,2.54,0,0,0,77,184a2.66,2.66,0,0,0-2.07,1.33,7.24,7.24,0,0,0-1,3.88,7.17,7.17,0,0,0,1,3.86A2.69,2.69,0,0,0,77,194.41a2.57,2.57,0,0,0,2-1.35A7.17,7.17,0,0,0,80,189.2Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "76.9466px 189.205px",
        }}
        id="elsmlts8hgcij"
        className="animable"
      />
      <path
        d="M91.33,182.77v12.86a1.22,1.22,0,0,1-1.22,1.2,1.18,1.18,0,0,1-1.2-1.2V184.91l-.88.5a1.06,1.06,0,0,1-.6.17,1.22,1.22,0,0,1-.64-2.24l2.68-1.59s.07,0,.09-.05.07,0,.11-.06l.13,0a.15.15,0,0,1,.09,0,.27.27,0,0,1,.15,0h.24s.06,0,.09,0a.28.28,0,0,1,.13,0,.12.12,0,0,1,.08,0c.07,0,.09.07.14.07a.28.28,0,0,0,.08.06l.09.07s.06.06.09.08L91,182l.09.09,0,0s0,.05,0,.07,0,.06,0,.11,0,.08,0,.11a.87.87,0,0,0,0,.1.18.18,0,0,0,0,.16Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "88.7844px 189.23px",
        }}
        id="elx6bxl3wgiyg"
        className="animable"
      />
      <path
        d="M94.09,189.2A9.5,9.5,0,0,1,95.57,184a4.59,4.59,0,0,1,8.09,0,10,10,0,0,1,0,10.42,4.59,4.59,0,0,1-8.09,0A9.55,9.55,0,0,1,94.09,189.2Zm8.61,0a7.24,7.24,0,0,0-1-3.88,2.54,2.54,0,0,0-2-1.33,2.63,2.63,0,0,0-2.07,1.33,7.24,7.24,0,0,0-1,3.88,7.17,7.17,0,0,0,1,3.86,2.66,2.66,0,0,0,2.07,1.35,2.57,2.57,0,0,0,2-1.35A7.17,7.17,0,0,0,102.7,189.2Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "99.6069px 189.21px",
        }}
        id="elf2z1c6ala"
        className="animable"
      />
      <path
        d="M109.57,189.2a9.34,9.34,0,0,1,1.48-5.21,4.58,4.58,0,0,1,8.08,0,10,10,0,0,1,0,10.42,4.58,4.58,0,0,1-8.08,0A9.39,9.39,0,0,1,109.57,189.2Zm8.6,0a7.24,7.24,0,0,0-1-3.88,2.56,2.56,0,0,0-2-1.33,2.63,2.63,0,0,0-2.07,1.33,7.24,7.24,0,0,0-1,3.88,7.17,7.17,0,0,0,1,3.86,2.66,2.66,0,0,0,2.07,1.35,2.59,2.59,0,0,0,2-1.35A7.17,7.17,0,0,0,118.17,189.2Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "115.082px 189.2px",
        }}
        id="elsea0r0phzrj"
        className="animable"
      />
      <path
        d="M129.46,182.77v12.86a1.21,1.21,0,0,1-1.22,1.2,1.19,1.19,0,0,1-1.2-1.2V184.91l-.87.5a1.07,1.07,0,0,1-.61.17,1.22,1.22,0,0,1-.63-2.24l2.68-1.59s.07,0,.09-.05.06,0,.11-.06l.13,0a.12.12,0,0,1,.08,0,.3.3,0,0,1,.16,0h.24a.09.09,0,0,0,.08,0,.22.22,0,0,1,.13,0,.13.13,0,0,1,.09,0c.07,0,.09.07.13.07l.09.06.09.07s.06.06.08.08a.31.31,0,0,1,.07.09l.09.09,0,0s0,.05,0,.07a.21.21,0,0,1,0,.11s0,.08.05.11a.87.87,0,0,0,0,.1.22.22,0,0,0,0,.16Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "126.919px 189.23px",
        }}
        id="el9et986ldovt"
        className="animable"
      />
      <path
        d="M136.76,182.77v12.86a1.21,1.21,0,0,1-1.22,1.2,1.19,1.19,0,0,1-1.2-1.2V184.91l-.87.5a1.07,1.07,0,0,1-.61.17,1.22,1.22,0,0,1-.63-2.24l2.68-1.59s.07,0,.09-.05.06,0,.11-.06l.13,0a.12.12,0,0,1,.08,0,.3.3,0,0,1,.16,0h.24a.09.09,0,0,0,.08,0,.22.22,0,0,1,.13,0,.13.13,0,0,1,.09,0c.07,0,.09.07.13.07l.09.06.09.07s.06.06.08.08a.31.31,0,0,1,.07.09l.09.09,0,0s0,.05,0,.07a.21.21,0,0,1,0,.11s0,.08,0,.11a.87.87,0,0,0,0,.1.22.22,0,0,0,0,.16Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "134.219px 189.23px",
        }}
        id="elqekeb3joo7o"
        className="animable"
      />
      <path
        d="M139.53,189.2A9.34,9.34,0,0,1,141,184a4.59,4.59,0,0,1,8.09,0,10,10,0,0,1,0,10.42,4.59,4.59,0,0,1-8.09,0A9.39,9.39,0,0,1,139.53,189.2Zm8.61,0a7.24,7.24,0,0,0-1-3.88,2.54,2.54,0,0,0-2-1.33,2.63,2.63,0,0,0-2.07,1.33A7.24,7.24,0,0,0,142,189.2a7.17,7.17,0,0,0,1.05,3.86,2.66,2.66,0,0,0,2.07,1.35,2.57,2.57,0,0,0,2-1.35A7.17,7.17,0,0,0,148.14,189.2Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "145.041px 189.21px",
        }}
        id="elllny0dxzws"
        className="animable"
      />
      <path
        d="M159.43,182.77v12.86a1.22,1.22,0,0,1-1.22,1.2,1.18,1.18,0,0,1-1.2-1.2V184.91l-.87.5a1.11,1.11,0,0,1-.61.17,1.22,1.22,0,0,1-.63-2.24l2.68-1.59s.06,0,.08-.05.07,0,.11-.06l.13,0a.15.15,0,0,1,.09,0,.27.27,0,0,1,.15,0h.24s.07,0,.09,0a.25.25,0,0,1,.13,0,.16.16,0,0,1,.09,0c.06,0,.08.07.13.07a.28.28,0,0,0,.08.06l.09.07a.24.24,0,0,1,.09.08l.06.09.09.09,0,0s0,.05,0,.07a.21.21,0,0,1,0,.11s0,.08,0,.11a.87.87,0,0,0,0,.1.18.18,0,0,0,.05.16Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "156.889px 189.23px",
        }}
        id="elcs4oh9b73h"
        className="animable"
      />
      <polygon
        points="397.74 172.59 326.22 155.8 347.77 63.98 400.88 76.45 415.19 98.27 397.74 172.59"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "370.705px 118.285px",
        }}
        id="eli13i216lqf"
        className="animable"
      />
      <polygon
        points="400.88 76.45 415.19 98.27 396.77 93.94 400.88 76.45"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "405.98px 87.36px",
        }}
        id="el4cumfchg9pj"
        className="animable"
      />
      <g id="el4ke3uff4gxx">
        <rect
          x={350.32}
          y={85.66}
          width={40.85}
          height={2.42}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "370.745px 86.87px",
            transform: "rotate(-166.79deg)",
          }}
          className="animable"
        />
      </g>
      <g id="ell6sdiqob35">
        <rect
          x={348.92}
          y={91.61}
          width={40.85}
          height={2.42}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "369.345px 92.82px",
            transform: "rotate(-166.79deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el8mbkpqgq38k">
        <rect
          x={347.52}
          y={97.56}
          width={40.85}
          height={2.42}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "367.945px 98.77px",
            transform: "rotate(-166.79deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elrsq7a0w5so">
        <rect
          x={345.87}
          y={105.68}
          width={59.85}
          height={2.42}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "375.795px 106.89px",
            transform: "rotate(-166.79deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elnql68kyv1dl">
        <rect
          x={344.48}
          y={111.63}
          width={59.85}
          height={2.42}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "374.405px 112.84px",
            transform: "rotate(-166.79deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elflryd89l3gg">
        <rect
          x={343.08}
          y={117.59}
          width={59.85}
          height={2.42}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "373.005px 118.8px",
            transform: "rotate(-166.79deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el87wlbw4tt8n">
        <rect
          x={341.68}
          y={123.54}
          width={59.85}
          height={2.42}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "371.605px 124.75px",
            transform: "rotate(-166.79deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el96rvpwgiw">
        <rect
          x={340.29}
          y={129.49}
          width={59.85}
          height={2.42}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "370.215px 130.7px",
            transform: "rotate(-166.79deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elrpboqs9phkn">
        <rect
          x={338.89}
          y={135.44}
          width={59.85}
          height={2.42}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "368.815px 136.65px",
            transform: "rotate(-166.79deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el9fkkteocdcg">
        <rect
          x={337.49}
          y={141.39}
          width={59.85}
          height={2.42}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "367.415px 142.6px",
            transform: "rotate(-166.79deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el2u517qk8v8">
        <rect
          x={336.09}
          y={147.34}
          width={59.85}
          height={2.42}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "366.015px 148.55px",
            transform: "rotate(-166.79deg)",
          }}
          className="animable"
        />
      </g>
      <g id="els2u22uhqry">
        <rect
          x={334.7}
          y={153.29}
          width={59.85}
          height={2.42}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "364.625px 154.5px",
            transform: "rotate(-166.79deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eleqytocof2i">
        <circle
          cx={394.46}
          cy={170.66}
          r={11.34}
          style={{
            fill: "rgb(199, 199, 199)",
            transformOrigin: "394.46px 170.66px",
            transform: "rotate(-82.96deg)",
          }}
          className="animable"
        />
      </g>
      <rect
        x={389.43}
        y={169.28}
        width={10.07}
        height={7.88}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "394.465px 173.22px",
        }}
        id="elz5bs8dixeue"
        className="animable"
      />
      <path
        d="M398.49,167.69h-1v-.93a3,3,0,0,0-6,0v4.08h-1v-4.08a4,4,0,0,1,8.05,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "394.515px 166.788px",
        }}
        id="el9aczicbcbxc"
        className="animable"
      />
      <path
        d="M394.89,172.7l.42,2h-1.79l.42-2a.89.89,0,0,1-.42-.75.9.9,0,1,1,1.79,0A.89.89,0,0,1,394.89,172.7Z"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "394.415px 172.828px",
        }}
        id="elh4r5og359s8"
        className="animable"
      />
      <g id="elm6n1q3ep2ui">
        <rect
          x={121.86}
          y={54.51}
          width={82.22}
          height={59.65}
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "162.97px 84.335px",
            transform: "rotate(-170.256deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eldca1dsktyhe">
        <rect
          x={125.28}
          y={57.94}
          width={75.39}
          height={52.8}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "162.975px 84.34px",
            transform: "rotate(-170.256deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M136.36,68.33a6.25,6.25,0,1,0,7.2-5.13A6.24,6.24,0,0,0,136.36,68.33Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "142.524px 69.3628px",
        }}
        id="elpgjhtru5pz"
        className="animable"
      />
      <polygon
        points="145.56 108.08 195.82 116.5 176.39 78.23 145.56 108.08"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "170.69px 97.365px",
        }}
        id="el6j7057v2oq5"
        className="animable"
      />
      <polygon
        points="125.85 104.8 167.35 111.75 150.31 86.09 125.85 104.8"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "146.6px 98.92px",
        }}
        id="elg0nboox9jv4"
        className="animable"
      />
      <g id="ele4jde382uf">
        <rect
          x={94.2}
          y={65.9}
          width={82.22}
          height={59.65}
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "135.31px 95.725px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elo9mscwvjwz">
        <rect
          x={97.62}
          y={69.33}
          width={75.39}
          height={52.8}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "135.315px 95.73px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M106.43,84.34a6.25,6.25,0,1,0,6.24-6.25A6.24,6.24,0,0,0,106.43,84.34Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "112.68px 84.34px",
        }}
        id="ely9drpslvcg"
        className="animable"
      />
      <polygon
        points="122.06 122.02 173.02 122.02 147.54 87.49 122.06 122.02"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "147.54px 104.755px",
        }}
        id="ell77gyk0j9u"
        className="animable"
      />
      <polygon
        points="102.08 122.04 144.15 122.04 123.12 99.55 102.08 122.04"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "123.115px 110.795px",
        }}
        id="el2zeka83trmv"
        className="animable"
      />
    </g>
    <g
      id="freepik--Floor--inject-2"
      className="animable"
      style={{
        transformOrigin: "250px 441.275px",
      }}
    >
      <polygon
        points="41.54 441.28 93.66 441.03 145.77 440.94 250 440.78 354.23 440.94 406.34 441.03 458.46 441.28 406.34 441.52 354.23 441.61 250 441.77 145.77 441.61 93.66 441.52 41.54 441.28"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "250px 441.275px",
        }}
        id="elp0kgtq5ct4"
        className="animable"
      />
    </g>
    <g
      id="freepik--credit-card--inject-2"
      className="animable"
      style={{
        transformOrigin: "342.731px 228.072px",
      }}
    >
      <path
        d="M394.14,195.81l-2.81,59.51a6,6,0,0,1-6.3,5.74l-94.39-4.44a6,6,0,0,1-5.73-6.31l2.8-59.51a5.61,5.61,0,0,1,5.87-5.34l95.22,4.48A5.62,5.62,0,0,1,394.14,195.81Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "339.524px 223.261px",
        }}
        id="elx4yxnsedz1o"
        className="animable"
      />
      <path
        d="M376.39,246.21a5.55,5.55,0,1,1-5.28-5.8A5.55,5.55,0,0,1,376.39,246.21Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "370.846px 245.954px",
        }}
        id="eliifqx5aenl"
        className="animable"
      />
      <path
        d="M383.21,246.53a5.55,5.55,0,1,1-5.28-5.8A5.54,5.54,0,0,1,383.21,246.53Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "377.666px 246.274px",
        }}
        id="el1p8s0027mzn"
        className="animable"
      />
      <g id="elaf10jkqv2c7">
        <rect
          x={296.1}
          y={196.3}
          width={20}
          height={13.82}
          rx={4.2}
          style={{
            fill: "rgb(255, 199, 39)",
            transformOrigin: "306.1px 203.21px",
            transform: "rotate(2.7deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M381.26,207.42l-9-.43a3.79,3.79,0,0,1-3.61-4h0a3.8,3.8,0,0,1,4-3.61l9,.42a3.79,3.79,0,0,1,3.6,4h0A3.79,3.79,0,0,1,381.26,207.42Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "376.95px 203.4px",
        }}
        id="elu4ubno6ov"
        className="animable"
      />
      <g id="ely9y5sa346uo">
        <rect
          x={294.39}
          y={238.56}
          width={50.6}
          height={2.49}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "319.69px 239.805px",
            transform: "rotate(2.7deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elau3qphlixcb">
        <rect
          x={294.14}
          y={243.73}
          width={35.82}
          height={2.49}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "312.05px 244.975px",
            transform: "rotate(2.7deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elp0rqhrw04a">
        <rect
          x={340.04}
          y={198.48}
          width={25.23}
          height={2.49}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "352.655px 199.725px",
            transform: "rotate(2.7deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elndjedef1l4q">
        <rect
          x={295.22}
          y={225.85}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "297.11px 226px",
            transform: "rotate(-87.19deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="299 226.24 295.22 226.06 295.23 225.76 299.01 225.94 299 226.24"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "297.115px 226px",
        }}
        id="elnzbpp0xj1v"
        className="animable"
      />
      <g id="elk7zmtqok17i">
        <rect
          x={295.22}
          y={225.85}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "297.11px 226px",
            transform: "rotate(-42.1deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="298.27 227.5 295.73 224.7 295.95 224.5 298.5 227.3 298.27 227.5"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "297.115px 226px",
        }}
        id="el20zlbn2grlr"
        className="animable"
      />
      <g id="elfqbosrra5op">
        <rect
          x={299.77}
          y={226.08}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "301.66px 226.23px",
            transform: "rotate(-87.19deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="303.54 226.47 299.76 226.28 299.78 225.98 303.56 226.17 303.54 226.47"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "301.66px 226.225px",
        }}
        id="elh3hcvx2596"
        className="animable"
      />
      <g id="el2co9diw56zu">
        <rect
          x={299.77}
          y={226.08}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "301.66px 226.23px",
            transform: "rotate(-42.1deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elz7puz3jc0bh">
        <rect
          x={301.51}
          y={224.33}
          width={0.3}
          height={3.78}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "301.66px 226.22px",
            transform: "rotate(-42.15deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elgshyqin7rg">
        <rect
          x={304.31}
          y={226.3}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "306.2px 226.45px",
            transform: "rotate(-87.19deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="308.09 226.7 304.31 226.51 304.32 226.21 308.1 226.4 308.09 226.7"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "306.205px 226.455px",
        }}
        id="ela01q90fxnes"
        className="animable"
      />
      <g id="el3z1qj5rva25">
        <rect
          x={304.31}
          y={226.3}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "306.2px 226.45px",
            transform: "rotate(-42.1deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elghndl8v59xv">
        <rect
          x={306.06}
          y={224.56}
          width={0.3}
          height={3.78}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "306.21px 226.45px",
            transform: "rotate(-42.15deg)",
          }}
          className="animable"
        />
      </g>
      <g id="ely1jnxq8odmi">
        <rect
          x={308.86}
          y={226.53}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "310.75px 226.68px",
            transform: "rotate(-87.19deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="312.63 226.92 308.85 226.73 308.87 226.44 312.65 226.62 312.63 226.92"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "310.75px 226.68px",
        }}
        id="elcqsm08q8qc"
        className="animable"
      />
      <g id="el4fqz94finiu">
        <rect
          x={308.86}
          y={226.53}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "310.75px 226.68px",
            transform: "rotate(-42.1deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elomfoplsr39p">
        <rect
          x={310.6}
          y={224.79}
          width={0.3}
          height={3.78}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "310.75px 226.68px",
            transform: "rotate(-42.15deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elkiob62rqd3i">
        <rect
          x={318.78}
          y={227.02}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "320.67px 227.17px",
            transform: "rotate(-87.19deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="322.56 227.41 318.78 227.23 318.79 226.93 322.57 227.12 322.56 227.41"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "320.675px 227.17px",
        }}
        id="elmlho85f6vl"
        className="animable"
      />
      <g id="elde815y5k4e">
        <rect
          x={318.78}
          y={227.02}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "320.67px 227.17px",
            transform: "rotate(-42.1deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elazuormuedgh">
        <rect
          x={320.52}
          y={225.28}
          width={0.3}
          height={3.78}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "320.67px 227.17px",
            transform: "rotate(-42.15deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elwwa2aewadgd">
        <rect
          x={323.33}
          y={227.25}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "325.22px 227.4px",
            transform: "rotate(-87.19deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="327.1 227.64 323.32 227.45 323.34 227.15 327.12 227.34 327.1 227.64"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "325.22px 227.395px",
        }}
        id="eld14yjqsf4"
        className="animable"
      />
      <g id="elhy6opfnys6b">
        <rect
          x={323.33}
          y={227.25}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "325.22px 227.4px",
            transform: "rotate(-42.1deg)",
          }}
          className="animable"
        />
      </g>
      <g id="ele99epc01k65">
        <rect
          x={325.07}
          y={225.51}
          width={0.3}
          height={3.78}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "325.22px 227.4px",
            transform: "rotate(-42.15deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elop9wtjo7tf">
        <rect
          x={327.87}
          y={227.47}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "329.76px 227.62px",
            transform: "rotate(-87.19deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="331.65 227.87 327.87 227.68 327.88 227.38 331.66 227.57 331.65 227.87"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "329.765px 227.625px",
        }}
        id="el5p5yh113ilu"
        className="animable"
      />
      <polygon
        points="328.46 229 328.26 228.78 331.07 226.24 331.27 226.47 328.46 229"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "329.765px 227.62px",
        }}
        id="eluh2aomgk35"
        className="animable"
      />
      <g id="elumurb10wrje">
        <rect
          x={329.62}
          y={225.73}
          width={0.3}
          height={3.78}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "329.77px 227.62px",
            transform: "rotate(-42.15deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elgp0hm1hw5gq">
        <rect
          x={332.42}
          y={227.7}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "334.31px 227.85px",
            transform: "rotate(-87.19deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="336.19 228.09 332.41 227.91 332.43 227.6 336.21 227.79 336.19 228.09"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "334.31px 227.845px",
        }}
        id="eluuyblp9zf1h"
        className="animable"
      />
      <g id="elaxsu06j4efd">
        <rect
          x={332.42}
          y={227.7}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "334.31px 227.85px",
            transform: "rotate(-42.1deg)",
          }}
          className="animable"
        />
      </g>
      <g id="ely1jm3bnka7a">
        <rect
          x={334.16}
          y={225.96}
          width={0.3}
          height={3.78}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "334.31px 227.85px",
            transform: "rotate(-42.15deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elttqt09aqdif">
        <rect
          x={342.34}
          y={228.19}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "344.23px 228.34px",
            transform: "rotate(-87.19deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="346.12 228.59 342.34 228.4 342.35 228.1 346.13 228.29 346.12 228.59"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "344.235px 228.345px",
        }}
        id="elgf1vf76plyw"
        className="animable"
      />
      <g id="el8fvlwqy59b2">
        <rect
          x={342.34}
          y={228.19}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "344.23px 228.34px",
            transform: "rotate(-42.1deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elhwvgo9m39oc">
        <rect
          x={344.08}
          y={226.45}
          width={0.3}
          height={3.78}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "344.23px 228.34px",
            transform: "rotate(-42.15deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elycb7p0i5pvc">
        <rect
          x={346.89}
          y={228.42}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "348.78px 228.57px",
            transform: "rotate(-87.19deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="350.66 228.81 346.88 228.63 346.9 228.32 350.68 228.51 350.66 228.81"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "348.78px 228.565px",
        }}
        id="elh9l0mh3vby"
        className="animable"
      />
      <g id="eltavtxszpwk">
        <rect
          x={346.89}
          y={228.42}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "348.78px 228.57px",
            transform: "rotate(-42.1deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eleo97bgqtciq">
        <rect
          x={348.63}
          y={226.68}
          width={0.3}
          height={3.78}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "348.78px 228.57px",
            transform: "rotate(-42.15deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eltqpm68s4ea">
        <rect
          x={351.43}
          y={228.64}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "353.32px 228.79px",
            transform: "rotate(-87.19deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="355.21 229.04 351.43 228.85 351.44 228.55 355.22 228.74 355.21 229.04"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "353.325px 228.795px",
        }}
        id="eli8hvv612sl"
        className="animable"
      />
      <polygon
        points="352.02 230.18 351.82 229.95 354.63 227.41 354.83 227.64 352.02 230.18"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "353.325px 228.795px",
        }}
        id="elcp0p1m3h7xg"
        className="animable"
      />
      <g id="eleblr0sc7ewi">
        <rect
          x={353.18}
          y={226.9}
          width={0.3}
          height={3.78}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "353.33px 228.79px",
            transform: "rotate(-42.15deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elsks1zj8s3wj">
        <rect
          x={355.98}
          y={228.87}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "357.87px 229.02px",
            transform: "rotate(-87.19deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="359.75 229.26 355.98 229.08 355.99 228.78 359.77 228.96 359.75 229.26"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "357.875px 229.02px",
        }}
        id="elu0zymfqrc2e"
        className="animable"
      />
      <polygon
        points="356.57 230.4 356.37 230.18 359.17 227.64 359.38 227.86 356.57 230.4"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "357.875px 229.02px",
        }}
        id="elr2zdxm9rqt"
        className="animable"
      />
      <g id="eln5g9mabtgk">
        <rect
          x={357.72}
          y={227.13}
          width={0.3}
          height={3.78}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "357.87px 229.02px",
            transform: "rotate(-42.15deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elluzqroymwe9">
        <rect
          x={365.9}
          y={229.36}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "367.79px 229.51px",
            transform: "rotate(-87.19deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="369.68 229.76 365.9 229.57 365.91 229.27 369.69 229.46 369.68 229.76"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "367.795px 229.515px",
        }}
        id="elcig7bdx9s6f"
        className="animable"
      />
      <g id="elt494gz74eul">
        <rect
          x={365.9}
          y={229.36}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "367.79px 229.51px",
            transform: "rotate(-42.1deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elrzw3brgzsds">
        <rect
          x={367.64}
          y={227.62}
          width={0.3}
          height={3.78}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "367.79px 229.51px",
            transform: "rotate(-42.15deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el2s35itcil2a">
        <rect
          x={370.45}
          y={229.59}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "372.34px 229.74px",
            transform: "rotate(-87.19deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="374.22 229.98 370.44 229.79 370.46 229.5 374.24 229.68 374.22 229.98"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "372.34px 229.74px",
        }}
        id="els0roymp5clh"
        className="animable"
      />
      <g id="el3l1dzaew92c">
        <rect
          x={370.45}
          y={229.59}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "372.34px 229.74px",
            transform: "rotate(-42.1deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elyoc55tshpki">
        <rect
          x={372.19}
          y={227.85}
          width={0.3}
          height={3.78}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "372.34px 229.74px",
            transform: "rotate(-42.15deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elrsebqr9g5bp">
        <rect
          x={374.99}
          y={229.82}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "376.88px 229.97px",
            transform: "rotate(-87.19deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="378.77 230.21 374.99 230.02 375 229.72 378.78 229.91 378.77 230.21"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "376.885px 229.965px",
        }}
        id="elunawwvd9mfe"
        className="animable"
      />
      <g id="elk6zrdrk9zac">
        <rect
          x={375}
          y={229.82}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "376.89px 229.97px",
            transform: "rotate(-42.1deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="378.05 231.47 375.5 228.66 375.73 228.46 378.27 231.27 378.05 231.47"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "376.885px 229.965px",
        }}
        id="elum7vgfxss7e"
        className="animable"
      />
      <g id="el5ezcp5kuhfp">
        <rect
          x={379.54}
          y={230.04}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "381.43px 230.19px",
            transform: "rotate(-87.19deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="383.31 230.44 379.54 230.25 379.55 229.95 383.33 230.13 383.31 230.44"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "381.435px 230.195px",
        }}
        id="elcqm0htcto0q"
        className="animable"
      />
      <g id="el9pn54l4vx99">
        <rect
          x={379.54}
          y={230.04}
          width={3.78}
          height={0.3}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "381.43px 230.19px",
            transform: "rotate(-42.1deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="382.59 231.69 380.05 228.89 380.27 228.69 382.81 231.49 382.59 231.69"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "381.43px 230.19px",
        }}
        id="el5f74yyz09g3"
        className="animable"
      />
      <circle
        cx={389.22}
        cy={259.35}
        r={11.34}
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "389.22px 259.35px",
        }}
        id="elq7h39v1t4lm"
        className="animable"
      />
      <rect
        x={384.19}
        y={257.97}
        width={10.07}
        height={7.88}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "389.225px 261.91px",
        }}
        id="eldvmyj7byx4d"
        className="animable"
      />
      <path
        d="M393.25,256.38h-1v-.93a3,3,0,0,0-6,0v4.08h-1v-4.08a4,4,0,0,1,8.05,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "389.275px 255.478px",
        }}
        id="elwwmdflex8f"
        className="animable"
      />
      <path
        d="M389.65,261.39l.42,2h-1.79l.42-2a.89.89,0,0,1-.42-.75.9.9,0,1,1,1.79,0A.89.89,0,0,1,389.65,261.39Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "389.175px 261.518px",
        }}
        id="elr3d6kvcgmt"
        className="animable"
      />
    </g>
    <g
      id="freepik--Icon--inject-2"
      className="animable"
      style={{
        transformOrigin: "303.411px 99.7588px",
      }}
    >
      <g
        id="freepik--65yqnltif--inject-2"
        className="animable"
        style={{
          transformOrigin: "303.411px 99.7588px",
        }}
      >
        <path
          d="M305.84,153.35H301a5.62,5.62,0,0,0-.7-.1,51.76,51.76,0,0,1-14.1-2.75,52.29,52.29,0,0,1-24.55-17.24c-10-12.61-13.63-26.94-11-42.78a51.53,51.53,0,0,1,14.67-28.34,52.83,52.83,0,0,1,36.38-15.93A50.26,50.26,0,0,1,316,47.68a53,53,0,0,1,29.37,18.77A52.47,52.47,0,0,1,356.69,94c.12,1.12.21,2.24.31,3.36v4.82c0,.21-.08.42-.09.64a50.72,50.72,0,0,1-2,11.6,53.17,53.17,0,0,1-23.22,30.81,51.89,51.89,0,0,1-22.59,7.8C308,153.15,306.94,153.24,305.84,153.35ZM326.1,73.92a68.44,68.44,0,0,1,5,24h22.15a49.67,49.67,0,0,0-12.52-31.19c-2.45,1.35-4.76,2.73-7.17,3.92S328.63,72.81,326.1,73.92Zm-60-7.19a49.78,49.78,0,0,0-12.48,31.16h22.17a68.84,68.84,0,0,1,5-24c-2.54-1.11-5.07-2.1-7.5-3.29S268.51,68.07,266.06,66.73Zm75.54,65.15a49.83,49.83,0,0,0,11.68-30.23H331.12a68.7,68.7,0,0,1-4.45,22.53A69.44,69.44,0,0,1,341.6,131.88Zm-88-30.24a49.54,49.54,0,0,0,11.54,30.05c2.5-1.42,4.88-2.89,7.38-4.17s5.07-2.33,7.63-3.5a68.8,68.8,0,0,1-4.39-22.38Zm48-24.07a69.6,69.6,0,0,1-17.23-2.65,64.64,64.64,0,0,0-4.88,23h22.11Zm25.86,20.32a64.48,64.48,0,0,0-4.94-23,69.16,69.16,0,0,1-17.16,2.65V97.89Zm-25.86,3.76H279.45A64.71,64.71,0,0,0,283.68,123a68.93,68.93,0,0,1,17.88-2.89Zm3.75,18.46A67.75,67.75,0,0,1,323.18,123a64.46,64.46,0,0,0,4.23-21.35h-22.1Zm-23.73,7.42a64.53,64.53,0,0,0-13.86,7A49.24,49.24,0,0,0,281,144.31c4.8,2.4,13.24,5,15.68,4.8A68.93,68.93,0,0,1,281.58,127.53Zm57.5,7.14a66,66,0,0,0-13.86-7,70,70,0,0,1-15,21.45A49.67,49.67,0,0,0,339.08,134.67Zm-37.54,13.84V123.85c-3.43-.24-14.48,1.52-16.41,2.62A64.58,64.58,0,0,0,301.54,148.51Zm3.77,0a64.75,64.75,0,0,0,16.38-22c-3-1.27-13.77-3-16.38-2.67Zm-23-78.14a70.05,70.05,0,0,1,14.32-20,49.62,49.62,0,0,0-27.86,13.49A64.87,64.87,0,0,0,282.33,70.39Zm42.19,0c3-.74,11.75-5,13.46-6.56A49.4,49.4,0,0,0,310.24,50.4,70.25,70.25,0,0,1,324.52,70.35Zm-38.64,1.14a64.35,64.35,0,0,0,15.67,2.38V51A64.71,64.71,0,0,0,285.88,71.49Zm19.43,2.38a64.11,64.11,0,0,0,15.62-2.36A65.76,65.76,0,0,0,305.31,51Z"
          style={{
            fill: "rgb(255, 199, 39)",
            transformOrigin: "303.411px 99.7588px",
          }}
          id="el3tsf90bskfl"
          className="animable"
        />
      </g>
      <g id="freepik--65yqnltif--inject-2">
        <g
          style={{
            opacity: 0.7,
            transformOrigin: "303.411px 99.7588px",
          }}
          className="animable"
        >
          <path
            d="M305.84,153.35H301a5.62,5.62,0,0,0-.7-.1,51.76,51.76,0,0,1-14.1-2.75,52.29,52.29,0,0,1-24.55-17.24c-10-12.61-13.63-26.94-11-42.78a51.53,51.53,0,0,1,14.67-28.34,52.83,52.83,0,0,1,36.38-15.93A50.26,50.26,0,0,1,316,47.68a53,53,0,0,1,29.37,18.77A52.47,52.47,0,0,1,356.69,94c.12,1.12.21,2.24.31,3.36v4.82c0,.21-.08.42-.09.64a50.72,50.72,0,0,1-2,11.6,53.17,53.17,0,0,1-23.22,30.81,51.89,51.89,0,0,1-22.59,7.8C308,153.15,306.94,153.24,305.84,153.35ZM326.1,73.92a68.44,68.44,0,0,1,5,24h22.15a49.67,49.67,0,0,0-12.52-31.19c-2.45,1.35-4.76,2.73-7.17,3.92S328.63,72.81,326.1,73.92Zm-60-7.19a49.78,49.78,0,0,0-12.48,31.16h22.17a68.84,68.84,0,0,1,5-24c-2.54-1.11-5.07-2.1-7.5-3.29S268.51,68.07,266.06,66.73Zm75.54,65.15a49.83,49.83,0,0,0,11.68-30.23H331.12a68.7,68.7,0,0,1-4.45,22.53A69.44,69.44,0,0,1,341.6,131.88Zm-88-30.24a49.54,49.54,0,0,0,11.54,30.05c2.5-1.42,4.88-2.89,7.38-4.17s5.07-2.33,7.63-3.5a68.8,68.8,0,0,1-4.39-22.38Zm48-24.07a69.6,69.6,0,0,1-17.23-2.65,64.64,64.64,0,0,0-4.88,23h22.11Zm25.86,20.32a64.48,64.48,0,0,0-4.94-23,69.16,69.16,0,0,1-17.16,2.65V97.89Zm-25.86,3.76H279.45A64.71,64.71,0,0,0,283.68,123a68.93,68.93,0,0,1,17.88-2.89Zm3.75,18.46A67.75,67.75,0,0,1,323.18,123a64.46,64.46,0,0,0,4.23-21.35h-22.1Zm-23.73,7.42a64.53,64.53,0,0,0-13.86,7A49.24,49.24,0,0,0,281,144.31c4.8,2.4,13.24,5,15.68,4.8A68.93,68.93,0,0,1,281.58,127.53Zm57.5,7.14a66,66,0,0,0-13.86-7,70,70,0,0,1-15,21.45A49.67,49.67,0,0,0,339.08,134.67Zm-37.54,13.84V123.85c-3.43-.24-14.48,1.52-16.41,2.62A64.58,64.58,0,0,0,301.54,148.51Zm3.77,0a64.75,64.75,0,0,0,16.38-22c-3-1.27-13.77-3-16.38-2.67Zm-23-78.14a70.05,70.05,0,0,1,14.32-20,49.62,49.62,0,0,0-27.86,13.49A64.87,64.87,0,0,0,282.33,70.39Zm42.19,0c3-.74,11.75-5,13.46-6.56A49.4,49.4,0,0,0,310.24,50.4,70.25,70.25,0,0,1,324.52,70.35Zm-38.64,1.14a64.35,64.35,0,0,0,15.67,2.38V51A64.71,64.71,0,0,0,285.88,71.49Zm19.43,2.38a64.11,64.11,0,0,0,15.62-2.36A65.76,65.76,0,0,0,305.31,51Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "303.411px 99.7588px",
            }}
            id="elsb41a2mdzb"
            className="animable"
          />
        </g>
      </g>
    </g>
    <g
      id="freepik--Password--inject-2"
      className="animable"
      style={{
        transformOrigin: "139.14px 148.18px",
      }}
    >
      <rect
        x={86}
        y={139.05}
        width={120.34}
        height={18.24}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "146.17px 148.17px",
        }}
        id="elri47anb0et"
        className="animable"
      />
      <path
        d="M206.84,157.79H85.5V138.55H206.84Zm-120.34-1H205.84V139.55H86.5Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "146.17px 148.17px",
        }}
        id="elwqrxfcw7lun"
        className="animable"
      />
      <rect
        x={105.71}
        y={144.29}
        width={1}
        height={8.35}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "106.21px 148.465px",
        }}
        id="elxhiswexqgah"
        className="animable"
      />
      <rect
        x={102.03}
        y={147.97}
        width={8.35}
        height={1}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "106.205px 148.47px",
        }}
        id="eloqb149c2tyi"
        className="animable"
      />
      <g id="elxbjyk35mvzj">
        <rect
          x={102.03}
          y={147.97}
          width={8.35}
          height={1}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "106.205px 148.47px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elo8x35o7bt6">
        <rect
          x={105.71}
          y={144.29}
          width={1}
          height={8.35}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "106.21px 148.465px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <rect
        x={119.88}
        y={144.29}
        width={1}
        height={8.35}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "120.38px 148.465px",
        }}
        id="elczgbpnf7gc"
        className="animable"
      />
      <rect
        x={116.21}
        y={147.97}
        width={8.35}
        height={1}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "120.385px 148.47px",
        }}
        id="elo5r8mbb79u"
        className="animable"
      />
      <g id="elvq3y2kcmjn">
        <rect
          x={116.21}
          y={147.97}
          width={8.35}
          height={1}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "120.385px 148.47px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elxd52o7dy0za">
        <rect
          x={119.89}
          y={144.29}
          width={1}
          height={8.35}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "120.39px 148.465px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <rect
        x={134.06}
        y={144.29}
        width={1}
        height={8.35}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "134.56px 148.465px",
        }}
        id="el8z94qylcgwt"
        className="animable"
      />
      <rect
        x={130.39}
        y={147.97}
        width={8.35}
        height={1}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "134.565px 148.47px",
        }}
        id="eldm7rwgv7ml"
        className="animable"
      />
      <g id="eljbkxbgmz8k">
        <rect
          x={130.38}
          y={147.97}
          width={8.35}
          height={1}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "134.555px 148.47px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elre0u4r917q">
        <rect
          x={134.06}
          y={144.29}
          width={1}
          height={8.35}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "134.56px 148.465px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <rect
        x={148.24}
        y={144.29}
        width={1}
        height={8.35}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "148.74px 148.465px",
        }}
        id="elje5za47gswg"
        className="animable"
      />
      <rect
        x={144.56}
        y={147.97}
        width={8.35}
        height={1}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "148.735px 148.47px",
        }}
        id="elvqu5g8xwzbn"
        className="animable"
      />
      <g id="elb9okubcyllf">
        <rect
          x={144.56}
          y={147.97}
          width={8.35}
          height={1}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "148.735px 148.47px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el2n0hm0qbvue">
        <rect
          x={148.24}
          y={144.29}
          width={1}
          height={8.35}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "148.74px 148.465px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <rect
        x={162.42}
        y={144.29}
        width={1}
        height={8.35}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "162.92px 148.465px",
        }}
        id="elfkct4excbqk"
        className="animable"
      />
      <rect
        x={158.74}
        y={147.97}
        width={8.35}
        height={1}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "162.915px 148.47px",
        }}
        id="el1csieuup68x"
        className="animable"
      />
      <g id="elwv4yc54a2wo">
        <rect
          x={158.74}
          y={147.97}
          width={8.35}
          height={1}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "162.915px 148.47px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elim4fm6ut2ol">
        <rect
          x={162.42}
          y={144.29}
          width={1}
          height={8.35}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "162.92px 148.465px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <rect
        x={176.59}
        y={144.29}
        width={1}
        height={8.35}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "177.09px 148.465px",
        }}
        id="elschdzwrqjsd"
        className="animable"
      />
      <rect
        x={172.92}
        y={147.97}
        width={8.35}
        height={1}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "177.095px 148.47px",
        }}
        id="el51cek91txa"
        className="animable"
      />
      <g id="elmma8x5zs56n">
        <rect
          x={172.92}
          y={147.97}
          width={8.35}
          height={1}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "177.095px 148.47px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elibv3qqxvrw">
        <rect
          x={176.59}
          y={144.29}
          width={1}
          height={8.35}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "177.09px 148.465px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M94.12,148.17a11.34,11.34,0,1,1-11.33-11.33A11.34,11.34,0,0,1,94.12,148.17Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "82.78px 148.18px",
        }}
        id="el5yqrpc2r7f9"
        className="animable"
      />
      <rect
        x={77.75}
        y={146.79}
        width={10.07}
        height={7.88}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "82.785px 150.73px",
        }}
        id="elhhe0rg41cc9"
        className="animable"
      />
      <path
        d="M86.81,145.21h-1v-.94a3,3,0,0,0-6,0v4.08h-1v-4.08a4,4,0,0,1,8,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "82.81px 144.31px",
        }}
        id="el15i0c2wsjq7"
        className="animable"
      />
      <path
        d="M83.21,150.22l.42,2H81.84l.43-2a.89.89,0,1,1,1.36-.76A.9.9,0,0,1,83.21,150.22Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "82.735px 150.397px",
        }}
        id="eln41zlnhtuai"
        className="animable"
      />
    </g>
    <g
      id="freepik--Shield--inject-2"
      className="animable animator-active"
      style={{
        transformOrigin: "250.005px 162.135px",
      }}
    >
      <path
        d="M310.68,125.31s-8.87,71-23.43,86.93C269.76,231.4,257.07,228.6,250,238.48c0,0,0,0,0,0-7.06-9.89-19.75-7.07-37.25-26.25-14.57-16-23.42-86.93-23.42-86.93C227.71,106.68,250,85.79,250,85.79l0,0C250.69,86.45,272.89,107,310.68,125.31Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "250.005px 162.135px",
        }}
        id="elr4xihqac5n"
        className="animable"
      />
      <g id="el8owmp5da8q5">
        <path
          d="M250,85.81V238.48s0,0,0,0c-7.06-9.89-19.75-7.07-37.25-26.25-14.57-16-23.42-86.93-23.42-86.93C227.71,106.68,250,85.79,250,85.79Z"
          style={{
            opacity: 0.2,
            transformOrigin: "219.665px 162.135px",
          }}
          className="animable"
        />
      </g>
      <g id="el502fogcexm9">
        <path
          d="M310.68,125.31s-8.87,71-23.43,86.93C269.76,231.4,257.07,228.6,250,238.48V85.81C250.69,86.45,272.89,107,310.68,125.31Z"
          style={{
            opacity: 0.1,
            transformOrigin: "280.34px 162.145px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M301.26,131s-7.48,59.95-19.79,73.44C266.69,220.68,256,218.3,250,226.64c-6-8.34-16.69-6-31.48-22.17C206.21,191,198.74,131,198.74,131,231.16,115.28,250,97.64,250,97.64S268.84,115.28,301.26,131Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "250px 162.14px",
        }}
        id="elyw14k187wl"
        className="animable"
      />
      <g id="elvudcbst8wp">
        <path
          d="M250.37,98V226.15a5.85,5.85,0,0,0-.38.49c-6-8.34-16.69-6-31.48-22.17C206.21,191,198.74,131,198.74,131,231.17,115.28,250,97.64,250,97.64Z"
          style={{
            opacity: 0.1,
            transformOrigin: "224.555px 162.14px",
          }}
          className="animable"
        />
      </g>
      <rect
        x={231.55}
        y={159.7}
        width={36.9}
        height={28.86}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "250px 174.13px",
        }}
        id="el5nn1wmg7xyw"
        className="animable"
      />
      <path
        d="M264.76,153.88H261v-3.41a11,11,0,0,0-22,0v14.95h-3.75V150.47a14.76,14.76,0,0,1,29.51,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "250.005px 150.757px",
        }}
        id="elt361h84al8q"
        className="animable"
      />
      <path
        d="M251.56,172.24l1.54,7.33h-6.55l1.54-7.33a3.28,3.28,0,1,1,3.47,0Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "249.825px 172.873px",
        }}
        id="el199equlcacr"
        className="animable"
      />
    </g>
    <g
      id="freepik--Character--inject-2"
      className="animable"
      style={{
        transformOrigin: "214.935px 307.26px",
      }}
    >
      <path
        d="M161.26,291.82c9.62,41.86,29.52,92,38,100.18,10.74,10.3,81.83,29.19,85.38,29.57,8.78.89,31.22-58.44,21.87-59.79-14.24-2.06-70.83-8.75-73.94-11.94s-25.06-30.87-47.52-62C169.56,266.34,155.3,265.85,161.26,291.82Z"
        style={{
          fill: "rgb(235, 148, 129)",
          transformOrigin: "234.31px 346.792px",
        }}
        id="elem6zh92de8f"
        className="animable"
      />
      <path
        d="M276.11,418.1c1,.49,14.63,7.37,23.09,9.82,6.31,1.83,24.61,6.6,27.31-.14,1.85-4.6-8.58-8.67-15.89-11.72-3.86-1.6-8.6-5.64-7.47-8.11,2-4.33,11.9,0,18.42,3.81,12.61,7.32,29.41,20.51,37.11,14.11,2.91-2.42-.47-6.82-5.42-11.14,8,5,16.59,9.16,19.93,6.6,1.91-1.45,2-3.45.93-5.74,2.48.81,4.67.65,6.2-1,2.61-2.73,1.34-6.76-1.52-10.89a3.1,3.1,0,0,0,2.37-1.56c5.52-8.42-22.12-32.6-31.69-34.87-28.13-6.68-57.77-5.44-57.77-5.44C280.66,370.7,276.09,418.09,276.11,418.1Z"
        style={{
          fill: "rgb(235, 148, 129)",
          transformOrigin: "329.001px 396.505px",
        }}
        id="elx4r5zj0kmsc"
        className="animable"
      />
      <path
        d="M317.48,392.21a47.78,47.78,0,0,1,18.76,7.22c7.13,4.71,11.86,10.22,17.85,15.77.15.14-.07.39-.23.24-2.85-2.63-14.66-12.55-17.71-14.8a45.58,45.58,0,0,0-18.7-8.32C317.38,392.31,317.4,392.2,317.48,392.21Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "335.774px 403.848px",
        }}
        id="ela6ot5if2md"
        className="animable"
      />
      <path
        d="M322.72,380.27c7.39,2,15,4.07,21.83,7.56,7.51,3.83,13.39,10.07,19.17,16.07q5.2,5.42,10.16,11.05c.14.16-.07.41-.22.25q-8.79-9.31-18.14-18.07a60.05,60.05,0,0,0-19.91-12.55c-4.23-1.61-8.58-2.9-12.92-4.18A.07.07,0,0,1,322.72,380.27Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "348.291px 397.761px",
        }}
        id="elyjo8do7ywg"
        className="animable"
      />
      <path
        d="M330.62,369.83c7.18,1.48,13.45,2.74,19.81,6.59,8.42,5.09,16.55,13.68,19.4,16.73C373,396.51,376,400,379,403.48c.12.15-.12.36-.25.22A237.54,237.54,0,0,0,356.83,382a47.38,47.38,0,0,0-12.25-8,84.92,84.92,0,0,0-14-4.15S330.58,369.83,330.62,369.83Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "354.806px 386.788px",
        }}
        id="el0ncrhdpqkey"
        className="animable"
      />
      <path
        d="M289.63,354.49l-21.87,69.26s-59.19-16.9-70-28.57S167,323.28,161.11,294.09,170,265.62,183.56,284s47.35,63,49.24,64S289.63,354.49,289.63,354.49Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "224.705px 347.391px",
        }}
        id="elkaw18l5n8br"
        className="animable"
      />
      <path
        d="M262.09,393c2.64-8.17,5.36-16.31,7.7-24.58,1.33-4.69,2.69-9.38,4-14.09.05-.18-.25-.29-.31-.11-3,8.09-6,16.21-8.57,24.43s-5.26,16.35-7.76,24.57c-1.41,4.67-2.68,9.37-3.82,14.13,0,.09.13.15.17.06C256.73,409.39,259.42,401.2,262.09,393Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "263.563px 385.791px",
        }}
        id="elcitgc1i6yfk"
        className="animable"
      />
      <g id="els230oh323w">
        <path
          d="M237.91,414.23l-2.62-.92c-4.93-1.76-10-3.65-14.69-5.59-.62-.24-1.22-.49-1.81-.74-.95-.39-1.9-.8-2.82-1.2l-1.38-.6-.49-.22-1.42-.66c-1.88-.86-3.66-1.73-5.32-2.6l-1.15-.61a41.16,41.16,0,0,1-7.3-4.8c-.21-.19-.42-.37-.6-.56a7.12,7.12,0,0,1-.55-.55c-10.82-11.66-30.78-71.89-36.65-101.09-.07-.34-.14-.68-.2-1l0-.22c-.4-2.22-.75-5-1-7.93v0c-.82-14.53,6.05-16.9,14.55-10.51C195.66,319.85,228.1,394.94,237.91,414.23Z"
          style={{
            opacity: 0.1,
            transformOrigin: "198.877px 342.657px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M210.33,441.15H65.71c.11-3.11,20.76-94.41,37.17-128.1,21-43.19,31.9-46.66,38.38-49,8.29-3,23.05-1.5,26.56.15,4,1.85,16.62,24,21.16,40.16C198.08,336.9,212.37,439.17,210.33,441.15Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "138.118px 351.74px",
        }}
        id="eldnzv5xdksui"
        className="animable"
      />
      <path
        d="M164,335.2s-6.68,1.41-9.62-2.12c-3.68-4.41-2.31-13.31-.18-17.78,2.5-5.2,10.31-8.78,16.52-8.41s9.09,2.47,10.7,3.86c2.56,2.19,6.27,11.72,3.69,17.06s-6,5.41-8.67,6.16c0,0,.4,6.17-.6,8.66-.41,1-9.24,1.81-10.22,1S164,335.2,164,335.2Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "169.036px 325.412px",
        }}
        id="el1k108egtuj4"
        className="animable"
      />
      <path
        d="M168.08,344h-.25c0-.31-.07-.63-.12-1l-.15-1.36-.14-1.37q-.07-.69-.12-1.38l-.06-.69,0-.68c0-.46,0-.92,0-1.39,0,.47.11.92.17,1.37l.18,1.37c.06.45.11.91.17,1.37l.15,1.37L168,343C168,343.32,168.05,343.65,168.08,344Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "167.66px 340.065px",
        }}
        id="elihw9qpzje"
        className="animable"
      />
      <path
        d="M171.18,343.8l-.26,0c0-.15,0-.29,0-.44l-.13-1.47-.12-1.48c0-.49-.07-1-.1-1.47l-.09-1.48,0-.73c0-.13,0-.25,0-.37v-.19l0-.18,0,.18,0,.18.06.37c0,.24.06.48.1.73.07.49.12,1,.18,1.47s.1,1,.14,1.47l.13,1.47.12,1.48Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "170.845px 339.895px",
        }}
        id="el24tcdzykwio"
        className="animable"
      />
      <path
        d="M174.09,343.36l-.24.05q0-.42-.06-.84l-.12-1.45-.21-2.9c-.07-1-.14-1.92-.26-2.88.06.23.12.47.16.71s.09.48.13.71l.1.72c0,.24.07.49.1.73s.05.47.08.72,0,.48.07.72c0,.48.09,1,.12,1.45s.06,1,.1,1.45Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "173.645px 339.375px",
        }}
        id="eloh5iu0xwn9m"
        className="animable"
      />
      <path
        d="M173.07,331.66a.9.9,0,0,1-.29.54,1,1,0,0,1-.55.24,1.45,1.45,0,0,0,.47-.32,1,1,0,0,0,.23-.49,1.15,1.15,0,0,0,0-.19.79.79,0,0,1-.14.23c-1,1.07-2.24-1.09-2.24-1.09s-.55,2.26-1.75,1.67a.69.69,0,0,1-.28-.25.5.5,0,0,0,0,.12,1.66,1.66,0,0,0,.15.33,1,1,0,0,0,.23.27,1.11,1.11,0,0,0,.68.27,1.05,1.05,0,0,1-.74-.19,1.16,1.16,0,0,1-.45-.64,1.68,1.68,0,0,1,0-.77l0-.14a6.34,6.34,0,0,1,1.82-3.15c.31-.28,2.36,1.44,2.72,2.71h0a1.13,1.13,0,0,1,.08.24A1.38,1.38,0,0,1,173.07,331.66Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "170.713px 330.534px",
        }}
        id="ellj1bjbzz3q"
        className="animable"
      />
      <path
        d="M170.24,332.22a.88.88,0,0,1-.3.34.79.79,0,0,1-.44.14,1.6,1.6,0,0,1-.23,0l-.21-.08h.22a.75.75,0,0,0,.21,0,.73.73,0,0,0,.37-.14,1.5,1.5,0,0,0,.5-.65A1,1,0,0,1,170.24,332.22Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "169.71px 332.267px",
        }}
        id="el1o7xvgf3y62"
        className="animable"
      />
      <path
        d="M160.35,310.56c-.42.33-.84.68-1.23,1s-.78.73-1.15,1.12a14.76,14.76,0,0,0-1,1.19c-.17.21-.32.42-.48.64l-.21.32a4,4,0,0,0-.22.34,18.55,18.55,0,0,0-2.32,5.9,11.18,11.18,0,0,1,.6-3.17,14,14,0,0,1,.59-1.52,12.5,12.5,0,0,1,.77-1.44,12.2,12.2,0,0,1,2.07-2.51,15.18,15.18,0,0,1,1.25-1A10.92,10.92,0,0,1,160.35,310.56Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "157.045px 315.815px",
        }}
        id="elza0n3ougr7a"
        className="animable"
      />
      <path
        d="M158.21,313.6a5.07,5.07,0,0,1,.77-1.12,7.82,7.82,0,0,1,1-.92,8.52,8.52,0,0,1,1.13-.75c.2-.11.41-.2.61-.29l.63-.24c-.18.13-.38.24-.56.36l-.28.18-.28.19-.27.18-.27.2c-.19.12-.36.26-.53.39-.35.28-.68.56-1,.86S158.51,313.26,158.21,313.6Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "160.28px 311.94px",
        }}
        id="elooiu94xjxpl"
        className="animable"
      />
      <path
        d="M160.79,310.29a1.8,1.8,0,0,1,.49-.38,1.31,1.31,0,0,1,.6-.18,5.54,5.54,0,0,1-.53.3A3.57,3.57,0,0,1,160.79,310.29Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "161.335px 310.01px",
        }}
        id="ell37jib5e5nk"
        className="animable"
      />
      <path
        d="M160.06,321.65c1.09-.23,6.1,1,5.91,5.52s-2.24,6-4.41,6.08a5.72,5.72,0,0,1-5.93-6C155.67,323.14,157.85,322.13,160.06,321.65Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "160.799px 327.439px",
        }}
        id="elmk28xny1n8h"
        className="animable"
      />
      <path
        d="M176.86,321.24a4.33,4.33,0,0,1,5.47,3.42c.51,2.74-.7,5.57-3.13,5.91-3,.43-5.34-2.37-5.45-4.76C173.6,322.51,176.86,321.24,176.86,321.24Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "178.095px 325.842px",
        }}
        id="elr6uae3aewc9"
        className="animable"
      />
      <path
        d="M182.45,326.91a5.85,5.85,0,0,1-.52,1.63,4.46,4.46,0,0,1-.45.73,3.91,3.91,0,0,1-.61.61,3.56,3.56,0,0,1-1.54.65,3.4,3.4,0,0,0,1.44-.76,3.57,3.57,0,0,0,.9-1.34,5.52,5.52,0,0,0,.28-1,4.09,4.09,0,0,1-3.8,2.31,4.92,4.92,0,0,1-2.4-.63,2.79,2.79,0,0,0,.32.28,2.85,2.85,0,0,0,.55.35,3,3,0,0,0,.61.24,4.14,4.14,0,0,0,1.32.11,4.08,4.08,0,0,1-1.35,0,3.9,3.9,0,0,1-.67-.18,3.26,3.26,0,0,1-.62-.31,4.25,4.25,0,0,1-1-.94,5.57,5.57,0,0,1-.4-.59,3.8,3.8,0,0,1-.31-.64h0a4.24,4.24,0,0,1-.49-2c0-2.49,1.63-5.29,4.89-5.08a4.56,4.56,0,0,1,4,4.89h0a6.3,6.3,0,0,1,0,.87A8.39,8.39,0,0,1,182.45,326.91Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "178.163px 325.434px",
        }}
        id="ellcl5nqi1qk"
        className="animable"
      />
      <path
        d="M163,332.13a3,3,0,0,1-.81.38,3.4,3.4,0,0,1-.89.16,4.27,4.27,0,0,1-1.77-.27,8.85,8.85,0,0,0,.88.12,8.61,8.61,0,0,0,.88,0,5.2,5.2,0,0,0,.87-.12l.21-.06.21-.06C162.74,332.25,162.87,332.18,163,332.13Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "161.265px 332.404px",
        }}
        id="ellyuajxrw2qk"
        className="animable"
      />
      <path
        d="M165.85,328.7a8.27,8.27,0,0,1-.45,1.81,4.82,4.82,0,0,1-1,1.58,4,4,0,0,1-.72.59,4.16,4.16,0,0,1-.83.38,3.56,3.56,0,0,0,1.44-1.06,4.76,4.76,0,0,0,.8-1.59l.09-.33a5.68,5.68,0,0,1-8,.92,5.25,5.25,0,0,0,.38.58,4,4,0,0,0,.49.52,4.81,4.81,0,0,0,.57.44,4.07,4.07,0,0,0,1.36.51,3.81,3.81,0,0,1-1.43-.39,3.54,3.54,0,0,1-.64-.39,4.44,4.44,0,0,1-.56-.5,5.69,5.69,0,0,1-.88-1.24c-.12-.23-.22-.46-.32-.7a4.92,4.92,0,0,1-.23-.71,7.1,7.1,0,0,1-.32-2.87c.22-3.14,2.62-4.83,5.56-4.73,1,0,4.78.76,4.8,5.57a8.5,8.5,0,0,1-.07,1Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "160.764px 327.288px",
        }}
        id="elwnmy8o26rng"
        className="animable"
      />
      <path
        d="M122,276.66C98.16,297.5,37.05,379.55,52,397.17s109.16,5.51,136.25-4.18c8.06-2.89-16.29-53.79-25.21-55.82-7.43-1.7-47.68,12-48.57,9.67-1-2.63,7-20.47,19.63-52.95C148.69,256.44,132.53,267.45,122,276.66Z"
        style={{
          fill: "rgb(235, 148, 129)",
          transformOrigin: "119.768px 336.922px",
        }}
        id="elmyujlgkg3ld"
        className="animable"
      />
      <path
        d="M202.82,325.57c19-.71,23.92,2,39.6-7.07,10.28-6,18.51-1.95,11.92,8.52-10.77,17.12-35,23.16-44,22.84Z"
        style={{
          fill: "rgb(235, 148, 129)",
          transformOrigin: "229.762px 332.79px",
        }}
        id="el4nmji974pqd"
        className="animable"
      />
      <path
        d="M151.35,338.52s22.18-9.53,42.3-12.28c13.92-1.9,44.65-2.66,70.64,30.22,6.17,7.8,9.42,23.29-5.27,14.21-10.44-6.45-15.11-13.7-21.87-16.35,0,0,1.8,20.19-6.25,26.07a184.66,184.66,0,0,1-22.15,13.84,39.19,39.19,0,0,1-3-3.91s-8,4.77-22,2Z"
        style={{
          fill: "rgb(235, 148, 129)",
          transformOrigin: "210.423px 359.862px",
        }}
        id="ele5cawdwrjdh"
        className="animable"
      />
      <path
        d="M207.22,326.55c6.15,4,19,12.83,27.09,23.69,8.93,12.06,22.89,37.71,13.92,44.21-6.58,4.77-12.77-4.55-12.77-4.55s3.53,11.81-3.35,14.82c-6.27,2.74-10.63-4.76-10.63-4.76s.91,7.54-5.64,9c-7.88,1.78-8.5-14.31-18-19.4C195.66,388.42,207.22,326.55,207.22,326.55Z"
        style={{
          fill: "rgb(235, 148, 129)",
          transformOrigin: "224.344px 367.823px",
        }}
        id="elj2la5t9plpe"
        className="animable"
      />
      <path
        d="M220,357.56c5.16,4.75,14.11,18.55,15.6,32.05,0,.31-.18.5-.27.23a147.15,147.15,0,0,0-15.45-32.2C219.83,357.57,219.93,357.5,220,357.56Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "227.733px 373.753px",
        }}
        id="elcvmf18yoqar"
        className="animable"
      />
      <path
        d="M208.07,366.93c4,4.56,12.62,19.19,13.67,32.93,0,.31-.21.48-.29.21A158,158,0,0,0,208,367,.07.07,0,0,1,208.07,366.93Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "214.87px 383.567px",
        }}
        id="elvf8tkq5hib"
        className="animable"
      />
      <path
        d="M237.17,354.2c5.91,2.41,9.56,5.8,14.34,9.89a83.1,83.1,0,0,0,7.34,5.77c2.35,1.59,5.8,4,8.65,2.16,1.59-1,1.79-3.15,1.63-4.86a24.42,24.42,0,0,0-2.64-7.27c-.05-.09.1-.18.15-.09a34.63,34.63,0,0,1,3,6.54,8.22,8.22,0,0,1,.07,4.81c-1.24,3.27-5.24,3.31-7.95,2.1a27.4,27.4,0,0,1-4.56-2.81c-1.39-1-2.73-2.06-4.05-3.15-2.69-2.25-5.26-4.66-7.93-6.94a79.91,79.91,0,0,0-8.19-6A.12.12,0,0,1,237.17,354.2Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "253.531px 364.089px",
        }}
        id="el5qzlhuk3gtx"
        className="animable"
      />
      <path
        d="M247.75,376.92c-1.23-3.63-8.29-19-10.72-22.59a.09.09,0,0,1,.14-.1c4.29,5,10.7,17.7,12.09,21.71s2.91,9,2.48,13.22c-.65,6.37-5,7.14-6.58,7.3a8.31,8.31,0,0,1-5.33-1.44,16.78,16.78,0,0,1-4.44-5.11c0-.09.08-.2.14-.11,1.95,2.73,5.36,5.58,8.87,5.48a5.84,5.84,0,0,0,4.15-1.73,7.6,7.6,0,0,0,1.54-5C250.16,384.55,249,380.65,247.75,376.92Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "243.602px 375.347px",
        }}
        id="eltm3qzerv9tq"
        className="animable"
      />
      <path
        d="M151.35,338.52l23.17,65S56.69,417.65,48.78,396.59s44.81-95,67.29-115.82c26.05-24.11,28.12-14.8,20.59,8.15-7.89,24-21.9,58.53-21.9,58.53Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "111.249px 337.382px",
        }}
        id="elcecgd2zhl7"
        className="animable"
      />
      <path
        d="M78.07,360.39a135.53,135.53,0,0,1,17.8-8.16,131.76,131.76,0,0,1,18.6-5.36c3.6-.79,7.22-1.42,10.85-2.06a.09.09,0,0,1,0,.17,169.15,169.15,0,0,0-18.76,5.19q-9.2,3-18.26,6.38c-3.42,1.27-6.78,2.7-10.16,4.08C78,360.7,77.92,360.47,78.07,360.39Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "101.685px 352.727px",
        }}
        id="elt7qp0j3i4p"
        className="animable"
      />
      <path
        d="M151.06,338.31c-.59.09-33.15,7.3-34.23,7.68.18-.47,7.53-16.65,8.62-19,.11-.24.21-.48.32-.71.68-1,1.37-2.07,2-3.13l2.46-4.1s0-.09-.07-.05c-.87,1.33-1.75,2.65-2.62,4-.41.61-.79,1.24-1.17,1.87.73-1.68,1.44-3.36,2.15-5.05,1.13-2.7,2.17-5.42,3.21-8.16,0-.08-.1-.13-.14,0-2.17,4.71-4.48,9.36-6.66,14.07s-10.49,22-10.44,22,35.94-8.72,36.64-8.94A.22.22,0,0,0,151.06,338.31Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "132.913px 329.655px",
        }}
        id="eljhx7n5d9ubo"
        className="animable"
      />
      <path
        d="M149.09,378.94c-2.54-7.24-5-14.51-7.84-21.66-1.59-4.06-3.17-8.13-4.8-12.18-.06-.15-.33-.07-.28.09,2.26,7.37,4.54,14.76,7.14,22s5.11,14.48,7.79,21.68C152.63,393,154.27,397,156,401c0,.08.17,0,.15,0C154.17,393.52,151.65,386.24,149.09,378.94Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "146.158px 373.028px",
        }}
        id="el3wm9f42fthb"
        className="animable"
      />
      <path
        d="M175.91,404.59c-1-2-2.14-4.92-3.19-6.9-.27-.53-.66-.46-.45.08.7,1.82,1.23,3.84,1.92,5.7-1.65.16-3.3.28-4.95.46l-5.73.61q-5.59.57-11.22,1-11.37.93-22.77,1.38a439.11,439.11,0,0,1-45.75-.35c-4.25-.27-8.51-.63-12.75-1.12a125.36,125.36,0,0,1-12.47-1.87c-.11,0-.15.16,0,.2a70.92,70.92,0,0,0,10.76,2.52q5.73.87,11.51,1.4c7.66.69,15.37,1,23.06,1,15.26.15,30.54-.37,45.75-1.49q6.41-.48,12.79-1.1c2.14-.21,4.29-.42,6.43-.67S176.07,404.93,175.91,404.59Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "117.183px 403.026px",
        }}
        id="elkwucnn2qom"
        className="animable"
      />
      <path
        d="M193.26,270.46c-6.89,7-14.92,10-18.43,14.7s-4.58,13.59-4.58,13.59-2.09-9.6-6.23-14.26-19.54-17.79-19.53-23.28,2.8-24.08,9.06-30c3.27-3.12,22.52-3.63,36.74-1,8.9,1.63,13.46,4.62,13.8,6.05C207.36,249.67,199.9,263.7,193.26,270.46Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "174.687px 263.633px",
        }}
        id="el4ssk41iezix"
        className="animable"
      />
      <g id="eld3uiwb9fjag">
        <path
          d="M193.26,270.46c-6.89,7-14.92,10-18.43,14.7s-4.58,13.59-4.58,13.59-2.09-9.6-6.23-14.26-19.54-17.79-19.53-23.28,2.8-24.08,9.06-30c3.27-3.12,22.52-3.63,36.74-1,8.9,1.63,13.46,4.62,13.8,6.05C207.36,249.67,199.9,263.7,193.26,270.46Z"
          style={{
            opacity: 0.2,
            transformOrigin: "174.687px 263.633px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M153.55,231.17c-6.26,6-9.05,24.54-9.06,30,0,1.66,1.41,4,3.5,6.65-4-4.56-12.24-12.14-13.56-14.63s10.39-20.88,22.13-23.44A7.23,7.23,0,0,0,153.55,231.17Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "145.444px 248.785px",
        }}
        id="elmgmyzgbfkp"
        className="animable"
      />
      <g id="el4ks31gazw2d">
        <path
          d="M153.55,231.17c-6.26,6-9.05,24.54-9.06,30,0,1.66,1.41,4,3.5,6.65-4-4.56-12.24-12.14-13.56-14.63s10.39-20.88,22.13-23.44A7.23,7.23,0,0,0,153.55,231.17Z"
          style={{
            opacity: 0.2,
            transformOrigin: "145.444px 248.785px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M167.66,299.7c-8.84,1.22-22.42-13.42-27.26-24.69-.33-.78,1.54-8.41,3.37-17.79,1.09-5.7,2.17-12.07,2.73-18,.11-1.2,36.05,12.51,36.05,12.51s-4.81,12-5.74,22.25a14.1,14.1,0,0,0,0,2.44c0,.11,0,.25,0,.4C177.16,281.52,176.21,298.52,167.66,299.7Z"
        style={{
          fill: "rgb(235, 148, 129)",
          transformOrigin: "161.456px 269.459px",
        }}
        id="els4j5c8bw879"
        className="animable"
      />
      <path
        d="M148.76,239.64c.75,10.11,4.64,34.73,24.29,37.07a22.63,22.63,0,0,0,3.79.15,1.38,1.38,0,0,0,0-.41,15,15,0,0,1,0-2.43c.93-10.28,5.75-22.26,5.75-22.26S156.63,241.87,148.76,239.64Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "165.675px 258.261px",
        }}
        id="elpq0j97qdp8s"
        className="animable"
      />
      <path
        d="M155.7,193c-8,11.18-13.48,49.39-7.31,60.39,8.94,16,29.61,24.12,44.68,12,14.6-11.75,20.89-61.68,13.44-72.29C195.53,177.46,167.5,176.48,155.7,193Z"
        style={{
          fill: "rgb(235, 148, 129)",
          transformOrigin: "177.697px 226.104px",
        }}
        id="elxhdtuvaj0in"
        className="animable"
      />
      <path
        d="M182.46,227.94c0-.1-.22.1-.22.2-.21,2.6-.88,5.53-3.31,6.13-.08,0-.08.15,0,.15C181.83,234.33,182.68,230.43,182.46,227.94Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "180.681px 231.166px",
        }}
        id="ela1kv5m04u09"
        className="animable"
      />
      <path
        d="M180.45,225c-4.12-.77-5.37,7.53-1.55,8.25S183.9,225.63,180.45,225Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "179.551px 229.122px",
        }}
        id="el20q2mt9vai1"
        className="animable"
      />
      <path
        d="M196.84,230.17c0-.09.18.16.16.26-.46,2.56-.57,5.57,1.63,6.77.07,0,0,.17,0,.14C195.81,236.52,196,232.52,196.84,230.17Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "197.477px 233.747px",
        }}
        id="el9rl1j3pw8u"
        className="animable"
      />
      <path
        d="M199.54,227.83c4.18.31,3.26,8.65-.61,8.37S196,227.58,199.54,227.83Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "199.353px 232.016px",
        }}
        id="elvykxywm7r5m"
        className="animable"
      />
      <path
        d="M177.5,221.17a32.75,32.75,0,0,0,3.1,1.5c1.2.56,2.1,1.13,3.46.92a1.77,1.77,0,0,0,1.32-1.72c-.09-1.68-1.39-2.73-2.8-3.46a6.48,6.48,0,0,0-4.62-.67A1.89,1.89,0,0,0,177.5,221.17Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "180.987px 220.606px",
        }}
        id="elxa703ctqvvo"
        className="animable"
      />
      <path
        d="M204.26,222.35a31.75,31.75,0,0,1-3.17-1.35c-1.21-.54-2.23-.84-3-2a1.78,1.78,0,0,1,.42-2.13c1.33-1,3-.74,4.45-.16a6.44,6.44,0,0,1,3.54,3A1.89,1.89,0,0,1,204.26,222.35Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "202.298px 219.313px",
        }}
        id="el25pfl3ub5g8"
        className="animable"
      />
      <path
        d="M174.58,251.18c.52.7,1,1.62,1.93,1.84a7,7,0,0,0,2.87-.23.09.09,0,0,1,.09.14,3.5,3.5,0,0,1-3.48.91,2.79,2.79,0,0,1-1.68-2.58C174.3,251.11,174.5,251.08,174.58,251.18Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "176.898px 252.551px",
        }}
        id="elxddkaq9gcf"
        className="animable"
      />
      <path
        d="M178.44,243.89a8.79,8.79,0,0,0,5.26,4.77,11,11,0,0,0,3.12.62,3.16,3.16,0,0,0,.61,0H188a.64.64,0,0,0,.6-.5v0a1.13,1.13,0,0,0,0-.26v0l0-.23c.35-2,.61-5,.61-5,.67.47,4.11,2.68,4.21,1.64.77-8.64,1.64-18.25.58-27a.24.24,0,0,0-.48,0c-1.07,8.27-.68,16.68-1.63,25A15.46,15.46,0,0,0,188,241c-.29,0-.4,5.88-.63,6.76a.19.19,0,0,1,0,.09c-3.39-.08-5.74-1.46-8.64-4.17C178.54,243.52,178.31,243.66,178.44,243.89Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "186.447px 233.492px",
        }}
        id="elvot5cu0rjmi"
        className="animable"
      />
      <path
        d="M209.64,211.54c-.13-1.75-.95-11.14-3.72-11.91-3.06-.85-5.48,2.49-11.37,2.33s-10.57-7.4-17.65-5.29c-4.3,1.28-7.39,4.29-10.79,13.93-3.3,9.36-8.58,19.92-20.14,20.53.95-14.58,4.81-32.86,9.66-39.65,11.8-16.53,39.79-15.2,50.77.44C208.67,195.15,209.77,203.21,209.64,211.54Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "177.81px 205.379px",
        }}
        id="ell6bd0opzbkb"
        className="animable"
      />
      <path
        d="M157.19,227.35s-2.38-14.79-8.73-14.47-8.29,19.17-3.47,24.21a6.65,6.65,0,0,0,9.58.66Z"
        style={{
          fill: "rgb(235, 148, 129)",
          transformOrigin: "149.742px 226.177px",
        }}
        id="el6ffjym4jxtm"
        className="animable"
      />
      <path
        d="M148,218.3c-.09-.06-.18.07-.11.14,3.32,3.67,3.64,8.55,3.24,13.26-.49-2.13-1.76-3.77-4.51-3.3-.14,0-.12.24,0,.27a4.1,4.1,0,0,1,3.43,3.25,19.83,19.83,0,0,1,.35,4.22c0,.46.78.59.84.09a.37.37,0,0,0,0-.11C154,230.91,153.43,221.56,148,218.3Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "149.691px 227.417px",
        }}
        id="elzjzu286e9m8"
        className="animable"
      />
      <path
        d="M211.58,205.84c-3.6-20.17-20-32.47-41.57-32.47-32.73,0-58.82,56.16-55.7,75.45,2.83,17.45,18.61,22.2,21.11,24.2,5.82,4.66,10.77,13.53,17.13,20.78,5.27,6,19,7.89,19,7.89.2-3.08-.37-3.37,7.34-11.7,8.52-9.21,19.44-13.23,24.56-20.51C214.05,254.39,214.73,223.54,211.58,205.84Zm-18.32,64.62c-6.89,7-14.92,10-18.43,14.7s-4.58,13.59-4.58,13.59-2.09-9.6-6.23-14.26c-2.87-3.23-11.23-10.6-16-16.63l0,0c-4.05-4.56-12.22-12.12-13.54-14.6s10.36-20.83,22.08-23.43h.05c6.74-1.82,22-1.8,33.73.37,8.9,1.63,13.46,4.62,13.8,6.05C207.36,249.67,199.9,263.7,193.26,270.46Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "163.643px 237.53px",
        }}
        id="elkjg64jzxcak"
        className="animable"
      />
      <path
        d="M129.46,269.88a14.63,14.63,0,0,0,2.6,1.21,24.12,24.12,0,0,1,2.63,1.23,11.37,11.37,0,0,1,2.36,1.73,28.3,28.3,0,0,1,2,2.16c2.49,2.93,4.81,6,7.2,9l3.57,4.51c1.19,1.49,2.38,3,3.67,4.36a24.4,24.4,0,0,0,9.73,5.67,20.43,20.43,0,0,1-5.53-1.73,17.25,17.25,0,0,1-4.84-3.3,112.68,112.68,0,0,1-7.35-8.93l-7-9.14c-.59-.75-1.18-1.52-1.82-2.19a13,13,0,0,0-2.19-1.77,20.91,20.91,0,0,0-2.5-1.38A13,13,0,0,1,129.46,269.88Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "146.34px 284.815px",
        }}
        id="el1tdrnbwkn41"
        className="animable"
      />
      <path
        d="M174.31,295.12a34.35,34.35,0,0,1,4.41-5.62c.83-.85,1.69-1.69,2.6-2.46a17.29,17.29,0,0,1,2.94-2.07c-1.61,1.77-3.18,3.51-4.81,5.22A68.5,68.5,0,0,1,174.31,295.12Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "179.285px 290.045px",
        }}
        id="el9uf38fcc7l9"
        className="animable"
      />
      <path
        d="M131.65,273.62a10.09,10.09,0,0,1,4,1.51,9.9,9.9,0,0,1,1.71,1.39,16.5,16.5,0,0,1,1.31,1.74,31.79,31.79,0,0,0,2.67,3.25c.47.52,1,1,1.49,1.5a14.33,14.33,0,0,0,1.63,1.36,13.22,13.22,0,0,1-3.53-2.45,19.73,19.73,0,0,1-2.88-3.23,15.88,15.88,0,0,0-1.21-1.69,11.9,11.9,0,0,0-1.52-1.4,11.42,11.42,0,0,0-1.76-1.12A13.46,13.46,0,0,0,131.65,273.62Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "138.055px 278.995px",
        }}
        id="el8rsgfh50lm8"
        className="animable"
      />
      <path
        d="M135.71,248.82a30.85,30.85,0,0,0-1.06,3.3,33.19,33.19,0,0,0-.72,3.34,6,6,0,0,0-.08.82,1.5,1.5,0,0,0,.14.69,6.11,6.11,0,0,0,.89,1.38,9.35,9.35,0,0,0,2.51,2.3,6.68,6.68,0,0,1-2.95-1.91,6,6,0,0,1-1.09-1.47,2,2,0,0,1-.23-1,6.86,6.86,0,0,1,.07-.92,17.24,17.24,0,0,1,.91-3.42A13,13,0,0,1,135.71,248.82Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "135.254px 254.735px",
        }}
        id="elugyusdmul6"
        className="animable"
      />
      <path
        d="M204.09,236.21a12.88,12.88,0,0,1,2.76,5.15c.31.93.54,1.89.76,2.84.11.48.19,1,.28,1.45a5.73,5.73,0,0,1,.09,1.56,23.49,23.49,0,0,1-1.5,5.72,43.13,43.13,0,0,1-2.39,5.33l1.65-5.57a32.23,32.23,0,0,0,1.24-5.55,10.72,10.72,0,0,0-.3-2.75c-.16-.95-.37-1.88-.58-2.82s-.47-1.85-.79-2.76A11,11,0,0,0,204.09,236.21Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "206.044px 247.235px",
        }}
        id="elpnki9r6t8va"
        className="animable"
      />
      <g id="elczfkwdhu9q6">
        <circle
          cx={164.35}
          cy={295}
          r={1.29}
          style={{
            opacity: 0.2,
            transformOrigin: "164.35px 295px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M163.75,295.16a38.45,38.45,0,0,1,2.06,6.62,13.57,13.57,0,0,1,.37,4.79,26.35,26.35,0,0,1-1.09,4.1c-2.21,7-5.16,14.56-3.31,22,.19.78,1.4.45,1.21-.34-1.9-7.6,1.52-15.28,3.66-22.46a16,16,0,0,0,.37-8.42,39.24,39.24,0,0,0-2.06-6.61c-.31-.73-1.52-.41-1.21.33Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "164.306px 313.771px",
        }}
        id="ela0jtd14aa07"
        className="animable"
      />
      <g id="el75q35m163t">
        <path
          d="M175.39,293.41a1.3,1.3,0,1,1-1.29-1.29A1.3,1.3,0,0,1,175.39,293.41Z"
          style={{
            opacity: 0.2,
            transformOrigin: "174.09px 293.42px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M173.49,293.7a41.19,41.19,0,0,0,1.65,14.36,73.39,73.39,0,0,1,2.68,9.57c.63,3.36.94,7-.33,10.27-.29.75.92,1.08,1.21.33,1.19-3,1-6.41.55-9.58a59.6,59.6,0,0,0-2.59-10,42,42,0,0,1-1.92-14.94c0-.8-1.22-.8-1.25,0Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "176.504px 310.882px",
        }}
        id="elkg3ace5pqna"
        className="animable"
      />
    </g>
    <g
      id="freepik--Devices--inject-2"
      className="animable"
      style={{
        transformOrigin: "250px 381.38px",
      }}
    >
      <polygon
        points="331.54 446.42 256.25 446.42 256.25 440.57 331.54 438.13 331.54 446.42"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "293.895px 442.275px",
        }}
        id="elpn4fz0qvflp"
        className="animable"
      />
      <polygon
        points="407.42 419.47 268.83 419.47 290.87 316.29 429.46 316.29 407.42 419.47"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "349.145px 367.88px",
        }}
        id="elkmhhgcr1t9c"
        className="animable"
      />
      <polygon
        points="412.55 419.47 273.95 419.47 295.99 316.29 434.59 316.29 412.55 419.47"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "354.27px 367.88px",
        }}
        id="ell0p40we65hr"
        className="animable"
      />
      <polygon
        points="337.8 382.47 334.55 382.47 339.48 359.33 342.73 359.33 337.8 382.47"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "338.64px 370.9px",
        }}
        id="elan0jq29dg6e"
        className="animable"
      />
      <polygon
        points="344.21 382.47 340.96 382.47 345.89 359.33 349.14 359.33 344.21 382.47"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "345.05px 370.9px",
        }}
        id="elats42g6fr8r"
        className="animable"
      />
      <polygon
        points="350.62 382.47 347.37 382.47 352.31 359.33 355.56 359.33 350.62 382.47"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "351.465px 370.9px",
        }}
        id="ellutc8cinm8"
        className="animable"
      />
      <polygon
        points="357.04 382.47 353.79 382.47 358.72 359.33 361.97 359.33 357.04 382.47"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "357.88px 370.9px",
        }}
        id="el7q0y6b2glwg"
        className="animable"
      />
      <polygon
        points="363.45 382.47 360.2 382.47 365.13 359.33 368.38 359.33 363.45 382.47"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "364.29px 370.9px",
        }}
        id="elitykfb4zopr"
        className="animable"
      />
      <polygon
        points="369.86 382.47 366.61 382.47 371.55 359.33 374.8 359.33 369.86 382.47"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "370.705px 370.9px",
        }}
        id="eljdb1x3iu8"
        className="animable"
      />
      <polygon
        points="376.28 382.47 373.03 382.47 377.96 359.33 381.21 359.33 376.28 382.47"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "377.12px 370.9px",
        }}
        id="el6sghktbqp9m"
        className="animable"
      />
      <polygon
        points="373.35 446.42 305.18 446.42 305.18 441.12 373.35 438.92 373.35 446.42"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "339.265px 442.67px",
        }}
        id="el1mwd17j4ekj"
        className="animable"
      />
      <polygon
        points="391.88 446.41 355.28 446.41 333.24 388.59 369.84 388.59 391.88 446.41"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "362.56px 417.5px",
        }}
        id="elk250421sun"
        className="animable"
      />
      <polygon
        points="397.91 446.41 361.31 446.41 339.27 388.59 375.87 388.59 397.91 446.41"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "368.59px 417.5px",
        }}
        id="elsh4vxk18na"
        className="animable"
      />
      <polygon
        points="92.58 419.47 135.35 419.47 113.31 316.29 70.54 316.29 92.58 419.47"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "102.945px 367.88px",
        }}
        id="el9jx8xfa2yrd"
        className="animable"
      />
      <polygon
        points="87.45 419.47 130.23 419.47 108.19 316.29 65.41 316.29 87.45 419.47"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "97.82px 367.88px",
        }}
        id="ell8riwgwyvga"
        className="animable"
      />
      <polygon
        points="93.28 382.47 93.89 382.47 88.95 359.33 88.35 359.33 93.28 382.47"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "91.12px 370.9px",
        }}
        id="elj4gd75alehl"
        className="animable"
      />
      <polygon
        points="94.57 382.47 95.18 382.47 90.25 359.33 89.64 359.33 94.57 382.47"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "92.41px 370.9px",
        }}
        id="elxesnrc05or"
        className="animable"
      />
      <polygon
        points="95.87 382.47 96.48 382.47 91.54 359.33 90.93 359.33 95.87 382.47"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "93.705px 370.9px",
        }}
        id="el0zi46jab21ng"
        className="animable"
      />
      <polygon
        points="97.16 382.47 97.77 382.47 92.84 359.33 92.23 359.33 97.16 382.47"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "95px 370.9px",
        }}
        id="elb3l27ofwf5p"
        className="animable"
      />
      <polygon
        points="98.46 382.47 99.06 382.47 94.13 359.33 93.52 359.33 98.46 382.47"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "96.29px 370.9px",
        }}
        id="el2zqrd3kub5m"
        className="animable"
      />
      <polygon
        points="99.75 382.47 100.36 382.47 95.42 359.33 94.82 359.33 99.75 382.47"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "97.59px 370.9px",
        }}
        id="elfdcghfyxkk4"
        className="animable"
      />
      <polygon
        points="101.04 382.47 101.65 382.47 96.72 359.33 96.11 359.33 101.04 382.47"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "98.88px 370.9px",
        }}
        id="elqkyl6569jb"
        className="animable"
      />
      <polygon
        points="102.34 382.47 102.95 382.47 98.01 359.33 97.41 359.33 102.34 382.47"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "100.18px 370.9px",
        }}
        id="elvyk9g7jzr9f"
        className="animable"
      />
      <polygon
        points="86.63 446.42 137.79 446.42 137.79 441.12 86.63 438.92 86.63 446.42"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "112.21px 442.67px",
        }}
        id="el6r9nvmlc0j4"
        className="animable"
      />
      <polygon
        points="68.1 446.41 87.69 446.41 109.74 388.59 90.14 388.59 68.1 446.41"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "88.92px 417.5px",
        }}
        id="elxzg8doq8c2"
        className="animable"
      />
      <polygon
        points="68.1 446.41 81.67 446.41 103.71 388.59 90.14 388.59 68.1 446.41"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "85.905px 417.5px",
        }}
        id="el1u3x6yegwsn"
        className="animable"
      />
      <path
        d="M249,446.47H199.3c-4.24,0,1.89-12.07,17.36-16.7S253.85,441.94,249,446.47Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "223.841px 437.72px",
        }}
        id="el3gj6t82gu"
        className="animable"
      />
      <path
        d="M229.48,446.47a15.36,15.36,0,0,1,2.23-7.91,13.32,13.32,0,0,1,2.68-3.21,11.24,11.24,0,0,1,3.56-2.09,20.9,20.9,0,0,0-3,2.66,17.69,17.69,0,0,0-2.36,3.16,25.16,25.16,0,0,0-1.78,3.56A36.71,36.71,0,0,0,229.48,446.47Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "233.715px 439.865px",
        }}
        id="elwsjaf4gnizn"
        className="animable"
      />
      <path
        d="M235.36,435a27.82,27.82,0,0,1,8.36,4,16.5,16.5,0,0,1,3.45,3.18,7.09,7.09,0,0,1,1.8,4.29,9.06,9.06,0,0,0-2.38-3.78,24.51,24.51,0,0,0-3.45-2.88C240.67,438.05,238,436.54,235.36,435Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "242.165px 440.735px",
        }}
        id="elmhn2ymahgnr"
        className="animable"
      />
    </g>
    <g
      id="freepik--speech-bubble--inject-2"
      className="animable"
      style={{
        transformOrigin: "423.53px 316.27px",
      }}
    >
      <path
        d="M466.13,320.35a38.78,38.78,0,0,1-64.79,24.53L382,347.72l9.7-16.77a38.78,38.78,0,1,1,74.48-10.6Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "424.195px 316.31px",
        }}
        id="eltxgk2w5xxja"
        className="animable"
      />
      <path
        d="M466.13,320.35A39,39,0,0,1,458.3,340a45,45,0,0,1-3.51,4,43.71,43.71,0,0,1-4.1,3.47,39.31,39.31,0,0,1-19.79,7.69,38.66,38.66,0,0,1-5.38.11,35.68,35.68,0,0,1-5.37-.56A39.15,39.15,0,0,1,401,345.29l.46.14-19.38,3-1.41.22.71-1.24,9.65-16.8,0,.66a39.2,39.2,0,0,1,2.38-34.56,39.75,39.75,0,0,1,12.15-12.93,39.44,39.44,0,0,1,34.08-4.59,39.64,39.64,0,0,1,14.95,9.17A39,39,0,0,1,466.13,320.35Zm0,0a38.35,38.35,0,0,0-44-42,38.06,38.06,0,0,0-16,6.33,39.87,39.87,0,0,0-11.6,12.65,38.25,38.25,0,0,0-4.92,16.41,38.72,38.72,0,0,0,2.73,16.89l.14.34-.19.32-9.76,16.74-.69-1,19.41-2.72.27,0,.19.18A38.22,38.22,0,0,0,420.29,354a33.15,33.15,0,0,0,5.26.64,37.1,37.1,0,0,0,15.66-2.29,35.82,35.82,0,0,0,9.35-5,34.69,34.69,0,0,0,7.62-7.42A39,39,0,0,0,466.13,320.35Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "423.51px 316.27px",
        }}
        id="elu49ofgcnai"
        className="animable"
      />
      <polygon
        points="427.55 291 402.05 335.18 453.06 335.18 427.55 291"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "427.555px 313.09px",
        }}
        id="elb9wnsgy8np"
        className="animable"
      />
      <polygon
        points="427.55 297.4 407.59 331.98 447.51 331.98 427.55 297.4"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "427.55px 314.69px",
        }}
        id="elzlkclh5ahe"
        className="animable"
      />
      <polygon
        points="425.53 307.41 429.57 307.41 429.3 322.31 425.8 322.31 425.53 307.41"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "427.55px 314.86px",
        }}
        id="el21youbwlf27"
        className="animable"
      />
      <rect
        x={425.8}
        y={324.18}
        width={3.5}
        height={3.5}
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "427.55px 325.93px",
        }}
        id="el57negllo73u"
        className="animable"
      />
    </g>
    <g
      id="freepik--Bugs--inject-2"
      className="animable"
      style={{
        transformOrigin: "350.335px 367.8px",
      }}
    >
      <path
        d="M409.43,396.19l-19,7.42s.21.71.66,1.81c.17.38.36.82.58,1.28a26.56,26.56,0,0,0,1.85,3.4l.81,1.19a16.39,16.39,0,0,0,2.53,2.73,9.42,9.42,0,0,0,1.3,1,9,9,0,0,0,13.31-5.19,12.06,12.06,0,0,0,.31-1.61,17,17,0,0,0,0-3.71c-.07-.48-.13-1-.21-1.42a26.1,26.1,0,0,0-.94-3.76c-.15-.49-.3-.94-.45-1.33C409.75,396.86,409.43,396.19,409.43,396.19ZM405.66,413l-1.12.43L400,401.78l1.11-.44Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "401.156px 406.257px",
        }}
        id="el4mrnku3ehv7"
        className="animable"
      />
      <path
        d="M408.91,394.74l-19.1,7.45c-1.46-3.73,1.16-8.81,6.72-11S407.46,391,408.91,394.74Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "399.161px 396.236px",
        }}
        id="elb4x2hqbwh2"
        className="animable"
      />
      <polygon
        points="392.88 406.21 389.21 407.64 384.88 404.2 385.74 403.11 389.44 406.06 392.38 404.91 392.88 406.21"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "388.88px 405.375px",
        }}
        id="elglbjc9oma6b"
        className="animable"
      />
      <g id="el4veaegmtpr2">
        <rect
          x={388.76}
          y={410.64}
          width={6.98}
          height={1.39}
          style={{
            fill: "rgb(255, 199, 39)",
            transformOrigin: "392.25px 411.335px",
            transform: "rotate(-21.3deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="394.12 419.47 392.82 418.98 394.32 415 398.6 413.33 399.1 414.63 395.4 416.07 394.12 419.47"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "395.96px 416.4px",
        }}
        id="elkyzx5j5eeyi"
        className="animable"
      />
      <polygon
        points="413.05 398.35 409.37 399.78 408.86 398.49 411.8 397.34 412.53 392.67 413.9 392.88 413.05 398.35"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "411.38px 396.225px",
        }}
        id="elbwwpxbw4qzv"
        className="animable"
      />
      <g id="el90qdrt9yly">
        <rect
          x={409.81}
          y={402.43}
          width={6.98}
          height={1.39}
          style={{
            fill: "rgb(255, 199, 39)",
            transformOrigin: "413.3px 403.125px",
            transform: "rotate(-21.3deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="417.44 410.38 414.19 408.75 410.49 410.19 409.98 408.89 414.26 407.22 418.06 409.13 417.44 410.38"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "414.02px 408.8px",
        }}
        id="elt3gooeetj7"
        className="animable"
      />
      <polygon
        points="395.42 395.93 387.94 392.64 385.93 387.47 387.22 386.97 389.03 391.6 395.98 394.65 395.42 395.93"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "390.955px 391.45px",
        }}
        id="el2267biuyxnb"
        className="animable"
      />
      <polygon
        points="400.54 393.93 399.27 393.37 402.32 386.42 400.51 381.79 401.81 381.28 403.82 386.45 400.54 393.93"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "401.545px 387.605px",
        }}
        id="elmplt6zkh8s7"
        className="animable"
      />
      <path
        d="M310.65,379.08l-16-12.73s-.5.55-1.23,1.49c-.24.34-.52.72-.81,1.14a24.7,24.7,0,0,0-2,3.31c-.21.42-.42.86-.62,1.3a15.87,15.87,0,0,0-1.1,3.55,9.82,9.82,0,0,0-.19,1.61,8.94,8.94,0,0,0,11.17,8.91,11.28,11.28,0,0,0,1.54-.54,16.11,16.11,0,0,0,3.21-1.87c.38-.3.77-.59,1.12-.89a24.87,24.87,0,0,0,2.78-2.7c.35-.37.66-.73.92-1.06C310.23,379.69,310.65,379.08,310.65,379.08Zm-16.41,5.17-.93-.75,7.78-9.76.93.74Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "299.673px 377.147px",
        }}
        id="el0ryieg0xcgg"
        className="animable"
      />
      <path
        d="M311.65,377.91l-16-12.79c2.5-3.13,8.21-3.42,12.88.3S314.14,374.78,311.65,377.91Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "304.321px 370.306px",
        }}
        id="eliiafrkkany8"
        className="animable"
      />
      <polygon
        points="293.68 369.8 290.6 367.34 291.4 361.87 292.77 362.07 292.09 366.75 294.55 368.71 293.68 369.8"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "292.575px 365.835px",
        }}
        id="el0ya7ewbywusl"
        className="animable"
      />
      <g id="el59gxvo31rni">
        <rect
          x={288.24}
          y={368.34}
          width={1.39}
          height={6.98}
          style={{
            fill: "rgb(255, 199, 39)",
            transformOrigin: "288.935px 371.83px",
            transform: "rotate(-51.43deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="282.83 377.52 282.61 376.15 286.8 375.46 290.39 378.32 289.52 379.41 286.42 376.93 282.83 377.52"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "286.5px 377.435px",
        }}
        id="elo66ovqwjs9a"
        className="animable"
      />
      <polygon
        points="310.6 383.29 307.52 380.83 308.38 379.74 310.85 381.71 315.26 380 315.76 381.29 310.6 383.29"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "311.64px 381.515px",
        }}
        id="el7ur3nhs8ogf"
        className="animable"
      />
      <g id="elupqrcncvqs">
        <rect
          x={305.9}
          y={382.43}
          width={1.39}
          height={6.98}
          style={{
            fill: "rgb(255, 199, 39)",
            transformOrigin: "306.595px 385.92px",
            transform: "rotate(-51.43deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="302.4 393.13 302.18 389.5 299.08 387.02 299.94 385.94 303.53 388.8 303.79 393.04 302.4 393.13"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "301.435px 389.535px",
        }}
        id="eltl4fyygm0vj"
        className="animable"
      />
      <polygon
        points="303.85 366.83 302.93 358.72 306.39 354.38 307.48 355.25 304.38 359.13 305.23 366.68 303.85 366.83"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "305.205px 360.605px",
        }}
        id="elbvijkzv98s7"
        className="animable"
      />
      <polygon
        points="308.14 370.26 307.99 368.88 315.53 368.03 318.63 364.14 319.72 365 316.26 369.34 308.14 370.26"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "313.855px 367.2px",
        }}
        id="elhx4jslm5x8r"
        className="animable"
      />
      <path
        d="M361.76,328l12.42,16.2s.62-.41,1.56-1.15c.31-.26.68-.57,1.06-.91a24.26,24.26,0,0,0,2.75-2.73c.3-.35.61-.73.91-1.1a16.19,16.19,0,0,0,1.92-3.19,9.69,9.69,0,0,0,.58-1.52,8.94,8.94,0,0,0-8.69-11.33,11.33,11.33,0,0,0-1.63.14,16.76,16.76,0,0,0-3.56,1.05c-.45.19-.89.39-1.31.59a26.06,26.06,0,0,0-3.35,1.95q-.65.42-1.14.81A17.89,17.89,0,0,0,361.76,328ZM378.93,327l.72.95-9.91,7.6-.72-.95Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "372.523px 333.235px",
        }}
        id="elwt4lfts4qdk"
        className="animable"
      />
      <path
        d="M360.5,328.93,373,345.2c-3.18,2.44-8.8,1.34-12.43-3.4S357.32,331.36,360.5,328.93Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "365.49px 337.694px",
        }}
        id="el9z6czcjl5j"
        className="animable"
      />
      <polygon
        points="375.99 341.13 378.39 344.26 376.29 349.38 375 348.85 376.8 344.47 374.88 341.97 375.99 341.13"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "376.635px 345.255px",
        }}
        id="elxbgmq2a96i8"
        className="animable"
      />
      <g id="el802b8aaolud">
        <rect
          x={380.39}
          y={336.81}
          width={1.39}
          height={6.98}
          style={{
            fill: "rgb(255, 199, 39)",
            transformOrigin: "381.085px 340.3px",
            transform: "rotate(-37.49deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="388.38 336.24 388.27 337.63 384.02 337.29 381.23 333.65 382.34 332.81 384.75 335.95 388.38 336.24"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "384.805px 335.22px",
        }}
        id="elfuorx43lnc6"
        className="animable"
      />
      <polygon
        points="362.81 323.95 365.22 327.08 364.11 327.93 362.2 325.43 357.5 326.03 357.33 324.65 362.81 323.95"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "361.275px 325.94px",
        }}
        id="elln8x7nfp7vo"
        className="animable"
      />
      <g id="el1wo3r6gfwbs">
        <rect
          x={366.64}
          y={318.88}
          width={1.39}
          height={6.98}
          style={{
            fill: "rgb(255, 199, 39)",
            transformOrigin: "367.335px 322.37px",
            transform: "rotate(-37.49deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="373.14 316.38 372.48 319.96 374.9 323.11 373.8 323.96 371 320.31 371.78 316.13 373.14 316.38"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "372.95px 320.045px",
        }}
        id="eloir1l5zc4p8"
        className="animable"
      />
      <polygon
        points="365.4 341.55 364.34 349.65 359.93 353.03 359.08 351.93 363.03 348.9 364.02 341.37 365.4 341.55"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "362.24px 347.2px",
        }}
        id="elhp7pt2x3fwq"
        className="animable"
      />
      <polygon
        points="362.06 337.19 361.88 338.57 354.35 337.58 350.4 340.61 349.56 339.5 353.96 336.13 362.06 337.19"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "355.81px 338.37px",
        }}
        id="elpr0ibkni2uk"
        className="animable"
      />
    </g>
    <defs>
      <filter id="active" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter id="hover" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#ff0000" floodOpacity={0.5} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
        <feColorMatrix
          type="matrix"
          values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
        />
      </filter>
    </defs>
  </svg>
);
export default SvgComponent;

import * as React from "react";
import "./ProductsSvg.css";
const SvgComponent = () => (
  <svg
    className="animated"
    id="freepik_stories-server"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlnssvgjs="http://svgjs.com/svgjs"
  >
    <g
      id="freepik--background-simple--inject-183"
      className="animable"
      style={{
        transformOrigin: "256.578px 236.979px",
      }}
    >
      <polygon
        points="375.43 165.07 370.22 174.09 375.43 183.11 385.79 183.11 391 174.09 385.79 165.07 375.43 165.07"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "380.61px 174.09px",
        }}
        id="el4s39ald2sc"
        className="animable"
      />
      <polygon
        points="359.35 174.37 354.15 183.38 359.35 192.4 369.71 192.4 374.92 183.38 369.71 174.37 359.35 174.37"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "364.535px 183.385px",
        }}
        id="eljxu1dip83dg"
        className="animable"
      />
      <polygon
        points="343.28 183.66 338.07 192.68 343.28 201.7 353.64 201.7 358.85 192.68 353.64 183.66 343.28 183.66"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "348.46px 192.68px",
        }}
        id="elhp1r5mffbx9"
        className="animable"
      />
      <polygon
        points="327.2 174.37 322 183.38 327.2 192.4 337.56 192.4 342.77 183.38 337.56 174.37 327.2 174.37"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "332.385px 183.385px",
        }}
        id="el5875nu3bkc8"
        className="animable"
      />
      <polygon
        points="401.87 192.4 407.07 183.38 401.87 174.37 391.5 174.37 386.3 183.38 391.5 192.4 401.87 192.4"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "396.685px 183.385px",
        }}
        id="elpks3psiw8oi"
        className="animable"
      />
      <path
        d="M417.94,183.11l1.67-2.89a86.8,86.8,0,0,0-2.11-15.15h-9.92l-5.21,9,5.21,9Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "410.99px 174.09px",
        }}
        id="ela3jqdk2qbkd"
        className="animable"
      />
      <polygon
        points="385.79 201.7 391 192.68 385.79 183.66 375.43 183.66 370.22 192.68 375.43 201.7 385.79 201.7"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "380.61px 192.68px",
        }}
        id="elu2ryctq989"
        className="animable"
      />
      <polygon
        points="391.5 155.78 386.3 164.79 391.5 173.81 401.87 173.81 407.07 164.79 401.87 155.78 391.5 155.78"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "396.685px 164.795px",
        }}
        id="el45n0n72rdr9"
        className="animable"
      />
      <path
        d="M402.37,155.5l5.21,9h9.79a87.84,87.84,0,0,0-6.62-18h-3.17Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "409.87px 155.5px",
        }}
        id="el8hpqv2pz3y"
        className="animable"
      />
      <polygon
        points="198.6 174.37 193.4 183.38 198.6 192.4 208.97 192.4 214.17 183.38 208.97 174.37 198.6 174.37"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "203.785px 183.385px",
        }}
        id="elmpy6bivvgvk"
        className="animable"
      />
      <polygon
        points="369.71 192.96 359.35 192.96 354.15 201.97 359.35 210.99 369.71 210.99 374.92 201.97 369.71 192.96"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "364.535px 201.975px",
        }}
        id="elsp1ford3d1"
        className="animable"
      />
      <polygon
        points="230.75 174.37 225.55 183.38 230.75 192.4 241.12 192.4 246.32 183.38 241.12 174.37 230.75 174.37"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "235.935px 183.385px",
        }}
        id="elgdfxtpueuhl"
        className="animable"
      />
      <polygon
        points="214.68 165.07 209.47 174.09 214.68 183.11 225.04 183.11 230.25 174.09 225.04 165.07 214.68 165.07"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "219.86px 174.09px",
        }}
        id="elmg0pxqeafmj"
        className="animable"
      />
      <polygon
        points="166.45 155.78 161.25 164.79 166.45 173.81 176.82 173.81 182.02 164.79 176.82 155.78 166.45 155.78"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "171.635px 164.795px",
        }}
        id="elumdit9mip6l"
        className="animable"
      />
      <polygon
        points="182.53 165.07 177.32 174.09 182.53 183.11 192.89 183.11 198.1 174.09 192.89 165.07 182.53 165.07"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "187.71px 174.09px",
        }}
        id="elfn8m4uy95je"
        className="animable"
      />
      <polygon
        points="262.9 192.96 257.7 201.97 262.9 210.99 273.26 210.99 278.47 201.97 273.26 192.96 262.9 192.96"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "268.085px 201.975px",
        }}
        id="elp3w2g1z7htn"
        className="animable"
      />
      <polygon
        points="278.98 183.66 273.77 192.68 278.98 201.7 289.34 201.7 294.55 192.68 289.34 183.66 278.98 183.66"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "284.16px 192.68px",
        }}
        id="el4n7elugvti"
        className="animable"
      />
      <polygon
        points="295.05 192.96 289.85 201.97 295.05 210.99 305.42 210.99 310.62 201.97 305.42 192.96 295.05 192.96"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "300.235px 201.975px",
        }}
        id="elnhzkugp4yg"
        className="animable"
      />
      <polygon
        points="311.13 183.66 305.92 192.68 311.13 201.7 321.49 201.7 326.7 192.68 321.49 183.66 311.13 183.66"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "316.31px 192.68px",
        }}
        id="el06m1657rsi4a"
        className="animable"
      />
      <polygon
        points="246.83 183.66 241.62 192.68 246.83 201.7 257.19 201.7 262.4 192.68 257.19 183.66 246.83 183.66"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "252.01px 192.68px",
        }}
        id="eldpt1rmzxp9m"
        className="animable"
      />
      <polygon
        points="353.64 258.02 343.28 258.02 338.07 267.04 343.28 276.06 353.64 276.06 358.85 267.04 353.64 258.02"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "348.46px 267.04px",
        }}
        id="el8owcn8likxu"
        className="animable"
      />
      <polygon
        points="160.74 294.65 165.95 285.63 160.74 276.61 150.38 276.61 145.17 285.63 150.38 294.65 160.74 294.65"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "155.56px 285.63px",
        }}
        id="el0tsv15k1nxzn"
        className="animable"
      />
      <polygon
        points="176.82 285.35 182.02 276.34 176.82 267.32 166.45 267.32 161.25 276.34 166.45 285.35 176.82 285.35"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "171.635px 276.335px",
        }}
        id="el7o4w8wgh54o"
        className="animable"
      />
      <polygon
        points="192.89 276.06 198.1 267.04 192.89 258.02 182.53 258.02 177.32 267.04 182.53 276.06 192.89 276.06"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "187.71px 267.04px",
        }}
        id="elfwljfnyhdf8"
        className="animable"
      />
      <polygon
        points="208.97 266.76 214.17 257.75 208.97 248.73 198.6 248.73 193.4 257.75 198.6 266.76 208.97 266.76"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "203.785px 257.745px",
        }}
        id="el370s6zdw0fb"
        className="animable"
      />
      <polygon
        points="225.04 276.06 230.25 267.04 225.04 258.02 214.68 258.02 209.47 267.04 214.68 276.06 225.04 276.06"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "219.86px 267.04px",
        }}
        id="elhnhgbrb81vm"
        className="animable"
      />
      <polygon
        points="128.59 294.65 133.8 285.63 128.59 276.61 118.23 276.61 113.02 285.63 118.23 294.65 128.59 294.65"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "123.41px 285.63px",
        }}
        id="el268tunrgctr"
        className="animable"
      />
      <polygon
        points="144.67 285.35 149.87 276.34 144.67 267.32 134.3 267.32 129.1 276.34 134.3 285.35 144.67 285.35"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "139.485px 276.335px",
        }}
        id="elamox8j85ata"
        className="animable"
      />
      <polygon
        points="86.08 294.65 96.44 294.65 101.65 285.63 96.44 276.61 86.08 276.61 80.87 285.63 86.08 294.65"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "91.26px 285.63px",
        }}
        id="el8b7yohf8hj3"
        className="animable"
      />
      <polygon
        points="112.52 285.35 117.72 276.34 112.52 267.32 102.15 267.32 96.95 276.34 102.15 285.35 112.52 285.35"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "107.335px 276.335px",
        }}
        id="ellfvv9o2ef4r"
        className="animable"
      />
      <polygon
        points="241.12 266.76 246.32 257.75 241.12 248.73 230.75 248.73 225.55 257.75 230.75 266.76 241.12 266.76"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "235.935px 257.745px",
        }}
        id="elldq5z7gwyle"
        className="animable"
      />
      <polygon
        points="353.64 220.84 343.28 220.84 338.07 229.86 343.28 238.88 353.64 238.88 358.85 229.86 353.64 220.84"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "348.46px 229.86px",
        }}
        id="eloqeua0bzep"
        className="animable"
      />
      <polygon
        points="353.64 239.43 343.28 239.43 338.07 248.45 343.28 257.47 353.64 257.47 358.85 248.45 353.64 239.43"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "348.46px 248.45px",
        }}
        id="elzqk1r5q4m2j"
        className="animable"
      />
      <polygon
        points="353.64 294.65 358.85 285.63 353.64 276.61 343.28 276.61 338.07 285.63 343.28 294.65 353.64 294.65"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "348.46px 285.63px",
        }}
        id="el9zdj4lz6w2p"
        className="animable"
      />
      <polygon
        points="273.26 266.76 278.47 257.75 273.26 248.73 262.9 248.73 257.7 257.75 262.9 266.76 273.26 266.76"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "268.085px 257.745px",
        }}
        id="elp06v1hh3ud"
        className="animable"
      />
      <polygon
        points="369.71 229.58 374.92 220.57 369.71 211.55 359.35 211.55 354.15 220.57 359.35 229.58 369.71 229.58"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "364.535px 220.565px",
        }}
        id="el0mt8xp14j8p"
        className="animable"
      />
      <polygon
        points="150.38 146.48 145.17 155.5 150.38 164.52 160.74 164.52 165.95 155.5 160.74 146.48 150.38 146.48"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "155.56px 155.5px",
        }}
        id="eldnlxd4s5t5c"
        className="animable"
      />
      <polygon
        points="305.42 266.76 310.62 257.75 305.42 248.73 295.05 248.73 289.85 257.75 295.05 266.76 305.42 266.76"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "300.235px 257.745px",
        }}
        id="el1eddppwgmoh"
        className="animable"
      />
      <polygon
        points="337.56 285.35 342.77 276.34 337.56 267.32 327.2 267.32 322 276.34 327.2 285.35 337.56 285.35"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "332.385px 276.335px",
        }}
        id="elfsfuxao6syi"
        className="animable"
      />
      <polygon
        points="289.34 257.47 294.55 248.45 289.34 239.43 278.98 239.43 273.77 248.45 278.98 257.47 289.34 257.47"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "284.16px 248.45px",
        }}
        id="elckv16q8tgq6"
        className="animable"
      />
      <polygon
        points="321.49 276.06 326.7 267.04 321.49 258.02 311.13 258.02 305.92 267.04 311.13 276.06 321.49 276.06"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "316.31px 267.04px",
        }}
        id="eledm1gxfm33k"
        className="animable"
      />
      <polygon
        points="262.9 174.37 257.7 183.38 262.9 192.4 273.26 192.4 278.47 183.38 273.26 174.37 262.9 174.37"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "268.085px 183.385px",
        }}
        id="elxiy9lkjgzrq"
        className="animable"
      />
      <polygon
        points="385.79 202.25 375.43 202.25 370.22 211.27 375.43 220.29 385.79 220.29 391 211.27 385.79 202.25"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "380.61px 211.27px",
        }}
        id="elkhttboquwr"
        className="animable"
      />
      <polygon
        points="96.44 276.06 101.65 267.04 96.44 258.02 86.08 258.02 80.87 267.04 86.08 276.06 96.44 276.06"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "91.26px 267.04px",
        }}
        id="elpol5gbew418"
        className="animable"
      />
      <path
        d="M417.94,201.7l2-3.52c-.11-3.77-.09-7.48-.15-11.25l-1.89-3.27H407.58l-5.21,9,5.21,9Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "411.155px 192.68px",
        }}
        id="el52wdy9zekw7"
        className="animable"
      />
      <path
        d="M418.45,183.39l1.36,2.35c0-1.47-.08-3-.15-4.46Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "419.13px 183.51px",
        }}
        id="el5bdjolnvbzh"
        className="animable"
      />
      <path
        d="M420,199.26,418.45,202l1.92,3.33C420.19,203.27,420.08,201.26,420,199.26Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "419.41px 202.295px",
        }}
        id="el2455ifuyr4ui"
        className="animable"
      />
      <polygon
        points="385.79 238.88 391 229.86 385.79 220.84 375.43 220.84 370.22 229.86 375.43 238.88 385.79 238.88"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "380.61px 229.86px",
        }}
        id="el8hv2b0tlutd"
        className="animable"
      />
      <polygon
        points="369.71 267.32 359.35 267.32 354.15 276.34 359.35 285.35 369.71 285.35 374.92 276.34 369.71 267.32"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "364.535px 276.335px",
        }}
        id="elcbcyjts75e"
        className="animable"
      />
      <path
        d="M128.59,145.93l3.13-5.42c-4.85,1.75-9.28,3.54-13.29,5.42Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "125.075px 143.22px",
        }}
        id="ele6j89v8islr"
        className="animable"
      />
      <polygon
        points="369.71 248.73 359.35 248.73 354.15 257.75 359.35 266.76 369.71 266.76 374.92 257.75 369.71 248.73"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "364.535px 257.745px",
        }}
        id="elj8hv6rm5g6b"
        className="animable"
      />
      <polygon
        points="369.71 230.14 359.35 230.14 354.15 239.16 359.35 248.17 369.71 248.17 374.92 239.16 369.71 230.14"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "364.535px 239.155px",
        }}
        id="elvg95366xl8p"
        className="animable"
      />
      <polygon
        points="295.05 174.37 289.85 183.38 295.05 192.4 305.42 192.4 310.62 183.38 305.42 174.37 295.05 174.37"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "300.235px 183.385px",
        }}
        id="elxednhxang8f"
        className="animable"
      />
      <polygon
        points="327.2 155.78 322 164.79 327.2 173.81 337.56 173.81 342.77 164.79 337.56 155.78 327.2 155.78"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "332.385px 164.795px",
        }}
        id="eln6jkzszttg"
        className="animable"
      />
      <polygon
        points="311.13 165.07 305.92 174.09 311.13 183.11 321.49 183.11 326.7 174.09 321.49 165.07 311.13 165.07"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "316.31px 174.09px",
        }}
        id="elrb8fxi6nb5h"
        className="animable"
      />
      <polygon
        points="369.71 322.54 374.92 313.52 369.71 304.5 359.35 304.5 354.15 313.52 359.35 322.54 369.71 322.54"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "364.535px 313.52px",
        }}
        id="eldw8y13rmkbi"
        className="animable"
      />
      <polygon
        points="278.98 165.07 273.77 174.09 278.98 183.11 289.34 183.11 294.55 174.09 289.34 165.07 278.98 165.07"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "284.16px 174.09px",
        }}
        id="elajr52xhvxx4"
        className="animable"
      />
      <polygon
        points="391.5 137.19 386.3 146.21 391.5 155.22 401.87 155.22 407.07 146.21 401.87 137.19 391.5 137.19"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "396.685px 146.205px",
        }}
        id="elhyfjkpeya3f"
        className="animable"
      />
      <polygon
        points="375.43 146.48 370.22 155.5 375.43 164.52 385.79 164.52 391 155.5 385.79 146.48 375.43 146.48"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "380.61px 155.5px",
        }}
        id="elrryize47a4"
        className="animable"
      />
      <polygon
        points="343.28 165.07 338.07 174.09 343.28 183.11 353.64 183.11 358.85 174.09 353.64 165.07 343.28 165.07"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "348.46px 174.09px",
        }}
        id="elgtgcxjpup2a"
        className="animable"
      />
      <path
        d="M407.58,145.93h2.94A49.58,49.58,0,0,0,404.05,134l-1.68,2.91Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "406.445px 139.965px",
        }}
        id="elpazrfcmdman"
        className="animable"
      />
      <polygon
        points="359.35 155.78 354.15 164.79 359.35 173.81 369.71 173.81 374.92 164.79 369.71 155.78 359.35 155.78"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "364.535px 164.795px",
        }}
        id="elfff60a3qs1q"
        className="animable"
      />
      <polygon
        points="369.71 285.91 359.35 285.91 354.15 294.93 359.35 303.94 369.71 303.94 374.92 294.93 369.71 285.91"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "364.535px 294.925px",
        }}
        id="elp073bwlhubi"
        className="animable"
      />
      <polygon
        points="144.67 303.94 149.87 294.93 144.67 285.91 134.3 285.91 129.1 294.93 134.3 303.94 144.67 303.94"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "139.485px 294.925px",
        }}
        id="el7raffgm4827"
        className="animable"
      />
      <polygon
        points="128.59 313.24 133.8 304.22 128.59 295.2 118.23 295.2 113.02 304.22 118.23 313.24 128.59 313.24"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "123.41px 304.22px",
        }}
        id="ell1sb3cex1bp"
        className="animable"
      />
      <polygon
        points="112.52 303.94 117.72 294.93 112.52 285.91 102.15 285.91 96.95 294.93 102.15 303.94 112.52 303.94"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "107.335px 294.925px",
        }}
        id="eln0rmfm02cqe"
        className="animable"
      />
      <polygon
        points="160.74 313.24 165.95 304.22 160.74 295.2 150.38 295.2 145.17 304.22 150.38 313.24 160.74 313.24"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "155.56px 304.22px",
        }}
        id="ellxuyoz87ak"
        className="animable"
      />
      <polygon
        points="176.82 303.94 182.02 294.93 176.82 285.91 166.45 285.91 161.25 294.93 166.45 303.94 176.82 303.94"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "171.635px 294.925px",
        }}
        id="el8mykji2sby5"
        className="animable"
      />
      <polygon
        points="70 303.94 80.37 303.94 85.57 294.93 80.37 285.91 70 285.91 64.8 294.93 70 303.94"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "75.185px 294.925px",
        }}
        id="elnf6fi5l661"
        className="animable"
      />
      <polygon
        points="192.89 294.65 198.1 285.63 192.89 276.61 182.53 276.61 177.32 285.63 182.53 294.65 192.89 294.65"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "187.71px 285.63px",
        }}
        id="elv327f33q3yd"
        className="animable"
      />
      <path
        d="M70,285.35H80.37l5.2-9-5.2-9h-9c-1.09,1-2.15,2-3.15,3l-3.47,6Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "75.16px 276.35px",
        }}
        id="eler22odlpfth"
        className="animable"
      />
      <polygon
        points="70 322.54 80.37 322.54 85.57 313.52 80.37 304.5 70 304.5 64.8 313.52 70 322.54"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "75.185px 313.52px",
        }}
        id="elm5v1w1ea05l"
        className="animable"
      />
      <polygon
        points="96.44 313.24 101.65 304.22 96.44 295.2 86.08 295.2 80.87 304.22 86.08 313.24 96.44 313.24"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "91.26px 304.22px",
        }}
        id="el239mtfbxozoh"
        className="animable"
      />
      <polygon
        points="289.34 276.06 294.55 267.04 289.34 258.02 278.98 258.02 273.77 267.04 278.98 276.06 289.34 276.06"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "284.16px 267.04px",
        }}
        id="el6evyn76ujmi"
        className="animable"
      />
      <polygon
        points="321.49 294.65 326.7 285.63 321.49 276.61 311.13 276.61 305.92 285.63 311.13 294.65 321.49 294.65"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "316.31px 285.63px",
        }}
        id="elvfop7qym9g"
        className="animable"
      />
      <polygon
        points="353.64 313.24 358.85 304.22 353.64 295.2 343.28 295.2 338.07 304.22 343.28 313.24 353.64 313.24"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "348.46px 304.22px",
        }}
        id="ela79z7e0ah6e"
        className="animable"
      />
      <polygon
        points="305.42 285.35 310.62 276.34 305.42 267.32 295.05 267.32 289.85 276.34 295.05 285.35 305.42 285.35"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "300.235px 276.335px",
        }}
        id="elclbrsvtemvh"
        className="animable"
      />
      <polygon
        points="337.56 303.94 342.77 294.93 337.56 285.91 327.2 285.91 322 294.93 327.2 303.94 337.56 303.94"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "332.385px 294.925px",
        }}
        id="el0jla92c32qs"
        className="animable"
      />
      <polygon
        points="225.04 294.65 230.25 285.63 225.04 276.61 214.68 276.61 209.47 285.63 214.68 294.65 225.04 294.65"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "219.86px 285.63px",
        }}
        id="el4zqj0s51p2n"
        className="animable"
      />
      <polygon
        points="273.26 285.35 278.47 276.34 273.26 267.32 262.9 267.32 257.7 276.34 262.9 285.35 273.26 285.35"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "268.085px 276.335px",
        }}
        id="eltcls22soxo"
        className="animable"
      />
      <polygon
        points="208.97 285.35 214.17 276.34 208.97 267.32 198.6 267.32 193.4 276.34 198.6 285.35 208.97 285.35"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "203.785px 276.335px",
        }}
        id="el1q7c4hkidek"
        className="animable"
      />
      <polygon
        points="241.12 285.35 246.32 276.34 241.12 267.32 230.75 267.32 225.55 276.34 230.75 285.35 241.12 285.35"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "235.935px 276.335px",
        }}
        id="elizf3fz3f0gh"
        className="animable"
      />
      <polygon
        points="257.19 276.06 262.4 267.04 257.19 258.02 246.83 258.02 241.62 267.04 246.83 276.06 257.19 276.06"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "252.01px 267.04px",
        }}
        id="el37mfxk3uxur"
        className="animable"
      />
      <polygon
        points="401.87 210.99 407.07 201.97 401.87 192.96 391.5 192.96 386.3 201.97 391.5 210.99 401.87 210.99"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "396.685px 201.975px",
        }}
        id="elby8l1n1aw4"
        className="animable"
      />
      <polygon
        points="144.67 229.58 149.87 220.57 144.67 211.55 134.3 211.55 129.1 220.57 134.3 229.58 144.67 229.58"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "139.485px 220.565px",
        }}
        id="el6efnx5dijnc"
        className="animable"
      />
      <polygon
        points="128.59 238.88 133.8 229.86 128.59 220.84 118.23 220.84 113.02 229.86 118.23 238.88 128.59 238.88"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "123.41px 229.86px",
        }}
        id="elwayjgbi39bi"
        className="animable"
      />
      <polygon
        points="192.89 238.88 198.1 229.86 192.89 220.84 182.53 220.84 177.32 229.86 182.53 238.88 192.89 238.88"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "187.71px 229.86px",
        }}
        id="elhvzcgwix00i"
        className="animable"
      />
      <polygon
        points="160.74 238.88 165.95 229.86 160.74 220.84 150.38 220.84 145.17 229.86 150.38 238.88 160.74 238.88"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "155.56px 229.86px",
        }}
        id="el321dcviyzqa"
        className="animable"
      />
      <path
        d="M87.84,183.66a34.24,34.24,0,0,0,2.28,18h6.32l5.21-9-5.21-9Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "94.5723px 192.66px",
        }}
        id="elu4e78jzao5f"
        className="animable"
      />
      <path
        d="M96.44,202.25H90.35c2.27,5.45,5.47,10.16,8,14.79l3.33-5.77Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "96.015px 209.645px",
        }}
        id="elckik6bydwv4"
        className="animable"
      />
      <polygon
        points="102.15 192.96 96.95 201.97 102.15 210.99 112.52 210.99 117.72 201.97 112.52 192.96 102.15 192.96"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "107.335px 201.975px",
        }}
        id="elhxd3xnevqbq"
        className="animable"
      />
      <polygon
        points="150.38 202.25 145.17 211.27 150.38 220.29 160.74 220.29 165.95 211.27 160.74 202.25 150.38 202.25"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "155.56px 211.27px",
        }}
        id="el20b9bzpfp2aj"
        className="animable"
      />
      <path
        d="M117.72,220.57l-5.2-9H102.15l-3.51,6.08c2,3.89,3.56,7.74,3.58,12h10.3Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "108.18px 220.61px",
        }}
        id="el696y1kr0399"
        className="animable"
      />
      <polygon
        points="128.59 220.29 133.8 211.27 128.59 202.25 118.23 202.25 113.02 211.27 118.23 220.29 128.59 220.29"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "123.41px 211.27px",
        }}
        id="el97rsfs55etj"
        className="animable"
      />
      <polygon
        points="160.74 257.47 165.95 248.45 160.74 239.43 150.38 239.43 145.17 248.45 150.38 257.47 160.74 257.47"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "155.56px 248.45px",
        }}
        id="elrzzyyd53i1"
        className="animable"
      />
      <polygon
        points="128.59 257.47 133.8 248.45 128.59 239.43 118.23 239.43 113.02 248.45 118.23 257.47 128.59 257.47"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "123.41px 248.45px",
        }}
        id="els3x3lovu7x"
        className="animable"
      />
      <polygon
        points="134.3 192.96 129.1 201.97 134.3 210.99 144.67 210.99 149.87 201.97 144.67 192.96 134.3 192.96"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "139.485px 201.975px",
        }}
        id="ele4keny31btj"
        className="animable"
      />
      <polygon
        points="198.6 211.55 193.4 220.57 198.6 229.58 208.97 229.58 214.17 220.57 208.97 211.55 198.6 211.55"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "203.785px 220.565px",
        }}
        id="elde6ktfkybrc"
        className="animable"
      />
      <polygon
        points="176.82 248.17 182.02 239.16 176.82 230.14 166.45 230.14 161.25 239.16 166.45 248.17 176.82 248.17"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "171.635px 239.155px",
        }}
        id="eltdvawh43et"
        className="animable"
      />
      <polygon
        points="144.67 248.17 149.87 239.16 144.67 230.14 134.3 230.14 129.1 239.16 134.3 248.17 144.67 248.17"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "139.485px 239.155px",
        }}
        id="elzmwmjsxpydb"
        className="animable"
      />
      <polygon
        points="118.23 183.66 113.02 192.68 118.23 201.7 128.59 201.7 133.8 192.68 128.59 183.66 118.23 183.66"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "123.41px 192.68px",
        }}
        id="elpi749wgse0r"
        className="animable"
      />
      <polygon
        points="102.15 174.37 96.95 183.38 102.15 192.4 112.52 192.4 117.72 183.38 112.52 174.37 102.15 174.37"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "107.335px 183.385px",
        }}
        id="el9thrx7v4yht"
        className="animable"
      />
      <path
        d="M94.32,165.07a45.62,45.62,0,0,0-6.4,18h8.52l5.21-9-5.21-9Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "94.785px 174.07px",
        }}
        id="elepq9u8nawk"
        className="animable"
      />
      <path
        d="M112.52,248.17l5.2-9-5.2-9H102.21a22.24,22.24,0,0,1-3.89,11.4l3.83,6.63Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "108.02px 239.185px",
        }}
        id="el7dix58z3swn"
        className="animable"
      />
      <polygon
        points="176.82 229.58 182.02 220.57 176.82 211.55 166.45 211.55 161.25 220.57 166.45 229.58 176.82 229.58"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "171.635px 220.565px",
        }}
        id="elukcuizatrr"
        className="animable"
      />
      <path
        d="M354.15,109l5.2,9h10.36l5.21-9-1.48-2.57c-3.59-2.18-7.5-4.34-11.78-6.44h-2.31Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "364.535px 108.995px",
        }}
        id="elfk0lhtkxbsk"
        className="animable"
      />
      <path
        d="M337.56,341.68H327.2l-5.2,9,5.2,9h4.58c2.55-1.48,5.31-2.95,8.31-4.37l2.68-4.64Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "332.385px 350.68px",
        }}
        id="eld41rzp1yz0s"
        className="animable"
      />
      <path
        d="M214.68,108.75H225l5.21-9-.85-1.46c-2,1.12-3.83,2.25-5.48,3.39-3.39,2.35-6.54,4.47-9.62,6.44Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "222.235px 103.52px",
        }}
        id="elw2pi8lff96e"
        className="animable"
      />
      <polygon
        points="214.68 202.25 209.47 211.27 214.68 220.29 225.04 220.29 230.25 211.27 225.04 202.25 214.68 202.25"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "219.86px 211.27px",
        }}
        id="eljbzm6nxonm"
        className="animable"
      />
      <path
        d="M385.79,332.38H375.43l-5.21,9,2.1,3.64A132.71,132.71,0,0,1,390,343.17l1-1.77Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "380.61px 338.7px",
        }}
        id="el5pnwogr790o"
        className="animable"
      />
      <path
        d="M455.46,303.34a60.7,60.7,0,0,0-1.64-14l-3.22,5.58Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "453.03px 296.34px",
        }}
        id="elkdl0je3wemj"
        className="animable"
      />
      <path
        d="M273.27,378.86H262.9l-5.22,9,.71,1.21a62.32,62.32,0,0,0,18.68,1.24l1.41-2.45Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "268.08px 384.696px",
        }}
        id="elgdogb6d7qft"
        className="animable"
      />
      <path
        d="M293.23,83.43a149.38,149.38,0,0,0-17.61.9L279,90.16h10.36Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "284.425px 86.7926px",
        }}
        id="elxuvch25az3e"
        className="animable"
      />
      <path
        d="M353.64,332.38H343.28l-5.21,9,5.21,9h8.85c.63-.22,1.25-.45,1.89-.66l4.83-8.36Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "348.46px 341.38px",
        }}
        id="elumv8ysjfnqm"
        className="animable"
      />
      <polygon
        points="278.98 108.75 289.34 108.75 294.55 99.73 289.34 90.71 278.98 90.71 273.77 99.73 278.98 108.75"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "284.16px 99.73px",
        }}
        id="elw0qo5hh7yed"
        className="animable"
      />
      <path
        d="M182.53,127.34h10.36l5.21-9-.48-.83c-4.93,2.38-10.33,4.66-16.72,7Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "189.5px 122.425px",
        }}
        id="elylos7ubzfqk"
        className="animable"
      />
      <path
        d="M102.15,173.81h10.37l5.2-9-5.2-9h-10l-.68.6L97,164.8Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "107.36px 164.81px",
        }}
        id="el8koo1nvdc1f"
        className="animable"
      />
      <polygon
        points="241.12 229.58 246.32 220.57 241.12 211.55 230.75 211.55 225.55 220.57 230.75 229.58 241.12 229.58"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "235.935px 220.565px",
        }}
        id="elvckfvieoys"
        className="animable"
      />
      <polygon
        points="214.68 220.84 209.47 229.86 214.68 238.88 225.04 238.88 230.25 229.86 225.04 220.84 214.68 220.84"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "219.86px 229.86px",
        }}
        id="eld06a3c3ho5b"
        className="animable"
      />
      <path
        d="M134.3,155.22h10.37l5.2-9-5.2-9h-3.16q-4.69,1.5-8.95,3l-3.46,6Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "139.485px 146.22px",
        }}
        id="el91ivdorb68f"
        className="animable"
      />
      <path
        d="M85.57,332.11l-5.2-9H70l-4.81,8.34a38.11,38.11,0,0,0,7.24,9.69h7.94Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "75.38px 332.125px",
        }}
        id="eltf6esgz8vq"
        className="animable"
      />
      <path
        d="M160.74,332.38H150.38l-5.21,9,5.12,8.87a43.3,43.3,0,0,1,7.15.15h3.3l5.21-9Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "155.56px 341.39px",
        }}
        id="el44buq5qhq3u"
        className="animable"
      />
      <path
        d="M85.57,257.75l-.8-1.39C80.4,260.06,76,263.3,72,266.76h8.33Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "78.785px 261.56px",
        }}
        id="elgme6omg23yj"
        className="animable"
      />
      <path
        d="M150.38,145.93h10.36l5.21-9-3.55-6.15c-3.41,1.07-7,2.19-11,3.36-1.94.58-3.82,1.15-5.66,1.72l-.62,1.07Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "155.535px 138.355px",
        }}
        id="elsq4919vqsqb"
        className="animable"
      />
      <path
        d="M69.5,304.22l-5.21-9H57.74a58.08,58.08,0,0,0,.2,7.58c.33,3.77.79,7.24,1.37,10.46h5Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "63.598px 304.24px",
        }}
        id="el9vnno5vjdrn"
        className="animable"
      />
      <path
        d="M101.65,341.4l-5.21-9H86.08l-5.21,9,4.89,8.46c.41.19.83.38,1.26.56h9.42Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "91.26px 341.41px",
        }}
        id="elnca7pch8rn8"
        className="animable"
      />
      <polygon
        points="327.2 192.96 322 201.97 327.2 210.99 337.56 210.99 342.77 201.97 337.56 192.96 327.2 192.96"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "332.385px 201.975px",
        }}
        id="elby3ffbm3gwb"
        className="animable"
      />
      <polygon
        points="295.05 211.55 289.85 220.57 295.05 229.58 305.42 229.58 310.62 220.57 305.42 211.55 295.05 211.55"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "300.235px 220.565px",
        }}
        id="elkzlq5yh6h2d"
        className="animable"
      />
      <polygon
        points="311.13 202.25 305.92 211.27 311.13 220.29 321.49 220.29 326.7 211.27 321.49 202.25 311.13 202.25"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "316.31px 211.27px",
        }}
        id="elvu8obk1r28q"
        className="animable"
      />
      <polygon
        points="246.83 202.25 241.62 211.27 246.83 220.29 257.19 220.29 262.4 211.27 257.19 202.25 246.83 202.25"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "252.01px 211.27px",
        }}
        id="elj9jmrw56mwb"
        className="animable"
      />
      <polygon
        points="278.98 202.25 273.77 211.27 278.98 220.29 289.34 220.29 294.55 211.27 289.34 202.25 278.98 202.25"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "284.16px 211.27px",
        }}
        id="elyz8d647pxea"
        className="animable"
      />
      <polygon
        points="262.9 211.55 257.7 220.57 262.9 229.58 273.26 229.58 278.47 220.57 273.26 211.55 262.9 211.55"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "268.085px 220.565px",
        }}
        id="eltvquz65g72a"
        className="animable"
      />
      <polygon
        points="337.56 266.76 342.77 257.75 337.56 248.73 327.2 248.73 322 257.75 327.2 266.76 337.56 266.76"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "332.385px 257.745px",
        }}
        id="ellcxb3tflmg7"
        className="animable"
      />
      <polygon
        points="321.49 257.47 326.7 248.45 321.49 239.43 311.13 239.43 305.92 248.45 311.13 257.47 321.49 257.47"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "316.31px 248.45px",
        }}
        id="elj4y0lxqc9c"
        className="animable"
      />
      <polygon
        points="337.56 230.14 327.2 230.14 322 239.16 327.2 248.17 337.56 248.17 342.77 239.16 337.56 230.14"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "332.385px 239.155px",
        }}
        id="elvgqdp4hyi3"
        className="animable"
      />
      <polygon
        points="337.56 211.55 327.2 211.55 322 220.57 327.2 229.58 337.56 229.58 342.77 220.57 337.56 211.55"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "332.385px 220.565px",
        }}
        id="elzq9xbgkdw2"
        className="animable"
      />
      <polygon
        points="353.64 220.29 358.85 211.27 353.64 202.25 343.28 202.25 338.07 211.27 343.28 220.29 353.64 220.29"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "348.46px 211.27px",
        }}
        id="el70lffs56q8"
        className="animable"
      />
      <polygon
        points="150.38 165.07 145.17 174.09 150.38 183.11 160.74 183.11 165.95 174.09 160.74 165.07 150.38 165.07"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "155.56px 174.09px",
        }}
        id="elbsl2y8bfs6b"
        className="animable"
      />
      <polygon
        points="134.3 155.78 129.1 164.79 134.3 173.81 144.67 173.81 149.87 164.79 144.67 155.78 134.3 155.78"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "139.485px 164.795px",
        }}
        id="elz9frw5sujr"
        className="animable"
      />
      <polygon
        points="118.23 146.48 113.02 155.5 118.23 164.52 128.59 164.52 133.8 155.5 128.59 146.48 118.23 146.48"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "123.41px 155.5px",
        }}
        id="elin6lbmm28k"
        className="animable"
      />
      <path
        d="M112.52,155.22l5.16-8.94a70.37,70.37,0,0,0-14.52,8.94Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "110.42px 150.75px",
        }}
        id="elq3xxp3hdoak"
        className="animable"
      />
      <polygon
        points="198.6 192.96 193.4 201.97 198.6 210.99 208.97 210.99 214.17 201.97 208.97 192.96 198.6 192.96"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "203.785px 201.975px",
        }}
        id="elf62mof62an7"
        className="animable"
      />
      <polygon
        points="166.45 174.37 161.25 183.38 166.45 192.4 176.82 192.4 182.02 183.38 176.82 174.37 166.45 174.37"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "171.635px 183.385px",
        }}
        id="elwk3pxssszv"
        className="animable"
      />
      <polygon
        points="230.75 192.96 225.55 201.97 230.75 210.99 241.12 210.99 246.32 201.97 241.12 192.96 230.75 192.96"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "235.935px 201.975px",
        }}
        id="elmh7q9mi945s"
        className="animable"
      />
      <polygon
        points="214.68 183.66 209.47 192.68 214.68 201.7 225.04 201.7 230.25 192.68 225.04 183.66 214.68 183.66"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "219.86px 192.68px",
        }}
        id="el8vf4k7d976u"
        className="animable"
      />
      <polygon
        points="182.53 183.66 177.32 192.68 182.53 201.7 192.89 201.7 198.1 192.68 192.89 183.66 182.53 183.66"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "187.71px 192.68px",
        }}
        id="ellpfcog085uq"
        className="animable"
      />
      <polygon
        points="112.52 266.76 117.72 257.75 112.52 248.73 102.15 248.73 96.95 257.75 102.15 266.76 112.52 266.76"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "107.335px 257.745px",
        }}
        id="elpr2gc3pr8oa"
        className="animable"
      />
      <path
        d="M94.68,164.52h1.76l3.94-6.83A41,41,0,0,0,94.68,164.52Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "97.53px 161.105px",
        }}
        id="elp1cwgrf8dj"
        className="animable"
      />
      <polygon
        points="160.74 276.06 165.95 267.04 160.74 258.02 150.38 258.02 145.17 267.04 150.38 276.06 160.74 276.06"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "155.56px 267.04px",
        }}
        id="el9ervnhkb5x"
        className="animable"
      />
      <polygon
        points="182.53 202.25 177.32 211.27 182.53 220.29 192.89 220.29 198.1 211.27 192.89 202.25 182.53 202.25"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "187.71px 211.27px",
        }}
        id="elj98a09yu5uf"
        className="animable"
      />
      <polygon
        points="128.59 276.06 133.8 267.04 128.59 258.02 118.23 258.02 113.02 267.04 118.23 276.06 128.59 276.06"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "123.41px 267.04px",
        }}
        id="elhoc7hy192aa"
        className="animable"
      />
      <polygon
        points="144.67 266.76 149.87 257.75 144.67 248.73 134.3 248.73 129.1 257.75 134.3 266.76 144.67 266.76"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "139.485px 257.745px",
        }}
        id="elfoj7bcx3te"
        className="animable"
      />
      <path
        d="M96.44,257.47l5.21-9L98,242.08c-.21.33-.43.66-.67,1A70.21,70.21,0,0,1,85.22,256l.86,1.49Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "93.435px 249.785px",
        }}
        id="elt8ssoiacatl"
        className="animable"
      />
      <polygon
        points="150.38 183.66 145.17 192.68 150.38 201.7 160.74 201.7 165.95 192.68 160.74 183.66 150.38 183.66"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "155.56px 192.68px",
        }}
        id="elbksjfddq00w"
        className="animable"
      />
      <polygon
        points="166.45 192.96 161.25 201.97 166.45 210.99 176.82 210.99 182.02 201.97 176.82 192.96 166.45 192.96"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "171.635px 201.975px",
        }}
        id="el89nf0jd879d"
        className="animable"
      />
      <polygon
        points="118.23 165.07 113.02 174.09 118.23 183.11 128.59 183.11 133.8 174.09 128.59 165.07 118.23 165.07"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "123.41px 174.09px",
        }}
        id="els8vaifbigd"
        className="animable"
      />
      <polygon
        points="134.3 174.37 129.1 183.38 134.3 192.4 144.67 192.4 149.87 183.38 144.67 174.37 134.3 174.37"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "139.485px 183.385px",
        }}
        id="elqqvzc9n2fw"
        className="animable"
      />
      <polygon
        points="257.19 238.88 262.4 229.86 257.19 220.84 246.83 220.84 241.62 229.86 246.83 238.88 257.19 238.88"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "252.01px 229.86px",
        }}
        id="elsg8ehy2oq6f"
        className="animable"
      />
      <polygon
        points="289.34 238.88 294.55 229.86 289.34 220.84 278.98 220.84 273.77 229.86 278.98 238.88 289.34 238.88"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "284.16px 229.86px",
        }}
        id="elnq3n8imdya"
        className="animable"
      />
      <polygon
        points="273.26 248.17 278.47 239.16 273.26 230.14 262.9 230.14 257.7 239.16 262.9 248.17 273.26 248.17"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "268.085px 239.155px",
        }}
        id="elx8navuq5ssq"
        className="animable"
      />
      <polygon
        points="305.42 248.17 310.62 239.16 305.42 230.14 295.05 230.14 289.85 239.16 295.05 248.17 305.42 248.17"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "300.235px 239.155px",
        }}
        id="el5yiv9g0lw4n"
        className="animable"
      />
      <polygon
        points="321.49 238.88 326.7 229.86 321.49 220.84 311.13 220.84 305.92 229.86 311.13 238.88 321.49 238.88"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "316.31px 229.86px",
        }}
        id="elvanfodfsbc"
        className="animable"
      />
      <polygon
        points="192.89 257.47 198.1 248.45 192.89 239.43 182.53 239.43 177.32 248.45 182.53 257.47 192.89 257.47"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "187.71px 248.45px",
        }}
        id="elgar2dl0736p"
        className="animable"
      />
      <polygon
        points="176.82 266.76 182.02 257.75 176.82 248.73 166.45 248.73 161.25 257.75 166.45 266.76 176.82 266.76"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "171.635px 257.745px",
        }}
        id="elx60r6tq011p"
        className="animable"
      />
      <polygon
        points="208.97 248.17 214.17 239.16 208.97 230.14 198.6 230.14 193.4 239.16 198.6 248.17 208.97 248.17"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "203.785px 239.155px",
        }}
        id="elu0i7nzwcbwq"
        className="animable"
      />
      <polygon
        points="241.12 248.17 246.32 239.16 241.12 230.14 230.75 230.14 225.55 239.16 230.75 248.17 241.12 248.17"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "235.935px 239.155px",
        }}
        id="el7ovcbdet9kj"
        className="animable"
      />
      <polygon
        points="225.04 257.47 230.25 248.45 225.04 239.43 214.68 239.43 209.47 248.45 214.68 257.47 225.04 257.47"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "219.86px 248.45px",
        }}
        id="el857kd3uwewq"
        className="animable"
      />
      <polygon
        points="257.19 257.47 262.4 248.45 257.19 239.43 246.83 239.43 241.62 248.45 246.83 257.47 257.19 257.47"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "252.01px 248.45px",
        }}
        id="elg5dc3qi41l"
        className="animable"
      />
      <path
        d="M375.43,108.75h1.63c-.84-.56-1.7-1.11-2.59-1.66Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "375.765px 107.92px",
        }}
        id="elg1ckz0up2q"
        className="animable"
      />
      <polygon
        points="343.28 109.3 338.07 118.32 343.28 127.34 353.64 127.34 358.85 118.32 353.64 109.3 343.28 109.3"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "348.46px 118.32px",
        }}
        id="ellowoe8x1bwc"
        className="animable"
      />
      <path
        d="M431.38,239.27a100.45,100.45,0,0,1-4.61-9.13h-3.12l-5.2,9,5.2,9H434l1.2-2.08C434,243.86,432.7,241.58,431.38,239.27Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "426.825px 239.14px",
        }}
        id="elnefdgkyiueg"
        className="animable"
      />
      <polygon
        points="327.2 100.01 322 109.03 327.2 118.04 337.56 118.04 342.77 109.03 337.56 100.01 327.2 100.01"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "332.385px 109.025px",
        }}
        id="elufv9n25elw"
        className="animable"
      />
      <polygon
        points="311.13 109.3 305.92 118.32 311.13 127.34 321.49 127.34 326.7 118.32 321.49 109.3 311.13 109.3"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "316.31px 118.32px",
        }}
        id="eljbexi0vh73"
        className="animable"
      />
      <polygon
        points="434.01 266.76 439.22 257.75 434.01 248.73 423.65 248.73 418.45 257.75 423.65 266.76 434.01 266.76"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "428.835px 257.745px",
        }}
        id="el96ir57gzcb"
        className="animable"
      />
      <polygon
        points="417.94 276.61 407.58 276.61 402.37 285.63 407.58 294.65 417.94 294.65 423.15 285.63 417.94 276.61"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "412.76px 285.63px",
        }}
        id="el8fqvl2x1c78"
        className="animable"
      />
      <polygon
        points="417.94 295.2 407.58 295.2 402.37 304.22 407.58 313.24 417.94 313.24 423.15 304.22 417.94 295.2"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "412.76px 304.22px",
        }}
        id="el2razu8am1q6"
        className="animable"
      />
      <polygon
        points="417.94 258.02 407.58 258.02 402.37 267.04 407.58 276.06 417.94 276.06 423.15 267.04 417.94 258.02"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "412.76px 267.04px",
        }}
        id="el7qd3461kxxb"
        className="animable"
      />
      <polygon
        points="295.05 118.6 289.85 127.61 295.05 136.63 305.42 136.63 310.62 127.61 305.42 118.6 295.05 118.6"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "300.235px 127.615px",
        }}
        id="eltvcogloipj"
        className="animable"
      />
      <polygon
        points="225.04 369.01 230.25 359.99 225.04 350.97 214.68 350.97 209.47 359.99 214.68 369.01 225.04 369.01"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "219.86px 359.99px",
        }}
        id="el1l3o90ggcml"
        className="animable"
      />
      <polygon
        points="278.98 109.3 273.77 118.32 278.98 127.34 289.34 127.34 294.55 118.32 289.34 109.3 278.98 109.3"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "284.16px 118.32px",
        }}
        id="elnjnmjcborj"
        className="animable"
      />
      <path
        d="M192.89,351H182.53l-3.41,5.91c5.22,2.2,10.75,4.75,16.48,7.44l2.5-4.33Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "188.61px 357.675px",
        }}
        id="el8u8vi66lllx"
        className="animable"
      />
      <polygon
        points="417.94 313.79 407.58 313.79 402.37 322.81 407.58 331.83 417.94 331.83 423.15 322.81 417.94 313.79"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "412.76px 322.81px",
        }}
        id="elteeacfvsxk"
        className="animable"
      />
      <polygon
        points="257.19 350.42 262.4 341.4 257.19 332.38 246.83 332.38 241.62 341.4 246.83 350.42 257.19 350.42"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "252.01px 341.4px",
        }}
        id="el9rezep9a2d9"
        className="animable"
      />
      <polygon
        points="241.12 359.71 246.32 350.7 241.12 341.68 230.75 341.68 225.55 350.7 230.75 359.71 241.12 359.71"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "235.935px 350.695px",
        }}
        id="el51qe6rmt4wb"
        className="animable"
      />
      <polygon
        points="208.97 359.71 214.17 350.7 208.97 341.68 198.6 341.68 193.4 350.7 198.6 359.71 208.97 359.71"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "203.785px 350.695px",
        }}
        id="elh116b88kvew"
        className="animable"
      />
      <path
        d="M198.6,118H209l5.2-9-.35-.61a157.27,157.27,0,0,1-15.68,8.83Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "206.185px 113.195px",
        }}
        id="el6h06ojdu0op"
        className="animable"
      />
      <polygon
        points="262.9 118.6 257.7 127.61 262.9 136.63 273.26 136.63 278.47 127.61 273.26 118.6 262.9 118.6"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "268.085px 127.615px",
        }}
        id="elzcchb3pr7yq"
        className="animable"
      />
      <polygon
        points="246.83 109.3 241.62 118.32 246.83 127.34 257.19 127.34 262.4 118.32 257.19 109.3 246.83 109.3"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "252.01px 118.32px",
        }}
        id="elw5flmt89ak"
        className="animable"
      />
      <polygon
        points="230.75 100.01 225.55 109.03 230.75 118.04 241.12 118.04 246.32 109.03 241.12 100.01 230.75 100.01"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "235.935px 109.025px",
        }}
        id="eljq6utjyr5j"
        className="animable"
      />
      <path
        d="M407.58,332.38l-5.21,9,1,1.65c7,.1,13.31.3,19-.25l.81-1.4-5.21-9Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "412.775px 337.765px",
        }}
        id="elgs0yqxb3kwp"
        className="animable"
      />
      <path
        d="M130.76,354.73,128.59,351H118.23l-3.65,6.33A53.38,53.38,0,0,0,130.76,354.73Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "122.67px 354.165px",
        }}
        id="elu90h9xtqz4"
        className="animable"
      />
      <path
        d="M144.67,341.68H134.3l-5.2,9,2.23,3.86c5.87-1.74,11.56-3.73,18.32-4.25Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "139.375px 348.11px",
        }}
        id="eltdsn3ixxvhc"
        className="animable"
      />
      <polygon
        points="192.89 350.42 198.1 341.4 192.89 332.38 182.53 332.38 177.32 341.4 182.53 350.42 192.89 350.42"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "187.71px 341.4px",
        }}
        id="elruqyp4wu3m8"
        className="animable"
      />
      <path
        d="M176.82,341.68H166.45l-5.2,9,.18.31a60.11,60.11,0,0,1,9.58,2.66c2.43.89,5,1.9,7.57,3l3.44-6Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "171.635px 349.165px",
        }}
        id="elt5ira93cxb9"
        className="animable"
      />
      <path
        d="M112.52,341.68H102.15l-5.2,9,2.27,3.93c1.29.37,2.59.73,4,1.08a47.63,47.63,0,0,0,10.75,1.57l3.8-6.58Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "107.36px 349.47px",
        }}
        id="elki7mdtygpxo"
        className="animable"
      />
      <polygon
        points="230.75 118.6 225.55 127.61 230.75 136.63 241.12 136.63 246.32 127.61 241.12 118.6 230.75 118.6"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "235.935px 127.615px",
        }}
        id="elelnit290jaf"
        className="animable"
      />
      <polygon
        points="214.68 109.3 209.47 118.32 214.68 127.34 225.04 127.34 230.25 118.32 225.04 109.3 214.68 109.3"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "219.86px 118.32px",
        }}
        id="elufvhwoj6dd"
        className="animable"
      />
      <polygon
        points="246.83 127.89 241.62 136.91 246.83 145.93 257.19 145.93 262.4 136.91 257.19 127.89 246.83 127.89"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "252.01px 136.91px",
        }}
        id="elso5pmjxmsen"
        className="animable"
      />
      <polygon
        points="208.97 341.12 214.17 332.11 208.97 323.09 198.6 323.09 193.4 332.11 198.6 341.12 208.97 341.12"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "203.785px 332.105px",
        }}
        id="elusg002otbhr"
        className="animable"
      />
      <polygon
        points="198.6 118.6 193.4 127.61 198.6 136.63 208.97 136.63 214.17 127.61 208.97 118.6 198.6 118.6"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "203.785px 127.615px",
        }}
        id="elti20l65qxc"
        className="animable"
      />
      <path
        d="M343.28,351,341,354.92c2.93-1.36,6.09-2.68,9.54-3.95Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "345.77px 352.945px",
        }}
        id="elrwsihrgzsff"
        className="animable"
      />
      <polygon
        points="305.42 341.12 310.62 332.11 305.42 323.09 295.05 323.09 289.85 332.11 295.05 341.12 305.42 341.12"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "300.235px 332.105px",
        }}
        id="el6apz1v0kp87"
        className="animable"
      />
      <polygon
        points="321.49 350.42 326.7 341.4 321.49 332.38 311.13 332.38 305.92 341.4 311.13 350.42 321.49 350.42"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "316.31px 341.4px",
        }}
        id="elvojts932ee"
        className="animable"
      />
      <polygon
        points="225.04 350.42 230.25 341.4 225.04 332.38 214.68 332.38 209.47 341.4 214.68 350.42 225.04 350.42"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "219.86px 341.4px",
        }}
        id="el4zapo5n6fpx"
        className="animable"
      />
      <path
        d="M423.05,342.72a45.65,45.65,0,0,0,6-1h-5.41Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "426.05px 342.22px",
        }}
        id="el5gnxg68p29x"
        className="animable"
      />
      <polygon
        points="273.26 359.71 278.47 350.7 273.26 341.68 262.9 341.68 257.7 350.7 262.9 359.71 273.26 359.71"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "268.085px 350.695px",
        }}
        id="eldyojgq1qlge"
        className="animable"
      />
      <polygon
        points="241.12 341.12 246.32 332.11 241.12 323.09 230.75 323.09 225.55 332.11 230.75 341.12 241.12 341.12"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "235.935px 332.105px",
        }}
        id="ele5xptbuc6n7"
        className="animable"
      />
      <polygon
        points="257.19 331.83 262.4 322.81 257.19 313.79 246.83 313.79 241.62 322.81 246.83 331.83 257.19 331.83"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "252.01px 322.81px",
        }}
        id="elgbynj5mx4mv"
        className="animable"
      />
      <polygon
        points="289.34 331.83 294.55 322.81 289.34 313.79 278.98 313.79 273.77 322.81 278.98 331.83 289.34 331.83"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "284.16px 322.81px",
        }}
        id="el2mclbcw4i5"
        className="animable"
      />
      <polygon
        points="273.26 341.12 278.47 332.11 273.26 323.09 262.9 323.09 257.7 332.11 262.9 341.12 273.26 341.12"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "268.085px 332.105px",
        }}
        id="elpbzhn1mn89g"
        className="animable"
      />
      <path
        d="M96.44,351H88.35a103.1,103.1,0,0,0,10.08,3.44Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "93.39px 352.72px",
        }}
        id="el0jopwlv6qe8j"
        className="animable"
      />
      <polygon
        points="305.42 378.31 310.62 369.29 305.42 360.27 295.05 360.27 289.85 369.29 295.05 378.31 305.42 378.31"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "300.235px 369.29px",
        }}
        id="ela1k2oqgjwvw"
        className="animable"
      />
      <polygon
        points="289.34 369.01 294.55 359.99 289.34 350.97 278.98 350.97 273.77 359.99 278.98 369.01 289.34 369.01"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "284.16px 359.99px",
        }}
        id="el2i5j8vzhwmk"
        className="animable"
      />
      <path
        d="M311.13,369.57l-5.05,8.74c3.71-2.71,7.33-5.68,11.3-8.74Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "311.73px 373.94px",
        }}
        id="elipqxsvxqc9"
        className="animable"
      />
      <polygon
        points="273.26 378.31 278.47 369.29 273.26 360.27 262.9 360.27 257.7 369.29 262.9 378.31 273.26 378.31"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "268.085px 369.29px",
        }}
        id="el9vwztfvrv08"
        className="animable"
      />
      <path
        d="M305.32,378.86H295.05l-4.78,8.28A62.93,62.93,0,0,0,305.32,378.86Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "297.795px 383px",
        }}
        id="elamerhimc3r"
        className="animable"
      />
      <path
        d="M450.11,313.79H439.73l-5.21,9,5.21,9h5.11c.58-.69,1.15-1.41,1.72-2.18a46.3,46.3,0,0,0,6.18-11.31Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "443.63px 322.79px",
        }}
        id="elbsm3xzjd23s"
        className="animable"
      />
      <path
        d="M241.12,378.86H230.75l-.66,1.15c5,2.06,10,3.93,14.87,5.52Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "237.525px 382.195px",
        }}
        id="el879t8gdmzh"
        className="animable"
      />
      <path
        d="M230.25,378.58l-5.21-9H214.68L213,372.49c5.48,2.54,11,5,16.56,7.31Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "221.625px 374.69px",
        }}
        id="elzhgwfhvwa5"
        className="animable"
      />
      <polygon
        points="241.12 360.27 230.75 360.27 225.55 369.29 230.75 378.31 241.12 378.31 246.32 369.29 241.12 360.27"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "235.935px 369.29px",
        }}
        id="ely3ieq92qqfl"
        className="animable"
      />
      <path
        d="M209,360.27H198.6l-2.48,4.29c5.32,2.5,10.8,5.12,16.34,7.69l1.71-3Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "205.145px 366.26px",
        }}
        id="elg9mex3dpw0m"
        className="animable"
      />
      <polygon
        points="257.19 369.01 262.4 359.99 257.19 350.97 246.83 350.97 241.62 359.99 246.83 369.01 257.19 369.01"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "252.01px 359.99px",
        }}
        id="el4ennu5nfwy9"
        className="animable"
      />
      <path
        d="M450.6,313.52l2.39,4.15a43.14,43.14,0,0,0,2.41-12.48Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "453px 311.43px",
        }}
        id="elnsvyw430b6a"
        className="animable"
      />
      <path
        d="M257.19,388.15H254.1c1.2.3,2.38.57,3.56.82Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "255.88px 388.56px",
        }}
        id="elf8l699mmf56"
        className="animable"
      />
      <path
        d="M289,387.6l.47-.17,5.11-8.85-5.21-9H279l-5.21,9,5.21,9Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "284.185px 378.59px",
        }}
        id="elrxvvxnam30i"
        className="animable"
      />
      <polygon
        points="450.11 295.2 439.73 295.2 434.52 304.22 439.73 313.24 450.11 313.24 455.32 304.22 450.11 295.2"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "444.92px 304.22px",
        }}
        id="eljb6ddyop0ih"
        className="animable"
      />
      <path
        d="M279,388.15l-1.21,2.11c.8-.1,1.6-.21,2.39-.35a60.58,60.58,0,0,0,7.11-1.76Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "282.54px 389.205px",
        }}
        id="elij7o1kmff3s"
        className="animable"
      />
      <path
        d="M449.9,276.61H439.73l-5.21,9,5.21,9h10.38l3.52-6.08A101.72,101.72,0,0,0,449.9,276.61Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "444.075px 285.61px",
        }}
        id="eloq8xtw5qivg"
        className="animable"
      />
      <path
        d="M289.85,90.43l5.2,9h10.37l5.2-9L307,84.17c-4.46-.44-8.84-.69-13.11-.74Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "300.235px 91.43px",
        }}
        id="elhb220ao58z9"
        className="animable"
      />
      <path
        d="M257.7,90.43l5.2,9h10.37l5.2-9-3.48-6A142.81,142.81,0,0,0,259.67,87Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "268.085px 91.93px",
        }}
        id="el7zcilwut9vf"
        className="animable"
      />
      <path
        d="M257.19,387.6l5.21-9-5.21-9H246.83l-5.21,9,4.16,7.21c2.08.66,4.14,1.27,6.18,1.81Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "252.01px 378.61px",
        }}
        id="el4clckhul3mg"
        className="animable"
      />
      <polygon
        points="246.83 90.71 241.62 99.73 246.83 108.75 257.19 108.75 262.4 99.73 257.19 90.71 246.83 90.71"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "252.01px 99.73px",
        }}
        id="el3ksux7ktsgc"
        className="animable"
      />
      <polygon
        points="434.01 285.91 423.65 285.91 418.45 294.93 423.65 303.94 434.01 303.94 439.22 294.93 434.01 285.91"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "428.835px 294.925px",
        }}
        id="elm2now8ukj2f"
        className="animable"
      />
      <polygon
        points="434.01 304.5 423.65 304.5 418.45 313.52 423.65 322.54 434.01 322.54 439.22 313.52 434.01 304.5"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "428.835px 313.52px",
        }}
        id="el5aa9vcdg1xg"
        className="animable"
      />
      <path
        d="M423.65,323.09l-5.2,9,5.2,9H431a29.77,29.77,0,0,0,3.87-1.5l4.33-7.51-5.2-9Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "428.825px 332.085px",
        }}
        id="elxrlcxabcmki"
        className="animable"
      />
      <path
        d="M449.69,276.06a188.85,188.85,0,0,0-8.13-18h-1.83l-5.21,9,5.21,9Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "442.105px 267.06px",
        }}
        id="eladpssljv61e"
        className="animable"
      />
      <polygon
        points="434.01 267.32 423.65 267.32 418.45 276.34 423.65 285.35 434.01 285.35 439.22 276.34 434.01 267.32"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "428.835px 276.335px",
        }}
        id="elvps6g10p1p"
        className="animable"
      />
      <path
        d="M439.73,332.38l-3.91,6.77a29.35,29.35,0,0,0,8.53-6.77Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "440.085px 335.765px",
        }}
        id="elrpa51fleiqd"
        className="animable"
      />
      <polygon
        points="305.42 359.71 310.62 350.7 305.42 341.68 295.05 341.68 289.85 350.7 295.05 359.71 305.42 359.71"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "300.235px 350.695px",
        }}
        id="elfe8wtd3gfhc"
        className="animable"
      />
      <path
        d="M318.1,369c1.86-1.42,3.81-2.86,5.87-4.3L326.7,360l-5.21-9H311.13l-5.21,9,5.21,9Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "316.31px 360px",
        }}
        id="el9ejr90cmv1q"
        className="animable"
      />
      <path
        d="M434.52,248.45l5.21,9h1.55q-2.67-5.25-5.74-10.79Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "437.9px 252.055px",
        }}
        id="el97jppw1tl56"
        className="animable"
      />
      <path
        d="M327.2,360.27,325.07,364c1.82-1.24,3.74-2.47,5.79-3.69Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "327.965px 362.135px",
        }}
        id="el202l5rkbuu1"
        className="animable"
      />
      <path
        d="M230.75,99.45h10.37l4.93-8.54A102.28,102.28,0,0,0,229.91,98Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "237.98px 95.18px",
        }}
        id="eleqlofdmg69n"
        className="animable"
      />
      <polygon
        points="262.9 100.01 257.7 109.03 262.9 118.04 273.26 118.04 278.47 109.03 273.26 100.01 262.9 100.01"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "268.085px 109.025px",
        }}
        id="elz60khrzq6va"
        className="animable"
      />
      <polygon
        points="295.05 100.01 289.85 109.03 295.05 118.04 305.42 118.04 310.62 109.03 305.42 100.01 295.05 100.01"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "300.235px 109.025px",
        }}
        id="elw5iz2z8i3up"
        className="animable"
      />
      <polygon
        points="289.34 350.42 294.55 341.4 289.34 332.38 278.98 332.38 273.77 341.4 278.98 350.42 289.34 350.42"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "284.16px 341.4px",
        }}
        id="elodcpzqw6do"
        className="animable"
      />
      <path
        d="M248.27,90.16h8.92l1.71-3C255.17,88.07,251.62,89.07,248.27,90.16Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "253.585px 88.66px",
        }}
        id="elc2zh1vvbzam"
        className="animable"
      />
      <path
        d="M322,90.43l5.2,9h10.36L342,91.83a157.42,157.42,0,0,0-17.91-5Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "332px 93.13px",
        }}
        id="eln9nqwjuoizt"
        className="animable"
      />
      <path
        d="M343.28,108.75h10.36l5.21-9L358,98.27q-5-2.33-10.73-4.52c-1.59-.61-3.17-1.18-4.75-1.73l-4.45,7.71Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "348.46px 100.385px",
        }}
        id="el3iltn9pukz9"
        className="animable"
      />
      <path
        d="M359.35,99.45h1.17l-1.61-.76Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "359.715px 99.07px",
        }}
        id="elld3a1b3ssq"
        className="animable"
      />
      <path
        d="M311.13,90.16h10.36l2-3.44a151.15,151.15,0,0,0-15.76-2.48Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "315.61px 87.2px",
        }}
        id="el0q4d8b2mn2xp"
        className="animable"
      />
      <polygon
        points="311.13 108.75 321.49 108.75 326.7 99.73 321.49 90.71 311.13 90.71 305.92 99.73 311.13 108.75"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "316.31px 99.73px",
        }}
        id="elx8a0sshb06b"
        className="animable"
      />
      <polygon
        points="311.13 127.89 305.92 136.91 311.13 145.93 321.49 145.93 326.7 136.91 321.49 127.89 311.13 127.89"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "316.31px 136.91px",
        }}
        id="elc6x6c0tszzn"
        className="animable"
      />
      <polygon
        points="369.71 341.12 374.92 332.11 369.71 323.09 359.35 323.09 354.15 332.11 359.35 341.12 369.71 341.12"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "364.535px 332.105px",
        }}
        id="elzhyow2l9bo"
        className="animable"
      />
      <polygon
        points="385.79 258.02 375.43 258.02 370.22 267.04 375.43 276.06 385.79 276.06 391 267.04 385.79 258.02"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "380.61px 267.04px",
        }}
        id="el118lyvznzd8l"
        className="animable"
      />
      <polygon
        points="353.64 331.83 358.85 322.81 353.64 313.79 343.28 313.79 338.07 322.81 343.28 331.83 353.64 331.83"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "348.46px 322.81px",
        }}
        id="elq5xpb0dbnvc"
        className="animable"
      />
      <polygon
        points="385.79 276.61 375.43 276.61 370.22 285.63 375.43 294.65 385.79 294.65 391 285.63 385.79 276.61"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "380.61px 285.63px",
        }}
        id="elxvy1w1rtjr"
        className="animable"
      />
      <polygon
        points="385.79 295.2 375.43 295.2 370.22 304.22 375.43 313.24 385.79 313.24 391 304.22 385.79 295.2"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "380.61px 304.22px",
        }}
        id="el74o72u9ebjn"
        className="animable"
      />
      <polygon
        points="385.79 331.83 391 322.81 385.79 313.79 375.43 313.79 370.22 322.81 375.43 331.83 385.79 331.83"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "380.61px 322.81px",
        }}
        id="elrsx9hgw9m9f"
        className="animable"
      />
      <polygon
        points="305.42 303.94 310.62 294.93 305.42 285.91 295.05 285.91 289.85 294.93 295.05 303.94 305.42 303.94"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "300.235px 294.925px",
        }}
        id="elqdd3z05v8yf"
        className="animable"
      />
      <polygon
        points="289.34 294.65 294.55 285.63 289.34 276.61 278.98 276.61 273.77 285.63 278.98 294.65 289.34 294.65"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "284.16px 285.63px",
        }}
        id="elbtk4316p76r"
        className="animable"
      />
      <polygon
        points="337.56 322.54 342.77 313.52 337.56 304.5 327.2 304.5 322 313.52 327.2 322.54 337.56 322.54"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "332.385px 313.52px",
        }}
        id="el6s6wilrmbe"
        className="animable"
      />
      <polygon
        points="321.49 313.24 326.7 304.22 321.49 295.2 311.13 295.2 305.92 304.22 311.13 313.24 321.49 313.24"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "316.31px 304.22px",
        }}
        id="elu78mzm5phnr"
        className="animable"
      />
      <path
        d="M391.5,136.63h10.36l1.82-3.15a87.43,87.43,0,0,0-12.67-14l-4.71,8.16Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "394.99px 128.055px",
        }}
        id="el7uowzdbevpp"
        className="animable"
      />
      <polygon
        points="343.28 146.48 338.07 155.5 343.28 164.52 353.64 164.52 358.85 155.5 353.64 146.48 343.28 146.48"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "348.46px 155.5px",
        }}
        id="elrqhwcyinpx"
        className="animable"
      />
      <polygon
        points="375.43 127.89 370.22 136.91 375.43 145.93 385.79 145.93 391 136.91 385.79 127.89 375.43 127.89"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "380.61px 136.91px",
        }}
        id="elmcur5v0igxf"
        className="animable"
      />
      <polygon
        points="359.35 137.19 354.15 146.21 359.35 155.22 369.71 155.22 374.92 146.21 369.71 137.19 359.35 137.19"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "364.535px 146.205px",
        }}
        id="ellovedhissj9"
        className="animable"
      />
      <polygon
        points="327.2 137.19 322 146.21 327.2 155.22 337.56 155.22 342.77 146.21 337.56 137.19 327.2 137.19"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "332.385px 146.205px",
        }}
        id="el5a79ndtdvic"
        className="animable"
      />
      <polygon
        points="273.26 303.94 278.47 294.93 273.26 285.91 262.9 285.91 257.7 294.93 262.9 303.94 273.26 303.94"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "268.085px 294.925px",
        }}
        id="ele1ivievmf9"
        className="animable"
      />
      <polygon
        points="385.79 239.43 375.43 239.43 370.22 248.45 375.43 257.47 385.79 257.47 391 248.45 385.79 239.43"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "380.61px 248.45px",
        }}
        id="el9ezriq0edrn"
        className="animable"
      />
      <polygon
        points="401.87 248.17 407.07 239.16 401.87 230.14 391.5 230.14 386.3 239.16 391.5 248.17 401.87 248.17"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "396.685px 239.155px",
        }}
        id="elzonvsuw7xtk"
        className="animable"
      />
      <polygon
        points="401.87 211.55 391.5 211.55 386.3 220.57 391.5 229.58 401.87 229.58 407.07 220.57 401.87 211.55"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "396.685px 220.565px",
        }}
        id="elllos76i4te"
        className="animable"
      />
      <path
        d="M417.94,220.29l3.65-6.32c-.5-2.49-.83-4.9-1.08-7.27l-2.57-4.45H407.58l-5.21,9,5.21,9Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "411.98px 211.27px",
        }}
        id="el97q5iuks13p"
        className="animable"
      />
      <polygon
        points="214.68 146.48 209.47 155.5 214.68 164.52 225.04 164.52 230.25 155.5 225.04 146.48 214.68 146.48"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "219.86px 155.5px",
        }}
        id="el3qu5g4ldf6j"
        className="animable"
      />
      <path
        d="M64.29,276.06l2.2-3.81a39.44,39.44,0,0,0-2.95,3.81Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "65.015px 274.155px",
        }}
        id="el3lc7b6fzusy"
        className="animable"
      />
      <path
        d="M144.67,136.63l.31-.54-1.72.54Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "144.12px 136.36px",
        }}
        id="elnx0rier8glh"
        className="animable"
      />
      <path
        d="M69.5,322.81l-5.21-9H59.41a61.77,61.77,0,0,0,5.46,17Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "64.455px 322.31px",
        }}
        id="elj0p2hbp18z"
        className="animable"
      />
      <path
        d="M64.29,294.65l5.21-9-5.21-9h-1.1a35.38,35.38,0,0,0-5.43,18Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "63.63px 285.65px",
        }}
        id="el9l3pj1hx79r"
        className="animable"
      />
      <polygon
        points="166.45 137.19 161.25 146.21 166.45 155.22 176.82 155.22 182.02 146.21 176.82 137.19 166.45 137.19"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "171.635px 146.205px",
        }}
        id="elza81ytrimma"
        className="animable"
      />
      <polygon
        points="182.53 146.48 177.32 155.5 182.53 164.52 192.89 164.52 198.1 155.5 192.89 146.48 182.53 146.48"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "187.71px 155.5px",
        }}
        id="elc6yhf2sc9a"
        className="animable"
      />
      <polygon
        points="96.44 331.83 101.65 322.81 96.44 313.79 86.08 313.79 80.87 322.81 86.08 331.83 96.44 331.83"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "91.26px 322.81px",
        }}
        id="el1njskrb44wm"
        className="animable"
      />
      <polygon
        points="230.75 155.78 225.55 164.79 230.75 173.81 241.12 173.81 246.32 164.79 241.12 155.78 230.75 155.78"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "235.935px 164.795px",
        }}
        id="el0yk3dwl57n79"
        className="animable"
      />
      <polygon
        points="311.13 146.48 305.92 155.5 311.13 164.52 321.49 164.52 326.7 155.5 321.49 146.48 311.13 146.48"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "316.31px 155.5px",
        }}
        id="el5whdtk5c7zi"
        className="animable"
      />
      <polygon
        points="198.6 155.78 193.4 164.79 198.6 173.81 208.97 173.81 214.17 164.79 208.97 155.78 198.6 155.78"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "203.785px 164.795px",
        }}
        id="elelhrdvma747"
        className="animable"
      />
      <polygon
        points="208.97 303.94 214.17 294.93 208.97 285.91 198.6 285.91 193.4 294.93 198.6 303.94 208.97 303.94"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "203.785px 294.925px",
        }}
        id="el6g8pjwain12"
        className="animable"
      />
      <polygon
        points="112.52 322.54 117.72 313.52 112.52 304.5 102.15 304.5 96.95 313.52 102.15 322.54 112.52 322.54"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "107.335px 313.52px",
        }}
        id="eluw7yh8lnac"
        className="animable"
      />
      <polygon
        points="192.89 313.24 198.1 304.22 192.89 295.2 182.53 295.2 177.32 304.22 182.53 313.24 192.89 313.24"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "187.71px 304.22px",
        }}
        id="eld9znmg1fm3d"
        className="animable"
      />
      <polygon
        points="262.9 137.19 257.7 146.21 262.9 155.22 273.26 155.22 278.47 146.21 273.26 137.19 262.9 137.19"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "268.085px 146.205px",
        }}
        id="elojrbndno5p"
        className="animable"
      />
      <polygon
        points="241.12 303.94 246.32 294.93 241.12 285.91 230.75 285.91 225.55 294.93 230.75 303.94 241.12 303.94"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "235.935px 294.925px",
        }}
        id="ellsfm410hhmb"
        className="animable"
      />
      <polygon
        points="257.19 294.65 262.4 285.63 257.19 276.61 246.83 276.61 241.62 285.63 246.83 294.65 257.19 294.65"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "252.01px 285.63px",
        }}
        id="eluaxcey8pxi"
        className="animable"
      />
      <polygon
        points="128.59 331.83 133.8 322.81 128.59 313.79 118.23 313.79 113.02 322.81 118.23 331.83 128.59 331.83"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "123.41px 322.81px",
        }}
        id="elcjo68jf8jhp"
        className="animable"
      />
      <polygon
        points="144.67 322.54 149.87 313.52 144.67 304.5 134.3 304.5 129.1 313.52 134.3 322.54 144.67 322.54"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "139.485px 313.52px",
        }}
        id="ellan4n4z5ab"
        className="animable"
      />
      <polygon
        points="160.74 331.83 165.95 322.81 160.74 313.79 150.38 313.79 145.17 322.81 150.38 331.83 160.74 331.83"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "155.56px 322.81px",
        }}
        id="elgf04fkj3mi8"
        className="animable"
      />
      <polygon
        points="176.82 322.54 182.02 313.52 176.82 304.5 166.45 304.5 161.25 313.52 166.45 322.54 176.82 322.54"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "171.635px 313.52px",
        }}
        id="eldhbvkfdvip8"
        className="animable"
      />
      <polygon
        points="225.04 313.24 230.25 304.22 225.04 295.2 214.68 295.2 209.47 304.22 214.68 313.24 225.04 313.24"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "219.86px 304.22px",
        }}
        id="elj2aepbmnv4"
        className="animable"
      />
      <polygon
        points="401.87 304.5 391.5 304.5 386.3 313.52 391.5 322.54 401.87 322.54 407.07 313.52 401.87 304.5"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "396.685px 313.52px",
        }}
        id="elitfrawu0mk"
        className="animable"
      />
      <polygon
        points="401.87 285.91 391.5 285.91 386.3 294.93 391.5 303.94 401.87 303.94 407.07 294.93 401.87 285.91"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "396.685px 294.925px",
        }}
        id="elkanqxue3cdm"
        className="animable"
      />
      <polygon
        points="401.87 267.32 391.5 267.32 386.3 276.34 391.5 285.35 401.87 285.35 407.07 276.34 401.87 267.32"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "396.685px 276.335px",
        }}
        id="eli6vkrocrdn"
        className="animable"
      />
      <polygon
        points="401.87 323.09 391.5 323.09 386.3 332.11 391.5 341.12 401.87 341.12 407.07 332.11 401.87 323.09"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "396.685px 332.105px",
        }}
        id="elj1apmwplya"
        className="animable"
      />
      <polygon
        points="401.87 248.73 391.5 248.73 386.3 257.75 391.5 266.76 401.87 266.76 407.07 257.75 401.87 248.73"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "396.685px 257.745px",
        }}
        id="elaauqy74ab9i"
        className="animable"
      />
      <polygon
        points="321.49 331.83 326.7 322.81 321.49 313.79 311.13 313.79 305.92 322.81 311.13 331.83 321.49 331.83"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "316.31px 322.81px",
        }}
        id="elga25epdx1wq"
        className="animable"
      />
      <polygon
        points="337.56 341.12 342.77 332.11 337.56 323.09 327.2 323.09 322 332.11 327.2 341.12 337.56 341.12"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "332.385px 332.105px",
        }}
        id="elprb8sf5931"
        className="animable"
      />
      <polygon
        points="417.94 257.47 423.15 248.45 417.94 239.43 407.58 239.43 402.37 248.45 407.58 257.47 417.94 257.47"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "412.76px 248.45px",
        }}
        id="elfmk81ooyqe"
        className="animable"
      />
      <path
        d="M402.65,343l-.79-1.36H391.5l-.84,1.47C394.87,343,398.87,343,402.65,343Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "396.655px 342.375px",
        }}
        id="eld918edetjyp"
        className="animable"
      />
      <path
        d="M371.72,345.15l-2-3.47H359.35l-4.51,7.81c1.14-.38,2.3-.75,3.49-1.11A121.39,121.39,0,0,1,371.72,345.15Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "363.28px 345.585px",
        }}
        id="elel1z5l9pwml"
        className="animable"
      />
      <path
        d="M370.22,118.32l5.21,9h10.36l4.78-8.29a114.72,114.72,0,0,0-12.67-9.75h-2.47Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "380.395px 118.3px",
        }}
        id="el1bp5re9iaf5"
        className="animable"
      />
      <polygon
        points="295.05 155.78 289.85 164.79 295.05 173.81 305.42 173.81 310.62 164.79 305.42 155.78 295.05 155.78"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "300.235px 164.795px",
        }}
        id="elyl15f437zh"
        className="animable"
      />
      <polygon
        points="246.83 165.07 241.62 174.09 246.83 183.11 257.19 183.11 262.4 174.09 257.19 165.07 246.83 165.07"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "252.01px 174.09px",
        }}
        id="elnmw51g3ogud"
        className="animable"
      />
      <polygon
        points="278.98 127.89 273.77 136.91 278.98 145.93 289.34 145.93 294.55 136.91 289.34 127.89 278.98 127.89"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "284.16px 136.91px",
        }}
        id="elrsdm005gnv"
        className="animable"
      />
      <polygon
        points="327.2 118.6 322 127.61 327.2 136.63 337.56 136.63 342.77 127.61 337.56 118.6 327.2 118.6"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "332.385px 127.615px",
        }}
        id="elx60i891noln"
        className="animable"
      />
      <polygon
        points="359.35 118.6 354.15 127.61 359.35 136.63 369.71 136.63 374.92 127.61 369.71 118.6 359.35 118.6"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "364.535px 127.615px",
        }}
        id="elxj4tuoqag4h"
        className="animable"
      />
      <polygon
        points="343.28 127.89 338.07 136.91 343.28 145.93 353.64 145.93 358.85 136.91 353.64 127.89 343.28 127.89"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "348.46px 136.91px",
        }}
        id="elx4sa44l7nk"
        className="animable"
      />
      <path
        d="M426.52,229.58a76.31,76.31,0,0,1-4.75-14.77l-3.32,5.76,5.2,9Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "422.485px 222.195px",
        }}
        id="elenlrwfgzo2k"
        className="animable"
      />
      <polygon
        points="305.42 322.54 310.62 313.52 305.42 304.5 295.05 304.5 289.85 313.52 295.05 322.54 305.42 322.54"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "300.235px 313.52px",
        }}
        id="elz0v7xvve1b"
        className="animable"
      />
      <polygon
        points="417.94 220.84 407.58 220.84 402.37 229.86 407.58 238.88 417.94 238.88 423.15 229.86 417.94 220.84"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "412.76px 229.86px",
        }}
        id="el36wjdpk7m4y"
        className="animable"
      />
      <polygon
        points="295.05 137.19 289.85 146.21 295.05 155.22 305.42 155.22 310.62 146.21 305.42 137.19 295.05 137.19"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "300.235px 146.205px",
        }}
        id="eldlp81qtvhss"
        className="animable"
      />
      <polygon
        points="289.34 313.24 294.55 304.22 289.34 295.2 278.98 295.2 273.77 304.22 278.98 313.24 289.34 313.24"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "284.16px 304.22px",
        }}
        id="el15gxbegbuob"
        className="animable"
      />
      <polygon
        points="198.6 137.19 193.4 146.21 198.6 155.22 208.97 155.22 214.17 146.21 208.97 137.19 198.6 137.19"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "203.785px 146.205px",
        }}
        id="ellqkmaq6p2a"
        className="animable"
      />
      <path
        d="M163,130.58l3.49,6h10.37l5.2-9-1.67-2.9C175.27,126.58,169.56,128.5,163,130.58Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "172.53px 130.63px",
        }}
        id="eli1ovab1ohqp"
        className="animable"
      />
      <polygon
        points="214.68 127.89 209.47 136.91 214.68 145.93 225.04 145.93 230.25 136.91 225.04 127.89 214.68 127.89"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "219.86px 136.91px",
        }}
        id="eltaj7llm43ic"
        className="animable"
      />
      <path
        d="M80.37,341.68H73a45.8,45.8,0,0,0,11.82,7.77Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "78.91px 345.565px",
        }}
        id="elzkhgnmupvrn"
        className="animable"
      />
      <polygon
        points="112.52 341.12 117.72 332.11 112.52 323.09 102.15 323.09 96.95 332.11 102.15 341.12 112.52 341.12"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "107.335px 332.105px",
        }}
        id="elv7c97gexjfn"
        className="animable"
      />
      <polygon
        points="278.98 146.48 273.77 155.5 278.98 164.52 289.34 164.52 294.55 155.5 289.34 146.48 278.98 146.48"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "284.16px 155.5px",
        }}
        id="elnt3sd0hya5j"
        className="animable"
      />
      <polygon
        points="230.75 137.19 225.55 146.21 230.75 155.22 241.12 155.22 246.32 146.21 241.12 137.19 230.75 137.19"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "235.935px 146.205px",
        }}
        id="eljegjyfnphr"
        className="animable"
      />
      <polygon
        points="262.9 155.78 257.7 164.79 262.9 173.81 273.26 173.81 278.47 164.79 273.26 155.78 262.9 155.78"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "268.085px 164.795px",
        }}
        id="elkdbz9re3hl"
        className="animable"
      />
      <polygon
        points="246.83 146.48 241.62 155.5 246.83 164.52 257.19 164.52 262.4 155.5 257.19 146.48 246.83 146.48"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "252.01px 155.5px",
        }}
        id="elui7vg5pem0e"
        className="animable"
      />
      <polygon
        points="182.53 127.89 177.32 136.91 182.53 145.93 192.89 145.93 198.1 136.91 192.89 127.89 182.53 127.89"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "187.71px 136.91px",
        }}
        id="el6ds3csxhupl"
        className="animable"
      />
      <polygon
        points="241.12 322.54 246.32 313.52 241.12 304.5 230.75 304.5 225.55 313.52 230.75 322.54 241.12 322.54"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "235.935px 313.52px",
        }}
        id="elbuoyfp2abrn"
        className="animable"
      />
      <polygon
        points="257.19 313.24 262.4 304.22 257.19 295.2 246.83 295.2 241.62 304.22 246.83 313.24 257.19 313.24"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "252.01px 304.22px",
        }}
        id="el3nrxwcd5fvg"
        className="animable"
      />
      <polygon
        points="273.26 322.54 278.47 313.52 273.26 304.5 262.9 304.5 257.7 313.52 262.9 322.54 273.26 322.54"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "268.085px 313.52px",
        }}
        id="elaefmq6dgnp"
        className="animable"
      />
      <polygon
        points="128.59 350.42 133.8 341.4 128.59 332.38 118.23 332.38 113.02 341.4 118.23 350.42 128.59 350.42"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "123.41px 341.4px",
        }}
        id="elniqg351fv9d"
        className="animable"
      />
      <polygon
        points="225.04 331.83 230.25 322.81 225.04 313.79 214.68 313.79 209.47 322.81 214.68 331.83 225.04 331.83"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "219.86px 322.81px",
        }}
        id="eletu5ty990ac"
        className="animable"
      />
      <polygon
        points="208.97 322.54 214.17 313.52 208.97 304.5 198.6 304.5 193.4 313.52 198.6 322.54 208.97 322.54"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "203.785px 313.52px",
        }}
        id="elv6yg2n6r36b"
        className="animable"
      />
      <polygon
        points="144.67 341.12 149.87 332.11 144.67 323.09 134.3 323.09 129.1 332.11 134.3 341.12 144.67 341.12"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "139.485px 332.105px",
        }}
        id="elu4cwwxsctm"
        className="animable"
      />
      <polygon
        points="176.82 341.12 182.02 332.11 176.82 323.09 166.45 323.09 161.25 332.11 166.45 341.12 176.82 341.12"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "171.635px 332.105px",
        }}
        id="elqydgd3d6sa"
        className="animable"
      />
      <polygon
        points="192.89 331.83 198.1 322.81 192.89 313.79 182.53 313.79 177.32 322.81 182.53 331.83 192.89 331.83"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "187.71px 322.81px",
        }}
        id="elkzia57euemc"
        className="animable"
      />
      <g id="el8t5otbuq63">
        <g
          style={{
            opacity: 0.6,
            transformOrigin: "256.578px 236.979px",
          }}
          className="animable"
        >
          <polygon
            points="375.43 165.07 370.22 174.09 375.43 183.11 385.79 183.11 391 174.09 385.79 165.07 375.43 165.07"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "380.61px 174.09px",
            }}
            id="el4ux1zn1ns13"
            className="animable"
          />
          <polygon
            points="359.35 174.37 354.15 183.38 359.35 192.4 369.71 192.4 374.92 183.38 369.71 174.37 359.35 174.37"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "364.535px 183.385px",
            }}
            id="elzcrsfe3ppkb"
            className="animable"
          />
          <polygon
            points="343.28 183.66 338.07 192.68 343.28 201.7 353.64 201.7 358.85 192.68 353.64 183.66 343.28 183.66"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "348.46px 192.68px",
            }}
            id="elbuk17xnbre"
            className="animable"
          />
          <polygon
            points="327.2 174.37 322 183.38 327.2 192.4 337.56 192.4 342.77 183.38 337.56 174.37 327.2 174.37"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "332.385px 183.385px",
            }}
            id="elaqb2gco7a1v"
            className="animable"
          />
          <polygon
            points="401.87 192.4 407.07 183.38 401.87 174.37 391.5 174.37 386.3 183.38 391.5 192.4 401.87 192.4"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "396.685px 183.385px",
            }}
            id="elp69x28p7308"
            className="animable"
          />
          <path
            d="M417.94,183.11l1.67-2.89a86.8,86.8,0,0,0-2.11-15.15h-9.92l-5.21,9,5.21,9Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "410.99px 174.09px",
            }}
            id="elxoh9bu0hy7q"
            className="animable"
          />
          <polygon
            points="385.79 201.7 391 192.68 385.79 183.66 375.43 183.66 370.22 192.68 375.43 201.7 385.79 201.7"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "380.61px 192.68px",
            }}
            id="elx3dkev8segr"
            className="animable"
          />
          <polygon
            points="391.5 155.78 386.3 164.79 391.5 173.81 401.87 173.81 407.07 164.79 401.87 155.78 391.5 155.78"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "396.685px 164.795px",
            }}
            id="el78q6yf66nmf"
            className="animable"
          />
          <path
            d="M402.37,155.5l5.21,9h9.79a87.84,87.84,0,0,0-6.62-18h-3.17Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "409.87px 155.5px",
            }}
            id="el6mmc6wz8f8w"
            className="animable"
          />
          <polygon
            points="198.6 174.37 193.4 183.38 198.6 192.4 208.97 192.4 214.17 183.38 208.97 174.37 198.6 174.37"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "203.785px 183.385px",
            }}
            id="elxe6bn5ly2ii"
            className="animable"
          />
          <polygon
            points="369.71 192.96 359.35 192.96 354.15 201.97 359.35 210.99 369.71 210.99 374.92 201.97 369.71 192.96"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "364.535px 201.975px",
            }}
            id="elbc70l20160i"
            className="animable"
          />
          <polygon
            points="230.75 174.37 225.55 183.38 230.75 192.4 241.12 192.4 246.32 183.38 241.12 174.37 230.75 174.37"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "235.935px 183.385px",
            }}
            id="elgepf0bvt9d"
            className="animable"
          />
          <polygon
            points="214.68 165.07 209.47 174.09 214.68 183.11 225.04 183.11 230.25 174.09 225.04 165.07 214.68 165.07"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "219.86px 174.09px",
            }}
            id="el6z07pwd08xk"
            className="animable"
          />
          <polygon
            points="166.45 155.78 161.25 164.79 166.45 173.81 176.82 173.81 182.02 164.79 176.82 155.78 166.45 155.78"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "171.635px 164.795px",
            }}
            id="eltmi8v0tcohn"
            className="animable"
          />
          <polygon
            points="182.53 165.07 177.32 174.09 182.53 183.11 192.89 183.11 198.1 174.09 192.89 165.07 182.53 165.07"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "187.71px 174.09px",
            }}
            id="el65gg9ljl0tn"
            className="animable"
          />
          <polygon
            points="262.9 192.96 257.7 201.97 262.9 210.99 273.26 210.99 278.47 201.97 273.26 192.96 262.9 192.96"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "268.085px 201.975px",
            }}
            id="ele3ain9de6lc"
            className="animable"
          />
          <polygon
            points="278.98 183.66 273.77 192.68 278.98 201.7 289.34 201.7 294.55 192.68 289.34 183.66 278.98 183.66"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "284.16px 192.68px",
            }}
            id="el79ky23wtzkk"
            className="animable"
          />
          <polygon
            points="295.05 192.96 289.85 201.97 295.05 210.99 305.42 210.99 310.62 201.97 305.42 192.96 295.05 192.96"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "300.235px 201.975px",
            }}
            id="elg9u8rmhl3tn"
            className="animable"
          />
          <polygon
            points="311.13 183.66 305.92 192.68 311.13 201.7 321.49 201.7 326.7 192.68 321.49 183.66 311.13 183.66"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "316.31px 192.68px",
            }}
            id="el3pxdmvzymdc"
            className="animable"
          />
          <polygon
            points="246.83 183.66 241.62 192.68 246.83 201.7 257.19 201.7 262.4 192.68 257.19 183.66 246.83 183.66"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "252.01px 192.68px",
            }}
            id="elffpzpu92vda"
            className="animable"
          />
          <polygon
            points="353.64 258.02 343.28 258.02 338.07 267.04 343.28 276.06 353.64 276.06 358.85 267.04 353.64 258.02"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "348.46px 267.04px",
            }}
            id="el09k8gwmiwvj"
            className="animable"
          />
          <polygon
            points="160.74 294.65 165.95 285.63 160.74 276.61 150.38 276.61 145.17 285.63 150.38 294.65 160.74 294.65"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "155.56px 285.63px",
            }}
            id="eln1ff5t8f6je"
            className="animable"
          />
          <polygon
            points="176.82 285.35 182.02 276.34 176.82 267.32 166.45 267.32 161.25 276.34 166.45 285.35 176.82 285.35"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "171.635px 276.335px",
            }}
            id="elwwowjy19mif"
            className="animable"
          />
          <polygon
            points="192.89 276.06 198.1 267.04 192.89 258.02 182.53 258.02 177.32 267.04 182.53 276.06 192.89 276.06"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "187.71px 267.04px",
            }}
            id="eli6ejx1q0ott"
            className="animable"
          />
          <polygon
            points="208.97 266.76 214.17 257.75 208.97 248.73 198.6 248.73 193.4 257.75 198.6 266.76 208.97 266.76"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "203.785px 257.745px",
            }}
            id="eludhsc73vrf"
            className="animable"
          />
          <polygon
            points="225.04 276.06 230.25 267.04 225.04 258.02 214.68 258.02 209.47 267.04 214.68 276.06 225.04 276.06"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "219.86px 267.04px",
            }}
            id="el1folti4er75"
            className="animable"
          />
          <polygon
            points="128.59 294.65 133.8 285.63 128.59 276.61 118.23 276.61 113.02 285.63 118.23 294.65 128.59 294.65"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "123.41px 285.63px",
            }}
            id="elqm7c3xeqqz"
            className="animable"
          />
          <polygon
            points="144.67 285.35 149.87 276.34 144.67 267.32 134.3 267.32 129.1 276.34 134.3 285.35 144.67 285.35"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "139.485px 276.335px",
            }}
            id="elbf50cqdo8f"
            className="animable"
          />
          <polygon
            points="86.08 294.65 96.44 294.65 101.65 285.63 96.44 276.61 86.08 276.61 80.87 285.63 86.08 294.65"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "91.26px 285.63px",
            }}
            id="elqh05huy3cb"
            className="animable"
          />
          <polygon
            points="112.52 285.35 117.72 276.34 112.52 267.32 102.15 267.32 96.95 276.34 102.15 285.35 112.52 285.35"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "107.335px 276.335px",
            }}
            id="elvl5v17tynpi"
            className="animable"
          />
          <polygon
            points="241.12 266.76 246.32 257.75 241.12 248.73 230.75 248.73 225.55 257.75 230.75 266.76 241.12 266.76"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "235.935px 257.745px",
            }}
            id="ele0vkn4jx39u"
            className="animable"
          />
          <polygon
            points="353.64 220.84 343.28 220.84 338.07 229.86 343.28 238.88 353.64 238.88 358.85 229.86 353.64 220.84"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "348.46px 229.86px",
            }}
            id="el0zolv2ayh1vd"
            className="animable"
          />
          <polygon
            points="353.64 239.43 343.28 239.43 338.07 248.45 343.28 257.47 353.64 257.47 358.85 248.45 353.64 239.43"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "348.46px 248.45px",
            }}
            id="eldb037t6temi"
            className="animable"
          />
          <polygon
            points="353.64 294.65 358.85 285.63 353.64 276.61 343.28 276.61 338.07 285.63 343.28 294.65 353.64 294.65"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "348.46px 285.63px",
            }}
            id="eldzcuf0gqbsq"
            className="animable"
          />
          <polygon
            points="273.26 266.76 278.47 257.75 273.26 248.73 262.9 248.73 257.7 257.75 262.9 266.76 273.26 266.76"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "268.085px 257.745px",
            }}
            id="el8asnd3fb9ex"
            className="animable"
          />
          <polygon
            points="369.71 229.58 374.92 220.57 369.71 211.55 359.35 211.55 354.15 220.57 359.35 229.58 369.71 229.58"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "364.535px 220.565px",
            }}
            id="el2dbndk7x1v8"
            className="animable"
          />
          <polygon
            points="150.38 146.48 145.17 155.5 150.38 164.52 160.74 164.52 165.95 155.5 160.74 146.48 150.38 146.48"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "155.56px 155.5px",
            }}
            id="elaty72x8nsxf"
            className="animable"
          />
          <polygon
            points="305.42 266.76 310.62 257.75 305.42 248.73 295.05 248.73 289.85 257.75 295.05 266.76 305.42 266.76"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "300.235px 257.745px",
            }}
            id="elmjf7vlmjo0c"
            className="animable"
          />
          <polygon
            points="337.56 285.35 342.77 276.34 337.56 267.32 327.2 267.32 322 276.34 327.2 285.35 337.56 285.35"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "332.385px 276.335px",
            }}
            id="elldylys2f2dt"
            className="animable"
          />
          <polygon
            points="289.34 257.47 294.55 248.45 289.34 239.43 278.98 239.43 273.77 248.45 278.98 257.47 289.34 257.47"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "284.16px 248.45px",
            }}
            id="el0w26b8gua4rl"
            className="animable"
          />
          <polygon
            points="321.49 276.06 326.7 267.04 321.49 258.02 311.13 258.02 305.92 267.04 311.13 276.06 321.49 276.06"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "316.31px 267.04px",
            }}
            id="el8myc99w6jrx"
            className="animable"
          />
          <polygon
            points="262.9 174.37 257.7 183.38 262.9 192.4 273.26 192.4 278.47 183.38 273.26 174.37 262.9 174.37"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "268.085px 183.385px",
            }}
            id="elpnkearxqsfg"
            className="animable"
          />
          <polygon
            points="385.79 202.25 375.43 202.25 370.22 211.27 375.43 220.29 385.79 220.29 391 211.27 385.79 202.25"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "380.61px 211.27px",
            }}
            id="elgg0zemur915"
            className="animable"
          />
          <polygon
            points="96.44 276.06 101.65 267.04 96.44 258.02 86.08 258.02 80.87 267.04 86.08 276.06 96.44 276.06"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "91.26px 267.04px",
            }}
            id="el79ksjksc1eh"
            className="animable"
          />
          <path
            d="M417.94,201.7l2-3.52c-.11-3.77-.09-7.48-.15-11.25l-1.89-3.27H407.58l-5.21,9,5.21,9Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "411.155px 192.68px",
            }}
            id="eljkwkxth6p2"
            className="animable"
          />
          <path
            d="M418.45,183.39l1.36,2.35c0-1.47-.08-3-.15-4.46Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "419.13px 183.51px",
            }}
            id="el04qxa8c43xlf"
            className="animable"
          />
          <path
            d="M420,199.26,418.45,202l1.92,3.33C420.19,203.27,420.08,201.26,420,199.26Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "419.41px 202.295px",
            }}
            id="elvrn1z33akwo"
            className="animable"
          />
          <polygon
            points="385.79 238.88 391 229.86 385.79 220.84 375.43 220.84 370.22 229.86 375.43 238.88 385.79 238.88"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "380.61px 229.86px",
            }}
            id="el7q6u08l2yq"
            className="animable"
          />
          <polygon
            points="369.71 267.32 359.35 267.32 354.15 276.34 359.35 285.35 369.71 285.35 374.92 276.34 369.71 267.32"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "364.535px 276.335px",
            }}
            id="el00gn8s14lg86i"
            className="animable"
          />
          <path
            d="M128.59,145.93l3.13-5.42c-4.85,1.75-9.28,3.54-13.29,5.42Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "125.075px 143.22px",
            }}
            id="el3n8cgmtaajd"
            className="animable"
          />
          <polygon
            points="369.71 248.73 359.35 248.73 354.15 257.75 359.35 266.76 369.71 266.76 374.92 257.75 369.71 248.73"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "364.535px 257.745px",
            }}
            id="el8ibadjyklma"
            className="animable"
          />
          <polygon
            points="369.71 230.14 359.35 230.14 354.15 239.16 359.35 248.17 369.71 248.17 374.92 239.16 369.71 230.14"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "364.535px 239.155px",
            }}
            id="elrh51mtmtvpc"
            className="animable"
          />
          <polygon
            points="295.05 174.37 289.85 183.38 295.05 192.4 305.42 192.4 310.62 183.38 305.42 174.37 295.05 174.37"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "300.235px 183.385px",
            }}
            id="elvkxzxfb7c1m"
            className="animable"
          />
          <polygon
            points="327.2 155.78 322 164.79 327.2 173.81 337.56 173.81 342.77 164.79 337.56 155.78 327.2 155.78"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "332.385px 164.795px",
            }}
            id="elsirc0wufyf"
            className="animable"
          />
          <polygon
            points="311.13 165.07 305.92 174.09 311.13 183.11 321.49 183.11 326.7 174.09 321.49 165.07 311.13 165.07"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "316.31px 174.09px",
            }}
            id="elz1efe234h1h"
            className="animable"
          />
          <polygon
            points="369.71 322.54 374.92 313.52 369.71 304.5 359.35 304.5 354.15 313.52 359.35 322.54 369.71 322.54"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "364.535px 313.52px",
            }}
            id="eldwhvgbio8ih"
            className="animable"
          />
          <polygon
            points="278.98 165.07 273.77 174.09 278.98 183.11 289.34 183.11 294.55 174.09 289.34 165.07 278.98 165.07"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "284.16px 174.09px",
            }}
            id="eltopithpbnwi"
            className="animable"
          />
          <polygon
            points="391.5 137.19 386.3 146.21 391.5 155.22 401.87 155.22 407.07 146.21 401.87 137.19 391.5 137.19"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "396.685px 146.205px",
            }}
            id="el07ug7f96rfwv"
            className="animable"
          />
          <polygon
            points="375.43 146.48 370.22 155.5 375.43 164.52 385.79 164.52 391 155.5 385.79 146.48 375.43 146.48"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "380.61px 155.5px",
            }}
            id="elsy91wvgcy2r"
            className="animable"
          />
          <polygon
            points="343.28 165.07 338.07 174.09 343.28 183.11 353.64 183.11 358.85 174.09 353.64 165.07 343.28 165.07"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "348.46px 174.09px",
            }}
            id="el14shz9qpd01"
            className="animable"
          />
          <path
            d="M407.58,145.93h2.94A49.58,49.58,0,0,0,404.05,134l-1.68,2.91Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "406.445px 139.965px",
            }}
            id="eluf0bffig25"
            className="animable"
          />
          <polygon
            points="359.35 155.78 354.15 164.79 359.35 173.81 369.71 173.81 374.92 164.79 369.71 155.78 359.35 155.78"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "364.535px 164.795px",
            }}
            id="elk7a3k7qwr4"
            className="animable"
          />
          <polygon
            points="369.71 285.91 359.35 285.91 354.15 294.93 359.35 303.94 369.71 303.94 374.92 294.93 369.71 285.91"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "364.535px 294.925px",
            }}
            id="elqtswq23y49i"
            className="animable"
          />
          <polygon
            points="144.67 303.94 149.87 294.93 144.67 285.91 134.3 285.91 129.1 294.93 134.3 303.94 144.67 303.94"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "139.485px 294.925px",
            }}
            id="elnk66bbcijd8"
            className="animable"
          />
          <polygon
            points="128.59 313.24 133.8 304.22 128.59 295.2 118.23 295.2 113.02 304.22 118.23 313.24 128.59 313.24"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "123.41px 304.22px",
            }}
            id="el4er322mw8o5"
            className="animable"
          />
          <polygon
            points="112.52 303.94 117.72 294.93 112.52 285.91 102.15 285.91 96.95 294.93 102.15 303.94 112.52 303.94"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "107.335px 294.925px",
            }}
            id="elyol4yfqsvhr"
            className="animable"
          />
          <polygon
            points="160.74 313.24 165.95 304.22 160.74 295.2 150.38 295.2 145.17 304.22 150.38 313.24 160.74 313.24"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "155.56px 304.22px",
            }}
            id="elsz00tc37oam"
            className="animable"
          />
          <polygon
            points="176.82 303.94 182.02 294.93 176.82 285.91 166.45 285.91 161.25 294.93 166.45 303.94 176.82 303.94"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "171.635px 294.925px",
            }}
            id="elg3woxc8cerd"
            className="animable"
          />
          <polygon
            points="70 303.94 80.37 303.94 85.57 294.93 80.37 285.91 70 285.91 64.8 294.93 70 303.94"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "75.185px 294.925px",
            }}
            id="eldv4yz79lniu"
            className="animable"
          />
          <polygon
            points="192.89 294.65 198.1 285.63 192.89 276.61 182.53 276.61 177.32 285.63 182.53 294.65 192.89 294.65"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "187.71px 285.63px",
            }}
            id="eljzfvcmd8ow"
            className="animable"
          />
          <path
            d="M70,285.35H80.37l5.2-9-5.2-9h-9c-1.09,1-2.15,2-3.15,3l-3.47,6Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "75.16px 276.35px",
            }}
            id="elxlwprcvg54"
            className="animable"
          />
          <polygon
            points="70 322.54 80.37 322.54 85.57 313.52 80.37 304.5 70 304.5 64.8 313.52 70 322.54"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "75.185px 313.52px",
            }}
            id="el4nyqkjfebjy"
            className="animable"
          />
          <polygon
            points="96.44 313.24 101.65 304.22 96.44 295.2 86.08 295.2 80.87 304.22 86.08 313.24 96.44 313.24"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "91.26px 304.22px",
            }}
            id="el20mxxdn2j2l"
            className="animable"
          />
          <polygon
            points="289.34 276.06 294.55 267.04 289.34 258.02 278.98 258.02 273.77 267.04 278.98 276.06 289.34 276.06"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "284.16px 267.04px",
            }}
            id="el19d3c8yxtq6"
            className="animable"
          />
          <polygon
            points="321.49 294.65 326.7 285.63 321.49 276.61 311.13 276.61 305.92 285.63 311.13 294.65 321.49 294.65"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "316.31px 285.63px",
            }}
            id="elf7wmrupxzc"
            className="animable"
          />
          <polygon
            points="353.64 313.24 358.85 304.22 353.64 295.2 343.28 295.2 338.07 304.22 343.28 313.24 353.64 313.24"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "348.46px 304.22px",
            }}
            id="el8091f09v9r"
            className="animable"
          />
          <polygon
            points="305.42 285.35 310.62 276.34 305.42 267.32 295.05 267.32 289.85 276.34 295.05 285.35 305.42 285.35"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "300.235px 276.335px",
            }}
            id="ellkfbhf2g66d"
            className="animable"
          />
          <polygon
            points="337.56 303.94 342.77 294.93 337.56 285.91 327.2 285.91 322 294.93 327.2 303.94 337.56 303.94"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "332.385px 294.925px",
            }}
            id="elp4g4gzr5oym"
            className="animable"
          />
          <polygon
            points="225.04 294.65 230.25 285.63 225.04 276.61 214.68 276.61 209.47 285.63 214.68 294.65 225.04 294.65"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "219.86px 285.63px",
            }}
            id="elzbs2k5bsax9"
            className="animable"
          />
          <polygon
            points="273.26 285.35 278.47 276.34 273.26 267.32 262.9 267.32 257.7 276.34 262.9 285.35 273.26 285.35"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "268.085px 276.335px",
            }}
            id="elk0ssdazf9v"
            className="animable"
          />
          <polygon
            points="208.97 285.35 214.17 276.34 208.97 267.32 198.6 267.32 193.4 276.34 198.6 285.35 208.97 285.35"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "203.785px 276.335px",
            }}
            id="eld8rjxod5py9"
            className="animable"
          />
          <polygon
            points="241.12 285.35 246.32 276.34 241.12 267.32 230.75 267.32 225.55 276.34 230.75 285.35 241.12 285.35"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "235.935px 276.335px",
            }}
            id="elc3v9tr9gx8n"
            className="animable"
          />
          <polygon
            points="257.19 276.06 262.4 267.04 257.19 258.02 246.83 258.02 241.62 267.04 246.83 276.06 257.19 276.06"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "252.01px 267.04px",
            }}
            id="elhgb7b758uec"
            className="animable"
          />
          <polygon
            points="401.87 210.99 407.07 201.97 401.87 192.96 391.5 192.96 386.3 201.97 391.5 210.99 401.87 210.99"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "396.685px 201.975px",
            }}
            id="elrh8qsbmzh08"
            className="animable"
          />
          <polygon
            points="144.67 229.58 149.87 220.57 144.67 211.55 134.3 211.55 129.1 220.57 134.3 229.58 144.67 229.58"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "139.485px 220.565px",
            }}
            id="elnclb4xi7ou"
            className="animable"
          />
          <polygon
            points="128.59 238.88 133.8 229.86 128.59 220.84 118.23 220.84 113.02 229.86 118.23 238.88 128.59 238.88"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "123.41px 229.86px",
            }}
            id="eleo7baso2uuq"
            className="animable"
          />
          <polygon
            points="192.89 238.88 198.1 229.86 192.89 220.84 182.53 220.84 177.32 229.86 182.53 238.88 192.89 238.88"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "187.71px 229.86px",
            }}
            id="el5ygr9nvd4aj"
            className="animable"
          />
          <polygon
            points="160.74 238.88 165.95 229.86 160.74 220.84 150.38 220.84 145.17 229.86 150.38 238.88 160.74 238.88"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "155.56px 229.86px",
            }}
            id="el9t2tidsb22c"
            className="animable"
          />
          <path
            d="M87.84,183.66a34.24,34.24,0,0,0,2.28,18h6.32l5.21-9-5.21-9Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "94.5723px 192.66px",
            }}
            id="elf6ez44xs3nv"
            className="animable"
          />
          <path
            d="M96.44,202.25H90.35c2.27,5.45,5.47,10.16,8,14.79l3.33-5.77Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "96.015px 209.645px",
            }}
            id="elogl3a34qt4j"
            className="animable"
          />
          <polygon
            points="102.15 192.96 96.95 201.97 102.15 210.99 112.52 210.99 117.72 201.97 112.52 192.96 102.15 192.96"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "107.335px 201.975px",
            }}
            id="elnog8jdi5me"
            className="animable"
          />
          <polygon
            points="150.38 202.25 145.17 211.27 150.38 220.29 160.74 220.29 165.95 211.27 160.74 202.25 150.38 202.25"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "155.56px 211.27px",
            }}
            id="elhqiwui25m77"
            className="animable"
          />
          <path
            d="M117.72,220.57l-5.2-9H102.15l-3.51,6.08c2,3.89,3.56,7.74,3.58,12h10.3Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "108.18px 220.61px",
            }}
            id="elb566bnkwwx"
            className="animable"
          />
          <polygon
            points="128.59 220.29 133.8 211.27 128.59 202.25 118.23 202.25 113.02 211.27 118.23 220.29 128.59 220.29"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "123.41px 211.27px",
            }}
            id="el81geb7z9ybl"
            className="animable"
          />
          <polygon
            points="160.74 257.47 165.95 248.45 160.74 239.43 150.38 239.43 145.17 248.45 150.38 257.47 160.74 257.47"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "155.56px 248.45px",
            }}
            id="elzw0hohi3so"
            className="animable"
          />
          <polygon
            points="128.59 257.47 133.8 248.45 128.59 239.43 118.23 239.43 113.02 248.45 118.23 257.47 128.59 257.47"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "123.41px 248.45px",
            }}
            id="eld8vu6zvta"
            className="animable"
          />
          <polygon
            points="134.3 192.96 129.1 201.97 134.3 210.99 144.67 210.99 149.87 201.97 144.67 192.96 134.3 192.96"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "139.485px 201.975px",
            }}
            id="elpaiuybjt6cd"
            className="animable"
          />
          <polygon
            points="198.6 211.55 193.4 220.57 198.6 229.58 208.97 229.58 214.17 220.57 208.97 211.55 198.6 211.55"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "203.785px 220.565px",
            }}
            id="elvk2k8khx769"
            className="animable"
          />
          <polygon
            points="176.82 248.17 182.02 239.16 176.82 230.14 166.45 230.14 161.25 239.16 166.45 248.17 176.82 248.17"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "171.635px 239.155px",
            }}
            id="el7pp9lqo6svy"
            className="animable"
          />
          <polygon
            points="144.67 248.17 149.87 239.16 144.67 230.14 134.3 230.14 129.1 239.16 134.3 248.17 144.67 248.17"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "139.485px 239.155px",
            }}
            id="el2vfnjng439"
            className="animable"
          />
          <polygon
            points="118.23 183.66 113.02 192.68 118.23 201.7 128.59 201.7 133.8 192.68 128.59 183.66 118.23 183.66"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "123.41px 192.68px",
            }}
            id="el3y67gsgntp9"
            className="animable"
          />
          <polygon
            points="102.15 174.37 96.95 183.38 102.15 192.4 112.52 192.4 117.72 183.38 112.52 174.37 102.15 174.37"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "107.335px 183.385px",
            }}
            id="elc1sn5xg4rl"
            className="animable"
          />
          <path
            d="M94.32,165.07a45.62,45.62,0,0,0-6.4,18h8.52l5.21-9-5.21-9Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "94.785px 174.07px",
            }}
            id="elsg02wze1it"
            className="animable"
          />
          <path
            d="M112.52,248.17l5.2-9-5.2-9H102.21a22.24,22.24,0,0,1-3.89,11.4l3.83,6.63Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "108.02px 239.185px",
            }}
            id="elb0odebmdazw"
            className="animable"
          />
          <polygon
            points="176.82 229.58 182.02 220.57 176.82 211.55 166.45 211.55 161.25 220.57 166.45 229.58 176.82 229.58"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "171.635px 220.565px",
            }}
            id="el9kt0k9a0uj8"
            className="animable"
          />
          <path
            d="M354.15,109l5.2,9h10.36l5.21-9-1.48-2.57c-3.59-2.18-7.5-4.34-11.78-6.44h-2.31Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "364.535px 108.995px",
            }}
            id="eltiqvamyuapk"
            className="animable"
          />
          <path
            d="M337.56,341.68H327.2l-5.2,9,5.2,9h4.58c2.55-1.48,5.31-2.95,8.31-4.37l2.68-4.64Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "332.385px 350.68px",
            }}
            id="ely43q6dya1fk"
            className="animable"
          />
          <path
            d="M214.68,108.75H225l5.21-9-.85-1.46c-2,1.12-3.83,2.25-5.48,3.39-3.39,2.35-6.54,4.47-9.62,6.44Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "222.235px 103.52px",
            }}
            id="elzlg7i7nr1r"
            className="animable"
          />
          <polygon
            points="214.68 202.25 209.47 211.27 214.68 220.29 225.04 220.29 230.25 211.27 225.04 202.25 214.68 202.25"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "219.86px 211.27px",
            }}
            id="elotitmyazs2"
            className="animable"
          />
          <path
            d="M385.79,332.38H375.43l-5.21,9,2.1,3.64A132.71,132.71,0,0,1,390,343.17l1-1.77Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "380.61px 338.7px",
            }}
            id="elyqe4hau882o"
            className="animable"
          />
          <path
            d="M455.46,303.34a60.7,60.7,0,0,0-1.64-14l-3.22,5.58Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "453.03px 296.34px",
            }}
            id="el2dkqctn9ay9"
            className="animable"
          />
          <path
            d="M273.27,378.86H262.9l-5.22,9,.71,1.21a62.32,62.32,0,0,0,18.68,1.24l1.41-2.45Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "268.08px 384.696px",
            }}
            id="elxktzlbsxfke"
            className="animable"
          />
          <path
            d="M293.23,83.43a149.38,149.38,0,0,0-17.61.9L279,90.16h10.36Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "284.425px 86.7926px",
            }}
            id="ely17uy1wu8ds"
            className="animable"
          />
          <path
            d="M353.64,332.38H343.28l-5.21,9,5.21,9h8.85c.63-.22,1.25-.45,1.89-.66l4.83-8.36Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "348.46px 341.38px",
            }}
            id="elgoix36f7cjr"
            className="animable"
          />
          <polygon
            points="278.98 108.75 289.34 108.75 294.55 99.73 289.34 90.71 278.98 90.71 273.77 99.73 278.98 108.75"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "284.16px 99.73px",
            }}
            id="eltbz8mbh99n"
            className="animable"
          />
          <path
            d="M182.53,127.34h10.36l5.21-9-.48-.83c-4.93,2.38-10.33,4.66-16.72,7Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "189.5px 122.425px",
            }}
            id="elz6ul9fwil5g"
            className="animable"
          />
          <path
            d="M102.15,173.81h10.37l5.2-9-5.2-9h-10l-.68.6L97,164.8Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "107.36px 164.81px",
            }}
            id="elyq4piuwp5t9"
            className="animable"
          />
          <polygon
            points="241.12 229.58 246.32 220.57 241.12 211.55 230.75 211.55 225.55 220.57 230.75 229.58 241.12 229.58"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "235.935px 220.565px",
            }}
            id="elkvqmrb8qgmj"
            className="animable"
          />
          <polygon
            points="214.68 220.84 209.47 229.86 214.68 238.88 225.04 238.88 230.25 229.86 225.04 220.84 214.68 220.84"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "219.86px 229.86px",
            }}
            id="ely8uhlmgbg8o"
            className="animable"
          />
          <path
            d="M134.3,155.22h10.37l5.2-9-5.2-9h-3.16q-4.69,1.5-8.95,3l-3.46,6Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "139.485px 146.22px",
            }}
            id="el5b2b4vtnx2c"
            className="animable"
          />
          <path
            d="M85.57,332.11l-5.2-9H70l-4.81,8.34a38.11,38.11,0,0,0,7.24,9.69h7.94Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "75.38px 332.125px",
            }}
            id="elfbmzowlesn9"
            className="animable"
          />
          <path
            d="M160.74,332.38H150.38l-5.21,9,5.12,8.87a43.3,43.3,0,0,1,7.15.15h3.3l5.21-9Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "155.56px 341.39px",
            }}
            id="el4a9cr5burac"
            className="animable"
          />
          <path
            d="M85.57,257.75l-.8-1.39C80.4,260.06,76,263.3,72,266.76h8.33Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "78.785px 261.56px",
            }}
            id="elts7u7teoakj"
            className="animable"
          />
          <path
            d="M150.38,145.93h10.36l5.21-9-3.55-6.15c-3.41,1.07-7,2.19-11,3.36-1.94.58-3.82,1.15-5.66,1.72l-.62,1.07Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "155.535px 138.355px",
            }}
            id="elpjt7eosdltk"
            className="animable"
          />
          <path
            d="M69.5,304.22l-5.21-9H57.74a58.08,58.08,0,0,0,.2,7.58c.33,3.77.79,7.24,1.37,10.46h5Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "63.598px 304.24px",
            }}
            id="elmoowc4nhqaa"
            className="animable"
          />
          <path
            d="M101.65,341.4l-5.21-9H86.08l-5.21,9,4.89,8.46c.41.19.83.38,1.26.56h9.42Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "91.26px 341.41px",
            }}
            id="el3jiwm8vehwb"
            className="animable"
          />
          <polygon
            points="327.2 192.96 322 201.97 327.2 210.99 337.56 210.99 342.77 201.97 337.56 192.96 327.2 192.96"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "332.385px 201.975px",
            }}
            id="elvb72j9pth4"
            className="animable"
          />
          <polygon
            points="295.05 211.55 289.85 220.57 295.05 229.58 305.42 229.58 310.62 220.57 305.42 211.55 295.05 211.55"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "300.235px 220.565px",
            }}
            id="elgg205xiouo9"
            className="animable"
          />
          <polygon
            points="311.13 202.25 305.92 211.27 311.13 220.29 321.49 220.29 326.7 211.27 321.49 202.25 311.13 202.25"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "316.31px 211.27px",
            }}
            id="elruxlatb0ek"
            className="animable"
          />
          <polygon
            points="246.83 202.25 241.62 211.27 246.83 220.29 257.19 220.29 262.4 211.27 257.19 202.25 246.83 202.25"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "252.01px 211.27px",
            }}
            id="elah6ojbxo06v"
            className="animable"
          />
          <polygon
            points="278.98 202.25 273.77 211.27 278.98 220.29 289.34 220.29 294.55 211.27 289.34 202.25 278.98 202.25"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "284.16px 211.27px",
            }}
            id="elmtzdyab6el"
            className="animable"
          />
          <polygon
            points="262.9 211.55 257.7 220.57 262.9 229.58 273.26 229.58 278.47 220.57 273.26 211.55 262.9 211.55"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "268.085px 220.565px",
            }}
            id="el8sxqfyjeb6a"
            className="animable"
          />
          <polygon
            points="337.56 266.76 342.77 257.75 337.56 248.73 327.2 248.73 322 257.75 327.2 266.76 337.56 266.76"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "332.385px 257.745px",
            }}
            id="el8henqx7mk6n"
            className="animable"
          />
          <polygon
            points="321.49 257.47 326.7 248.45 321.49 239.43 311.13 239.43 305.92 248.45 311.13 257.47 321.49 257.47"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "316.31px 248.45px",
            }}
            id="elmgvn30umd8"
            className="animable"
          />
          <polygon
            points="337.56 230.14 327.2 230.14 322 239.16 327.2 248.17 337.56 248.17 342.77 239.16 337.56 230.14"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "332.385px 239.155px",
            }}
            id="elhu57tbd105b"
            className="animable"
          />
          <polygon
            points="337.56 211.55 327.2 211.55 322 220.57 327.2 229.58 337.56 229.58 342.77 220.57 337.56 211.55"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "332.385px 220.565px",
            }}
            id="elxzr0sy1rglo"
            className="animable"
          />
          <polygon
            points="353.64 220.29 358.85 211.27 353.64 202.25 343.28 202.25 338.07 211.27 343.28 220.29 353.64 220.29"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "348.46px 211.27px",
            }}
            id="elofkqxfv9oe"
            className="animable"
          />
          <polygon
            points="150.38 165.07 145.17 174.09 150.38 183.11 160.74 183.11 165.95 174.09 160.74 165.07 150.38 165.07"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "155.56px 174.09px",
            }}
            id="elpb6wpaif5ng"
            className="animable"
          />
          <polygon
            points="134.3 155.78 129.1 164.79 134.3 173.81 144.67 173.81 149.87 164.79 144.67 155.78 134.3 155.78"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "139.485px 164.795px",
            }}
            id="elo3xt8dxcl3g"
            className="animable"
          />
          <polygon
            points="118.23 146.48 113.02 155.5 118.23 164.52 128.59 164.52 133.8 155.5 128.59 146.48 118.23 146.48"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "123.41px 155.5px",
            }}
            id="elozucuws3fm"
            className="animable"
          />
          <path
            d="M112.52,155.22l5.16-8.94a70.37,70.37,0,0,0-14.52,8.94Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "110.42px 150.75px",
            }}
            id="el24m4lumdze7h"
            className="animable"
          />
          <polygon
            points="198.6 192.96 193.4 201.97 198.6 210.99 208.97 210.99 214.17 201.97 208.97 192.96 198.6 192.96"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "203.785px 201.975px",
            }}
            id="elpju45r9tc"
            className="animable"
          />
          <polygon
            points="166.45 174.37 161.25 183.38 166.45 192.4 176.82 192.4 182.02 183.38 176.82 174.37 166.45 174.37"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "171.635px 183.385px",
            }}
            id="ely4vec0ipd29"
            className="animable"
          />
          <polygon
            points="230.75 192.96 225.55 201.97 230.75 210.99 241.12 210.99 246.32 201.97 241.12 192.96 230.75 192.96"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "235.935px 201.975px",
            }}
            id="el9vp9qnwpjvg"
            className="animable"
          />
          <polygon
            points="214.68 183.66 209.47 192.68 214.68 201.7 225.04 201.7 230.25 192.68 225.04 183.66 214.68 183.66"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "219.86px 192.68px",
            }}
            id="ellij5znj2dw"
            className="animable"
          />
          <polygon
            points="182.53 183.66 177.32 192.68 182.53 201.7 192.89 201.7 198.1 192.68 192.89 183.66 182.53 183.66"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "187.71px 192.68px",
            }}
            id="ele12gmckxgt9"
            className="animable"
          />
          <polygon
            points="112.52 266.76 117.72 257.75 112.52 248.73 102.15 248.73 96.95 257.75 102.15 266.76 112.52 266.76"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "107.335px 257.745px",
            }}
            id="elrdczkozjo8n"
            className="animable"
          />
          <path
            d="M94.68,164.52h1.76l3.94-6.83A41,41,0,0,0,94.68,164.52Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "97.53px 161.105px",
            }}
            id="elgeh6hfp49je"
            className="animable"
          />
          <polygon
            points="160.74 276.06 165.95 267.04 160.74 258.02 150.38 258.02 145.17 267.04 150.38 276.06 160.74 276.06"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "155.56px 267.04px",
            }}
            id="el1fryfhplcuq"
            className="animable"
          />
          <polygon
            points="182.53 202.25 177.32 211.27 182.53 220.29 192.89 220.29 198.1 211.27 192.89 202.25 182.53 202.25"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "187.71px 211.27px",
            }}
            id="elgeh44eyj02k"
            className="animable"
          />
          <polygon
            points="128.59 276.06 133.8 267.04 128.59 258.02 118.23 258.02 113.02 267.04 118.23 276.06 128.59 276.06"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "123.41px 267.04px",
            }}
            id="elfq0vscto9yq"
            className="animable"
          />
          <polygon
            points="144.67 266.76 149.87 257.75 144.67 248.73 134.3 248.73 129.1 257.75 134.3 266.76 144.67 266.76"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "139.485px 257.745px",
            }}
            id="elmowc88pgaef"
            className="animable"
          />
          <path
            d="M96.44,257.47l5.21-9L98,242.08c-.21.33-.43.66-.67,1A70.21,70.21,0,0,1,85.22,256l.86,1.49Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "93.435px 249.785px",
            }}
            id="el18zubr12omm"
            className="animable"
          />
          <polygon
            points="150.38 183.66 145.17 192.68 150.38 201.7 160.74 201.7 165.95 192.68 160.74 183.66 150.38 183.66"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "155.56px 192.68px",
            }}
            id="elw248v51u698"
            className="animable"
          />
          <polygon
            points="166.45 192.96 161.25 201.97 166.45 210.99 176.82 210.99 182.02 201.97 176.82 192.96 166.45 192.96"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "171.635px 201.975px",
            }}
            id="eln62xgntc018"
            className="animable"
          />
          <polygon
            points="118.23 165.07 113.02 174.09 118.23 183.11 128.59 183.11 133.8 174.09 128.59 165.07 118.23 165.07"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "123.41px 174.09px",
            }}
            id="elzobvhjc51v"
            className="animable"
          />
          <polygon
            points="134.3 174.37 129.1 183.38 134.3 192.4 144.67 192.4 149.87 183.38 144.67 174.37 134.3 174.37"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "139.485px 183.385px",
            }}
            id="elz3x8frq8d8"
            className="animable"
          />
          <polygon
            points="257.19 238.88 262.4 229.86 257.19 220.84 246.83 220.84 241.62 229.86 246.83 238.88 257.19 238.88"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "252.01px 229.86px",
            }}
            id="elqd2vuhke1x"
            className="animable"
          />
          <polygon
            points="289.34 238.88 294.55 229.86 289.34 220.84 278.98 220.84 273.77 229.86 278.98 238.88 289.34 238.88"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "284.16px 229.86px",
            }}
            id="eln69p96p1mtm"
            className="animable"
          />
          <polygon
            points="273.26 248.17 278.47 239.16 273.26 230.14 262.9 230.14 257.7 239.16 262.9 248.17 273.26 248.17"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "268.085px 239.155px",
            }}
            id="el10jemnmmkuy"
            className="animable"
          />
          <polygon
            points="305.42 248.17 310.62 239.16 305.42 230.14 295.05 230.14 289.85 239.16 295.05 248.17 305.42 248.17"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "300.235px 239.155px",
            }}
            id="elc7md757kuq4"
            className="animable"
          />
          <polygon
            points="321.49 238.88 326.7 229.86 321.49 220.84 311.13 220.84 305.92 229.86 311.13 238.88 321.49 238.88"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "316.31px 229.86px",
            }}
            id="eltnr0nw1in5i"
            className="animable"
          />
          <polygon
            points="192.89 257.47 198.1 248.45 192.89 239.43 182.53 239.43 177.32 248.45 182.53 257.47 192.89 257.47"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "187.71px 248.45px",
            }}
            id="elkh5pcbnwrd9"
            className="animable"
          />
          <polygon
            points="176.82 266.76 182.02 257.75 176.82 248.73 166.45 248.73 161.25 257.75 166.45 266.76 176.82 266.76"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "171.635px 257.745px",
            }}
            id="elvl4xwwfkfw"
            className="animable"
          />
          <polygon
            points="208.97 248.17 214.17 239.16 208.97 230.14 198.6 230.14 193.4 239.16 198.6 248.17 208.97 248.17"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "203.785px 239.155px",
            }}
            id="elycsdyb72bii"
            className="animable"
          />
          <polygon
            points="241.12 248.17 246.32 239.16 241.12 230.14 230.75 230.14 225.55 239.16 230.75 248.17 241.12 248.17"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "235.935px 239.155px",
            }}
            id="eldkh4b5f06so"
            className="animable"
          />
          <polygon
            points="225.04 257.47 230.25 248.45 225.04 239.43 214.68 239.43 209.47 248.45 214.68 257.47 225.04 257.47"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "219.86px 248.45px",
            }}
            id="elxfh28w4a0u"
            className="animable"
          />
          <polygon
            points="257.19 257.47 262.4 248.45 257.19 239.43 246.83 239.43 241.62 248.45 246.83 257.47 257.19 257.47"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "252.01px 248.45px",
            }}
            id="el2rrurklde8n"
            className="animable"
          />
          <path
            d="M375.43,108.75h1.63c-.84-.56-1.7-1.11-2.59-1.66Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "375.765px 107.92px",
            }}
            id="elvf7z5xo9a4"
            className="animable"
          />
          <polygon
            points="343.28 109.3 338.07 118.32 343.28 127.34 353.64 127.34 358.85 118.32 353.64 109.3 343.28 109.3"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "348.46px 118.32px",
            }}
            id="elyh0vbxcw50f"
            className="animable"
          />
          <path
            d="M431.38,239.27a100.45,100.45,0,0,1-4.61-9.13h-3.12l-5.2,9,5.2,9H434l1.2-2.08C434,243.86,432.7,241.58,431.38,239.27Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "426.825px 239.14px",
            }}
            id="elbp754jfqbr"
            className="animable"
          />
          <polygon
            points="327.2 100.01 322 109.03 327.2 118.04 337.56 118.04 342.77 109.03 337.56 100.01 327.2 100.01"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "332.385px 109.025px",
            }}
            id="el5syhr2mrk1x"
            className="animable"
          />
          <polygon
            points="311.13 109.3 305.92 118.32 311.13 127.34 321.49 127.34 326.7 118.32 321.49 109.3 311.13 109.3"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "316.31px 118.32px",
            }}
            id="ela2zofxngvd"
            className="animable"
          />
          <polygon
            points="434.01 266.76 439.22 257.75 434.01 248.73 423.65 248.73 418.45 257.75 423.65 266.76 434.01 266.76"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "428.835px 257.745px",
            }}
            id="elumq487svvna"
            className="animable"
          />
          <polygon
            points="417.94 276.61 407.58 276.61 402.37 285.63 407.58 294.65 417.94 294.65 423.15 285.63 417.94 276.61"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "412.76px 285.63px",
            }}
            id="ele63tfk9dhot"
            className="animable"
          />
          <polygon
            points="417.94 295.2 407.58 295.2 402.37 304.22 407.58 313.24 417.94 313.24 423.15 304.22 417.94 295.2"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "412.76px 304.22px",
            }}
            id="eldozlsdmh9l"
            className="animable"
          />
          <polygon
            points="417.94 258.02 407.58 258.02 402.37 267.04 407.58 276.06 417.94 276.06 423.15 267.04 417.94 258.02"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "412.76px 267.04px",
            }}
            id="elbbn34l3fl5a"
            className="animable"
          />
          <polygon
            points="295.05 118.6 289.85 127.61 295.05 136.63 305.42 136.63 310.62 127.61 305.42 118.6 295.05 118.6"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "300.235px 127.615px",
            }}
            id="elkcbis1kkylc"
            className="animable"
          />
          <polygon
            points="225.04 369.01 230.25 359.99 225.04 350.97 214.68 350.97 209.47 359.99 214.68 369.01 225.04 369.01"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "219.86px 359.99px",
            }}
            id="elx5fk3voy6nl"
            className="animable"
          />
          <polygon
            points="278.98 109.3 273.77 118.32 278.98 127.34 289.34 127.34 294.55 118.32 289.34 109.3 278.98 109.3"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "284.16px 118.32px",
            }}
            id="el7arf55n09je"
            className="animable"
          />
          <path
            d="M192.89,351H182.53l-3.41,5.91c5.22,2.2,10.75,4.75,16.48,7.44l2.5-4.33Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "188.61px 357.675px",
            }}
            id="elhqqy7gblj4j"
            className="animable"
          />
          <polygon
            points="417.94 313.79 407.58 313.79 402.37 322.81 407.58 331.83 417.94 331.83 423.15 322.81 417.94 313.79"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "412.76px 322.81px",
            }}
            id="el4omjyycr98p"
            className="animable"
          />
          <polygon
            points="257.19 350.42 262.4 341.4 257.19 332.38 246.83 332.38 241.62 341.4 246.83 350.42 257.19 350.42"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "252.01px 341.4px",
            }}
            id="eljd9wleryfff"
            className="animable"
          />
          <polygon
            points="241.12 359.71 246.32 350.7 241.12 341.68 230.75 341.68 225.55 350.7 230.75 359.71 241.12 359.71"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "235.935px 350.695px",
            }}
            id="elb2i4a7eirsb"
            className="animable"
          />
          <polygon
            points="208.97 359.71 214.17 350.7 208.97 341.68 198.6 341.68 193.4 350.7 198.6 359.71 208.97 359.71"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "203.785px 350.695px",
            }}
            id="elj6zrwnt88ys"
            className="animable"
          />
          <path
            d="M198.6,118H209l5.2-9-.35-.61a157.27,157.27,0,0,1-15.68,8.83Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "206.185px 113.195px",
            }}
            id="el8ziqakbxbu2"
            className="animable"
          />
          <polygon
            points="262.9 118.6 257.7 127.61 262.9 136.63 273.26 136.63 278.47 127.61 273.26 118.6 262.9 118.6"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "268.085px 127.615px",
            }}
            id="elk313bh339w8"
            className="animable"
          />
          <polygon
            points="246.83 109.3 241.62 118.32 246.83 127.34 257.19 127.34 262.4 118.32 257.19 109.3 246.83 109.3"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "252.01px 118.32px",
            }}
            id="elin0z3naztys"
            className="animable"
          />
          <polygon
            points="230.75 100.01 225.55 109.03 230.75 118.04 241.12 118.04 246.32 109.03 241.12 100.01 230.75 100.01"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "235.935px 109.025px",
            }}
            id="eld1bgvtxc535"
            className="animable"
          />
          <path
            d="M407.58,332.38l-5.21,9,1,1.65c7,.1,13.31.3,19-.25l.81-1.4-5.21-9Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "412.775px 337.765px",
            }}
            id="el7nt5jmlarxr"
            className="animable"
          />
          <path
            d="M130.76,354.73,128.59,351H118.23l-3.65,6.33A53.38,53.38,0,0,0,130.76,354.73Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "122.67px 354.165px",
            }}
            id="elmyzaz8bx68d"
            className="animable"
          />
          <path
            d="M144.67,341.68H134.3l-5.2,9,2.23,3.86c5.87-1.74,11.56-3.73,18.32-4.25Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "139.375px 348.11px",
            }}
            id="eljmlsaeaq7j"
            className="animable"
          />
          <polygon
            points="192.89 350.42 198.1 341.4 192.89 332.38 182.53 332.38 177.32 341.4 182.53 350.42 192.89 350.42"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "187.71px 341.4px",
            }}
            id="eludu9hhzjfu"
            className="animable"
          />
          <path
            d="M176.82,341.68H166.45l-5.2,9,.18.31a60.11,60.11,0,0,1,9.58,2.66c2.43.89,5,1.9,7.57,3l3.44-6Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "171.635px 349.165px",
            }}
            id="elsg99wn0l51"
            className="animable"
          />
          <path
            d="M112.52,341.68H102.15l-5.2,9,2.27,3.93c1.29.37,2.59.73,4,1.08a47.63,47.63,0,0,0,10.75,1.57l3.8-6.58Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "107.36px 349.47px",
            }}
            id="elmu3vfokpgja"
            className="animable"
          />
          <polygon
            points="230.75 118.6 225.55 127.61 230.75 136.63 241.12 136.63 246.32 127.61 241.12 118.6 230.75 118.6"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "235.935px 127.615px",
            }}
            id="elkskfzzp7ztd"
            className="animable"
          />
          <polygon
            points="214.68 109.3 209.47 118.32 214.68 127.34 225.04 127.34 230.25 118.32 225.04 109.3 214.68 109.3"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "219.86px 118.32px",
            }}
            id="elg1z33epyew7"
            className="animable"
          />
          <polygon
            points="246.83 127.89 241.62 136.91 246.83 145.93 257.19 145.93 262.4 136.91 257.19 127.89 246.83 127.89"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "252.01px 136.91px",
            }}
            id="el0f5sqle82npg"
            className="animable"
          />
          <polygon
            points="208.97 341.12 214.17 332.11 208.97 323.09 198.6 323.09 193.4 332.11 198.6 341.12 208.97 341.12"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "203.785px 332.105px",
            }}
            id="elbnqpkptuats"
            className="animable"
          />
          <polygon
            points="198.6 118.6 193.4 127.61 198.6 136.63 208.97 136.63 214.17 127.61 208.97 118.6 198.6 118.6"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "203.785px 127.615px",
            }}
            id="el4iw1dzknz8k"
            className="animable"
          />
          <path
            d="M343.28,351,341,354.92c2.93-1.36,6.09-2.68,9.54-3.95Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "345.77px 352.945px",
            }}
            id="elc3pkiofu0v9"
            className="animable"
          />
          <polygon
            points="305.42 341.12 310.62 332.11 305.42 323.09 295.05 323.09 289.85 332.11 295.05 341.12 305.42 341.12"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "300.235px 332.105px",
            }}
            id="elsc1x6us1r3"
            className="animable"
          />
          <polygon
            points="321.49 350.42 326.7 341.4 321.49 332.38 311.13 332.38 305.92 341.4 311.13 350.42 321.49 350.42"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "316.31px 341.4px",
            }}
            id="el9xak8i4euak"
            className="animable"
          />
          <polygon
            points="225.04 350.42 230.25 341.4 225.04 332.38 214.68 332.38 209.47 341.4 214.68 350.42 225.04 350.42"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "219.86px 341.4px",
            }}
            id="elplli64n8cs8"
            className="animable"
          />
          <path
            d="M423.05,342.72a45.65,45.65,0,0,0,6-1h-5.41Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "426.05px 342.22px",
            }}
            id="eltcgtw69s8jq"
            className="animable"
          />
          <polygon
            points="273.26 359.71 278.47 350.7 273.26 341.68 262.9 341.68 257.7 350.7 262.9 359.71 273.26 359.71"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "268.085px 350.695px",
            }}
            id="elmiod575j02a"
            className="animable"
          />
          <polygon
            points="241.12 341.12 246.32 332.11 241.12 323.09 230.75 323.09 225.55 332.11 230.75 341.12 241.12 341.12"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "235.935px 332.105px",
            }}
            id="elv6n8xsfx4oq"
            className="animable"
          />
          <polygon
            points="257.19 331.83 262.4 322.81 257.19 313.79 246.83 313.79 241.62 322.81 246.83 331.83 257.19 331.83"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "252.01px 322.81px",
            }}
            id="el6ylrkpx18fa"
            className="animable"
          />
          <polygon
            points="289.34 331.83 294.55 322.81 289.34 313.79 278.98 313.79 273.77 322.81 278.98 331.83 289.34 331.83"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "284.16px 322.81px",
            }}
            id="el8zxxa4d2ruv"
            className="animable"
          />
          <polygon
            points="273.26 341.12 278.47 332.11 273.26 323.09 262.9 323.09 257.7 332.11 262.9 341.12 273.26 341.12"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "268.085px 332.105px",
            }}
            id="elvuzsz9mg7i"
            className="animable"
          />
          <path
            d="M96.44,351H88.35a103.1,103.1,0,0,0,10.08,3.44Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "93.39px 352.72px",
            }}
            id="elte2m0vm9y1j"
            className="animable"
          />
          <polygon
            points="305.42 378.31 310.62 369.29 305.42 360.27 295.05 360.27 289.85 369.29 295.05 378.31 305.42 378.31"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "300.235px 369.29px",
            }}
            id="el4xtic4t7yrf"
            className="animable"
          />
          <polygon
            points="289.34 369.01 294.55 359.99 289.34 350.97 278.98 350.97 273.77 359.99 278.98 369.01 289.34 369.01"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "284.16px 359.99px",
            }}
            id="el9pwd77pd2ul"
            className="animable"
          />
          <path
            d="M311.13,369.57l-5.05,8.74c3.71-2.71,7.33-5.68,11.3-8.74Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "311.73px 373.94px",
            }}
            id="el2wic9572ew3"
            className="animable"
          />
          <polygon
            points="273.26 378.31 278.47 369.29 273.26 360.27 262.9 360.27 257.7 369.29 262.9 378.31 273.26 378.31"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "268.085px 369.29px",
            }}
            id="elz9pj3qowhu"
            className="animable"
          />
          <path
            d="M305.32,378.86H295.05l-4.78,8.28A62.93,62.93,0,0,0,305.32,378.86Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "297.795px 383px",
            }}
            id="elldiiihcw71"
            className="animable"
          />
          <path
            d="M450.11,313.79H439.73l-5.21,9,5.21,9h5.11c.58-.69,1.15-1.41,1.72-2.18a46.3,46.3,0,0,0,6.18-11.31Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "443.63px 322.79px",
            }}
            id="elv9q01pkvz4l"
            className="animable"
          />
          <path
            d="M241.12,378.86H230.75l-.66,1.15c5,2.06,10,3.93,14.87,5.52Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "237.525px 382.195px",
            }}
            id="elxnq6jqhoy1"
            className="animable"
          />
          <path
            d="M230.25,378.58l-5.21-9H214.68L213,372.49c5.48,2.54,11,5,16.56,7.31Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "221.625px 374.69px",
            }}
            id="el4yau8ppd3bt"
            className="animable"
          />
          <polygon
            points="241.12 360.27 230.75 360.27 225.55 369.29 230.75 378.31 241.12 378.31 246.32 369.29 241.12 360.27"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "235.935px 369.29px",
            }}
            id="el4q8omrba0yf"
            className="animable"
          />
          <path
            d="M209,360.27H198.6l-2.48,4.29c5.32,2.5,10.8,5.12,16.34,7.69l1.71-3Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "205.145px 366.26px",
            }}
            id="elxqvwmaitfgf"
            className="animable"
          />
          <polygon
            points="257.19 369.01 262.4 359.99 257.19 350.97 246.83 350.97 241.62 359.99 246.83 369.01 257.19 369.01"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "252.01px 359.99px",
            }}
            id="elqatqakwlwv"
            className="animable"
          />
          <path
            d="M450.6,313.52l2.39,4.15a43.14,43.14,0,0,0,2.41-12.48Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "453px 311.43px",
            }}
            id="eld3ns8wrdho7"
            className="animable"
          />
          <path
            d="M257.19,388.15H254.1c1.2.3,2.38.57,3.56.82Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "255.88px 388.56px",
            }}
            id="el2g2ycffh3c3"
            className="animable"
          />
          <path
            d="M289,387.6l.47-.17,5.11-8.85-5.21-9H279l-5.21,9,5.21,9Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "284.185px 378.59px",
            }}
            id="el1zfu2sfvmms"
            className="animable"
          />
          <polygon
            points="450.11 295.2 439.73 295.2 434.52 304.22 439.73 313.24 450.11 313.24 455.32 304.22 450.11 295.2"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "444.92px 304.22px",
            }}
            id="el9rz9daso7t"
            className="animable"
          />
          <path
            d="M279,388.15l-1.21,2.11c.8-.1,1.6-.21,2.39-.35a60.58,60.58,0,0,0,7.11-1.76Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "282.54px 389.205px",
            }}
            id="elaidao9nq5nd"
            className="animable"
          />
          <path
            d="M449.9,276.61H439.73l-5.21,9,5.21,9h10.38l3.52-6.08A101.72,101.72,0,0,0,449.9,276.61Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "444.075px 285.61px",
            }}
            id="elt5malwtzmsk"
            className="animable"
          />
          <path
            d="M289.85,90.43l5.2,9h10.37l5.2-9L307,84.17c-4.46-.44-8.84-.69-13.11-.74Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "300.235px 91.43px",
            }}
            id="elm3u5pule2"
            className="animable"
          />
          <path
            d="M257.7,90.43l5.2,9h10.37l5.2-9-3.48-6A142.81,142.81,0,0,0,259.67,87Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "268.085px 91.93px",
            }}
            id="elt2vydyl29ph"
            className="animable"
          />
          <path
            d="M257.19,387.6l5.21-9-5.21-9H246.83l-5.21,9,4.16,7.21c2.08.66,4.14,1.27,6.18,1.81Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "252.01px 378.61px",
            }}
            id="el6g0nuh0gn5y"
            className="animable"
          />
          <polygon
            points="246.83 90.71 241.62 99.73 246.83 108.75 257.19 108.75 262.4 99.73 257.19 90.71 246.83 90.71"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "252.01px 99.73px",
            }}
            id="eltj5momt1ry"
            className="animable"
          />
          <polygon
            points="434.01 285.91 423.65 285.91 418.45 294.93 423.65 303.94 434.01 303.94 439.22 294.93 434.01 285.91"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "428.835px 294.925px",
            }}
            id="el8m5zbwjybog"
            className="animable"
          />
          <polygon
            points="434.01 304.5 423.65 304.5 418.45 313.52 423.65 322.54 434.01 322.54 439.22 313.52 434.01 304.5"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "428.835px 313.52px",
            }}
            id="el1n7p2f76s0i"
            className="animable"
          />
          <path
            d="M423.65,323.09l-5.2,9,5.2,9H431a29.77,29.77,0,0,0,3.87-1.5l4.33-7.51-5.2-9Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "428.825px 332.085px",
            }}
            id="el6icvulp6g3"
            className="animable"
          />
          <path
            d="M449.69,276.06a188.85,188.85,0,0,0-8.13-18h-1.83l-5.21,9,5.21,9Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "442.105px 267.06px",
            }}
            id="el7dv4fo9zd1y"
            className="animable"
          />
          <polygon
            points="434.01 267.32 423.65 267.32 418.45 276.34 423.65 285.35 434.01 285.35 439.22 276.34 434.01 267.32"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "428.835px 276.335px",
            }}
            id="elqjsq1odohmf"
            className="animable"
          />
          <path
            d="M439.73,332.38l-3.91,6.77a29.35,29.35,0,0,0,8.53-6.77Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "440.085px 335.765px",
            }}
            id="eldxkljnu7nhp"
            className="animable"
          />
          <polygon
            points="305.42 359.71 310.62 350.7 305.42 341.68 295.05 341.68 289.85 350.7 295.05 359.71 305.42 359.71"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "300.235px 350.695px",
            }}
            id="eltj8odp1i83p"
            className="animable"
          />
          <path
            d="M318.1,369c1.86-1.42,3.81-2.86,5.87-4.3L326.7,360l-5.21-9H311.13l-5.21,9,5.21,9Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "316.31px 360px",
            }}
            id="elbv3ywc8981"
            className="animable"
          />
          <path
            d="M434.52,248.45l5.21,9h1.55q-2.67-5.25-5.74-10.79Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "437.9px 252.055px",
            }}
            id="el7tv6r1cgpf"
            className="animable"
          />
          <path
            d="M327.2,360.27,325.07,364c1.82-1.24,3.74-2.47,5.79-3.69Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "327.965px 362.135px",
            }}
            id="elrns7w9igec9"
            className="animable"
          />
          <path
            d="M230.75,99.45h10.37l4.93-8.54A102.28,102.28,0,0,0,229.91,98Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "237.98px 95.18px",
            }}
            id="eloro1ffi7s4"
            className="animable"
          />
          <polygon
            points="262.9 100.01 257.7 109.03 262.9 118.04 273.26 118.04 278.47 109.03 273.26 100.01 262.9 100.01"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "268.085px 109.025px",
            }}
            id="elrggbldtqld"
            className="animable"
          />
          <polygon
            points="295.05 100.01 289.85 109.03 295.05 118.04 305.42 118.04 310.62 109.03 305.42 100.01 295.05 100.01"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "300.235px 109.025px",
            }}
            id="eln3jngn4t8ek"
            className="animable"
          />
          <polygon
            points="289.34 350.42 294.55 341.4 289.34 332.38 278.98 332.38 273.77 341.4 278.98 350.42 289.34 350.42"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "284.16px 341.4px",
            }}
            id="el6letotbr4fo"
            className="animable"
          />
          <path
            d="M248.27,90.16h8.92l1.71-3C255.17,88.07,251.62,89.07,248.27,90.16Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "253.585px 88.66px",
            }}
            id="elifbplx8d13j"
            className="animable"
          />
          <path
            d="M322,90.43l5.2,9h10.36L342,91.83a157.42,157.42,0,0,0-17.91-5Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "332px 93.13px",
            }}
            id="elytz6uf87dc"
            className="animable"
          />
          <path
            d="M343.28,108.75h10.36l5.21-9L358,98.27q-5-2.33-10.73-4.52c-1.59-.61-3.17-1.18-4.75-1.73l-4.45,7.71Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "348.46px 100.385px",
            }}
            id="elf543tzkqro"
            className="animable"
          />
          <path
            d="M359.35,99.45h1.17l-1.61-.76Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "359.715px 99.07px",
            }}
            id="elbljjp1l0fhr"
            className="animable"
          />
          <path
            d="M311.13,90.16h10.36l2-3.44a151.15,151.15,0,0,0-15.76-2.48Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "315.61px 87.2px",
            }}
            id="eljvc3d8ukfse"
            className="animable"
          />
          <polygon
            points="311.13 108.75 321.49 108.75 326.7 99.73 321.49 90.71 311.13 90.71 305.92 99.73 311.13 108.75"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "316.31px 99.73px",
            }}
            id="eltpjpsq51t4"
            className="animable"
          />
          <polygon
            points="311.13 127.89 305.92 136.91 311.13 145.93 321.49 145.93 326.7 136.91 321.49 127.89 311.13 127.89"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "316.31px 136.91px",
            }}
            id="elfxdudarkavi"
            className="animable"
          />
          <polygon
            points="369.71 341.12 374.92 332.11 369.71 323.09 359.35 323.09 354.15 332.11 359.35 341.12 369.71 341.12"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "364.535px 332.105px",
            }}
            id="el6hn4txnhdz8"
            className="animable"
          />
          <polygon
            points="385.79 258.02 375.43 258.02 370.22 267.04 375.43 276.06 385.79 276.06 391 267.04 385.79 258.02"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "380.61px 267.04px",
            }}
            id="elyudvwi9or5h"
            className="animable"
          />
          <polygon
            points="353.64 331.83 358.85 322.81 353.64 313.79 343.28 313.79 338.07 322.81 343.28 331.83 353.64 331.83"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "348.46px 322.81px",
            }}
            id="ell4kast3cmm"
            className="animable"
          />
          <polygon
            points="385.79 276.61 375.43 276.61 370.22 285.63 375.43 294.65 385.79 294.65 391 285.63 385.79 276.61"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "380.61px 285.63px",
            }}
            id="el264fvqgu5kb"
            className="animable"
          />
          <polygon
            points="385.79 295.2 375.43 295.2 370.22 304.22 375.43 313.24 385.79 313.24 391 304.22 385.79 295.2"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "380.61px 304.22px",
            }}
            id="eljqs46o7k2vg"
            className="animable"
          />
          <polygon
            points="385.79 331.83 391 322.81 385.79 313.79 375.43 313.79 370.22 322.81 375.43 331.83 385.79 331.83"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "380.61px 322.81px",
            }}
            id="elvr4y8ext42g"
            className="animable"
          />
          <polygon
            points="305.42 303.94 310.62 294.93 305.42 285.91 295.05 285.91 289.85 294.93 295.05 303.94 305.42 303.94"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "300.235px 294.925px",
            }}
            id="elorkit17jywg"
            className="animable"
          />
          <polygon
            points="289.34 294.65 294.55 285.63 289.34 276.61 278.98 276.61 273.77 285.63 278.98 294.65 289.34 294.65"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "284.16px 285.63px",
            }}
            id="eltsflia0i48"
            className="animable"
          />
          <polygon
            points="337.56 322.54 342.77 313.52 337.56 304.5 327.2 304.5 322 313.52 327.2 322.54 337.56 322.54"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "332.385px 313.52px",
            }}
            id="elovj7zq4mp58"
            className="animable"
          />
          <polygon
            points="321.49 313.24 326.7 304.22 321.49 295.2 311.13 295.2 305.92 304.22 311.13 313.24 321.49 313.24"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "316.31px 304.22px",
            }}
            id="elgystg1zp2kg"
            className="animable"
          />
          <path
            d="M391.5,136.63h10.36l1.82-3.15a87.43,87.43,0,0,0-12.67-14l-4.71,8.16Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "394.99px 128.055px",
            }}
            id="eldfplz1ds1r"
            className="animable"
          />
          <polygon
            points="343.28 146.48 338.07 155.5 343.28 164.52 353.64 164.52 358.85 155.5 353.64 146.48 343.28 146.48"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "348.46px 155.5px",
            }}
            id="elnvrp9ei67y"
            className="animable"
          />
          <polygon
            points="375.43 127.89 370.22 136.91 375.43 145.93 385.79 145.93 391 136.91 385.79 127.89 375.43 127.89"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "380.61px 136.91px",
            }}
            id="elpc7euki0sjo"
            className="animable"
          />
          <polygon
            points="359.35 137.19 354.15 146.21 359.35 155.22 369.71 155.22 374.92 146.21 369.71 137.19 359.35 137.19"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "364.535px 146.205px",
            }}
            id="el118a8omi7zgb"
            className="animable"
          />
          <polygon
            points="327.2 137.19 322 146.21 327.2 155.22 337.56 155.22 342.77 146.21 337.56 137.19 327.2 137.19"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "332.385px 146.205px",
            }}
            id="ell6imtmzn35"
            className="animable"
          />
          <polygon
            points="273.26 303.94 278.47 294.93 273.26 285.91 262.9 285.91 257.7 294.93 262.9 303.94 273.26 303.94"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "268.085px 294.925px",
            }}
            id="elbewnsa0tzx4"
            className="animable"
          />
          <polygon
            points="385.79 239.43 375.43 239.43 370.22 248.45 375.43 257.47 385.79 257.47 391 248.45 385.79 239.43"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "380.61px 248.45px",
            }}
            id="el1h9m0sqz54w"
            className="animable"
          />
          <polygon
            points="401.87 248.17 407.07 239.16 401.87 230.14 391.5 230.14 386.3 239.16 391.5 248.17 401.87 248.17"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "396.685px 239.155px",
            }}
            id="elnbtzltyr2h"
            className="animable"
          />
          <polygon
            points="401.87 211.55 391.5 211.55 386.3 220.57 391.5 229.58 401.87 229.58 407.07 220.57 401.87 211.55"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "396.685px 220.565px",
            }}
            id="el430e11yfu23"
            className="animable"
          />
          <path
            d="M417.94,220.29l3.65-6.32c-.5-2.49-.83-4.9-1.08-7.27l-2.57-4.45H407.58l-5.21,9,5.21,9Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "411.98px 211.27px",
            }}
            id="elvvyyhq2q5pq"
            className="animable"
          />
          <polygon
            points="214.68 146.48 209.47 155.5 214.68 164.52 225.04 164.52 230.25 155.5 225.04 146.48 214.68 146.48"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "219.86px 155.5px",
            }}
            id="elu7rngbvs6h9"
            className="animable"
          />
          <path
            d="M64.29,276.06l2.2-3.81a39.44,39.44,0,0,0-2.95,3.81Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "65.015px 274.155px",
            }}
            id="elicofh41usxq"
            className="animable"
          />
          <path
            d="M144.67,136.63l.31-.54-1.72.54Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "144.12px 136.36px",
            }}
            id="el5wd346akyx6"
            className="animable"
          />
          <path
            d="M69.5,322.81l-5.21-9H59.41a61.77,61.77,0,0,0,5.46,17Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "64.455px 322.31px",
            }}
            id="elbq5iv898yzp"
            className="animable"
          />
          <path
            d="M64.29,294.65l5.21-9-5.21-9h-1.1a35.38,35.38,0,0,0-5.43,18Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "63.63px 285.65px",
            }}
            id="el67aanftb4l3"
            className="animable"
          />
          <polygon
            points="166.45 137.19 161.25 146.21 166.45 155.22 176.82 155.22 182.02 146.21 176.82 137.19 166.45 137.19"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "171.635px 146.205px",
            }}
            id="elgkzgfrpsa5v"
            className="animable"
          />
          <polygon
            points="182.53 146.48 177.32 155.5 182.53 164.52 192.89 164.52 198.1 155.5 192.89 146.48 182.53 146.48"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "187.71px 155.5px",
            }}
            id="elsiczz56v5gp"
            className="animable"
          />
          <polygon
            points="96.44 331.83 101.65 322.81 96.44 313.79 86.08 313.79 80.87 322.81 86.08 331.83 96.44 331.83"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "91.26px 322.81px",
            }}
            id="eluam49hgwmn"
            className="animable"
          />
          <polygon
            points="230.75 155.78 225.55 164.79 230.75 173.81 241.12 173.81 246.32 164.79 241.12 155.78 230.75 155.78"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "235.935px 164.795px",
            }}
            id="el1p3zxwdm8i8"
            className="animable"
          />
          <polygon
            points="311.13 146.48 305.92 155.5 311.13 164.52 321.49 164.52 326.7 155.5 321.49 146.48 311.13 146.48"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "316.31px 155.5px",
            }}
            id="el8aa4kyxj9q5"
            className="animable"
          />
          <polygon
            points="198.6 155.78 193.4 164.79 198.6 173.81 208.97 173.81 214.17 164.79 208.97 155.78 198.6 155.78"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "203.785px 164.795px",
            }}
            id="elp0m8yq05qo"
            className="animable"
          />
          <polygon
            points="208.97 303.94 214.17 294.93 208.97 285.91 198.6 285.91 193.4 294.93 198.6 303.94 208.97 303.94"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "203.785px 294.925px",
            }}
            id="elluaeycna78j"
            className="animable"
          />
          <polygon
            points="112.52 322.54 117.72 313.52 112.52 304.5 102.15 304.5 96.95 313.52 102.15 322.54 112.52 322.54"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "107.335px 313.52px",
            }}
            id="el2l1guesppf"
            className="animable"
          />
          <polygon
            points="192.89 313.24 198.1 304.22 192.89 295.2 182.53 295.2 177.32 304.22 182.53 313.24 192.89 313.24"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "187.71px 304.22px",
            }}
            id="elxz8dj0fgdll"
            className="animable"
          />
          <polygon
            points="262.9 137.19 257.7 146.21 262.9 155.22 273.26 155.22 278.47 146.21 273.26 137.19 262.9 137.19"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "268.085px 146.205px",
            }}
            id="el8k95cw5pgcn"
            className="animable"
          />
          <polygon
            points="241.12 303.94 246.32 294.93 241.12 285.91 230.75 285.91 225.55 294.93 230.75 303.94 241.12 303.94"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "235.935px 294.925px",
            }}
            id="elcyurq82j27a"
            className="animable"
          />
          <polygon
            points="257.19 294.65 262.4 285.63 257.19 276.61 246.83 276.61 241.62 285.63 246.83 294.65 257.19 294.65"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "252.01px 285.63px",
            }}
            id="elw4ph3gglzb"
            className="animable"
          />
          <polygon
            points="128.59 331.83 133.8 322.81 128.59 313.79 118.23 313.79 113.02 322.81 118.23 331.83 128.59 331.83"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "123.41px 322.81px",
            }}
            id="elohzmg2x5kq"
            className="animable"
          />
          <polygon
            points="144.67 322.54 149.87 313.52 144.67 304.5 134.3 304.5 129.1 313.52 134.3 322.54 144.67 322.54"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "139.485px 313.52px",
            }}
            id="elw1qizda1lfg"
            className="animable"
          />
          <polygon
            points="160.74 331.83 165.95 322.81 160.74 313.79 150.38 313.79 145.17 322.81 150.38 331.83 160.74 331.83"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "155.56px 322.81px",
            }}
            id="ely46n0rudr5"
            className="animable"
          />
          <polygon
            points="176.82 322.54 182.02 313.52 176.82 304.5 166.45 304.5 161.25 313.52 166.45 322.54 176.82 322.54"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "171.635px 313.52px",
            }}
            id="el50l0ejj6d0h"
            className="animable"
          />
          <polygon
            points="225.04 313.24 230.25 304.22 225.04 295.2 214.68 295.2 209.47 304.22 214.68 313.24 225.04 313.24"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "219.86px 304.22px",
            }}
            id="elp7yg9hd50dd"
            className="animable"
          />
          <polygon
            points="401.87 304.5 391.5 304.5 386.3 313.52 391.5 322.54 401.87 322.54 407.07 313.52 401.87 304.5"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "396.685px 313.52px",
            }}
            id="el3m2afanufd"
            className="animable"
          />
          <polygon
            points="401.87 285.91 391.5 285.91 386.3 294.93 391.5 303.94 401.87 303.94 407.07 294.93 401.87 285.91"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "396.685px 294.925px",
            }}
            id="elldw0t4ppbok"
            className="animable"
          />
          <polygon
            points="401.87 267.32 391.5 267.32 386.3 276.34 391.5 285.35 401.87 285.35 407.07 276.34 401.87 267.32"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "396.685px 276.335px",
            }}
            id="el8w3bejwk9le"
            className="animable"
          />
          <polygon
            points="401.87 323.09 391.5 323.09 386.3 332.11 391.5 341.12 401.87 341.12 407.07 332.11 401.87 323.09"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "396.685px 332.105px",
            }}
            id="elii2atc2503i"
            className="animable"
          />
          <polygon
            points="401.87 248.73 391.5 248.73 386.3 257.75 391.5 266.76 401.87 266.76 407.07 257.75 401.87 248.73"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "396.685px 257.745px",
            }}
            id="el1tf4kbacecm"
            className="animable"
          />
          <polygon
            points="321.49 331.83 326.7 322.81 321.49 313.79 311.13 313.79 305.92 322.81 311.13 331.83 321.49 331.83"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "316.31px 322.81px",
            }}
            id="eluxevknp53w"
            className="animable"
          />
          <polygon
            points="337.56 341.12 342.77 332.11 337.56 323.09 327.2 323.09 322 332.11 327.2 341.12 337.56 341.12"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "332.385px 332.105px",
            }}
            id="elixc36wcauw"
            className="animable"
          />
          <polygon
            points="417.94 257.47 423.15 248.45 417.94 239.43 407.58 239.43 402.37 248.45 407.58 257.47 417.94 257.47"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "412.76px 248.45px",
            }}
            id="el0xf7q5nn15nf"
            className="animable"
          />
          <path
            d="M402.65,343l-.79-1.36H391.5l-.84,1.47C394.87,343,398.87,343,402.65,343Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "396.655px 342.375px",
            }}
            id="elf3jwidlrcq"
            className="animable"
          />
          <path
            d="M371.72,345.15l-2-3.47H359.35l-4.51,7.81c1.14-.38,2.3-.75,3.49-1.11A121.39,121.39,0,0,1,371.72,345.15Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "363.28px 345.585px",
            }}
            id="eldh21uzgvva"
            className="animable"
          />
          <path
            d="M370.22,118.32l5.21,9h10.36l4.78-8.29a114.72,114.72,0,0,0-12.67-9.75h-2.47Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "380.395px 118.3px",
            }}
            id="elkfp4pxl9l2"
            className="animable"
          />
          <polygon
            points="295.05 155.78 289.85 164.79 295.05 173.81 305.42 173.81 310.62 164.79 305.42 155.78 295.05 155.78"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "300.235px 164.795px",
            }}
            id="el9tq0jzp337"
            className="animable"
          />
          <polygon
            points="246.83 165.07 241.62 174.09 246.83 183.11 257.19 183.11 262.4 174.09 257.19 165.07 246.83 165.07"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "252.01px 174.09px",
            }}
            id="elaa91j2ovo6j"
            className="animable"
          />
          <polygon
            points="278.98 127.89 273.77 136.91 278.98 145.93 289.34 145.93 294.55 136.91 289.34 127.89 278.98 127.89"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "284.16px 136.91px",
            }}
            id="el0905de1nyrsw"
            className="animable"
          />
          <polygon
            points="327.2 118.6 322 127.61 327.2 136.63 337.56 136.63 342.77 127.61 337.56 118.6 327.2 118.6"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "332.385px 127.615px",
            }}
            id="el0p09evld7ktn"
            className="animable"
          />
          <polygon
            points="359.35 118.6 354.15 127.61 359.35 136.63 369.71 136.63 374.92 127.61 369.71 118.6 359.35 118.6"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "364.535px 127.615px",
            }}
            id="eluhpx53ux3ej"
            className="animable"
          />
          <polygon
            points="343.28 127.89 338.07 136.91 343.28 145.93 353.64 145.93 358.85 136.91 353.64 127.89 343.28 127.89"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "348.46px 136.91px",
            }}
            id="eltm9z7vztzj8"
            className="animable"
          />
          <path
            d="M426.52,229.58a76.31,76.31,0,0,1-4.75-14.77l-3.32,5.76,5.2,9Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "422.485px 222.195px",
            }}
            id="eljyxpzro1puf"
            className="animable"
          />
          <polygon
            points="305.42 322.54 310.62 313.52 305.42 304.5 295.05 304.5 289.85 313.52 295.05 322.54 305.42 322.54"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "300.235px 313.52px",
            }}
            id="elalewjirpp6"
            className="animable"
          />
          <polygon
            points="417.94 220.84 407.58 220.84 402.37 229.86 407.58 238.88 417.94 238.88 423.15 229.86 417.94 220.84"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "412.76px 229.86px",
            }}
            id="ele9ayu4xpzap"
            className="animable"
          />
          <polygon
            points="295.05 137.19 289.85 146.21 295.05 155.22 305.42 155.22 310.62 146.21 305.42 137.19 295.05 137.19"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "300.235px 146.205px",
            }}
            id="elvsuu88p050l"
            className="animable"
          />
          <polygon
            points="289.34 313.24 294.55 304.22 289.34 295.2 278.98 295.2 273.77 304.22 278.98 313.24 289.34 313.24"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "284.16px 304.22px",
            }}
            id="elbjnfxotq8s"
            className="animable"
          />
          <polygon
            points="198.6 137.19 193.4 146.21 198.6 155.22 208.97 155.22 214.17 146.21 208.97 137.19 198.6 137.19"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "203.785px 146.205px",
            }}
            id="elpzzvdntkr3"
            className="animable"
          />
          <path
            d="M163,130.58l3.49,6h10.37l5.2-9-1.67-2.9C175.27,126.58,169.56,128.5,163,130.58Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "172.53px 130.63px",
            }}
            id="elr2ftsrxms9o"
            className="animable"
          />
          <polygon
            points="214.68 127.89 209.47 136.91 214.68 145.93 225.04 145.93 230.25 136.91 225.04 127.89 214.68 127.89"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "219.86px 136.91px",
            }}
            id="elvg6mh54287k"
            className="animable"
          />
          <path
            d="M80.37,341.68H73a45.8,45.8,0,0,0,11.82,7.77Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "78.91px 345.565px",
            }}
            id="elahcjg3wdkcj"
            className="animable"
          />
          <polygon
            points="112.52 341.12 117.72 332.11 112.52 323.09 102.15 323.09 96.95 332.11 102.15 341.12 112.52 341.12"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "107.335px 332.105px",
            }}
            id="eltsq7oxhm5ti"
            className="animable"
          />
          <polygon
            points="278.98 146.48 273.77 155.5 278.98 164.52 289.34 164.52 294.55 155.5 289.34 146.48 278.98 146.48"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "284.16px 155.5px",
            }}
            id="elhzn69c1qdzi"
            className="animable"
          />
          <polygon
            points="230.75 137.19 225.55 146.21 230.75 155.22 241.12 155.22 246.32 146.21 241.12 137.19 230.75 137.19"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "235.935px 146.205px",
            }}
            id="elkgzapom6v"
            className="animable"
          />
          <polygon
            points="262.9 155.78 257.7 164.79 262.9 173.81 273.26 173.81 278.47 164.79 273.26 155.78 262.9 155.78"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "268.085px 164.795px",
            }}
            id="elte3u43ri4i"
            className="animable"
          />
          <polygon
            points="246.83 146.48 241.62 155.5 246.83 164.52 257.19 164.52 262.4 155.5 257.19 146.48 246.83 146.48"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "252.01px 155.5px",
            }}
            id="el1hpl629yvmg"
            className="animable"
          />
          <polygon
            points="182.53 127.89 177.32 136.91 182.53 145.93 192.89 145.93 198.1 136.91 192.89 127.89 182.53 127.89"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "187.71px 136.91px",
            }}
            id="el2pnxil1m7fw"
            className="animable"
          />
          <polygon
            points="241.12 322.54 246.32 313.52 241.12 304.5 230.75 304.5 225.55 313.52 230.75 322.54 241.12 322.54"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "235.935px 313.52px",
            }}
            id="elqwdh8arx6ba"
            className="animable"
          />
          <polygon
            points="257.19 313.24 262.4 304.22 257.19 295.2 246.83 295.2 241.62 304.22 246.83 313.24 257.19 313.24"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "252.01px 304.22px",
            }}
            id="el0qd82o0rttm"
            className="animable"
          />
          <polygon
            points="273.26 322.54 278.47 313.52 273.26 304.5 262.9 304.5 257.7 313.52 262.9 322.54 273.26 322.54"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "268.085px 313.52px",
            }}
            id="elrr8uxbokkk8"
            className="animable"
          />
          <polygon
            points="128.59 350.42 133.8 341.4 128.59 332.38 118.23 332.38 113.02 341.4 118.23 350.42 128.59 350.42"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "123.41px 341.4px",
            }}
            id="elnazx2e49g8g"
            className="animable"
          />
          <polygon
            points="225.04 331.83 230.25 322.81 225.04 313.79 214.68 313.79 209.47 322.81 214.68 331.83 225.04 331.83"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "219.86px 322.81px",
            }}
            id="elk057n4o7wwm"
            className="animable"
          />
          <polygon
            points="208.97 322.54 214.17 313.52 208.97 304.5 198.6 304.5 193.4 313.52 198.6 322.54 208.97 322.54"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "203.785px 313.52px",
            }}
            id="elg3vzb4nw8yh"
            className="animable"
          />
          <polygon
            points="144.67 341.12 149.87 332.11 144.67 323.09 134.3 323.09 129.1 332.11 134.3 341.12 144.67 341.12"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "139.485px 332.105px",
            }}
            id="eliwvzkiq17s"
            className="animable"
          />
          <polygon
            points="176.82 341.12 182.02 332.11 176.82 323.09 166.45 323.09 161.25 332.11 166.45 341.12 176.82 341.12"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "171.635px 332.105px",
            }}
            id="el34vnrxzz03u"
            className="animable"
          />
          <polygon
            points="192.89 331.83 198.1 322.81 192.89 313.79 182.53 313.79 177.32 322.81 182.53 331.83 192.89 331.83"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "187.71px 322.81px",
            }}
            id="elmul2mvuv9bs"
            className="animable"
          />
        </g>
      </g>
    </g>
    <g
      id="freepik--Shadow--inject-183"
      className="animable"
      style={{
        transformOrigin: "249.75px 399.18px",
      }}
    >
      <polygon
        points="381.79 408.68 307.67 389.68 377.64 389.68 453.45 408.68 381.79 408.68"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "380.56px 399.18px",
        }}
        id="elqqxw603m0ui"
        className="animable"
      />
      <polygon
        points="117.71 408.68 191.83 389.68 121.87 389.68 46.05 408.68 117.71 408.68"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "118.94px 399.18px",
        }}
        id="elv6gzwflhdnk"
        className="animable"
      />
    </g>
    <g
      id="freepik--Clouds--inject-183"
      className="animable"
      style={{
        transformOrigin: "246.046px 219.87px",
      }}
    >
      <path
        d="M253.17,329.64s-.07-.35-.18-1"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "253.08px 329.14px",
        }}
        id="elaoi6vgnguli"
        className="animable"
      />
      <path
        d="M252.66,326.68c-1.07-6.93-2.88-24.22,3.91-31.94,8.61-9.77,18.93-1.4,47.69,0s43.56-2.79,43.86-27.45S331.55,241,331.55,241H320.89"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeDasharray: "2.00773, 2.00773",
          transformOrigin: "299.868px 283.84px",
        }}
        id="elkuamq9yyxv"
        className="animable"
      />
      <line
        x1={319.88}
        y1={241.04}
        x2={318.88}
        y2={241.04}
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "319.38px 241.04px",
        }}
        id="elxcyab0nhtvl"
        className="animable"
      />
      <line
        x1={318.88}
        y1={165.29}
        x2={319.88}
        y2={165.29}
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "319.38px 165.29px",
        }}
        id="el9zobe9kkyn9"
        className="animable"
      />
      <path
        d="M321.88,165.29h36s10.85.94,10.4,13.46S349.78,203,350.93,228s43.81,21.27,49.62,46.3S363.68,298.08,359.71,311a22.11,22.11,0,0,0-1.8,9.18c0,4.2,0,14.18,0,18.45"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeDasharray: "1.99976, 1.99976",
          transformOrigin: "361.486px 251.96px",
        }}
        id="elca0q40c0du"
        className="animable"
      />
      <path
        d="M357.91,339.64v1"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "357.91px 340.14px",
        }}
        id="el7pp4jrm1sud"
        className="animable"
      />
      <line
        x1={186.36}
        y1={94.43}
        x2={185.36}
        y2={94.43}
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "185.86px 94.43px",
        }}
        id="el2jqedntkcxf"
        className="animable"
      />
      <path
        d="M183.37,94.43H165.74s-48-.55-48,40c0,18.77,6.52,32.8,25.84,55.86s19,95.14,12.72,108.07-19.6,11-24.28,18c0,0-3.37,3.53-3.66,7.84-.23,3.39-.1,14.5,0,19.06"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeDasharray: "1.98825, 1.98825",
          transformOrigin: "150.555px 218.845px",
        }}
        id="els538g3mhta"
        className="animable"
      />
      <path
        d="M128.31,344.31c0,.63,0,1,0,1"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "128.31px 344.81px",
        }}
        id="el0j53jssg1b4"
        className="animable"
      />
      <path
        d="M197.15,146.64A12.29,12.29,0,0,0,191,148.3a16.49,16.49,0,0,0,.26-2.91,17,17,0,0,0-33.71-3.14,15,15,0,1,0-5.3,29.09h44.91a12.35,12.35,0,0,0,0-24.7Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "173.459px 149.858px",
        }}
        id="ellxo0ca5xf5o"
        className="animable"
      />
      <path
        d="M387,196.82a13.13,13.13,0,0,0-6.56,1.76,18.49,18.49,0,0,0,.27-3.09,18.1,18.1,0,0,0-35.89-3.34,16,16,0,1,0-5.65,31H387a13.15,13.15,0,0,0,0-26.3Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "361.695px 200.268px",
        }}
        id="elwzjcjoohl4"
        className="animable"
      />
      <line
        x1={378.5}
        y1={226.5}
        x2={382.5}
        y2={226.5}
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "380.5px 226.5px",
        }}
        id="elg1x15sq1vx"
        className="animable"
      />
      <line
        x1={359}
        y1={226.5}
        x2={373}
        y2={226.5}
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "366px 226.5px",
        }}
        id="elbq9lzyiwsxd"
        className="animable"
      />
      <path
        d="M345.76,178.19a13,13,0,0,1,7.24-3"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "349.38px 176.69px",
        }}
        id="elluklwfawcb"
        className="animable"
      />
      <path
        d="M341,185.5a11.87,11.87,0,0,1,2-4.38"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "342px 183.31px",
        }}
        id="elsh9dgcstx58"
        className="animable"
      />
      <path
        d="M189.15,257.7a19.72,19.72,0,0,0-9.86,2.65,26.37,26.37,0,0,0,.41-4.65,27.19,27.19,0,0,0-53.92-5,24,24,0,1,0-8.48,46.53h71.85a19.76,19.76,0,0,0,0-39.51Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "150.989px 262.86px",
        }}
        id="eldffiuu4cin"
        className="animable"
      />
      <path
        d="M98.85,295.22A25.24,25.24,0,0,0,102,297"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "100.425px 296.11px",
        }}
        id="elpmgq7y4lleq"
        className="animable"
      />
      <path
        d="M91,286a19,19,0,0,0,4.1,6.09"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "93.05px 289.045px",
        }}
        id="elut8j8qggzf"
        className="animable"
      />
      <path
        d="M135.33,227a22.88,22.88,0,0,1,14.17-4"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "142.415px 224.984px",
        }}
        id="elza7vu0jwxbj"
        className="animable"
      />
      <path
        d="M131,231a16.32,16.32,0,0,1,2.36-2.49"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "132.18px 229.755px",
        }}
        id="el2oqfu7o9i65"
        className="animable"
      />
    </g>
    <g
      id="freepik--device-1--inject-183"
      className="animable"
      style={{
        transformOrigin: "252.19px 164.57px",
      }}
    >
      <rect
        x={314.13}
        y={159.76}
        width={4.75}
        height={12.56}
        style={{
          fill: "rgb(46, 53, 58)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "316.505px 166.04px",
        }}
        id="elhfr34cdxz4l"
        className="animable"
      />
      <rect
        x={314.13}
        y={233.85}
        width={4.75}
        height={12.56}
        style={{
          fill: "rgb(46, 53, 58)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "316.505px 240.13px",
        }}
        id="elubmx0qlc279"
        className="animable"
      />
      <rect
        x={186.36}
        y={87.09}
        width={4.75}
        height={12.56}
        style={{
          fill: "rgb(46, 53, 58)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "188.735px 93.37px",
        }}
        id="elar31zsbkzpd"
        className="animable"
      />
      <rect
        x={186.36}
        y={197.28}
        width={4.75}
        height={12.56}
        style={{
          fill: "rgb(46, 53, 58)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "188.735px 203.56px",
        }}
        id="elnc4ul9zv96"
        className="animable"
      />
      <rect
        x={190.66}
        y={80.14}
        width={125.03}
        height={27.08}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "253.175px 93.68px",
        }}
        id="elhgr56q7ypuj"
        className="animable"
      />
      <rect
        x={196.67}
        y={87.05}
        width={86.17}
        height={13.26}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "239.755px 93.68px",
        }}
        id="elrwtge9c67p"
        className="animable"
      />
      <circle
        cx={294.51}
        cy={93.1}
        r={3.34}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "294.51px 93.1px",
        }}
        id="el8pqzx8h2ka"
        className="animable"
      />
      <path
        d="M310.94,93.1a3.34,3.34,0,1,0-3.34,3.34A3.34,3.34,0,0,0,310.94,93.1Z"
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "307.6px 93.1px",
        }}
        id="elcelx5cw8r3d"
        className="animable"
      />
      <circle
        cx={275.02}
        cy={93.1}
        r={3.34}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "275.02px 93.1px",
        }}
        id="elij81ogd073"
        className="animable"
      />
      <rect
        x={195.41}
        y={107.22}
        width={115.54}
        height={9.27}
        style={{
          fill: "rgb(46, 53, 58)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "253.18px 111.855px",
        }}
        id="elddgdnrprada"
        className="animable"
      />
      <rect
        x={190.66}
        y={116.49}
        width={125.03}
        height={27.08}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "253.175px 130.03px",
        }}
        id="eli2qc2c4n88"
        className="animable"
      />
      <rect
        x={196.67}
        y={123.4}
        width={86.17}
        height={13.26}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "239.755px 130.03px",
        }}
        id="elz9n1aq15om"
        className="animable"
      />
      <circle
        cx={294.51}
        cy={129.46}
        r={3.34}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "294.51px 129.46px",
        }}
        id="elemd8moe7t5"
        className="animable"
      />
      <path
        d="M310.94,129.46a3.34,3.34,0,1,0-3.34,3.34A3.33,3.33,0,0,0,310.94,129.46Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "307.6px 129.46px",
        }}
        id="el4w9ee8xfzjj"
        className="animable"
      />
      <circle
        cx={275.02}
        cy={129.46}
        r={3.34}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "275.02px 129.46px",
        }}
        id="elyqrgm4qefd"
        className="animable"
      />
      <rect
        x={195.41}
        y={143.58}
        width={115.54}
        height={9.27}
        style={{
          fill: "rgb(46, 53, 58)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "253.18px 148.215px",
        }}
        id="elrkhlyopffl9"
        className="animable"
      />
      <rect
        x={190.66}
        y={152.85}
        width={125.03}
        height={27.08}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "253.175px 166.39px",
        }}
        id="elzewfikacuje"
        className="animable"
      />
      <rect
        x={196.67}
        y={159.76}
        width={86.17}
        height={13.26}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "239.755px 166.39px",
        }}
        id="el8zc9mws6c8i"
        className="animable"
      />
      <circle
        cx={294.51}
        cy={165.81}
        r={3.34}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "294.51px 165.81px",
        }}
        id="elczxyq3zq7m9"
        className="animable"
      />
      <path
        d="M310.94,165.81a3.34,3.34,0,1,0-3.34,3.34A3.34,3.34,0,0,0,310.94,165.81Z"
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "307.6px 165.81px",
        }}
        id="el7lnjoaop7y"
        className="animable"
      />
      <circle
        cx={275.02}
        cy={165.81}
        r={3.34}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "275.02px 165.81px",
        }}
        id="el9xg4xdwiumm"
        className="animable"
      />
      <rect
        x={195.41}
        y={179.93}
        width={115.54}
        height={9.27}
        style={{
          fill: "rgb(46, 53, 58)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "253.18px 184.565px",
        }}
        id="elrqbrtp1ajz"
        className="animable"
      />
      <rect
        x={190.66}
        y={189.2}
        width={125.03}
        height={27.08}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "253.175px 202.74px",
        }}
        id="elci9zcrde0w"
        className="animable"
      />
      <rect
        x={196.67}
        y={196.11}
        width={86.17}
        height={13.26}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "239.755px 202.74px",
        }}
        id="elp7lk3kr0v"
        className="animable"
      />
      <circle
        cx={294.51}
        cy={202.17}
        r={3.34}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "294.51px 202.17px",
        }}
        id="el1xcgcendf4"
        className="animable"
      />
      <path
        d="M310.94,202.17a3.34,3.34,0,1,0-3.34,3.34A3.33,3.33,0,0,0,310.94,202.17Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "307.6px 202.17px",
        }}
        id="eltm6dqv7wgoh"
        className="animable"
      />
      <circle
        cx={275.02}
        cy={202.17}
        r={3.34}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "275.02px 202.17px",
        }}
        id="elhve7e5rp6v"
        className="animable"
      />
      <rect
        x={195.41}
        y={216.29}
        width={115.54}
        height={9.27}
        style={{
          fill: "rgb(46, 53, 58)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "253.18px 220.925px",
        }}
        id="el0zek32v3stnn"
        className="animable"
      />
      <rect
        x={190.66}
        y={225.56}
        width={125.03}
        height={27.08}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "253.175px 239.1px",
        }}
        id="elquynsixdc7"
        className="animable"
      />
      <rect
        x={196.67}
        y={232.47}
        width={86.17}
        height={13.26}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "239.755px 239.1px",
        }}
        id="el2lmfzhwk034"
        className="animable"
      />
      <circle
        cx={294.51}
        cy={238.52}
        r={3.34}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "294.51px 238.52px",
        }}
        id="elspkwafsrmok"
        className="animable"
      />
      <path
        d="M310.94,238.52a3.34,3.34,0,1,0-3.34,3.34A3.34,3.34,0,0,0,310.94,238.52Z"
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "307.6px 238.52px",
        }}
        id="el5080u2dmt2o"
        className="animable"
      />
      <circle
        cx={275.02}
        cy={238.52}
        r={3.34}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "275.02px 238.52px",
        }}
        id="ellvo61vs3wb"
        className="animable"
      />
      <line
        x1={261.5}
        y1={76.5}
        x2={268.5}
        y2={76.5}
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "265px 76.5px",
        }}
        id="elxl22dq9ud0a"
        className="animable"
      />
      <line
        x1={213}
        y1={76.5}
        x2={253.5}
        y2={76.5}
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "233.25px 76.5px",
        }}
        id="eloatcwurqfa"
        className="animable"
      />
      <line
        x1={185.5}
        y1={241}
        x2={185.5}
        y2={246}
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "185.5px 243.5px",
        }}
        id="el517s5y6k63u"
        className="animable"
      />
      <line
        x1={185.5}
        y1={225.5}
        x2={185.5}
        y2={236}
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "185.5px 230.75px",
        }}
        id="elup4iuytr6rl"
        className="animable"
      />
    </g>
    <g
      id="freepik--device-2--inject-183"
      className="animable"
      style={{
        transformOrigin: "239.07px 216.435px",
      }}
    >
      <path
        d="M369.91,133.09l-7-13.85a.76.76,0,1,0-1.36.67l6.69,14A1.06,1.06,0,0,0,368,135l7.08,13.27c.19.38.79.46,1.36.18s.87-.81.68-1.18l-6.2-13.71A1,1,0,0,0,369.91,133.09Z"
        style={{
          fill: "rgb(46, 53, 58)",
          transformOrigin: "369.311px 133.692px",
        }}
        id="el8t1fdqbj1rg"
        className="animable"
      />
      <path
        d="M408.67,133.09l7.05-13.85a.75.75,0,0,1,1-.35.77.77,0,0,1,.35,1l-6.7,14a1,1,0,0,1,.22,1.09l-7.08,13.27c-.19.38-.8.46-1.36.18s-.87-.81-.69-1.18l6.21-13.71A1,1,0,0,1,408.67,133.09Z"
        style={{
          fill: "rgb(46, 53, 58)",
          transformOrigin: "409.273px 133.707px",
        }}
        id="el9lnu6txa738"
        className="animable"
      />
      <polygon
        points="411.35 148.87 367.38 148.87 370.68 143.27 408.05 143.27 411.35 148.87"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "389.365px 146.07px",
        }}
        id="el1k98x61qewx"
        className="animable"
      />
      <rect
        x={367.38}
        y={148.87}
        width={43.96}
        height={5.6}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "389.36px 151.67px",
        }}
        id="elz61yrpf1le"
        className="animable"
      />
      <path
        d="M388.6,152.91H371.7a1.24,1.24,0,0,1-1.23-1.24h0a1.23,1.23,0,0,1,1.23-1.23h16.9a1.23,1.23,0,0,1,1.23,1.23h0A1.24,1.24,0,0,1,388.6,152.91Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "380.15px 151.675px",
        }}
        id="elj3lhkmani0a"
        className="animable"
      />
      <circle
        cx={398.83}
        cy={151.52}
        r={1.08}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "398.83px 151.52px",
        }}
        id="elf4gpocgmhtg"
        className="animable"
      />
      <path
        d="M404.39,151.52a1.09,1.09,0,1,0-1.09,1.09A1.08,1.08,0,0,0,404.39,151.52Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "403.3px 151.52px",
        }}
        id="ely90mymkuzid"
        className="animable"
      />
      <circle
        cx={407.78}
        cy={151.52}
        r={1.08}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "407.78px 151.52px",
        }}
        id="elgfzoijwbw4"
        className="animable"
      />
      <path
        d="M426.71,117.38a10.22,10.22,0,0,1,.21,2.09c0,.09,0,.17,0,.26"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "426.815px 118.555px",
        }}
        id="elni4wna2s2n"
        className="animable"
      />
      <path
        d="M410.83,110.78a10.38,10.38,0,0,1,15.37,4.88"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "418.515px 112.365px",
        }}
        id="elpxocgqxn46m"
        className="animable"
      />
      <path
        d="M413.73,112.29a7.71,7.71,0,0,0-1.58.84"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "412.94px 112.71px",
        }}
        id="elyqe9q0c182"
        className="animable"
      />
      <path
        d="M424.22,119.66a1.15,1.15,0,0,0,0-.19,7.71,7.71,0,0,0-7.71-7.71,6.78,6.78,0,0,0-1.2.1"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "419.767px 115.71px",
        }}
        id="elvtbg2tzp1ih"
        className="animable"
      />
      <path
        d="M421.72,119.6v-.13a5.2,5.2,0,0,0-5.2-5.2,5.13,5.13,0,0,0-3.14,1.07"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "417.55px 116.935px",
        }}
        id="elyvhqkeozwj"
        className="animable"
      />
      <path
        d="M415.43,116.82a2.82,2.82,0,0,0-.89.59"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "414.985px 117.115px",
        }}
        id="elbmpm3jewl3p"
        className="animable"
      />
      <path
        d="M419.39,119.54v-.07a2.87,2.87,0,0,0-2.87-2.87"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "417.955px 118.07px",
        }}
        id="elbbn99cq908s"
        className="animable"
      />
      <path
        d="M351.92,118a10.56,10.56,0,0,0-.11,1.49c0,.09,0,.17,0,.26"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "351.865px 118.875px",
        }}
        id="eln62jpr3ddmp"
        className="animable"
      />
      <path
        d="M364.28,109.28a10.09,10.09,0,0,0-2.07-.21,10.4,10.4,0,0,0-9.86,7.08"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "358.315px 112.61px",
        }}
        id="elsrfudk1ltt9"
        className="animable"
      />
      <path
        d="M367.9,110.78a10.46,10.46,0,0,0-2.11-1.07"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "366.845px 110.245px",
        }}
        id="elzl60fnkzn0h"
        className="animable"
      />
      <path
        d="M361.59,111.79c.2,0,.41,0,.62,0a7.71,7.71,0,0,1,4.38,1.37"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "364.09px 112.475px",
        }}
        id="elanoe4ltrlri"
        className="animable"
      />
      <path
        d="M354.51,119.66c0-.06,0-.12,0-.19a7.71,7.71,0,0,1,4.71-7.1"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "356.865px 116.015px",
        }}
        id="el3xtug5y219g"
        className="animable"
      />
      <path
        d="M357,119.6v-.13a5.2,5.2,0,0,1,5.2-5.2,5.13,5.13,0,0,1,3.14,1.07"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "361.17px 116.935px",
        }}
        id="elpcnxn938g3"
        className="animable"
      />
      <path
        d="M359.35,119.54a.15.15,0,0,1,0-.07,2.86,2.86,0,0,1,4.86-2.06"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "361.778px 118.067px",
        }}
        id="elud0tsvzxqz"
        className="animable"
      />
      <path
        d="M231.63,302.42l-7-13.85a.75.75,0,0,0-1-.35.77.77,0,0,0-.35,1l6.7,14a1,1,0,0,0-.22,1.08l7.08,13.28c.19.37.8.45,1.36.18s.87-.81.69-1.19l-6.21-13.71A1.06,1.06,0,0,0,231.63,302.42Z"
        style={{
          fill: "rgb(46, 53, 58)",
          transformOrigin: "231.077px 303.033px",
        }}
        id="el94ht745qsmt"
        className="animable"
      />
      <path
        d="M270.4,302.42l7-13.85a.76.76,0,1,1,1.36.67l-6.69,14a1,1,0,0,1,.21,1.08l-7.08,13.28c-.19.37-.79.45-1.36.18s-.87-.81-.69-1.19l6.21-13.71A1.06,1.06,0,0,1,270.4,302.42Z"
        style={{
          fill: "rgb(46, 53, 58)",
          transformOrigin: "270.981px 303.018px",
        }}
        id="elo3prt8jr6bo"
        className="animable"
      />
      <polygon
        points="273.07 318.2 229.11 318.2 232.4 312.61 269.77 312.61 273.07 318.2"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "251.09px 315.405px",
        }}
        id="el1iey46mdqt4g"
        className="animable"
      />
      <rect
        x={229.11}
        y={318.2}
        width={43.96}
        height={5.6}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "251.09px 321px",
        }}
        id="elmofczsy8o9"
        className="animable"
      />
      <rect
        x={232.19}
        y={319.77}
        width={19.37}
        height={2.47}
        rx={1.23}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "241.875px 321.005px",
        }}
        id="el2rpbhe4wzby"
        className="animable"
      />
      <circle
        cx={260.55}
        cy={320.86}
        r={1.08}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "260.55px 320.86px",
        }}
        id="elrqxtve5a409"
        className="animable"
      />
      <path
        d="M266.11,320.86a1.09,1.09,0,1,0-1.08,1.08A1.08,1.08,0,0,0,266.11,320.86Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "265.02px 320.85px",
        }}
        id="el3wanmv2kcgw"
        className="animable"
      />
      <circle
        cx={269.5}
        cy={320.86}
        r={1.08}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "269.5px 320.86px",
        }}
        id="eloz1og601rnh"
        className="animable"
      />
      <path
        d="M283.44,279.79a10.41,10.41,0,0,1,5.21,9c0,.09,0,.17,0,.26"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "286.045px 284.42px",
        }}
        id="eluw693ih0mcl"
        className="animable"
      />
      <path
        d="M272.55,280.11a10.38,10.38,0,0,1,5.7-1.7,10.18,10.18,0,0,1,3.37.56"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "277.085px 279.26px",
        }}
        id="el6xvb9cffqwy"
        className="animable"
      />
      <path
        d="M276.9,281.21a7.82,7.82,0,0,0-3,1.25"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "275.4px 281.835px",
        }}
        id="elvinfrd2u4c"
        className="animable"
      />
      <path
        d="M286,289c0-.06,0-.12,0-.19a7.72,7.72,0,0,0-6.95-7.67"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "282.525px 285.07px",
        }}
        id="elcp7b7rakb0f"
        className="animable"
      />
      <path
        d="M282,285.19a5.15,5.15,0,0,0-3.73-1.58,5.22,5.22,0,0,0-3.15,1.06"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "278.56px 284.4px",
        }}
        id="elc9kkn5bll6j"
        className="animable"
      />
      <path
        d="M283.44,288.93s0-.08,0-.13a5.24,5.24,0,0,0-.41-2"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "283.235px 287.865px",
        }}
        id="el4km9gog0tea"
        className="animable"
      />
      <path
        d="M277.55,286a2.88,2.88,0,0,0-1.29.72"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "276.905px 286.36px",
        }}
        id="elrlog1266fs9"
        className="animable"
      />
      <path
        d="M281.11,288.87s0,0,0-.07A2.86,2.86,0,0,0,279,286"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "280.055px 287.435px",
        }}
        id="elit5vi5u07f"
        className="animable"
      />
      <path
        d="M213.68,287.06a10,10,0,0,0-.15,1.74c0,.09,0,.17,0,.26"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "213.605px 288.06px",
        }}
        id="el40agsbd3xmy"
        className="animable"
      />
      <path
        d="M219.83,279.24a10.5,10.5,0,0,0-5.62,5.86"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "217.02px 282.17px",
        }}
        id="elq1wgdrgnagh"
        className="animable"
      />
      <path
        d="M229.62,280.11a10.35,10.35,0,0,0-5.69-1.7,10.22,10.22,0,0,0-2.09.21"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "225.73px 279.26px",
        }}
        id="eluhqiouy2gw"
        className="animable"
      />
      <path
        d="M218.11,283.75a7.72,7.72,0,0,1,10.2-1.29"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "223.21px 282.424px",
        }}
        id="elhnf71b98z3t"
        className="animable"
      />
      <path
        d="M216.23,289c0-.06,0-.12,0-.19a7.75,7.75,0,0,1,.74-3.3"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "216.6px 287.255px",
        }}
        id="elbcxjxtivqet"
        className="animable"
      />
      <path
        d="M220.21,285.17a5.2,5.2,0,0,1,6.86-.5"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "223.64px 284.39px",
        }}
        id="elilcb4mhvyf9"
        className="animable"
      />
      <path
        d="M218.74,288.93s0-.08,0-.13a5.11,5.11,0,0,1,.51-2.24"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "218.995px 287.745px",
        }}
        id="el0r3kkpu1i7hm"
        className="animable"
      />
      <path
        d="M222.05,286.63a2.89,2.89,0,0,1,3.87.11"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "223.985px 286.34px",
        }}
        id="elapp3qtnmvcd"
        className="animable"
      />
      <path
        d="M221.07,288.87s0,0,0-.07a2.85,2.85,0,0,1,.34-1.35"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "221.24px 288.16px",
        }}
        id="el7j79ir4z3fc"
        className="animable"
      />
      <path
        d="M69.32,218.09l-7-13.85a.76.76,0,1,0-1.36.67l6.69,14A1.06,1.06,0,0,0,67.4,220l7.08,13.28c.19.37.79.45,1.36.17s.87-.8.68-1.18l-6.2-13.71A1.06,1.06,0,0,0,69.32,218.09Z"
        style={{
          fill: "rgb(46, 53, 58)",
          transformOrigin: "68.7164px 218.692px",
        }}
        id="elsu0atse0k69"
        className="animable"
      />
      <path
        d="M108.08,218.09l7-13.85a.75.75,0,0,1,1-.35.77.77,0,0,1,.35,1l-6.7,14A1,1,0,0,1,110,220l-7.08,13.28c-.19.37-.8.45-1.36.17s-.87-.8-.69-1.18l6.21-13.71A1.05,1.05,0,0,1,108.08,218.09Z"
        style={{
          fill: "rgb(46, 53, 58)",
          transformOrigin: "108.658px 218.717px",
        }}
        id="elwac9g5uneeg"
        className="animable"
      />
      <polygon
        points="110.76 233.87 66.8 233.87 70.09 228.27 107.46 228.27 110.76 233.87"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "88.78px 231.07px",
        }}
        id="elfu0d9oj3zaf"
        className="animable"
      />
      <rect
        x={66.79}
        y={233.87}
        width={43.96}
        height={5.6}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "88.77px 236.67px",
        }}
        id="elw7r5xm5xejq"
        className="animable"
      />
      <path
        d="M88,237.91H71.11a1.24,1.24,0,0,1-1.23-1.24h0a1.23,1.23,0,0,1,1.23-1.23H88a1.23,1.23,0,0,1,1.23,1.23h0A1.24,1.24,0,0,1,88,237.91Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "79.555px 236.675px",
        }}
        id="el2oo4t719ck7"
        className="animable"
      />
      <circle
        cx={98.24}
        cy={236.52}
        r={1.08}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "98.24px 236.52px",
        }}
        id="ela1iark5mda"
        className="animable"
      />
      <circle
        cx={102.71}
        cy={236.52}
        r={1.08}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "102.71px 236.52px",
        }}
        id="elvbrsekd4fq"
        className="animable"
      />
      <path
        d="M108.27,236.52a1.09,1.09,0,1,0-1.08,1.09A1.08,1.08,0,0,0,108.27,236.52Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "107.18px 236.52px",
        }}
        id="elkhi9n3t3wr8"
        className="animable"
      />
      <path
        d="M125.76,201.06a10.43,10.43,0,0,1,.57,3.41c0,.09,0,.17,0,.26"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "126.045px 202.895px",
        }}
        id="el8zve3mohobc"
        className="animable"
      />
      <path
        d="M118,194.27a10.45,10.45,0,0,1,7.37,5.76"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "121.685px 197.15px",
        }}
        id="elyozeqsisrf"
        className="animable"
      />
      <path
        d="M110.24,195.78a10.31,10.31,0,0,1,4.63-1.65"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "112.555px 194.955px",
        }}
        id="elvehq38ldw6"
        className="animable"
      />
      <path
        d="M121.73,199.39a7.69,7.69,0,0,0-10.17-1.26"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "116.645px 198.075px",
        }}
        id="el26s76u2t8a6"
        className="animable"
      />
      <path
        d="M123.63,204.66a1.15,1.15,0,0,0,0-.19,7.72,7.72,0,0,0-.86-3.55"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "123.202px 202.79px",
        }}
        id="elcelp4a25vru"
        className="animable"
      />
      <path
        d="M114.86,199.39a5.16,5.16,0,0,0-2.07,1"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "113.825px 199.89px",
        }}
        id="ela7epvjoydku"
        className="animable"
      />
      <path
        d="M121.13,204.6v-.13a5.19,5.19,0,0,0-4.07-5.07"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "119.095px 202px",
        }}
        id="el14hbzok0mlal"
        className="animable"
      />
      <path
        d="M118.19,202.7a2.85,2.85,0,0,0-4.24-.29"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "116.07px 202.15px",
        }}
        id="eljkuxgrz94bk"
        className="animable"
      />
      <path
        d="M118.8,204.54v-.07a2.82,2.82,0,0,0-.12-.83"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "118.74px 204.09px",
        }}
        id="elx804phvi48j"
        className="animable"
      />
      <path
        d="M59.8,194.23a10.4,10.4,0,0,0-8.58,10.24c0,.09,0,.17,0,.26"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "55.51px 199.48px",
        }}
        id="elusp7kczefvg"
        className="animable"
      />
      <path
        d="M67.31,195.78a10.41,10.41,0,0,0-5-1.68"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "64.81px 194.94px",
        }}
        id="el58j8lltr5a7"
        className="animable"
      />
      <path
        d="M63.32,197A7.89,7.89,0,0,1,66,198.13"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "64.66px 197.565px",
        }}
        id="elvayhj5fxzfc"
        className="animable"
      />
      <path
        d="M53.92,204.66c0-.06,0-.12,0-.19a7.71,7.71,0,0,1,7.71-7.71"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "57.775px 200.71px",
        }}
        id="elgy687jpbrdc"
        className="animable"
      />
      <path
        d="M57.61,201.17a5.17,5.17,0,0,1,7.15-.83"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "61.185px 200.221px",
        }}
        id="ellas126lwab"
        className="animable"
      />
      <path
        d="M56.42,204.6v-.13a5.07,5.07,0,0,1,.41-2"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "56.625px 203.535px",
        }}
        id="elyod2l5l5sn"
        className="animable"
      />
      <path
        d="M62.13,201.65a2.88,2.88,0,0,1,1.48.76"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "62.87px 202.03px",
        }}
        id="elfrbmhhvn77q"
        className="animable"
      />
      <path
        d="M58.76,204.54s0,0,0-.07a2.88,2.88,0,0,1,2.4-2.83"
        style={{
          fill: "none",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "59.96px 203.09px",
        }}
        id="elo9f1xl3a71p"
        className="animable"
      />
    </g>
    <g
      id="freepik--device-3--inject-183"
      className="animable animator-active"
      style={{
        transformOrigin: "252.19px 374.48px",
      }}
    >
      <rect
        x={208.95}
        y={329.64}
        width={89.11}
        height={61.05}
        style={{
          fill: "rgb(46, 53, 58)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "253.505px 360.165px",
        }}
        id="el7hlb93vj7x7"
        className="animable"
      />
      <polygon
        points="311.43 417.68 195.59 417.68 208.95 390.69 298.06 390.69 311.43 417.68"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "253.51px 404.185px",
        }}
        id="el97mp4eztyk"
        className="animable"
      />
      <rect
        x={214.35}
        y={334.47}
        width={78.31}
        height={51.39}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "253.505px 360.165px",
        }}
        id="el7k93lz43pxa"
        className="animable"
      />
      <polygon
        points="265.24 355.23 253.53 341.38 241.82 355.23 246.72 355.23 246.72 376.92 260.34 376.92 260.34 355.23 265.24 355.23"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "253.53px 359.15px",
        }}
        id="el9avfjao00n"
        className="animable"
      />
      <polygon
        points="249.79 399.24 249.94 395.95 245.24 395.95 244.92 399.24 249.79 399.24"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "247.43px 397.595px",
        }}
        id="eln6q7idlhqea"
        className="animable"
      />
      <polygon
        points="259.52 399.24 259.36 395.95 254.65 395.95 254.66 399.24 259.52 399.24"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "257.085px 397.595px",
        }}
        id="elblqiyiep1x"
        className="animable"
      />
      <polygon
        points="284.86 402.75 289.9 402.75 288.71 399.24 283.85 399.24 284.86 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "286.875px 400.995px",
        }}
        id="elozv3m1wwoai"
        className="animable"
      />
      <polygon
        points="244.92 399.24 245.24 395.95 240.53 395.95 240.06 399.24 244.92 399.24"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "242.65px 397.595px",
        }}
        id="el1joo6gj5kto"
        className="animable"
      />
      <polygon
        points="235.19 399.24 235.82 395.95 231.11 395.95 230.33 399.24 235.19 399.24"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "233.075px 397.595px",
        }}
        id="elmj7gzi4rpsf"
        className="animable"
      />
      <polygon
        points="278.98 399.24 278.19 395.95 273.48 395.95 274.12 399.24 278.98 399.24"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "276.23px 397.595px",
        }}
        id="elv16p455rua"
        className="animable"
      />
      <polygon
        points="283.85 399.24 282.9 395.95 278.19 395.95 278.98 399.24 283.85 399.24"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "281.02px 397.595px",
        }}
        id="elsnh4a33xh5k"
        className="animable"
      />
      <polygon
        points="230.33 399.24 231.11 395.95 226.4 395.95 225.46 399.24 230.33 399.24"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "228.285px 397.595px",
        }}
        id="el6xvk2xt69g7"
        className="animable"
      />
      <polygon
        points="264.39 399.24 264.07 395.95 259.36 395.95 259.52 399.24 264.39 399.24"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "261.875px 397.595px",
        }}
        id="elchlc3xczmbl"
        className="animable"
      />
      <polygon
        points="288.71 399.24 287.61 395.95 282.9 395.95 283.85 399.24 288.71 399.24"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "285.805px 397.595px",
        }}
        id="elwmwaojg634p"
        className="animable"
      />
      <polygon
        points="274.12 399.24 273.48 395.95 268.78 395.95 269.25 399.24 274.12 399.24"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "271.45px 397.595px",
        }}
        id="el23hbvqx49zy"
        className="animable"
      />
      <polygon
        points="269.25 399.24 268.78 395.95 264.07 395.95 264.39 399.24 269.25 399.24"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "266.66px 397.595px",
        }}
        id="el4cxil17lbad"
        className="animable"
      />
      <polygon
        points="254.66 399.24 254.65 395.95 249.94 395.95 249.79 399.24 254.66 399.24"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "252.225px 397.595px",
        }}
        id="el6n29w7om9zp"
        className="animable"
      />
      <polygon
        points="220.6 399.24 215.73 399.24 214.39 402.75 219.42 402.75 220.6 399.24"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "217.495px 400.995px",
        }}
        id="elimi88n82ozd"
        className="animable"
      />
      <polygon
        points="221.69 395.95 216.99 395.95 215.73 399.24 220.6 399.24 221.69 395.95"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "218.71px 397.595px",
        }}
        id="el51ja9gapz0o"
        className="animable"
      />
      <polygon
        points="225.46 399.24 226.4 395.95 221.69 395.95 220.6 399.24 225.46 399.24"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "223.5px 397.595px",
        }}
        id="elfj25cmyvvoo"
        className="animable"
      />
      <polygon
        points="212.95 406.52 211.4 410.57 216.81 410.57 218.16 406.52 212.95 406.52"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "214.78px 408.545px",
        }}
        id="elbm2pqj6iupn"
        className="animable"
      />
      <polygon
        points="219.42 402.75 214.39 402.75 212.95 406.52 218.16 406.52 219.42 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "216.185px 404.635px",
        }}
        id="el97i92kbxoxo"
        className="animable"
      />
      <polygon
        points="218.16 406.52 216.81 410.57 222.22 410.57 223.38 406.52 218.16 406.52"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "220.095px 408.545px",
        }}
        id="el1drpdbsove1"
        className="animable"
      />
      <polygon
        points="240.06 399.24 240.53 395.95 235.82 395.95 235.19 399.24 240.06 399.24"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "237.86px 397.595px",
        }}
        id="el44bzimayeu9"
        className="animable"
      />
      <polygon
        points="239.56 402.75 240.06 399.24 235.19 399.24 234.52 402.75 239.56 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "237.29px 400.995px",
        }}
        id="elco55thi9wsh"
        className="animable"
      />
      <polygon
        points="234.52 402.75 235.19 399.24 230.33 399.24 229.49 402.75 234.52 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "232.34px 400.995px",
        }}
        id="elbp5ftohvucj"
        className="animable"
      />
      <polygon
        points="244.59 402.75 244.92 399.24 240.06 399.24 239.56 402.75 244.59 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "242.24px 400.995px",
        }}
        id="elsstap0ggn0s"
        className="animable"
      />
      <polygon
        points="225.46 399.24 220.6 399.24 219.42 402.75 224.46 402.75 225.46 399.24"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "222.44px 400.995px",
        }}
        id="elo99lpvwh9j"
        className="animable"
      />
      <polygon
        points="285.95 406.52 291.16 406.52 289.9 402.75 284.86 402.75 285.95 406.52"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "288.01px 404.635px",
        }}
        id="elwpik442i3d"
        className="animable"
      />
      <polygon
        points="229.49 402.75 230.33 399.24 225.46 399.24 224.46 402.75 229.49 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "227.395px 400.995px",
        }}
        id="ell5t5x9jofy"
        className="animable"
      />
      <polygon
        points="219.42 402.75 218.16 406.52 223.38 406.52 224.46 402.75 219.42 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "221.31px 404.635px",
        }}
        id="ell7xdu2vm5cf"
        className="animable"
      />
      <polygon
        points="274.79 402.75 274.12 399.24 269.25 399.24 269.76 402.75 274.79 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "272.02px 400.995px",
        }}
        id="elnkzpbqgwjv"
        className="animable"
      />
      <polygon
        points="269.76 402.75 269.25 399.24 264.39 399.24 264.73 402.75 269.76 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "267.075px 400.995px",
        }}
        id="ell2r57yebdzb"
        className="animable"
      />
      <polygon
        points="284.86 402.75 283.85 399.24 278.98 399.24 279.83 402.75 284.86 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "281.92px 400.995px",
        }}
        id="elcuc7wa4hpmh"
        className="animable"
      />
      <polygon
        points="254.66 402.75 254.66 399.24 249.79 399.24 249.62 402.75 254.66 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "252.14px 400.995px",
        }}
        id="elxqlluar8o9r"
        className="animable"
      />
      <polygon
        points="264.73 402.75 264.39 399.24 259.52 399.24 259.69 402.75 264.73 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "262.125px 400.995px",
        }}
        id="el0mhutk868wh"
        className="animable"
      />
      <polygon
        points="259.69 402.75 259.52 399.24 254.66 399.24 254.66 402.75 259.69 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "257.175px 400.995px",
        }}
        id="elt43uh51m4s"
        className="animable"
      />
      <polygon
        points="249.62 402.75 249.79 399.24 244.92 399.24 244.59 402.75 249.62 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "247.19px 400.995px",
        }}
        id="elzifrdeu759s"
        className="animable"
      />
      <polygon
        points="264.73 402.75 265.09 406.52 270.31 406.52 269.76 402.75 264.73 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "267.52px 404.635px",
        }}
        id="eliljpfubbxop"
        className="animable"
      />
      <polygon
        points="269.76 402.75 270.31 406.52 275.52 406.52 274.79 402.75 269.76 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "272.64px 404.635px",
        }}
        id="el3jbqe4iw0ja"
        className="animable"
      />
      <polygon
        points="224.46 402.75 223.38 406.52 228.59 406.52 229.49 402.75 224.46 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "226.435px 404.635px",
        }}
        id="el2sw5l8elmvf"
        className="animable"
      />
      <polygon
        points="274.79 402.75 275.52 406.52 280.73 406.52 279.83 402.75 274.79 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "277.76px 404.635px",
        }}
        id="el7gmpbc5bufq"
        className="animable"
      />
      <polygon
        points="254.66 402.75 254.66 406.52 259.88 406.52 259.69 402.75 254.66 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "257.27px 404.635px",
        }}
        id="el15pdsq9goxx"
        className="animable"
      />
      <polygon
        points="279.83 402.75 280.73 406.52 285.95 406.52 284.86 402.75 279.83 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "282.89px 404.635px",
        }}
        id="elxe9ojx7k6a"
        className="animable"
      />
      <polygon
        points="259.69 402.75 259.88 406.52 265.09 406.52 264.73 402.75 259.69 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "262.39px 404.635px",
        }}
        id="el9shfrfpg3ne"
        className="animable"
      />
      <polygon
        points="234.52 402.75 233.8 406.52 239.02 406.52 239.56 402.75 234.52 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "236.68px 404.635px",
        }}
        id="elwxfamdg8d5i"
        className="animable"
      />
      <polygon
        points="229.49 402.75 228.59 406.52 233.8 406.52 234.52 402.75 229.49 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "231.555px 404.635px",
        }}
        id="elty8olbis8h"
        className="animable"
      />
      <polygon
        points="249.62 402.75 249.45 406.52 254.66 406.52 254.66 402.75 249.62 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "252.055px 404.635px",
        }}
        id="el1jtgmsupwnl"
        className="animable"
      />
      <polygon
        points="244.59 402.75 244.23 406.52 249.45 406.52 249.62 402.75 244.59 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "246.925px 404.635px",
        }}
        id="el2yrxfoqnahj"
        className="animable"
      />
      <polygon
        points="239.56 402.75 239.02 406.52 244.23 406.52 244.59 402.75 239.56 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "241.805px 404.635px",
        }}
        id="el495f9jch7jd"
        className="animable"
      />
      <polygon
        points="279.83 402.75 278.98 399.24 274.12 399.24 274.79 402.75 279.83 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "276.975px 400.995px",
        }}
        id="el35g7nmrbzew"
        className="animable"
      />
      <polygon
        points="221.69 395.95 222.82 392.58 218.27 392.58 216.99 395.95 221.69 395.95"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "219.905px 394.265px",
        }}
        id="el5v17hzxo50s"
        className="animable"
      />
      <polygon
        points="216.99 395.95 212.64 395.95 211.24 399.24 215.73 399.24 216.99 395.95"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "214.115px 397.595px",
        }}
        id="elpag1qf3r4c9"
        className="animable"
      />
      <polygon
        points="218.27 392.58 214.08 392.58 212.64 395.95 216.99 395.95 218.27 392.58"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "215.455px 394.265px",
        }}
        id="elxb8wgsiynbh"
        className="animable"
      />
      <polygon
        points="215.73 399.24 211.24 399.24 209.74 402.75 214.39 402.75 215.73 399.24"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "212.735px 400.995px",
        }}
        id="ela4slg53ejw9"
        className="animable"
      />
      <polygon
        points="214.39 402.75 209.74 402.75 208.13 406.52 212.95 406.52 214.39 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "211.26px 404.635px",
        }}
        id="elazzhene5kkc"
        className="animable"
      />
      <polygon
        points="212.95 406.52 208.13 406.52 206.41 410.57 211.4 410.57 212.95 406.52"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "209.68px 408.545px",
        }}
        id="el01mv70ykcxm6"
        className="animable"
      />
      <polygon
        points="293.58 399.24 294.93 402.75 299.38 402.75 297.88 399.24 293.58 399.24"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "296.48px 400.995px",
        }}
        id="elhce1j390gcd"
        className="animable"
      />
      <polygon
        points="296.38 406.52 300.99 406.52 299.38 402.75 294.93 402.75 296.38 406.52"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "297.96px 404.635px",
        }}
        id="elh48tdupjcxl"
        className="animable"
      />
      <polygon
        points="297.93 410.57 302.71 410.57 300.99 406.52 296.38 406.52 297.93 410.57"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "299.545px 408.545px",
        }}
        id="elp1uselky3ti"
        className="animable"
      />
      <polygon
        points="259.36 395.95 259.2 392.58 254.65 392.58 254.65 395.95 259.36 395.95"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "257.005px 394.265px",
        }}
        id="elgj7adf54w8w"
        className="animable"
      />
      <polygon
        points="293.58 399.24 297.88 399.24 296.48 395.95 292.32 395.95 293.58 399.24"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "295.1px 397.595px",
        }}
        id="eln2p0q7w2xv"
        className="animable"
      />
      <polygon
        points="291.16 406.52 296.38 406.52 294.93 402.75 289.9 402.75 291.16 406.52"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "293.14px 404.635px",
        }}
        id="el05yzw4w1kqm7"
        className="animable"
      />
      <polygon
        points="226.4 395.95 227.37 392.58 222.82 392.58 221.69 395.95 226.4 395.95"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "224.53px 394.265px",
        }}
        id="el6gp8nhws8zo"
        className="animable"
      />
      <polygon
        points="287.61 395.95 288.71 399.24 293.58 399.24 292.32 395.95 287.61 395.95"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "290.595px 397.595px",
        }}
        id="elr0a2e6baifg"
        className="animable"
      />
      <polygon
        points="296.48 395.95 295.05 392.58 291.02 392.58 292.32 395.95 296.48 395.95"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "293.75px 394.265px",
        }}
        id="elovxmonuwy4i"
        className="animable"
      />
      <polygon
        points="292.32 395.95 291.02 392.58 286.48 392.58 287.61 395.95 292.32 395.95"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "289.4px 394.265px",
        }}
        id="elkngkihqqai"
        className="animable"
      />
      <polygon
        points="275.52 406.52 276.3 410.57 281.71 410.57 280.73 406.52 275.52 406.52"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "278.615px 408.545px",
        }}
        id="elsmoicvzzyrs"
        className="animable"
      />
      <polygon
        points="292.52 410.57 297.93 410.57 296.38 406.52 291.16 406.52 292.52 410.57"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "294.545px 408.545px",
        }}
        id="el26qpytz4z3ei"
        className="animable"
      />
      <polygon
        points="287.61 395.95 286.48 392.58 281.93 392.58 282.9 395.95 287.61 395.95"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "284.77px 394.265px",
        }}
        id="elvubg8hidtud"
        className="animable"
      />
      <polygon
        points="285.95 406.52 287.11 410.57 292.52 410.57 291.16 406.52 285.95 406.52"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "289.235px 408.545px",
        }}
        id="el48mqp2q8h8r"
        className="animable"
      />
      <polygon
        points="280.73 406.52 281.71 410.57 287.11 410.57 285.95 406.52 280.73 406.52"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "283.92px 408.545px",
        }}
        id="elfmhuj4dnhjg"
        className="animable"
      />
      <polygon
        points="289.9 402.75 294.93 402.75 293.58 399.24 288.71 399.24 289.9 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "291.82px 400.995px",
        }}
        id="elnqpyc8t5t1"
        className="animable"
      />
      <polygon
        points="245.24 395.95 245.56 392.58 241.01 392.58 240.53 395.95 245.24 395.95"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "243.045px 394.265px",
        }}
        id="elnggv9ca9qu"
        className="animable"
      />
      <polygon
        points="282.9 395.95 281.93 392.58 277.38 392.58 278.19 395.95 282.9 395.95"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "280.14px 394.265px",
        }}
        id="elph76n57wed9"
        className="animable"
      />
      <polygon
        points="240.53 395.95 241.01 392.58 236.46 392.58 235.82 395.95 240.53 395.95"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "238.415px 394.265px",
        }}
        id="elu34b6jdhmmd"
        className="animable"
      />
      <polygon
        points="254.65 395.95 254.65 392.58 250.1 392.58 249.94 395.95 254.65 395.95"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "252.295px 394.265px",
        }}
        id="el72vrmsu9ph"
        className="animable"
      />
      <polygon
        points="235.82 395.95 236.46 392.58 231.91 392.58 231.11 395.95 235.82 395.95"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "233.785px 394.265px",
        }}
        id="eljdiz2xg5u5d"
        className="animable"
      />
      <polygon
        points="231.11 395.95 231.91 392.58 227.37 392.58 226.4 395.95 231.11 395.95"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "229.155px 394.265px",
        }}
        id="elpavfgegyw9"
        className="animable"
      />
      <polygon
        points="249.94 395.95 250.1 392.58 245.56 392.58 245.24 395.95 249.94 395.95"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "247.67px 394.265px",
        }}
        id="el9lolsl72an"
        className="animable"
      />
      <polygon
        points="278.19 395.95 277.38 392.58 272.84 392.58 273.48 395.95 278.19 395.95"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "275.515px 394.265px",
        }}
        id="el027s8e5jybir"
        className="animable"
      />
      <polygon
        points="273.48 395.95 272.84 392.58 268.29 392.58 268.78 395.95 273.48 395.95"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "270.885px 394.265px",
        }}
        id="eltprctehuosf"
        className="animable"
      />
      <polygon
        points="264.07 395.95 263.74 392.58 259.2 392.58 259.36 395.95 264.07 395.95"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "261.635px 394.265px",
        }}
        id="eleszhtr1922p"
        className="animable"
      />
      <polygon
        points="275.52 406.52 270.31 406.52 265.09 406.52 259.88 406.52 254.66 406.52 249.45 406.52 244.23 406.52 239.02 406.52 233.8 406.52 228.59 406.52 223.38 406.52 222.22 410.57 227.63 410.57 233.03 410.57 238.44 410.57 243.85 410.57 249.26 410.57 254.66 410.57 260.07 410.57 265.48 410.57 270.89 410.57 276.3 410.57 275.52 406.52"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "249.26px 408.545px",
        }}
        id="el8cwbqcvtanf"
        className="animable"
      />
      <polygon
        points="268.78 395.95 268.29 392.58 263.74 392.58 264.07 395.95 268.78 395.95"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "266.26px 394.265px",
        }}
        id="el5alnwg95fxt"
        className="animable"
      />
      <rect
        x={195.59}
        y={417.68}
        width={115.89}
        height={1.64}
        style={{
          fill: "rgb(46, 53, 58)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "253.535px 418.5px",
        }}
        id="elzgediy8b0ck"
        className="animable"
      />
      <path
        d="M330.85,329.64h68a0,0,0,0,1,0,0v61.05a0,0,0,0,1,0,0h-68a1.62,1.62,0,0,1-1.62-1.62v-57.8A1.62,1.62,0,0,1,330.85,329.64Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "364.04px 360.165px",
        }}
        id="elshjmt34dcvl"
        className="animable"
      />
      <rect
        x={331.06}
        y={329.64}
        width={70.13}
        height={61.05}
        style={{
          fill: "rgb(46, 53, 58)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          transformOrigin: "366.125px 360.165px",
        }}
        id="el2mtzconvafc"
        className="animable"
      />
      <rect
        x={336.46}
        y={334.47}
        width={60.9}
        height={51.39}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "366.91px 360.165px",
        }}
        id="eld35fsrhu0c"
        className="animable"
      />
      <polygon
        points="357.26 355.23 366.34 341.38 375.42 355.23 371.62 355.23 371.62 376.92 361.06 376.92 361.06 355.23 357.26 355.23"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "366.34px 359.15px",
        }}
        id="els0nr3d0hm6"
        className="animable"
      />
      <polygon
        points="331.06 390.69 384.22 406.06 455.89 406.06 401.19 390.69 331.06 390.69"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "393.475px 398.375px",
        }}
        id="elqlbl6b3fq98"
        className="animable"
      />
      <polygon
        points="387.83 396.77 381.18 394.88 378.27 394.85 384.9 396.75 387.83 396.77"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "383.05px 395.81px",
        }}
        id="eltdnocau6tbl"
        className="animable"
      />
      <polygon
        points="393.62 396.82 386.95 394.94 384.08 394.91 390.73 396.8 393.62 396.82"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "388.85px 395.865px",
        }}
        id="el26tyb2zy7dij"
        className="animable"
      />
      <polygon
        points="414.64 398.81 417.42 398.83 410.51 396.94 407.74 396.92 414.64 398.81"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "412.58px 397.875px",
        }}
        id="elz9mlvpg2kll"
        className="animable"
      />
      <polygon
        points="384.9 396.75 378.27 394.85 375.34 394.82 381.95 396.73 384.9 396.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "380.12px 395.785px",
        }}
        id="el4vy1r4evetu"
        className="animable"
      />
      <polygon
        points="378.99 396.71 372.38 394.8 369.41 394.77 376 396.69 378.99 396.71"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "374.2px 395.74px",
        }}
        id="elp2apzwjfui"
        className="animable"
      />
      <polygon
        points="404.96 396.9 398.24 395.04 395.45 395.02 402.15 396.88 404.96 396.9"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "400.205px 395.96px",
        }}
        id="elyvdfp0xl0ne"
        className="animable"
      />
      <polygon
        points="407.74 396.92 401.01 395.07 398.24 395.04 404.96 396.9 407.74 396.92"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "402.99px 395.98px",
        }}
        id="elz6fsqmgo7l8"
        className="animable"
      />
      <polygon
        points="376 396.69 369.41 394.77 366.41 394.74 372.98 396.67 376 396.69"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "371.205px 395.715px",
        }}
        id="el11qts4vtp51r"
        className="animable"
      />
      <polygon
        points="396.48 396.84 389.8 394.96 386.95 394.94 393.62 396.82 396.48 396.84"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "391.715px 395.89px",
        }}
        id="elxecfik0v6vh"
        className="animable"
      />
      <polygon
        points="410.51 396.94 403.77 395.1 401.01 395.07 407.74 396.92 410.51 396.94"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "405.76px 396.005px",
        }}
        id="elwesbqgvtxx"
        className="animable"
      />
      <polygon
        points="402.15 396.88 395.45 395.02 392.63 394.99 399.33 396.86 402.15 396.88"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "397.39px 395.935px",
        }}
        id="eleu2njpto3g"
        className="animable"
      />
      <polygon
        points="399.33 396.86 392.63 394.99 389.8 394.96 396.48 396.84 399.33 396.86"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "394.565px 395.91px",
        }}
        id="elglxlt71as59"
        className="animable"
      />
      <polygon
        points="390.73 396.8 384.08 394.91 381.18 394.88 387.83 396.77 390.73 396.8"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "385.955px 395.84px",
        }}
        id="el6x2xbpnj109"
        className="animable"
      />
      <polygon
        points="369.95 396.64 366.89 396.62 373.61 398.61 376.68 398.63 369.95 396.64"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "371.785px 397.625px",
        }}
        id="elicxuo3v6u1r"
        className="animable"
      />
      <polygon
        points="363.39 394.71 360.35 394.68 366.89 396.62 369.95 396.64 363.39 394.71"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "365.15px 395.66px",
        }}
        id="ely6gkndmx47c"
        className="animable"
      />
      <polygon
        points="372.98 396.67 366.41 394.74 363.39 394.71 369.95 396.64 372.98 396.67"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "368.185px 395.69px",
        }}
        id="el6putegakoc"
        className="animable"
      />
      <polygon
        points="380.5 400.65 387.59 402.75 390.69 402.75 383.59 400.66 380.5 400.65"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "385.595px 401.7px",
        }}
        id="el2um6r50wkbs"
        className="animable"
      />
      <polygon
        points="376.68 398.63 373.61 398.61 380.5 400.65 383.59 400.66 376.68 398.63"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "378.6px 399.635px",
        }}
        id="elbbnid0phu5g"
        className="animable"
      />
      <polygon
        points="383.59 400.66 390.69 402.75 393.77 402.75 386.65 400.67 383.59 400.66"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "388.68px 401.705px",
        }}
        id="elz10mtacjud"
        className="animable"
      />
      <polygon
        points="381.95 396.73 375.34 394.82 372.38 394.8 378.99 396.71 381.95 396.73"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "377.165px 395.765px",
        }}
        id="eldzwiizm3kmr"
        className="animable"
      />
      <polygon
        points="388.74 398.69 381.95 396.73 378.99 396.71 385.76 398.67 388.74 398.69"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "383.865px 397.7px",
        }}
        id="el72izky63awp"
        className="animable"
      />
      <polygon
        points="385.76 398.67 378.99 396.71 376 396.69 382.76 398.66 385.76 398.67"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "380.88px 397.68px",
        }}
        id="el8fdjxqajnyq"
        className="animable"
      />
      <polygon
        points="391.7 398.7 384.9 396.75 381.95 396.73 388.74 398.69 391.7 398.7"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "386.825px 397.715px",
        }}
        id="elqkpv05999x"
        className="animable"
      />
      <polygon
        points="372.98 396.67 369.95 396.64 376.68 398.63 379.73 398.64 372.98 396.67"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "374.84px 397.64px",
        }}
        id="eleg97ukx0z2e"
        className="animable"
      />
      <polygon
        points="421.72 400.76 424.5 400.77 417.42 398.83 414.64 398.81 421.72 400.76"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "419.57px 399.79px",
        }}
        id="el02xt2etp2g94"
        className="animable"
      />
      <polygon
        points="382.76 398.66 376 396.69 372.98 396.67 379.73 398.64 382.76 398.66"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "377.87px 397.665px",
        }}
        id="el13tga27nb02c"
        className="animable"
      />
      <polygon
        points="376.68 398.63 383.59 400.66 386.65 400.67 379.73 398.64 376.68 398.63"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "381.665px 399.65px",
        }}
        id="elj3mhf5d50x"
        className="animable"
      />
      <polygon
        points="409.03 398.79 402.15 396.88 399.33 396.86 406.19 398.77 409.03 398.79"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "404.18px 397.825px",
        }}
        id="elh18z75inojh"
        className="animable"
      />
      <polygon
        points="406.19 398.77 399.33 396.86 396.48 396.84 403.34 398.76 406.19 398.77"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "401.335px 397.805px",
        }}
        id="eluomxb2slg6h"
        className="animable"
      />
      <polygon
        points="414.64 398.81 407.74 396.92 404.96 396.9 411.85 398.8 414.64 398.81"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "409.8px 397.855px",
        }}
        id="elc4l2re54xia"
        className="animable"
      />
      <polygon
        points="397.56 398.73 390.73 396.8 387.83 396.77 394.64 398.71 397.56 398.73"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "392.695px 397.75px",
        }}
        id="el8gt8afsrn"
        className="animable"
      />
      <polygon
        points="403.34 398.76 396.48 396.84 393.62 396.82 400.46 398.74 403.34 398.76"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "398.48px 397.79px",
        }}
        id="el69lutxm2pze"
        className="animable"
      />
      <polygon
        points="400.46 398.74 393.62 396.82 390.73 396.8 397.56 398.73 400.46 398.74"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "395.595px 397.77px",
        }}
        id="el41ohr686zl8"
        className="animable"
      />
      <polygon
        points="394.64 398.71 387.83 396.77 384.9 396.75 391.7 398.7 394.64 398.71"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "389.77px 397.73px",
        }}
        id="el28v3g80jwi4"
        className="animable"
      />
      <polygon
        points="403.34 398.76 410.37 400.73 413.24 400.74 406.19 398.77 403.34 398.76"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "408.29px 399.75px",
        }}
        id="elxglostphcv"
        className="animable"
      />
      <polygon
        points="406.19 398.77 413.24 400.74 416.08 400.75 409.03 398.79 406.19 398.77"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "411.135px 399.76px",
        }}
        id="el4nkw3xop6wq"
        className="animable"
      />
      <polygon
        points="379.73 398.64 386.65 400.67 389.7 400.68 382.76 398.66 379.73 398.64"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "384.715px 399.66px",
        }}
        id="elbg8ophdyhqj"
        className="animable"
      />
      <polygon
        points="409.03 398.79 416.08 400.75 418.91 400.75 411.85 398.8 409.03 398.79"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "413.97px 399.77px",
        }}
        id="eliktgmdsyglb"
        className="animable"
      />
      <polygon
        points="397.56 398.73 404.57 400.71 407.48 400.72 400.46 398.74 397.56 398.73"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "402.52px 399.725px",
        }}
        id="elh2ihsbew538"
        className="animable"
      />
      <polygon
        points="411.85 398.8 418.91 400.75 421.72 400.76 414.64 398.81 411.85 398.8"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "416.785px 399.78px",
        }}
        id="ela27q7eccphn"
        className="animable"
      />
      <polygon
        points="400.46 398.74 407.48 400.72 410.37 400.73 403.34 398.76 400.46 398.74"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "405.415px 399.735px",
        }}
        id="el8t7oohiaps3"
        className="animable"
      />
      <polygon
        points="385.76 398.67 392.71 400.69 395.71 400.69 388.74 398.69 385.76 398.67"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "390.735px 399.68px",
        }}
        id="elo18ib0l4z6p"
        className="animable"
      />
      <polygon
        points="382.76 398.66 389.7 400.68 392.71 400.69 385.76 398.67 382.76 398.66"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "387.735px 399.675px",
        }}
        id="elkuc26pj953p"
        className="animable"
      />
      <polygon
        points="394.64 398.71 401.64 400.71 404.57 400.71 397.56 398.73 394.64 398.71"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "399.605px 399.71px",
        }}
        id="elwqacwjqo3xh"
        className="animable"
      />
      <polygon
        points="391.7 398.7 398.69 400.7 401.64 400.71 394.64 398.71 391.7 398.7"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "396.67px 399.705px",
        }}
        id="el6q6q9yk397b"
        className="animable"
      />
      <polygon
        points="388.74 398.69 395.71 400.69 398.69 400.7 391.7 398.7 388.74 398.69"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "393.715px 399.695px",
        }}
        id="el1t6pfz07lkc"
        className="animable"
      />
      <polygon
        points="411.85 398.8 404.96 396.9 402.15 396.88 409.03 398.79 411.85 398.8"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "407px 397.84px",
        }}
        id="el8n2gbjqx4a"
        className="animable"
      />
      <polygon
        points="363.39 394.71 356.4 392.65 353.38 392.62 360.35 394.68 363.39 394.71"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "358.385px 393.665px",
        }}
        id="elvtamvjkh1qh"
        className="animable"
      />
      <polygon
        points="360.35 394.68 357.52 394.65 364.05 396.6 366.89 396.62 360.35 394.68"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "362.205px 395.635px",
        }}
        id="elyo8i7qrzol"
        className="animable"
      />
      <polygon
        points="353.38 392.62 350.57 392.58 357.52 394.65 360.35 394.68 353.38 392.62"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "355.46px 393.63px",
        }}
        id="el06r3acoc9b5g"
        className="animable"
      />
      <polygon
        points="366.89 396.62 364.05 396.6 370.75 398.6 373.61 398.61 366.89 396.62"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "368.83px 397.605px",
        }}
        id="elxx3f44l9b19"
        className="animable"
      />
      <polygon
        points="373.61 398.61 370.75 398.6 377.63 400.65 380.5 400.65 373.61 398.61"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "375.625px 399.625px",
        }}
        id="elsoz9kzcuqzd"
        className="animable"
      />
      <polygon
        points="380.5 400.65 377.63 400.65 384.7 402.75 387.59 402.75 380.5 400.65"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "382.61px 401.7px",
        }}
        id="ela9l1q1a79cn"
        className="animable"
      />
      <polygon
        points="413.26 396.96 420.18 398.84 422.6 398.86 415.67 396.98 413.26 396.96"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "417.93px 397.91px",
        }}
        id="elnp5vj0wxxse"
        className="animable"
      />
      <polygon
        points="427.27 400.77 429.7 400.78 422.6 398.86 420.18 398.84 427.27 400.77"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "424.94px 399.81px",
        }}
        id="elf316h3dw7d"
        className="animable"
      />
      <polygon
        points="434.55 402.75 436.98 402.75 429.7 400.78 427.27 400.77 434.55 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "432.125px 401.76px",
        }}
        id="elrnvbhwg4nvn"
        className="animable"
      />
      <polygon
        points="386.95 394.94 379.83 392.93 376.97 392.9 384.08 394.91 386.95 394.94"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "381.96px 393.92px",
        }}
        id="elmom5vqt8ppl"
        className="animable"
      />
      <polygon
        points="413.26 396.96 415.67 396.98 408.91 395.15 406.5 395.12 413.26 396.96"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "411.085px 396.05px",
        }}
        id="elhqa40jkelam"
        className="animable"
      />
      <polygon
        points="424.5 400.77 427.27 400.77 420.18 398.84 417.42 398.83 424.5 400.77"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "422.345px 399.8px",
        }}
        id="elff0pttcztf7"
        className="animable"
      />
      <polygon
        points="366.41 394.74 359.4 392.69 356.4 392.65 363.39 394.71 366.41 394.74"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "361.405px 393.695px",
        }}
        id="elcf63k1clch"
        className="animable"
      />
      <polygon
        points="403.77 395.1 410.51 396.94 413.26 396.96 406.5 395.12 403.77 395.1"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "408.515px 396.03px",
        }}
        id="eldg1a1iq6l1m"
        className="animable"
      />
      <polygon
        points="408.91 395.15 401.69 393.19 399.3 393.16 406.5 395.12 408.91 395.15"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "404.105px 394.155px",
        }}
        id="elu358ffhv6k"
        className="animable"
      />
      <polygon
        points="406.5 395.12 399.3 393.16 396.57 393.13 403.77 395.1 406.5 395.12"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "401.535px 394.125px",
        }}
        id="elukl50vph21h"
        className="animable"
      />
      <polygon
        points="416.08 400.75 423.32 402.75 426.16 402.75 418.91 400.75 416.08 400.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "421.12px 401.75px",
        }}
        id="eli5okm7ladbn"
        className="animable"
      />
      <polygon
        points="431.77 402.75 434.55 402.75 427.27 400.77 424.5 400.77 431.77 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "429.525px 401.76px",
        }}
        id="elmylenigvww"
        className="animable"
      />
      <polygon
        points="403.77 395.1 396.57 393.13 393.83 393.1 401.01 395.07 403.77 395.1"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "398.8px 394.1px",
        }}
        id="eli59mmku58hg"
        className="animable"
      />
      <polygon
        points="421.72 400.76 428.97 402.75 431.77 402.75 424.5 400.77 421.72 400.76"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "426.745px 401.755px",
        }}
        id="elkw3qiikmomq"
        className="animable"
      />
      <polygon
        points="418.91 400.75 426.16 402.75 428.97 402.75 421.72 400.76 418.91 400.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "423.94px 401.75px",
        }}
        id="elv9bz38iebfb"
        className="animable"
      />
      <polygon
        points="417.42 398.83 420.18 398.84 413.26 396.96 410.51 396.94 417.42 398.83"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "415.345px 397.89px",
        }}
        id="el5p3zw5xgdz3"
        className="animable"
      />
      <polygon
        points="378.27 394.85 371.2 392.83 368.28 392.79 375.34 394.82 378.27 394.85"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "373.275px 393.82px",
        }}
        id="eliupqs9s5w8t"
        className="animable"
      />
      <polygon
        points="401.01 395.07 393.83 393.1 391.07 393.06 398.24 395.04 401.01 395.07"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "396.04px 394.065px",
        }}
        id="el8hldeqvvao"
        className="animable"
      />
      <polygon
        points="375.34 394.82 368.28 392.79 365.34 392.76 372.38 394.8 375.34 394.82"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "370.34px 393.79px",
        }}
        id="eliz6shevi74k"
        className="animable"
      />
      <polygon
        points="384.08 394.91 376.97 392.9 374.1 392.86 381.18 394.88 384.08 394.91"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "379.09px 393.885px",
        }}
        id="el8n4jvziinep"
        className="animable"
      />
      <polygon
        points="372.38 394.8 365.34 392.76 362.38 392.72 369.41 394.77 372.38 394.8"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "367.38px 393.76px",
        }}
        id="eldd9vvd8h3jt"
        className="animable"
      />
      <polygon
        points="369.41 394.77 362.38 392.72 359.4 392.69 366.41 394.74 369.41 394.77"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "364.405px 393.73px",
        }}
        id="elf8wtkjqx5wv"
        className="animable"
      />
      <polygon
        points="381.18 394.88 374.1 392.86 371.2 392.83 378.27 394.85 381.18 394.88"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "376.19px 393.855px",
        }}
        id="elapditaz08bh"
        className="animable"
      />
      <polygon
        points="398.24 395.04 391.07 393.06 388.29 393.03 395.45 395.02 398.24 395.04"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "393.265px 394.035px",
        }}
        id="els1r661c0pi"
        className="animable"
      />
      <polygon
        points="395.45 395.02 388.29 393.03 385.49 393 392.63 394.99 395.45 395.02"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "390.47px 394.01px",
        }}
        id="ela28fnc4884p"
        className="animable"
      />
      <polygon
        points="389.8 394.96 382.67 392.96 379.83 392.93 386.95 394.94 389.8 394.96"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "384.815px 393.945px",
        }}
        id="el314jtvlsdyo"
        className="animable"
      />
      <polygon
        points="416.08 400.75 413.24 400.74 410.37 400.73 407.48 400.72 404.57 400.71 401.64 400.71 398.69 400.7 395.71 400.69 392.71 400.69 389.7 400.68 386.65 400.67 393.77 402.75 396.82 402.75 399.86 402.75 402.87 402.75 405.85 402.75 408.82 402.75 411.76 402.75 414.68 402.75 417.58 402.75 420.46 402.75 423.32 402.75 416.08 400.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "404.985px 401.71px",
        }}
        id="elv2w487pg3hi"
        className="animable"
      />
      <polygon
        points="392.63 394.99 385.49 393 382.67 392.96 389.8 394.96 392.63 394.99"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "387.65px 393.975px",
        }}
        id="elkaej4cyyj5n"
        className="animable"
      />
      <polygon
        points="331.06 390.69 331.06 392.73 384.2 408.68 384.22 406.06 331.06 390.69"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "357.64px 399.685px",
        }}
        id="el05zet0sr6wr2"
        className="animable"
      />
      <rect
        x={384.22}
        y={406.06}
        width={71.67}
        height={2.61}
        style={{
          fill: "rgb(46, 53, 58)",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "420.055px 407.365px",
        }}
        id="ellctnm7cyvc"
        className="animable"
      />
      <g id="el880a0my0723">
        <path
          d="M107.16,329.64h68a0,0,0,0,1,0,0v61.05a0,0,0,0,1,0,0h-68a1.62,1.62,0,0,1-1.62-1.62v-57.8A1.62,1.62,0,0,1,107.16,329.64Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(46, 53, 58)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "140.35px 360.165px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elqrhwscbl6h">
        <rect
          x={103.19}
          y={329.64}
          width={70.13}
          height={61.05}
          style={{
            fill: "rgb(46, 53, 58)",
            stroke: "rgb(46, 53, 58)",
            strokeMiterlimit: 10,
            transformOrigin: "138.255px 360.165px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elyipuszbs3h">
        <rect
          x={107.02}
          y={334.47}
          width={60.9}
          height={51.39}
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(46, 53, 58)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "137.47px 360.165px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="143.31 355.23 134.23 341.38 125.15 355.23 128.95 355.23 128.95 376.92 139.51 376.92 139.51 355.23 143.31 355.23"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "134.23px 359.15px",
        }}
        id="el6dm443f7c1t"
        className="animable"
      />
      <polygon
        points="173.32 390.69 120.16 406.06 48.49 406.06 103.19 390.69 173.32 390.69"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "110.905px 398.375px",
        }}
        id="elibx3oz4th4d"
        className="animable"
      />
      <polygon
        points="116.55 396.77 123.2 394.88 126.11 394.85 119.48 396.75 116.55 396.77"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "121.33px 395.81px",
        }}
        id="el7qz3pd763ov"
        className="animable"
      />
      <polygon
        points="110.76 396.82 117.43 394.94 120.3 394.91 113.65 396.8 110.76 396.82"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "115.53px 395.865px",
        }}
        id="eluo345th9zd"
        className="animable"
      />
      <polygon
        points="89.74 398.81 86.96 398.83 93.87 396.94 96.64 396.92 89.74 398.81"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "91.8px 397.875px",
        }}
        id="eldy89u1r3lnt"
        className="animable"
      />
      <polygon
        points="119.48 396.75 126.11 394.85 129.04 394.82 122.43 396.73 119.48 396.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "124.26px 395.785px",
        }}
        id="elxid5g2yawz"
        className="animable"
      />
      <polygon
        points="125.39 396.71 132 394.8 134.97 394.77 128.38 396.69 125.39 396.71"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "130.18px 395.74px",
        }}
        id="eltsqcx0g0ph"
        className="animable"
      />
      <polygon
        points="99.42 396.9 106.14 395.04 108.93 395.02 102.23 396.88 99.42 396.9"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "104.175px 395.96px",
        }}
        id="elg963f3xh4qk"
        className="animable"
      />
      <polygon
        points="96.64 396.92 103.37 395.07 106.14 395.04 99.42 396.9 96.64 396.92"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "101.39px 395.98px",
        }}
        id="elcibi1ef7osh"
        className="animable"
      />
      <polygon
        points="128.38 396.69 134.97 394.77 137.97 394.74 131.4 396.67 128.38 396.69"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "133.175px 395.715px",
        }}
        id="elvxumq523vl"
        className="animable"
      />
      <polygon
        points="107.9 396.84 114.58 394.96 117.43 394.94 110.76 396.82 107.9 396.84"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "112.665px 395.89px",
        }}
        id="elieqadjot03"
        className="animable"
      />
      <polygon
        points="93.87 396.94 100.61 395.1 103.37 395.07 96.64 396.92 93.87 396.94"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "98.62px 396.005px",
        }}
        id="ele8tu7x74bho"
        className="animable"
      />
      <polygon
        points="102.23 396.88 108.93 395.02 111.75 394.99 105.05 396.86 102.23 396.88"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "106.99px 395.935px",
        }}
        id="elopo9infzu2d"
        className="animable"
      />
      <polygon
        points="105.05 396.86 111.75 394.99 114.58 394.96 107.9 396.84 105.05 396.86"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "109.815px 395.91px",
        }}
        id="elcf5ebqx8n3a"
        className="animable"
      />
      <polygon
        points="113.65 396.8 120.3 394.91 123.2 394.88 116.55 396.77 113.65 396.8"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "118.425px 395.84px",
        }}
        id="elcoiqg06qgde"
        className="animable"
      />
      <polygon
        points="134.43 396.64 137.49 396.62 130.77 398.61 127.7 398.63 134.43 396.64"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "132.595px 397.625px",
        }}
        id="els2r3eew5r7f"
        className="animable"
      />
      <polygon
        points="140.99 394.71 144.03 394.68 137.49 396.62 134.43 396.64 140.99 394.71"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "139.23px 395.66px",
        }}
        id="elt8ajfhzpgo9"
        className="animable"
      />
      <polygon
        points="131.4 396.67 137.97 394.74 140.99 394.71 134.43 396.64 131.4 396.67"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "136.195px 395.69px",
        }}
        id="elcf5gvkxtpva"
        className="animable"
      />
      <polygon
        points="123.88 400.65 116.79 402.75 113.69 402.75 120.79 400.66 123.88 400.65"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "118.785px 401.7px",
        }}
        id="elu0dgnyl9zzq"
        className="animable"
      />
      <polygon
        points="127.7 398.63 130.77 398.61 123.88 400.65 120.79 400.66 127.7 398.63"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "125.78px 399.635px",
        }}
        id="elx9nhpvw5pvg"
        className="animable"
      />
      <polygon
        points="120.79 400.66 113.69 402.75 110.61 402.75 117.72 400.67 120.79 400.66"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "115.7px 401.705px",
        }}
        id="elii35zgyb8ns"
        className="animable"
      />
      <polygon
        points="122.43 396.73 129.04 394.82 132 394.8 125.39 396.71 122.43 396.73"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "127.215px 395.765px",
        }}
        id="elwl38n8xos59"
        className="animable"
      />
      <polygon
        points="115.64 398.69 122.43 396.73 125.39 396.71 118.62 398.67 115.64 398.69"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "120.515px 397.7px",
        }}
        id="el5155mzc1uzy"
        className="animable"
      />
      <polygon
        points="118.62 398.67 125.39 396.71 128.38 396.69 121.62 398.66 118.62 398.67"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "123.5px 397.68px",
        }}
        id="elqt3p7phhnsf"
        className="animable"
      />
      <polygon
        points="112.68 398.7 119.48 396.75 122.43 396.73 115.64 398.69 112.68 398.7"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "117.555px 397.715px",
        }}
        id="elms8c06qyvgm"
        className="animable"
      />
      <polygon
        points="131.4 396.67 134.43 396.64 127.7 398.63 124.65 398.64 131.4 396.67"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "129.54px 397.64px",
        }}
        id="elqe2gvf1mva"
        className="animable"
      />
      <polygon
        points="82.66 400.76 79.88 400.77 86.96 398.83 89.74 398.81 82.66 400.76"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "84.81px 399.79px",
        }}
        id="elrpjnba3loqh"
        className="animable"
      />
      <polygon
        points="121.62 398.66 128.38 396.69 131.4 396.67 124.65 398.64 121.62 398.66"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "126.51px 397.665px",
        }}
        id="el8fkbs72skr9"
        className="animable"
      />
      <polygon
        points="127.7 398.63 120.79 400.66 117.72 400.67 124.65 398.64 127.7 398.63"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "122.71px 399.65px",
        }}
        id="el2shooccsk2p"
        className="animable"
      />
      <polygon
        points="95.35 398.79 102.23 396.88 105.05 396.86 98.19 398.77 95.35 398.79"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "100.2px 397.825px",
        }}
        id="elnbwj0s2dnwa"
        className="animable"
      />
      <polygon
        points="98.19 398.77 105.05 396.86 107.9 396.84 101.04 398.76 98.19 398.77"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "103.045px 397.805px",
        }}
        id="el7km6sl6d7wm"
        className="animable"
      />
      <polygon
        points="89.74 398.81 96.64 396.92 99.42 396.9 92.53 398.8 89.74 398.81"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "94.58px 397.855px",
        }}
        id="elsgi40kmv3m"
        className="animable"
      />
      <polygon
        points="106.82 398.73 113.65 396.8 116.55 396.77 109.74 398.71 106.82 398.73"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "111.685px 397.75px",
        }}
        id="el70nbv3t6w8u"
        className="animable"
      />
      <polygon
        points="101.04 398.76 107.9 396.84 110.76 396.82 103.92 398.74 101.04 398.76"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "105.9px 397.79px",
        }}
        id="el7itdmcx5a1u"
        className="animable"
      />
      <polygon
        points="103.92 398.74 110.76 396.82 113.65 396.8 106.82 398.73 103.92 398.74"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "108.785px 397.77px",
        }}
        id="elyhlvvrqo8u"
        className="animable"
      />
      <polygon
        points="109.74 398.71 116.55 396.77 119.48 396.75 112.68 398.7 109.74 398.71"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "114.61px 397.73px",
        }}
        id="el19orqo0rjaz"
        className="animable"
      />
      <polygon
        points="101.04 398.76 94.01 400.73 91.14 400.74 98.19 398.77 101.04 398.76"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "96.09px 399.75px",
        }}
        id="elfzp5tqzggp"
        className="animable"
      />
      <polygon
        points="98.19 398.77 91.14 400.74 88.3 400.75 95.35 398.79 98.19 398.77"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "93.245px 399.76px",
        }}
        id="el8ppwsw3evlj"
        className="animable"
      />
      <polygon
        points="124.65 398.64 117.72 400.67 114.68 400.68 121.62 398.66 124.65 398.64"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "119.665px 399.66px",
        }}
        id="el5e6oi14mytp"
        className="animable"
      />
      <polygon
        points="95.35 398.79 88.3 400.75 85.47 400.75 92.53 398.8 95.35 398.79"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "90.41px 399.77px",
        }}
        id="eldtsrcba0npg"
        className="animable"
      />
      <polygon
        points="106.82 398.73 99.81 400.71 96.9 400.72 103.92 398.74 106.82 398.73"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "101.86px 399.725px",
        }}
        id="els3x21wb77oq"
        className="animable"
      />
      <polygon
        points="92.53 398.8 85.47 400.75 82.66 400.76 89.74 398.81 92.53 398.8"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "87.595px 399.78px",
        }}
        id="elvlyuurtjtf7"
        className="animable"
      />
      <polygon
        points="103.92 398.74 96.9 400.72 94.01 400.73 101.04 398.76 103.92 398.74"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "98.965px 399.735px",
        }}
        id="elwb97bdnoair"
        className="animable"
      />
      <polygon
        points="118.62 398.67 111.67 400.69 108.67 400.69 115.64 398.69 118.62 398.67"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "113.645px 399.68px",
        }}
        id="elo3pratni9ub"
        className="animable"
      />
      <polygon
        points="121.62 398.66 114.68 400.68 111.67 400.69 118.62 398.67 121.62 398.66"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "116.645px 399.675px",
        }}
        id="elj6j5bpo4n6"
        className="animable"
      />
      <polygon
        points="109.74 398.71 102.74 400.71 99.81 400.71 106.82 398.73 109.74 398.71"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "104.775px 399.71px",
        }}
        id="elerwf5h1vz2p"
        className="animable"
      />
      <polygon
        points="112.68 398.7 105.69 400.7 102.74 400.71 109.74 398.71 112.68 398.7"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "107.71px 399.705px",
        }}
        id="elbcj4i0skk2k"
        className="animable"
      />
      <polygon
        points="115.64 398.69 108.67 400.69 105.69 400.7 112.68 398.7 115.64 398.69"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "110.665px 399.695px",
        }}
        id="eldnlsv2orpt7"
        className="animable"
      />
      <polygon
        points="92.53 398.8 99.42 396.9 102.23 396.88 95.35 398.79 92.53 398.8"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "97.38px 397.84px",
        }}
        id="eloyqutm7r0yp"
        className="animable"
      />
      <polygon
        points="140.99 394.71 147.98 392.65 151 392.62 144.03 394.68 140.99 394.71"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "145.995px 393.665px",
        }}
        id="eln6cwhv5axls"
        className="animable"
      />
      <polygon
        points="144.03 394.68 146.86 394.65 140.33 396.6 137.49 396.62 144.03 394.68"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "142.175px 395.635px",
        }}
        id="eliqrqfyuyv4l"
        className="animable"
      />
      <polygon
        points="151 392.62 153.81 392.58 146.86 394.65 144.03 394.68 151 392.62"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "148.92px 393.63px",
        }}
        id="elr8oc21y7wz"
        className="animable"
      />
      <polygon
        points="137.49 396.62 140.33 396.6 133.63 398.6 130.77 398.61 137.49 396.62"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "135.55px 397.605px",
        }}
        id="el633c534obi9"
        className="animable"
      />
      <polygon
        points="130.77 398.61 133.63 398.6 126.75 400.65 123.88 400.65 130.77 398.61"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "128.755px 399.625px",
        }}
        id="elugdlg1n4mt"
        className="animable"
      />
      <polygon
        points="123.88 400.65 126.75 400.65 119.68 402.75 116.79 402.75 123.88 400.65"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "121.77px 401.7px",
        }}
        id="elwlgehlwhju"
        className="animable"
      />
      <polygon
        points="91.12 396.96 84.2 398.84 81.78 398.86 88.71 396.98 91.12 396.96"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "86.45px 397.91px",
        }}
        id="elh56u0ospgx4"
        className="animable"
      />
      <polygon
        points="77.11 400.77 74.68 400.78 81.78 398.86 84.2 398.84 77.11 400.77"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "79.44px 399.81px",
        }}
        id="elvvyfwcfz00h"
        className="animable"
      />
      <polygon
        points="69.83 402.75 67.4 402.75 74.68 400.78 77.11 400.77 69.83 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "72.255px 401.76px",
        }}
        id="eldcuntulk1j5"
        className="animable"
      />
      <polygon
        points="117.43 394.94 124.55 392.93 127.41 392.9 120.3 394.91 117.43 394.94"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "122.42px 393.92px",
        }}
        id="el01rwqpszr7qz"
        className="animable"
      />
      <polygon
        points="91.12 396.96 88.71 396.98 95.47 395.15 97.87 395.12 91.12 396.96"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "93.29px 396.05px",
        }}
        id="elqbwcckut5u9"
        className="animable"
      />
      <polygon
        points="79.88 400.77 77.11 400.77 84.2 398.84 86.96 398.83 79.88 400.77"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "82.035px 399.8px",
        }}
        id="ela5aocrd306"
        className="animable"
      />
      <polygon
        points="137.97 394.74 144.98 392.69 147.98 392.65 140.99 394.71 137.97 394.74"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "142.975px 393.695px",
        }}
        id="elmtzy02laiz"
        className="animable"
      />
      <polygon
        points="100.61 395.1 93.87 396.94 91.12 396.96 97.87 395.12 100.61 395.1"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "95.865px 396.03px",
        }}
        id="el4els11fra2l"
        className="animable"
      />
      <polygon
        points="95.47 395.15 102.69 393.19 105.08 393.16 97.87 395.12 95.47 395.15"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "100.275px 394.155px",
        }}
        id="elcf8lir2f57l"
        className="animable"
      />
      <polygon
        points="97.87 395.12 105.08 393.16 107.81 393.13 100.61 395.1 97.87 395.12"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "102.84px 394.125px",
        }}
        id="elh6a7lz8w2c9"
        className="animable"
      />
      <polygon
        points="88.3 400.75 81.06 402.75 78.22 402.75 85.47 400.75 88.3 400.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "83.26px 401.75px",
        }}
        id="elsq5on6pdi4o"
        className="animable"
      />
      <polygon
        points="72.61 402.75 69.83 402.75 77.11 400.77 79.88 400.77 72.61 402.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "74.855px 401.76px",
        }}
        id="elhpxsne40fd"
        className="animable"
      />
      <polygon
        points="100.61 395.1 107.81 393.13 110.55 393.1 103.37 395.07 100.61 395.1"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "105.58px 394.1px",
        }}
        id="eljppbfx46r5"
        className="animable"
      />
      <polygon
        points="82.66 400.76 75.41 402.75 72.61 402.75 79.88 400.77 82.66 400.76"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "77.635px 401.755px",
        }}
        id="el2hcls7c3l8l"
        className="animable"
      />
      <polygon
        points="85.47 400.75 78.22 402.75 75.41 402.75 82.66 400.76 85.47 400.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "80.44px 401.75px",
        }}
        id="el9njo6rvutxn"
        className="animable"
      />
      <polygon
        points="86.96 398.83 84.2 398.84 91.12 396.96 93.87 396.94 86.96 398.83"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "89.035px 397.89px",
        }}
        id="elbgml5hyvqoe"
        className="animable"
      />
      <polygon
        points="126.11 394.85 133.18 392.83 136.1 392.79 129.04 394.82 126.11 394.85"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "131.105px 393.82px",
        }}
        id="elb20x9z3wxow"
        className="animable"
      />
      <polygon
        points="103.37 395.07 110.55 393.1 113.31 393.06 106.14 395.04 103.37 395.07"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "108.34px 394.065px",
        }}
        id="el9c23wdlybyk"
        className="animable"
      />
      <polygon
        points="129.04 394.82 136.1 392.79 139.04 392.76 132 394.8 129.04 394.82"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "134.04px 393.79px",
        }}
        id="el2v4zkvfmer4"
        className="animable"
      />
      <polygon
        points="120.3 394.91 127.41 392.9 130.28 392.86 123.2 394.88 120.3 394.91"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "125.29px 393.885px",
        }}
        id="elaof9jooigrg"
        className="animable"
      />
      <polygon
        points="132 394.8 139.04 392.76 142 392.72 134.97 394.77 132 394.8"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "137px 393.76px",
        }}
        id="el070f4fpq0ywg"
        className="animable"
      />
      <polygon
        points="134.97 394.77 142 392.72 144.98 392.69 137.97 394.74 134.97 394.77"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "139.975px 393.73px",
        }}
        id="eltjspi2w4mb"
        className="animable"
      />
      <polygon
        points="123.2 394.88 130.28 392.86 133.18 392.83 126.11 394.85 123.2 394.88"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "128.19px 393.855px",
        }}
        id="el1nc8wprr4oa"
        className="animable"
      />
      <polygon
        points="106.14 395.04 113.31 393.06 116.09 393.03 108.93 395.02 106.14 395.04"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "111.115px 394.035px",
        }}
        id="elxaij57e3xi"
        className="animable"
      />
      <polygon
        points="108.93 395.02 116.09 393.03 118.89 393 111.75 394.99 108.93 395.02"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "113.91px 394.01px",
        }}
        id="elfk5caiu4j0t"
        className="animable"
      />
      <polygon
        points="114.58 394.96 121.71 392.96 124.55 392.93 117.43 394.94 114.58 394.96"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "119.565px 393.945px",
        }}
        id="eli407uvxsg7"
        className="animable"
      />
      <polygon
        points="88.3 400.75 91.14 400.74 94.01 400.73 96.9 400.72 99.81 400.71 102.74 400.71 105.69 400.7 108.67 400.69 111.67 400.69 114.68 400.68 117.72 400.67 110.61 402.75 107.56 402.75 104.52 402.75 101.52 402.75 98.53 402.75 95.56 402.75 92.62 402.75 89.7 402.75 86.8 402.75 83.92 402.75 81.06 402.75 88.3 400.75"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "99.39px 401.71px",
        }}
        id="el255gjtu9l94"
        className="animable"
      />
      <polygon
        points="111.75 394.99 118.89 393 121.71 392.96 114.58 394.96 111.75 394.99"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
          transformOrigin: "116.73px 393.975px",
        }}
        id="elz8oydk2oil"
        className="animable"
      />
      <polygon
        points="173.32 390.69 173.32 392.73 120.17 408.68 120.16 406.06 173.32 390.69"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(46, 53, 58)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "146.74px 399.685px",
        }}
        id="elaevqxb3xhrr"
        className="animable"
      />
      <g id="el6nz8piuie95">
        <rect
          x={48.49}
          y={406.06}
          width={71.67}
          height={2.61}
          style={{
            fill: "rgb(46, 53, 58)",
            stroke: "rgb(46, 53, 58)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "84.325px 407.365px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
    </g>
    <defs>
      <filter id="active" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter id="hover" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#ff0000" floodOpacity={0.5} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
        <feColorMatrix
          type="matrix"
          values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
        />
      </filter>
    </defs>
  </svg>
);
export default SvgComponent;

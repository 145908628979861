import * as React from "react";
import "./ProductsSvg.css";
const SvgComponent = () => (
  <svg
    className="animated"
    id="freepik_stories-memory-storage"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 500 500"
    xmlnssvgjs="http://svgjs.com/svgjs"
  >
    <g
      id="freepik--background-simple--inject-186"
      className="animable animator-active"
      style={{
        transformOrigin: "241.263px 250.609px",
      }}
    >
      <g id="el86mgjigy0nt">
        <path
          d="M67.93,167.09s-27.88,72,11.3,144.5S199.11,422.39,263.7,455.14s131.66,16.8,163.53-36.84-11.84-91.93-11.9-168.42,11.45-96.74-30-161.36-143.06-78.93-219.9-31S67.93,167.09,67.93,167.09Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.7,
            transformOrigin: "248.588px 250.609px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M66.52,95.24a98.56,98.56,0,0,1,11-11.2C106,59.38,146.67,54.68,181.83,65.74s65.26,36.15,89.62,65.39c11.13,13.37,21.71,28.08,36.61,36.25,19.21,10.54,41.46,8.33,62.23,11.43C445.81,190.09,470.42,296,418.82,351.12c-20.34,21.72-48.73,34-75.65,43.17-37.28,12.74-76.28,21.09-115.39,19.53s-78.48-13.5-110-38.31C76.53,343,51.73,290.84,41.4,237.21c-5.23-27.13-7.08-55.37-1.9-82.52C43.67,132.87,52.68,111.75,66.52,95.24Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "241.26px 237.048px",
        }}
        id="el1y16ld4fr27"
        className="animable"
      />
      <g id="elecs11xxsppu">
        <path
          d="M370.29,178.81c-20.77-3.1-43-.89-62.23-11.43-14.9-8.17-25.48-22.88-36.61-36.25C247.09,101.89,217,76.8,181.83,65.74S106,59.38,77.51,84a98.56,98.56,0,0,0-11,11.2c-13.84,16.51-22.85,37.63-27,59.45-5.18,27.15-3.33,55.39,1.9,82.52C51.73,290.84,76.53,343,117.8,375.51c31.5,24.81,70.86,36.75,110,38.31s78.11-6.79,115.39-19.53c26.92-9.2,55.31-21.45,75.65-43.17C470.42,296,445.81,190.09,370.29,178.81Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.7,
            transformOrigin: "241.268px 237.047px",
          }}
          className="animable"
        />
      </g>
    </g>
    <g
      id="freepik--Floor--inject-186"
      className="animable"
      style={{
        transformOrigin: "250.61px 442.34px",
      }}
    >
      <line
        x1={36.42}
        y1={442.34}
        x2={464.8}
        y2={442.34}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "250.61px 442.34px",
        }}
        id="elhhir08yuz79"
        className="animable"
      />
    </g>
    <g
      id="freepik--Line--inject-186"
      className="animable"
      style={{
        transformOrigin: "187.97px 146.93px",
      }}
    >
      <line
        x1={251.55}
        y1={119.79}
        x2={251.55}
        y2={123.29}
        style={{
          fill: "none",
          stroke: "rgb(176, 190, 197)",
          strokeLinejoin: "round",
          strokeWidth: 3,
          transformOrigin: "251.55px 121.54px",
        }}
        id="elvd7rylrb8l"
        className="animable"
      />
      <line
        x1={251.55}
        y1={130.98}
        x2={251.55}
        y2={142.51}
        style={{
          fill: "none",
          stroke: "rgb(176, 190, 197)",
          strokeLinejoin: "round",
          strokeWidth: 3,
          strokeDasharray: "7.6847, 7.6847",
          transformOrigin: "251.55px 136.745px",
        }}
        id="el17681rp1bao"
        className="animable"
      />
      <polyline
        points="251.55 146.35 251.55 149.85 248.05 149.85"
        style={{
          fill: "none",
          stroke: "rgb(176, 190, 197)",
          strokeLinejoin: "round",
          strokeWidth: 3,
          transformOrigin: "249.8px 148.1px",
        }}
        id="el9dwfq9qs1c7"
        className="animable"
      />
      <line
        x1={240.98}
        y1={149.85}
        x2={131.42}
        y2={149.85}
        style={{
          fill: "none",
          stroke: "rgb(176, 190, 197)",
          strokeLinejoin: "round",
          strokeWidth: 3,
          strokeDasharray: "7.0683, 7.0683",
          transformOrigin: "186.2px 149.85px",
        }}
        id="eldun2pj1u4v6"
        className="animable"
      />
      <polyline
        points="127.89 149.85 124.39 149.85 124.39 153.35"
        style={{
          fill: "none",
          stroke: "rgb(176, 190, 197)",
          strokeLinejoin: "round",
          strokeWidth: 3,
          transformOrigin: "126.14px 151.6px",
        }}
        id="el9nzsjuus6lu"
        className="animable"
      />
      <line
        x1={124.39}
        y1={159.09}
        x2={124.39}
        y2={167.7}
        style={{
          fill: "none",
          stroke: "rgb(176, 190, 197)",
          strokeLinejoin: "round",
          strokeWidth: 3,
          strokeDasharray: "5.74017, 5.74017",
          transformOrigin: "124.39px 163.395px",
        }}
        id="elukxyohcl7lb"
        className="animable"
      />
      <line
        x1={124.39}
        y1={170.57}
        x2={124.39}
        y2={174.07}
        style={{
          fill: "none",
          stroke: "rgb(176, 190, 197)",
          strokeLinejoin: "round",
          strokeWidth: 3,
          transformOrigin: "124.39px 172.32px",
        }}
        id="el5prdb2at7zm"
        className="animable"
      />
    </g>
    <g
      id="freepik--Clouds--inject-186"
      className="animable"
      style={{
        transformOrigin: "245.603px 91.5687px",
      }}
    >
      <line
        x1={142.16}
        y1={136.41}
        x2={156.37}
        y2={136.41}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "149.265px 136.41px",
        }}
        id="elfmkiujxykm8"
        className="animable"
      />
      <path
        d="M145.29,102.26A12.8,12.8,0,0,0,138.67,118c-.23,0-.46,0-.7,0a9.23,9.23,0,1,0,0,18.45"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "136.863px 119.357px",
        }}
        id="elm9xjz6a4tlg"
        className="animable"
      />
      <path
        d="M173.28,91.48A18.52,18.52,0,0,0,151.09,101h-.3"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "162.035px 95.8132px",
        }}
        id="elcuay3gc5e6b"
        className="animable"
      />
      <path
        d="M188.32,106.52a15.72,15.72,0,0,0-2.29.17,18.47,18.47,0,0,0-6.73-12"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "183.81px 100.69px",
        }}
        id="el21wa6c5rsrn"
        className="animable"
      />
      <path
        d="M165.29,136.41h23a14.94,14.94,0,0,0,15-14.94"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "184.29px 128.94px",
        }}
        id="el91ye9wrhxb"
        className="animable"
      />
      <line
        x1={321.35}
        y1={137.24}
        x2={330.99}
        y2={137.24}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "326.17px 137.24px",
        }}
        id="elvdhjpuazrab"
        className="animable"
      />
      <path
        d="M323.47,114.07a8.7,8.7,0,0,0-5,7.86,8.83,8.83,0,0,0,.47,2.82,3.59,3.59,0,0,0-.47,0,6.26,6.26,0,1,0,0,12.52"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "317.84px 125.67px",
        }}
        id="eloh7sqkdxlil"
        className="animable"
      />
      <path
        d="M342.46,106.75a12.7,12.7,0,0,0-3.77-.58,12.54,12.54,0,0,0-11.28,7.07h-.2"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "334.835px 109.705px",
        }}
        id="elkd4fsxgqiu"
        className="animable"
      />
      <path
        d="M352.67,117a11.46,11.46,0,0,0-1.56.11,12.46,12.46,0,0,0-4.56-8.12"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "349.61px 113.05px",
        }}
        id="eli5dkeqiwfee"
        className="animable"
      />
      <path
        d="M337,137.24h15.63a10.14,10.14,0,0,0,10.14-10.14"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "349.885px 132.17px",
        }}
        id="elvet13cqbnyg"
        className="animable"
      />
      <path
        d="M285.06,70.91a23.74,23.74,0,0,0-3.6.27,29,29,0,0,0-54.9-8.88c-.16,0-.31,0-.47,0a20.12,20.12,0,0,0-19,26.68c-.36,0-.73-.06-1.1-.06a14.5,14.5,0,0,0,0,29h79.11a23.49,23.49,0,0,0,0-47Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeMiterlimit: 10,
          transformOrigin: "250.045px 81.8937px",
        }}
        id="el8ti3s18ff6k"
        className="animable"
      />
      <line
        x1={251.67}
        y1={94.71}
        x2={251.67}
        y2={77.9}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "251.67px 86.305px",
        }}
        id="elvyre3o3f77"
        className="animable"
      />
      <polyline
        points="261 83.62 251.67 74.29 242.35 83.62"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "251.675px 78.955px",
        }}
        id="elk9gs54eehw"
        className="animable"
      />
      <line
        x1={251.67}
        y1={98.47}
        x2={251.67}
        y2={102.26}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "251.67px 100.365px",
        }}
        id="elm07gktx5d7m"
        className="animable"
      />
      <line
        x1={251.67}
        y1={106.69}
        x2={251.67}
        y2={109.59}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "251.67px 108.14px",
        }}
        id="elhz4smfxbb7j"
        className="animable"
      />
    </g>
    <g
      id="freepik--Folders--inject-186"
      className="animable"
      style={{
        transformOrigin: "308.994px 402.832px",
      }}
    >
      <path
        d="M221.7,442.34H201.89a7.33,7.33,0,0,1-7.42-8.8l7.67-48.08a10.73,10.73,0,0,1,10.23-8.8h60.76"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "233.725px 409.502px",
        }}
        id="eltf6egwvpfae"
        className="animable"
      />
      <path
        d="M210.63,363.32h17.8a2.69,2.69,0,0,1,2.5,1.71l2.12,5.45h37.7a8.83,8.83,0,0,1,8.36,6l2.12.18a7.33,7.33,0,0,1,7.42,8.8L281,433.54a10.75,10.75,0,0,1-10.23,8.8H233.18"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "249.715px 402.83px",
        }}
        id="elpzqg7grv1s"
        className="animable"
      />
      <path
        d="M193.07,400.32v-21a8.8,8.8,0,0,1,8.19-8.76l2.15-5.52a2.68,2.68,0,0,1,2.5-1.71"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "199.49px 381.825px",
        }}
        id="elu15ahnkuypk"
        className="animable"
      />
      <path
        d="M201.89,442.34a8.82,8.82,0,0,1-8.82-8.82V414"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "197.48px 428.17px",
        }}
        id="elfv98ufnjbt8"
        className="animable"
      />
      <path
        d="M390.5,442.34a4.45,4.45,0,0,1-4.45-4.45V410.54a4.44,4.44,0,0,1,4.13-4.42l1.09-2.78a1.35,1.35,0,0,1,1.26-.87h11.36a1.35,1.35,0,0,1,1.26.87l1.07,2.75h19a4.45,4.45,0,0,1,4.22,3"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "407.745px 422.405px",
        }}
        id="el1bbep5omblx"
        className="animable"
      />
      <g
        style={{
          clipPath: "url(&quot",
          transformOrigin: "407.745px 422.405px",
        }}
        id="eln4d6gslwnp"
        className="animable"
      >
        <g id="elmiizs4tu06a">
          <path
            d="M390.5,442.34a4.45,4.45,0,0,1-4.45-4.45V410.54a4.44,4.44,0,0,1,4.13-4.42l1.09-2.78a1.35,1.35,0,0,1,1.26-.87h11.36a1.35,1.35,0,0,1,1.26.87l1.07,2.75h19a4.45,4.45,0,0,1,4.22,3"
            style={{
              fillOpacity: 0.7,
              opacity: 0.3,
              transformOrigin: "407.745px 422.405px",
            }}
            className="animable"
          />
        </g>
      </g>
      <path
        d="M390.5,442.34a4.45,4.45,0,0,1-4.45-4.45V410.54a4.44,4.44,0,0,1,4.13-4.42l1.09-2.78a1.35,1.35,0,0,1,1.26-.87h11.36a1.35,1.35,0,0,1,1.26.87l1.07,2.75h19a4.45,4.45,0,0,1,4.22,3"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "407.745px 422.405px",
        }}
        id="el8qauvags1ih"
        className="animable"
      />
      <path
        d="M390.5,442.34h34.74a5.41,5.41,0,0,0,5.16-4.44l3.87-24.26a3.69,3.69,0,0,0-3.74-4.44H395.79a5.42,5.42,0,0,0-5.16,4.44l-3.87,24.26A3.69,3.69,0,0,0,390.5,442.34Z"
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "410.515px 425.77px",
        }}
        id="el3gz8e1jve9n"
        className="animable"
      />
      <path
        d="M188.09,442.34a4.45,4.45,0,0,1-4.45-4.45V410.54a4.43,4.43,0,0,1,4.13-4.42l1.08-2.78a1.38,1.38,0,0,1,1.27-.87h11.36a1.35,1.35,0,0,1,1.26.87l1.07,2.75h19a4.45,4.45,0,0,1,4.22,3"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "205.335px 422.405px",
        }}
        id="el4vzh8vimgzr"
        className="animable"
      />
      <g
        style={{
          clipPath: "url(&quot",
          transformOrigin: "205.335px 422.405px",
        }}
        id="elx2sqzk0orgh"
        className="animable"
      >
        <g id="elz0qdvy1ik5p">
          <path
            d="M188.09,442.34a4.45,4.45,0,0,1-4.45-4.45V410.54a4.43,4.43,0,0,1,4.13-4.42l1.08-2.78a1.38,1.38,0,0,1,1.27-.87h11.36a1.35,1.35,0,0,1,1.26.87l1.07,2.75h19a4.45,4.45,0,0,1,4.22,3"
            style={{
              fillOpacity: 0.7,
              opacity: 0.3,
              transformOrigin: "205.335px 422.405px",
            }}
            className="animable"
          />
        </g>
      </g>
      <path
        d="M188.09,442.34a4.45,4.45,0,0,1-4.45-4.45V410.54a4.43,4.43,0,0,1,4.13-4.42l1.08-2.78a1.38,1.38,0,0,1,1.27-.87h11.36a1.35,1.35,0,0,1,1.26.87l1.07,2.75h19a4.45,4.45,0,0,1,4.22,3"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "205.335px 422.405px",
        }}
        id="elecm4fvgxd5h"
        className="animable"
      />
      <path
        d="M188.09,442.34h34.74A5.41,5.41,0,0,0,228,437.9l3.87-24.26a3.69,3.69,0,0,0-3.74-4.44H193.37a5.43,5.43,0,0,0-5.16,4.44l-3.87,24.26A3.7,3.7,0,0,0,188.09,442.34Z"
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "208.106px 425.77px",
        }}
        id="el39b9dw0vko9"
        className="animable"
      />
    </g>
    <g
      id="freepik--Character--inject-186"
      className="animable"
      style={{
        transformOrigin: "237.043px 295.667px",
      }}
    >
      <path
        d="M310.35,428.21s1.58,9.63,0,14.13H278.48s-1.35-3.37,0-5.49,11.45-3.35,14.59-6.22a54.33,54.33,0,0,0,6.51-7.81Z"
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "294.466px 432.58px",
        }}
        id="elalbqua22m2u"
        className="animable"
      />
      <polygon
        points="277.88 440.65 310.77 440.65 311.05 442.34 277.88 442.34 277.88 440.65"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "294.465px 441.495px",
        }}
        id="elpgjtxic7p1m"
        className="animable"
      />
      <path
        d="M302.69,427s-3,.72-3.34.83-3.25,4.61-6.17,5.39-5.84.64-5.39,2.06"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "295.217px 431.14px",
        }}
        id="el74e5jccnmyi"
        className="animable"
      />
      <line
        x1={296.05}
        y1={427.04}
        x2={299.47}
        y2={429.56}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "297.76px 428.3px",
        }}
        id="elrfipxtwgxs"
        className="animable"
      />
      <line
        x1={294.47}
        y1={429.24}
        x2={297.76}
        y2={431.66}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "296.115px 430.45px",
        }}
        id="el95lzxu39c58"
        className="animable"
      />
      <line
        x1={292.36}
        y1={431.18}
        x2={295.22}
        y2={433.94}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "293.79px 432.56px",
        }}
        id="elc25hs56or1"
        className="animable"
      />
      <line
        x1={289.48}
        y1={432.58}
        x2={290.71}
        y2={435.32}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "290.095px 433.95px",
        }}
        id="elqvcg4psmf2"
        className="animable"
      />
      <path
        d="M310.87,432.58A27,27,0,0,0,299.92,434c-5.73,2-7.7,3.76-7.56,4.29s.82,2.09.82,2.09h2.87s-1-1.83-.83-2.09,10-4.08,15.65-3.41Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "301.612px 436.44px",
        }}
        id="elolf1oz9fodp"
        className="animable"
      />
      <path
        d="M364.38,442.34s1.22-4.69-.21-6.42c-2.58-3.12.31-5.74-2.28-6.83l-1.08,1.26c-.21-1.27.39-5.16-4.41-5.66-5.8-.62-5.91,3.61-6.23,5l.1-1.94c-2.48,0-1.19,2.47-4.95,6.51a10.74,10.74,0,0,0-2.71,8.08Z"
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "353.718px 433.484px",
        }}
        id="elsst73x8ixkd"
        className="animable"
      />
      <path
        d="M385.4,263.94c2.68,1.56,9.59,16.28,5.56,45.49s-16.11,86.34-18.14,95.08-9.12,29.75-9.12,29.75S361,425.14,348,430a.66.66,0,0,1-.88-.75l2.87-14.89s.2-10.89,1.13-13.62c3.53-10.28,5.3-50,6.47-55.55s-1.13-21.49-1.13-21.49-16.31,39-16.84,42.86-10.48,19.78-10.48,19.78L327.38,401s-13.24,22.68-15.57,29.45L296.05,427l.44-11.21,4.24-1.46,7.42-25.49a227.11,227.11,0,0,1,8.64-26c3.51-8.86,13.46-43.19,13.46-43.19,3.09-3.36,6-21.94,6-21.94L341,274.3Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "344.122px 349.1px",
        }}
        id="elq97otby97v"
        className="animable"
      />
      <path
        d="M319.22,202l-32.4,24.4L266,193.51l-7.8,1s15.53,42.42,24.12,46.65,41-19.23,41-19.23"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "290.76px 217.59px",
        }}
        id="el7d3mcubi2oa"
        className="animable"
      />
      <path
        d="M326.34,197.12s-9.38,3.27-15.67,8.72-16.32,13.59-16.32,13.59S300,233,306.56,238.53l14-5.6Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "310.345px 217.825px",
        }}
        id="elhaonzjjdmhc"
        className="animable"
      />
      <g
        style={{
          clipPath: "url(&quot",
          transformOrigin: "310.295px 217.79px",
        }}
        id="el4ze1i6vvo5"
        className="animable"
      >
        <g id="ele5u2adiuvol">
          <path
            d="M306.47,238.46l5.12-4.55c-3.54-1.19-3.95-8.41-2-6.33a11.71,11.71,0,0,0,5.34,2.77,31.46,31.46,0,0,1-1.54-13.92,80.83,80.83,0,0,1,2.69,9.57c.65,3.2,3,5.35,4,6.49l6.21-35.37s-9.38,3.27-15.67,8.72-16.32,13.59-16.32,13.59S300,232.83,306.47,238.46Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.4,
              transformOrigin: "310.295px 217.79px",
            }}
            className="animable"
          />
        </g>
      </g>
      <path
        d="M326.34,197.12s-9.38,3.27-15.67,8.72-16.32,13.59-16.32,13.59S300,233,306.56,238.53l14-5.6Z"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "310.345px 217.825px",
        }}
        id="elatx2rhzcn37"
        className="animable"
      />
      <path
        d="M320.53,232.93c5.45,13.34,14,30,14,30l3.77,18.59s14.16,10.79,30.4,6.26c19-5.3,22.11-17.33,22.11-17.33l-7.24-14.56c-2.48-14-5.72-47-16.87-54.59s-30.29-8.2-38-5.14c-6.87,2.72-10.68,4.79-12.55,7.3"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "353.48px 241.664px",
        }}
        id="eljl39kto2u4"
        className="animable"
      />
      <g
        style={{
          clipPath: "url(&quot",
          transformOrigin: "353.45px 241.729px",
        }}
        id="eliksu99mgr4k"
        className="animable"
      >
        <g id="elid37udoswii">
          <path
            d="M379.87,275c-17.17,5.55-15.27,1.58-11.34-5.34,3.55-6.26,1-13.57,1.09-15.54-.77,1.52-3.69,6-7.31,7.69-4.66,2.25-3,3,1.08-4.81a54.4,54.4,0,0,0,5.14-16.62c-4.58-4.55-8.19-15.65-8.19-15.65l15-9.19c-2.21-6.43-5-11.69-8.69-14.17-11.16-7.55-30.29-8.2-38-5.14-6.87,2.72-10.68,4.79-12.55,7.3l4.42,29.45c5.45,13.34,14,30,14,30l3.77,18.59s14.16,10.79,30.4,6.26c19-5.3,22.11-17.33,22.11-17.33l-.27-.56A46.24,46.24,0,0,1,379.87,275Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.4,
              transformOrigin: "353.45px 241.729px",
            }}
            className="animable"
          />
        </g>
      </g>
      <path
        d="M320.53,232.93c5.45,13.34,14,30,14,30l3.77,18.59s14.16,10.79,30.4,6.26c19-5.3,22.11-17.33,22.11-17.33l-7.24-14.56c-2.48-14-5.72-47-16.87-54.59s-30.29-8.2-38-5.14c-6.87,2.72-10.68,4.79-12.55,7.3"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "353.48px 241.664px",
        }}
        id="eluiqqfrjm1j"
        className="animable"
      />
      <path
        d="M346.71,198.76s-4.18,7.76-11.61,7c-6.18-.61-6-5.59-6-5.59a5.89,5.89,0,0,0,1.86-2.83,5.11,5.11,0,0,0,.19-1.83c-.1-1.54-.88-3.42-3-5.57l-.1-.1a6.42,6.42,0,0,1-1.09-1.4c-3.41-6,8.49-12.79,17.47-7.8C344.41,180.68,337.45,186.05,346.71,198.76Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "336.536px 192.361px",
        }}
        id="eltiwcrzixp5"
        className="animable"
      />
      <path
        d="M333.59,188.23s-.17,4.67-2.44,7.32c-.1-1.54-.88-3.42-3-5.57A5.75,5.75,0,0,1,333.59,188.23Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "330.87px 191.828px",
        }}
        id="el4zwu0ldul24"
        className="animable"
      />
      <path
        d="M345.82,174.91c3.36-14.43-3.61-20.68-8.18-22.58-7.32-3-16.84,1.74-19,9.75s-.22,9.85-.32,13.13c-.07,2.3-1.49,3.43.08,9.2,1,3.81,2.74,9.92,9.26,8.83a21.8,21.8,0,0,0,5.75-1.88"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "332.16px 172.395px",
        }}
        id="elcqd6jebr3j"
        className="animable"
      />
      <path
        d="M326.82,170.22s2.7-1,4.15,2.17"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "328.895px 171.23px",
        }}
        id="eloq3wnximlm"
        className="animable"
      />
      <path
        d="M317.61,168.54s2.83-.61,4,1.44"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "319.61px 169.218px",
        }}
        id="eljca9ody3gn"
        className="animable"
      />
      <path
        d="M322.37,174.07s-2.79,4.82-2.74,5.48c.14,2.06,2.76,1.13,2.76,1.13"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "321.01px 177.48px",
        }}
        id="elreafnzpto1n"
        className="animable"
      />
      <path
        d="M327.35,174.73c0,.67.3,1.22.67,1.22s.68-.55.68-1.22-.3-1.21-.68-1.21S327.35,174.06,327.35,174.73Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "328.025px 174.735px",
        }}
        id="elli4sc5g6mz"
        className="animable"
      />
      <path
        d="M319.63,174.07c0,.67.3,1.21.67,1.21s.67-.54.67-1.21-.3-1.22-.67-1.22S319.63,173.4,319.63,174.07Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "320.3px 174.065px",
        }}
        id="el90vtwv0nzvg"
        className="animable"
      />
      <path
        d="M327.35,182.66a8.86,8.86,0,0,1-5.71.92"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "324.495px 183.186px",
        }}
        id="elhv2nj3360sf"
        className="animable"
      />
      <path
        d="M326.82,183.21a2.78,2.78,0,0,1-2.32,2.34,1.85,1.85,0,0,1-2.11-1.79"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "324.605px 184.389px",
        }}
        id="elqlls25pt94q"
        className="animable"
      />
      <path
        d="M345.84,156.49S345.4,148.76,331,149s-18.65,11.43-11.34,14.46,11.84,1.7,11.84,1.7a12.39,12.39,0,0,1-5.13-3.52,36.75,36.75,0,0,0,9.34,3.81s-1.64,2.15,0,5.75.84,5.14.84,5.14,6.61,1.87,5.51,9c0,0,6.07-.12,8.53-14.1S345.84,156.49,345.84,156.49Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "333.624px 167.167px",
        }}
        id="el1eyuvbsp1cd"
        className="animable"
      />
      <path
        d="M337.64,177.44s2.64-4.93,5.41-3.73.84,7.09-1.56,9-3.85,0-3.85,0"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "341.026px 178.54px",
        }}
        id="eleeliuh0q8ht"
        className="animable"
      />
      <path
        d="M313.42,216.43c1.61,6.49,2.45,11.6,7.11,16.5"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "316.975px 224.68px",
        }}
        id="elrce31r0pnj"
        className="animable"
      />
      <path
        d="M312.41,211.27c.13,1,.31,2,.52,3"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "312.67px 212.77px",
        }}
        id="elx4d46apiis"
        className="animable"
      />
      <path
        d="M368.53,228.87s19.3,20.86,24,20.08,7.21-8.48,6.17-10.61S380.31,216,380.31,216Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "383.731px 232.486px",
        }}
        id="elkfs2urqmvwl"
        className="animable"
      />
      <path
        d="M361.35,209.26c-1.88,6.55-2.67,13.6.46,19.09,1.57,4.7,10.7,9.18,13.83,13.1,9.83-2.4,14-14.88,14-14.88S384,218.5,378.26,210s-17.08-11.65-17.08-11.65"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "374.67px 219.9px",
        }}
        id="eludwwxkusbdm"
        className="animable"
      />
      <g
        style={{
          clipPath: "url(&quot",
          transformOrigin: "374.67px 219.9px",
        }}
        id="elmgjhfojrrq"
        className="animable"
      >
        <g id="elun793rixa7">
          <path
            d="M361.35,209.26c-1.88,6.55-2.67,13.6.46,19.09,1.57,4.7,10.7,9.18,13.83,13.1,9.83-2.4,14-14.88,14-14.88S384,218.5,378.26,210s-17.08-11.65-17.08-11.65"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.4,
              transformOrigin: "374.67px 219.9px",
            }}
            className="animable"
          />
        </g>
      </g>
      <path
        d="M361.35,209.26c-1.88,6.55-2.67,13.6.46,19.09,1.57,4.7,10.7,9.18,13.83,13.1,9.83-2.4,14-14.88,14-14.88S384,218.5,378.26,210s-17.08-11.65-17.08-11.65"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "374.67px 219.9px",
        }}
        id="elforvs2ejpvm"
        className="animable"
      />
      <path
        d="M266.21,193.88S263,186.35,261,184s-4-4-4.56-4.08c-1.39-.16-5.92,2.35-5.92,2.35s0,1.42,2.45,1.21c0,0-3.54,2.21-3.71,3.94s2,4.18,2.46,5.13A.83.83,0,0,0,253,193s3.82,5.2,7.51,5.56"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "257.73px 189.236px",
        }}
        id="elstubqn5t6q"
        className="animable"
      />
      <path
        d="M253,193a2.22,2.22,0,0,0,.5-1.42s1.53,0,1.61-1.34c0,0,2.25.14,1.31-2.72"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "254.825px 190.26px",
        }}
        id="elzzlup5ai36r"
        className="animable"
      />
      <path
        d="M255.1,190.21s-2.55-2.74-2.7-3.8"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "253.75px 188.31px",
        }}
        id="ely6my7jatsib"
        className="animable"
      />
      <path
        d="M253.49,191.55s-2.8-2.5-2.65-3.63"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "252.162px 189.735px",
        }}
        id="elb1mwacyif4"
        className="animable"
      />
      <path
        d="M256,181.8a4,4,0,0,1-2.78,1.47"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "254.61px 182.535px",
        }}
        id="el9guxfxitav7"
        className="animable"
      />
      <path
        d="M255.41,182.42a9.1,9.1,0,0,1,1.3,1.25"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "256.06px 183.045px",
        }}
        id="elllfiwqbyi7p"
        className="animable"
      />
      <path
        d="M362.31,207.19a8,8,0,0,1,2.43-2.6"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "363.525px 205.89px",
        }}
        id="elgprijtcznk6"
        className="animable"
      />
      <polygon
        points="342.59 440.65 364.86 440.65 364.38 442.34 342.61 442.34 342.59 440.65"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "353.725px 441.495px",
        }}
        id="el0y451sc5ynxo"
        className="animable"
      />
      <path
        d="M349.74,434.06s7.31-2.42,10.84.61"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "355.16px 433.907px",
        }}
        id="elz82uw8n70yj"
        className="animable"
      />
      <path
        d="M349.74,432.46s6.43-2,10.84.69"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "355.16px 432.447px",
        }}
        id="eliqqkz93l5t"
        className="animable"
      />
      <path
        d="M350.12,430.89s6.05-2.28,10.46.84"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "355.35px 430.908px",
        }}
        id="elkdxztbor9r9"
        className="animable"
      />
      <path
        d="M345.32,434.26l.73.71a.6.6,0,0,1,.08.75,8.94,8.94,0,0,0-1.49,4.93h-1.07a15.54,15.54,0,0,1,1.54-4.91l-.55-.55Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "344.898px 437.455px",
        }}
        id="eldyk7p8ye52g"
        className="animable"
      />
      <path
        d="M370.79,252.2c-1.26,1.76-18.38,22.43-26.38,21.31"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "357.6px 262.877px",
        }}
        id="eluyu6873fgv"
        className="animable"
      />
      <path
        d="M326.34,234.53s9.56,14.74,24.73,14.44"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "338.705px 241.752px",
        }}
        id="el2r1d3nm7mel"
        className="animable"
      />
      <g id="els141nl68g2">
        <g
          style={{
            opacity: 0.2,
            transformOrigin: "344.809px 353.02px",
          }}
          className="animable"
        >
          <path
            d="M351.07,307.39c.88.2,17.69,6.33,21.05,12.46"
            style={{
              fill: "none",
              stroke: "rgb(255, 255, 255)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "361.595px 313.62px",
            }}
            id="elcx3kylej4fg"
            className="animable"
          />
          <path
            d="M355.16,316.29c-.84,1.19-8,23.54-11,29.67s-13.34,33.63-15.71,35.21c0,0-3.17,16-5.15,18.59s-12.21,23.06-12.21,23.06"
            style={{
              fill: "none",
              stroke: "rgb(255, 255, 255)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "333.125px 369.555px",
            }}
            id="elhcvfdiyima"
            className="animable"
          />
          <line
            x1={299.58}
            y1={422.82}
            x2={311.99}
            y2={425.48}
            style={{
              fill: "none",
              stroke: "rgb(255, 255, 255)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "305.785px 424.15px",
            }}
            id="elvli62mh9ncr"
            className="animable"
          />
          <path
            d="M350.12,424.15s9.93-.89,13.3,2.89"
            style={{
              fill: "none",
              stroke: "rgb(255, 255, 255)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "356.77px 425.549px",
            }}
            id="el1zpocbrro01"
            className="animable"
          />
          <path
            d="M361.6,318.86c.83,4.75,13.29,31.06,16.26,28.09"
            style={{
              fill: "none",
              stroke: "rgb(255, 255, 255)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "369.73px 333.022px",
            }}
            id="el9aglfbz6pc4"
            className="animable"
          />
          <path
            d="M356.77,321.24s-1.46-6.53-.08-9.5c.53-1.13-10.68,2.77-14.44,1.88"
            style={{
              fill: "none",
              stroke: "rgb(255, 255, 255)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "349.51px 316.386px",
            }}
            id="el9q0v8whjs2m"
            className="animable"
          />
          <path
            d="M355.16,288.91s2,5.62,2.44,7.4S354,305,355.16,306.4"
            style={{
              fill: "none",
              stroke: "rgb(255, 255, 255)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "356.292px 297.655px",
            }}
            id="elp3vpdbkbrso"
            className="animable"
          />
          <path
            d="M382.41,283.65s2.77,4.24,2.77,7.37,3.75,4.5,3.75,4.5"
            style={{
              fill: "none",
              stroke: "rgb(255, 255, 255)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "385.67px 289.585px",
            }}
            id="ely5powtadmm"
            className="animable"
          />
          <path
            d="M388.31,279a72.5,72.5,0,0,1,.62,28.37c-2.77,15.63-7.31,47.47-9.09,55.58s-5.94,26.31-5.94,28.88-4.17,17.21-4.17,17.21"
            style={{
              fill: "none",
              stroke: "rgb(255, 255, 255)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "379.884px 344.02px",
            }}
            id="elcjha5snbhyn"
            className="animable"
          />
          <path
            d="M370.74,386.91c-.6,0-11.67,10.68-14.44,10.87"
            style={{
              fill: "none",
              stroke: "rgb(255, 255, 255)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "363.52px 392.345px",
            }}
            id="elfdt69x4z6ri"
            className="animable"
          />
          <path
            d="M322.27,380.38c-.59.2-9.89,4.75-10.28,3.36"
            style={{
              fill: "none",
              stroke: "rgb(255, 255, 255)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "317.13px 382.192px",
            }}
            id="el531vl2l47u6"
            className="animable"
          />
        </g>
      </g>
      <path
        d="M368.85,297.83a4.45,4.45,0,0,1-4.79-4.08l-2.2-27.27a4.45,4.45,0,0,1,3.76-4.74l.86-2.85a1.36,1.36,0,0,1,1.19-1L379,257a1.35,1.35,0,0,1,1.33.76l1.29,2.65,19-1.52a4.46,4.46,0,0,1,4.45,2.67"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "383.458px 277.42px",
        }}
        id="elfv6w66p510n"
        className="animable"
      />
      <g
        style={{
          clipPath: "url(&quot",
          transformOrigin: "383.458px 277.42px",
        }}
        id="el6p670voug8r"
        className="animable"
      >
        <g id="elun691mu4p0n">
          <path
            d="M368.85,297.83a4.45,4.45,0,0,1-4.79-4.08l-2.2-27.27a4.45,4.45,0,0,1,3.76-4.74l.86-2.85a1.36,1.36,0,0,1,1.19-1L379,257a1.35,1.35,0,0,1,1.33.76l1.29,2.65,19-1.52a4.46,4.46,0,0,1,4.45,2.67"
            style={{
              fillOpacity: 0.7,
              opacity: 0.3,
              transformOrigin: "383.458px 277.42px",
            }}
            className="animable"
          />
        </g>
      </g>
      <path
        d="M368.85,297.83a4.45,4.45,0,0,1-4.79-4.08l-2.2-27.27a4.45,4.45,0,0,1,3.76-4.74l.86-2.85a1.36,1.36,0,0,1,1.19-1L379,257a1.35,1.35,0,0,1,1.33.76l1.29,2.65,19-1.52a4.46,4.46,0,0,1,4.45,2.67"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "383.458px 277.42px",
        }}
        id="el8rbdv2f09hm"
        className="animable"
      />
      <path
        d="M368.85,297.83,403.48,295a5.43,5.43,0,0,0,4.79-4.84l1.91-24.5a3.7,3.7,0,0,0-4.09-4.12l-34.63,2.79a5.42,5.42,0,0,0-4.79,4.84l-1.91,24.49A3.7,3.7,0,0,0,368.85,297.83Z"
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "387.467px 279.685px",
        }}
        id="elow8z52hisss"
        className="animable"
      />
      <path
        d="M395.64,234.35c3.58,3.44,5.43,6.13,5.17,17.31S398.52,279,397.2,290l-9.51,1.45s1.49-17.2,0-28.52c-1.62-12.36-.34-19.28,1.15-20.17"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "393.886px 262.9px",
        }}
        id="elj9rpphjvto"
        className="animable"
      />
      <path
        d="M388.23,281.87c-.2,3.21-6.05,4.19-6.48,6.2-.14.66-2.16,5.08-2.16,5.08a14.42,14.42,0,0,0,.27,3.79c.5,1.72,2.05.72,2.05.72l.5,1.22c.79,2,2.27.15,4.79,1.39s3.74-.4,4.71,0,4.14-.59,4.74-1.78-.35-6.45.55-8.54.92-6.32.92-8.08"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "388.842px 291.306px",
        }}
        id="elbfm0y3ahjfe"
        className="animable"
      />
      <path
        d="M381.91,297.66s0-3.23,2.08-4.51"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "382.95px 295.405px",
        }}
        id="elci50ojzzhv"
        className="animable"
      />
      <rect
        x={163.3}
        y={181.45}
        width={88.95}
        height={18.38}
        style={{
          fill: "rgb(153, 153, 153)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "207.775px 190.64px",
        }}
        id="el44tpk3m841w"
        className="animable"
      />
      <rect
        x={163.3}
        y={205.34}
        width={88.95}
        height={18.38}
        style={{
          fill: "rgb(153, 153, 153)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "207.775px 214.53px",
        }}
        id="eldekflqgad9a"
        className="animable"
      />
      <rect
        x={163.3}
        y={229.23}
        width={88.95}
        height={18.38}
        style={{
          fill: "rgb(153, 153, 153)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "207.775px 238.42px",
        }}
        id="eld4fj6vkspf5"
        className="animable"
      />
      <rect
        x={163.3}
        y={253.12}
        width={88.95}
        height={18.38}
        style={{
          fill: "rgb(153, 153, 153)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "207.775px 262.31px",
        }}
        id="elnwaffzo5w09"
        className="animable"
      />
      <rect
        x={163.3}
        y={277.01}
        width={88.95}
        height={18.38}
        style={{
          fill: "rgb(153, 153, 153)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "207.775px 286.2px",
        }}
        id="elz8g8qpwenm"
        className="animable"
      />
      <rect
        x={179.75}
        y={179.38}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(191, 191, 191)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "217.37px 190.63px",
        }}
        id="eljht70z6w0v"
        className="animable"
      />
      <rect
        x={179.75}
        y={203.27}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(191, 191, 191)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "217.37px 214.52px",
        }}
        id="eler2632fol0q"
        className="animable"
      />
      <rect
        x={179.75}
        y={227.17}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(191, 191, 191)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "217.37px 238.42px",
        }}
        id="el15xnha8ojmn"
        className="animable"
      />
      <rect
        x={179.75}
        y={251.06}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(191, 191, 191)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "217.37px 262.31px",
        }}
        id="el35d0lh8i4yq"
        className="animable"
      />
      <rect
        x={179.75}
        y={274.95}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(191, 191, 191)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "217.37px 286.2px",
        }}
        id="el2h9avt97iig"
        className="animable"
      />
      <path
        d="M262.08,194.16s-5.16-2.31-5-6a6.74,6.74,0,0,0-.7-2.71s-5-1.19-4.3-3.18L259,183a11.9,11.9,0,0,1,4.95,6"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "257.981px 188.215px",
        }}
        id="elh82ivejng6b"
        className="animable"
      />
      <rect
        x={68.5}
        y={178.94}
        width={104.88}
        height={263.4}
        style={{
          fill: "rgb(117, 117, 117)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "120.94px 310.64px",
        }}
        id="elqmkojjwvmei"
        className="animable"
      />
      <rect
        x={63.88}
        y={179.39}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(153, 153, 153)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "101.5px 190.64px",
        }}
        id="eljlr1ochtime"
        className="animable"
      />
      <rect
        x={102.75}
        y={179.39}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(191, 191, 191)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "140.37px 190.64px",
        }}
        id="eldq54rquhlo"
        className="animable"
      />
      <path
        d="M104.74,198.53v-15.8a1.36,1.36,0,0,1,1.36-1.36l68.53,0a1.36,1.36,0,0,1,1.37,1.36l0,15.78a1.36,1.36,0,0,1-1.36,1.36H106.1A1.36,1.36,0,0,1,104.74,198.53Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "140.37px 190.62px",
        }}
        id="elwt5zchnsafs"
        className="animable"
      />
      <rect
        x={109.18}
        y={192.6}
        width={40.22}
        height={6.46}
        rx={1.32}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "129.29px 195.83px",
        }}
        id="elf07u889pfaj"
        className="animable"
      />
      <rect
        x={117.05}
        y={185.48}
        width={40.22}
        height={6.46}
        rx={1.32}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "137.16px 188.71px",
        }}
        id="el386ixws18ue"
        className="animable"
      />
      <rect
        x={63.88}
        y={298.85}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(153, 153, 153)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "101.5px 310.1px",
        }}
        id="elpt4rhxu86o"
        className="animable"
      />
      <rect
        x={102.75}
        y={298.85}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(191, 191, 191)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "140.37px 310.1px",
        }}
        id="elh822br6ufjq"
        className="animable"
      />
      <rect
        x={112.61}
        y={308.31}
        width={44.66}
        height={3.57}
        style={{
          fill: "rgb(117, 117, 117)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "134.94px 310.095px",
        }}
        id="elz6wtbnz1tj"
        className="animable"
      />
      <path
        d="M165.11,310.1a1.81,1.81,0,1,1-1.81-1.81A1.81,1.81,0,0,1,165.11,310.1Z"
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "163.3px 310.1px",
        }}
        id="ela09ws32nzki"
        className="animable"
      />
      <path
        d="M171.55,310.1a1.81,1.81,0,1,1-1.81-1.81A1.81,1.81,0,0,1,171.55,310.1Z"
        style={{
          fill: "rgb(117, 117, 117)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "169.74px 310.1px",
        }}
        id="elkv4ixml6zqg"
        className="animable"
      />
      <rect
        x={114.24}
        y={309.5}
        width={26.13}
        height={1.19}
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "127.305px 310.095px",
        }}
        id="el0ekxr3rbha8l"
        className="animable"
      />
      <rect
        x={63.88}
        y={322.74}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(153, 153, 153)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "101.5px 333.99px",
        }}
        id="elrjzyp6v4bkb"
        className="animable"
      />
      <rect
        x={102.75}
        y={322.74}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(191, 191, 191)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "140.37px 333.99px",
        }}
        id="el9o8iqcovsfh"
        className="animable"
      />
      <rect
        x={112.61}
        y={332.2}
        width={44.66}
        height={3.57}
        style={{
          fill: "rgb(117, 117, 117)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "134.94px 333.985px",
        }}
        id="el637rkgvkwz"
        className="animable"
      />
      <circle
        cx={163.3}
        cy={333.99}
        r={1.81}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "163.3px 333.99px",
        }}
        id="eloqy4oa2ibg8"
        className="animable"
      />
      <path
        d="M171.55,334a1.81,1.81,0,1,1-1.81-1.81A1.8,1.8,0,0,1,171.55,334Z"
        style={{
          fill: "rgb(117, 117, 117)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "169.74px 334px",
        }}
        id="elwlsd8j18cn9"
        className="animable"
      />
      <rect
        x={114.24}
        y={333.4}
        width={26.13}
        height={1.18}
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "127.305px 333.99px",
        }}
        id="elqas9xlbcxv"
        className="animable"
      />
      <rect
        x={63.88}
        y={346.63}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(153, 153, 153)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "101.5px 357.88px",
        }}
        id="ellmv2fuccqp"
        className="animable"
      />
      <rect
        x={102.75}
        y={346.63}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(191, 191, 191)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "140.37px 357.88px",
        }}
        id="elecxauvmzm2h"
        className="animable"
      />
      <rect
        x={112.61}
        y={356.09}
        width={44.66}
        height={3.57}
        style={{
          fill: "rgb(117, 117, 117)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "134.94px 357.875px",
        }}
        id="el9opr2n07b0c"
        className="animable"
      />
      <circle
        cx={163.3}
        cy={357.88}
        r={1.81}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "163.3px 357.88px",
        }}
        id="eltnhu6c8p02a"
        className="animable"
      />
      <path
        d="M171.55,357.88a1.81,1.81,0,1,1-1.81-1.8A1.81,1.81,0,0,1,171.55,357.88Z"
        style={{
          fill: "rgb(117, 117, 117)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "169.74px 357.89px",
        }}
        id="elpoeafxtpyt"
        className="animable"
      />
      <rect
        x={114.24}
        y={357.29}
        width={26.13}
        height={1.18}
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "127.305px 357.88px",
        }}
        id="el87ai3yc70v3"
        className="animable"
      />
      <rect
        x={63.88}
        y={370.52}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(153, 153, 153)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "101.5px 381.77px",
        }}
        id="elj4u53hfzjf"
        className="animable"
      />
      <rect
        x={102.75}
        y={370.52}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(191, 191, 191)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "140.37px 381.77px",
        }}
        id="elxc9k4x5jjc"
        className="animable"
      />
      <rect
        x={112.61}
        y={379.98}
        width={44.66}
        height={3.57}
        style={{
          fill: "rgb(117, 117, 117)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "134.94px 381.765px",
        }}
        id="eltvvzlfukwc"
        className="animable"
      />
      <path
        d="M165.11,381.77a1.81,1.81,0,1,1-1.81-1.8A1.81,1.81,0,0,1,165.11,381.77Z"
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "163.3px 381.78px",
        }}
        id="ele9irp91exs4"
        className="animable"
      />
      <path
        d="M171.55,381.77a1.81,1.81,0,1,1-1.81-1.8A1.81,1.81,0,0,1,171.55,381.77Z"
        style={{
          fill: "rgb(117, 117, 117)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "169.74px 381.78px",
        }}
        id="elnkv1f8q7eo"
        className="animable"
      />
      <rect
        x={114.24}
        y={381.18}
        width={26.13}
        height={1.19}
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "127.305px 381.775px",
        }}
        id="el7a0t6zhxxa2"
        className="animable"
      />
      <rect
        x={63.88}
        y={394.41}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(153, 153, 153)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "101.5px 405.66px",
        }}
        id="eln85zvxgpnes"
        className="animable"
      />
      <rect
        x={102.75}
        y={394.41}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(191, 191, 191)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "140.37px 405.66px",
        }}
        id="elqbp6pqztlqe"
        className="animable"
      />
      <rect
        x={112.61}
        y={403.88}
        width={44.66}
        height={3.57}
        style={{
          fill: "rgb(117, 117, 117)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "134.94px 405.665px",
        }}
        id="eli7z1kv831x"
        className="animable"
      />
      <circle
        cx={163.3}
        cy={405.66}
        r={1.81}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "163.3px 405.66px",
        }}
        id="elsj5otanm258"
        className="animable"
      />
      <path
        d="M171.55,405.66a1.81,1.81,0,1,1-1.81-1.8A1.81,1.81,0,0,1,171.55,405.66Z"
        style={{
          fill: "rgb(117, 117, 117)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "169.74px 405.67px",
        }}
        id="elbb77u5nekvr"
        className="animable"
      />
      <rect
        x={114.24}
        y={405.07}
        width={26.13}
        height={1.18}
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "127.305px 405.66px",
        }}
        id="el1165uvc1cehf"
        className="animable"
      />
      <rect
        x={63.88}
        y={418.3}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(153, 153, 153)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "101.5px 429.55px",
        }}
        id="elrrlxvdwnp1p"
        className="animable"
      />
      <rect
        x={102.75}
        y={418.3}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(191, 191, 191)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "140.37px 429.55px",
        }}
        id="elurrwop1al8h"
        className="animable"
      />
      <rect
        x={112.61}
        y={427.77}
        width={44.66}
        height={3.57}
        style={{
          fill: "rgb(117, 117, 117)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "134.94px 429.555px",
        }}
        id="elzvaub21rn8"
        className="animable"
      />
      <circle
        cx={163.3}
        cy={429.56}
        r={1.81}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "163.3px 429.56px",
        }}
        id="el9cu91sh8a3p"
        className="animable"
      />
      <path
        d="M171.55,429.56a1.81,1.81,0,1,1-1.81-1.81A1.81,1.81,0,0,1,171.55,429.56Z"
        style={{
          fill: "rgb(117, 117, 117)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "169.74px 429.56px",
        }}
        id="eljorlv2zijqh"
        className="animable"
      />
      <rect
        x={114.24}
        y={428.96}
        width={26.13}
        height={1.18}
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "127.305px 429.55px",
        }}
        id="elxh8pyn548cm"
        className="animable"
      />
      <rect
        x={63.88}
        y={274.95}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(153, 153, 153)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "101.5px 286.2px",
        }}
        id="elrlcmc6qsmpn"
        className="animable"
      />
      <rect
        x={102.75}
        y={274.95}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(191, 191, 191)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "140.37px 286.2px",
        }}
        id="elhrf4bobsh1e"
        className="animable"
      />
      <path
        d="M104.74,294.09V278.3a1.36,1.36,0,0,1,1.36-1.36h68.53a1.37,1.37,0,0,1,1.37,1.37l0,15.78a1.36,1.36,0,0,1-1.36,1.36H106.1A1.36,1.36,0,0,1,104.74,294.09Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "140.37px 286.195px",
        }}
        id="el9ae8zn0g5hp"
        className="animable"
      />
      <rect
        x={109.18}
        y={288.16}
        width={40.22}
        height={6.46}
        rx={1.32}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "129.29px 291.39px",
        }}
        id="eldixnu7cy7jt"
        className="animable"
      />
      <rect
        x={151.39}
        y={288.16}
        width={20.22}
        height={6.46}
        rx={1.32}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "161.5px 291.39px",
        }}
        id="eltqwmrh2q9j"
        className="animable"
      />
      <rect
        x={113.51}
        y={281.65}
        width={20.22}
        height={6.46}
        rx={1.32}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "123.62px 284.88px",
        }}
        id="eli664239klhi"
        className="animable"
      />
      <rect
        x={63.88}
        y={251.06}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(153, 153, 153)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "101.5px 262.31px",
        }}
        id="elcfxv6c7m57k"
        className="animable"
      />
      <rect
        x={102.75}
        y={251.06}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(191, 191, 191)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "140.37px 262.31px",
        }}
        id="elhdt7hrg6kvp"
        className="animable"
      />
      <path
        d="M104.74,270.2V254.41a1.36,1.36,0,0,1,1.36-1.36h68.53a1.37,1.37,0,0,1,1.37,1.37l0,15.77a1.36,1.36,0,0,1-1.36,1.37H106.1A1.36,1.36,0,0,1,104.74,270.2Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "140.37px 262.305px",
        }}
        id="el1spz1ijqtjj"
        className="animable"
      />
      <rect
        x={129.79}
        y={264.27}
        width={20.22}
        height={6.46}
        rx={1.32}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "139.9px 267.5px",
        }}
        id="elkd91lf526u"
        className="animable"
      />
      <rect
        x={151.39}
        y={264.27}
        width={20.22}
        height={6.46}
        rx={1.32}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "161.5px 267.5px",
        }}
        id="elcknu81m1agc"
        className="animable"
      />
      <rect
        x={108.57}
        y={264.27}
        width={20.22}
        height={6.46}
        rx={1.32}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "118.68px 267.5px",
        }}
        id="elas7kd0uqh0h"
        className="animable"
      />
      <rect
        x={124.39}
        y={257.35}
        width={20.22}
        height={6.46}
        rx={1.32}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "134.5px 260.58px",
        }}
        id="elc8oq0ldnscn"
        className="animable"
      />
      <rect
        x={63.88}
        y={227.17}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(153, 153, 153)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "101.5px 238.42px",
        }}
        id="ela2xodv01afq"
        className="animable"
      />
      <rect
        x={102.75}
        y={227.17}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(191, 191, 191)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "140.37px 238.42px",
        }}
        id="elawe4ue7773u"
        className="animable"
      />
      <path
        d="M104.74,246.31V230.52a1.36,1.36,0,0,1,1.36-1.37l68.53,0a1.37,1.37,0,0,1,1.37,1.37l0,15.77a1.36,1.36,0,0,1-1.36,1.37H106.1A1.36,1.36,0,0,1,104.74,246.31Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "140.37px 238.405px",
        }}
        id="el37htg0gqouh"
        className="animable"
      />
      <rect
        x={122.18}
        y={240.38}
        width={40.22}
        height={6.46}
        rx={1.32}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "142.29px 243.61px",
        }}
        id="elp1qraaoi0qf"
        className="animable"
      />
      <path
        d="M156,239.72H118.37a1.32,1.32,0,0,1-1.32-1.32v.18a1.32,1.32,0,0,1,1.32-1.32H156a1.32,1.32,0,0,1,1.31,1.32v-.18A1.32,1.32,0,0,1,156,239.72Z"
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "137.18px 238.49px",
        }}
        id="elmle4rg4czao"
        className="animable"
      />
      <rect
        x={63.88}
        y={203.28}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(153, 153, 153)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "101.5px 214.53px",
        }}
        id="el2840xaew4kb"
        className="animable"
      />
      <rect
        x={102.75}
        y={203.28}
        width={75.24}
        height={22.5}
        rx={1.98}
        style={{
          fill: "rgb(191, 191, 191)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "140.37px 214.53px",
        }}
        id="el5a2fxp86gvo"
        className="animable"
      />
      <path
        d="M104.74,222.42V206.63a1.36,1.36,0,0,1,1.36-1.37l68.53,0a1.36,1.36,0,0,1,1.37,1.36l0,15.78a1.36,1.36,0,0,1-1.36,1.36H106.1A1.36,1.36,0,0,1,104.74,222.42Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "140.37px 214.51px",
        }}
        id="el7rz2o34m8i6"
        className="animable"
      />
      <rect
        x={129.18}
        y={216.49}
        width={40.22}
        height={6.46}
        rx={1.32}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "149.29px 219.72px",
        }}
        id="el5pjypkumqe"
        className="animable"
      />
      <rect
        x={137.05}
        y={209.37}
        width={20.22}
        height={6.46}
        rx={1.32}
        style={{
          fill: "rgb(176, 190, 197)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "147.16px 212.6px",
        }}
        id="ela29sm1o9gx"
        className="animable"
      />
      <line
        x1={68.16}
        y1={193.65}
        x2={68.16}
        y2={199.06}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "68.16px 196.355px",
        }}
        id="eltmrzabmngu9"
        className="animable"
      />
      <line
        x1={69.88}
        y1={193.65}
        x2={69.88}
        y2={199.06}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "69.88px 196.355px",
        }}
        id="elvay3y7jue4"
        className="animable"
      />
      <line
        x1={71.59}
        y1={193.65}
        x2={71.59}
        y2={199.06}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "71.59px 196.355px",
        }}
        id="elmkbgiuage0j"
        className="animable"
      />
      <line
        x1={73.31}
        y1={193.65}
        x2={73.31}
        y2={199.06}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "73.31px 196.355px",
        }}
        id="el623uvp9g5iv"
        className="animable"
      />
      <line
        x1={75.02}
        y1={193.65}
        x2={75.02}
        y2={199.06}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "75.02px 196.355px",
        }}
        id="el9vs4m6actnc"
        className="animable"
      />
      <line
        x1={68.16}
        y1={432.65}
        x2={68.16}
        y2={438.06}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "68.16px 435.355px",
        }}
        id="elmi5zsxrlwk"
        className="animable"
      />
      <line
        x1={69.88}
        y1={432.65}
        x2={69.88}
        y2={438.06}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "69.88px 435.355px",
        }}
        id="elu9fa2xdtn7f"
        className="animable"
      />
      <line
        x1={71.59}
        y1={432.65}
        x2={71.59}
        y2={438.06}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "71.59px 435.355px",
        }}
        id="elawwbk06l16"
        className="animable"
      />
      <line
        x1={73.31}
        y1={432.65}
        x2={73.31}
        y2={438.06}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "73.31px 435.355px",
        }}
        id="elk9d3c8nkah"
        className="animable"
      />
      <line
        x1={75.02}
        y1={432.65}
        x2={75.02}
        y2={438.06}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "75.02px 435.355px",
        }}
        id="el8xqhjosp4fg"
        className="animable"
      />
      <line
        x1={68.16}
        y1={408.75}
        x2={68.16}
        y2={414.16}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "68.16px 411.455px",
        }}
        id="elfv9cjnwuly"
        className="animable"
      />
      <line
        x1={69.88}
        y1={408.75}
        x2={69.88}
        y2={414.16}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "69.88px 411.455px",
        }}
        id="el64ue3chwnhl"
        className="animable"
      />
      <line
        x1={71.59}
        y1={408.75}
        x2={71.59}
        y2={414.16}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "71.59px 411.455px",
        }}
        id="elxuxx3h0mb8c"
        className="animable"
      />
      <line
        x1={73.31}
        y1={408.75}
        x2={73.31}
        y2={414.16}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "73.31px 411.455px",
        }}
        id="elgy8jxgs4pi7"
        className="animable"
      />
      <line
        x1={75.02}
        y1={408.75}
        x2={75.02}
        y2={414.16}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "75.02px 411.455px",
        }}
        id="elae2k53l7bi"
        className="animable"
      />
      <line
        x1={68.16}
        y1={384.85}
        x2={68.16}
        y2={390.26}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "68.16px 387.555px",
        }}
        id="elvqw95zrxew"
        className="animable"
      />
      <line
        x1={69.88}
        y1={384.85}
        x2={69.88}
        y2={390.26}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "69.88px 387.555px",
        }}
        id="elf25w8ybgo4i"
        className="animable"
      />
      <line
        x1={71.59}
        y1={384.85}
        x2={71.59}
        y2={390.26}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "71.59px 387.555px",
        }}
        id="elh55coz5enbn"
        className="animable"
      />
      <line
        x1={73.31}
        y1={384.85}
        x2={73.31}
        y2={390.26}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "73.31px 387.555px",
        }}
        id="elmi4b44qz4ge"
        className="animable"
      />
      <line
        x1={75.02}
        y1={384.85}
        x2={75.02}
        y2={390.26}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "75.02px 387.555px",
        }}
        id="el4wf6odgeein"
        className="animable"
      />
      <line
        x1={68.16}
        y1={360.95}
        x2={68.16}
        y2={366.36}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "68.16px 363.655px",
        }}
        id="elgipv3yz64a"
        className="animable"
      />
      <line
        x1={69.88}
        y1={360.95}
        x2={69.88}
        y2={366.36}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "69.88px 363.655px",
        }}
        id="elgwp6l9e8ey"
        className="animable"
      />
      <line
        x1={71.59}
        y1={360.95}
        x2={71.59}
        y2={366.36}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "71.59px 363.655px",
        }}
        id="elcldls4zcmrw"
        className="animable"
      />
      <line
        x1={73.31}
        y1={360.95}
        x2={73.31}
        y2={366.36}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "73.31px 363.655px",
        }}
        id="elyqhy998bld"
        className="animable"
      />
      <line
        x1={75.02}
        y1={360.95}
        x2={75.02}
        y2={366.36}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "75.02px 363.655px",
        }}
        id="el0kgbtwpj53ys"
        className="animable"
      />
      <line
        x1={68.16}
        y1={337.05}
        x2={68.16}
        y2={342.46}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "68.16px 339.755px",
        }}
        id="el3er3exjlfjh"
        className="animable"
      />
      <line
        x1={69.88}
        y1={337.05}
        x2={69.88}
        y2={342.46}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "69.88px 339.755px",
        }}
        id="el2mhi4hgsqjt"
        className="animable"
      />
      <line
        x1={71.59}
        y1={337.05}
        x2={71.59}
        y2={342.46}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "71.59px 339.755px",
        }}
        id="ela9ei0nux2sh"
        className="animable"
      />
      <line
        x1={73.31}
        y1={337.05}
        x2={73.31}
        y2={342.46}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "73.31px 339.755px",
        }}
        id="ellpr7nxtfksr"
        className="animable"
      />
      <line
        x1={75.02}
        y1={337.05}
        x2={75.02}
        y2={342.46}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "75.02px 339.755px",
        }}
        id="elfaqp6lpaoy4"
        className="animable"
      />
      <line
        x1={68.16}
        y1={313.15}
        x2={68.16}
        y2={318.56}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "68.16px 315.855px",
        }}
        id="elyxedzkwhg5p"
        className="animable"
      />
      <line
        x1={69.88}
        y1={313.15}
        x2={69.88}
        y2={318.56}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "69.88px 315.855px",
        }}
        id="el79zdvmfuv5n"
        className="animable"
      />
      <line
        x1={71.59}
        y1={313.15}
        x2={71.59}
        y2={318.56}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "71.59px 315.855px",
        }}
        id="elmoffhaapesb"
        className="animable"
      />
      <line
        x1={73.31}
        y1={313.15}
        x2={73.31}
        y2={318.56}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "73.31px 315.855px",
        }}
        id="elezghqh1o2xc"
        className="animable"
      />
      <line
        x1={75.02}
        y1={313.15}
        x2={75.02}
        y2={318.56}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "75.02px 315.855px",
        }}
        id="elgzuoaupp5ra"
        className="animable"
      />
      <line
        x1={68.16}
        y1={289.25}
        x2={68.16}
        y2={294.66}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "68.16px 291.955px",
        }}
        id="elm9auleegj4h"
        className="animable"
      />
      <line
        x1={69.88}
        y1={289.25}
        x2={69.88}
        y2={294.66}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "69.88px 291.955px",
        }}
        id="eljb84bgfrtj"
        className="animable"
      />
      <line
        x1={71.59}
        y1={289.25}
        x2={71.59}
        y2={294.66}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "71.59px 291.955px",
        }}
        id="elf1hnt6gyo3"
        className="animable"
      />
      <line
        x1={73.31}
        y1={289.25}
        x2={73.31}
        y2={294.66}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "73.31px 291.955px",
        }}
        id="elxlfxhiwywif"
        className="animable"
      />
      <line
        x1={75.02}
        y1={289.25}
        x2={75.02}
        y2={294.66}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "75.02px 291.955px",
        }}
        id="eljdla3l23kis"
        className="animable"
      />
      <line
        x1={68.16}
        y1={265.35}
        x2={68.16}
        y2={270.76}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "68.16px 268.055px",
        }}
        id="el41mirfe7dx8"
        className="animable"
      />
      <line
        x1={69.88}
        y1={265.35}
        x2={69.88}
        y2={270.76}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "69.88px 268.055px",
        }}
        id="el50pyb2ptijv"
        className="animable"
      />
      <line
        x1={71.59}
        y1={265.35}
        x2={71.59}
        y2={270.76}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "71.59px 268.055px",
        }}
        id="eli40v1530oc"
        className="animable"
      />
      <line
        x1={73.31}
        y1={265.35}
        x2={73.31}
        y2={270.76}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "73.31px 268.055px",
        }}
        id="el5db7vfrbpsj"
        className="animable"
      />
      <line
        x1={75.02}
        y1={265.35}
        x2={75.02}
        y2={270.76}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "75.02px 268.055px",
        }}
        id="eltw28llns6ik"
        className="animable"
      />
      <line
        x1={68.16}
        y1={241.45}
        x2={68.16}
        y2={246.86}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "68.16px 244.155px",
        }}
        id="el7c316a7j796"
        className="animable"
      />
      <line
        x1={69.88}
        y1={241.45}
        x2={69.88}
        y2={246.86}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "69.88px 244.155px",
        }}
        id="el5zctr0gn8j5"
        className="animable"
      />
      <line
        x1={71.59}
        y1={241.45}
        x2={71.59}
        y2={246.86}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "71.59px 244.155px",
        }}
        id="elsjl4f2j954"
        className="animable"
      />
      <line
        x1={73.31}
        y1={241.45}
        x2={73.31}
        y2={246.86}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "73.31px 244.155px",
        }}
        id="elej9xlcd7sa9"
        className="animable"
      />
      <line
        x1={75.02}
        y1={241.45}
        x2={75.02}
        y2={246.86}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "75.02px 244.155px",
        }}
        id="eli9k4t2p15bm"
        className="animable"
      />
      <line
        x1={68.16}
        y1={217.55}
        x2={68.16}
        y2={222.96}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "68.16px 220.255px",
        }}
        id="el3r9x4sesqjy"
        className="animable"
      />
      <line
        x1={69.88}
        y1={217.55}
        x2={69.88}
        y2={222.96}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "69.88px 220.255px",
        }}
        id="eljwq8oic3c7"
        className="animable"
      />
      <line
        x1={71.59}
        y1={217.55}
        x2={71.59}
        y2={222.96}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "71.59px 220.255px",
        }}
        id="ellnxnnvmlcy8"
        className="animable"
      />
      <line
        x1={73.31}
        y1={217.55}
        x2={73.31}
        y2={222.96}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "73.31px 220.255px",
        }}
        id="elxujnri8frpk"
        className="animable"
      />
      <line
        x1={75.02}
        y1={217.55}
        x2={75.02}
        y2={222.96}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "75.02px 220.255px",
        }}
        id="eltlps4qffek"
        className="animable"
      />
    </g>
    <defs>
      <filter id="active" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter id="hover" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#ff0000" floodOpacity={0.5} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
        <feColorMatrix
          type="matrix"
          values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
        />
      </filter>
    </defs>
    <defs>
      <clipPath id="freepik--clip-path--inject-186">
        <path
          d="M390.5,442.34a4.45,4.45,0,0,1-4.45-4.45V410.54a4.44,4.44,0,0,1,4.13-4.42l1.09-2.78a1.35,1.35,0,0,1,1.26-.87h11.36a1.35,1.35,0,0,1,1.26.87l1.07,2.75h19a4.45,4.45,0,0,1,4.22,3"
          style={{
            fill: "#B0BEC5",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
      </clipPath>
      <clipPath id="freepik--clip-path-2--inject-186">
        <path
          d="M188.09,442.34a4.45,4.45,0,0,1-4.45-4.45V410.54a4.43,4.43,0,0,1,4.13-4.42l1.08-2.78a1.38,1.38,0,0,1,1.27-.87h11.36a1.35,1.35,0,0,1,1.26.87l1.07,2.75h19a4.45,4.45,0,0,1,4.22,3"
          style={{
            fill: "#B0BEC5",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
      </clipPath>
      <clipPath id="freepik--clip-path-3--inject-186">
        <path
          d="M326.34,197.12s-9.38,3.27-15.67,8.72-16.32,13.59-16.32,13.59S300,233,306.56,238.53l14-5.6Z"
          style={{
            fill: "#B0BEC5",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
      </clipPath>
      <clipPath id="freepik--clip-path-4--inject-186">
        <path
          d="M320.53,232.93c5.45,13.34,14,30,14,30l3.77,18.59s14.16,10.79,30.4,6.26c19-5.3,22.11-17.33,22.11-17.33l-7.24-14.56c-2.48-14-5.72-47-16.87-54.59s-30.29-8.2-38-5.14c-6.87,2.72-10.68,4.79-12.55,7.3"
          style={{
            fill: "#B0BEC5",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
      </clipPath>
      <clipPath id="freepik--clip-path-5--inject-186">
        <path
          d="M361.35,209.26c-1.88,6.55-2.67,13.6.46,19.09,1.57,4.7,10.7,9.18,13.83,13.1,9.83-2.4,14-14.88,14-14.88S384,218.5,378.26,210s-17.08-11.65-17.08-11.65"
          style={{
            fill: "#B0BEC5",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
      </clipPath>
      <clipPath id="freepik--clip-path-6--inject-186">
        <path
          d="M368.85,297.83a4.45,4.45,0,0,1-4.79-4.08l-2.2-27.27a4.45,4.45,0,0,1,3.76-4.74l.86-2.85a1.36,1.36,0,0,1,1.19-1L379,257a1.35,1.35,0,0,1,1.33.76l1.29,2.65,19-1.52a4.46,4.46,0,0,1,4.45,2.67"
          style={{
            fill: "#B0BEC5",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;

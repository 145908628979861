import * as React from "react";
import "./ProductsSvg.css";
const SvgComponent = () => (
  <svg
    className="animated"
    id="freepik_stories-switches"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlnssvgjs="http://svgjs.com/svgjs"
  >
    <g
      id="freepik--background-complete--inject-2"
      className="animable"
      style={{
        transformOrigin: "250px 228.23px",
      }}
    >
      <rect
        y={382.4}
        width={500}
        height={0.25}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "250px 382.525px",
        }}
        id="elygk5k2pf5uh"
        className="animable"
      />
      <rect
        x={416.78}
        y={398.49}
        width={33.12}
        height={0.25}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "433.34px 398.615px",
        }}
        id="el8v4mqic4r7l"
        className="animable"
      />
      <rect
        x={322.53}
        y={401.21}
        width={8.69}
        height={0.25}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "326.875px 401.335px",
        }}
        id="elygzm1dxontj"
        className="animable"
      />
      <rect
        x={396.59}
        y={389.21}
        width={19.19}
        height={0.25}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "406.185px 389.335px",
        }}
        id="elq1ll7f0y31p"
        className="animable"
      />
      <rect
        x={52.46}
        y={390.89}
        width={43.19}
        height={0.25}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "74.055px 391.015px",
        }}
        id="el111essver96k"
        className="animable"
      />
      <rect
        x={104.56}
        y={390.89}
        width={6.33}
        height={0.25}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "107.725px 391.015px",
        }}
        id="el2k8krffx6tq"
        className="animable"
      />
      <rect
        x={131.47}
        y={395.11}
        width={93.68}
        height={0.25}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "178.31px 395.235px",
        }}
        id="elutx5yq651s"
        className="animable"
      />
      <path
        d="M237,337.8H43.91a5.71,5.71,0,0,1-5.7-5.71V60.66A5.71,5.71,0,0,1,43.91,55H237a5.71,5.71,0,0,1,5.71,5.71V332.09A5.71,5.71,0,0,1,237,337.8ZM43.91,55.2a5.46,5.46,0,0,0-5.45,5.46V332.09a5.46,5.46,0,0,0,5.45,5.46H237a5.47,5.47,0,0,0,5.46-5.46V60.66A5.47,5.47,0,0,0,237,55.2Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "140.46px 196.4px",
        }}
        id="eljlr19nii85i"
        className="animable"
      />
      <path
        d="M453.31,337.8H260.21a5.72,5.72,0,0,1-5.71-5.71V60.66A5.72,5.72,0,0,1,260.21,55h193.1A5.71,5.71,0,0,1,459,60.66V332.09A5.71,5.71,0,0,1,453.31,337.8ZM260.21,55.2a5.47,5.47,0,0,0-5.46,5.46V332.09a5.47,5.47,0,0,0,5.46,5.46h193.1a5.47,5.47,0,0,0,5.46-5.46V60.66a5.47,5.47,0,0,0-5.46-5.46Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "356.75px 196.4px",
        }}
        id="elfn70hjo81vt"
        className="animable"
      />
      <path
        d="M169.47,375.54c-.88,0-1.76,0-2.66-.06l.16-3.27c47.33,2.31,61-62.89,70.1-106,3.46-16.5,6.19-29.53,10-35.13,13.82-20.34,36.65-17.27,36.88-17.24l-.47,3.24h0c-.89-.13-21.07-2.75-33.71,15.84-3.44,5.07-6.25,18.45-9.5,34C231.15,310.28,217.45,375.54,169.47,375.54Z"
        style={{
          fill: "rgb(230, 230, 230)",
          transformOrigin: "225.38px 294.599px",
        }}
        id="ela6l8e0iwgvh"
        className="animable"
      />
      <path
        d="M150.81,364.85l-.23-3.27c27.64-1.91,43.21-32.39,58.26-61.87,8.45-16.56,16.44-32.2,26.41-42.83,28.2-30.07,41.8-25.73,42.36-25.53l-1.09,3.09.55-1.55-.52,1.56c-.12,0-12.54-3.47-38.92,24.67-9.65,10.29-17.53,25.73-25.88,42.08C196.28,331.49,180.29,362.8,150.81,364.85Z"
        style={{
          fill: "rgb(230, 230, 230)",
          transformOrigin: "214.095px 297.937px",
        }}
        id="el7t5ekti3jwx"
        className="animable"
      />
      <path
        d="M167.06,371.06l-1-3.11c9.53-3.07,15.36-14.32,22.11-27.34,8.24-15.88,17.57-33.88,36.61-43.85C259.65,278.5,302.25,302,304,303l-1.6,2.85c-.42-.24-42.86-23.66-76.14-6.24-18.13,9.5-27.21,27-35.23,42.47C184,355.78,177.88,367.58,167.06,371.06Z"
        style={{
          fill: "rgb(230, 230, 230)",
          transformOrigin: "235.03px 330.446px",
        }}
        id="elwcz6002gui"
        className="animable"
      />
      <path
        d="M161.89,381.74l-.16-3.26c.47,0,46.63-2.46,71.7-25.8,26.19-24.39,58-15.54,58.34-15.45l-.9,3.14c-.3-.08-30.42-8.39-55.21,14.7C209.7,379.24,163.83,381.65,161.89,381.74Z"
        style={{
          fill: "rgb(230, 230, 230)",
          transformOrigin: "226.75px 358.666px",
        }}
        id="eld03kcegl1sb"
        className="animable"
      />
      <rect
        x={274.88}
        y={180.13}
        width={130.25}
        height={201.54}
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "340.005px 280.9px",
        }}
        id="ele0uv4ubfl8q"
        className="animable"
      />
      <rect
        x={270.82}
        y={180.13}
        width={106.37}
        height={201.54}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "324.005px 280.9px",
        }}
        id="elf3w8oz3sqka"
        className="animable"
      />
      <rect
        x={274.26}
        y={182.72}
        width={99.48}
        height={198.94}
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "324px 282.19px",
        }}
        id="elxta3xex287f"
        className="animable"
      />
      <rect
        x={289.72}
        y={365.89}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.005px 370.965px",
        }}
        id="elaiycahx3o1k"
        className="animable"
      />
      <rect
        x={289.72}
        y={365.89}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "307.005px 370.965px",
        }}
        id="el7avbr2nih7d"
        className="animable"
      />
      <rect
        x={277.94}
        y={365.89}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "295.225px 370.965px",
        }}
        id="elmtb03hksgxd"
        className="animable"
      />
      <path
        d="M309.1,371a2.13,2.13,0,1,1-2.13-2.14A2.13,2.13,0,0,1,309.1,371Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "306.97px 370.99px",
        }}
        id="elmx5qzz6g4cp"
        className="animable"
      />
      <path
        d="M308.05,371a1.09,1.09,0,0,1-2.17,0,1.09,1.09,0,1,1,2.17,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "306.965px 370.896px",
        }}
        id="elt59v46044f9"
        className="animable"
      />
      <rect
        x={299.97}
        y={369.75}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "301.185px 370.965px",
        }}
        id="el8s4jyfphalf"
        className="animable"
      />
      <rect
        x={289.72}
        y={354.81}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.005px 359.885px",
        }}
        id="elkzyhvtqbgfb"
        className="animable"
      />
      <rect
        x={289.72}
        y={354.81}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "307.005px 359.885px",
        }}
        id="elsfpi251p2z"
        className="animable"
      />
      <rect
        x={277.94}
        y={354.81}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "295.225px 359.885px",
        }}
        id="elgzfwlcb011c"
        className="animable"
      />
      <path
        d="M309.1,359.88a2.13,2.13,0,1,1-2.13-2.13A2.13,2.13,0,0,1,309.1,359.88Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "306.97px 359.88px",
        }}
        id="el3pfdkdjelhz"
        className="animable"
      />
      <path
        d="M308.05,359.88A1.09,1.09,0,1,1,307,358.8,1.08,1.08,0,0,1,308.05,359.88Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "306.96px 359.889px",
        }}
        id="elpy8y3yai8ve"
        className="animable"
      />
      <rect
        x={299.97}
        y={358.67}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "301.185px 359.885px",
        }}
        id="elkatc04qoem"
        className="animable"
      />
      <rect
        x={289.72}
        y={343.73}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.005px 348.805px",
        }}
        id="elipdama0ex1f"
        className="animable"
      />
      <rect
        x={289.72}
        y={343.73}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "307.005px 348.805px",
        }}
        id="elny7h9gm1x2p"
        className="animable"
      />
      <rect
        x={277.94}
        y={343.73}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "295.225px 348.805px",
        }}
        id="el1e2kmczga7w"
        className="animable"
      />
      <path
        d="M309.1,348.8a2.13,2.13,0,1,1-2.13-2.13A2.13,2.13,0,0,1,309.1,348.8Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "306.97px 348.8px",
        }}
        id="elyqjnfa7o65r"
        className="animable"
      />
      <path
        d="M308.05,348.8a1.09,1.09,0,1,1-1.08-1.08A1.08,1.08,0,0,1,308.05,348.8Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "306.96px 348.81px",
        }}
        id="elhoti5qzyx6w"
        className="animable"
      />
      <rect
        x={299.97}
        y={347.59}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "301.185px 348.805px",
        }}
        id="elgps28gpgirp"
        className="animable"
      />
      <rect
        x={289.72}
        y={332.64}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.005px 337.715px",
        }}
        id="eluly7pt0m4ra"
        className="animable"
      />
      <rect
        x={289.72}
        y={332.64}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "307.005px 337.715px",
        }}
        id="elkg451r47a2"
        className="animable"
      />
      <rect
        x={277.94}
        y={332.64}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "295.225px 337.715px",
        }}
        id="elz3jgowl8ve"
        className="animable"
      />
      <path
        d="M309.1,337.72a2.13,2.13,0,1,1-2.13-2.14A2.13,2.13,0,0,1,309.1,337.72Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "306.97px 337.71px",
        }}
        id="elxs8gbocwqhe"
        className="animable"
      />
      <path
        d="M308.05,337.72a1.09,1.09,0,0,1-2.17,0,1.09,1.09,0,1,1,2.17,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "306.965px 337.616px",
        }}
        id="ele9mcuxwnwsw"
        className="animable"
      />
      <rect
        x={299.97}
        y={336.5}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "301.185px 337.715px",
        }}
        id="elfsp223cltb7"
        className="animable"
      />
      <rect
        x={289.79}
        y={321.56}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.075px 326.635px",
        }}
        id="elhmfz9l1d8qa"
        className="animable"
      />
      <rect
        x={289.79}
        y={321.56}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "307.075px 326.635px",
        }}
        id="el0a2yy4o3dooo"
        className="animable"
      />
      <rect
        x={278.01}
        y={321.56}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "295.295px 326.635px",
        }}
        id="el6ib25p53nac"
        className="animable"
      />
      <circle
        cx={307.04}
        cy={326.63}
        r={2.13}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "307.04px 326.63px",
        }}
        id="el6gw4fcwsojk"
        className="animable"
      />
      <path
        d="M308.12,326.63a1.09,1.09,0,1,1-1.08-1.08A1.08,1.08,0,0,1,308.12,326.63Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.03px 326.64px",
        }}
        id="elraikq1h7g2m"
        className="animable"
      />
      <rect
        x={300.04}
        y={325.42}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "301.255px 326.635px",
        }}
        id="eliorco9lrcmd"
        className="animable"
      />
      <rect
        x={289.86}
        y={310.48}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.145px 315.555px",
        }}
        id="elb7squmsiyxk"
        className="animable"
      />
      <rect
        x={289.86}
        y={310.48}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "307.145px 315.555px",
        }}
        id="ela9x07wp1wqs"
        className="animable"
      />
      <rect
        x={278.08}
        y={310.48}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "295.365px 315.555px",
        }}
        id="el165v8pvxeli"
        className="animable"
      />
      <path
        d="M309.24,315.55a2.13,2.13,0,1,1-2.13-2.13A2.13,2.13,0,0,1,309.24,315.55Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "307.11px 315.55px",
        }}
        id="el7bpl5y2z18q"
        className="animable"
      />
      <path
        d="M308.19,315.55a1.09,1.09,0,1,1-1.08-1.08A1.08,1.08,0,0,1,308.19,315.55Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.1px 315.56px",
        }}
        id="elfqdxywtmhc"
        className="animable"
      />
      <rect
        x={300.11}
        y={314.34}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "301.325px 315.555px",
        }}
        id="elykch8dvi8g"
        className="animable"
      />
      <rect
        x={289.93}
        y={299.39}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.215px 304.465px",
        }}
        id="el4t2firupdio"
        className="animable"
      />
      <rect
        x={289.93}
        y={299.39}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "307.215px 304.465px",
        }}
        id="elv8v2nh4g3b"
        className="animable"
      />
      <rect
        x={278.15}
        y={299.39}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "295.435px 304.465px",
        }}
        id="eliom22kl4ja"
        className="animable"
      />
      <path
        d="M309.31,304.47a2.14,2.14,0,1,1-2.13-2.14A2.13,2.13,0,0,1,309.31,304.47Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "307.17px 304.47px",
        }}
        id="elt66qel0fky"
        className="animable"
      />
      <path
        d="M308.26,304.47a1.09,1.09,0,0,1-2.17,0,1.09,1.09,0,1,1,2.17,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.175px 304.366px",
        }}
        id="el5jmdvlp1zk"
        className="animable"
      />
      <rect
        x={300.18}
        y={303.25}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "301.395px 304.465px",
        }}
        id="ely6yud13jf0q"
        className="animable"
      />
      <rect
        x={290}
        y={288.31}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.285px 293.385px",
        }}
        id="eloqhuk5a40qq"
        className="animable"
      />
      <rect
        x={290}
        y={288.31}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "307.285px 293.385px",
        }}
        id="elov66okiz8h"
        className="animable"
      />
      <rect
        x={278.22}
        y={288.31}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "295.505px 293.385px",
        }}
        id="eljbkcn7x9x1k"
        className="animable"
      />
      <path
        d="M309.38,293.38a2.14,2.14,0,1,1-2.13-2.13A2.13,2.13,0,0,1,309.38,293.38Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "307.24px 293.39px",
        }}
        id="elmek0angopt7"
        className="animable"
      />
      <path
        d="M308.33,293.38a1.09,1.09,0,1,1-1.08-1.08A1.08,1.08,0,0,1,308.33,293.38Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.24px 293.39px",
        }}
        id="el18ovy84kfuk"
        className="animable"
      />
      <rect
        x={300.25}
        y={292.17}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "301.465px 293.385px",
        }}
        id="elm3sfu506clg"
        className="animable"
      />
      <rect
        x={290.07}
        y={277.23}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.355px 282.305px",
        }}
        id="el83s28j3htfw"
        className="animable"
      />
      <rect
        x={290.07}
        y={277.23}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "307.355px 282.305px",
        }}
        id="el8k0lcsl2q9c"
        className="animable"
      />
      <rect
        x={278.29}
        y={277.23}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "295.575px 282.305px",
        }}
        id="elvjy50yuxmre"
        className="animable"
      />
      <path
        d="M309.45,282.3a2.14,2.14,0,1,1-2.13-2.13A2.13,2.13,0,0,1,309.45,282.3Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "307.31px 282.31px",
        }}
        id="elcdl3fgfgvtk"
        className="animable"
      />
      <circle
        cx={307.32}
        cy={282.3}
        r={1.08}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.32px 282.3px",
        }}
        id="eluis0si0qvfb"
        className="animable"
      />
      <rect
        x={300.32}
        y={281.09}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "301.535px 282.305px",
        }}
        id="el3qdiqpr2bi7"
        className="animable"
      />
      <rect
        x={290.14}
        y={266.14}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.425px 271.215px",
        }}
        id="elwz15y9eyyls"
        className="animable"
      />
      <rect
        x={290.14}
        y={266.14}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "307.425px 271.215px",
        }}
        id="elbeuhh0v3vrs"
        className="animable"
      />
      <rect
        x={278.36}
        y={266.14}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "295.645px 271.215px",
        }}
        id="el49zct4kltwj"
        className="animable"
      />
      <path
        d="M309.52,271.22a2.14,2.14,0,1,1-2.13-2.13A2.13,2.13,0,0,1,309.52,271.22Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "307.38px 271.23px",
        }}
        id="ell5sqb7tbtw"
        className="animable"
      />
      <circle
        cx={307.39}
        cy={271.22}
        r={1.08}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.39px 271.22px",
        }}
        id="eltt13y91fqli"
        className="animable"
      />
      <rect
        x={300.39}
        y={270}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "301.605px 271.215px",
        }}
        id="elwdk99i4mdsm"
        className="animable"
      />
      <rect
        x={290.21}
        y={255.06}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.495px 260.135px",
        }}
        id="elkyk7376lmp"
        className="animable"
      />
      <rect
        x={290.21}
        y={255.06}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "307.495px 260.135px",
        }}
        id="elo4xnikleud"
        className="animable"
      />
      <rect
        x={278.43}
        y={255.06}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "295.715px 260.135px",
        }}
        id="elbon1jpadh1h"
        className="animable"
      />
      <path
        d="M309.59,260.14a2.14,2.14,0,1,1-2.13-2.14A2.13,2.13,0,0,1,309.59,260.14Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "307.45px 260.14px",
        }}
        id="el2gvhoy3q41w"
        className="animable"
      />
      <path
        d="M308.54,260.14a1.09,1.09,0,1,1-1.08-1.09A1.08,1.08,0,0,1,308.54,260.14Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.45px 260.14px",
        }}
        id="elstsnabna6z"
        className="animable"
      />
      <rect
        x={300.46}
        y={258.92}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "301.675px 260.135px",
        }}
        id="elzkswt8yo7nn"
        className="animable"
      />
      <rect
        x={290.28}
        y={243.98}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.565px 249.055px",
        }}
        id="eldgirq4tzuh"
        className="animable"
      />
      <rect
        x={290.28}
        y={243.98}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "307.565px 249.055px",
        }}
        id="ellgi6u87jox"
        className="animable"
      />
      <rect
        x={278.5}
        y={243.98}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "295.785px 249.055px",
        }}
        id="ela5vvjc68of9"
        className="animable"
      />
      <path
        d="M309.66,249.05a2.14,2.14,0,1,1-2.13-2.13A2.14,2.14,0,0,1,309.66,249.05Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "307.52px 249.06px",
        }}
        id="elfxtpaw6lkyn"
        className="animable"
      />
      <path
        d="M308.61,249.05a1.09,1.09,0,1,1-1.08-1.08A1.08,1.08,0,0,1,308.61,249.05Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.52px 249.06px",
        }}
        id="elmiw07w5cytn"
        className="animable"
      />
      <rect
        x={300.53}
        y={247.84}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "301.745px 249.055px",
        }}
        id="elbgzcrnr2abd"
        className="animable"
      />
      <rect
        x={290.35}
        y={232.89}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.635px 237.965px",
        }}
        id="elq36xatjo1m"
        className="animable"
      />
      <rect
        x={290.35}
        y={232.89}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "307.635px 237.965px",
        }}
        id="elpvcd1no4jmp"
        className="animable"
      />
      <rect
        x={278.57}
        y={232.89}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "295.855px 237.965px",
        }}
        id="ela2x7u85dcwl"
        className="animable"
      />
      <path
        d="M309.73,238a2.14,2.14,0,1,1-2.13-2.13A2.13,2.13,0,0,1,309.73,238Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "307.59px 238.01px",
        }}
        id="el4nwobvxjw22"
        className="animable"
      />
      <path
        d="M308.68,238a1.09,1.09,0,0,1-2.17,0,1.09,1.09,0,1,1,2.17,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.595px 237.896px",
        }}
        id="elaui5n8io2vc"
        className="animable"
      />
      <rect
        x={300.6}
        y={236.76}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "301.815px 237.975px",
        }}
        id="el99ayfpeibz8"
        className="animable"
      />
      <rect
        x={290.42}
        y={221.81}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.705px 226.885px",
        }}
        id="elub6dry3yqzq"
        className="animable"
      />
      <rect
        x={290.42}
        y={221.81}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "307.705px 226.885px",
        }}
        id="el3qputxj6hm9"
        className="animable"
      />
      <rect
        x={278.64}
        y={221.81}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "295.925px 226.885px",
        }}
        id="el4oi439aw0c4"
        className="animable"
      />
      <circle
        cx={307.67}
        cy={226.89}
        r={2.13}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "307.67px 226.89px",
        }}
        id="elcq1772u8qw"
        className="animable"
      />
      <path
        d="M308.75,226.89a1.09,1.09,0,0,1-2.17,0,1.09,1.09,0,1,1,2.17,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.665px 226.786px",
        }}
        id="el1ublu5lo26y"
        className="animable"
      />
      <rect
        x={300.67}
        y={225.67}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "301.885px 226.885px",
        }}
        id="elbb7vukgm6hp"
        className="animable"
      />
      <rect
        x={290.49}
        y={210.73}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.775px 215.805px",
        }}
        id="elq3ftzg72k7"
        className="animable"
      />
      <rect
        x={290.49}
        y={210.73}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "307.775px 215.805px",
        }}
        id="elorjbus93rht"
        className="animable"
      />
      <rect
        x={278.71}
        y={210.73}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "295.995px 215.805px",
        }}
        id="elv9oeejaxjk"
        className="animable"
      />
      <circle
        cx={307.74}
        cy={215.8}
        r={2.13}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "307.74px 215.8px",
        }}
        id="el9a37l23ce2g"
        className="animable"
      />
      <path
        d="M308.82,215.8a1.09,1.09,0,1,1-1.08-1.08A1.08,1.08,0,0,1,308.82,215.8Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.73px 215.81px",
        }}
        id="elylndom3c7x9"
        className="animable"
      />
      <rect
        x={300.74}
        y={214.59}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "301.955px 215.805px",
        }}
        id="elabzpr0c34zd"
        className="animable"
      />
      <rect
        x={290.56}
        y={199.64}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.845px 204.715px",
        }}
        id="elm0fsrayjjnh"
        className="animable"
      />
      <rect
        x={290.56}
        y={199.64}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "307.845px 204.715px",
        }}
        id="elpdnezgjt8vh"
        className="animable"
      />
      <rect
        x={278.78}
        y={199.64}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "296.065px 204.715px",
        }}
        id="el1c7k3gfirp4"
        className="animable"
      />
      <path
        d="M309.94,204.72a2.14,2.14,0,1,1-2.13-2.13A2.13,2.13,0,0,1,309.94,204.72Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "307.8px 204.73px",
        }}
        id="elmxheuutsj7"
        className="animable"
      />
      <path
        d="M308.89,204.72a1.09,1.09,0,0,1-2.17,0,1.09,1.09,0,1,1,2.17,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.805px 204.616px",
        }}
        id="eln55q9hdeh39"
        className="animable"
      />
      <rect
        x={300.81}
        y={203.51}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "302.025px 204.725px",
        }}
        id="el9b2bmllxj6a"
        className="animable"
      />
      <rect
        x={290.63}
        y={188.56}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.915px 193.635px",
        }}
        id="el7nbpm3rjeoc"
        className="animable"
      />
      <rect
        x={290.63}
        y={188.56}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "307.915px 193.635px",
        }}
        id="eloiwx5ggle0o"
        className="animable"
      />
      <rect
        x={278.85}
        y={188.56}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "296.135px 193.635px",
        }}
        id="elf0mfmtl6uw5"
        className="animable"
      />
      <path
        d="M310,193.64a2.14,2.14,0,1,1-2.13-2.14A2.14,2.14,0,0,1,310,193.64Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "307.86px 193.64px",
        }}
        id="el3vso49utd8l"
        className="animable"
      />
      <path
        d="M309,193.64a1.09,1.09,0,0,1-2.17,0,1.09,1.09,0,1,1,2.17,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "307.915px 193.536px",
        }}
        id="elqo9h7comq5"
        className="animable"
      />
      <rect
        x={300.88}
        y={192.42}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "302.095px 193.635px",
        }}
        id="elmhcqwfu3bz"
        className="animable"
      />
      <rect
        x={332.57}
        y={365.89}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "349.855px 370.965px",
        }}
        id="elkf4vb3dd95f"
        className="animable"
      />
      <rect
        x={332.57}
        y={365.89}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "349.855px 370.965px",
        }}
        id="el3acxturn0kc"
        className="animable"
      />
      <rect
        x={320.79}
        y={365.89}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "338.075px 370.965px",
        }}
        id="el7mhbcrcur7i"
        className="animable"
      />
      <path
        d="M352,371a2.13,2.13,0,1,1-2.13-2.14A2.13,2.13,0,0,1,352,371Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "349.87px 370.99px",
        }}
        id="el3zgwcztlatr"
        className="animable"
      />
      <circle
        cx={349.82}
        cy={370.97}
        r={1.08}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "349.82px 370.97px",
        }}
        id="elzcec2az703"
        className="animable"
      />
      <rect
        x={342.82}
        y={369.75}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "344.035px 370.965px",
        }}
        id="el3jc0bq2l7mi"
        className="animable"
      />
      <rect
        x={332.57}
        y={354.81}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "349.855px 359.885px",
        }}
        id="eljiy80614k1n"
        className="animable"
      />
      <rect
        x={332.57}
        y={354.81}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "349.855px 359.885px",
        }}
        id="el0x9qy6ox1wi"
        className="animable"
      />
      <rect
        x={320.79}
        y={354.81}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "338.075px 359.885px",
        }}
        id="elempmvzbnpfq"
        className="animable"
      />
      <path
        d="M352,359.88a2.13,2.13,0,1,1-2.13-2.13A2.13,2.13,0,0,1,352,359.88Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "349.87px 359.88px",
        }}
        id="elamlg2voiky8"
        className="animable"
      />
      <path
        d="M350.9,359.88a1.09,1.09,0,1,1-1.08-1.08A1.08,1.08,0,0,1,350.9,359.88Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "349.81px 359.89px",
        }}
        id="eleok32ff6a1s"
        className="animable"
      />
      <rect
        x={342.82}
        y={358.67}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "344.035px 359.885px",
        }}
        id="eldr1w30y2m9k"
        className="animable"
      />
      <rect
        x={332.57}
        y={343.73}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "349.855px 348.805px",
        }}
        id="elyhwu4w2cwa"
        className="animable"
      />
      <rect
        x={332.57}
        y={343.73}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "349.855px 348.805px",
        }}
        id="elxqgu49erolf"
        className="animable"
      />
      <rect
        x={320.79}
        y={343.73}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "338.075px 348.805px",
        }}
        id="elsuxblfqazte"
        className="animable"
      />
      <path
        d="M352,348.8a2.13,2.13,0,1,1-2.13-2.13A2.13,2.13,0,0,1,352,348.8Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "349.87px 348.8px",
        }}
        id="el5fbyxcmv56a"
        className="animable"
      />
      <path
        d="M350.9,348.8a1.09,1.09,0,1,1-1.08-1.08A1.08,1.08,0,0,1,350.9,348.8Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "349.81px 348.81px",
        }}
        id="elt5crwf84lrd"
        className="animable"
      />
      <rect
        x={342.82}
        y={347.59}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "344.035px 348.805px",
        }}
        id="el1d0wmxegtos"
        className="animable"
      />
      <rect
        x={332.57}
        y={332.64}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "349.855px 337.715px",
        }}
        id="elgiut22shcxp"
        className="animable"
      />
      <rect
        x={332.57}
        y={332.64}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "349.855px 337.715px",
        }}
        id="elfqa15sx891m"
        className="animable"
      />
      <rect
        x={320.79}
        y={332.64}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "338.075px 337.715px",
        }}
        id="el4s5f2xevlvv"
        className="animable"
      />
      <path
        d="M352,337.72a2.13,2.13,0,1,1-2.13-2.14A2.13,2.13,0,0,1,352,337.72Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "349.87px 337.71px",
        }}
        id="elmmywul9o0ne"
        className="animable"
      />
      <path
        d="M350.9,337.72a1.09,1.09,0,0,1-2.17,0,1.09,1.09,0,1,1,2.17,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "349.815px 337.616px",
        }}
        id="elag5ssvn0rfc"
        className="animable"
      />
      <rect
        x={342.82}
        y={336.5}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "344.035px 337.715px",
        }}
        id="elxnwcvdfltk8"
        className="animable"
      />
      <rect
        x={332.64}
        y={321.56}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "349.925px 326.635px",
        }}
        id="el51kqbir5cji"
        className="animable"
      />
      <rect
        x={332.64}
        y={321.56}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "349.925px 326.635px",
        }}
        id="elbccim5isb1c"
        className="animable"
      />
      <rect
        x={320.86}
        y={321.56}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "338.145px 326.635px",
        }}
        id="elg0rivi7zolb"
        className="animable"
      />
      <path
        d="M352,326.63a2.13,2.13,0,1,1-2.13-2.13A2.13,2.13,0,0,1,352,326.63Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "349.87px 326.63px",
        }}
        id="el48aenkjrnhy"
        className="animable"
      />
      <circle
        cx={349.89}
        cy={326.63}
        r={1.08}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "349.89px 326.63px",
        }}
        id="eljkcac1h6oic"
        className="animable"
      />
      <rect
        x={342.89}
        y={325.42}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "344.105px 326.635px",
        }}
        id="eltud0obqqd7"
        className="animable"
      />
      <rect
        x={332.71}
        y={310.48}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "349.995px 315.555px",
        }}
        id="elwwkggk2yau"
        className="animable"
      />
      <rect
        x={332.71}
        y={310.48}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "349.995px 315.555px",
        }}
        id="eld2a33iou0do"
        className="animable"
      />
      <rect
        x={320.93}
        y={310.48}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "338.215px 315.555px",
        }}
        id="elryzhtwfl5w"
        className="animable"
      />
      <path
        d="M352.09,315.55a2.13,2.13,0,1,1-2.13-2.13A2.13,2.13,0,0,1,352.09,315.55Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "349.96px 315.55px",
        }}
        id="elwy3qul6ykn"
        className="animable"
      />
      <path
        d="M351,315.55a1.09,1.09,0,1,1-1.08-1.08A1.08,1.08,0,0,1,351,315.55Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "349.91px 315.56px",
        }}
        id="el54un0axdqa"
        className="animable"
      />
      <rect
        x={342.96}
        y={314.34}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "344.175px 315.555px",
        }}
        id="elwwdxa624di"
        className="animable"
      />
      <rect
        x={332.78}
        y={299.39}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.065px 304.465px",
        }}
        id="el7ihxrjkcz2q"
        className="animable"
      />
      <rect
        x={332.78}
        y={299.39}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "350.065px 304.465px",
        }}
        id="eldbchco769c7"
        className="animable"
      />
      <rect
        x={321}
        y={299.39}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "338.285px 304.465px",
        }}
        id="elvo13iugcdue"
        className="animable"
      />
      <path
        d="M352.16,304.47a2.13,2.13,0,1,1-2.13-2.14A2.13,2.13,0,0,1,352.16,304.47Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "350.03px 304.46px",
        }}
        id="elolzmaedpsl"
        className="animable"
      />
      <path
        d="M351.11,304.47a1.09,1.09,0,0,1-2.17,0,1.09,1.09,0,1,1,2.17,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.025px 304.366px",
        }}
        id="el9axt76o8o4m"
        className="animable"
      />
      <rect
        x={343.03}
        y={303.25}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "344.245px 304.465px",
        }}
        id="elxzdsh50m91n"
        className="animable"
      />
      <rect
        x={332.85}
        y={288.31}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.135px 293.385px",
        }}
        id="elxzjzcukotnm"
        className="animable"
      />
      <rect
        x={332.85}
        y={288.31}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "350.135px 293.385px",
        }}
        id="el7q0i0utp814"
        className="animable"
      />
      <rect
        x={321.07}
        y={288.31}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "338.355px 293.385px",
        }}
        id="elug6o5ehxqf"
        className="animable"
      />
      <path
        d="M352.23,293.38a2.13,2.13,0,1,1-2.13-2.13A2.13,2.13,0,0,1,352.23,293.38Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "350.1px 293.38px",
        }}
        id="elg4hq1lsbic7"
        className="animable"
      />
      <path
        d="M351.18,293.38a1.09,1.09,0,1,1-1.08-1.08A1.08,1.08,0,0,1,351.18,293.38Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.09px 293.39px",
        }}
        id="elvd4rryhug6"
        className="animable"
      />
      <rect
        x={343.1}
        y={292.17}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "344.315px 293.385px",
        }}
        id="el0fj2u4umh76c"
        className="animable"
      />
      <rect
        x={332.92}
        y={277.23}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.205px 282.305px",
        }}
        id="el4ji3sl0vrcq"
        className="animable"
      />
      <rect
        x={332.92}
        y={277.23}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "350.205px 282.305px",
        }}
        id="elcg4ic13td9g"
        className="animable"
      />
      <rect
        x={321.14}
        y={277.23}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "338.425px 282.305px",
        }}
        id="elrsm2km13mao"
        className="animable"
      />
      <circle
        cx={350.17}
        cy={282.3}
        r={2.13}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "350.17px 282.3px",
        }}
        id="el523xxz4ewi"
        className="animable"
      />
      <circle
        cx={350.17}
        cy={282.3}
        r={1.08}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.17px 282.3px",
        }}
        id="el4gd4lrw781c"
        className="animable"
      />
      <rect
        x={343.17}
        y={281.09}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "344.385px 282.305px",
        }}
        id="elmencmmmcj39"
        className="animable"
      />
      <rect
        x={332.99}
        y={266.14}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.275px 271.215px",
        }}
        id="elwei5lc0pwu8"
        className="animable"
      />
      <rect
        x={332.99}
        y={266.14}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "350.275px 271.215px",
        }}
        id="el9y97itda9y"
        className="animable"
      />
      <rect
        x={321.21}
        y={266.14}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "338.495px 271.215px",
        }}
        id="el32jxjhe6wir"
        className="animable"
      />
      <circle
        cx={350.24}
        cy={271.22}
        r={2.13}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "350.24px 271.22px",
        }}
        id="el5j6gfqay7zu"
        className="animable"
      />
      <circle
        cx={350.24}
        cy={271.22}
        r={1.08}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.24px 271.22px",
        }}
        id="elhg0tgk5p88u"
        className="animable"
      />
      <rect
        x={343.24}
        y={270}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "344.455px 271.215px",
        }}
        id="el27q97itcuadj"
        className="animable"
      />
      <rect
        x={333.06}
        y={255.06}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.345px 260.135px",
        }}
        id="el6y2bktugs9p"
        className="animable"
      />
      <rect
        x={333.06}
        y={255.06}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "350.345px 260.135px",
        }}
        id="elziad27p5fs"
        className="animable"
      />
      <rect
        x={321.28}
        y={255.06}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "338.565px 260.135px",
        }}
        id="el7i0iojwz5x"
        className="animable"
      />
      <path
        d="M352.44,260.14a2.13,2.13,0,1,1-2.13-2.14A2.13,2.13,0,0,1,352.44,260.14Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "350.31px 260.13px",
        }}
        id="elgm552lnyv4w"
        className="animable"
      />
      <path
        d="M351.39,260.14a1.09,1.09,0,1,1-1.08-1.09A1.08,1.08,0,0,1,351.39,260.14Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.3px 260.14px",
        }}
        id="elemcu7vtsewb"
        className="animable"
      />
      <rect
        x={343.31}
        y={258.92}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "344.525px 260.135px",
        }}
        id="elp1d52u6ji9f"
        className="animable"
      />
      <rect
        x={333.13}
        y={243.98}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.415px 249.055px",
        }}
        id="elxwvyu2qez0n"
        className="animable"
      />
      <rect
        x={333.13}
        y={243.98}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "350.415px 249.055px",
        }}
        id="elfeiq6zy6nvn"
        className="animable"
      />
      <rect
        x={321.35}
        y={243.98}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "338.635px 249.055px",
        }}
        id="el4p2ah1hppr6"
        className="animable"
      />
      <path
        d="M352.51,249.05a2.13,2.13,0,1,1-2.13-2.13A2.13,2.13,0,0,1,352.51,249.05Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "350.38px 249.05px",
        }}
        id="el2ygdvmvtalc"
        className="animable"
      />
      <path
        d="M351.46,249.05a1.09,1.09,0,1,1-1.08-1.08A1.08,1.08,0,0,1,351.46,249.05Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.37px 249.06px",
        }}
        id="el9nbze7dbzp8"
        className="animable"
      />
      <rect
        x={343.38}
        y={247.84}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "344.595px 249.055px",
        }}
        id="eljx3tzj2wb5m"
        className="animable"
      />
      <rect
        x={333.2}
        y={232.89}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.485px 237.965px",
        }}
        id="elpwif60n064"
        className="animable"
      />
      <rect
        x={333.2}
        y={232.89}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "350.485px 237.965px",
        }}
        id="elo653fmytqzd"
        className="animable"
      />
      <rect
        x={321.42}
        y={232.89}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "338.705px 237.965px",
        }}
        id="elsrl8ixaay59"
        className="animable"
      />
      <path
        d="M352.58,238a2.14,2.14,0,1,1-2.13-2.13A2.13,2.13,0,0,1,352.58,238Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "350.44px 238.01px",
        }}
        id="el16ei2gd865f"
        className="animable"
      />
      <path
        d="M351.53,238a1.09,1.09,0,0,1-2.17,0,1.09,1.09,0,1,1,2.17,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.445px 237.896px",
        }}
        id="elc1voq87klc5"
        className="animable"
      />
      <rect
        x={343.45}
        y={236.76}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "344.665px 237.975px",
        }}
        id="elrz94g9zdyb"
        className="animable"
      />
      <rect
        x={333.27}
        y={221.81}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.555px 226.885px",
        }}
        id="elrk5fx2ihs6t"
        className="animable"
      />
      <rect
        x={333.27}
        y={221.81}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "350.555px 226.885px",
        }}
        id="el7kbyihvpzqj"
        className="animable"
      />
      <rect
        x={321.49}
        y={221.81}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "338.775px 226.885px",
        }}
        id="elupr1jj618ob"
        className="animable"
      />
      <path
        d="M352.65,226.89a2.14,2.14,0,1,1-2.13-2.14A2.13,2.13,0,0,1,352.65,226.89Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "350.51px 226.89px",
        }}
        id="elv04ybwd25j"
        className="animable"
      />
      <path
        d="M351.6,226.89a1.09,1.09,0,0,1-2.17,0,1.09,1.09,0,1,1,2.17,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.515px 226.786px",
        }}
        id="elez8jwj7we8m"
        className="animable"
      />
      <rect
        x={343.52}
        y={225.67}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "344.735px 226.885px",
        }}
        id="elxiypbm9eiks"
        className="animable"
      />
      <rect
        x={333.34}
        y={210.73}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.625px 215.805px",
        }}
        id="ela89so8sikwr"
        className="animable"
      />
      <rect
        x={333.34}
        y={210.73}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "350.625px 215.805px",
        }}
        id="el40vjpr4ern4"
        className="animable"
      />
      <rect
        x={321.56}
        y={210.73}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "338.845px 215.805px",
        }}
        id="elgwh5v1d88s"
        className="animable"
      />
      <path
        d="M352.72,215.8a2.14,2.14,0,1,1-2.13-2.13A2.13,2.13,0,0,1,352.72,215.8Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "350.58px 215.81px",
        }}
        id="elyao12ehk5nd"
        className="animable"
      />
      <path
        d="M351.67,215.8a1.09,1.09,0,1,1-1.08-1.08A1.08,1.08,0,0,1,351.67,215.8Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.58px 215.81px",
        }}
        id="elfi3tdn7o0s"
        className="animable"
      />
      <rect
        x={343.59}
        y={214.59}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "344.805px 215.805px",
        }}
        id="elxjxydhwgt7"
        className="animable"
      />
      <rect
        x={333.41}
        y={199.64}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.695px 204.715px",
        }}
        id="el14l4mtdlhme"
        className="animable"
      />
      <rect
        x={333.41}
        y={199.64}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "350.695px 204.715px",
        }}
        id="elxz96onpe458"
        className="animable"
      />
      <rect
        x={321.63}
        y={199.64}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "338.915px 204.715px",
        }}
        id="elfn7j6d61xbt"
        className="animable"
      />
      <path
        d="M352.79,204.72a2.14,2.14,0,1,1-2.13-2.13A2.13,2.13,0,0,1,352.79,204.72Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "350.65px 204.73px",
        }}
        id="elgtww2a7b0a6"
        className="animable"
      />
      <path
        d="M351.74,204.72a1.09,1.09,0,0,1-2.17,0,1.09,1.09,0,1,1,2.17,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.655px 204.616px",
        }}
        id="el50ci2cgd9yf"
        className="animable"
      />
      <rect
        x={343.66}
        y={203.51}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "344.875px 204.725px",
        }}
        id="el7fzclbv996u"
        className="animable"
      />
      <rect
        x={333.48}
        y={188.56}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.765px 193.635px",
        }}
        id="elkz7h0d7rmbk"
        className="animable"
      />
      <rect
        x={333.48}
        y={188.56}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(230, 230, 230)",
          isolation: "isolate",
          transformOrigin: "350.765px 193.635px",
        }}
        id="elind6s8nax6h"
        className="animable"
      />
      <rect
        x={321.7}
        y={188.56}
        width={34.57}
        height={10.15}
        rx={1.65}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "338.985px 193.635px",
        }}
        id="elvqe89bvolbp"
        className="animable"
      />
      <path
        d="M352.86,193.64a2.14,2.14,0,1,1-2.13-2.14A2.13,2.13,0,0,1,352.86,193.64Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "350.72px 193.64px",
        }}
        id="el4h05n2cpmkf"
        className="animable"
      />
      <path
        d="M351.81,193.64a1.09,1.09,0,0,1-2.17,0,1.09,1.09,0,1,1,2.17,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "350.725px 193.536px",
        }}
        id="elgks4rqt77e8"
        className="animable"
      />
      <rect
        x={343.73}
        y={192.42}
        width={2.43}
        height={2.43}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "344.945px 193.635px",
        }}
        id="elbliiz3f5nuw"
        className="animable"
      />
      <g id="elngjult2c0zt">
        <rect
          x={90.82}
          y={180.13}
          width={130.25}
          height={201.54}
          style={{
            fill: "rgb(224, 224, 224)",
            transformOrigin: "155.945px 280.9px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elj4csh89eiq">
        <rect
          x={118.76}
          y={180.13}
          width={106.37}
          height={201.54}
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "171.945px 280.9px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elyn2c8lbnmcp">
        <rect
          x={122.2}
          y={182.72}
          width={99.48}
          height={198.94}
          style={{
            fill: "rgb(224, 224, 224)",
            transformOrigin: "171.94px 282.19px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el9xoih42i576">
        <rect
          x={171.66}
          y={365.89}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "188.945px 370.965px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el7azwqe9mbzc">
        <rect
          x={171.66}
          y={365.89}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "188.945px 370.965px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elaq51ocy3h9">
        <rect
          x={183.43}
          y={365.89}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "200.715px 370.965px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <circle
        cx={188.98}
        cy={370.97}
        r={2.13}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "188.98px 370.97px",
        }}
        id="elv5a7jev5my"
        className="animable"
      />
      <circle
        cx={188.98}
        cy={370.97}
        r={1.08}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "188.98px 370.97px",
        }}
        id="ellwrgkcwoljt"
        className="animable"
      />
      <g id="eltyym9ibc2r">
        <rect
          x={193.55}
          y={369.75}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "194.765px 370.965px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el3nmjv9iy0fi">
        <rect
          x={171.66}
          y={354.81}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "188.945px 359.885px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el0kbo9me6t529">
        <rect
          x={171.66}
          y={354.81}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "188.945px 359.885px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eld6rmmtp4nbf">
        <rect
          x={183.43}
          y={354.81}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "200.715px 359.885px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M186.84,359.88a2.14,2.14,0,1,0,2.14-2.13A2.14,2.14,0,0,0,186.84,359.88Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "188.98px 359.89px",
        }}
        id="elb1ehpo8e0ob"
        className="animable"
      />
      <path
        d="M187.89,359.88a1.09,1.09,0,1,0,2.17,0,1.09,1.09,0,0,0-2.17,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "188.975px 359.984px",
        }}
        id="elygsma9wmk9"
        className="animable"
      />
      <g id="elxzi4ad6wz1d">
        <rect
          x={193.55}
          y={358.67}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "194.765px 359.885px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elkz7mttxwwb">
        <rect
          x={171.66}
          y={343.73}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "188.945px 348.805px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elcvfrvbi6vxl">
        <rect
          x={171.66}
          y={343.73}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "188.945px 348.805px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eliuc707ld6h">
        <rect
          x={183.43}
          y={343.73}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "200.715px 348.805px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M186.84,348.8a2.14,2.14,0,1,0,2.14-2.13A2.13,2.13,0,0,0,186.84,348.8Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "188.98px 348.81px",
        }}
        id="elfymda01tx6b"
        className="animable"
      />
      <path
        d="M187.89,348.8a1.09,1.09,0,1,0,2.17,0,1.09,1.09,0,0,0-2.17,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "188.975px 348.904px",
        }}
        id="el2iwmuyjht2l"
        className="animable"
      />
      <g id="el0qsrnh5gy9e">
        <rect
          x={193.55}
          y={347.59}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "194.765px 348.805px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elodr4zdvgy">
        <rect
          x={171.66}
          y={332.64}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "188.945px 337.715px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el8k6as8q0esa">
        <rect
          x={171.66}
          y={332.64}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "188.945px 337.715px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elteczhig0qe">
        <rect
          x={183.43}
          y={332.64}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "200.715px 337.715px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M186.84,337.72a2.14,2.14,0,1,0,2.14-2.14A2.13,2.13,0,0,0,186.84,337.72Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "188.98px 337.72px",
        }}
        id="elv9znqdn5jed"
        className="animable"
      />
      <path
        d="M187.89,337.72a1.09,1.09,0,1,0,1.09-1.09A1.08,1.08,0,0,0,187.89,337.72Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "188.98px 337.72px",
        }}
        id="elzeox1m6gy4"
        className="animable"
      />
      <g id="elcoggbdc8ryh">
        <rect
          x={193.55}
          y={336.5}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "194.765px 337.715px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elwp5vr0n0jyk">
        <rect
          x={171.59}
          y={321.56}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "188.875px 326.635px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elkxzsd4dn6l">
        <rect
          x={171.59}
          y={321.56}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "188.875px 326.635px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elkhs5mf8x40r">
        <rect
          x={183.36}
          y={321.56}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "200.645px 326.635px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <circle
        cx={188.91}
        cy={326.63}
        r={2.13}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "188.91px 326.63px",
        }}
        id="elk0cya1lxlkc"
        className="animable"
      />
      <circle
        cx={188.91}
        cy={326.63}
        r={1.08}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "188.91px 326.63px",
        }}
        id="elz4n1512t43j"
        className="animable"
      />
      <g id="elin5ofuk6sce">
        <rect
          x={193.48}
          y={325.42}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "194.695px 326.635px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el0hz3z2e3w72k">
        <rect
          x={171.52}
          y={310.48}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "188.805px 315.555px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="ellf2bwtk8pzq">
        <rect
          x={171.52}
          y={310.48}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "188.805px 315.555px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elyvua6d0hne">
        <rect
          x={183.29}
          y={310.48}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "200.575px 315.555px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M186.7,315.55a2.14,2.14,0,1,0,2.14-2.13A2.13,2.13,0,0,0,186.7,315.55Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "188.84px 315.56px",
        }}
        id="elnueyj0b4tcg"
        className="animable"
      />
      <circle
        cx={188.84}
        cy={315.55}
        r={1.08}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "188.84px 315.55px",
        }}
        id="elbn9uo06mi5v"
        className="animable"
      />
      <g id="el0me7c0zytrwa">
        <rect
          x={193.41}
          y={314.34}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "194.625px 315.555px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elsqkwlhpgpzo">
        <rect
          x={171.45}
          y={299.39}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "188.735px 304.465px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elpcj1kei7s1">
        <rect
          x={171.45}
          y={299.39}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "188.735px 304.465px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el9i7v2rn9f1u">
        <rect
          x={183.22}
          y={299.39}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "200.505px 304.465px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M186.64,304.47a2.13,2.13,0,1,0,2.13-2.14A2.13,2.13,0,0,0,186.64,304.47Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "188.77px 304.46px",
        }}
        id="eloy451zwmt2r"
        className="animable"
      />
      <path
        d="M187.68,304.47a1.09,1.09,0,1,0,1.09-1.09A1.08,1.08,0,0,0,187.68,304.47Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "188.77px 304.47px",
        }}
        id="ellc8lmvwwul"
        className="animable"
      />
      <g id="elhhn5mzgkmm6">
        <rect
          x={193.34}
          y={303.25}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "194.555px 304.465px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elerizuntvfri">
        <rect
          x={171.38}
          y={288.31}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "188.665px 293.385px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="ellbtrgfnq61">
        <rect
          x={171.38}
          y={288.31}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "188.665px 293.385px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elu52qx2x17og">
        <rect
          x={183.15}
          y={288.31}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "200.435px 293.385px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M186.57,293.38a2.13,2.13,0,1,0,2.13-2.13A2.13,2.13,0,0,0,186.57,293.38Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "188.7px 293.38px",
        }}
        id="elhq5ro0av4yi"
        className="animable"
      />
      <path
        d="M187.61,293.38a1.09,1.09,0,1,0,1.09-1.08A1.09,1.09,0,0,0,187.61,293.38Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "188.7px 293.39px",
        }}
        id="eloxws4cqzioh"
        className="animable"
      />
      <g id="elk24flkvqboo">
        <rect
          x={193.27}
          y={292.17}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "194.485px 293.385px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elwon2p4j70qq">
        <rect
          x={171.31}
          y={277.23}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "188.595px 282.305px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elyys54cilekk">
        <rect
          x={171.31}
          y={277.23}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "188.595px 282.305px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elmmwzxa0mbzm">
        <rect
          x={183.08}
          y={277.23}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "200.365px 282.305px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M186.5,282.3a2.13,2.13,0,1,0,2.13-2.13A2.13,2.13,0,0,0,186.5,282.3Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "188.63px 282.3px",
        }}
        id="elpbivsaj4ig"
        className="animable"
      />
      <circle
        cx={188.63}
        cy={282.3}
        r={1.08}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "188.63px 282.3px",
        }}
        id="elimihf676et"
        className="animable"
      />
      <g id="eluj24iajvvqp">
        <rect
          x={193.2}
          y={281.09}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "194.415px 282.305px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elmnk5xab21v">
        <rect
          x={171.24}
          y={266.14}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "188.525px 271.215px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elygmvkyoidhj">
        <rect
          x={171.24}
          y={266.14}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "188.525px 271.215px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el9ptgybayfp5">
        <rect
          x={183.01}
          y={266.14}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "200.295px 271.215px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M186.43,271.22a2.13,2.13,0,1,0,2.13-2.13A2.13,2.13,0,0,0,186.43,271.22Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "188.56px 271.22px",
        }}
        id="elwc7ff560l8q"
        className="animable"
      />
      <path
        d="M187.47,271.22a1.09,1.09,0,1,0,1.09-1.09A1.08,1.08,0,0,0,187.47,271.22Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "188.56px 271.22px",
        }}
        id="el1ai3pghudji"
        className="animable"
      />
      <g id="eliy0hdg0myus">
        <rect
          x={193.13}
          y={270}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "194.345px 271.215px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el2x3qup2l56m">
        <rect
          x={171.17}
          y={255.06}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "188.455px 260.135px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elvfqbqt37cr">
        <rect
          x={171.17}
          y={255.06}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "188.455px 260.135px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elykbdp8kpt4">
        <rect
          x={182.94}
          y={255.06}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "200.225px 260.135px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M186.36,260.14a2.13,2.13,0,1,0,2.13-2.14A2.13,2.13,0,0,0,186.36,260.14Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "188.49px 260.13px",
        }}
        id="elqldbqehk0mo"
        className="animable"
      />
      <path
        d="M187.4,260.14a1.09,1.09,0,1,0,1.09-1.09A1.09,1.09,0,0,0,187.4,260.14Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "188.49px 260.14px",
        }}
        id="elr4x3wbbcbj"
        className="animable"
      />
      <g id="eljpexk6k6k2">
        <rect
          x={193.06}
          y={258.92}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "194.275px 260.135px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elsshfcwmodv">
        <rect
          x={171.1}
          y={243.98}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "188.385px 249.055px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elpgeebib65v">
        <rect
          x={171.1}
          y={243.98}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "188.385px 249.055px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elxz70hginlwa">
        <rect
          x={182.88}
          y={243.98}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "200.165px 249.055px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M186.29,249.05a2.13,2.13,0,1,0,2.13-2.13A2.13,2.13,0,0,0,186.29,249.05Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "188.42px 249.05px",
        }}
        id="el6lq7dkbq9"
        className="animable"
      />
      <path
        d="M187.33,249.05a1.09,1.09,0,1,0,2.17,0,1.09,1.09,0,0,0-2.17,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "188.415px 249.154px",
        }}
        id="elgn9ghp0w01q"
        className="animable"
      />
      <g id="ell4yla2mhsh">
        <rect
          x={192.99}
          y={247.84}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "194.205px 249.055px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eli433g4vfd">
        <rect
          x={171.03}
          y={232.89}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "188.315px 237.965px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elqr49ckhmeu">
        <rect
          x={171.03}
          y={232.89}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "188.315px 237.965px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elvxoaoou4urs">
        <rect
          x={182.81}
          y={232.89}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "200.095px 237.965px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M186.22,238a2.13,2.13,0,1,0,2.13-2.13A2.13,2.13,0,0,0,186.22,238Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "188.35px 238px",
        }}
        id="el0z9175wywpj"
        className="animable"
      />
      <path
        d="M187.26,238a1.09,1.09,0,1,0,1.09-1.09A1.08,1.08,0,0,0,187.26,238Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "188.35px 238px",
        }}
        id="eljk0jyryu7ng"
        className="animable"
      />
      <g id="el0ax9ohvwv2c5">
        <rect
          x={192.92}
          y={236.76}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "194.135px 237.975px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el4mly9r3pjsm">
        <rect
          x={170.96}
          y={221.81}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "188.245px 226.885px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="ela2mjqnl4g6">
        <rect
          x={170.96}
          y={221.81}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "188.245px 226.885px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el3x6duz0rjmq">
        <rect
          x={182.74}
          y={221.81}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "200.025px 226.885px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <circle
        cx={188.28}
        cy={226.89}
        r={2.13}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "188.28px 226.89px",
        }}
        id="elxppjurm48o"
        className="animable"
      />
      <circle
        cx={188.28}
        cy={226.89}
        r={1.08}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "188.28px 226.89px",
        }}
        id="elj3p0wwa0xb"
        className="animable"
      />
      <g id="elux0s9xoblgb">
        <rect
          x={192.85}
          y={225.67}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "194.065px 226.885px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elj9n8aciqurf">
        <rect
          x={170.89}
          y={210.73}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "188.175px 215.805px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el14jl4vikhxq">
        <rect
          x={170.89}
          y={210.73}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "188.175px 215.805px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elmwho0cdi89p">
        <rect
          x={182.67}
          y={210.73}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "199.955px 215.805px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M186.08,215.8a2.13,2.13,0,1,0,2.13-2.13A2.13,2.13,0,0,0,186.08,215.8Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "188.21px 215.8px",
        }}
        id="elzzv3o19pbj"
        className="animable"
      />
      <circle
        cx={188.21}
        cy={215.8}
        r={1.08}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "188.21px 215.8px",
        }}
        id="elx3wre2yo5s"
        className="animable"
      />
      <g id="elyt5yyr9drx">
        <rect
          x={192.78}
          y={214.59}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "193.995px 215.805px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elsroe1ihq419">
        <rect
          x={170.82}
          y={199.64}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "188.105px 204.715px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elptluqqwkdk">
        <rect
          x={170.82}
          y={199.64}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "188.105px 204.715px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elj0f7mx9oj4a">
        <rect
          x={182.6}
          y={199.64}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "199.885px 204.715px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M186,204.72a2.13,2.13,0,1,0,2.13-2.13A2.13,2.13,0,0,0,186,204.72Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "188.13px 204.72px",
        }}
        id="elmgcfinky2a8"
        className="animable"
      />
      <path
        d="M187.06,204.72a1.08,1.08,0,1,0,1.08-1.09A1.08,1.08,0,0,0,187.06,204.72Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "188.14px 204.71px",
        }}
        id="el6u0t1rs6obp"
        className="animable"
      />
      <g id="el58v2rwcsr6m">
        <rect
          x={192.71}
          y={203.51}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "193.925px 204.725px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el4mdznmpsc5d">
        <rect
          x={170.75}
          y={188.56}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "188.035px 193.635px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elv9siz323gf">
        <rect
          x={170.75}
          y={188.56}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "188.035px 193.635px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eliy6jgi65q3j">
        <rect
          x={182.53}
          y={188.56}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "199.815px 193.635px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M185.94,193.64a2.13,2.13,0,1,0,2.13-2.14A2.13,2.13,0,0,0,185.94,193.64Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "188.07px 193.63px",
        }}
        id="eltzy73r9evok"
        className="animable"
      />
      <path
        d="M187,193.64a1.08,1.08,0,1,0,1.08-1.09A1.08,1.08,0,0,0,187,193.64Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "188.08px 193.63px",
        }}
        id="eld748i5e41br"
        className="animable"
      />
      <g id="elrhsm9py46ap">
        <rect
          x={192.64}
          y={192.42}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "193.855px 193.635px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elynr9d34f2s">
        <rect
          x={128.81}
          y={365.89}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "146.095px 370.965px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="ellw3hpx39y8">
        <rect
          x={128.81}
          y={365.89}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "146.095px 370.965px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el2uo2kgzfk2u">
        <rect
          x={140.58}
          y={365.89}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "157.865px 370.965px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M144,371a2.14,2.14,0,1,0,2.14-2.14A2.14,2.14,0,0,0,144,371Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "146.14px 371px",
        }}
        id="elozt4xbk9qnj"
        className="animable"
      />
      <path
        d="M145,371a1.09,1.09,0,1,0,1.09-1.09A1.08,1.08,0,0,0,145,371Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "146.09px 371px",
        }}
        id="el2d5demxwa24"
        className="animable"
      />
      <g id="elgxnmbk9bo4n">
        <rect
          x={150.7}
          y={369.75}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "151.915px 370.965px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elsazy0x3d0v">
        <rect
          x={128.81}
          y={354.81}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "146.095px 359.885px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eli03kl03glmc">
        <rect
          x={128.81}
          y={354.81}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "146.095px 359.885px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elrwgtkiws9sc">
        <rect
          x={140.58}
          y={354.81}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "157.865px 359.885px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M144,359.88a2.14,2.14,0,1,0,2.14-2.13A2.14,2.14,0,0,0,144,359.88Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "146.14px 359.89px",
        }}
        id="elkrlwd801agq"
        className="animable"
      />
      <path
        d="M145,359.88a1.09,1.09,0,1,0,2.17,0,1.09,1.09,0,0,0-2.17,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "146.085px 359.984px",
        }}
        id="el3c82scd00m7"
        className="animable"
      />
      <g id="el075xz74cdefk">
        <rect
          x={150.7}
          y={358.67}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "151.915px 359.885px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elmvpgdkiv2pa">
        <rect
          x={128.81}
          y={343.73}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "146.095px 348.805px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el7f2xbgjzr1x">
        <rect
          x={128.81}
          y={343.73}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "146.095px 348.805px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elakg9oulwvxr">
        <rect
          x={140.58}
          y={343.73}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "157.865px 348.805px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M144,348.8a2.14,2.14,0,1,0,2.14-2.13A2.13,2.13,0,0,0,144,348.8Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "146.14px 348.81px",
        }}
        id="el5h2l4v3ccru"
        className="animable"
      />
      <path
        d="M145,348.8a1.09,1.09,0,1,0,2.17,0,1.09,1.09,0,0,0-2.17,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "146.085px 348.904px",
        }}
        id="elmvf2rym2mz"
        className="animable"
      />
      <g id="el1merhpnl7jy">
        <rect
          x={150.7}
          y={347.59}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "151.915px 348.805px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elt9tpatke4y">
        <rect
          x={128.81}
          y={332.64}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "146.095px 337.715px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el1ng3uwprnna">
        <rect
          x={128.81}
          y={332.64}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "146.095px 337.715px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elb5tk9pim3cn">
        <rect
          x={140.58}
          y={332.64}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "157.865px 337.715px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M144,337.72a2.14,2.14,0,1,0,2.14-2.14A2.13,2.13,0,0,0,144,337.72Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "146.14px 337.72px",
        }}
        id="elixt54kuhquh"
        className="animable"
      />
      <path
        d="M145,337.72a1.09,1.09,0,1,0,1.09-1.09A1.08,1.08,0,0,0,145,337.72Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "146.09px 337.72px",
        }}
        id="elyl6bgeo6j4e"
        className="animable"
      />
      <g id="eli87edtladg">
        <rect
          x={150.7}
          y={336.5}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "151.915px 337.715px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elcmw0k672l4o">
        <rect
          x={128.74}
          y={321.56}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "146.025px 326.635px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elwoxt03rzd4e">
        <rect
          x={128.74}
          y={321.56}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "146.025px 326.635px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el4ekzjibzm5l">
        <rect
          x={140.51}
          y={321.56}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "157.795px 326.635px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <circle
        cx={146.06}
        cy={326.63}
        r={2.13}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "146.06px 326.63px",
        }}
        id="el238ad0eg9v3"
        className="animable"
      />
      <path
        d="M145,326.63a1.09,1.09,0,1,0,2.17,0,1.09,1.09,0,0,0-2.17,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "146.085px 326.734px",
        }}
        id="elpcp6ujt9sos"
        className="animable"
      />
      <g id="ele0buk82s4md">
        <rect
          x={150.63}
          y={325.42}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "151.845px 326.635px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elhy3k5hpjkxv">
        <rect
          x={128.67}
          y={310.48}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "145.955px 315.555px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el2do9fx0uwxl">
        <rect
          x={128.67}
          y={310.48}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "145.955px 315.555px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elhszrz0tj8z">
        <rect
          x={140.44}
          y={310.48}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "157.725px 315.555px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M143.85,315.55a2.14,2.14,0,1,0,2.14-2.13A2.13,2.13,0,0,0,143.85,315.55Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "145.99px 315.56px",
        }}
        id="el7525f9g3h2"
        className="animable"
      />
      <path
        d="M144.9,315.55a1.09,1.09,0,1,0,2.17,0,1.09,1.09,0,0,0-2.17,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "145.985px 315.654px",
        }}
        id="elbzr14e8bjat"
        className="animable"
      />
      <g id="elqa80w6gcind">
        <rect
          x={150.56}
          y={314.34}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "151.775px 315.555px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el7eh5ac2227j">
        <rect
          x={128.6}
          y={299.39}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "145.885px 304.465px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elbwfbt9uuy1d">
        <rect
          x={128.6}
          y={299.39}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "145.885px 304.465px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elde3m5dgga4">
        <rect
          x={140.37}
          y={299.39}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "157.655px 304.465px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M143.78,304.47a2.14,2.14,0,1,0,2.14-2.14A2.13,2.13,0,0,0,143.78,304.47Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "145.92px 304.47px",
        }}
        id="elor1elqbl5do"
        className="animable"
      />
      <path
        d="M144.83,304.47a1.09,1.09,0,1,0,1.09-1.09A1.08,1.08,0,0,0,144.83,304.47Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "145.92px 304.47px",
        }}
        id="elqvfizftospq"
        className="animable"
      />
      <g id="eliycb3tn4ob8">
        <rect
          x={150.49}
          y={303.25}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "151.705px 304.465px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elwjb9pr6nrt">
        <rect
          x={128.53}
          y={288.31}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "145.815px 293.385px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elfkqc8z7icbn">
        <rect
          x={128.53}
          y={288.31}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "145.815px 293.385px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="els6p09cb600j">
        <rect
          x={140.3}
          y={288.31}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "157.585px 293.385px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M143.71,293.38a2.14,2.14,0,1,0,2.14-2.13A2.14,2.14,0,0,0,143.71,293.38Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "145.85px 293.39px",
        }}
        id="el3m5plt82kqg"
        className="animable"
      />
      <path
        d="M144.76,293.38a1.09,1.09,0,1,0,1.09-1.08A1.09,1.09,0,0,0,144.76,293.38Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "145.85px 293.39px",
        }}
        id="elptnwzvw7kln"
        className="animable"
      />
      <g id="el1c29sanfo9f">
        <rect
          x={150.42}
          y={292.17}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "151.635px 293.385px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el4vupddadr3s">
        <rect
          x={128.46}
          y={277.23}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "145.745px 282.305px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el5mpnihr6v1o">
        <rect
          x={128.46}
          y={277.23}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "145.745px 282.305px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eltwdky0ft9me">
        <rect
          x={140.23}
          y={277.23}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "157.515px 282.305px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M143.64,282.3a2.14,2.14,0,1,0,2.14-2.13A2.13,2.13,0,0,0,143.64,282.3Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "145.78px 282.31px",
        }}
        id="elkyfzoarri5l"
        className="animable"
      />
      <circle
        cx={145.78}
        cy={282.3}
        r={1.08}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "145.78px 282.3px",
        }}
        id="elmoq9t9bxwx"
        className="animable"
      />
      <g id="ellv5tyc9t0ln">
        <rect
          x={150.35}
          y={281.09}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "151.565px 282.305px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="ela4zzmb25edr">
        <rect
          x={128.39}
          y={266.14}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "145.675px 271.215px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elc29noqza3u">
        <rect
          x={128.39}
          y={266.14}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "145.675px 271.215px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elpi7130lprz">
        <rect
          x={140.16}
          y={266.14}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "157.445px 271.215px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M143.57,271.22a2.14,2.14,0,1,0,2.14-2.13A2.13,2.13,0,0,0,143.57,271.22Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "145.71px 271.23px",
        }}
        id="elc9frlt88xq4"
        className="animable"
      />
      <circle
        cx={145.71}
        cy={271.22}
        r={1.08}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "145.71px 271.22px",
        }}
        id="el8huxuuk6u0v"
        className="animable"
      />
      <g id="elllpd6kmx3l">
        <rect
          x={150.28}
          y={270}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "151.495px 271.215px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elhqymk68ok3f">
        <rect
          x={128.32}
          y={255.06}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "145.605px 260.135px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elkzg95k50gnm">
        <rect
          x={128.32}
          y={255.06}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "145.605px 260.135px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el7oavngv4n8">
        <rect
          x={140.09}
          y={255.06}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "157.375px 260.135px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M143.5,260.14a2.14,2.14,0,1,0,2.14-2.14A2.14,2.14,0,0,0,143.5,260.14Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "145.64px 260.14px",
        }}
        id="el1mcptt390et"
        className="animable"
      />
      <path
        d="M144.55,260.14a1.09,1.09,0,1,0,1.09-1.09A1.09,1.09,0,0,0,144.55,260.14Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "145.64px 260.14px",
        }}
        id="eljf6zz429gii"
        className="animable"
      />
      <g id="el27nkcecv5w4i">
        <rect
          x={150.21}
          y={258.92}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "151.425px 260.135px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eliprt9tvwlhm">
        <rect
          x={128.25}
          y={243.98}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "145.535px 249.055px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el1ltblz7tltj">
        <rect
          x={128.25}
          y={243.98}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "145.535px 249.055px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elag59z6qjdo7">
        <rect
          x={140.02}
          y={243.98}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "157.305px 249.055px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M143.43,249.05a2.14,2.14,0,1,0,2.14-2.13A2.14,2.14,0,0,0,143.43,249.05Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "145.57px 249.06px",
        }}
        id="elet45i1ukfwi"
        className="animable"
      />
      <path
        d="M144.48,249.05a1.09,1.09,0,1,0,2.17,0,1.09,1.09,0,0,0-2.17,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "145.565px 249.154px",
        }}
        id="elx4nq3ly5blf"
        className="animable"
      />
      <g id="elvg1ktaa7fw">
        <rect
          x={150.14}
          y={247.84}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "151.355px 249.055px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el6tuy6m3lx4a">
        <rect
          x={128.18}
          y={232.89}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "145.465px 237.965px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el06jqral9ia6n">
        <rect
          x={128.18}
          y={232.89}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "145.465px 237.965px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elfgqc9g6x55s">
        <rect
          x={139.95}
          y={232.89}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "157.235px 237.965px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M143.37,238a2.13,2.13,0,1,0,2.13-2.13A2.13,2.13,0,0,0,143.37,238Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "145.5px 238px",
        }}
        id="eljumskqa71zc"
        className="animable"
      />
      <path
        d="M144.41,238a1.09,1.09,0,1,0,1.09-1.09A1.08,1.08,0,0,0,144.41,238Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "145.5px 238px",
        }}
        id="el6fvn3r4w9pg"
        className="animable"
      />
      <g id="eljcsz66mn5y">
        <rect
          x={150.07}
          y={236.76}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "151.285px 237.975px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elyvnlartacw">
        <rect
          x={128.11}
          y={221.81}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "145.395px 226.885px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elldom1u5wr3">
        <rect
          x={128.11}
          y={221.81}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "145.395px 226.885px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elcsvrj43jx5p">
        <rect
          x={139.88}
          y={221.81}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "157.165px 226.885px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <circle
        cx={145.43}
        cy={226.89}
        r={2.13}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "145.43px 226.89px",
        }}
        id="elgeegr0ppzs"
        className="animable"
      />
      <path
        d="M144.34,226.89a1.09,1.09,0,1,0,1.09-1.09A1.08,1.08,0,0,0,144.34,226.89Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "145.43px 226.89px",
        }}
        id="eliyddko7k1gq"
        className="animable"
      />
      <g id="elafrwmiffzt6">
        <rect
          x={150}
          y={225.67}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "151.215px 226.885px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eljes3hdkeusb">
        <rect
          x={128.04}
          y={210.73}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "145.325px 215.805px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elmmd6oekd18">
        <rect
          x={128.04}
          y={210.73}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "145.325px 215.805px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elffk5ywzdb4q">
        <rect
          x={139.81}
          y={210.73}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "157.095px 215.805px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <circle
        cx={145.36}
        cy={215.8}
        r={2.13}
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "145.36px 215.8px",
        }}
        id="elq5k3e5vmdrg"
        className="animable"
      />
      <circle
        cx={145.36}
        cy={215.8}
        r={1.08}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "145.36px 215.8px",
        }}
        id="ely1wh5z2ysdb"
        className="animable"
      />
      <g id="elo3lh5zatd9q">
        <rect
          x={149.93}
          y={214.59}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "151.145px 215.805px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elomyxwhufbnd">
        <rect
          x={127.97}
          y={199.64}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "145.255px 204.715px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eldvn37xlmj8m">
        <rect
          x={127.97}
          y={199.64}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "145.255px 204.715px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elbcnp2iynev">
        <rect
          x={139.74}
          y={199.64}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "157.025px 204.715px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M143.16,204.72a2.13,2.13,0,1,0,2.13-2.13A2.13,2.13,0,0,0,143.16,204.72Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "145.29px 204.72px",
        }}
        id="eldwetbu7n84c"
        className="animable"
      />
      <path
        d="M144.2,204.72a1.09,1.09,0,1,0,1.09-1.09A1.08,1.08,0,0,0,144.2,204.72Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "145.29px 204.72px",
        }}
        id="elfegiqr903lk"
        className="animable"
      />
      <g id="eln3fwoc7w98s">
        <rect
          x={149.86}
          y={203.51}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "151.075px 204.725px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elyjpf09ijhdh">
        <rect
          x={127.9}
          y={188.56}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "145.185px 193.635px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eludqkge7gx5">
        <rect
          x={127.9}
          y={188.56}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(230, 230, 230)",
            isolation: "isolate",
            transformOrigin: "145.185px 193.635px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elgnj8feoifg7">
        <rect
          x={139.67}
          y={188.56}
          width={34.57}
          height={10.15}
          rx={1.65}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "156.955px 193.635px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M143.09,193.64a2.13,2.13,0,1,0,2.13-2.14A2.13,2.13,0,0,0,143.09,193.64Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "145.22px 193.63px",
        }}
        id="el5rv0qr3k7t"
        className="animable"
      />
      <path
        d="M144.13,193.64a1.09,1.09,0,1,0,1.09-1.09A1.08,1.08,0,0,0,144.13,193.64Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "145.22px 193.64px",
        }}
        id="elbozbdau8n78"
        className="animable"
      />
      <g id="elm9qdakjmlmk">
        <rect
          x={149.79}
          y={192.42}
          width={2.43}
          height={2.43}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "151.005px 193.635px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <rect
        x={54.69}
        y={349.62}
        width={42.51}
        height={32.44}
        rx={2.39}
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "75.945px 365.84px",
        }}
        id="ely4qt52j48n"
        className="animable"
      />
      <rect
        x={71.04}
        y={349.62}
        width={42.5}
        height={32.44}
        rx={2.39}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "92.29px 365.84px",
        }}
        id="ella3wn0guktc"
        className="animable"
      />
      <path
        d="M95.2,376H89.38a.41.41,0,0,1-.41-.41.41.41,0,0,1,.41-.41H95.2a.41.41,0,0,1,.41.41A.41.41,0,0,1,95.2,376Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "92.29px 375.59px",
        }}
        id="el7z08ecd2z3t"
        className="animable"
      />
      <path
        d="M99.08,373.85H85.5a.42.42,0,0,1-.41-.41.41.41,0,0,1,.41-.41H99.08a.41.41,0,0,1,.41.41A.42.42,0,0,1,99.08,373.85Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "92.29px 373.44px",
        }}
        id="el4t6fg8ayj4q"
        className="animable"
      />
      <path
        d="M100.92,371.67H83.67a.41.41,0,1,1,0-.81h17.25a.41.41,0,0,1,0,.81Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "92.2312px 371.265px",
        }}
        id="ela4vsmhjyl8p"
        className="animable"
      />
      <path
        d="M102,369.51H82.63a.42.42,0,0,1-.41-.41.41.41,0,0,1,.41-.41H102a.4.4,0,0,1,.4.41A.41.41,0,0,1,102,369.51Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "92.3101px 369.1px",
        }}
        id="el8pnq5i9f9kv"
        className="animable"
      />
      <path
        d="M102.42,367.33H82.16a.41.41,0,1,1,0-.81h20.26a.41.41,0,1,1,0,.81Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "92.29px 366.925px",
        }}
        id="eloy60mogi5rj"
        className="animable"
      />
      <path
        d="M102.42,365.17H82.16a.41.41,0,0,1-.41-.41.41.41,0,0,1,.41-.41h20.26a.41.41,0,0,1,.41.41A.41.41,0,0,1,102.42,365.17Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "92.29px 364.76px",
        }}
        id="elxhprwmovcy"
        className="animable"
      />
      <path
        d="M102,363H82.64a.41.41,0,1,1,0-.81H102a.41.41,0,0,1,0,.81Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "92.2561px 362.595px",
        }}
        id="eltup4tpsasf8"
        className="animable"
      />
      <path
        d="M100.92,360.83H83.67a.42.42,0,0,1-.41-.41.41.41,0,0,1,.41-.41h17.25a.4.4,0,0,1,.4.41A.41.41,0,0,1,100.92,360.83Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "92.2901px 360.42px",
        }}
        id="el7ru9glt1drj"
        className="animable"
      />
      <path
        d="M99.08,358.65H85.5a.41.41,0,1,1,0-.81H99.08a.41.41,0,1,1,0,.81Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "92.29px 358.245px",
        }}
        id="elod9vebjqha"
        className="animable"
      />
      <path
        d="M95.2,356.49H89.38a.41.41,0,0,1-.41-.41.41.41,0,0,1,.41-.41H95.2a.41.41,0,0,1,.41.41A.41.41,0,0,1,95.2,356.49Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "92.29px 356.08px",
        }}
        id="elsjjs27m4nr"
        className="animable"
      />
      <g id="elq02b1nio2d">
        <rect
          x={398.65}
          y={349.62}
          width={42.51}
          height={32.44}
          rx={2.39}
          style={{
            fill: "rgb(224, 224, 224)",
            transformOrigin: "419.905px 365.84px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elptfcbmjsmq">
        <rect
          x={382.31}
          y={349.62}
          width={42.51}
          height={32.44}
          rx={2.39}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "403.565px 365.84px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M400.64,376h5.83a.41.41,0,0,0,.41-.41.41.41,0,0,0-.41-.41h-5.83a.41.41,0,0,0-.4.41A.4.4,0,0,0,400.64,376Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "403.56px 375.59px",
        }}
        id="el8ftejczgzaq"
        className="animable"
      />
      <path
        d="M396.77,373.85h13.58a.41.41,0,0,0,.4-.41.4.4,0,0,0-.4-.41H396.77a.41.41,0,0,0-.41.41A.41.41,0,0,0,396.77,373.85Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "403.555px 373.44px",
        }}
        id="elqt35xrmh6xp"
        className="animable"
      />
      <path
        d="M394.93,371.67h17.25a.41.41,0,1,0,0-.81H394.93a.41.41,0,1,0,0,.81Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "403.555px 371.265px",
        }}
        id="el1z7y0jq1988"
        className="animable"
      />
      <path
        d="M393.9,369.51h19.31a.41.41,0,0,0,.41-.41.41.41,0,0,0-.41-.41H393.9a.41.41,0,0,0-.41.41A.42.42,0,0,0,393.9,369.51Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "403.555px 369.1px",
        }}
        id="eln7zwf1ej1rg"
        className="animable"
      />
      <path
        d="M393.43,367.33h20.26a.41.41,0,1,0,0-.81H393.43a.41.41,0,1,0,0,.81Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "403.56px 366.925px",
        }}
        id="el0ptbo48wsc3l"
        className="animable"
      />
      <path
        d="M393.43,365.17h20.26a.42.42,0,0,0,.41-.41.41.41,0,0,0-.41-.41H393.43a.41.41,0,0,0-.41.41A.41.41,0,0,0,393.43,365.17Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "403.56px 364.76px",
        }}
        id="el6ye1ikg4y3f"
        className="animable"
      />
      <path
        d="M393.9,363h19.31a.41.41,0,1,0,0-.81H393.9a.41.41,0,1,0,0,.81Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "403.555px 362.595px",
        }}
        id="elg0zs9gqtfqu"
        className="animable"
      />
      <path
        d="M394.93,360.83h17.25a.42.42,0,0,0,.41-.41.41.41,0,0,0-.41-.41H394.93a.41.41,0,0,0-.41.41A.42.42,0,0,0,394.93,360.83Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "403.555px 360.42px",
        }}
        id="elinpp7sluqxg"
        className="animable"
      />
      <path
        d="M396.77,358.65h13.58a.41.41,0,0,0,0-.81H396.77a.41.41,0,1,0,0,.81Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "403.496px 358.245px",
        }}
        id="eluh3kwav1a4"
        className="animable"
      />
      <path
        d="M400.64,356.49h5.83a.41.41,0,0,0,.41-.41.41.41,0,0,0-.41-.41h-5.83a.4.4,0,0,0-.4.41A.41.41,0,0,0,400.64,356.49Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "403.56px 356.08px",
        }}
        id="el42j5ua2xaov"
        className="animable"
      />
    </g>
    <g
      id="freepik--Shadow--inject-2"
      className="animable"
      style={{
        transformOrigin: "250px 416.24px",
      }}
    >
      <ellipse
        id="freepik--path--inject-2"
        cx={250}
        cy={416.24}
        rx={193.89}
        ry={11.32}
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "250px 416.24px",
        }}
        className="animable"
      />
    </g>
    <g
      id="freepik--Plant--inject-2"
      className="animable"
      style={{
        transformOrigin: "99.0032px 369.469px",
      }}
    >
      <path
        d="M79.34,348.18s6.35-5.3,11.79,0,6.5,38.07,6.5,38.07"
        style={{
          fill: "none",
          stroke: "rgb(176, 190, 197)",
          strokeMiterlimit: 10,
          transformOrigin: "88.485px 366.037px",
        }}
        id="elftrjzeo2wut"
        className="animable"
      />
      <path
        d="M98.08,392.58s-3.74-38.47,10.34-54.64"
        style={{
          fill: "none",
          stroke: "rgb(176, 190, 197)",
          strokeMiterlimit: 10,
          transformOrigin: "103.025px 365.26px",
        }}
        id="eloppev0ali2o"
        className="animable"
      />
      <path
        d="M96.74,388.53s2.88-43.8-7.69-53.52"
        style={{
          fill: "none",
          stroke: "rgb(176, 190, 197)",
          strokeMiterlimit: 10,
          transformOrigin: "93.0742px 361.77px",
        }}
        id="elj73e26fdg8"
        className="animable"
      />
      <path
        d="M98.08,388.53s.24-28.95,8.1-33.44S118,365.26,118,365.26"
        style={{
          fill: "none",
          stroke: "rgb(176, 190, 197)",
          strokeMiterlimit: 10,
          transformOrigin: "108.04px 371.383px",
        }}
        id="elutf52gsf9yn"
        className="animable"
      />
      <path
        d="M95,388.53s1.28-22.22-4.81-30.16-12.48,3.4-12.48,3.4"
        style={{
          fill: "none",
          stroke: "rgb(176, 190, 197)",
          strokeMiterlimit: 10,
          transformOrigin: "86.4111px 372.039px",
        }}
        id="el17gizvnyhe1"
        className="animable"
      />
      <path
        d="M97.1,391.5s-3.94-52,2.57-60.76"
        style={{
          fill: "none",
          stroke: "rgb(176, 190, 197)",
          strokeMiterlimit: 10,
          transformOrigin: "97.7943px 361.12px",
        }}
        id="elirl0sd9e4rc"
        className="animable"
      />
      <path
        d="M112.9,359.3s-3.92,1.45-2.75,4.85,13.54,9,13.54,9,5.19-4.75.42-12.48S113,356.86,112.9,359.3Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "118.029px 364.632px",
        }}
        id="el3kn9ynabalt"
        className="animable"
      />
      <g id="elduofqcil99b">
        <path
          d="M112.9,359.3s-3.92,1.45-2.75,4.85,13.54,9,13.54,9,5.19-4.75.42-12.48S113,356.86,112.9,359.3Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            isolation: "isolate",
            transformOrigin: "118.029px 364.632px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M95.88,352.82s4.17-.18,4.41,3.41-9,13.54-9,13.54-6.61-2.39-5.19-11.36S94.84,350.61,95.88,352.82Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "93.0973px 360.509px",
        }}
        id="el11z50a9cslad"
        className="animable"
      />
      <g id="el0ss8y8ebb17">
        <path
          d="M95.88,352.82s4.17-.18,4.41,3.41-9,13.54-9,13.54-6.61-2.39-5.19-11.36S94.84,350.61,95.88,352.82Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            isolation: "isolate",
            transformOrigin: "93.0973px 360.509px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M92.35,335.62s2.78-.3,2.56-2-10.81-6.05-17,1c0,0,4,6.87,9.77,7S92.35,335.62,92.35,335.62Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "86.4162px 336.175px",
        }}
        id="elhau4b98q3qq"
        className="animable"
      />
      <g id="el3r9jsrusfcs">
        <path
          d="M92.35,335.62s2.78-.3,2.56-2-10.81-6.05-17,1c0,0,4,6.87,9.77,7S92.35,335.62,92.35,335.62Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            isolation: "isolate",
            transformOrigin: "86.4162px 336.175px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M97.53,331.51s-2.52,1.21-3.22-.34,6-10.85,15-8.17c0,0,.24,7.94-4.62,11.07S97.53,331.51,97.53,331.51Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "101.786px 328.764px",
        }}
        id="el1o2cokaqnq1"
        className="animable"
      />
      <g id="el42nqdx2czg">
        <path
          d="M97.53,331.51s-2.52,1.21-3.22-.34,6-10.85,15-8.17c0,0,.24,7.94-4.62,11.07S97.53,331.51,97.53,331.51Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            isolation: "isolate",
            transformOrigin: "101.786px 328.764px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M84.37,357.93s1.92-1.55-.15-2.3-12.23-.54-12.34,9.52c0,0,11.83,2,13.3-1.87S84.37,357.93,84.37,357.93Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "78.7769px 360.516px",
        }}
        id="elowlbaxx1g9"
        className="animable"
      />
      <g id="el08n3d6iswao">
        <path
          d="M84.37,357.93s1.92-1.55-.15-2.3-12.23-.54-12.34,9.52c0,0,11.83,2,13.3-1.87S84.37,357.93,84.37,357.93Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            isolation: "isolate",
            transformOrigin: "78.7769px 360.516px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M108.49,335.36s-1.91-1.54.16-2.3,12.22-.54,12.33,9.52c0,0-11.83,2-13.3-1.87S108.49,335.36,108.49,335.36Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "114.083px 337.945px",
        }}
        id="el7qki3k96u37"
        className="animable"
      />
      <g id="elsmr32stlvbb">
        <path
          d="M108.49,335.36s-1.91-1.54.16-2.3,12.22-.54,12.33,9.52c0,0-11.83,2-13.3-1.87S108.49,335.36,108.49,335.36Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            isolation: "isolate",
            transformOrigin: "114.083px 337.945px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M102.24,343.47s-.17-1.88,1.35-1.14,6.82,6.45,1.33,11.87c0,0-7.39-5.48-6.06-8.32S102.24,343.47,102.24,343.47Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "103.027px 348.179px",
        }}
        id="elvyosmalvqhq"
        className="animable"
      />
      <g id="eltrlo6r21nnn">
        <path
          d="M102.24,343.47s-.17-1.88,1.35-1.14,6.82,6.45,1.33,11.87c0,0-7.39-5.48-6.06-8.32S102.24,343.47,102.24,343.47Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            isolation: "isolate",
            transformOrigin: "103.027px 348.179px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M82.65,346.15s1.15-1.5-.53-1.69-9.2,1.83-7.45,9.34c0,0,9.17-.69,9.56-3.81S82.65,346.15,82.65,346.15Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "79.3444px 349.124px",
        }}
        id="elr1c8qvwihoj"
        className="animable"
      />
      <g id="elr84o8xf2ffs">
        <path
          d="M82.65,346.15s1.15-1.5-.53-1.69-9.2,1.83-7.45,9.34c0,0,9.17-.69,9.56-3.81S82.65,346.15,82.65,346.15Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            isolation: "isolate",
            transformOrigin: "79.3444px 349.124px",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="113.63 416.42 82.17 416.42 78.78 381.98 117.03 381.98 113.63 416.42"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "97.905px 399.2px",
        }}
        id="elt8wfm6nbmg9"
        className="animable"
      />
      <g id="elfo3pq3um7rh">
        <polygon
          points="113.63 416.42 82.17 416.42 78.78 381.98 117.03 381.98 113.63 416.42"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.5,
            isolation: "isolate",
            transformOrigin: "97.905px 399.2px",
          }}
          className="animable"
        />
      </g>
      <rect
        x={77.1}
        y={378.17}
        width={41.61}
        height={7.6}
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "97.905px 381.97px",
        }}
        id="el9gngbvl0lyg"
        className="animable"
      />
      <g id="elyg4vwsnqbp">
        <rect
          x={77.1}
          y={378.17}
          width={41.61}
          height={7.6}
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            isolation: "isolate",
            transformOrigin: "97.905px 381.97px",
          }}
          className="animable"
        />
      </g>
    </g>
    <g
      id="freepik--trash-bin--inject-2"
      className="animable"
      style={{
        transformOrigin: "396.78px 395.7px",
      }}
    >
      <path
        d="M409.67,397.93a2.23,2.23,0,0,0-1.78-3.19,4.75,4.75,0,0,0-1.5-5.19,1.1,1.1,0,0,0-.66-.29,1.27,1.27,0,0,0-.7.31l-4.16,3a2.64,2.64,0,0,0-.82.81c-.85,1.65,2.21,3.76.95,5.12-.36.39-.94.47-1.39.75-1,.62-1.11,2-1.57,3.05s-2.07,1.9-2.69.91c.46-1-.92-1.91-2-2s-2.29-.19-2.9-1.24c-1.59,1.38-5.13-2.48-5.05-4a3.07,3.07,0,0,0-2.48,2.54,18.6,18.6,0,0,0-.13,3.88c0,2.52-.85,5-.81,7.51,0,2,.79,4.15,2.59,4.93a6.73,6.73,0,0,0,2.79.38l20.45-.13a3.17,3.17,0,0,0,1.44-.24c.92-.48,1.12-1.69,1.2-2.73q.56-6.67,1.1-13.33Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "396.764px 402.245px",
        }}
        id="el2pi4gadbqd7"
        className="animable"
      />
      <g id="ell44hs92fzh">
        <path
          d="M409.67,397.93a2.23,2.23,0,0,0-1.78-3.19,4.75,4.75,0,0,0-1.5-5.19,1.1,1.1,0,0,0-.66-.29,1.27,1.27,0,0,0-.7.31l-4.16,3a2.64,2.64,0,0,0-.82.81c-.85,1.65,2.21,3.76.95,5.12-.36.39-.94.47-1.39.75-1,.62-1.11,2-1.57,3.05s-2.07,1.9-2.69.91c.46-1-.92-1.91-2-2s-2.29-.19-2.9-1.24c-1.59,1.38-5.13-2.48-5.05-4a3.07,3.07,0,0,0-2.48,2.54,18.6,18.6,0,0,0-.13,3.88c0,2.52-.85,5-.81,7.51,0,2,.79,4.15,2.59,4.93a6.73,6.73,0,0,0,2.79.38l20.45-.13a3.17,3.17,0,0,0,1.44-.24c.92-.48,1.12-1.69,1.2-2.73q.56-6.67,1.1-13.33Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.3,
            isolation: "isolate",
            transformOrigin: "396.764px 402.245px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M410.87,376.31H379.13l.26,3.21.18,2.29.49,5.95.27,3.43.37,4.55.16,2,.05.58.19,2.3.25,3.14.16,2,.16,2,.19,2.3.14,1.74.15,1.95.07.85h29.13l.07-.85.15-1.95.15-1.75.18-2.3.17-2,.15-2,.26-3.15.18-2.29,0-.58.15-2,.38-4.55.27-3.42.48-6,.19-2.29.26-3.21Zm1.9,2.1h0l-.61-.61h.67Zm-.15,1.95-.06.77L409.72,384l-3.26-3.26,2.91-2.92h.69Zm-.82,10.16-2.08,2.08-3.26-3.26,3.26-3.25,2.25,2.25Zm-30.21-2.18h0l2.26-2.25,3.26,3.25-3.26,3.26-2.08-2.08ZM408.67,385l-3.26,3.26L402.15,385l3.26-3.26Zm-5.13-7.23h3.73l-1.86,1.87Zm.81,11.54-3.25,3.26-3.26-3.26,3.26-3.25Zm-10.82,4.31,3.26-3.25,3.25,3.25-3.25,3.26Zm2.2,4.31-3.26,3.26L389.22,398l3.26-3.26Zm5.37-3.25,3.25,3.26-3.25,3.25L397.84,398Zm-4.31-6.42L393.53,385l3.26-3.26L400,385Zm-1.06,1-3.25,3.26-3.26-3.26,3.26-3.25Zm-7.57-1L384.91,385l3.25-3.26,3.26,3.26Zm0,2.11,3.26,3.25-3.26,3.26-3.25-3.26Zm3.26,11.88-3.26,3.25-3.25-3.25,3.25-3.26Zm1.05,1.05,3.26,3.26-3.25,3.26-3.26-3.26Zm1.06-1.05,3.26-3.26,3.25,3.26-3.25,3.25Zm7.57,1.05,3.25,3.26-3.25,3.26-3.26-3.26Zm4.31,4.31,3.26,3.26L406.56,413h-2.3l-2.11-2.11Zm-3.26-5.36,3.26-3.26,3.26,3.26-3.26,3.25Zm0-8.63,3.26-3.25,3.26,3.25-3.26,3.26Zm2.2-12.93L401.1,384l-3.26-3.26,2.91-2.92h.69Zm-7.56-1.05-1.87-1.87h3.72Zm-1.06,1.05L392.48,384l-3.26-3.26,2.91-2.92h.69ZM390,377.8l-1.86,1.87-1.86-1.87Zm-5.83,0,2.92,2.92L383.85,384,381,381.13l-.06-.77,2.55-2.56Zm-2.79,0-.6.6h0l-.06-.6Zm-.2,5.63,1.6,1.6-1.36,1.35Zm.89,10.93-.13-1.55h0l.84.84-.71.71Zm.15,2,1.61-1.61,3.26,3.26-3.26,3.25-1.32-1.32Zm.49,6v-.14l.08.08-.07.06Zm.57,7-.41-5,1-1,3.26,3.26-3.26,3.25-.55-.55Zm.29,3.72-.06-.75.32-.31,1.06,1.06Zm1.32-2.11,3.25-3.26,3.26,3.26L389.31,413H387Zm6.51,2.11,1.06-1.06,1,1.06Zm4.22,0-2.11-2.11,3.26-3.26L400,410.9,397.93,413Zm4.4,0L401.1,412l1.06,1.06Zm9.93,0h-1.31l1.06-1.06.31.31Zm.31-3.72-.56.55-3.26-3.25,3.26-3.26,1,1Zm.57-7.1v.15l-.07-.06.08-.09Zm.19-2.29-1.32,1.32L406.46,398l3.26-3.26,1.61,1.61Zm.44-5.54-.71-.71.84-.84Zm.89-10.94-.24,3L410.77,385l1.6-1.61Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "396.76px 395.43px",
        }}
        id="elozsuwnb9bd"
        className="animable"
      />
      <g id="elsyaoc5ltgo">
        <g
          style={{
            opacity: 0.6,
            isolation: "isolate",
            transformOrigin: "396.76px 395.43px",
          }}
          className="animable"
        >
          <path
            d="M410.87,376.31H379.13l.26,3.21.18,2.29.49,5.95.27,3.43.37,4.55.16,2,.05.58.19,2.3.25,3.14.16,2,.16,2,.19,2.3.14,1.74.15,1.95.07.85h29.13l.07-.85.15-1.95.15-1.75.18-2.3.17-2,.15-2,.26-3.15.18-2.29,0-.58.15-2,.38-4.55.27-3.42.48-6,.19-2.29.26-3.21Zm1.9,2.1h0l-.61-.61h.67Zm-.15,1.95-.06.77L409.72,384l-3.26-3.26,2.91-2.92h.69Zm-.82,10.16-2.08,2.08-3.26-3.26,3.26-3.25,2.25,2.25Zm-30.21-2.18h0l2.26-2.25,3.26,3.25-3.26,3.26-2.08-2.08ZM408.67,385l-3.26,3.26L402.15,385l3.26-3.26Zm-5.13-7.23h3.73l-1.86,1.87Zm.81,11.54-3.25,3.26-3.26-3.26,3.26-3.25Zm-10.82,4.31,3.26-3.25,3.25,3.25-3.25,3.26Zm2.2,4.31-3.26,3.26L389.22,398l3.26-3.26Zm5.37-3.25,3.25,3.26-3.25,3.25L397.84,398Zm-4.31-6.42L393.53,385l3.26-3.26L400,385Zm-1.06,1-3.25,3.26-3.26-3.26,3.26-3.25Zm-7.57-1L384.91,385l3.25-3.26,3.26,3.26Zm0,2.11,3.26,3.25-3.26,3.26-3.25-3.26Zm3.26,11.88-3.26,3.25-3.25-3.25,3.25-3.26Zm1.05,1.05,3.26,3.26-3.25,3.26-3.26-3.26Zm1.06-1.05,3.26-3.26,3.25,3.26-3.25,3.25Zm7.57,1.05,3.25,3.26-3.25,3.26-3.26-3.26Zm4.31,4.31,3.26,3.26L406.56,413h-2.3l-2.11-2.11Zm-3.26-5.36,3.26-3.26,3.26,3.26-3.26,3.25Zm0-8.63,3.26-3.25,3.26,3.25-3.26,3.26Zm2.2-12.93L401.1,384l-3.26-3.26,2.91-2.92h.69Zm-7.56-1.05-1.87-1.87h3.72Zm-1.06,1.05L392.48,384l-3.26-3.26,2.91-2.92h.69ZM390,377.8l-1.86,1.87-1.86-1.87Zm-5.83,0,2.92,2.92L383.85,384,381,381.13l-.06-.77,2.55-2.56Zm-2.79,0-.6.6h0l-.06-.6Zm-.2,5.63,1.6,1.6-1.36,1.35Zm.89,10.93-.13-1.55h0l.84.84-.71.71Zm.15,2,1.61-1.61,3.26,3.26-3.26,3.25-1.32-1.32Zm.49,6v-.14l.08.08-.07.06Zm.57,7-.41-5,1-1,3.26,3.26-3.26,3.25-.55-.55Zm.29,3.72-.06-.75.32-.31,1.06,1.06Zm1.32-2.11,3.25-3.26,3.26,3.26L389.31,413H387Zm6.51,2.11,1.06-1.06,1,1.06Zm4.22,0-2.11-2.11,3.26-3.26L400,410.9,397.93,413Zm4.4,0L401.1,412l1.06,1.06Zm9.93,0h-1.31l1.06-1.06.31.31Zm.31-3.72-.56.55-3.26-3.25,3.26-3.26,1,1Zm.57-7.1v.15l-.07-.06.08-.09Zm.19-2.29-1.32,1.32L406.46,398l3.26-3.26,1.61,1.61Zm.44-5.54-.71-.71.84-.84Zm.89-10.94-.24,3L410.77,385l1.6-1.61Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "396.76px 395.43px",
            }}
            id="elloru23m3q0g"
            className="animable"
          />
        </g>
      </g>
      <g id="elgcxsp2h8e2e">
        <rect
          x={377.09}
          y={374.98}
          width={39.38}
          height={4.15}
          rx={1.64}
          style={{
            fill: "rgb(176, 190, 197)",
            transformOrigin: "396.78px 377.055px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <rect
        x={379.93}
        y={412.27}
        width={33.7}
        height={4.15}
        rx={1.64}
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "396.78px 414.345px",
        }}
        id="el26prmy9kpv8"
        className="animable"
      />
    </g>
    <g
      id="freepik--Switches--inject-2"
      className="animable"
      style={{
        transformOrigin: "216.05px 187.065px",
      }}
    >
      <g id="ellyh41hrx82">
        <path
          d="M79,108a4.56,4.56,0,0,0-4.56,4.55v154A4.57,4.57,0,0,0,79,271.13H348.1a4.56,4.56,0,0,0,4.56-4.56v-154A4.56,4.56,0,0,0,348.1,108Z"
          style={{
            fill: "rgb(176, 190, 197)",
            opacity: 0.8,
            transformOrigin: "213.55px 189.565px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M84,103a4.56,4.56,0,0,0-4.56,4.55v154A4.57,4.57,0,0,0,84,266.13H353.1a4.56,4.56,0,0,0,4.56-4.56v-154A4.56,4.56,0,0,0,353.1,103Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "218.55px 184.565px",
        }}
        id="el8iettsvphyg"
        className="animable"
      />
      <g id="el0xi0wctspyfa">
        <path
          d="M84,103a4.56,4.56,0,0,0-4.56,4.55v154A4.57,4.57,0,0,0,84,266.13H353.1a4.56,4.56,0,0,0,4.56-4.56v-154A4.56,4.56,0,0,0,353.1,103Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.8,
            transformOrigin: "218.55px 184.565px",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="331.3 130.38 329.3 126.21 294.75 126.21 294.75 242.95 329.3 242.95 331.3 238.78 331.3 130.38"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "313.025px 184.58px",
        }}
        id="el5q1j6xzbdq2"
        className="animable"
      />
      <g id="elq46pm5vhyfq">
        <polygon
          points="300.51 130.38 294.75 126.21 294.75 242.95 300.51 238.78 300.51 130.38"
          style={{
            opacity: 0.2,
            isolation: "isolate",
            transformOrigin: "297.63px 184.58px",
          }}
          className="animable"
        />
      </g>
      <g id="eln20g3bxerq">
        <polygon
          points="294.75 126.21 300.51 130.38 331.3 130.38 329.3 126.21 294.75 126.21"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            transformOrigin: "313.025px 128.295px",
          }}
          className="animable"
        />
      </g>
      <g id="elqxcqv0fuydg">
        <polygon
          points="300.51 238.78 331.3 238.78 329.3 242.95 294.75 242.95 300.51 238.78"
          style={{
            opacity: 0.4,
            isolation: "isolate",
            transformOrigin: "313.025px 240.865px",
          }}
          className="animable"
        />
      </g>
      <g id="elhb2tnhhgclu">
        <g
          style={{
            opacity: 0.3,
            transformOrigin: "315.91px 184.56px",
          }}
          className="animable"
        >
          <path
            d="M315.91,147.62a6,6,0,1,1,6-6A6,6,0,0,1,315.91,147.62Zm0-10a4,4,0,1,0,4,4A4,4,0,0,0,315.91,137.66Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "315.91px 141.62px",
            }}
            id="elyqruqc8hk0d"
            className="animable"
          />
          <path
            d="M315.91,233.5a1,1,0,0,1-1-1v-10a1,1,0,0,1,2,0v10A1,1,0,0,1,315.91,233.5Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "315.91px 227.5px",
            }}
            id="eladzff1g8vhr"
            className="animable"
          />
        </g>
      </g>
      <rect
        x={303.51}
        y={153.38}
        width={24.8}
        height={62.4}
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "315.91px 184.58px",
        }}
        id="elx19616tbcy"
        className="animable"
      />
      <path
        d="M348.31,172.78l-2.18-18.55h-20l-22.62.05V215h20a228.93,228.93,0,0,0,1.68-29.77v0l23.11-6.13.72-.19Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "326.265px 184.615px",
        }}
        id="elsmtkgnj844"
        className="animable"
      />
      <g id="elv5ofscgpdsa">
        <path
          d="M348.31,172.78l-2.18-18.55h-20l-22.62.05V215h20a228.93,228.93,0,0,0,1.68-29.77v0l23.11-6.13.72-.19Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.4,
            transformOrigin: "326.265px 184.615px",
          }}
          className="animable"
        />
      </g>
      <g id="elqzb2pxysd8f">
        <polygon
          points="326.13 154.23 346.13 154.23 349.03 178.91 329.03 178.91 326.13 154.23"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            transformOrigin: "337.58px 166.57px",
          }}
          className="animable"
        />
      </g>
      <g id="el7u6yyh1tp42">
        <polygon
          points="305.19 185.21 325.2 185.23 349.03 178.91 329.03 178.91 305.19 185.21"
          style={{
            opacity: 0.4,
            isolation: "isolate",
            transformOrigin: "327.11px 182.07px",
          }}
          className="animable"
        />
      </g>
      <g id="elvkf6f5q64fm">
        <path
          d="M329,178.91l-.72.19-23.11,6.13v0A228.93,228.93,0,0,1,303.51,215v-60.7c.27,1.89.52,4.06.74,6.45l21.88-6.5,2.18,18.55Z"
          style={{
            opacity: 0.2,
            isolation: "isolate",
            transformOrigin: "316.255px 184.625px",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="268.82 130.38 266.82 126.21 232.27 126.21 232.27 242.95 266.82 242.95 268.82 238.78 268.82 130.38"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "250.545px 184.58px",
        }}
        id="el9jnmg4bob28"
        className="animable"
      />
      <g id="el92r503m69bs">
        <polygon
          points="238.03 130.38 232.27 126.21 232.27 242.95 238.03 238.78 238.03 130.38"
          style={{
            opacity: 0.2,
            isolation: "isolate",
            transformOrigin: "235.15px 184.58px",
          }}
          className="animable"
        />
      </g>
      <g id="elfe3i0xafsq">
        <polygon
          points="232.27 126.21 238.03 130.38 268.82 130.38 266.82 126.21 232.27 126.21"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            transformOrigin: "250.545px 128.295px",
          }}
          className="animable"
        />
      </g>
      <g id="elr2yfb1ixmq">
        <polygon
          points="238.03 238.78 268.82 238.78 266.82 242.95 232.27 242.95 238.03 238.78"
          style={{
            opacity: 0.4,
            isolation: "isolate",
            transformOrigin: "250.545px 240.865px",
          }}
          className="animable"
        />
      </g>
      <g id="elz2dipsg0kq">
        <g
          style={{
            opacity: 0.3,
            transformOrigin: "253.43px 184.56px",
          }}
          className="animable"
        >
          <path
            d="M253.43,147.62a6,6,0,1,1,6-6A6,6,0,0,1,253.43,147.62Zm0-10a4,4,0,1,0,4,4A4,4,0,0,0,253.43,137.66Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "253.43px 141.62px",
            }}
            id="elxyz2t9k74lf"
            className="animable"
          />
          <path
            d="M253.43,233.5a1,1,0,0,1-1-1v-10a1,1,0,0,1,2,0v10A1,1,0,0,1,253.43,233.5Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "253.43px 227.5px",
            }}
            id="elubnbo0rwp8"
            className="animable"
          />
        </g>
      </g>
      <rect
        x={241.03}
        y={153.38}
        width={24.8}
        height={62.4}
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "253.43px 184.58px",
        }}
        id="elscvbtljujvi"
        className="animable"
      />
      <path
        d="M285.83,172.78l-2.18-18.55h-20l-22.62.05V215h20a228.93,228.93,0,0,0,1.68-29.77v0l23.11-6.13.72-.19Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "263.785px 184.615px",
        }}
        id="elntf92amnyap"
        className="animable"
      />
      <g id="elctbndk0et2i">
        <path
          d="M285.83,172.78l-2.18-18.55h-20l-22.62.05V215h20a228.93,228.93,0,0,0,1.68-29.77v0l23.11-6.13.72-.19Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.4,
            transformOrigin: "263.785px 184.615px",
          }}
          className="animable"
        />
      </g>
      <g id="el12zjzbv0kzb">
        <polygon
          points="263.65 154.23 283.65 154.23 286.55 178.91 266.55 178.91 263.65 154.23"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            transformOrigin: "275.1px 166.57px",
          }}
          className="animable"
        />
      </g>
      <g id="elsvii78lk33">
        <polygon
          points="242.71 185.21 262.72 185.23 286.55 178.91 266.55 178.91 242.71 185.21"
          style={{
            opacity: 0.4,
            isolation: "isolate",
            transformOrigin: "264.63px 182.07px",
          }}
          className="animable"
        />
      </g>
      <g id="el3zp5ez2z4yd">
        <path
          d="M266.55,178.91l-.72.19-23.11,6.13v0A228.93,228.93,0,0,1,241,215v-60.7c.27,1.89.52,4.06.74,6.45l21.88-6.5,2.18,18.55Z"
          style={{
            opacity: 0.2,
            isolation: "isolate",
            transformOrigin: "253.775px 184.625px",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="206.34 130.38 204.34 126.21 169.79 126.21 169.79 242.95 204.34 242.95 206.34 238.78 206.34 130.38"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "188.065px 184.58px",
        }}
        id="elc61um96c7b"
        className="animable"
      />
      <g id="elvsrzoon40ua">
        <polygon
          points="175.55 130.38 169.79 126.21 169.79 242.95 175.55 238.78 175.55 130.38"
          style={{
            opacity: 0.2,
            isolation: "isolate",
            transformOrigin: "172.67px 184.58px",
          }}
          className="animable"
        />
      </g>
      <g id="el6b97i6qn9l9">
        <polygon
          points="169.79 126.21 175.55 130.38 206.34 130.38 204.34 126.21 169.79 126.21"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            transformOrigin: "188.065px 128.295px",
          }}
          className="animable"
        />
      </g>
      <g id="ele05yhl0xhso">
        <polygon
          points="175.55 238.78 206.34 238.78 204.34 242.95 169.79 242.95 175.55 238.78"
          style={{
            opacity: 0.4,
            isolation: "isolate",
            transformOrigin: "188.065px 240.865px",
          }}
          className="animable"
        />
      </g>
      <g id="elh8uorckhd4">
        <g
          style={{
            opacity: 0.3,
            transformOrigin: "190.94px 184.56px",
          }}
          className="animable"
        >
          <path
            d="M190.94,147.62a6,6,0,1,1,6-6A6,6,0,0,1,190.94,147.62Zm0-10a4,4,0,1,0,4,4A4,4,0,0,0,190.94,137.66Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "190.94px 141.62px",
            }}
            id="eltpjncdbdqw"
            className="animable"
          />
          <path
            d="M190.94,233.5a1,1,0,0,1-1-1v-10a1,1,0,0,1,2,0v10A1,1,0,0,1,190.94,233.5Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "190.94px 227.5px",
            }}
            id="el0dq9swrm7505"
            className="animable"
          />
        </g>
      </g>
      <rect
        x={178.54}
        y={153.38}
        width={24.8}
        height={62.4}
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "190.94px 184.58px",
        }}
        id="el1msx4i7faku"
        className="animable"
      />
      <path
        d="M223.34,196.43,221.16,215h-20l-22.62,0v-60.7h20A228.93,228.93,0,0,1,200.22,184v0l23.11,6.13.72.19Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "201.295px 184.65px",
        }}
        id="ellagh9yoxpgn"
        className="animable"
      />
      <g id="el1b12ntofylx">
        <path
          d="M223.34,196.43,221.16,215h-20l-22.62,0v-60.7h20A228.93,228.93,0,0,1,200.22,184v0l23.11,6.13.72.19Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.4,
            transformOrigin: "201.295px 184.65px",
          }}
          className="animable"
        />
      </g>
      <g id="el0kxhbbf8sdoi">
        <polygon
          points="201.16 214.98 221.16 214.98 224.06 190.3 204.06 190.3 201.16 214.98"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            transformOrigin: "212.61px 202.64px",
          }}
          className="animable"
        />
      </g>
      <g id="elryglbxqewi">
        <polygon
          points="180.22 184 200.24 183.98 224.06 190.3 204.06 190.3 180.22 184"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.4,
            transformOrigin: "202.14px 187.14px",
          }}
          className="animable"
        />
      </g>
      <g id="elz1s20wy3px">
        <path
          d="M204.06,190.3l-.72-.19L180.23,184v0a228.93,228.93,0,0,0-1.68-29.77v60.7c.27-1.89.52-4.06.74-6.45l21.88,6.5,2.18-18.55Z"
          style={{
            opacity: 0.2,
            isolation: "isolate",
            transformOrigin: "191.305px 184.605px",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="143.86 130.38 141.86 126.21 107.31 126.21 107.31 242.95 141.86 242.95 143.86 238.78 143.86 130.38"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "125.585px 184.58px",
        }}
        id="el3gxtnpcjyl7"
        className="animable"
      />
      <g id="ele3w6won5i0u">
        <polygon
          points="113.07 130.38 107.31 126.21 107.31 242.95 113.07 238.78 113.07 130.38"
          style={{
            opacity: 0.2,
            isolation: "isolate",
            transformOrigin: "110.19px 184.58px",
          }}
          className="animable"
        />
      </g>
      <g id="el46nkw4wil4n">
        <polygon
          points="107.31 126.21 113.07 130.38 143.86 130.38 141.86 126.21 107.31 126.21"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            transformOrigin: "125.585px 128.295px",
          }}
          className="animable"
        />
      </g>
      <g id="elv2gof2tb0yd">
        <polygon
          points="113.07 238.78 143.86 238.78 141.86 242.95 107.31 242.95 113.07 238.78"
          style={{
            opacity: 0.4,
            isolation: "isolate",
            transformOrigin: "125.585px 240.865px",
          }}
          className="animable"
        />
      </g>
      <g id="els31bnzk7bsh">
        <g
          style={{
            opacity: 0.3,
            transformOrigin: "128.46px 184.56px",
          }}
          className="animable"
        >
          <path
            d="M128.46,147.62a6,6,0,1,1,6-6A6,6,0,0,1,128.46,147.62Zm0-10a4,4,0,1,0,4,4A4,4,0,0,0,128.46,137.66Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "128.46px 141.62px",
            }}
            id="eln1id29408cl"
            className="animable"
          />
          <path
            d="M128.46,233.5a1,1,0,0,1-1-1v-10a1,1,0,0,1,2,0v10A1,1,0,0,1,128.46,233.5Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "128.46px 227.5px",
            }}
            id="ell9serkelbb"
            className="animable"
          />
        </g>
      </g>
      <rect
        x={116.06}
        y={153.38}
        width={24.8}
        height={62.4}
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "128.46px 184.58px",
        }}
        id="elzqodxixy3s"
        className="animable"
      />
      <path
        d="M160.86,196.43,158.68,215h-20l-22.62,0v-60.7h20A228.93,228.93,0,0,1,137.74,184v0l23.11,6.13.72.19Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "138.815px 184.65px",
        }}
        id="elici89iwdq7g"
        className="animable"
      />
      <g id="elp03i9b4zgph">
        <path
          d="M160.86,196.43,158.68,215h-20l-22.62,0v-60.7h20A228.93,228.93,0,0,1,137.74,184v0l23.11,6.13.72.19Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.4,
            transformOrigin: "138.815px 184.65px",
          }}
          className="animable"
        />
      </g>
      <g id="eldtteqjaisas">
        <polygon
          points="138.68 214.98 158.68 214.98 161.58 190.3 141.58 190.3 138.68 214.98"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            transformOrigin: "150.13px 202.64px",
          }}
          className="animable"
        />
      </g>
      <g id="elq05qp0isayh">
        <polygon
          points="117.74 184 137.75 183.98 161.58 190.3 141.58 190.3 117.74 184"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.4,
            transformOrigin: "139.66px 187.14px",
          }}
          className="animable"
        />
      </g>
      <g id="ell3p6jg0ityg">
        <path
          d="M141.58,190.3l-.72-.19L117.75,184v0a228.93,228.93,0,0,0-1.68-29.77v60.7c.27-1.89.52-4.06.74-6.45l21.88,6.5,2.18-18.55Z"
          style={{
            opacity: 0.2,
            isolation: "isolate",
            transformOrigin: "128.825px 184.605px",
          }}
          className="animable"
        />
      </g>
    </g>
    <g
      id="freepik--Character--inject-2"
      className="animable animator-active"
      style={{
        transformOrigin: "327.591px 279.196px",
      }}
    >
      <g
        id="freepik--group--inject-2"
        className="animable"
        style={{
          transformOrigin: "327.591px 279.196px",
        }}
      >
        <path
          d="M363.13,188.05s10.53,19.72,11.22,24.4S364,246.57,364,246.57s2,7.89-.46,9.14-6.88,3.79-7.43,1.52.27-9.7.27-9.7l1.89-1.53s7.41-30.89,7.45-32-11.36-19.73-11.36-19.73Z"
          style={{
            fill: "rgb(159, 91, 83)",
            transformOrigin: "364.371px 223.095px",
          }}
          id="el0qr8jp47u5zb"
          className="animable"
        />
        <path
          d="M375,213.46c-.06,3.61-7.35,27.06-7.35,27.06l-8.52-1s4.37-23.23,4.36-25.49a9.79,9.79,0,0,0-1.31-3.3c-1.25-2.35-3.22-5.47-5.12-8.36-2.82-4.29-5.5-8.07-5.5-8.07l7-5.66s.52-1.6,1.27-3.26c.89-2,2.11-4,3.17-3.68C367,183,375.11,209.86,375,213.46Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "363.281px 211.093px",
          }}
          id="elpy6zz0oj4i"
          className="animable"
        />
        <polygon
          points="359.28 235.93 369.93 237.7 367.69 240.53 359.17 239.48 359.28 235.93"
          style={{
            fill: "rgb(176, 190, 197)",
            transformOrigin: "364.55px 238.23px",
          }}
          id="elrmdu7qn67p"
          className="animable"
        />
        <g id="elz26zw15nwks">
          <polygon
            points="359.28 235.93 369.93 237.7 367.69 240.53 359.17 239.48 359.28 235.93"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.6,
              transformOrigin: "364.55px 238.23px",
            }}
            className="animable"
          />
        </g>
        <g id="elgr5yism5e7k">
          <path
            d="M362.22,210.69c-1.25-2.35-3.22-5.47-5.12-8.36-2.82-4.29-5.5-8.07-5.5-8.07l7-5.66s.52-1.6,1.27-3.26C361.81,188.94,367.2,200.42,362.22,210.69Z"
            style={{
              opacity: 0.2,
              isolation: "isolate",
              transformOrigin: "357.938px 198.015px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M334,166.58a8.79,8.79,0,0,0,.32,1,10.28,10.28,0,0,0,5,5.66,9.26,9.26,0,0,0,7.95.36,9.36,9.36,0,0,0,3-2.1c3.68-3.72,3.58-8,4-15.26a10,10,0,0,0-13.74-9.93C333.35,149.24,332.38,160,334,166.58Z"
          style={{
            fill: "rgb(159, 91, 83)",
            transformOrigin: "343.809px 159.951px",
          }}
          id="elyvpjecofhmr"
          className="animable"
        />
        <g id="elzjkf2j20i69">
          <path
            d="M337.9,172.45a10.11,10.11,0,0,0,1.33.82,9.26,9.26,0,0,0,7.95.36,10.68,10.68,0,0,0-1.3-2.47A14.3,14.3,0,0,0,337.9,172.45Z"
            style={{
              opacity: 0.2,
              isolation: "isolate",
              transformOrigin: "342.54px 172.702px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M352.51,181.87c-3.58,3.25-12.34,2-12.34,2s-5.47-1.5-4.32-3.21c3.22-.53,4.78-2,5.42-4a9.29,9.29,0,0,0,.33-3.68,20.55,20.55,0,0,0-.27-2l10.83-8C350.74,168.29,349,178,352.51,181.87Z"
          style={{
            fill: "rgb(159, 91, 83)",
            transformOrigin: "344.101px 173.543px",
          }}
          id="els5d2mdraah8"
          className="animable"
        />
        <path
          d="M342.54,143.21s-1.92-1.18-5.2,1.91c-2.76,2.61,0,4.7-1.65,8s.58,6.62.58,6.62,1.6,3.44,4.37,3.22c0,0,2.26,2.74,1.9,5.57s3.7,5.07,9.24,5,3.33-3.53,3.33-3.53a22.83,22.83,0,0,0,3.84-6.22,21.35,21.35,0,0,0,1.57-8.67,11.59,11.59,0,0,0-3.2-8.4C353.63,142.59,344.81,140.5,342.54,143.21Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "347.805px 157.761px",
          }}
          id="elnljyxljyat"
          className="animable"
        />
        <path
          d="M342,161.42a4.72,4.72,0,0,1-2.41,3.23c-1.48.62-2.45-1.46-2.24-3.64.2-1.94,1.21-4.66,2.79-4.3S342.54,159.47,342,161.42Z"
          style={{
            fill: "rgb(159, 91, 83)",
            transformOrigin: "339.744px 160.719px",
          }}
          id="elg12wcwjkxub"
          className="animable"
        />
        <path
          d="M353.39,181.18l-1.6-4.12S341,175,337.34,180.36,353.39,181.18,353.39,181.18Z"
          style={{
            fill: "rgb(176, 190, 197)",
            transformOrigin: "345.138px 179.823px",
          }}
          id="eldksyfwoc68k"
          className="animable"
        />
        <g id="el5k2kw5tva5g">
          <path
            d="M353.39,181.18l-1.6-4.12S341,175,337.34,180.36,353.39,181.18,353.39,181.18Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.6,
              transformOrigin: "345.138px 179.823px",
            }}
            className="animable"
          />
        </g>
        <polygon
          points="324.59 409.47 317.02 410.26 316.64 405.87 315.6 393.93 323.17 393.13 324.32 406.32 324.59 409.47"
          style={{
            fill: "rgb(159, 91, 83)",
            transformOrigin: "320.095px 401.695px",
          }}
          id="elkpd9ox3xw1d"
          className="animable"
        />
        <polygon
          points="362.38 408.5 355.05 408.5 354.98 406.84 354.97 406.64 354.6 395.99 354.59 395.78 354.44 391.7 361.79 391.7 362.38 408.5"
          style={{
            fill: "rgb(159, 91, 83)",
            transformOrigin: "358.41px 400.1px",
          }}
          id="eleri736z9xw"
          className="animable"
        />
        <g id="elg7e49n8m19t">
          <polygon
            points="323.17 393.13 324.32 406.32 316.64 405.87 315.6 393.93 323.17 393.13"
            style={{
              opacity: 0.2,
              isolation: "isolate",
              transformOrigin: "319.96px 399.725px",
            }}
            className="animable"
          />
        </g>
        <g id="elu970gtaysam">
          <polygon
            points="362.34 407.57 362.33 407.57 354.98 406.64 354.97 406.64 354.59 396.37 354.6 396.37 361.95 396.95 362.33 407.25 362.34 407.57"
            style={{
              opacity: 0.2,
              isolation: "isolate",
              transformOrigin: "358.465px 401.97px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M354,408.06h8.91a.65.65,0,0,1,.63.51L365,415.1a1.09,1.09,0,0,1-.84,1.29l-.24,0c-2.88-.05-5-.22-8.6-.22-2.24,0-9,.23-12,.23s-3.48-3.05-2.22-3.33c5.66-1.23,9.91-2.94,11.73-4.56A1.74,1.74,0,0,1,354,408.06Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "352.736px 412.23px",
          }}
          id="elb39s7kjdzcd"
          className="animable"
        />
        <path
          d="M316.22,407.83h8.91a.65.65,0,0,1,.63.5l1.45,6.54a1.08,1.08,0,0,1-.84,1.28,1,1,0,0,1-.24,0c-2.88-.05-5-.23-8.61-.23-2.23,0-9,.24-12,.24s-3.48-3.05-2.22-3.33c5.66-1.23,9.91-2.94,11.72-4.56A1.78,1.78,0,0,1,316.22,407.83Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "314.941px 411.995px",
          }}
          id="el88mgplzvskj"
          className="animable"
        />
        <path
          d="M314.9,404.77h10.77s-.88-92.67,10.76-124c.83,10.34,1.75,21.08,2.66,30,.56,5.54,1.13,10.29,1.66,13.92,3.39,23.34,11.94,80.95,11.94,80.95h10.63s-1.26-55.21-2.52-78.15c-1.39-24.89-2-79.63-2-84.3H319.81C316.1,262.86,306.6,341.28,314.9,404.77Z"
          style={{
            fill: "rgb(176, 190, 197)",
            transformOrigin: "337.457px 324.415px",
          }}
          id="el8rfx5cql7n4"
          className="animable"
        />
        <g id="el3ao9vuw4ara">
          <path
            d="M336.43,280.77c.83,10.34,1.75,21.1,2.66,30,1.32-12.91,3-22.12,3-22.12s1.52-1.25,5.7-7.92,0-19.14,0-19.14C347.87,273.2,336.43,280.77,336.43,280.77Z"
            style={{
              opacity: 0.2,
              isolation: "isolate",
              transformOrigin: "343.039px 286.18px",
            }}
            className="animable"
          />
        </g>
        <polygon
          points="313.7 401.74 314.22 404.77 326.94 404.77 326.94 401.97 313.7 401.74"
          style={{
            fill: "rgb(176, 190, 197)",
            transformOrigin: "320.32px 403.255px",
          }}
          id="el386jh4suqzl"
          className="animable"
        />
        <polygon
          points="351.47 402.9 352.69 405.7 363.36 405.7 364.06 402.9 351.47 402.9"
          style={{
            fill: "rgb(176, 190, 197)",
            transformOrigin: "357.765px 404.3px",
          }}
          id="el5csqne29vne"
          className="animable"
        />
        <path
          d="M359.72,245.85s-1.5-16.93.73-42.69c.58-6.63,1.4-13.84,2.56-21.5,0,0-21.19-4.68-36,0,0,0-.6,3.13-1.44,8.46-.51,3.22-1.1,7.23-1.69,11.83h0c0,.17-.05.35-.07.52-1.47,11.23-4.27,27.64-4.55,42.71Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "341.135px 212.715px",
          }}
          id="elkqszyd03y6s"
          className="animable"
        />
        <g id="el5p3zc6yvyc2">
          <path
            d="M323.85,202.48c3-2.71,4.58-12.84,4.72-13.23l-3,.87c-.51,3.22-1.1,7.23-1.69,11.83h0C323.9,202.13,323.87,202.31,323.85,202.48Z"
            style={{
              opacity: 0.2,
              isolation: "isolate",
              transformOrigin: "326.21px 195.865px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M325,192.18s-16.14.07-21.3-1.49S288,165,288,165l-7.82-3.81s1.77-6,4.1-6.22,7.17,5.78,7.69,7.08,14,18.78,14.72,19.73,20.1.06,20.1.06Z"
          style={{
            fill: "rgb(159, 91, 83)",
            transformOrigin: "303.485px 173.572px",
          }}
          id="el91eruvunx2"
          className="animable"
        />
        <path
          d="M329.72,180.93c-5.16,1-20.91.83-22.38.29s-11.08-14.64-11.08-14.64l-6,6.65s8.93,17.64,11.27,19,23.27,3.16,23.27,3.16C330.61,189.42,329.72,180.93,329.72,180.93Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "310.014px 180.985px",
          }}
          id="elwcy85ij0lca"
          className="animable"
        />
        <g id="elooeft3heim9">
          <polygon
            points="313.7 401.74 314.22 404.77 326.94 404.77 326.94 401.97 313.7 401.74"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.6,
              transformOrigin: "320.32px 403.255px",
            }}
            className="animable"
          />
        </g>
        <g id="elhdtj0oojk04">
          <polygon
            points="351.47 402.9 352.69 405.7 363.36 405.7 364.06 402.9 351.47 402.9"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.6,
              transformOrigin: "357.765px 404.3px",
            }}
            className="animable"
          />
        </g>
        <polygon
          points="292.07 176.66 297.97 169.06 296.26 166.58 290.29 173.23 292.07 176.66"
          style={{
            fill: "rgb(176, 190, 197)",
            transformOrigin: "294.13px 171.62px",
          }}
          id="elg71ez574keu"
          className="animable"
        />
        <g id="elco639d0kb9">
          <polygon
            points="292.07 176.66 297.97 169.06 296.26 166.58 290.29 173.23 292.07 176.66"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.6,
              transformOrigin: "294.13px 171.62px",
            }}
            className="animable"
          />
        </g>
      </g>
    </g>
    <defs>
      <filter id="active" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter id="hover" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#ff0000" floodOpacity={0.5} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
        <feColorMatrix
          type="matrix"
          values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
        />
      </filter>
    </defs>
  </svg>
);
export default SvgComponent;

import React from "react";
import SubPageHead from "../Components/SubPageHead";
import ProductData from "../Products/ProductData";
// import ImageScroll from "../Components/ImageScroll";
import { Link } from "react-router-dom";

const Products = () => {
  return (
    <>
      <div className="products">
        <div className="products-heading">
          <SubPageHead Header="Products" />
        </div>
        <div className="product-heading-sub"></div>
        <div className="container">
          <div className="product-main-grid">
            {ProductData.map((current, index) => {
              return (
                <>
                  <Link
                    to={`/Products/${current.id}`}
                    className="product-grid"
                    key={index}
                  >
                    <div className="product-grid-hover"></div>
                    <div className="product-grid-sub-1">
                      <h3>{current.no}</h3>
                    </div>
                    <div className="product-grid-sub-2">
                      <h4>{current.productname}</h4>
                      <p>{current.description}</p>
                      {/* <div className="product-grid-scroll">
                        <ImageScroll
                          ImageScrollImage={current.logoimg}
                          ImgAlt={current.productname}
                        />
                      </div> */}
                    </div>
                  </Link>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;

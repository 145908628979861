import * as React from "react";
import "./ProductsSvg.css";
const SvgComponent = () => (
  <svg
    className="animated"
    id="freepik_stories-secure-data"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlnssvgjs="http://svgjs.com/svgjs"
  >
    <g
      id="freepik--background-complete--inject-19"
      className="animable animator-active"
      style={{
        transformOrigin: "219.474px 237.76px",
      }}
    >
      <polygon
        points="262.32 120.67 211.01 120.67 211.01 54.8 249.11 54.8 262.32 67.35 262.32 120.67"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "236.665px 87.735px",
        }}
        id="el9xk3dg6adnc"
        className="animable"
      />
      <polygon
        points="249.11 54.8 262.32 67.35 249.11 67.35 249.11 54.8"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "255.715px 61.075px",
        }}
        id="elpdg47waosmj"
        className="animable"
      />
      <g id="el4bv90rg7ij9">
        <rect
          x={216.01}
          y={65.85}
          width={28.53}
          height={1.69}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "230.275px 66.695px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elzzhyxn5q00j">
        <rect
          x={216.01}
          y={70.12}
          width={28.53}
          height={1.69}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "230.275px 70.965px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elcsw58ug98l7">
        <rect
          x={216.01}
          y={74.39}
          width={28.53}
          height={1.69}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "230.275px 75.235px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="els9633xmoxom">
        <rect
          x={216.01}
          y={78.66}
          width={41.8}
          height={1.69}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "236.91px 79.505px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el98klp0br3ci">
        <rect
          x={216.01}
          y={82.93}
          width={41.8}
          height={1.69}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "236.91px 83.775px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elt89zlk8pz7">
        <rect
          x={216.01}
          y={87.2}
          width={41.8}
          height={1.69}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "236.91px 88.045px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elczzf89kity">
        <rect
          x={216.01}
          y={91.47}
          width={41.8}
          height={1.69}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "236.91px 92.315px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elivg4l2jkidn">
        <rect
          x={216.01}
          y={95.74}
          width={41.8}
          height={1.69}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "236.91px 96.585px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elnd7rq59g3sk">
        <rect
          x={216.01}
          y={100.01}
          width={41.8}
          height={1.69}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "236.91px 100.855px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elt51x7uidtur">
        <rect
          x={216.01}
          y={104.28}
          width={41.8}
          height={1.69}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "236.91px 105.125px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elbqh0sau4f8h">
        <rect
          x={216.01}
          y={108.55}
          width={41.8}
          height={1.69}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "236.91px 109.395px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el9vpyjd86w2e">
        <rect
          x={216.01}
          y={112.82}
          width={41.8}
          height={1.69}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "236.91px 113.665px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el3lyudyc8m08">
        <circle
          cx={262.32}
          cy={118.36}
          r={9}
          style={{
            fill: "rgb(199, 199, 199)",
            transformOrigin: "262.32px 118.36px",
            transform: "rotate(-22.63deg)",
          }}
          className="animable"
        />
      </g>
      <rect
        x={257.86}
        y={116.08}
        width={8.92}
        height={8.1}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "262.32px 120.13px",
        }}
        id="elehoo08ff05e"
        className="animable"
      />
      <path
        d="M265.89,118.58H265V115a2.67,2.67,0,1,0-5.33,0v3.61h-.91V115a3.57,3.57,0,0,1,7.14,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "262.33px 115.02px",
        }}
        id="el3i02gkqjdgv"
        className="animable"
      />
      <path
        d="M262.7,120.23l.37,1.77h-1.58l.37-1.77a.8.8,0,0,1-.37-.67.79.79,0,0,1,1.58,0A.8.8,0,0,1,262.7,120.23Z"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "262.28px 120.387px",
        }}
        id="elso5q9z0t14"
        className="animable"
      />
      <path
        d="M329.1,147.48l.13,51.25a5.19,5.19,0,0,1-5.17,5.21l-81.28.21a5.19,5.19,0,0,1-5.2-5.18l-.13-51.25a4.82,4.82,0,0,1,4.81-4.84l82-.21A4.82,4.82,0,0,1,329.1,147.48Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "283.34px 173.41px",
        }}
        id="el3wus1r030vx"
        className="animable"
      />
      <path
        d="M316,191.55a4.77,4.77,0,1,1-4.78-4.76A4.77,4.77,0,0,1,316,191.55Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "311.23px 191.56px",
        }}
        id="elb3sm45pqvjl"
        className="animable"
      />
      <path
        d="M321.88,191.53a4.77,4.77,0,1,1-4.79-4.76A4.77,4.77,0,0,1,321.88,191.53Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "317.11px 191.54px",
        }}
        id="elnuhw34kywfn"
        className="animable"
      />
      <g id="elf9csbfccxwi">
        <rect
          x={245.17}
          y={151.65}
          width={17.2}
          height={11.89}
          rx={4.2}
          style={{
            fill: "rgb(176, 190, 197)",
            transformOrigin: "253.77px 157.595px",
            transform: "rotate(-0.15deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M318.53,158l-7.78,0a3.26,3.26,0,0,1-3.27-3.26h0a3.25,3.25,0,0,1,3.25-3.26l7.78,0a3.25,3.25,0,0,1,3.27,3.25h0A3.26,3.26,0,0,1,318.53,158Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "314.63px 154.74px",
        }}
        id="el6kipif4hcw9"
        className="animable"
      />
      <g id="eldgyyip4j5j8">
        <rect
          x={245.25}
          y={187.39}
          width={43.52}
          height={2.14}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "267.01px 188.46px",
            transform: "rotate(-0.15deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el4mkyymy518a">
        <rect
          x={245.27}
          y={192.16}
          width={30.81}
          height={2.14}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "260.675px 193.23px",
            transform: "rotate(-0.15deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eljdpa1l434jf">
        <rect
          x={282.77}
          y={151.55}
          width={21.71}
          height={2.14}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "293.625px 152.62px",
            transform: "rotate(-0.15deg)",
          }}
          className="animable"
        />
      </g>
      <rect
        x={246.88}
        y={175.94}
        width={0.3}
        height={3.25}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "247.03px 177.565px",
        }}
        id="elgma1amt5xcg"
        className="animable"
      />
      <rect
        x={245.4}
        y={177.42}
        width={3.25}
        height={0.3}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "247.025px 177.57px",
        }}
        id="elap8puyiov0h"
        className="animable"
      />
      <g id="elmlaycmjcldt">
        <rect
          x={245.4}
          y={177.42}
          width={3.26}
          height={0.3}
          style={{
            fill: "rgb(199, 199, 199)",
            transformOrigin: "247.03px 177.57px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el7eg01dmoy8l">
        <rect
          x={246.88}
          y={175.94}
          width={0.3}
          height={3.26}
          style={{
            fill: "rgb(199, 199, 199)",
            transformOrigin: "247.03px 177.57px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <rect
        x={250.79}
        y={175.94}
        width={0.3}
        height={3.25}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "250.94px 177.565px",
        }}
        id="ellmvbwoc7l7"
        className="animable"
      />
      <rect
        x={249.32}
        y={177.42}
        width={3.25}
        height={0.3}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "250.945px 177.57px",
        }}
        id="elwqi4wnc9z4l"
        className="animable"
      />
      <g id="elz34d1on2u1e">
        <rect
          x={249.32}
          y={177.42}
          width={3.25}
          height={0.3}
          style={{
            fill: "rgb(199, 199, 199)",
            transformOrigin: "250.945px 177.57px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eltfapr7j8tlo">
        <rect
          x={250.79}
          y={175.94}
          width={0.3}
          height={3.25}
          style={{
            fill: "rgb(199, 199, 199)",
            transformOrigin: "250.94px 177.565px",
            transform: "rotate(-44.99deg)",
          }}
          className="animable"
        />
      </g>
      <rect
        x={254.71}
        y={175.94}
        width={0.3}
        height={3.25}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "254.86px 177.565px",
        }}
        id="elku48m60s28"
        className="animable"
      />
      <rect
        x={253.23}
        y={177.42}
        width={3.25}
        height={0.3}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "254.855px 177.57px",
        }}
        id="el8a27duecoes"
        className="animable"
      />
      <polygon
        points="253.81 178.82 253.6 178.61 255.9 176.31 256.12 176.52 253.81 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "254.86px 177.565px",
        }}
        id="elpb0ooo3if6k"
        className="animable"
      />
      <polygon
        points="255.9 178.82 253.6 176.52 253.81 176.31 256.12 178.61 255.9 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "254.86px 177.565px",
        }}
        id="elstu5afbxw4o"
        className="animable"
      />
      <rect
        x={258.62}
        y={175.94}
        width={0.3}
        height={3.25}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "258.77px 177.565px",
        }}
        id="elmvnjitov9q"
        className="animable"
      />
      <rect
        x={257.15}
        y={177.42}
        width={3.25}
        height={0.3}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "258.775px 177.57px",
        }}
        id="el9f92n32mknp"
        className="animable"
      />
      <polygon
        points="257.73 178.82 257.52 178.61 259.82 176.31 260.03 176.52 257.73 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "258.775px 177.565px",
        }}
        id="ela11qonsg5g5"
        className="animable"
      />
      <polygon
        points="259.82 178.82 257.52 176.52 257.73 176.31 260.03 178.61 259.82 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "258.775px 177.565px",
        }}
        id="elce7v4enzi6o"
        className="animable"
      />
      <rect
        x={267.17}
        y={175.94}
        width={0.3}
        height={3.25}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "267.32px 177.565px",
        }}
        id="elpzkl2zhah5g"
        className="animable"
      />
      <rect
        x={265.69}
        y={177.42}
        width={3.25}
        height={0.3}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "267.315px 177.57px",
        }}
        id="el89xovdsz5cs"
        className="animable"
      />
      <polygon
        points="266.28 178.82 266.06 178.61 268.36 176.31 268.58 176.52 266.28 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "267.32px 177.565px",
        }}
        id="elt9vzd288v1"
        className="animable"
      />
      <polygon
        points="268.36 178.82 266.06 176.52 266.28 176.31 268.58 178.61 268.36 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "267.32px 177.565px",
        }}
        id="elhdh26pv7snm"
        className="animable"
      />
      <rect
        x={271.08}
        y={175.94}
        width={0.3}
        height={3.25}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "271.23px 177.565px",
        }}
        id="elwl1ycgo0c4d"
        className="animable"
      />
      <rect
        x={269.61}
        y={177.42}
        width={3.25}
        height={0.3}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "271.235px 177.57px",
        }}
        id="el1z5v4v20bn7"
        className="animable"
      />
      <polygon
        points="270.19 178.82 269.98 178.61 272.28 176.31 272.49 176.52 270.19 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "271.235px 177.565px",
        }}
        id="elwck7onkepta"
        className="animable"
      />
      <polygon
        points="272.28 178.82 269.98 176.52 270.19 176.31 272.49 178.61 272.28 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "271.235px 177.565px",
        }}
        id="el9bmzqud0ece"
        className="animable"
      />
      <rect
        x={275}
        y={175.94}
        width={0.3}
        height={3.25}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "275.15px 177.565px",
        }}
        id="el23elcsoqqby"
        className="animable"
      />
      <rect
        x={273.52}
        y={177.42}
        width={3.25}
        height={0.3}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "275.145px 177.57px",
        }}
        id="elyx4iptw665"
        className="animable"
      />
      <polygon
        points="274.11 178.82 273.89 178.61 276.19 176.31 276.41 176.52 274.11 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "275.15px 177.565px",
        }}
        id="elrolwlyze7g"
        className="animable"
      />
      <polygon
        points="276.19 178.82 273.89 176.52 274.11 176.31 276.41 178.61 276.19 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "275.15px 177.565px",
        }}
        id="eli268xafg69e"
        className="animable"
      />
      <rect
        x={278.92}
        y={175.94}
        width={0.3}
        height={3.25}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "279.07px 177.565px",
        }}
        id="el9tc6aa5f2nm"
        className="animable"
      />
      <rect
        x={277.44}
        y={177.42}
        width={3.25}
        height={0.3}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "279.065px 177.57px",
        }}
        id="elmu1zavp8kze"
        className="animable"
      />
      <polygon
        points="278.02 178.82 277.81 178.61 280.11 176.31 280.32 176.52 278.02 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "279.065px 177.565px",
        }}
        id="elvj478kqfh2c"
        className="animable"
      />
      <polygon
        points="280.11 178.82 277.81 176.52 278.02 176.31 280.32 178.61 280.11 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "279.065px 177.565px",
        }}
        id="elinmwi6mzk"
        className="animable"
      />
      <rect
        x={287.46}
        y={175.94}
        width={0.3}
        height={3.25}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "287.61px 177.565px",
        }}
        id="elt9ylcg1zupd"
        className="animable"
      />
      <rect
        x={285.99}
        y={177.42}
        width={3.25}
        height={0.3}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "287.615px 177.57px",
        }}
        id="elo873pk40rc"
        className="animable"
      />
      <polygon
        points="286.57 178.82 286.36 178.61 288.66 176.31 288.87 176.52 286.57 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "287.615px 177.565px",
        }}
        id="elu0c3380r4r"
        className="animable"
      />
      <polygon
        points="288.66 178.82 286.36 176.52 286.57 176.31 288.87 178.61 288.66 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "287.615px 177.565px",
        }}
        id="elz5cuwqbllt"
        className="animable"
      />
      <rect
        x={291.38}
        y={175.94}
        width={0.3}
        height={3.25}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "291.53px 177.565px",
        }}
        id="elzi7hk0qj6b9"
        className="animable"
      />
      <rect
        x={289.9}
        y={177.42}
        width={3.25}
        height={0.3}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "291.525px 177.57px",
        }}
        id="elv6436zmamsk"
        className="animable"
      />
      <polygon
        points="290.48 178.82 290.27 178.61 292.57 176.31 292.79 176.52 290.48 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "291.53px 177.565px",
        }}
        id="elva5bpzwd5b"
        className="animable"
      />
      <polygon
        points="292.57 178.82 290.27 176.52 290.48 176.31 292.79 178.61 292.57 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "291.53px 177.565px",
        }}
        id="el17ch642cr9l"
        className="animable"
      />
      <rect
        x={295.29}
        y={175.94}
        width={0.3}
        height={3.25}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "295.44px 177.565px",
        }}
        id="elai4p8v8rn2p"
        className="animable"
      />
      <rect
        x={293.82}
        y={177.42}
        width={3.25}
        height={0.3}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "295.445px 177.57px",
        }}
        id="elff939k56b0v"
        className="animable"
      />
      <polygon
        points="294.4 178.82 294.19 178.61 296.49 176.31 296.7 176.52 294.4 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "295.445px 177.565px",
        }}
        id="eln0x2yhjg4b"
        className="animable"
      />
      <polygon
        points="296.49 178.82 294.19 176.52 294.4 176.31 296.7 178.61 296.49 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "295.445px 177.565px",
        }}
        id="elw7d3ib8e4wc"
        className="animable"
      />
      <rect
        x={299.21}
        y={175.94}
        width={0.3}
        height={3.25}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "299.36px 177.565px",
        }}
        id="el382r4v1fbdq"
        className="animable"
      />
      <rect
        x={297.73}
        y={177.42}
        width={3.25}
        height={0.3}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "299.355px 177.57px",
        }}
        id="elc2zjrh5jku"
        className="animable"
      />
      <polygon
        points="298.31 178.82 298.1 178.61 300.4 176.31 300.62 176.52 298.31 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "299.36px 177.565px",
        }}
        id="ela01khly1uhf"
        className="animable"
      />
      <polygon
        points="300.4 178.82 298.1 176.52 298.31 176.31 300.62 178.61 300.4 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "299.36px 177.565px",
        }}
        id="elx4ox6qcu4da"
        className="animable"
      />
      <rect
        x={307.75}
        y={175.94}
        width={0.3}
        height={3.25}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "307.9px 177.565px",
        }}
        id="el7dm8douggbm"
        className="animable"
      />
      <rect
        x={306.28}
        y={177.42}
        width={3.25}
        height={0.3}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "307.905px 177.57px",
        }}
        id="elmopyiub0tc"
        className="animable"
      />
      <polygon
        points="306.86 178.82 306.65 178.61 308.95 176.31 309.16 176.52 306.86 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "307.905px 177.565px",
        }}
        id="elmq7amq31u9"
        className="animable"
      />
      <polygon
        points="308.95 178.82 306.65 176.52 306.86 176.31 309.16 178.61 308.95 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "307.905px 177.565px",
        }}
        id="elz8sifaplvv"
        className="animable"
      />
      <rect
        x={311.67}
        y={175.94}
        width={0.3}
        height={3.25}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "311.82px 177.565px",
        }}
        id="elk0iodax2lfi"
        className="animable"
      />
      <rect
        x={310.19}
        y={177.42}
        width={3.25}
        height={0.3}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "311.815px 177.57px",
        }}
        id="elbmu79b8mtdl"
        className="animable"
      />
      <polygon
        points="310.78 178.82 310.56 178.61 312.87 176.31 313.08 176.52 310.78 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "311.82px 177.565px",
        }}
        id="elrf8bhlaax8"
        className="animable"
      />
      <polygon
        points="312.87 178.82 310.56 176.52 310.78 176.31 313.08 178.61 312.87 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "311.82px 177.565px",
        }}
        id="elk2h4ora1zom"
        className="animable"
      />
      <rect
        x={315.59}
        y={175.94}
        width={0.3}
        height={3.25}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "315.74px 177.565px",
        }}
        id="eltn1cfyncu09"
        className="animable"
      />
      <rect
        x={314.11}
        y={177.42}
        width={3.25}
        height={0.3}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "315.735px 177.57px",
        }}
        id="elog9paxkuqc"
        className="animable"
      />
      <polygon
        points="314.69 178.82 314.48 178.61 316.78 176.31 316.99 176.52 314.69 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "315.735px 177.565px",
        }}
        id="elueeb1is11v"
        className="animable"
      />
      <polygon
        points="316.78 178.82 314.48 176.52 314.69 176.31 316.99 178.61 316.78 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "315.735px 177.565px",
        }}
        id="elllh7n9vr0pa"
        className="animable"
      />
      <rect
        x={319.5}
        y={175.94}
        width={0.3}
        height={3.25}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "319.65px 177.565px",
        }}
        id="elptzn6nzvu6m"
        className="animable"
      />
      <rect
        x={318.02}
        y={177.42}
        width={3.25}
        height={0.3}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "319.645px 177.57px",
        }}
        id="elrigjkgnew3j"
        className="animable"
      />
      <polygon
        points="318.61 178.82 318.39 178.61 320.69 176.31 320.91 176.52 318.61 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "319.65px 177.565px",
        }}
        id="el36da1wx611f"
        className="animable"
      />
      <polygon
        points="320.69 178.82 318.39 176.52 318.61 176.31 320.91 178.61 320.69 178.82"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "319.65px 177.565px",
        }}
        id="el147dbzn4zvq"
        className="animable"
      />
      <g id="elv6kdna7lr3o">
        <circle
          cx={329.23}
          cy={203.89}
          r={9}
          style={{
            fill: "rgb(199, 199, 199)",
            transformOrigin: "329.23px 203.89px",
            transform: "rotate(-80.76deg)",
          }}
          className="animable"
        />
      </g>
      <rect
        x={324.77}
        y={201.62}
        width={8.92}
        height={8.1}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "329.23px 205.67px",
        }}
        id="el5194c65x5sr"
        className="animable"
      />
      <path
        d="M332.8,204.12h-.9V200.5a2.67,2.67,0,0,0-5.33,0v3.62h-.91V200.5a3.57,3.57,0,0,1,7.14,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "329.23px 200.525px",
        }}
        id="elsssn5imy6tt"
        className="animable"
      />
      <path
        d="M329.61,205.77l.37,1.77H328.4l.37-1.77a.8.8,0,0,1-.37-.67.79.79,0,0,1,1.58,0A.8.8,0,0,1,329.61,205.77Z"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "329.19px 205.927px",
        }}
        id="elyjbm0qri37c"
        className="animable"
      />
      <g id="el90i4tx2y1ym">
        <rect
          x={120.1}
          y={78.29}
          width={61.55}
          height={44.65}
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "150.875px 100.615px",
            transform: "rotate(-170.5deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elrzjmuhyth2c">
        <rect
          x={122.66}
          y={80.85}
          width={56.44}
          height={39.53}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "150.88px 100.615px",
            transform: "rotate(-170.5deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M131,88.63a4.67,4.67,0,1,0,5.38-3.84A4.68,4.68,0,0,0,131,88.63Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "135.607px 89.3963px",
        }}
        id="elfrvzhpg4v1j"
        className="animable"
      />
      <polygon
        points="145.9 111.19 176.86 116.38 164.89 92.8 145.9 111.19"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "161.38px 104.59px",
        }}
        id="el0cag3u3lnrt"
        className="animable"
      />
      <polygon
        points="133.77 109.17 159.32 113.45 148.83 97.65 133.77 109.17"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "146.545px 105.55px",
        }}
        id="el7k67p4znb3a"
        className="animable"
      />
      <g id="elltoeze03f0d">
        <rect
          x={120.1}
          y={111.65}
          width={56.44}
          height={8.52}
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "148.32px 115.91px",
            transform: "rotate(9.5deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elb9aj8642ns8">
        <rect
          x={99.39}
          y={86.81}
          width={61.55}
          height={44.65}
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "130.165px 109.135px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el85hylskdtg9">
        <rect
          x={101.95}
          y={89.38}
          width={56.44}
          height={39.53}
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "130.17px 109.145px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M108.54,100.61a4.68,4.68,0,1,0,4.68-4.67A4.68,4.68,0,0,0,108.54,100.61Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "113.22px 100.62px",
        }}
        id="elk5d48o5gq7m"
        className="animable"
      />
      <polygon
        points="127.01 120.4 158.4 120.4 142.7 99.12 127.01 120.4"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "142.705px 109.76px",
        }}
        id="el0brkrro8gjat"
        className="animable"
      />
      <polygon
        points="114.7 120.41 140.62 120.41 127.66 106.55 114.7 120.41"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "127.66px 113.48px",
        }}
        id="elzciacq366a"
        className="animable"
      />
      <rect
        x={101.95}
        y={120.39}
        width={56.44}
        height={8.52}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "130.17px 124.65px",
        }}
        id="elawc4fi8o3mk"
        className="animable"
      />
      <rect
        x={106.51}
        y={298.61}
        width={88.24}
        height={122.11}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "150.63px 359.665px",
        }}
        id="el2jhe92gh479"
        className="animable"
      />
      <rect
        x={102.24}
        y={294.08}
        width={96.79}
        height={4.53}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "150.635px 296.345px",
        }}
        id="elqq58yzm9gmq"
        className="animable"
      />
      <rect
        x={199.02}
        y={294.08}
        width={30.44}
        height={4.53}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "214.24px 296.345px",
        }}
        id="elfzqsjz4vua"
        className="animable"
      />
      <rect
        x={194.75}
        y={298.61}
        width={29.9}
        height={122.11}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "209.7px 359.665px",
        }}
        id="elnqq320irkb"
        className="animable"
      />
      <path
        d="M185.26,355.14H116V316h69.26Zm-69-.25H185V316.23H116.25Z"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "150.63px 335.57px",
        }}
        id="el3f5d5t78ybr"
        className="animable"
      />
      <rect
        x={140.97}
        y={327.43}
        width={13.48}
        height={7.75}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "147.71px 331.305px",
        }}
        id="elqoh964liiw"
        className="animable"
      />
      <path
        d="M158.56,334.72H144.83v-6.83h13.73Zm-13.48-.25h13.23v-6.33H145.08Z"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "151.695px 331.305px",
        }}
        id="el6yg9t4bmnpu"
        className="animable"
      />
      <path
        d="M185.26,404.3H116V365.14h69.26Zm-69-.25H185V365.39H116.25Z"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "150.63px 384.72px",
        }}
        id="el74w5nngj27h"
        className="animable"
      />
      <rect
        x={140.97}
        y={376.59}
        width={13.48}
        height={7.75}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "147.71px 380.465px",
        }}
        id="elecj3i8rxrxe"
        className="animable"
      />
      <path
        d="M158.56,383.88H144.83v-6.83h13.73Zm-13.48-.25h13.23V377.3H145.08Z"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "151.695px 380.465px",
        }}
        id="eli29jodti2v9"
        className="animable"
      />
    </g>
    <g
      id="freepik--Floor--inject-19"
      className="animable"
      style={{
        transformOrigin: "250px 420.76px",
      }}
    >
      <polygon
        points="51.54 420.76 101.16 420.51 150.77 420.43 250 420.26 349.23 420.42 398.84 420.51 448.46 420.76 398.84 421 349.23 421.09 250 421.26 150.77 421.09 101.16 421 51.54 420.76"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "250px 420.76px",
        }}
        id="elwgzgjekkbb"
        className="animable"
      />
    </g>
    <g
      id="freepik--Shadows--inject-19"
      className="animable"
      style={{
        transformOrigin: "250.005px 455.47px",
      }}
    >
      <ellipse
        cx={103.3}
        cy={456.14}
        rx={44.28}
        ry={8.5}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "103.3px 456.14px",
        }}
        id="eldn77lv6oapc"
        className="animable"
      />
      <ellipse
        cx={267.97}
        cy={455.47}
        rx={173.02}
        ry={16.97}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "267.97px 455.47px",
        }}
        id="elkd0mhni8pdi"
        className="animable"
      />
    </g>
    <g
      id="freepik--Character--inject-19"
      className="animable"
      style={{
        transformOrigin: "306.687px 280.45px",
      }}
    >
      <polygon
        points="315.63 307.45 312.72 324.93 292.97 431.56 289.79 431.56 304.15 324.93 306.27 307.45 315.63 307.45"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "302.71px 369.505px",
        }}
        id="el4bebxq91v82"
        className="animable"
      />
      <polygon
        points="306.27 307.45 315.63 307.45 312.72 324.93 304.15 324.93 306.27 307.45"
        style={{
          fill: "rgb(166, 166, 166)",
          transformOrigin: "309.89px 316.19px",
        }}
        id="elleej4zap5hk"
        className="animable"
      />
      <polygon
        points="377.64 431.56 374.46 431.56 354.71 324.93 351.8 307.45 361.16 307.45 363.28 324.93 377.64 431.56"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "364.72px 369.505px",
        }}
        id="elg37xgch9w1"
        className="animable"
      />
      <polygon
        points="361.16 307.45 363.28 324.93 354.71 324.93 351.8 307.45 361.16 307.45"
        style={{
          fill: "rgb(166, 166, 166)",
          transformOrigin: "357.54px 316.19px",
        }}
        id="el3ti1kt5f3uq"
        className="animable"
      />
      <polygon
        points="436.87 431.56 433.68 431.56 413.94 324.93 411.02 307.45 420.38 307.45 422.51 324.93 436.87 431.56"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "423.945px 369.505px",
        }}
        id="elwamkcrpgwe"
        className="animable"
      />
      <polygon
        points="422.51 324.93 413.94 324.93 411.02 307.45 420.38 307.45 422.51 324.93"
        style={{
          fill: "rgb(166, 166, 166)",
          transformOrigin: "416.765px 316.19px",
        }}
        id="elcgwsik7eb7d"
        className="animable"
      />
      <polygon
        points="431.82 315.66 287.13 315.66 287.13 300.42 341.29 300.42 369.5 142.4 446.3 142.4 431.82 315.66"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "366.715px 229.03px",
        }}
        id="el3vwm8gcvnpw"
        className="animable"
      />
      <polygon
        points="431.2 300.42 287.13 300.42 287.13 315.66 431.74 315.66 431.2 300.42"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "359.435px 308.04px",
        }}
        id="el4mkqkeua468"
        className="animable"
      />
      <polygon
        points="431.82 315.66 423.93 315.66 438.79 142.4 446.3 142.4 431.82 315.66"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "435.115px 229.03px",
        }}
        id="el6erxy74hqh5"
        className="animable"
      />
      <path
        d="M433.61,120.28c-2.23-2.45-11.36-9.3-19.25-13-3.82-1.83-15.36-5.9-17-3.07s1.65,4.32,1.65,4.32-5.14-1-6.51,2,3.78,5.15,3.78,5.15-5.34-.63-6.5,2.45,4.39,4.38,4.39,4.38-4.86-.8-4.74,2.14c.14,3.54,9.26,4,13.39,6.25.94.5,18.28,11.61,18.28,11.61Z"
        style={{
          fill: "rgb(235, 148, 129)",
          transformOrigin: "411.519px 122.874px",
        }}
        id="elfqqrwhi1xo7"
        className="animable"
      />
      <path
        d="M394.24,122.54c6.71.36,9.59,1.69,15,5.52.11.07.24-.07.14-.16-4.94-4.23-8.31-6.41-15.12-5.69C393.91,122.25,393.92,122.52,394.24,122.54Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "401.707px 125.077px",
        }}
        id="el9ebe4l0dsvn"
        className="animable"
      />
      <path
        d="M396,115.53c6.94,1,10.07,2.39,16,6,.09,0,.18-.09.1-.15-5.54-4-8.94-6.11-16-6.18C395.81,115.18,395.71,115.48,396,115.53Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "403.979px 118.365px",
        }}
        id="elgcywdso56gd"
        className="animable"
      />
      <path
        d="M399.58,108.59c6.65,1.44,10.15,2.83,15.47,7.06.13.1.29-.07.17-.18-5-4.85-9.36-6.62-15.57-7.08C399.22,108.36,399.16,108.5,399.58,108.59Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "407.278px 112.033px",
        }}
        id="ely916poca34r"
        className="animable"
      />
      <path
        d="M424,146.27s-8.36-1.07-11.93-2.35S397,133,398.93,130.38c2.51-3.37,10.27.89,13.8,3.47,0,0,5.8-7.8,12-5.95S424,146.27,424,146.27Z"
        style={{
          fill: "rgb(235, 148, 129)",
          transformOrigin: "413.011px 136.943px",
        }}
        id="ell0irfy9gg6"
        className="animable"
      />
      <path
        d="M399.92,129.68c4.51-1.42,8.92,2.27,12.53,4.52a.39.39,0,0,0,.6-.23c2.65-3.11,7.29-6.72,11.58-6.3a.1.1,0,0,0,0-.2c-4.54-1.07-9.39,2.11-12.08,5.7-3.66-2.37-8.25-5.87-12.68-3.53C399.88,129.65,399.9,129.69,399.92,129.68Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "412.3px 130.764px",
        }}
        id="el1zjbg5tk56c"
        className="animable"
      />
      <path
        d="M353.33,111.82c3-1.33,14.65-4.87,23.38-5.1,4.23-.11,16.43.86,16.77,4.11s-3.26,3.28-3.26,3.28,5.09,1.18,5.13,4.47-5.55,3.17-5.55,3.17,5.14,1.59,4.95,4.88-5.8,2.22-5.8,2.22,4.77,1.24,3.47,3.88c-1.57,3.18-10.1-.1-14.78.26-1.06.08-21.41,3.18-21.41,3.18Z"
        style={{
          fill: "rgb(235, 148, 129)",
          transformOrigin: "374.34px 121.441px",
        }}
        id="el19mr7s01f3z"
        className="animable"
      />
      <path
        d="M388.87,128.89c-6.28-2.39-9.45-2.35-15.93-1-.13,0-.2-.15-.07-.19,6.24-1.86,10.2-2.48,16.13.95C389.29,128.76,389.17,129,388.87,128.89Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "380.983px 127.554px",
        }}
        id="eljxzttm1t26i"
        className="animable"
      />
      <path
        d="M390.08,121.76c-6.74-1.92-10.17-1.91-17.06-1-.1,0-.13-.15,0-.17,6.69-1.42,10.65-2,17.14.86C390.42,121.53,390.39,121.85,390.08,121.76Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "381.635px 120.595px",
        }}
        id="elb3be1swech"
        className="animable"
      />
      <path
        d="M389.66,114c-6.66-1.39-10.42-1.54-17,.16a.12.12,0,0,1-.08-.23c6.55-2.39,11.24-2.24,17.11-.14C390.08,113.91,390.08,114.06,389.66,114Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "381.239px 113.172px",
        }}
        id="el06moudbicf9c"
        className="animable"
      />
      <path
        d="M352,138.47s8.07,2.42,11.84,2.7,18.25-3.84,17.54-7c-.93-4.1-9.75-3.36-14-2.43,0,0-2.13-9.49-8.57-10.33S352,138.47,352,138.47Z"
        style={{
          fill: "rgb(235, 148, 129)",
          transformOrigin: "366.703px 131.282px",
        }}
        id="eliei8d51j7i9"
        className="animable"
      />
      <path
        d="M380.77,133.11c-3.54-3.14-9.07-1.56-13.28-1a.39.39,0,0,1-.46-.45c-1.15-3.91-3.92-9.1-8-10.46-.12,0-.08-.22,0-.19,4.59.86,7.73,5.73,8.72,10.11,4.31-.68,9.93-2,13,1.92C380.82,133.1,380.8,133.13,380.77,133.11Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "369.876px 127.062px",
        }}
        id="el30rxbgpvr7r"
        className="animable"
      />
      <path
        d="M376.33,173.21c-22.88-6.18-58.91-26.87-55.6-36.83,3.21-9.68,16.72-19.8,34-25.18,2.52-.79,2,4.5,3.17,13.72.57,4.36,8.22,16.11,4.53,16.08a15.25,15.25,0,0,0-5.6,1.25c-1.35.52,14.88,11.36,23.95,18.16C393,169.53,386.1,175.84,376.33,173.21Z"
        style={{
          fill: "rgb(235, 148, 129)",
          transformOrigin: "353.918px 142.467px",
        }}
        id="elt764zsxxupr"
        className="animable"
      />
      <path
        d="M343.3,166.19l25.36-15.88s15.23,9.26,19,14.39.8,10.68-11,10.69C363.09,175.4,343.3,166.19,343.3,166.19Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "366.249px 162.85px",
        }}
        id="elsht92llu6e"
        className="animable"
      />
      <g id="elvq3c0a30fpn">
        <g
          style={{
            opacity: 0.1,
            transformOrigin: "367.641px 163.35px",
          }}
          className="animable"
        >
          <path
            d="M375.15,154.49H362l2.34-1.46h8.62Z"
            id="elml6kremfg2"
            className="animable"
            style={{
              transformOrigin: "368.575px 153.76px",
            }}
          />
          <path
            d="M381.84,159.29H354.32l2.33-1.46h23.26Z"
            id="eled771azqsd8"
            className="animable"
            style={{
              transformOrigin: "368.08px 158.56px",
            }}
          />
          <path
            d="M387.15,164.08H346.67l2.33-1.46h36.76C386.29,163.13,386.76,163.62,387.15,164.08Z"
            id="elmaamenfqpkl"
            className="animable"
            style={{
              transformOrigin: "366.91px 163.35px",
            }}
          />
          <path
            d="M389.18,168.88H349.7c-1.39-.54-2.61-1-3.6-1.46H389A5,5,0,0,1,389.18,168.88Z"
            id="ely4tzff9vp7s"
            className="animable"
            style={{
              transformOrigin: "367.641px 168.15px",
            }}
          />
          <path
            d="M387.74,172.22a7.54,7.54,0,0,1-2,1.45H364.5c-1.78-.44-3.54-.93-5.25-1.45Z"
            id="el7qubgoel8a7"
            className="animable"
            style={{
              transformOrigin: "373.495px 172.945px",
            }}
          />
        </g>
      </g>
      <path
        d="M350,168.14c10.21-6.24,20.32-13.28,22-14.65.07-.06,0-.17-.1-.13a220.15,220.15,0,0,0-22,14.65A.08.08,0,0,0,350,168.14Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "360.949px 160.754px",
        }}
        id="elscy7d031vzd"
        className="animable"
      />
      <path
        d="M376.64,175.39a57.73,57.73,0,0,1-14.7-2.39c6.63-4.45,14.51-9.7,20.28-13.41a30.87,30.87,0,0,1,5.43,5.11C391.41,169.82,388.45,175.38,376.64,175.39Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "375.562px 167.49px",
        }}
        id="eln5f0as7xk9q"
        className="animable"
      />
      <path
        d="M292.73,451.88c2.82.27,79.43-18,81.84-20.46.3-.28-1.21-7.24-3.42-16.74l0-.19c-1.39-6-23.7-54.7-23.7-54.7l-49.66,13.84,22.56,45.17S311,425.55,303,432c0,0,0,0,0,0-4.95,3.94-9.22,7.47-10.12,8.62C290.47,443.59,289.93,451.63,292.73,451.88Z"
        style={{
          fill: "rgb(235, 148, 129)",
          transformOrigin: "332.725px 405.836px",
        }}
        id="elqbjgewm9qc"
        className="animable"
      />
      <path
        d="M292.74,451.87c2.8.27,79.41-18,81.84-20.45.29-.28-1.22-7.24-3.43-16.74l0-.18c-.5-2.24-3.94-10.43-8-19.8-8.17,2.19-37.47,10.11-48.11,13.4l5.35,10.71S311,425.56,303,432c0,0,0,0,0,0-4.95,3.93-9.22,7.47-10.12,8.61C290.47,443.59,289.92,451.64,292.74,451.87Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "332.728px 423.286px",
        }}
        id="elgtzxhhvhcol"
        className="animable"
      />
      <path
        d="M292.48,452.24c2.9.25,79.56-18.2,82-20.75.94-1-.75-13.06-4.95-25.88l-49.41,12s-25.23,20-27.65,23.06S289.59,452,292.48,452.24Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "332.606px 428.926px",
        }}
        id="elmgfqs3wlspo"
        className="animable"
      />
      <path
        d="M292.84,448.91c12.78-3.41,64.63-16,77.51-18.35.11,0,.13.05,0,.08-12.58,3.65-64.53,15.78-77.48,18.48C292.62,449.18,292.57,449,292.84,448.91Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "331.549px 439.846px",
        }}
        id="elt709pv4i0me"
        className="animable"
      />
      <path
        d="M296.77,436.69a10.64,10.64,0,0,1,8.45,1.28,10.32,10.32,0,0,1,4.52,6.17c0,.08-.12.12-.15,0a12.28,12.28,0,0,0-12.78-7.33A.08.08,0,0,1,296.77,436.69Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "303.248px 440.27px",
        }}
        id="elyrlwfwubi99"
        className="animable"
      />
      <path
        d="M316.44,419.82c5.13-1.31,11.53-.93,15.74,2.51.16.14,0,.36-.2.32a73.39,73.39,0,0,0-15.48-2.17A.34.34,0,0,1,316.44,419.82Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "324.226px 420.889px",
        }}
        id="elk79xf7rzfl"
        className="animable"
      />
      <path
        d="M312.84,422.33c5.13-1.31,11.53-.93,15.74,2.51.16.13,0,.36-.2.32A73.4,73.4,0,0,0,312.91,423,.33.33,0,0,1,312.84,422.33Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "320.593px 423.399px",
        }}
        id="el6edasy0me2o"
        className="animable"
      />
      <path
        d="M309.24,424.83c5.13-1.31,11.53-.93,15.74,2.51.16.13,0,.36-.2.32a72.74,72.74,0,0,0-15.47-2.17A.34.34,0,0,1,309.24,424.83Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "317.027px 425.899px",
        }}
        id="el0hz7t01v2bw"
        className="animable"
      />
      <path
        d="M305.65,427.34c5.12-1.31,11.52-.93,15.73,2.5.16.14,0,.37-.2.33A73.4,73.4,0,0,0,305.71,428,.33.33,0,0,1,305.65,427.34Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "313.397px 428.409px",
        }}
        id="elw35na7eil5h"
        className="animable"
      />
      <path
        d="M364.14,419.14c5.78-1.37,7.92,7.6,2.14,9S358.36,420.51,364.14,419.14Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "365.21px 423.643px",
        }}
        id="elhay8b2c40wg"
        className="animable"
      />
      <path
        d="M309.8,414.88c3.64,2.91,8.41,4.15,12.68,5.8a.65.65,0,0,0,.83-.64.13.13,0,0,0,.12-.18,27.61,27.61,0,0,0-7.84-12c-2.14-1.86-5.93-3.18-8-.39C305.61,410.07,307.68,413.18,309.8,414.88Zm-1.26-4.21c-.84-5.72,5.25-2.63,6.77-1.2a30,30,0,0,1,2.72,3,83.64,83.64,0,0,1,4.73,6.92c-2.05-.94-4.16-1.7-6.24-2.56C314,415.82,309,413.83,308.54,410.67Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "315.116px 413.285px",
        }}
        id="eltudb3fjh8kq"
        className="animable"
      />
      <path
        d="M340.91,412.18c-1.21-3.26-5.22-3.07-7.8-1.89a27.69,27.69,0,0,0-10.92,9.32.14.14,0,0,0,.07.21.65.65,0,0,0,.61.85c4.56-.38,9.49-.22,13.8-2C339.19,417.65,342.06,415.26,340.91,412.18Zm-11.24,6.5c-2.24.24-4.48.37-6.71.69a82.54,82.54,0,0,1,6.5-5.3,27.22,27.22,0,0,1,3.46-2.13c1.86-.94,8.57-2.18,6.15,3.07C337.74,417.9,332.34,418.39,329.67,418.68Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "331.669px 415.088px",
        }}
        id="eljgslsthzxsa"
        className="animable"
      />
      <path
        d="M315.92,412.92l52.73-14.12s-46.34-96.09-42.6-101.38c1.87-2.64,51.29-4.87,66.62-18.68,19.91-17.93,5.42-44,5.42-44H342.83s1,11.53-1.1,13.33c-1.38,1.2-67,8.69-73.57,31.11C260.11,306.67,315.92,412.92,315.92,412.92Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "335.274px 323.83px",
        }}
        id="elqi4e1ks3efe"
        className="animable"
      />
      <path
        d="M315,405.19a32.22,32.22,0,0,1,4.32-1.64c1.53-.53,3.07-1.06,4.6-1.57,3-1,6.08-1.94,9.14-2.82,6.17-1.78,12.34-3.52,18.56-5.14,3.24-.84,10.17-2.67,10.61-2.61.2,0,.25.38.07.45-6,2.18-12.1,4.05-18.19,5.95s-12.18,3.75-18.35,5.34c-1.71.44-3.43.84-5.14,1.26a39.41,39.41,0,0,1-5.46,1.2C315,405.62,314.8,405.28,315,405.19Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "338.664px 398.509px",
        }}
        id="elscncwspa2gs"
        className="animable"
      />
      <path
        d="M316.47,293.12c1.09-1,2.75-1.14,4.13-1.42L344.39,287c7.42-1.47,14.89-2.81,22.27-4.47,6.11-1.37,12.31-3.15,17.53-6.65a25.67,25.67,0,0,0,10.43-14.54c1.69-5.91,1.63-12.21.87-18.26-.19-1.48-.51-2.95-.83-4.41,0-.11.16-.15.18,0,2.36,11.26,2.93,24.4-5.28,33.67-4,4.52-9.61,7.28-15.35,9.12-6.49,2.09-13.29,3.19-20,4.51l-23.79,4.7c-3.82.75-7.7,1.37-11.48,2.28a3.9,3.9,0,0,0-2.05,1,3,3,0,0,0-.25,2.78c.44,1.86,1,3.71,1.59,5.53,4.8,14.74,38.42,84.53,39.33,86.14.1.17-.17.31-.27.14-7.7-12.82-32.76-66.7-38-80.62A88.81,88.81,0,0,1,315.86,297C315.54,295.66,315.34,294.16,316.47,293.12Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "355.991px 313.595px",
        }}
        id="el6eo78z486ry"
        className="animable"
      />
      <path
        d="M157.79,453.8c2.62,1.05,81.27,5.07,84.29,3.36.36-.18.87-7.29,1.41-17l.4-9.18c.14-3.1,40.63-48.23,40.63-48.23l-56.45-3.41-34.52,50.49s-10.87,3.85-20.36,7.75c0,0,0,0,0,0C167.31,440,162.23,442.15,161,443,158,445.21,155.16,452.78,157.79,453.8Z"
        style={{
          fill: "rgb(235, 148, 129)",
          transformOrigin: "220.635px 418.459px",
        }}
        id="eld6l79jpb5j"
        className="animable"
      />
      <path
        d="M254.21,417.41c-5.88,7-10.27,12.53-10.31,13.55l-.41,9.18c-.54,9.73-1.05,16.84-1.41,17-3,1.71-81.67-2.31-84.3-3.35s.16-8.6,3.26-10.81c1.19-.84,6.28-3,12.13-5.43l0,0c9.48-3.89,20.35-7.74,20.35-7.74l10-14.58Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "205.472px 436.394px",
        }}
        id="eln2yn1xwbvs"
        className="animable"
      />
      <path
        d="M157.44,454.08c2.71,1.06,81.47,4.88,84.57,3.13,1.18-.66,3-12.74,2.52-26.22l-50.78-2.41s-29.83,12.08-33,14.36S154.74,453,157.44,454.08Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "200.485px 443.121px",
        }}
        id="elqu0veg0vyin"
        className="animable"
      />
      <path
        d="M158.73,451c13.22.31,66.5,2.84,79.54,4.16.11,0,.11.08,0,.08-13.11,0-66.37-3-79.55-4C158.44,451.18,158.45,451,158.73,451Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "198.434px 453.12px",
        }}
        id="eldp8co1fnq8u"
        className="animable"
      />
      <path
        d="M165.93,440.36a10.61,10.61,0,0,1,7.75,3.61,10.26,10.26,0,0,1,2.6,7.19c0,.08-.15.07-.16,0a12.28,12.28,0,0,0-10.19-10.63A.08.08,0,0,1,165.93,440.36Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "171.066px 445.788px",
        }}
        id="elnad6k4jx0i"
        className="animable"
      />
      <path
        d="M189.55,429.7c5.28.18,11.32,2.35,14.39,6.83.12.17-.13.34-.28.25a73.26,73.26,0,0,0-14.24-6.43A.34.34,0,0,1,189.55,429.7Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "196.595px 433.252px",
        }}
        id="elcdu7tgygr3f"
        className="animable"
      />
      <path
        d="M185.39,431.09c5.29.19,11.32,2.35,14.4,6.83.11.17-.13.34-.29.25a73.17,73.17,0,0,0-14.23-6.43A.33.33,0,0,1,185.39,431.09Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "192.409px 434.64px",
        }}
        id="elur5c6i897rq"
        className="animable"
      />
      <path
        d="M181.23,432.49c5.29.18,11.33,2.34,14.4,6.82.12.18-.13.34-.28.26a72.71,72.71,0,0,0-14.24-6.44A.33.33,0,0,1,181.23,432.49Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "188.277px 436.041px",
        }}
        id="el54qck3hns4r"
        className="animable"
      />
      <path
        d="M177.07,433.88c5.29.18,11.33,2.34,14.4,6.83.12.17-.13.34-.28.25A73.3,73.3,0,0,0,177,434.52.33.33,0,0,1,177.07,433.88Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "184.139px 437.432px",
        }}
        id="elhmtmn3nhdt"
        className="animable"
      />
      <path
        d="M235.52,442.45c5.93.3,5.46,9.52-.48,9.21S229.58,442.14,235.52,442.45Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "235.278px 447.055px",
        }}
        id="elnomowhrcyqo"
        className="animable"
      />
      <path
        d="M184.56,423.09c2.68,3.82,6.91,6.34,10.55,9.13a.65.65,0,0,0,1-.38.14.14,0,0,0,.17-.14A27.74,27.74,0,0,0,192.1,418c-1.53-2.38-4.8-4.72-7.58-2.63S183,420.86,184.56,423.09Zm0-4.39c.81-5.72,5.78-1.06,6.84.75a29.17,29.17,0,0,1,1.76,3.65,83.46,83.46,0,0,1,2.6,8c-1.7-1.48-3.52-2.8-5.27-4.21C188.37,425.18,184.09,421.85,184.53,418.7Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "189.579px 423.447px",
        }}
        id="elc06s8q4uy9k"
        className="animable"
      />
      <path
        d="M215.18,429.24c-.25-3.47-4.15-4.41-7-4a27.8,27.8,0,0,0-13.1,5.88.14.14,0,0,0,0,.22.64.64,0,0,0,.35,1c4.49.93,9.17,2.47,13.8,2C212,434,215.41,432.52,215.18,429.24Zm-12.61,3.08c-2.22-.4-4.41-.91-6.64-1.22a83.23,83.23,0,0,1,7.73-3.27,29.36,29.36,0,0,1,3.91-1.07c2.06-.38,8.85.32,5.05,4.68C210.53,433.84,205.21,432.8,202.57,432.32Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "205.072px 429.789px",
        }}
        id="elkibuis3thlk"
        className="animable"
      />
      <path
        d="M191.14,419.25,254.21,427s62.37-74.62,67.41-79.59c15.62-15.4,71.62-42,88.33-66.7,15-22.21-.19-45.94-.19-45.94H348.18a126.71,126.71,0,0,0,6.28,19.21s-51.15,38.51-78.41,66C258.48,337.71,191.14,419.25,191.14,419.25Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "303.854px 330.885px",
        }}
        id="elv8snytufgng"
        className="animable"
      />
      <path
        d="M343.51,250a30.23,30.23,0,0,1,5.27,1.52c1.78.62,3.54,1.34,5.27,2.11a102.49,102.49,0,0,1,9.89,5.22c.1.06,0,.2-.09.15-3.38-1.63-6.86-3.08-10.35-4.47l-5-2a52.62,52.62,0,0,1-5.09-2.07C343.16,250.4,343.27,250,343.51,250Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "353.625px 254.505px",
        }}
        id="eltte1xdgz998"
        className="animable"
      />
      <path
        d="M346.75,234.75c.79,2.79,1.49,5.6,2.37,8.36.41,1.26.81,2.55,1.33,3.78.44,1,1.09,2.2,2.31,2.4,1.4.23,1.77-1,1.62-2.12a38.21,38.21,0,0,0-.83-3.82c-.33-1.45-2-8.49-2-8.53s0-.13.07-.21a.09.09,0,0,1,.12,0c.27.14,2,5.55,2.36,6.89s.75,2.69,1.12,4a6.36,6.36,0,0,1,.37,3.11,2.4,2.4,0,0,1-2.16,1.86c.05.09.11.17.16.27.13.27.23.56.34.84s.22.58.32.87a3.29,3.29,0,0,1,.09.43c0,.17,0,.33,0,.49a.23.23,0,0,1-.43.17c-.08-.14-.19-.25-.28-.39a2.4,2.4,0,0,1-.21-.46q-.15-.42-.27-.84c-.08-.28-.18-.57-.24-.85a4.63,4.63,0,0,1-.07-.52,3.21,3.21,0,0,1-1.64-.57,5.49,5.49,0,0,1-1.75-2.51c-.46-1.16-.82-2.36-1.19-3.55a52.37,52.37,0,0,1-2.05-9A.26.26,0,0,1,346.75,234.75Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "350.925px 244.105px",
        }}
        id="el5nve15utgjc"
        className="animable"
      />
      <path
        d="M383.54,240.54c.3,1.38.5,2.78.86,4.16s.76,2.68,1.25,4a14,14,0,0,0,1.82,3.57,13.33,13.33,0,0,0,2.81,2.7,23.21,23.21,0,0,0,6.7,3.47,29.6,29.6,0,0,0,3.7,1c.65.12,1.3.22,2,.28a4.24,4.24,0,0,1,1.58.32.32.32,0,0,1,0,.55,5.73,5.73,0,0,1-2.91.18,21.38,21.38,0,0,1-9.1-2.77,18.36,18.36,0,0,1-5-3.88,14,14,0,0,1-2.81-5.69,28.71,28.71,0,0,1-1.09-7.81A.14.14,0,0,1,383.54,240.54Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "393.883px 250.703px",
        }}
        id="eles6umu58qsh"
        className="animable"
      />
      <path
        d="M205,410a39.4,39.4,0,0,1,5.1.2c1.79.12,3.57.23,5.35.37,3.51.29,7,.61,10.5,1,7,.8,14.08,1.63,21.09,2.6,3.66.51,11.51,1.56,11.93,1.79a.24.24,0,0,1-.12.44c-7-.37-14-1.05-21-1.71s-14-1.35-21-2.32c-1.92-.27-3.84-.59-5.77-.88a46.56,46.56,0,0,1-6.08-1.05C204.81,410.36,204.77,410,205,410Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "231.96px 413.194px",
        }}
        id="elhswwhpkcy6f"
        className="animable"
      />
      <path
        d="M340.77,333c-3.27,2.1-6.43,4.36-9.6,6.6a90.09,90.09,0,0,0-8.7,6.44,46.45,46.45,0,0,0-3.85,4.09l-3.76,4.35c-2.51,2.89-5,5.81-7.47,8.74q-2.1,2.47-4.17,5c-1.44,1.73-2.78,3.55-4.23,5.28a.11.11,0,0,0,.16.15c2.56-2.89,5.28-5.66,7.84-8.56s5.12-5.8,7.65-8.74,4.79-5.77,7.39-8.46a59.43,59.43,0,0,1,8.62-6.76c3.5-2.5,7-5,10.43-7.61C341.37,333.3,341.1,332.82,340.77,333Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "320.085px 353.32px",
        }}
        id="elaqpej3frz59"
        className="animable"
      />
      <path
        d="M353.47,254.41c-10.52,7.38-21.4,15.63-31.29,23.82s-19.68,16.55-29.24,25.15q-4,3.6-8,7.3c-2.66,2.48-5.26,5.59-7.92,8.07-.14.13.08.34.22.21,4.81-4.31,9.78-9,14.65-13.24s9.7-8.4,14.57-12.57q14.68-12.57,29.73-24.71c5.56-4.51,12-9.07,17.5-13.72C353.9,254.57,353.65,254.27,353.47,254.41Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "315.373px 286.687px",
        }}
        id="elq2gg2t8upwj"
        className="animable"
      />
      <path
        d="M407.89,242c-.6-1.77-1.24-3.53-1.9-5.29,0-.1-.2,0-.16.05,2.48,7.32,5,14.82,5.28,22.62a29.29,29.29,0,0,1-1.6,11,28.91,28.91,0,0,1-5.62,9.15c-4.9,5.53-11.13,9.89-17,14.3q-9.6,7.19-19.51,14c-6.58,4.52-13.33,8.83-20.15,13s-13.72,7.93-20.5,12a55.08,55.08,0,0,0-9.18,6.8c-2.84,2.66-5.38,5.61-7.94,8.54q-8.22,9.39-16.28,18.92-16.19,19-31.73,38.57-1.9,2.38-3.8,4.79c-.06.08,0,.19.11.11q16-19.59,32.35-38.9,8.12-9.6,16.33-19.1c5.25-6.06,10.3-12.49,17-17,3.23-2.2,6.68-4.07,10.07-6q5.33-3,10.56-6.18,10.43-6.27,20.52-13c6.69-4.48,13.29-9.07,19.77-13.86q4.84-3.58,9.62-7.3a95.11,95.11,0,0,0,7.91-6.69,35.71,35.71,0,0,0,6.34-8,26.22,26.22,0,0,0,3.15-10.13C412.29,256.77,410.3,249.14,407.89,242Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "334.735px 323.628px",
        }}
        id="elilbb1kmtifl"
        className="animable"
      />
      <path
        d="M338.22,245.59c1.1,2.1,78,19.88,78.54,19.53,1.34-1,5.75-71.42-14.54-102.92-3.57-5.55-12.25-6.58-16.72-5.42-2.23.58-11.4,8.39-15.6,16.2S337.54,244.25,338.22,245.59Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "378.157px 210.74px",
        }}
        id="elwrx8ajs3opa"
        className="animable"
      />
      <g id="eltqa2088l58k">
        <g
          style={{
            opacity: 0.1,
            transformOrigin: "378.17px 211.315px",
          }}
          className="animable"
        >
          <path
            d="M399.37,159.29H381.66c.7-.58,1.35-1.07,1.92-1.46h13.15A12.79,12.79,0,0,1,399.37,159.29Z"
            id="el4mtk2fuasao"
            className="animable"
            style={{
              transformOrigin: "390.515px 158.56px",
            }}
          />
          <path
            d="M403.37,164.08H376.56c.47-.51.95-1,1.42-1.46h24.5C402.79,163.1,403.08,163.58,403.37,164.08Z"
            id="el8g58qnmz4vb"
            className="animable"
            style={{
              transformOrigin: "389.965px 163.35px",
            }}
          />
          <path
            d="M405.91,168.88H372.53c.36-.5.74-1,1.12-1.46h31.54C405.44,167.9,405.67,168.39,405.91,168.88Z"
            id="elb3hi7d2l4z"
            className="animable"
            style={{
              transformOrigin: "389.22px 168.15px",
            }}
          />
          <path
            d="M408,173.67H369.55l.35-.69c.14-.25.28-.51.43-.76h37.06C407.59,172.7,407.79,173.18,408,173.67Z"
            id="el9pxh59dmddv"
            className="animable"
            style={{
              transformOrigin: "388.775px 172.945px",
            }}
          />
          <path
            d="M409.72,178.47h-42.5c.25-.52.48-1,.69-1.46h41.31C409.39,177.49,409.56,178,409.72,178.47Z"
            id="eljib93a8xj4"
            className="animable"
            style={{
              transformOrigin: "388.47px 177.74px",
            }}
          />
          <path
            d="M411.2,183.27H365l.67-1.46h45.1C410.92,182.29,411.06,182.78,411.2,183.27Z"
            id="elot3ukv6aaul"
            className="animable"
            style={{
              transformOrigin: "388.1px 182.54px",
            }}
          />
          <path
            d="M412.46,188.06H362.81c.22-.5.44-1,.66-1.46H412.1Z"
            id="elrl3i1cspiq9"
            className="animable"
            style={{
              transformOrigin: "387.635px 187.33px",
            }}
          />
          <path
            d="M413.55,192.86H360.63c.22-.49.45-1,.66-1.46h51.94C413.34,191.88,413.45,192.37,413.55,192.86Z"
            id="elhc1tejlwgk8"
            className="animable"
            style={{
              transformOrigin: "387.09px 192.13px",
            }}
          />
          <path
            d="M414.47,197.66h-56c.22-.49.43-1,.65-1.46h55.08C414.3,196.68,414.39,197.17,414.47,197.66Z"
            id="el8j5ioe7wff"
            className="animable"
            style={{
              transformOrigin: "386.47px 196.93px",
            }}
          />
          <path
            d="M415.28,202.45H356.34c.22-.49.43-1,.65-1.46H415Z"
            id="elvdop7owe7pb"
            className="animable"
            style={{
              transformOrigin: "385.81px 201.72px",
            }}
          />
          <path
            d="M416,207.25H354.21c.21-.48.43-1,.64-1.46h60.91C415.83,206.28,415.89,206.76,416,207.25Z"
            id="eld1j02kqb2t5"
            className="animable"
            style={{
              transformOrigin: "385.105px 206.52px",
            }}
          />
          <path
            d="M416.53,212H352.1c.21-.49.42-1,.64-1.46h63.62C416.42,211.07,416.47,211.55,416.53,212Z"
            id="elug2tfu43fs"
            className="animable"
            style={{
              transformOrigin: "384.315px 211.27px",
            }}
          />
          <path
            d="M417,216.84H350c.2-.48.42-1,.63-1.46h66.23C416.91,215.87,417,216.35,417,216.84Z"
            id="eloj6blgarl9"
            className="animable"
            style={{
              transformOrigin: "383.5px 216.11px",
            }}
          />
          <path
            d="M417.38,221.64H347.91c.2-.48.42-1,.63-1.46h68.73C417.31,220.67,417.35,221.15,417.38,221.64Z"
            id="elrw1rdup0b98"
            className="animable"
            style={{
              transformOrigin: "382.645px 220.91px",
            }}
          />
          <path
            d="M417.68,226.43H345.84l.62-1.46H417.6C417.63,225.46,417.65,226,417.68,226.43Z"
            id="elihuc2chyg9s"
            className="animable"
            style={{
              transformOrigin: "381.76px 225.7px",
            }}
          />
          <path
            d="M417.89,231.23h-74.1l.62-1.46h73.43C417.86,230.26,417.88,230.75,417.89,231.23Z"
            id="elqqb5tz3fyte"
            className="animable"
            style={{
              transformOrigin: "380.84px 230.5px",
            }}
          />
          <path
            d="M418,236H341.78l.61-1.46H418Z"
            id="elu8aad1bzx8"
            className="animable"
            style={{
              transformOrigin: "379.89px 235.27px",
            }}
          />
          <path
            d="M418.1,240.82H339.84c.17-.46.37-.95.57-1.46h77.68C418.1,239.85,418.1,240.34,418.1,240.82Z"
            id="elffn073u46ps"
            className="animable"
            style={{
              transformOrigin: "378.97px 240.09px",
            }}
          />
          <path
            d="M418.1,244.16c0,.49,0,1,0,1.46H338.24l0,0a4,4,0,0,1,.36-1.43Z"
            id="elau0dhc6oa4n"
            className="animable"
            style={{
              transformOrigin: "378.17px 244.89px",
            }}
          />
          <path
            d="M418,249c0,.5,0,1,0,1.46H354c-2-.51-3.81-1-5.5-1.46Z"
            id="el263st10o1sij"
            className="animable"
            style={{
              transformOrigin: "383.25px 249.73px",
            }}
          />
          <path
            d="M417.88,253.75c0,.5,0,1-.06,1.46H373.3c-2-.49-4-1-6-1.46Z"
            id="elhi0mrgxk0fg"
            className="animable"
            style={{
              transformOrigin: "392.59px 254.48px",
            }}
          />
          <path
            d="M417.62,258.54c0,.51-.07,1-.11,1.46h-24l-6.25-1.46Z"
            id="elwrz94nvyjz"
            className="animable"
            style={{
              transformOrigin: "402.44px 259.27px",
            }}
          />
          <path
            d="M417.16,263.34a9.5,9.5,0,0,1-.26,1.46h-2c-1.5-.3-3.83-.81-6.76-1.46Z"
            id="elaikgxt87187"
            className="animable"
            style={{
              transformOrigin: "412.65px 264.07px",
            }}
          />
        </g>
      </g>
      <path
        d="M398.75,178.4c13.83-1.12,36.75-6.08,49.22-13,7.48-4.18,8.61-9.79,9-13.71,1-10.14-15.9-26.6-24.78-32.74-1.17-.81-2.87,5.15-8.25,11.84-4.55,5.67-13.13,12.07-11.84,13.16,1.9,1.6,10.71,7.67,10.29,8.4-.72,1.25-8.89,4.72-21.13,10.65C384.84,170.92,389,179.2,398.75,178.4Z"
        style={{
          fill: "rgb(235, 148, 129)",
          transformOrigin: "423.597px 148.665px",
        }}
        id="elre1k045imcg"
        className="animable"
      />
      <path
        d="M435.13,174.49l-23.72-18.24s-16.05,7.76-20.28,12.5-1.82,10.55,9.94,11.7C414.55,181.76,435.13,174.49,435.13,174.49Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "412.159px 168.429px",
        }}
        id="elb57im3pjv3m"
        className="animable"
      />
      <path
        d="M429.16,175.06c-9-7.93-18.65-15.53-20.46-16.75-.08-.05-.16.07-.1.13a219.39,219.39,0,0,0,20.47,16.75C429.14,175.24,429.23,175.12,429.16,175.06Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "418.882px 166.75px",
        }}
        id="elxihwul9s3cp"
        className="animable"
      />
      <path
        d="M414.79,178.6a12.83,12.83,0,0,0-5,2,10.19,10.19,0,0,0-1.91,1.86,7.26,7.26,0,0,0-.76,1.08,7.14,7.14,0,0,0-.57,1.14c0,.09.1.14.15.08.29-.29.53-.63.83-.92s.57-.6.88-.88a19.55,19.55,0,0,1,2-1.65,14.34,14.34,0,0,1,4.45-2.48A.12.12,0,0,0,414.79,178.6Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "410.748px 181.69px",
        }}
        id="el5xf0o02ph6m"
        className="animable"
      />
      <g id="eltj2a4k8oywp">
        <g
          style={{
            opacity: 0.1,
            transformOrigin: "411.625px 168.145px",
          }}
          className="animable"
        >
          <path
            d="M415.36,159.29h-9.88c1-.54,2-1,2.78-1.46h5.2Z"
            id="elbfu3bpt7cdi"
            className="animable"
            style={{
              transformOrigin: "410.42px 158.56px",
            }}
          />
          <path
            d="M421.59,164.08H397.2c.76-.49,1.56-1,2.36-1.46h20.13Z"
            id="elxb1oyrr94i"
            className="animable"
            style={{
              transformOrigin: "409.395px 163.35px",
            }}
          />
          <path
            d="M427.83,168.88H391l.11-.13a14.08,14.08,0,0,1,1.4-1.33h33.41Z"
            id="elbgje05ug6mc"
            className="animable"
            style={{
              transformOrigin: "409.415px 168.15px",
            }}
          />
          <path
            d="M434.06,173.67H389.22a4.72,4.72,0,0,1,.06-1.45h42.9Z"
            id="elaxjmfyxc65"
            className="animable"
            style={{
              transformOrigin: "411.625px 172.945px",
            }}
          />
          <path
            d="M426.86,177c-1.78.48-3.77,1-5.88,1.46H393.24a10,10,0,0,1-2.19-1.46Z"
            id="elt4bv4zmojlm"
            className="animable"
            style={{
              transformOrigin: "408.955px 177.73px",
            }}
          />
        </g>
      </g>
      <path
        d="M383.44,169.79c4,1.66,12.09-3.46,15.74-8.1.24-.32,1.13-14.56,1.6-17.37.09-.57-18.31,1.34-18.31,1.34a45.14,45.14,0,0,1-.1,11.05C382.3,157.12,379.61,168.18,383.44,169.79Z"
        style={{
          fill: "rgb(235, 148, 129)",
          transformOrigin: "391.084px 157.162px",
        }}
        id="elpe8k7jtdya"
        className="animable"
      />
      <path
        d="M382.06,157.84c0,.06,0,.12-.06.19l.09,0a10,10,0,0,0,1.71.38c9.6,1.38,14.78-9.84,16.43-14.21-3-.09-17.76,1.44-17.76,1.44a45.14,45.14,0,0,1-.1,11.05c0,.14-.05.27-.07.39A4.27,4.27,0,0,1,382.06,157.84Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "391.115px 151.361px",
        }}
        id="elt72rj28lsj"
        className="animable"
      />
      <path
        d="M380.06,112.87s-6,2.42-7.16,8.48.16,12.44.68,12.76S380.06,112.87,380.06,112.87Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "376.248px 123.492px",
        }}
        id="elpyimd2p77uf"
        className="animable"
      />
      <path
        d="M403.19,121.93c1.91,6.33-1.46,24.59-6,28.66-6.53,5.89-17.2,6.43-22.2-1.42-4.85-7.6,0-31.32,5-35C387.43,108.72,400.35,112.59,403.19,121.93Z"
        style={{
          fill: "rgb(235, 148, 129)",
          transformOrigin: "388.36px 133.356px",
        }}
        id="ell0j80v8s8s"
        className="animable"
      />
      <path
        d="M373.72,128.89a29.24,29.24,0,0,0-.7,5.1c-.12,1.77-.14,3.54-.13,5.31,0,.1.16.09.16,0,0-1.74.12-3.48.27-5.21s.43-3.45.52-5.18C373.85,128.83,373.74,128.83,373.72,128.89Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "373.364px 134.109px",
        }}
        id="elqtzkbrokotb"
        className="animable"
      />
      <path
        d="M397.68,150.41h0"
        style={{
          fill: "none",
          stroke: "rgb(245, 167, 158)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 0,
          transformOrigin: "397.68px 150.41px",
        }}
        id="elt9h7amfw66r"
        className="animable"
      />
      <path
        d="M385,133c0-.05.09.07.08.12-.26,1.23-.35,2.67.69,3.28,0,0,0,.08,0,.07C384.44,136,384.58,134.08,385,133Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "385.238px 134.729px",
        }}
        id="el94uxqlw7z6o"
        className="animable"
      />
      <path
        d="M386.36,131.87c2,.21,1.44,4.2-.42,4S384.67,131.7,386.36,131.87Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "386.206px 133.871px",
        }}
        id="ely7fni1k1pqi"
        className="animable"
      />
      <path
        d="M378.16,132.19c0-.05-.11,0-.11.09-.14,1.25-.5,2.65-1.68,2.91,0,0,0,.07,0,.07C377.77,135.25,378.23,133.39,378.16,132.19Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "377.268px 133.715px",
        }}
        id="el3ejyvjclqdj"
        className="animable"
      />
      <path
        d="M377.23,130.74c-2-.43-2.68,3.54-.86,3.94S378.88,131.1,377.23,130.74Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "376.738px 132.708px",
        }}
        id="elbiomauctz7f"
        className="animable"
      />
      <path
        d="M388.19,130.26a14.55,14.55,0,0,1-1.47-.76,2.72,2.72,0,0,1-1.36-1.06.86.86,0,0,1,.28-1,2.13,2.13,0,0,1,2.15.08,3.08,3.08,0,0,1,1.58,1.58A.91.91,0,0,1,388.19,130.26Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "387.362px 128.753px",
        }}
        id="elkcmkf10cq8"
        className="animable"
      />
      <path
        d="M376,128.51a15.26,15.26,0,0,0,1.64-.19,2.72,2.72,0,0,0,1.65-.51.87.87,0,0,0,.09-1,2.12,2.12,0,0,0-2-.69,3.11,3.11,0,0,0-2.05.92A.91.91,0,0,0,376,128.51Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "377.317px 127.296px",
        }}
        id="el67tq02mpilp"
        className="animable"
      />
      <path
        d="M384.9,144.64c-.34.25-.69.61-1.15.59a3.47,3.47,0,0,1-1.29-.5s-.08,0-.06.05a1.68,1.68,0,0,0,1.48.9,1.35,1.35,0,0,0,1.13-1C385,144.65,384.94,144.61,384.9,144.64Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "383.703px 145.155px",
        }}
        id="elxk1jp0kg64"
        className="animable"
      />
      <path
        d="M384.11,140.75a4.25,4.25,0,0,1-3.08,1.49,5.6,5.6,0,0,1-1.52-.14l-.29-.08a1.29,1.29,0,0,1-.26-.08.29.29,0,0,1-.21-.31h0a.53.53,0,0,1,0-.12h0l0-.11c.1-1,.4-2.4.4-2.4-.37.13-2.26.68-2.16.19a64,64,0,0,1,3.41-12.54c.05-.13.26-.07.23.06-.64,4-2,7.79-2.67,11.76a7.35,7.35,0,0,1,2.08-.33c.13.06-.62,2.77-.64,3.21v0a5.88,5.88,0,0,0,4.55-.74C384.11,140.57,384.2,140.67,384.11,140.75Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "380.566px 134.412px",
        }}
        id="elz71miq9r4s"
        className="animable"
      />
      <path
        d="M380.14,141.69a5,5,0,0,0,1.78,1.83,2.25,2.25,0,0,0,1.36.28c1-.12,1.18-1,1.1-1.83a5.16,5.16,0,0,0-.27-1.25A6.57,6.57,0,0,1,380.14,141.69Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "382.268px 142.267px",
        }}
        id="elhqdaem1774q"
        className="animable"
      />
      <path
        d="M381.92,143.52a2.25,2.25,0,0,0,1.36.28c1-.12,1.18-1,1.1-1.83A2.35,2.35,0,0,0,381.92,143.52Z"
        style={{
          fill: "rgb(255, 152, 185)",
          transformOrigin: "383.158px 142.885px",
        }}
        id="eld14hpafqnlf"
        className="animable"
      />
      <path
        d="M401.71,136.74c-2.09.3-3.45-6.06-3.74-8.48-.26-2.12.09-8.68.06-9.14s-9.6,4.36-14.41,2.1-7.46-7.31-7.2-9.43,7.35-6.49,12.8-5.92,11.7,8.59,11.7,8.59a25.76,25.76,0,0,1-1.9-3.59c.13-.23,3.4,1.71,3.66,4.3,0,0-.21-3.23.07-3.25s2.57,3.09,1.64,5c0,0,2.71,3.21,2.54,6.24S404.17,136.39,401.71,136.74Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "391.67px 121.285px",
        }}
        id="elqdui4zh0mij"
        className="animable"
      />
      <path
        d="M380.21,119.61a17.16,17.16,0,0,0,4.49,2.44,11.41,11.41,0,0,0,5.57,0,17.47,17.47,0,0,0,5.2-2.07c1.42-.84,2.73-1.92,4.22-2.63.37-.18.69.36.43.64a18.27,18.27,0,0,1-10.19,5.3,10.49,10.49,0,0,1-9.74-3.63S380.19,119.59,380.21,119.61Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "390.202px 120.37px",
        }}
        id="el1f9wxozqt7o"
        className="animable"
      />
      <path
        d="M385.78,138.9a5.37,5.37,0,0,1-3.78-6.56h0a5.35,5.35,0,1,1,5.1,6.74A5,5,0,0,1,385.78,138.9Zm-3.09-6.38a4.64,4.64,0,1,0,5.69-3.27,5.14,5.14,0,0,0-1.15-.16A4.65,4.65,0,0,0,382.69,132.52Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "387.168px 133.73px",
        }}
        id="elrm52nmcg3b"
        className="animable"
      />
      <path
        d="M374.58,137.4c-2.18-.29-3.64-2.9-3.26-5.83a6.31,6.31,0,0,1,1.61-3.56,3.33,3.33,0,0,1,5.66.74,6.37,6.37,0,0,1,.63,3.86c-.36,2.77-2.24,4.84-4.29,4.81Zm-1.13-8.9a5.66,5.66,0,0,0-1.42,3.17c-.33,2.53.85,4.78,2.64,5s3.51-1.64,3.85-4.18a5.72,5.72,0,0,0-.56-3.42,2.85,2.85,0,0,0-2.09-1.6l-.27,0A2.94,2.94,0,0,0,373.45,128.5Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "375.276px 132.086px",
        }}
        id="els8t5wletnp"
        className="animable"
      />
      <path
        d="M382.1,133l.53-.49c-.72-.78-2.46-1.93-3.94-.71l.45.55C380.47,131.23,382,132.91,382.1,133Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "380.66px 132.133px",
        }}
        id="eli1ifiq19z4k"
        className="animable"
      />
      <path
        d="M405.57,137.44l.68-.24c-.12-.32-1.16-3.08-2.71-3.55s-11.08-.78-11.49-.8l0,.72c2.76.1,10.19.43,11.3.77S405.3,136.68,405.57,137.44Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "399.15px 135.145px",
        }}
        id="elqb8ilcctn5p"
        className="animable"
      />
      <path
        d="M399.25,136.81s4.26-5.81,6.91-4.28-.65,10-3.82,11.13a3.2,3.2,0,0,1-4.25-1.82Z"
        style={{
          fill: "rgb(235, 148, 129)",
          transformOrigin: "402.609px 138.095px",
        }}
        id="el90e0v5zehz"
        className="animable"
      />
      <path
        d="M405.16,134.94a0,0,0,0,1,0,.08c-2.22.85-3.43,2.87-4.29,5a1.76,1.76,0,0,1,2.65-.42c.06,0,0,.13-.06.11a2,2,0,0,0-2.18.64,10.66,10.66,0,0,0-1.08,1.73c-.1.2-.46.08-.38-.14l0,0C399.86,139,402.13,135.16,405.16,134.94Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "402.484px 138.561px",
        }}
        id="eloiz2c21hs4l"
        className="animable"
      />
      <path
        d="M406.63,132.6a2.26,2.26,0,0,0-2.12-.3c-.1,0-.11.09,0,.06a2.27,2.27,0,0,1,1.29,0c.35.11,1.34.52,1.33,2.59a13.42,13.42,0,0,1-1.33,4.7c0,.05.06.1.09.05a15.31,15.31,0,0,0,1.16-2.89C407.33,135.69,407.7,133.51,406.63,132.6Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "405.889px 135.941px",
        }}
        id="elpepyjm0um3f"
        className="animable"
      />
      <path
        d="M400.67,163.07c-.71.79-1.34,1.62-2.09,2.37a30.49,30.49,0,0,1-2.38,2.16A29.11,29.11,0,0,1,391,171a10.44,10.44,0,0,1-6.35,1.42,5.6,5.6,0,0,1-4.38-3.77c-.93-2.5-.3-5.16.31-7.78,0-.09-.12-.13-.15,0-.69,2.69-1.21,4.9-.74,7.43a6,6,0,0,0,3.47,4.55c4.43,1.84,9.15-1.35,12.43-4.08a31.55,31.55,0,0,0,2.73-2.57,22.52,22.52,0,0,0,2.5-3C400.85,163.08,400.73,163,400.67,163.07Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "390.167px 167.091px",
        }}
        id="el50f5rrvwnld"
        className="animable"
      />
    </g>
    <g
      id="freepik--Table--inject-19"
      className="animable"
      style={{
        transformOrigin: "258px 356.025px",
      }}
    >
      <rect
        x={78.73}
        y={260.2}
        width={358.54}
        height={12.75}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "258px 266.575px",
        }}
        id="el6m0vvcx3az7"
        className="animable"
      />
      <rect
        x={78.73}
        y={267.97}
        width={358.54}
        height={4.98}
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "258px 270.46px",
        }}
        id="el0yl4d4fd1trh"
        className="animable"
      />
      <polygon
        points="91.59 451.85 86.44 451.85 101.44 272.95 114.59 272.95 91.59 451.85"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "100.515px 362.4px",
        }}
        id="elbzctf84lucb"
        className="animable"
      />
      <polygon
        points="114.59 272.95 113.05 284.92 100.44 284.92 101.44 272.95 114.59 272.95"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "107.515px 278.935px",
        }}
        id="elj5bt6k1b4e"
        className="animable"
      />
      <polygon
        points="424.41 451.85 429.56 451.85 414.56 272.95 401.41 272.95 424.41 451.85"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "415.485px 362.4px",
        }}
        id="elfqw1s21tt"
        className="animable"
      />
      <polygon
        points="401.41 272.95 402.95 284.92 415.56 284.92 414.56 272.95 401.41 272.95"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "408.485px 278.935px",
        }}
        id="elbux1ktewcj"
        className="animable"
      />
    </g>
    <g
      id="freepik--Device--inject-19"
      className="animable"
      style={{
        transformOrigin: "284.44px 218.495px",
      }}
    >
      <polygon
        points="300.33 260.19 355.72 260.19 355.72 255.88 300.33 254.09 300.33 260.19"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "328.025px 257.14px",
        }}
        id="eltw6ra48apgr"
        className="animable"
      />
      <path
        d="M256.79,260.11h36.55c3.12,0-1.4-8.88-12.78-12.28S253.21,256.78,256.79,260.11Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "275.29px 253.677px",
        }}
        id="elis8tovdj2b9"
        className="animable"
      />
      <path
        d="M271.13,260.11a26.77,26.77,0,0,0-2.38-5.36,14.4,14.4,0,0,0-1.7-2.32,19,19,0,0,0-2.15-2,7.29,7.29,0,0,1,2.69,1.46,9.31,9.31,0,0,1,2,2.37,11.16,11.16,0,0,1,1.21,2.85A8.88,8.88,0,0,1,271.13,260.11Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "268.023px 255.27px",
        }}
        id="elxlcnoys8g7"
        className="animable"
      />
      <path
        d="M266.8,251.66c-1.92,1.25-3.84,2.37-5.64,3.66a20.54,20.54,0,0,0-2.54,2.08,7.19,7.19,0,0,0-1.83,2.71,5.05,5.05,0,0,1,1.25-3.22,11.94,11.94,0,0,1,2.54-2.38,19.3,19.3,0,0,1,3-1.72A16.07,16.07,0,0,1,266.8,251.66Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "261.795px 255.885px",
        }}
        id="el5qq4mo89tjq"
        className="animable"
      />
      <polygon
        points="230.57 242.92 319.4 242.92 305.28 176.79 216.44 176.79 230.57 242.92"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "267.92px 209.855px",
        }}
        id="elnpfmrx1qjeq"
        className="animable"
      />
      <polygon
        points="227.29 242.92 316.12 242.92 301.99 176.79 213.16 176.79 227.29 242.92"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "264.64px 209.855px",
        }}
        id="ellspf8y8odh"
        className="animable"
      />
      <polygon
        points="275.2 219.21 277.28 219.21 274.12 204.38 272.04 204.38 275.2 219.21"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "274.66px 211.795px",
        }}
        id="elbl3xz6p3r64"
        className="animable"
      />
      <polygon
        points="271.09 219.21 273.17 219.21 270.01 204.38 267.92 204.38 271.09 219.21"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "270.545px 211.795px",
        }}
        id="elxj9jxabn1"
        className="animable"
      />
      <polygon
        points="266.98 219.21 269.06 219.21 265.9 204.38 263.81 204.38 266.98 219.21"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "266.435px 211.795px",
        }}
        id="el2332w743dsa"
        className="animable"
      />
      <polygon
        points="262.87 219.21 264.95 219.21 261.79 204.38 259.7 204.38 262.87 219.21"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "262.325px 211.795px",
        }}
        id="elsytskdopy2p"
        className="animable"
      />
      <polygon
        points="258.75 219.21 260.84 219.21 257.68 204.38 255.59 204.38 258.75 219.21"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "258.215px 211.795px",
        }}
        id="elb3k5yj3mhn9"
        className="animable"
      />
      <polygon
        points="254.64 219.21 256.73 219.21 253.56 204.38 251.48 204.38 254.64 219.21"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "254.105px 211.795px",
        }}
        id="elaukhgt656i5"
        className="animable"
      />
      <polygon
        points="250.53 219.21 252.62 219.21 249.45 204.38 247.37 204.38 250.53 219.21"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "249.995px 211.795px",
        }}
        id="elzw8vwsww1zr"
        className="animable"
      />
      <polygon
        points="252.41 260.2 296.1 260.2 296.1 256.8 252.41 255.39 252.41 260.2"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "274.255px 257.795px",
        }}
        id="elnvr2y1yyq6p"
        className="animable"
      />
      <polygon
        points="240.53 260.19 263.99 260.19 278.12 223.13 254.66 223.13 240.53 260.19"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "259.325px 241.66px",
        }}
        id="elkzml2p63yo"
        className="animable"
      />
      <polygon
        points="236.67 260.19 260.13 260.19 274.25 223.13 250.8 223.13 236.67 260.19"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "255.46px 241.66px",
        }}
        id="ellhs5sd0sj4i"
        className="animable"
      />
    </g>
    <g
      id="freepik--Shield--inject-19"
      className="animable"
      style={{
        transformOrigin: "195.135px 146.275px",
      }}
    >
      <path
        d="M232.4,123.66S227,167.25,218,177.06c-10.74,11.76-18.53,10-22.87,16.11a0,0,0,0,0,0,0c-4.33-6.08-12.13-4.35-22.88-16.12-9-9.81-14.38-53.4-14.38-53.4,23.58-11.44,37.26-24.27,37.26-24.27h0C195.55,99.79,209.18,112.4,232.4,123.66Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "195.135px 146.275px",
        }}
        id="elzatarzhq27"
        className="animable"
      />
      <g id="elsc972i039iq">
        <path
          d="M195.13,99.4v93.77a0,0,0,0,0,0,0c-4.33-6.08-12.13-4.35-22.88-16.12-9-9.81-14.38-53.4-14.38-53.4,23.58-11.44,37.26-24.27,37.26-24.27Z"
          style={{
            opacity: 0.2,
            transformOrigin: "176.5px 146.275px",
          }}
          className="animable"
        />
      </g>
      <g id="elfltuppco73u">
        <path
          d="M232.4,123.66S227,167.25,218,177.06c-10.74,11.76-18.53,10-22.87,16.11V99.4C195.55,99.79,209.18,112.4,232.4,123.66Z"
          style={{
            opacity: 0.1,
            transformOrigin: "213.765px 146.285px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M226.61,127.17S222,164,214.46,172.28c-9.08,10-15.67,8.49-19.34,13.62-3.66-5.13-10.25-3.66-19.33-13.62-7.56-8.29-12.15-45.11-12.15-45.11,19.92-9.67,31.48-20.5,31.48-20.5S206.7,117.5,226.61,127.17Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "195.125px 146.285px",
        }}
        id="elkevitx1x0h"
        className="animable"
      />
      <rect
        x={185.68}
        y={141.06}
        width={18.89}
        height={17.14}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "195.125px 149.63px",
        }}
        id="el0apan4w4zv1h"
        className="animable"
      />
      <path
        d="M202.68,146.34h-1.92v-7.65a5.64,5.64,0,0,0-11.27,0v7.65h-1.92v-7.65a7.56,7.56,0,0,1,15.11,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "195.125px 138.873px",
        }}
        id="el3n1gnjxriil"
        className="animable"
      />
      <path
        d="M195.93,149.84l.79,3.75h-3.36l.79-3.75a1.68,1.68,0,1,1,1.78,0Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "195.04px 150.163px",
        }}
        id="elmrl3yhopc1q"
        className="animable"
      />
    </g>
    <g
      id="freepik--Letter--inject-19"
      className="animable"
      style={{
        transformOrigin: "110.51px 213.5px",
      }}
    >
      <polygon
        points="133.43 243.89 78.73 243.89 78.73 197.63 106.07 180.26 133.43 197.63 133.43 243.89"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "106.08px 212.075px",
        }}
        id="el9rthgmlv65d"
        className="animable"
      />
      <g id="els5zjlxf2c3">
        <polygon
          points="133.43 243.89 78.73 243.89 78.73 197.63 106.07 180.26 133.43 197.63 133.43 243.89"
          style={{
            opacity: 0.2,
            transformOrigin: "106.08px 212.075px",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="130.34 236.53 81.81 236.53 81.81 174.24 117.84 174.24 130.34 186.11 130.34 236.53"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "106.075px 205.385px",
        }}
        id="elikxx0oef6ie"
        className="animable"
      />
      <polygon
        points="117.84 174.24 130.34 186.11 117.84 186.11 117.84 174.24"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "124.09px 180.175px",
        }}
        id="elh98nbs8com"
        className="animable"
      />
      <g id="elishtqy0doe">
        <rect
          x={86.55}
          y={184.69}
          width={26.98}
          height={1.6}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "100.04px 185.49px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elogm4a7vo1w">
        <rect
          x={86.55}
          y={188.73}
          width={26.98}
          height={1.6}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "100.04px 189.53px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el7o1q4q1bphc">
        <rect
          x={86.55}
          y={192.77}
          width={26.98}
          height={1.6}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "100.04px 193.57px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elos36mhr2df">
        <rect
          x={86.55}
          y={196.8}
          width={39.53}
          height={1.6}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "106.315px 197.6px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elro8do92gx5c">
        <rect
          x={86.55}
          y={200.84}
          width={39.53}
          height={1.6}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "106.315px 201.64px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eloj66xf4soe">
        <rect
          x={86.55}
          y={204.88}
          width={39.53}
          height={1.6}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "106.315px 205.68px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="ely6204jljk4s">
        <rect
          x={86.55}
          y={208.92}
          width={39.53}
          height={1.6}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "106.315px 209.72px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elonfe62gq55">
        <rect
          x={86.55}
          y={212.95}
          width={39.53}
          height={1.6}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "106.315px 213.75px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eldhx938ycw0b">
        <rect
          x={86.55}
          y={216.99}
          width={39.53}
          height={1.6}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "106.315px 217.79px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el358w47bmjuq">
        <rect
          x={86.55}
          y={221.03}
          width={39.53}
          height={1.6}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "106.315px 221.83px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elsnl1ljfrx6f">
        <rect
          x={86.55}
          y={225.07}
          width={39.53}
          height={1.6}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "106.315px 225.87px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el6676zky9mc6">
        <rect
          x={86.55}
          y={229.1}
          width={39.53}
          height={1.6}
          style={{
            fill: "rgb(219, 219, 219)",
            transformOrigin: "106.315px 229.9px",
            transform: "rotate(180deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M133.43,209.21v34.68H78.73V209.21c2.35,1.33,4.66,2.72,7,4.09s4.63,2.77,6.93,4.19q3.85,2.34,7.68,4.74l.09,0-.07.06,5.74,3.59c1.91-1.2,3.82-2.41,5.74-3.59l-.07-.06.08,0c2.56-1.59,5.11-3.17,7.68-4.74q3.45-2.11,6.93-4.18C128.77,211.93,131.08,210.54,133.43,209.21Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "106.08px 226.55px",
        }}
        id="elx6n8gfh9jvh"
        className="animable"
      />
      <path
        d="M133.43,209.21c-2.21,1.55-4.46,3-6.69,4.56s-4.49,3-6.75,4.47-4.73,3.07-7.09,4.59l-.21.15-6.3,4-.32.19-.3-.19q-3.15-2-6.29-4a1.64,1.64,0,0,1-.22-.14c-2.38-1.52-4.74-3-7.1-4.6s-4.5-3-6.74-4.46-4.48-3-6.69-4.56c2.35,1.33,4.66,2.72,7,4.09s4.63,2.77,6.93,4.19q3.85,2.34,7.68,4.74h0l5.74,3.59c1.91-1.2,3.82-2.41,5.74-3.59,0,0,0,0,0,0,2.56-1.59,5.11-3.17,7.68-4.74q3.45-2.11,6.93-4.18C128.77,211.93,131.08,210.54,133.43,209.21Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "106.08px 218.19px",
        }}
        id="el04xvz1s8d8m2"
        className="animable"
      />
      <path
        d="M100.41,222.18l-.07.06-.86.73c-1.37,1.16-2.78,2.27-4.21,3.35-1.74,1.33-3.52,2.6-5.31,3.86s-3.61,2.48-5.46,3.66-3.71,2.34-5.64,3.4c1.66-1.45,3.39-2.81,5.13-4.13s3.53-2.61,5.31-3.87,3.62-2.48,5.46-3.66c1.47-1,3-1.88,4.5-2.75l1.06-.6Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "89.635px 229.71px",
        }}
        id="elpeoop340lu"
        className="animable"
      />
      <path
        d="M133.29,237.24c-1.93-1.06-3.8-2.22-5.64-3.4s-3.65-2.42-5.46-3.66-3.57-2.53-5.32-3.86c-1.42-1.08-2.82-2.18-4.18-3.34-.3-.25-.59-.49-.87-.74l-.07-.06.08,0,1.07.61c1.53.87,3,1.8,4.49,2.75,1.85,1.18,3.66,2.41,5.46,3.66s3.57,2.54,5.31,3.87S131.63,235.79,133.29,237.24Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "122.52px 229.71px",
        }}
        id="elbvhshocxnjd"
        className="animable"
      />
      <circle
        cx={133.29}
        cy={243.76}
        r={9}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "133.29px 243.76px",
        }}
        id="elxwpnkw2vyja"
        className="animable"
      />
      <rect
        x={128.83}
        y={241.49}
        width={8.92}
        height={8.1}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "133.29px 245.54px",
        }}
        id="elz4ue4ptcqwa"
        className="animable"
      />
      <path
        d="M136.86,244H136v-3.62a2.67,2.67,0,0,0-5.33,0V244h-.9v-3.62a3.57,3.57,0,1,1,7.13,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "133.335px 240.311px",
        }}
        id="elahza5m66be"
        className="animable"
      />
      <path
        d="M133.67,245.64l.38,1.77h-1.59l.37-1.77a.81.81,0,0,1-.37-.67.8.8,0,1,1,1.21.67Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "133.26px 245.782px",
        }}
        id="el4k3xebms4o5"
        className="animable"
      />
    </g>
    <g
      id="freepik--Password--inject-19"
      className="animable"
      style={{
        transformOrigin: "122.77px 159.69px",
      }}
    >
      <rect
        x={82.34}
        y={152.73}
        width={91.82}
        height={13.92}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "128.25px 159.69px",
        }}
        id="eld7zytm3ymya"
        className="animable"
      />
      <path
        d="M174.65,167.15H81.84V152.23h92.81Zm-91.81-1h90.81V153.23H82.84Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "128.245px 159.69px",
        }}
        id="elx7hm70qjt"
        className="animable"
      />
      <circle
        cx={79.89}
        cy={159.69}
        r={9}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "79.89px 159.69px",
        }}
        id="elp2n5tigk01e"
        className="animable"
      />
      <rect
        x={75.43}
        y={157.41}
        width={8.92}
        height={8.1}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "79.89px 161.46px",
        }}
        id="eltyqmqemwsjk"
        className="animable"
      />
      <path
        d="M83.46,159.91h-.91V156.3a2.66,2.66,0,1,0-5.32,0v3.61h-.91V156.3a3.57,3.57,0,1,1,7.14,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "79.89px 156.318px",
        }}
        id="elsvmadg6p75"
        className="animable"
      />
      <path
        d="M80.27,161.56l.37,1.78H79.05l.38-1.78a.77.77,0,0,1-.38-.67.8.8,0,0,1,1.59,0A.79.79,0,0,1,80.27,161.56Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "79.845px 161.76px",
        }}
        id="el5jho916c77"
        className="animable"
      />
      <rect
        x={97.26}
        y={156.73}
        width={1}
        height={6.37}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "97.76px 159.915px",
        }}
        id="elty0vfebrmn"
        className="animable"
      />
      <rect
        x={94.57}
        y={159.41}
        width={6.37}
        height={1}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "97.755px 159.91px",
        }}
        id="elaqtu6skksb8"
        className="animable"
      />
      <g id="el1tomq7zqfn9">
        <rect
          x={94.57}
          y={159.41}
          width={6.37}
          height={1}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "97.755px 159.91px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eljq36bdomggf">
        <rect
          x={97.26}
          y={156.73}
          width={1}
          height={6.37}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "97.76px 159.915px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <rect
        x={108.07}
        y={156.73}
        width={1}
        height={6.37}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "108.57px 159.915px",
        }}
        id="elb1vjqxpll1m"
        className="animable"
      />
      <rect
        x={105.39}
        y={159.41}
        width={6.37}
        height={1}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "108.575px 159.91px",
        }}
        id="elrsnsi0cqwt"
        className="animable"
      />
      <g id="el6w9x9956byu">
        <rect
          x={105.39}
          y={159.41}
          width={6.37}
          height={1}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "108.575px 159.91px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elwv6uxbzdfgg">
        <rect
          x={108.07}
          y={156.73}
          width={1}
          height={6.37}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "108.57px 159.915px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <rect
        x={118.89}
        y={156.73}
        width={1}
        height={6.37}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "119.39px 159.915px",
        }}
        id="eld1zoaw6dvqp"
        className="animable"
      />
      <rect
        x={116.2}
        y={159.41}
        width={6.37}
        height={1}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "119.385px 159.91px",
        }}
        id="el3ysk99omqub"
        className="animable"
      />
      <g id="ell5mmv15xmt">
        <rect
          x={116.2}
          y={159.41}
          width={6.37}
          height={1}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "119.385px 159.91px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elq897om6xzxi">
        <rect
          x={118.89}
          y={156.73}
          width={1}
          height={6.37}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "119.39px 159.915px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <rect
        x={129.71}
        y={156.73}
        width={1}
        height={6.37}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "130.21px 159.915px",
        }}
        id="el9n9jhlf07t"
        className="animable"
      />
      <rect
        x={127.02}
        y={159.41}
        width={6.37}
        height={1}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "130.205px 159.91px",
        }}
        id="ely8d8li9vlkg"
        className="animable"
      />
      <g id="elyf02c4cpje">
        <rect
          x={127.02}
          y={159.41}
          width={6.37}
          height={1}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "130.205px 159.91px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el4j4s34rd8p7">
        <rect
          x={129.71}
          y={156.73}
          width={1}
          height={6.37}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "130.21px 159.915px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <rect
        x={140.52}
        y={156.73}
        width={1}
        height={6.37}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "141.02px 159.915px",
        }}
        id="el7urt43z0yd"
        className="animable"
      />
      <rect
        x={137.84}
        y={159.41}
        width={6.37}
        height={1}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "141.025px 159.91px",
        }}
        id="elw672rbdipv"
        className="animable"
      />
      <g id="ellng577tcei">
        <rect
          x={137.84}
          y={159.41}
          width={6.37}
          height={1}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "141.025px 159.91px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elawqo49hery7">
        <rect
          x={140.52}
          y={156.73}
          width={1}
          height={6.37}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "141.02px 159.915px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <rect
        x={151.34}
        y={156.73}
        width={1}
        height={6.37}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "151.84px 159.915px",
        }}
        id="eleklyksth4pi"
        className="animable"
      />
      <rect
        x={148.65}
        y={159.41}
        width={6.37}
        height={1}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "151.835px 159.91px",
        }}
        id="elblekmjuc5w"
        className="animable"
      />
      <g id="elx9mp6ho6aak">
        <rect
          x={148.65}
          y={159.41}
          width={6.37}
          height={1}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "151.835px 159.91px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el82zz9nfajqj">
        <rect
          x={151.34}
          y={156.73}
          width={1}
          height={6.37}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "151.84px 159.915px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
    </g>
    <g
      id="freepik--Plant--inject-19"
      className="animable"
      style={{
        transformOrigin: "107.36px 358.73px",
      }}
    >
      <path
        d="M96.55,373.91s-22.17-5-34.32-16.22S43.3,326.44,43.3,326.44A191,191,0,0,1,68.16,333c2,2.12,5.76,6.49,7.52,10.63a51.65,51.65,0,0,0,.06-7.6,34,34,0,0,1,7.89,4.79C93.7,349.61,96.55,373.91,96.55,373.91Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "69.925px 350.175px",
        }}
        id="elhbkc0oknbw"
        className="animable"
      />
      <path
        d="M57.14,336.19c-3-2.1-6.1-4.11-9.11-6.22a.18.18,0,0,1,.18-.3c5.71,3.41,11.35,7.06,16.79,10.88,1.9,1.33,3.77,2.72,5.61,4.13l-.38-.66c-.67-1.11-1.37-2.2-2.07-3.29a46.65,46.65,0,0,0-4.71-6.29c-.06-.07,0-.17.11-.1,1.73,2,3.45,4,5,6.07.75,1,1.44,2,2.1,3.11.35.55.68,1.11,1,1.67l.33.57c2.87,2.25,5.66,4.6,8.32,7.08,1.65,1.54,3.23,3.12,4.76,4.76-.85-2.68-1.6-5.39-2.6-8,0-.09.12-.12.15,0,1,2.77,2,5.55,2.77,8.4q2.55,2.74,4.85,5.71c0-.12,0-.24-.08-.36-.25-1-.51-2.09-.79-3.12-.54-2-1.13-4-1.81-6a34.43,34.43,0,0,0-6-11.24c-.06-.08,0-.18.11-.11,4.33,5.13,6.87,11.28,8.49,17.73.23.95.46,1.89.67,2.84.12.5.25,1,.34,1.53.67.91,1.32,1.82,2,2.76a96,96,0,0,1,9.38,18.4,211.28,211.28,0,0,1,7.11,22.14c0,.15-.18.2-.23.06-4.34-13-8.76-26.13-16.19-37.72a86,86,0,0,0-5.06-7.09,41.82,41.82,0,0,1-10.78-2.14,48.07,48.07,0,0,1-10.8-5.22A68.07,68.07,0,0,1,61.09,352a.08.08,0,1,1,.1-.12A44,44,0,0,0,71.35,358a55.68,55.68,0,0,0,11.18,3.75c1.64.36,3.3.62,5,.85A95.49,95.49,0,0,0,81,355.33c-.75-.74-1.52-1.46-2.29-2.18a52,52,0,0,0-8.2-4.69c-3.63-1.76-7.2-3.62-10.8-5.45-2-1-4-2.09-6.09-3.08-.08-.05,0-.17.08-.13,3.65,1.76,7.35,3.4,11,5.09,3.47,1.58,7,3.17,10.29,5l.42.25q-4.51-3.92-9.29-7.51C63.15,340.45,60.17,338.28,57.14,336.19Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "78.8182px 369.042px",
        }}
        id="eltby80zvtji"
        className="animable"
      />
      <path
        d="M58.4,347.61c1.8.79,3.64,1.49,5.42,2.33s3.57,1.86,5.32,2.89c.07.05,0,.15-.08.12-1.76-.85-3.55-1.64-5.3-2.52s-3.57-1.87-5.41-2.67C58.27,347.72,58.31,347.57,58.4,347.61Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "63.7378px 350.279px",
        }}
        id="eljlg1lfzblwo"
        className="animable"
      />
      <path
        d="M168.56,262.2a31.65,31.65,0,0,0-6.62,18.41c-.08,2,.24,4.75.47,8C154.14,293,147.94,301,147.94,301c4.7-2.82,11.14-4,14.62-3.5a43.49,43.49,0,0,1-.85,6.62c-1.53-.09-4.5.12-9.4,1.86a21.14,21.14,0,0,1,8.48,1.68c-3.95,12.58-15.7,25.49-48.13,31A68.07,68.07,0,0,1,118.4,293C128.12,273.87,168.56,262.2,168.56,262.2Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "139.791px 300.43px",
        }}
        id="elal9sq97veyd"
        className="animable"
      />
      <path
        d="M101.43,392.42q.84-8.06,2.1-16.07a320.67,320.67,0,0,1,6.54-31.74,127.84,127.84,0,0,1,4.68-14.47,20.48,20.48,0,0,1-.11-2.6c0-1,0-2.08,0-3.11q0-3.12.3-6.23a74.84,74.84,0,0,1,2-12.16c.59-2.31,1.28-4.58,2-6.87,0-.09.17-.05.14,0a91.09,91.09,0,0,0-2.39,12.14,115.27,115.27,0,0,0-1.09,12.18c0,1.11-.06,2.22-.06,3.34,0,.47,0,.95,0,1.43A102.21,102.21,0,0,1,121,317a138.81,138.81,0,0,1,11.1-16.61,7,7,0,0,1,.27-1.81c.15-.73.31-1.45.47-2.17.33-1.48.7-3,1.11-4.42.8-2.94,1.87-5.78,2.79-8.68,0-.1.17-.05.14,0-.86,2.87-1.43,5.85-2.08,8.77-.33,1.45-.64,2.9-1,4.35-.16.72-.32,1.44-.47,2.17l0,.16c1.31-1.65,2.65-3.29,4-4.89,6.19-7.26,13-14,19.82-20.61.84-.82,1.7-1.62,2.55-2.43a.08.08,0,0,1,.11.11c-6.53,6.82-13,13.71-19.06,20.92-1.44,1.72-2.87,3.45-4.27,5.21.74-.5,1.48-1,2.21-1.46,1.89-1.24,3.8-2.44,5.77-3.54s3.95-2.18,6-3.19,4.31-1.87,6.31-3c.09-.05.16.09.08.13a46.16,46.16,0,0,0-5.92,3.28c-2,1.17-4,2.35-5.89,3.58s-3.82,2.4-5.71,3.63c-1.58,1-3.13,2.14-4.77,3.09a154,154,0,0,0-10.39,15.12,111.4,111.4,0,0,0-5.37,10.19l1.16-.5,3-1.32c1.91-.85,3.83-1.68,5.74-2.51,3.64-1.58,7.33-3,10.9-4.77a49.26,49.26,0,0,0,5.82-3.23c.08-.06.15.07.08.13-6.65,4.58-14.11,7.81-21.47,11.07l-3.19,1.41c-.88.39-1.76.82-2.66,1.16a114.82,114.82,0,0,0-5.49,15,306.47,306.47,0,0,0-7,31.38c-1.86,10.59-3.12,21.26-4.09,32-.13,1.34-.25,2.67-.4,4a.42.42,0,0,1-.84,0C100.31,403.27,100.86,397.82,101.43,392.42Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "130.089px 339.978px",
        }}
        id="elqq83t67zhtc"
        className="animable"
      />
      <path
        d="M121.16,302.85c.6-3.3,1.48-6.52,2.37-9.75,0-.1.16-.05.14,0-.82,3.17-1.41,6.4-2,9.62s-1.15,6.57-1.61,9.87c0,.05-.09.06-.09,0A88.81,88.81,0,0,1,121.16,302.85Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "121.821px 302.837px",
        }}
        id="elujzox8eaf9d"
        className="animable"
      />
      <path
        d="M135.14,311.79a57.5,57.5,0,0,1,7.06-4c1.18-.61,2.38-1.19,3.57-1.77s2.52-1.1,3.74-1.71a.08.08,0,0,1,.08.13c-1.19.59-2.31,1.31-3.48,1.95s-2.39,1.32-3.6,2c-2.37,1.27-4.7,2.68-7.19,3.72A.18.18,0,0,1,135.14,311.79Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "142.328px 308.22px",
        }}
        id="elb7nq9siu1tq"
        className="animable"
      />
      <g id="elpbziaxdu1vb">
        <path
          d="M96.15,335.51s-20.52-4.9-29.93-18.66c-9-13.14-11.91-35.54-12.14-37.51.37,1.8,4.61,21.38,18.57,30.5C83.34,316.83,93.67,322.57,96.15,335.51Z"
          style={{
            fill: "rgb(38, 50, 56)",
            opacity: 0.1,
            transformOrigin: "75.115px 307.425px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M77.21,300.07a26.21,26.21,0,0,1,7.57,12.45,0,0,0,0,1-.07,0,30.51,30.51,0,0,0-7.6-12.37C77,300.11,77.14,300,77.21,300.07Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "80.9246px 306.284px",
        }}
        id="elcutvuvkxi1l"
        className="animable"
      />
      <path
        d="M96.15,335.51s-20.52-4.9-29.93-18.66c-9-13.14-11.91-35.54-12.14-37.51a.84.84,0,0,1,0-.14s9.84,14,20.18,17.55S99.11,312.65,96.15,335.51Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "75.3088px 307.355px",
        }}
        id="el65llo7r3y03"
        className="animable"
      />
      <path
        d="M57.7,286.91A82.73,82.73,0,0,0,64,297.75a43.66,43.66,0,0,0,8.43,8.69c2.79,2.21,5.74,4.22,8.56,6.38l-.07-.1c-.53-.78-1.09-1.55-1.68-2.29-1.17-1.5-2.45-2.92-3.75-4.32-2.54-2.77-5.15-5.48-7.43-8.47a30.82,30.82,0,0,1-3.29-5.3c0-.09.1-.14.14,0,3.33,6.93,10,11.37,14.72,17.22a31.89,31.89,0,0,1,1.89,2.6,10.86,10.86,0,0,1,1.18,2.08,43.35,43.35,0,0,1,7,7.07,28.39,28.39,0,0,0-.82-4,20.06,20.06,0,0,0-2.18-5.19c0-.08.08-.17.13-.09a24.74,24.74,0,0,1,2.39,5.17A18.1,18.1,0,0,1,90,321.6h0a40.49,40.49,0,0,1,2.77,4.31c-.09-.44-.17-.88-.26-1.32-.4-2-.85-4.08-1.38-6.09A36.58,36.58,0,0,0,86.21,307a.08.08,0,0,1,.13-.08,44,44,0,0,1,5.46,11.3c.66,2,1.18,4.06,1.64,6.13.23,1,.43,2,.62,3,.1.52.22,1.06.3,1.6a73.26,73.26,0,0,1,4.73,13.9,203.28,203.28,0,0,1,4.38,25.94,216.58,216.58,0,0,1,1.41,27.54c0,1.15-.08,2.3-.15,3.46,0,.29-.46.34-.47,0-.2-4.55-.32-9.1-.59-13.65s-.67-9.09-1.15-13.63A242.43,242.43,0,0,0,98.24,346c-1.66-7.5-3.82-15.05-7.92-21.62a40.74,40.74,0,0,0-7.4-8.8c-2.87-2.58-6-4.84-9-7.23a49.5,49.5,0,0,1-8.31-8A64.92,64.92,0,0,1,59,289.77c-.48-.93-1-1.85-1.47-2.78C57.52,286.91,57.65,286.83,57.7,286.91Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "81.2133px 343.452px",
        }}
        id="elql571nb0whr"
        className="animable"
      />
      <path
        d="M61.24,289.92a67.25,67.25,0,0,0,4.57,8c0,.06-.06.12-.1.06a55.76,55.76,0,0,1-4.62-8C61.05,289.88,61.19,289.83,61.24,289.92Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "63.4465px 293.939px",
        }}
        id="elod9cd0tqcd"
        className="animable"
      />
      <path
        d="M171.42,326.44s.33,29.15-17.1,37.56-26.78,1.41-38.62,4.23l0,0c.46-.69,8.91-12.84,22.36-16.19C151.9,348.57,167.23,338.58,171.42,326.44Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "143.56px 347.376px",
        }}
        id="elabgxnphu7vb"
        className="animable"
      />
      <g id="eldu8nspb14d7">
        <path
          d="M171.42,326.44s.33,29.15-17.1,37.56-26.78,1.41-38.62,4.23l0,0a66,66,0,0,1,19.82-5.84C146.53,361,167.52,353.88,171.42,326.44Z"
          style={{
            opacity: 0.1,
            transformOrigin: "143.56px 347.376px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M114.55,368.75a29.31,29.31,0,0,1,6.93-4.7c.38-.18.76-.32,1.14-.48a7.63,7.63,0,0,1,.59-.67,20.15,20.15,0,0,1,1.64-1.56,24.14,24.14,0,0,1,3.5-2.53c2.89-1.7,6.06-2.89,9-4.46a.07.07,0,1,1,.07.13c-1.46.76-2.87,1.59-4.29,2.42s-2.79,1.58-4.14,2.44a29,29,0,0,0-3.45,2.59c-.3.27-.6.56-.89.86a45.61,45.61,0,0,1,5.89-1.57c3.19-.64,6.41-1.1,9.6-1.78a43.13,43.13,0,0,0,4.62-1.25c1.45-1.16,2.89-2.32,4.26-3.58a34.49,34.49,0,0,0,4.51-5c.06-.08.19,0,.13.08a53.43,53.43,0,0,1-7.15,7.89c.75-.29,1.5-.59,2.23-.93A36,36,0,0,0,163.6,344.3c.06-.08.19,0,.13.07a40.78,40.78,0,0,1-12,11.54,36.18,36.18,0,0,1-7.6,3.59,69.1,69.1,0,0,1-9.43,2.23,66.74,66.74,0,0,0-9.24,2.2,27.73,27.73,0,0,0-7.74,4.08A36.17,36.17,0,0,0,107.26,381c-3.3,6.61-4.87,14-5.75,21.31,0,.31-.52.31-.5,0C101.83,390,105.11,377.26,114.55,368.75Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "132.377px 373.405px",
        }}
        id="elnadfl2ez4xj"
        className="animable"
      />
      <path
        d="M134.78,359.24a10.07,10.07,0,0,1,3.58-3.25,13.08,13.08,0,0,0,4.26-3.52c.06-.08.19,0,.13.08a14.4,14.4,0,0,1-3.76,3.37,13.06,13.06,0,0,0-4.1,3.39C134.84,359.38,134.73,359.31,134.78,359.24Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "138.766px 355.888px",
        }}
        id="el2pecc3t09jr"
        className="animable"
      />
      <polygon
        points="125.53 390.26 124.74 406.29 122.31 455.26 85.74 455.26 83.31 406.29 82.52 390.26 125.53 390.26"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "104.025px 422.76px",
        }}
        id="el4vlas6powi6"
        className="animable"
      />
      <polygon
        points="125.53 390.26 124.74 406.29 83.31 406.29 82.52 390.26 125.53 390.26"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "104.025px 398.275px",
        }}
        id="elkbmecwzb6sa"
        className="animable"
      />
      <rect
        x={79.95}
        y={383.57}
        width={48.14}
        height={15.29}
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "104.02px 391.215px",
        }}
        id="el2uwv5sftjgx"
        className="animable"
      />
    </g>
    <defs>
      <filter id="active" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter id="hover" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#ff0000" floodOpacity={0.5} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
        <feColorMatrix
          type="matrix"
          values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
        />
      </filter>
    </defs>
  </svg>
);
export default SvgComponent;

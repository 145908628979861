import React from "react";
import SubPageHead from "../Components/SubPageHead";
const Blog = () => {
  return (
    <>
      <SubPageHead Header="BLOG" SubHeader="Coming Soon........." />
    </>
  );
};

export default Blog;

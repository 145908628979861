// import Img1 from "../Images/Gallery/1.jpeg";
import Img2 from "../Images/Gallery/2.jpeg";
import Img3 from "../Images/Gallery/3.jpeg";
import Img4 from "../Images/Gallery/4.jpeg";
import Img5 from "../Images/Gallery/5.jpeg";
import Img6 from "../Images/Gallery/6.jpeg";
import Img7 from "../Images/Gallery/7.jpeg";
import Img8 from "../Images/Gallery/8.jpeg";
import Img9 from "../Images/Gallery/9.jpeg";
import Img10 from "../Images/Gallery/10.jpeg";
import Img11 from "../Images/Gallery/11.jpeg";
import Img12 from "../Images/Gallery/12.jpeg";
import Img13 from "../Images/Gallery/13.jpeg";
import Img14 from "../Images/Gallery/14.jpeg";
import Img15 from "../Images/Gallery/15.jpeg";

const Gallery = [
  {
    id: "1",
    img: require("../Images/Gallery/1.jpeg"),
  },
  {
    id: "2",
    img: Img2,
  },
  {
    id: "3",
    img: Img3,
  },
  {
    id: "4",
    img: Img4,
  },
  {
    id: "5",
    img: Img5,
  },
  {
    id: "6",
    img: Img6,
  },
  {
    id: "7",
    img: Img7,
  },
  {
    id: "8",
    img: Img8,
  },
  {
    id: "9",
    img: Img9,
  },
  {
    id: "10",
    img: Img10,
  },
  {
    id: "11",
    img: Img11,
  },
  {
    id: "12",
    img: Img12,
  },
  {
    id: "13",
    img: Img13,
  },
  {
    id: "14",
    img: Img14,
  },
  {
    id: "15",
    img: Img15,
  },
];
export default Gallery;

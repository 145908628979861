import * as React from "react";
import "./ProductsSvg.css";
const SvgComponent = () => (
  <svg
    className="animated"
    id="freepik_stories-web-devices"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 750 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlnssvgjs="http://svgjs.com/svgjs"
  >
    <g
      id="freepik--background-complete--inject-409"
      className="animable animator-active"
      style={{
        transformOrigin: "359.695px 159.38px",
      }}
    >
      <g
        id="freepik--aiZGp2--inject-409"
        className="animable"
        style={{
          transformOrigin: "125.395px 209.675px",
        }}
      >
        <path
          d="M97.05,185.69a68,68,0,0,0-4.61,21.06H73.79v-.52l.15-1.43a50.56,50.56,0,0,1,1.23-7.35A56.62,56.62,0,0,1,79.32,186a.42.42,0,0,1,.45-.27H97.05Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "85.42px 196.22px",
          }}
          id="elb9bcy07wq24"
          className="animable"
        />
        <path
          d="M96.49,232.74h-.3c-5.61,0-11.23,0-16.84,0a.4.4,0,0,1-.42-.27,52.37,52.37,0,0,1-4.25-13,49.86,49.86,0,0,1-.84-6.56c0-.07,0-.15,0-.22v-.52l.28,0H92.12c.33,0,.32,0,.34.34.11,1.5.21,3,.36,4.49A60,60,0,0,0,94,224.08a66,66,0,0,0,2.44,8.39Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "85.165px 222.456px",
          }}
          id="el2tqzugi4m02"
          className="animable"
        />
        <path
          d="M168.57,238.17l-.14.25a52,52,0,0,1-4.86,6.3,48.1,48.1,0,0,1-7.12,6.56,50.92,50.92,0,0,1-8.11,5,50.39,50.39,0,0,1-8.57,3.34,54,54,0,0,1-7.14,1.52l-.7.12h-.41l-.4-.26.46-.32a54.82,54.82,0,0,0,13.51-13.11,60.86,60.86,0,0,0,5.52-9.16.41.41,0,0,1,.43-.27h17.53Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "149.845px 249.699px",
          }}
          id="elk5ycwnm6o0g"
          className="animable"
        />
        <path
          d="M151.43,206.73H127.35v-21h18.8a.34.34,0,0,1,.37.25,57.79,57.79,0,0,1,3.83,12.33,60.1,60.1,0,0,1,.93,6.67Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "139.39px 196.229px",
          }}
          id="el5gkhh8f9jy"
          className="animable"
        />
        <path
          d="M151.28,212.35c0,.5,0,1-.07,1.48-.11,1.18-.19,2.35-.35,3.51-.23,1.62-.47,3.23-.8,4.83a57.3,57.3,0,0,1-3.12,10.26.4.4,0,0,1-.44.31q-9.48,0-19,0h-.34V212.35Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "139.22px 222.547px",
          }}
          id="ele7ki4vuorwo"
          className="animable"
        />
        <path
          d="M121.92,185.82v21.07H98.41c0-.53,0-1.06.1-1.58.13-1.17.22-2.36.41-3.53.29-1.78.59-3.57,1-5.34a54.92,54.92,0,0,1,3.39-10.37.37.37,0,0,1,.4-.25h18.23Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "110.175px 196.353px",
          }}
          id="el5rkkanw6vkn"
          className="animable"
        />
        <path
          d="M122,212.63q0,9.9,0,19.81v.3h-.31q-9.24,0-18.47,0a.39.39,0,0,1-.42-.27,54.13,54.13,0,0,1-3.2-10.62c-.35-1.87-.62-3.76-.86-5.65-.15-1.23-.19-2.48-.27-3.73a.78.78,0,0,1,0-.13h23.23C121.89,212.32,122,212.39,122,212.63Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "110.234px 222.54px",
          }}
          id="elsbe56gv10cn"
          className="animable"
        />
        <path
          d="M177,206.88H157.23a68.45,68.45,0,0,0-4.6-21.19H171a.4.4,0,0,1,.43.26A52.18,52.18,0,0,1,176,199.13a59.28,59.28,0,0,1,1,7.39Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "164.815px 196.283px",
          }}
          id="elbz4849a02"
          className="animable"
        />
        <path
          d="M177,212.19c0,.48,0,.93-.06,1.38a52.71,52.71,0,0,1-.65,5.31,51.73,51.73,0,0,1-3.63,11.85c-.27.61-.56,1.21-.83,1.82a.27.27,0,0,1-.29.19H153.38l-.2,0c.22-.63.44-1.24.65-1.86a63.4,63.4,0,0,0,2.44-9.6,61.85,61.85,0,0,0,.85-7.16c0-.57.07-1.13.09-1.7,0-.15,0-.23.21-.21H177Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "165.09px 222.466px",
          }}
          id="el3xcpixap24o"
          className="animable"
        />
        <path
          d="M168,180.32l0,.08h-17.5a.4.4,0,0,1-.42-.24,59.23,59.23,0,0,0-8.56-12.7,49,49,0,0,0-6.57-6.06c-1.18-.9-2.4-1.75-3.6-2.62l-.2-.15.23-.17c0-.1-.06-.22-.11-.37a50.9,50.9,0,0,1,17.45,5.24A50.27,50.27,0,0,1,168,180.32Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "149.575px 169.247px",
          }}
          id="elq3wuouh8u4q"
          className="animable"
        />
        <path
          d="M118.58,261a1.76,1.76,0,0,1-.5.08,47,47,0,0,1-5.63-1.09,50.78,50.78,0,0,1-10-3.71,51.54,51.54,0,0,1-10.21-6.7,48.05,48.05,0,0,1-6.13-6.14,49.89,49.89,0,0,1-3.83-5.3.79.79,0,0,1-.08-.16H98.54a.39.39,0,0,1,.4.26,62.37,62.37,0,0,0,5.91,9.62,59.86,59.86,0,0,0,6.92,7.67,52.71,52.71,0,0,0,6.66,5.35Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "100.39px 249.529px",
          }}
          id="el4k5gg2yixbe"
          className="animable"
        />
        <path
          d="M118.37,158.61c-.74.53-1.6,1.14-2.44,1.78a60,60,0,0,0-7.78,7.25A54.54,54.54,0,0,0,100,179.29c-.14.27-.26.55-.42.8a.36.36,0,0,1-.25.16c-.29,0-.58,0-.88,0H82.73c8.75-12.35,20.5-19.78,35.45-22.05a2,2,0,0,1-.1.21Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "100.55px 169.225px",
          }}
          id="el9ygny6snb4h"
          className="animable"
        />
        <path
          d="M144.24,238.18a56.18,56.18,0,0,1-8,10.76,50.48,50.48,0,0,1-9.08,7.71V238.18Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "135.7px 247.415px",
          }}
          id="el2iwrz9n5fuc"
          className="animable"
        />
        <path
          d="M121.92,238.19v18.1a52.24,52.24,0,0,1-16.34-18.1Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "113.75px 247.24px",
          }}
          id="el63do65elviw"
          className="animable"
        />
        <path
          d="M143.71,180.24H127.35V163a49.77,49.77,0,0,1,9.18,7.68A58.2,58.2,0,0,1,143.71,180.24Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "135.53px 171.62px",
          }}
          id="elqzi2dj7p85f"
          className="animable"
        />
        <path
          d="M121.92,163.52v16.86h-15.7A49.8,49.8,0,0,1,121.92,163.52Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "114.07px 171.95px",
          }}
          id="el3lb4boyhfla"
          className="animable"
        />
      </g>
      <path
        d="M645.6,147H508.17V57.5H645.6Zm-136.43-1H644.6V58.5H509.17Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "576.885px 102.25px",
        }}
        id="eldhma1cze8o5"
        className="animable"
      />
      <path
        d="M645.6,70.26H508.17V57.5H645.6Zm-136.43-1H644.6V58.5H509.17Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "576.885px 63.88px",
        }}
        id="eloqdivbbbboh"
        className="animable"
      />
      <path
        d="M519.7,64a3,3,0,1,1-3-3A3,3,0,0,1,519.7,64Z"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "516.7px 64px",
        }}
        id="elz1143b0txrg"
        className="animable"
      />
      <path
        d="M531.78,64a3,3,0,1,1-3-3A3,3,0,0,1,531.78,64Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "528.78px 64px",
        }}
        id="elisavc21nk5i"
        className="animable"
      />
      <path
        d="M543.85,64a3,3,0,1,1-3-3A2.95,2.95,0,0,1,543.85,64Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "540.85px 63.9998px",
        }}
        id="el1je1gh5sr5b"
        className="animable"
      />
    </g>
    <g
      id="freepik--Shadow--inject-409"
      className="animable"
      style={{
        transformOrigin: "375px 432.19px",
      }}
    >
      <ellipse
        cx={375}
        cy={432.19}
        rx={328.77}
        ry={28.34}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "375px 432.19px",
        }}
        id="eluvpgohzwegm"
        className="animable"
      />
    </g>
    <g
      id="freepik--Floor--inject-409"
      className="animable"
      style={{
        transformOrigin: "375px 348.19px",
      }}
    >
      <polygon
        points="50.72 348.19 131.79 347.95 212.86 347.86 375 347.69 537.14 347.86 618.21 347.95 699.28 348.19 618.21 348.44 537.14 348.52 375 348.69 212.86 348.52 131.79 348.43 50.72 348.19"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "375px 348.19px",
        }}
        id="eloslg7ie8rt"
        className="animable"
      />
    </g>
    <g
      id="freepik--Computer--inject-409"
      className="animable"
      style={{
        transformOrigin: "370.045px 249.305px",
      }}
    >
      <rect
        x={170.66}
        y={82.79}
        width={398.77}
        height={271.16}
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "370.045px 218.37px",
        }}
        id="elsycfyf1jze"
        className="animable"
      />
      <rect
        x={170.66}
        y={313.95}
        width={398.77}
        height={40}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "370.045px 333.95px",
        }}
        id="elztawwvva1oe"
        className="animable"
      />
      <rect
        x={292.34}
        y={402.83}
        width={155.41}
        height={12.99}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "370.045px 409.325px",
        }}
        id="el2qbgqn1zfoz"
        className="animable"
      />
      <polygon
        points="429.25 402.84 310.85 402.84 313.86 380.3 314 379.24 314.28 377.12 314.42 376.06 314.71 373.94 314.75 373.62 314.85 372.88 315.14 370.76 315.28 369.7 317.38 353.95 422.71 353.95 424.7 368.81 424.8 369.52 425.13 371.99 425.22 372.7 425.35 373.62 425.55 375.16 425.64 375.88 425.97 378.35 426.07 379.06 429.25 402.84"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "370.05px 378.395px",
        }}
        id="elupi8sl5es6j"
        className="animable"
      />
      <path
        d="M376.54,331.26a6.61,6.61,0,1,1-6.61-6.61A6.6,6.6,0,0,1,376.54,331.26Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "369.93px 331.26px",
        }}
        id="elbzb6dpck3hf"
        className="animable"
      />
      <polygon
        points="425.35 373.62 314.75 373.62 314.85 372.88 315.14 370.76 315.28 369.7 317.38 353.95 422.71 353.95 424.7 368.81 424.8 369.52 425.13 371.99 425.22 372.7 425.35 373.62"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "370.05px 363.785px",
        }}
        id="el51mzn2w20ol"
        className="animable"
      />
      <rect
        x={189.06}
        y={100.42}
        width={361.97}
        height={194.72}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "370.045px 197.78px",
        }}
        id="elmjwkcnucoqo"
        className="animable"
      />
      <rect
        x={189.06}
        y={100.42}
        width={361.97}
        height={194.72}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "370.045px 197.78px",
        }}
        id="elwxpp2o1fig"
        className="animable"
      />
      <polygon
        points="282.26 250.98 315.85 250.76 349.45 250.64 416.64 250.54 483.84 250.64 517.44 250.76 551.03 250.98 517.44 251.19 483.84 251.32 416.64 251.42 349.45 251.32 315.85 251.19 282.26 250.98"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "416.645px 250.98px",
        }}
        id="elrzuyj05tb6"
        className="animable"
      />
      <path
        d="M327.57,240.6a1.2,1.2,0,1,0,0-2.39,1.55,1.55,0,0,0-.21,0l-.24,2.26A1.34,1.34,0,0,0,327.57,240.6Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "328px 239.405px",
        }}
        id="el7fplqomeq8"
        className="animable"
      />
      <path
        d="M331.16,241.8a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,331.16,241.8Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "331.16px 243px",
        }}
        id="elrokt63r4cb"
        className="animable"
      />
      <path
        d="M331.16,234.61a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,331.16,234.61Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "331.16px 235.81px",
        }}
        id="eltzljibj1yp"
        className="animable"
      />
      <path
        d="M328.77,232.22a1.19,1.19,0,0,0-.67-1.06l-.23,2.19A1.18,1.18,0,0,0,328.77,232.22Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "328.32px 232.255px",
        }}
        id="elv1vcdt92b49"
        className="animable"
      />
      <path
        d="M331.16,227.43a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,331.16,227.43Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "331.16px 228.63px",
        }}
        id="el5es45k04ops"
        className="animable"
      />
      <path
        d="M328.73,225.23a1.42,1.42,0,0,0,0-.2s0,0,0-.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "328.732px 225.1px",
        }}
        id="el1movku5hfg7"
        className="animable"
      />
      <path
        d="M331.16,220.25a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,331.16,220.25Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "331.16px 221.45px",
        }}
        id="elm88sohxsnrs"
        className="animable"
      />
      <path
        d="M331.16,213.06a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,331.16,213.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "331.16px 214.26px",
        }}
        id="els9wekdc669q"
        className="animable"
      />
      <path
        d="M332.36,207.07a1.19,1.19,0,0,0-1.2-1.19,1.1,1.1,0,0,0-.4.08l-.22,2.11a1.17,1.17,0,0,0,1.82-1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "331.45px 207.074px",
        }}
        id="ellrsqnss4b1"
        className="animable"
      />
      <path
        d="M332.36,199.89a1.17,1.17,0,0,0-.84-1.12l-.25,2.3A1.19,1.19,0,0,0,332.36,199.89Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "331.815px 199.92px",
        }}
        id="eljnvrwfnr45a"
        className="animable"
      />
      <path
        d="M332.36,192.71a1.13,1.13,0,0,0-.15-.55l-.14,1.3A1.13,1.13,0,0,0,332.36,192.71Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "332.215px 192.81px",
        }}
        id="ele0d7qjhk5uj"
        className="animable"
      />
      <path
        d="M334.68,168.74l.08,0a1.19,1.19,0,0,0,.16-2.36Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "335.289px 167.56px",
        }}
        id="elpw0yfkkewrq"
        className="animable"
      />
      <path
        d="M338.35,241.8a1.18,1.18,0,0,0-1.15.95l2.05-.52A1.18,1.18,0,0,0,338.35,241.8Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "338.225px 242.275px",
        }}
        id="el0lzxxi23bhgb"
        className="animable"
      />
      <path
        d="M338.35,234.61a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,338.35,234.61Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "338.34px 235.81px",
        }}
        id="elqy1w5mqur4m"
        className="animable"
      />
      <path
        d="M334.76,238.21A1.2,1.2,0,1,0,336,239.4,1.19,1.19,0,0,0,334.76,238.21Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "334.8px 239.409px",
        }}
        id="eltfvuch6gx3"
        className="animable"
      />
      <path
        d="M338.35,227.43a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "338.241px 228.625px",
        }}
        id="elk24eq00dzi"
        className="animable"
      />
      <path
        d="M334.76,231a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "334.651px 232.195px",
        }}
        id="elhfupz92uvru"
        className="animable"
      />
      <path
        d="M338.35,220.25a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,338.35,220.25Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "338.34px 221.45px",
        }}
        id="el9wae4zi6f4"
        className="animable"
      />
      <path
        d="M334.76,223.84A1.2,1.2,0,1,0,336,225,1.19,1.19,0,0,0,334.76,223.84Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "334.801px 225.039px",
        }}
        id="eld8iu84wlvt"
        className="animable"
      />
      <path
        d="M338.35,213.06a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "338.241px 214.255px",
        }}
        id="elkdwcd4ux4m9"
        className="animable"
      />
      <path
        d="M334.76,216.65a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,334.76,216.65Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "334.75px 217.85px",
        }}
        id="elwroeso9gpv"
        className="animable"
      />
      <path
        d="M338.35,205.88a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,338.35,205.88Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "338.34px 207.08px",
        }}
        id="el4sznjbkvrro"
        className="animable"
      />
      <path
        d="M334.76,209.47a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "334.651px 210.665px",
        }}
        id="elq9nnzeae3o"
        className="animable"
      />
      <path
        d="M338.35,198.69a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,338.35,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "338.34px 199.89px",
        }}
        id="ell80isjadhb8"
        className="animable"
      />
      <path
        d="M334.76,202.29a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,334.76,202.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "334.75px 203.49px",
        }}
        id="el4hwxbd41q98"
        className="animable"
      />
      <path
        d="M338.35,191.51a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "338.241px 192.705px",
        }}
        id="eltv62vlqwoz"
        className="animable"
      />
      <path
        d="M334.76,195.1a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,334.76,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "334.75px 196.3px",
        }}
        id="el0x1i23hpt0e"
        className="animable"
      />
      <path
        d="M338.35,184.33a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,338.35,184.33Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "338.34px 185.53px",
        }}
        id="elazwxfx6h1ik"
        className="animable"
      />
      <path
        d="M334.76,187.92a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,334.76,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "334.75px 189.12px",
        }}
        id="elr65wx1o4guc"
        className="animable"
      />
      <path
        d="M338.35,177.14a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,338.35,177.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "338.34px 178.34px",
        }}
        id="el2f4iodzbtgx"
        className="animable"
      />
      <path
        d="M334.76,180.74a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,334.76,180.74Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "334.75px 181.94px",
        }}
        id="el8jt6py1dfdo"
        className="animable"
      />
      <path
        d="M336,174.75a1.19,1.19,0,0,0-1.19-1.2,1.16,1.16,0,0,0-.61.19l-.2,1.88a1.16,1.16,0,0,0,.81.32A1.19,1.19,0,0,0,336,174.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "335px 174.745px",
        }}
        id="el4pcb53nlnvl"
        className="animable"
      />
      <path
        d="M338.35,170a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,338.35,170Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "338.34px 171.2px",
        }}
        id="eltmuot64nsdq"
        className="animable"
      />
      <path
        d="M338.35,162.78a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,338.35,162.78Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "338.34px 163.98px",
        }}
        id="elr2qy9a605c"
        className="animable"
      />
      <path
        d="M335.46,161.32a1.15,1.15,0,0,0,.18-1.73Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "335.706px 160.455px",
        }}
        id="elqkvk8b26na"
        className="animable"
      />
      <path
        d="M338.35,155.59a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "338.241px 156.785px",
        }}
        id="elu1qcbuuha0r"
        className="animable"
      />
      <path
        d="M338.35,148.41a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,338.35,148.41Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "338.34px 149.61px",
        }}
        id="el8bqbl9rs3pa"
        className="animable"
      />
      <path
        d="M338.35,143.62a1.2,1.2,0,0,0,0-2.4,1.17,1.17,0,0,0-.81.33l-.16,1.53A1.16,1.16,0,0,0,338.35,143.62Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "338.463px 142.42px",
        }}
        id="elaacyao6kgu"
        className="animable"
      />
      <path
        d="M338.35,136.43a1.2,1.2,0,0,0,0-2.39h0l-.25,2.34A1.17,1.17,0,0,0,338.35,136.43Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "338.77px 135.235px",
        }}
        id="el1u5ut76ri9t"
        className="animable"
      />
      <path
        d="M341.25,132.6a1.2,1.2,0,0,0,.69.24H342Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "341.625px 132.72px",
        }}
        id="elzwkuy2n0t2p"
        className="animable"
      />
      <path
        d="M345.53,234.61a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,345.53,234.61Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "345.53px 235.81px",
        }}
        id="elerha77qts2h"
        className="animable"
      />
      <path
        d="M341.94,238.21a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,341.94,238.21Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "341.94px 239.41px",
        }}
        id="el0q2x8km7znf"
        className="animable"
      />
      <path
        d="M345.53,227.43a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,345.53,227.43Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "345.53px 228.63px",
        }}
        id="elkm8mr32uj9i"
        className="animable"
      />
      <path
        d="M341.94,231a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,341.94,231Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "341.94px 232.2px",
        }}
        id="ellxhzfytlu4"
        className="animable"
      />
      <path
        d="M345.53,220.25a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "345.639px 221.445px",
        }}
        id="el8r1xkdpm8pr"
        className="animable"
      />
      <path
        d="M341.94,223.84a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,341.94,223.84Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "341.94px 225.04px",
        }}
        id="elvy47m3t5a"
        className="animable"
      />
      <path
        d="M345.53,213.06a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,345.53,213.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "345.53px 214.26px",
        }}
        id="el9fyxo1qwhqc"
        className="animable"
      />
      <path
        d="M341.94,216.65a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,341.94,216.65Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "341.94px 217.85px",
        }}
        id="el4hssno3m516"
        className="animable"
      />
      <path
        d="M345.53,205.88a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,345.53,205.88Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "345.53px 207.08px",
        }}
        id="el6ott45amrzm"
        className="animable"
      />
      <path
        d="M341.94,209.47a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,341.94,209.47Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "341.94px 210.67px",
        }}
        id="eleqm7349wpy4"
        className="animable"
      />
      <path
        d="M345.53,198.69a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,345.53,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "345.53px 199.89px",
        }}
        id="elfjj4wywaxjk"
        className="animable"
      />
      <path
        d="M341.94,202.29a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,341.94,202.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "341.94px 203.49px",
        }}
        id="elknba8kzlrqh"
        className="animable"
      />
      <path
        d="M345.53,191.51a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,345.53,191.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "345.53px 192.71px",
        }}
        id="eloh0oh34kqqh"
        className="animable"
      />
      <path
        d="M341.94,195.1a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,341.94,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "341.94px 196.3px",
        }}
        id="elnu8su4pxtog"
        className="animable"
      />
      <path
        d="M345.53,184.33a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "345.64px 185.525px",
        }}
        id="elv5l60zg963"
        className="animable"
      />
      <path
        d="M341.94,187.92a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,341.94,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "341.94px 189.12px",
        }}
        id="elnetnqs7xjc8"
        className="animable"
      />
      <path
        d="M345.53,177.14a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,345.53,177.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "345.53px 178.34px",
        }}
        id="elzco600aeeu8"
        className="animable"
      />
      <path
        d="M341.94,180.74a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,341.94,180.74Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "341.94px 181.94px",
        }}
        id="el8e9jwrgedcj"
        className="animable"
      />
      <path
        d="M345.53,170a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,345.53,170Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "345.53px 171.2px",
        }}
        id="eljfgm2xcjjks"
        className="animable"
      />
      <path
        d="M341.94,173.55a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,341.94,173.55Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "341.94px 174.75px",
        }}
        id="elhdfdrggfobq"
        className="animable"
      />
      <path
        d="M345.53,162.78a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "345.639px 163.975px",
        }}
        id="elar9rmtfv13e"
        className="animable"
      />
      <path
        d="M341.94,166.37a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,341.94,166.37Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "341.94px 167.57px",
        }}
        id="el9vsjk7j7iwn"
        className="animable"
      />
      <path
        d="M345.53,155.59a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,345.53,155.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "345.53px 156.79px",
        }}
        id="ele0idc7zf6g7"
        className="animable"
      />
      <path
        d="M341.94,159.18a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,341.94,159.18Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "341.94px 160.38px",
        }}
        id="el3kh4ue6bzme"
        className="animable"
      />
      <path
        d="M345.53,148.41a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "345.639px 149.605px",
        }}
        id="elsezh2c6qqir"
        className="animable"
      />
      <path
        d="M341.94,152a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,341.94,152Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "341.94px 153.2px",
        }}
        id="elem4j6ztrf9i"
        className="animable"
      />
      <path
        d="M345.53,141.22a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,345.53,141.22Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "345.53px 142.42px",
        }}
        id="elzhs2mphlfwe"
        className="animable"
      />
      <path
        d="M341.94,144.82a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,341.94,144.82Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "341.94px 146.02px",
        }}
        id="el26fsrteu6z7"
        className="animable"
      />
      <path
        d="M345.53,134a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,345.53,134Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "345.53px 135.2px",
        }}
        id="el50smu4htbiu"
        className="animable"
      />
      <path
        d="M341.94,137.63a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,341.94,137.63Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "341.94px 138.83px",
        }}
        id="elkcorjd6sppe"
        className="animable"
      />
      <path
        d="M352.72,234.61a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,352.72,234.61Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "352.71px 235.81px",
        }}
        id="elp4h4p6b94kq"
        className="animable"
      />
      <path
        d="M350.32,239.4a1.2,1.2,0,0,0-2.39,0,1.22,1.22,0,0,0,.16.57Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "349.125px 239.14px",
        }}
        id="elk04a5i27k3"
        className="animable"
      />
      <path
        d="M352.72,227.43a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "352.611px 228.625px",
        }}
        id="elioob0g0l8g"
        className="animable"
      />
      <path
        d="M349.12,231a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,349.12,231Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "349.12px 232.2px",
        }}
        id="elfhy9upnr1wh"
        className="animable"
      />
      <path
        d="M352.72,220.25a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,352.72,220.25Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "352.71px 221.45px",
        }}
        id="elvbt9lhphpt"
        className="animable"
      />
      <path
        d="M349.12,223.84a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "349.229px 225.035px",
        }}
        id="elquiddx1u4yr"
        className="animable"
      />
      <path
        d="M352.72,213.06a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "352.611px 214.255px",
        }}
        id="elo8c22r5back"
        className="animable"
      />
      <path
        d="M349.12,216.65a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,349.12,216.65Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "349.12px 217.85px",
        }}
        id="elzrnll5pbq1m"
        className="animable"
      />
      <path
        d="M352.72,205.88a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,352.72,205.88Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "352.71px 207.08px",
        }}
        id="elgzoy5uo84t"
        className="animable"
      />
      <path
        d="M349.12,209.47a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,349.12,209.47Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "349.12px 210.67px",
        }}
        id="elryz6dl3us7"
        className="animable"
      />
      <path
        d="M352.72,198.69a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,352.72,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "352.71px 199.89px",
        }}
        id="el4o1zdi6dvot"
        className="animable"
      />
      <path
        d="M349.12,202.29a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "349.23px 203.485px",
        }}
        id="elxe486e223ff"
        className="animable"
      />
      <path
        d="M352.72,191.51a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "352.611px 192.705px",
        }}
        id="elmglem09mjgk"
        className="animable"
      />
      <path
        d="M349.12,195.1a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,349.12,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "349.12px 196.3px",
        }}
        id="el381i7ltagyi"
        className="animable"
      />
      <path
        d="M352.72,184.33a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,352.72,184.33Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "352.71px 185.53px",
        }}
        id="ell1e9s0kyb3h"
        className="animable"
      />
      <path
        d="M349.12,187.92a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,349.12,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "349.12px 189.12px",
        }}
        id="els3rxzuf3k2"
        className="animable"
      />
      <path
        d="M352.72,177.14a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,352.72,177.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "352.71px 178.34px",
        }}
        id="elwawxt56zsd"
        className="animable"
      />
      <path
        d="M349.12,180.74a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "349.229px 181.935px",
        }}
        id="elsorz0xeetwc"
        className="animable"
      />
      <path
        d="M352.72,170a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,352.72,170Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "352.71px 171.2px",
        }}
        id="el2l5o7u9zwpj"
        className="animable"
      />
      <path
        d="M349.12,173.55a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,349.12,173.55Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "349.12px 174.75px",
        }}
        id="el3e847r2wo5d"
        className="animable"
      />
      <path
        d="M352.72,162.78a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,352.72,162.78Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "352.71px 163.98px",
        }}
        id="eli77o1vmwhq"
        className="animable"
      />
      <path
        d="M349.12,166.37a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "349.229px 167.565px",
        }}
        id="elvh1u9njki8"
        className="animable"
      />
      <path
        d="M352.72,155.59a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "352.611px 156.785px",
        }}
        id="elll8hgo87az"
        className="animable"
      />
      <path
        d="M349.12,159.18a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,349.12,159.18Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "349.12px 160.38px",
        }}
        id="elq67d1l9l98"
        className="animable"
      />
      <path
        d="M352.72,148.41a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,352.72,148.41Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "352.71px 149.61px",
        }}
        id="elufvm9u2tlnq"
        className="animable"
      />
      <path
        d="M349.12,152a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,349.12,152Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "349.12px 153.2px",
        }}
        id="elep82e1xmqap"
        className="animable"
      />
      <path
        d="M352.72,141.22a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,352.72,141.22Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "352.71px 142.42px",
        }}
        id="elu29uo3bj58"
        className="animable"
      />
      <path
        d="M349.12,144.82a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "349.229px 146.015px",
        }}
        id="el3h3nslhnxg8"
        className="animable"
      />
      <path
        d="M352.72,136.43a1.22,1.22,0,0,0,.38-.07l-1.33-.43A1.17,1.17,0,0,0,352.72,136.43Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "352.435px 136.18px",
        }}
        id="elgoig4s7hl39"
        className="animable"
      />
      <path
        d="M349.12,137.63a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,349.12,137.63Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "349.12px 138.83px",
        }}
        id="elmc5ffs11l9o"
        className="animable"
      />
      <path
        d="M361.1,235.81a1.2,1.2,0,0,0-2.4,0,1.18,1.18,0,0,0,1.08,1.17l.78-.2A1.18,1.18,0,0,0,361.1,235.81Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "359.9px 235.795px",
        }}
        id="elsz2xbxjkm6"
        className="animable"
      />
      <path
        d="M359.9,227.43a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,359.9,227.43Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "359.9px 228.63px",
        }}
        id="elp416tshi59a"
        className="animable"
      />
      <path
        d="M356.31,231a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "356.201px 232.195px",
        }}
        id="el4kgev1qk7ze"
        className="animable"
      />
      <path
        d="M359.9,220.25a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,359.9,220.25Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "359.9px 221.45px",
        }}
        id="elgfy01i3lene"
        className="animable"
      />
      <path
        d="M356.31,223.84A1.2,1.2,0,1,0,357.5,225,1.19,1.19,0,0,0,356.31,223.84Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "356.301px 225.04px",
        }}
        id="elqtw4nlljwjf"
        className="animable"
      />
      <path
        d="M359.9,213.06a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,359.9,213.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "359.9px 214.26px",
        }}
        id="el86pul2j54fb"
        className="animable"
      />
      <path
        d="M356.31,216.65a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,356.31,216.65Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "356.3px 217.85px",
        }}
        id="eldrrlusogn3h"
        className="animable"
      />
      <path
        d="M359.9,205.88a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,359.9,205.88Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "359.9px 207.08px",
        }}
        id="elsey6dywp2g"
        className="animable"
      />
      <path
        d="M356.31,209.47a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "356.201px 210.665px",
        }}
        id="elaak6u430ozr"
        className="animable"
      />
      <path
        d="M359.9,198.69a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,359.9,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "359.9px 199.89px",
        }}
        id="el6k9zibmo9lr"
        className="animable"
      />
      <path
        d="M356.31,202.29a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,356.31,202.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "356.3px 203.49px",
        }}
        id="el6ygi64i7djh"
        className="animable"
      />
      <path
        d="M359.9,191.51a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,359.9,191.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "359.9px 192.71px",
        }}
        id="elhakmpmhpbl"
        className="animable"
      />
      <path
        d="M356.31,195.1a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,356.31,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "356.3px 196.3px",
        }}
        id="elhenl1cczsyr"
        className="animable"
      />
      <path
        d="M359.9,184.33a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,359.9,184.33Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "359.9px 185.53px",
        }}
        id="el4aswi5s4m59"
        className="animable"
      />
      <path
        d="M356.31,187.92a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,356.31,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "356.3px 189.12px",
        }}
        id="elvbfk59v7cjd"
        className="animable"
      />
      <path
        d="M359.9,177.14a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,359.9,177.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "359.9px 178.34px",
        }}
        id="el7uv2v2jk4qs"
        className="animable"
      />
      <path
        d="M356.31,180.74a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,356.31,180.74Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "356.3px 181.94px",
        }}
        id="elulx5jp81a1b"
        className="animable"
      />
      <path
        d="M359.9,170a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,359.9,170Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "359.9px 171.2px",
        }}
        id="elxeqlhapzmld"
        className="animable"
      />
      <path
        d="M356.31,173.55a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "356.201px 174.745px",
        }}
        id="eltd2b4axelse"
        className="animable"
      />
      <path
        d="M359.9,162.78a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,359.9,162.78Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "359.9px 163.98px",
        }}
        id="eljbeq8mxflff"
        className="animable"
      />
      <path
        d="M356.31,166.37a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,356.31,166.37Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "356.3px 167.57px",
        }}
        id="elsmz3x4cjr48"
        className="animable"
      />
      <path
        d="M359.9,155.59a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,359.9,155.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "359.9px 156.79px",
        }}
        id="el3elovrrttml"
        className="animable"
      />
      <path
        d="M356.31,159.18a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,356.31,159.18Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "356.3px 160.38px",
        }}
        id="elh7v5enzs6l5"
        className="animable"
      />
      <path
        d="M359.9,148.41a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,359.9,148.41Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "359.9px 149.61px",
        }}
        id="elpdu3qxlihr9"
        className="animable"
      />
      <path
        d="M356.31,152a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "356.201px 153.195px",
        }}
        id="el27ff1xf027g"
        className="animable"
      />
      <path
        d="M359.9,141.22a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,359.9,141.22Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "359.9px 142.42px",
        }}
        id="el63bmawtsz7d"
        className="animable"
      />
      <path
        d="M363.49,140a1.14,1.14,0,0,0,.6-.18l-1.69-.53A1.18,1.18,0,0,0,363.49,140Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "363.245px 139.645px",
        }}
        id="el7ezqom5qnl8"
        className="animable"
      />
      <path
        d="M356.31,144.82A1.2,1.2,0,1,0,357.5,146,1.19,1.19,0,0,0,356.31,144.82Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "356.3px 146.02px",
        }}
        id="el9fg9uxm2wy9"
        className="animable"
      />
      <path
        d="M356.31,137.63a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "356.201px 138.825px",
        }}
        id="elqw5346q0zga"
        className="animable"
      />
      <path
        d="M366,235.4l1.89-.48a1.16,1.16,0,0,0-.78-.31A1.19,1.19,0,0,0,366,235.4Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "366.945px 235.005px",
        }}
        id="el7i48e93ffkc"
        className="animable"
      />
      <path
        d="M367.08,227.43a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,367.08,227.43Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "367.08px 228.63px",
        }}
        id="elwaqehk591d"
        className="animable"
      />
      <path
        d="M363.49,231a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,363.49,231Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "363.49px 232.2px",
        }}
        id="eltzbhoc4hezm"
        className="animable"
      />
      <path
        d="M367.08,220.25a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "367.189px 221.445px",
        }}
        id="eldagbgddx7sh"
        className="animable"
      />
      <path
        d="M363.49,223.84a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "363.599px 225.035px",
        }}
        id="elqyzdr5fa73f"
        className="animable"
      />
      <path
        d="M367.08,213.06a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,367.08,213.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "367.08px 214.26px",
        }}
        id="elypuvdchnv3"
        className="animable"
      />
      <path
        d="M363.49,216.65a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,363.49,216.65Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "363.49px 217.85px",
        }}
        id="elxgzm3ijf93"
        className="animable"
      />
      <path
        d="M367.08,205.88a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,367.08,205.88Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "367.08px 207.08px",
        }}
        id="el0zlwoarilfed"
        className="animable"
      />
      <path
        d="M363.49,209.47a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,363.49,209.47Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "363.49px 210.67px",
        }}
        id="ely81l4u5rlj"
        className="animable"
      />
      <path
        d="M367.08,198.69a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,367.08,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "367.08px 199.89px",
        }}
        id="el2yg0rpmgc1y"
        className="animable"
      />
      <path
        d="M363.49,202.29a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "363.6px 203.485px",
        }}
        id="eldnse60x8kro"
        className="animable"
      />
      <path
        d="M367.08,191.51a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,367.08,191.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "367.08px 192.71px",
        }}
        id="el4lnvtk385n7"
        className="animable"
      />
      <path
        d="M363.49,195.1a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,363.49,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "363.49px 196.3px",
        }}
        id="el0ot3pttu2gn"
        className="animable"
      />
      <path
        d="M367.08,184.33a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "367.19px 185.525px",
        }}
        id="elf4zxi24al4q"
        className="animable"
      />
      <path
        d="M363.49,187.92a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,363.49,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "363.49px 189.12px",
        }}
        id="elff5wau2kod"
        className="animable"
      />
      <path
        d="M367.08,177.14a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,367.08,177.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "367.08px 178.34px",
        }}
        id="el2enyp6sa8rd"
        className="animable"
      />
      <path
        d="M363.49,180.74a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "363.599px 181.935px",
        }}
        id="elt7lg352v5sf"
        className="animable"
      />
      <path
        d="M367.08,170a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,367.08,170Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "367.08px 171.2px",
        }}
        id="elcr4a7kr4ug8"
        className="animable"
      />
      <path
        d="M363.49,173.55a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,363.49,173.55Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "363.49px 174.75px",
        }}
        id="elfgjlsk5tydl"
        className="animable"
      />
      <path
        d="M367.08,162.78a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "367.189px 163.975px",
        }}
        id="elmgesb81swup"
        className="animable"
      />
      <path
        d="M363.49,166.37a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "363.599px 167.565px",
        }}
        id="elsoz8kzsna2"
        className="animable"
      />
      <path
        d="M367.08,155.59a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,367.08,155.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "367.08px 156.79px",
        }}
        id="elm7rx0kes13e"
        className="animable"
      />
      <path
        d="M363.49,159.18a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,363.49,159.18Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "363.49px 160.38px",
        }}
        id="elkywe0fs3es"
        className="animable"
      />
      <path
        d="M367.08,148.41a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "367.189px 149.605px",
        }}
        id="el7q027m2d6g"
        className="animable"
      />
      <path
        d="M363.49,152a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,363.49,152Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "363.49px 153.2px",
        }}
        id="elt5zvp0h3db"
        className="animable"
      />
      <path
        d="M367.08,141.22a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,367.08,141.22Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "367.08px 142.42px",
        }}
        id="elencey5lug6"
        className="animable"
      />
      <path
        d="M363.49,144.82a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "363.599px 146.015px",
        }}
        id="elvwdo9utqca"
        className="animable"
      />
      <path
        d="M374.27,227.43a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "374.161px 228.625px",
        }}
        id="elfknaai2kouj"
        className="animable"
      />
      <path
        d="M370.68,231a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,370.68,231Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "370.67px 232.2px",
        }}
        id="elxfz5yonbmbg"
        className="animable"
      />
      <path
        d="M374.27,220.25a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,374.27,220.25Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "374.26px 221.45px",
        }}
        id="el4ch5ul0l11u"
        className="animable"
      />
      <path
        d="M370.68,223.84a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,370.68,223.84Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "370.67px 225.04px",
        }}
        id="elnanljy9wo5"
        className="animable"
      />
      <path
        d="M374.27,213.06a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "374.161px 214.255px",
        }}
        id="el70z6w540jzp"
        className="animable"
      />
      <path
        d="M370.68,216.65a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,370.68,216.65Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "370.67px 217.85px",
        }}
        id="elkbcjfovpvo"
        className="animable"
      />
      <path
        d="M374.27,205.88a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,374.27,205.88Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "374.26px 207.08px",
        }}
        id="eltwp4bx9ynj"
        className="animable"
      />
      <path
        d="M370.68,209.47a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,370.68,209.47Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "370.67px 210.67px",
        }}
        id="elpz8lnulekc"
        className="animable"
      />
      <path
        d="M374.27,198.69a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,374.27,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "374.26px 199.89px",
        }}
        id="eldq5gq5afbk6"
        className="animable"
      />
      <path
        d="M370.68,202.29a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,370.68,202.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "370.67px 203.49px",
        }}
        id="elq4o4a2j8au"
        className="animable"
      />
      <path
        d="M374.27,191.51a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "374.161px 192.705px",
        }}
        id="el2bzx4zw7ljx"
        className="animable"
      />
      <path
        d="M370.68,195.1a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,370.68,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "370.67px 196.3px",
        }}
        id="elc3h8cmxvs2l"
        className="animable"
      />
      <path
        d="M374.27,184.33a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,374.27,184.33Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "374.26px 185.53px",
        }}
        id="eluutd8fqjcb"
        className="animable"
      />
      <path
        d="M370.68,187.92a1.2,1.2,0,1,0,1.19,1.2A1.19,1.19,0,0,0,370.68,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "370.67px 189.12px",
        }}
        id="els3mxea63lb"
        className="animable"
      />
      <path
        d="M374.27,177.14a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,374.27,177.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "374.26px 178.34px",
        }}
        id="el64ujnw0uxu3"
        className="animable"
      />
      <path
        d="M370.68,180.74a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,370.68,180.74Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "370.67px 181.94px",
        }}
        id="el85nzmlr8mlo"
        className="animable"
      />
      <path
        d="M374.27,170a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,374.27,170Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "374.26px 171.2px",
        }}
        id="elu974o0e7ltg"
        className="animable"
      />
      <path
        d="M370.68,173.55a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,370.68,173.55Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "370.67px 174.75px",
        }}
        id="elsd2djsho2e"
        className="animable"
      />
      <path
        d="M374.27,162.78a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,374.27,162.78Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "374.26px 163.98px",
        }}
        id="elgt8jrwav24r"
        className="animable"
      />
      <path
        d="M370.68,166.37a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,370.68,166.37Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "370.67px 167.57px",
        }}
        id="eldb3eojjwoc"
        className="animable"
      />
      <path
        d="M374.27,155.59a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "374.161px 156.785px",
        }}
        id="eleiseu4wpk7k"
        className="animable"
      />
      <path
        d="M370.68,159.18a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,370.68,159.18Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "370.67px 160.38px",
        }}
        id="el25kw8skiv6t"
        className="animable"
      />
      <path
        d="M374.27,148.41a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,374.27,148.41Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "374.26px 149.61px",
        }}
        id="el0byh5tv737zj"
        className="animable"
      />
      <path
        d="M370.68,152a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,370.68,152Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "370.67px 153.2px",
        }}
        id="ely1tjte6a7sj"
        className="animable"
      />
      <path
        d="M374.27,143.62a1.19,1.19,0,0,0,.77-.3l-1.91-.61A1.18,1.18,0,0,0,374.27,143.62Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "374.085px 143.165px",
        }}
        id="elcs9obk9kmau"
        className="animable"
      />
      <path
        d="M370.68,144.82a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,370.68,144.82Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "370.67px 146.02px",
        }}
        id="el1rw0beroenp"
        className="animable"
      />
      <path
        d="M381.45,227.43a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,381.45,227.43Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "381.45px 228.63px",
        }}
        id="elglj4gbzmm8"
        className="animable"
      />
      <path
        d="M377.86,231a1.2,1.2,0,0,0-1.2,1.2,1.27,1.27,0,0,0,.09.43l2.27-.58A1.17,1.17,0,0,0,377.86,231Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "377.84px 231.815px",
        }}
        id="elel26x3ayed"
        className="animable"
      />
      <path
        d="M381.45,220.25a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "381.559px 221.445px",
        }}
        id="el1mz0tm726xjh"
        className="animable"
      />
      <path
        d="M377.86,223.84a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,377.86,223.84Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "377.86px 225.04px",
        }}
        id="elq9706oculvc"
        className="animable"
      />
      <path
        d="M381.45,213.06a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,381.45,213.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "381.45px 214.26px",
        }}
        id="elfyelrwxs80l"
        className="animable"
      />
      <path
        d="M377.86,216.65a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,377.86,216.65Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "377.86px 217.85px",
        }}
        id="eldumrliopvoq"
        className="animable"
      />
      <path
        d="M381.45,205.88a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,381.45,205.88Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "381.45px 207.08px",
        }}
        id="elxyh73v1mcxm"
        className="animable"
      />
      <path
        d="M377.86,209.47a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,377.86,209.47Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "377.86px 210.67px",
        }}
        id="el6ghpl7oapnw"
        className="animable"
      />
      <path
        d="M381.45,198.69a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,381.45,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "381.45px 199.89px",
        }}
        id="elzvagcda8k7"
        className="animable"
      />
      <path
        d="M377.86,202.29a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,377.86,202.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "377.86px 203.49px",
        }}
        id="elf7wh16rskn5"
        className="animable"
      />
      <path
        d="M381.45,191.51a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,381.45,191.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "381.45px 192.71px",
        }}
        id="eljzaoft5ld2"
        className="animable"
      />
      <path
        d="M377.86,195.1a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,377.86,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "377.86px 196.3px",
        }}
        id="el9n4s56uuqo6"
        className="animable"
      />
      <path
        d="M381.45,184.33a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "381.56px 185.525px",
        }}
        id="elzy9mwqixttl"
        className="animable"
      />
      <path
        d="M377.86,187.92a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,377.86,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "377.86px 189.12px",
        }}
        id="elmreys8e1y6l"
        className="animable"
      />
      <path
        d="M381.45,177.14a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,381.45,177.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "381.45px 178.34px",
        }}
        id="el48zovr8zpsg"
        className="animable"
      />
      <path
        d="M377.86,180.74a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,377.86,180.74Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "377.86px 181.94px",
        }}
        id="ely663mtij9jk"
        className="animable"
      />
      <path
        d="M381.45,170a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,381.45,170Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "381.45px 171.2px",
        }}
        id="el4d2nzn46bju"
        className="animable"
      />
      <path
        d="M377.86,173.55a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,377.86,173.55Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "377.86px 174.75px",
        }}
        id="elq7bfjj4qd5"
        className="animable"
      />
      <path
        d="M381.45,162.78a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "381.559px 163.975px",
        }}
        id="ell55kn3cunwk"
        className="animable"
      />
      <path
        d="M377.86,166.37a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,377.86,166.37Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "377.86px 167.57px",
        }}
        id="elhhpuxu7ifuo"
        className="animable"
      />
      <path
        d="M381.45,155.59a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,381.45,155.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "381.45px 156.79px",
        }}
        id="elu492btu7xw"
        className="animable"
      />
      <path
        d="M377.86,159.18a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,377.86,159.18Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "377.86px 160.38px",
        }}
        id="elillzmi5sg9n"
        className="animable"
      />
      <path
        d="M381.45,148.41a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "381.559px 149.605px",
        }}
        id="elyjpjp309bw"
        className="animable"
      />
      <path
        d="M385,147.21a1.16,1.16,0,0,0,.9-.43l-2.07-.66A1.18,1.18,0,0,0,385,147.21Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "384.865px 146.665px",
        }}
        id="eltcwnmxn5xb"
        className="animable"
      />
      <path
        d="M377.86,152a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,377.86,152Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "377.86px 153.2px",
        }}
        id="elzg8avendukr"
        className="animable"
      />
      <path
        d="M377.86,144.82a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,377.86,144.82Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "377.86px 146.02px",
        }}
        id="eldggdmqqvexm"
        className="animable"
      />
      <path
        d="M388.64,227.43a1.2,1.2,0,0,0-.46,2.3l1.36-.35a1.18,1.18,0,0,0-.9-1.95Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "388.643px 228.58px",
        }}
        id="eld17t2enn96"
        className="animable"
      />
      <path
        d="M388.64,220.25a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,388.64,220.25Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "388.63px 221.45px",
        }}
        id="el8ln5nv66k4l"
        className="animable"
      />
      <path
        d="M385,223.84a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "385.109px 225.035px",
        }}
        id="elul1dka4orsi"
        className="animable"
      />
      <path
        d="M388.64,213.06a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,388.64,213.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "388.63px 214.26px",
        }}
        id="el9ubibchde5u"
        className="animable"
      />
      <path
        d="M385,216.65a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,385,216.65Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "385px 217.85px",
        }}
        id="el5lw9h4gfn4w"
        className="animable"
      />
      <path
        d="M388.64,205.88a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,388.64,205.88Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "388.63px 207.08px",
        }}
        id="ell7i3tpg8x6p"
        className="animable"
      />
      <path
        d="M385,209.47a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,385,209.47Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "385px 210.67px",
        }}
        id="eliuv3yhq3k0b"
        className="animable"
      />
      <path
        d="M388.64,198.69a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,388.64,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "388.63px 199.89px",
        }}
        id="elfkes2elilh"
        className="animable"
      />
      <path
        d="M385,202.29a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "385.11px 203.485px",
        }}
        id="el92yfnidxwm7"
        className="animable"
      />
      <path
        d="M388.64,191.51a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,388.64,191.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "388.63px 192.71px",
        }}
        id="elu2gy2p9r5be"
        className="animable"
      />
      <path
        d="M385,195.1a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,385,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "385px 196.3px",
        }}
        id="ellyqzva6mp0p"
        className="animable"
      />
      <path
        d="M388.64,184.33a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,388.64,184.33Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "388.63px 185.53px",
        }}
        id="el4wl5luel2qm"
        className="animable"
      />
      <path
        d="M385,187.92a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,385,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "385px 189.12px",
        }}
        id="elmc65i5tj5y"
        className="animable"
      />
      <path
        d="M388.64,177.14a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,388.64,177.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "388.63px 178.34px",
        }}
        id="ell6dq876rcq"
        className="animable"
      />
      <path
        d="M385,180.74a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "385.109px 181.935px",
        }}
        id="elwcz5v63qu6"
        className="animable"
      />
      <path
        d="M388.64,170a1.2,1.2,0,1,0,1.19,1.2A1.19,1.19,0,0,0,388.64,170Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "388.63px 171.2px",
        }}
        id="eln6sk7gekip"
        className="animable"
      />
      <path
        d="M385,173.55a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,385,173.55Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "385px 174.75px",
        }}
        id="elyex0wie39im"
        className="animable"
      />
      <path
        d="M388.64,162.78a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,388.64,162.78Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "388.63px 163.98px",
        }}
        id="elw7otw5eob9"
        className="animable"
      />
      <path
        d="M385,166.37a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "385.109px 167.565px",
        }}
        id="elwko64albotj"
        className="animable"
      />
      <path
        d="M388.64,155.59a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,388.64,155.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "388.63px 156.79px",
        }}
        id="el7uu5vgjtgut"
        className="animable"
      />
      <path
        d="M385,159.18a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,385,159.18Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "385px 160.38px",
        }}
        id="elpxgrr6cebc"
        className="animable"
      />
      <path
        d="M388.64,148.41a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,388.64,148.41Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "388.63px 149.61px",
        }}
        id="ell41wescchtd"
        className="animable"
      />
      <path
        d="M385,152a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,385,152Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "385px 153.2px",
        }}
        id="elg5jjj2y2b7i"
        className="animable"
      />
      <path
        d="M394.8,228l1.63-.42a1.16,1.16,0,0,0-.61-.19A1.18,1.18,0,0,0,394.8,228Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "395.615px 227.695px",
        }}
        id="elx5li4kkqe5h"
        className="animable"
      />
      <path
        d="M395.82,220.25a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,395.82,220.25Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "395.81px 221.45px",
        }}
        id="el87mn21qg6ny"
        className="animable"
      />
      <path
        d="M392.23,223.84a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,392.23,223.84Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "392.22px 225.04px",
        }}
        id="eld0l3utnlcdc"
        className="animable"
      />
      <path
        d="M395.82,213.06a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "395.711px 214.255px",
        }}
        id="el46x63ojg414"
        className="animable"
      />
      <path
        d="M392.23,216.65a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,392.23,216.65Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "392.22px 217.85px",
        }}
        id="el9s9cpmobvpi"
        className="animable"
      />
      <path
        d="M395.82,205.88a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,395.82,205.88Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "395.81px 207.08px",
        }}
        id="elmvlm2qmq96"
        className="animable"
      />
      <path
        d="M392.23,209.47a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "392.121px 210.665px",
        }}
        id="el3cyy7q956gy"
        className="animable"
      />
      <path
        d="M395.82,198.69a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,395.82,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "395.81px 199.89px",
        }}
        id="elwcnshdurgu"
        className="animable"
      />
      <path
        d="M392.23,202.29a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,392.23,202.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "392.22px 203.49px",
        }}
        id="elrxb2j2qfh1g"
        className="animable"
      />
      <path
        d="M395.82,191.51a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "395.711px 192.705px",
        }}
        id="elqwyzhy8dqzp"
        className="animable"
      />
      <path
        d="M392.23,195.1a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,392.23,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "392.22px 196.3px",
        }}
        id="elk8r68ezpdx"
        className="animable"
      />
      <path
        d="M395.82,184.33a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,395.82,184.33Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "395.81px 185.53px",
        }}
        id="elc576jp72axk"
        className="animable"
      />
      <path
        d="M392.23,187.92a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,392.23,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "392.22px 189.12px",
        }}
        id="elfxs4n7apjx9"
        className="animable"
      />
      <path
        d="M395.82,177.14a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,395.82,177.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "395.81px 178.34px",
        }}
        id="elddtjdufhj15"
        className="animable"
      />
      <path
        d="M392.23,180.74a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,392.23,180.74Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "392.22px 181.94px",
        }}
        id="elaq7zfer9mn5"
        className="animable"
      />
      <path
        d="M395.82,170a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,395.82,170Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "395.81px 171.2px",
        }}
        id="elrjnahbytdjp"
        className="animable"
      />
      <path
        d="M392.23,173.55a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "392.121px 174.745px",
        }}
        id="elvm93pbw6tuj"
        className="animable"
      />
      <path
        d="M395.82,162.78A1.2,1.2,0,1,0,397,164,1.19,1.19,0,0,0,395.82,162.78Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "395.8px 163.98px",
        }}
        id="el0o635r6wdpc"
        className="animable"
      />
      <path
        d="M392.23,166.37a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,392.23,166.37Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "392.22px 167.57px",
        }}
        id="el87ebkca2ec5"
        className="animable"
      />
      <path
        d="M395.82,155.59a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "395.711px 156.785px",
        }}
        id="elmu7mz1nrdlj"
        className="animable"
      />
      <path
        d="M392.23,159.18a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,392.23,159.18Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "392.22px 160.38px",
        }}
        id="ellzysn5q92mb"
        className="animable"
      />
      <path
        d="M395.82,150.8a1.17,1.17,0,0,0,1-.57l-2.17-.69a.14.14,0,0,0,0,.06A1.2,1.2,0,0,0,395.82,150.8Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "395.733px 150.17px",
        }}
        id="elmbjv3lil9t"
        className="animable"
      />
      <path
        d="M392.23,152a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "392.121px 153.195px",
        }}
        id="elg4kzqqhy07d"
        className="animable"
      />
      <path
        d="M403,220.25a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "403.109px 221.445px",
        }}
        id="eltgaupy3fqpl"
        className="animable"
      />
      <path
        d="M399.41,223.84a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,399.41,223.84Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "399.41px 225.04px",
        }}
        id="el5hs3lscz7cc"
        className="animable"
      />
      <path
        d="M403,213.06a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,403,213.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "403px 214.26px",
        }}
        id="eltm3rzg7501q"
        className="animable"
      />
      <path
        d="M399.41,216.65a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,399.41,216.65Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "399.41px 217.85px",
        }}
        id="elxaidx0a7jv"
        className="animable"
      />
      <path
        d="M403,205.88a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,403,205.88Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "403px 207.08px",
        }}
        id="el1h2ft7qghtr"
        className="animable"
      />
      <path
        d="M399.41,209.47a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,399.41,209.47Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "399.41px 210.67px",
        }}
        id="el308x7w7z6h4"
        className="animable"
      />
      <path
        d="M403,198.69a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,403,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "403px 199.89px",
        }}
        id="elr9v0sext2wd"
        className="animable"
      />
      <path
        d="M399.41,202.29a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,399.41,202.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "399.41px 203.49px",
        }}
        id="elvi2h53isc8"
        className="animable"
      />
      <path
        d="M403,191.51a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,403,191.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "403px 192.71px",
        }}
        id="elgqj3sy88dzt"
        className="animable"
      />
      <path
        d="M399.41,195.1a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,399.41,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "399.41px 196.3px",
        }}
        id="elxt8iclcu8o7"
        className="animable"
      />
      <path
        d="M403,184.33a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "403.11px 185.525px",
        }}
        id="elp57eluifaw"
        className="animable"
      />
      <path
        d="M399.41,187.92a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,399.41,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "399.41px 189.12px",
        }}
        id="eltj3f2k6w6zl"
        className="animable"
      />
      <path
        d="M403,177.14a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,403,177.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "403px 178.34px",
        }}
        id="el6jpi7ahco87"
        className="animable"
      />
      <path
        d="M399.41,180.74a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,399.41,180.74Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "399.41px 181.94px",
        }}
        id="elx66tlhfd2ke"
        className="animable"
      />
      <path
        d="M403,170a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,403,170Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "403px 171.2px",
        }}
        id="elny67y6a0e2o"
        className="animable"
      />
      <path
        d="M399.41,173.55a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,399.41,173.55Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "399.41px 174.75px",
        }}
        id="el18dzqp29bawi"
        className="animable"
      />
      <path
        d="M403,162.78a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "403.109px 163.975px",
        }}
        id="el6cw93gc8egu"
        className="animable"
      />
      <path
        d="M399.41,166.37a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,399.41,166.37Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "399.41px 167.57px",
        }}
        id="elpmw94zns4ge"
        className="animable"
      />
      <path
        d="M403,155.59a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,403,155.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "403px 156.79px",
        }}
        id="elxner8qz1cjk"
        className="animable"
      />
      <path
        d="M406.59,154.39a1.2,1.2,0,0,0,1.1-.71l-2.25-.71a1.37,1.37,0,0,0,0,.23A1.19,1.19,0,0,0,406.59,154.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "406.563px 153.68px",
        }}
        id="eltm9gp1qrzp"
        className="animable"
      />
      <path
        d="M399.41,159.18a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,399.41,159.18Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "399.41px 160.38px",
        }}
        id="elvc5jfjsw8zp"
        className="animable"
      />
      <path
        d="M399.41,152a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,399.41,152Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "399.41px 153.2px",
        }}
        id="el5lbuaes73mc"
        className="animable"
      />
      <path
        d="M410.19,220.25a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,410.19,220.25Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "410.18px 221.45px",
        }}
        id="elv6oo012he6"
        className="animable"
      />
      <path
        d="M406.59,223.84A1.19,1.19,0,0,0,405.4,225a1,1,0,0,0,.06.29l2.27-.58A1.17,1.17,0,0,0,406.59,223.84Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "406.565px 224.565px",
        }}
        id="elzp3yhk8t9er"
        className="animable"
      />
      <path
        d="M410.19,213.06a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "410.081px 214.255px",
        }}
        id="el7t1gf8isi6"
        className="animable"
      />
      <path
        d="M406.59,216.65a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,406.59,216.65Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "406.59px 217.85px",
        }}
        id="elunaoc2jtzmr"
        className="animable"
      />
      <path
        d="M410.19,205.88a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,410.19,205.88Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "410.18px 207.08px",
        }}
        id="el21u03lsqwmm"
        className="animable"
      />
      <path
        d="M406.59,209.47a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,406.59,209.47Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "406.59px 210.67px",
        }}
        id="elmbq9ai02f2"
        className="animable"
      />
      <path
        d="M410.19,198.69a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,410.19,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "410.18px 199.89px",
        }}
        id="eldvfuu3l0vcv"
        className="animable"
      />
      <path
        d="M406.59,202.29a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,406.59,202.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "406.59px 203.49px",
        }}
        id="eli1wb2jh4iw9"
        className="animable"
      />
      <path
        d="M410.19,191.51a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "410.081px 192.705px",
        }}
        id="elbapgu2ogdgv"
        className="animable"
      />
      <path
        d="M406.59,195.1a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,406.59,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "406.59px 196.3px",
        }}
        id="ellxnhx0lke8"
        className="animable"
      />
      <path
        d="M410.19,184.33a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,410.19,184.33Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "410.18px 185.53px",
        }}
        id="elrw006pn695"
        className="animable"
      />
      <path
        d="M406.59,187.92a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,406.59,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "406.59px 189.12px",
        }}
        id="el5vb1ypn94ox"
        className="animable"
      />
      <path
        d="M410.19,177.14a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,410.19,177.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "410.18px 178.34px",
        }}
        id="elaw7vfzqmbxh"
        className="animable"
      />
      <path
        d="M406.59,180.74a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,406.59,180.74Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "406.59px 181.94px",
        }}
        id="elqjijvffmg2b"
        className="animable"
      />
      <path
        d="M410.19,170a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,410.19,170Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "410.18px 171.2px",
        }}
        id="elmjwv9d2t7q"
        className="animable"
      />
      <path
        d="M406.59,173.55a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,406.59,173.55Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "406.59px 174.75px",
        }}
        id="elmcd9o4hs59r"
        className="animable"
      />
      <path
        d="M410.19,162.78a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,410.19,162.78Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "410.18px 163.98px",
        }}
        id="elmxtnuq4du3"
        className="animable"
      />
      <path
        d="M406.59,166.37a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,406.59,166.37Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "406.59px 167.57px",
        }}
        id="elohs6ucengjh"
        className="animable"
      />
      <path
        d="M410.19,155.59a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "410.081px 156.785px",
        }}
        id="elsq9h5v5p22"
        className="animable"
      />
      <path
        d="M406.59,159.18a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,406.59,159.18Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "406.59px 160.38px",
        }}
        id="el9kvyk8jicf4"
        className="animable"
      />
      <path
        d="M417.37,220.25a1.19,1.19,0,0,0-1.2,1.19,1.17,1.17,0,0,0,.57,1l1.66-.42a1.12,1.12,0,0,0,.17-.57A1.19,1.19,0,0,0,417.37,220.25Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "417.37px 221.345px",
        }}
        id="el8nf5ye8xdjr"
        className="animable"
      />
      <path
        d="M417.37,213.06a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,417.37,213.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "417.37px 214.26px",
        }}
        id="elq2j6fhzcuxa"
        className="animable"
      />
      <path
        d="M413.78,216.65a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,413.78,216.65Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "413.77px 217.85px",
        }}
        id="el8enpcqiq81f"
        className="animable"
      />
      <path
        d="M417.37,205.88a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,417.37,205.88Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "417.37px 207.08px",
        }}
        id="els7shgvj8fi"
        className="animable"
      />
      <path
        d="M413.78,209.47a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "413.671px 210.665px",
        }}
        id="elowqu90blnf"
        className="animable"
      />
      <path
        d="M417.37,198.69a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,417.37,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "417.37px 199.89px",
        }}
        id="ellct6s1a85u"
        className="animable"
      />
      <path
        d="M413.78,202.29a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,413.78,202.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "413.77px 203.49px",
        }}
        id="elvbhr2z5l3e9"
        className="animable"
      />
      <path
        d="M417.37,191.51a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,417.37,191.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "417.37px 192.71px",
        }}
        id="elwlgf0di9r3d"
        className="animable"
      />
      <path
        d="M413.78,195.1a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,413.78,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "413.77px 196.3px",
        }}
        id="el4mj08laev3v"
        className="animable"
      />
      <path
        d="M417.37,184.33a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,417.37,184.33Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "417.37px 185.53px",
        }}
        id="elz5r8n1r5rhc"
        className="animable"
      />
      <path
        d="M413.78,187.92a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,413.78,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "413.77px 189.12px",
        }}
        id="ela8j9eudz77f"
        className="animable"
      />
      <path
        d="M417.37,177.14a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,417.37,177.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "417.37px 178.34px",
        }}
        id="elephuy7b9tft"
        className="animable"
      />
      <path
        d="M413.78,180.74a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,413.78,180.74Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "413.77px 181.94px",
        }}
        id="elc8ewghvv51m"
        className="animable"
      />
      <path
        d="M417.37,170a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,417.37,170Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "417.37px 171.2px",
        }}
        id="el3xc7ffi4mse"
        className="animable"
      />
      <path
        d="M413.78,173.55a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "413.671px 174.745px",
        }}
        id="elo5q5vuijibl"
        className="animable"
      />
      <path
        d="M417.37,162.78a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,417.37,162.78Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "417.37px 163.98px",
        }}
        id="ele06yk4l3xl"
        className="animable"
      />
      <path
        d="M413.78,166.37a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,413.78,166.37Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "413.77px 167.57px",
        }}
        id="el3ja1t7dz29d"
        className="animable"
      />
      <path
        d="M417.37,158a1.17,1.17,0,0,0,1.13-.87l-2.25-.71a1.23,1.23,0,0,0-.08.39A1.19,1.19,0,0,0,417.37,158Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "417.335px 157.21px",
        }}
        id="elq7etd1crr9"
        className="animable"
      />
      <path
        d="M413.78,159.18a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,413.78,159.18Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "413.77px 160.38px",
        }}
        id="elrsl4gsjet7p"
        className="animable"
      />
      <path
        d="M423.66,220.67l1.32-.34a1.26,1.26,0,0,0-.43-.08A1.15,1.15,0,0,0,423.66,220.67Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "424.32px 220.46px",
        }}
        id="elm8s6pbv4apq"
        className="animable"
      />
      <path
        d="M424.55,213.06a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,424.55,213.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "424.55px 214.26px",
        }}
        id="el3e9pdlmgr7d"
        className="animable"
      />
      <path
        d="M421,216.65a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,421,216.65Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "421px 217.85px",
        }}
        id="elg1i4jkzl46"
        className="animable"
      />
      <path
        d="M424.55,205.88a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,424.55,205.88Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "424.55px 207.08px",
        }}
        id="elmicwa4xpgu9"
        className="animable"
      />
      <path
        d="M421,209.47a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,421,209.47Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "421px 210.67px",
        }}
        id="el2k629wvd3ev"
        className="animable"
      />
      <path
        d="M424.55,198.69a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,424.55,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "424.55px 199.89px",
        }}
        id="elvubzw2727t"
        className="animable"
      />
      <path
        d="M421,202.29a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "421.11px 203.485px",
        }}
        id="elruicn0pyqfq"
        className="animable"
      />
      <path
        d="M424.55,191.51a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,424.55,191.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "424.55px 192.71px",
        }}
        id="el3h5tst1ltcp"
        className="animable"
      />
      <path
        d="M421,195.1a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,421,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "421px 196.3px",
        }}
        id="eltu8buc6c5jb"
        className="animable"
      />
      <path
        d="M424.55,184.33a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "424.66px 185.525px",
        }}
        id="elk4o1iqh1tni"
        className="animable"
      />
      <path
        d="M421,187.92a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,421,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "421px 189.12px",
        }}
        id="elnqsi9i5y14b"
        className="animable"
      />
      <path
        d="M424.55,177.14a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,424.55,177.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "424.55px 178.34px",
        }}
        id="elmrm1yeqe8wo"
        className="animable"
      />
      <path
        d="M421,180.74a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "421.109px 181.935px",
        }}
        id="elfh1xji5hx56"
        className="animable"
      />
      <path
        d="M424.55,170a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,424.55,170Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "424.55px 171.2px",
        }}
        id="el1wku71ekwjo"
        className="animable"
      />
      <path
        d="M421,173.55a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,421,173.55Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "421px 174.75px",
        }}
        id="ell5yy6oslz1c"
        className="animable"
      />
      <path
        d="M424.55,162.78a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "424.659px 163.975px",
        }}
        id="elrsiq5uw607c"
        className="animable"
      />
      <path
        d="M428.15,161.58a1.18,1.18,0,0,0,1.16-1l-2.21-.7a1.09,1.09,0,0,0-.15.54A1.2,1.2,0,0,0,428.15,161.58Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "428.13px 160.73px",
        }}
        id="elq51fbsq2qqo"
        className="animable"
      />
      <path
        d="M421,166.37a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "421.109px 167.565px",
        }}
        id="eletk1j4w2khn"
        className="animable"
      />
      <path
        d="M421,159.18a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,421,159.18Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "421px 160.38px",
        }}
        id="eles93ycxxcy"
        className="animable"
      />
      <path
        d="M431.74,213.06a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "431.631px 214.255px",
        }}
        id="elr9oaw65tl59"
        className="animable"
      />
      <path
        d="M428.15,216.65a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,428.15,216.65Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "428.14px 217.85px",
        }}
        id="elobp58inn6tm"
        className="animable"
      />
      <path
        d="M431.74,205.88a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,431.74,205.88Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "431.73px 207.08px",
        }}
        id="el6c943e0832"
        className="animable"
      />
      <path
        d="M428.15,209.47a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "428.041px 210.665px",
        }}
        id="el3bj98tnmls4"
        className="animable"
      />
      <path
        d="M431.74,198.69a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,431.74,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "431.73px 199.89px",
        }}
        id="elnp9t60330h8"
        className="animable"
      />
      <path
        d="M428.15,202.29a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,428.15,202.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "428.14px 203.49px",
        }}
        id="el3u2duyjrg53"
        className="animable"
      />
      <path
        d="M431.74,191.51a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "431.631px 192.705px",
        }}
        id="elotelrqcioqq"
        className="animable"
      />
      <path
        d="M428.15,195.1a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,428.15,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "428.14px 196.3px",
        }}
        id="elehi8uzr5wy"
        className="animable"
      />
      <path
        d="M431.74,184.33a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,431.74,184.33Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "431.73px 185.53px",
        }}
        id="elapv2jqm1qfd"
        className="animable"
      />
      <path
        d="M428.15,187.92a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,428.15,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "428.14px 189.12px",
        }}
        id="elib2wj1jubpm"
        className="animable"
      />
      <path
        d="M431.74,177.14a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,431.74,177.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "431.73px 178.34px",
        }}
        id="elnmbjf4y8u0b"
        className="animable"
      />
      <path
        d="M428.15,180.74a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,428.15,180.74Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "428.14px 181.94px",
        }}
        id="elu19ups86l8"
        className="animable"
      />
      <path
        d="M431.74,170a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,431.74,170Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "431.73px 171.2px",
        }}
        id="eluso3aza7378"
        className="animable"
      />
      <path
        d="M428.15,173.55a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "428.041px 174.745px",
        }}
        id="el1lfp5qcyoho"
        className="animable"
      />
      <path
        d="M431.74,162.78a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,431.74,162.78Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "431.73px 163.98px",
        }}
        id="elzcnyiw2n60i"
        className="animable"
      />
      <path
        d="M428.15,166.37a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,428.15,166.37Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "428.14px 167.57px",
        }}
        id="elhr7za2kowo"
        className="animable"
      />
      <path
        d="M438.92,213.06a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,438.92,213.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "438.92px 214.26px",
        }}
        id="elftwsui1sun4"
        className="animable"
      />
      <path
        d="M435.33,216.65a1.2,1.2,0,0,0-1.2,1.2c0,.05,0,.09,0,.13l2.28-.58A1.22,1.22,0,0,0,435.33,216.65Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "435.27px 217.315px",
        }}
        id="el74vg4k1787o"
        className="animable"
      />
      <path
        d="M438.92,205.88a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,438.92,205.88Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "438.92px 207.08px",
        }}
        id="el05f96i14a5za"
        className="animable"
      />
      <path
        d="M435.33,209.47a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,435.33,209.47Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "435.33px 210.67px",
        }}
        id="el7bsp07di1nu"
        className="animable"
      />
      <path
        d="M438.92,198.69a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,438.92,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "438.92px 199.89px",
        }}
        id="el3yc3w0cedma"
        className="animable"
      />
      <path
        d="M435.33,202.29a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,435.33,202.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "435.33px 203.49px",
        }}
        id="ely4pl0fpby6c"
        className="animable"
      />
      <path
        d="M438.92,191.51a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,438.92,191.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "438.92px 192.71px",
        }}
        id="elljtibx4hfa"
        className="animable"
      />
      <path
        d="M435.33,195.1a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,435.33,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "435.33px 196.3px",
        }}
        id="elxrgv7fj3fq"
        className="animable"
      />
      <path
        d="M438.92,184.33a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "439.03px 185.525px",
        }}
        id="el7nj180gwqll"
        className="animable"
      />
      <path
        d="M435.33,187.92a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,435.33,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "435.33px 189.12px",
        }}
        id="el11zq33icv7uo"
        className="animable"
      />
      <path
        d="M438.92,177.14a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,438.92,177.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "438.92px 178.34px",
        }}
        id="elz1gdnt6r7tm"
        className="animable"
      />
      <path
        d="M435.33,180.74a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,435.33,180.74Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "435.33px 181.94px",
        }}
        id="elo35s7xqfjvf"
        className="animable"
      />
      <path
        d="M438.92,170a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,438.92,170Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "438.92px 171.2px",
        }}
        id="el18sb4ws7w0x"
        className="animable"
      />
      <path
        d="M435.33,173.55a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,435.33,173.55Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "435.33px 174.75px",
        }}
        id="eldg509yv8l2j"
        className="animable"
      />
      <path
        d="M438.92,165.17a1.2,1.2,0,0,0,1.2-1.2h0l-2.15-.68a1.14,1.14,0,0,0-.24.68A1.19,1.19,0,0,0,438.92,165.17Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "438.925px 164.23px",
        }}
        id="elwr7ettlv4vc"
        className="animable"
      />
      <path
        d="M435.33,166.37a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,435.33,166.37Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "435.33px 167.57px",
        }}
        id="elzn2250c9k1t"
        className="animable"
      />
      <path
        d="M446.11,213.06a1.19,1.19,0,0,0-.8,2.08l1.91-.49a1,1,0,0,0,.08-.39A1.19,1.19,0,0,0,446.11,213.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "446.105px 214.1px",
        }}
        id="elzjpjrqaszie"
        className="animable"
      />
      <path
        d="M446.11,205.88a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,446.11,205.88Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "446.1px 207.08px",
        }}
        id="eleyrzmp9hrcv"
        className="animable"
      />
      <path
        d="M442.51,209.47a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,442.51,209.47Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "442.51px 210.67px",
        }}
        id="elcli5v1jqdz"
        className="animable"
      />
      <path
        d="M446.11,198.69a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,446.11,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "446.1px 199.89px",
        }}
        id="el4cu638cbz7d"
        className="animable"
      />
      <path
        d="M442.51,202.29a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "442.62px 203.485px",
        }}
        id="elyrfk6wm31g"
        className="animable"
      />
      <path
        d="M446.11,191.51a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,446.11,191.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "446.1px 192.71px",
        }}
        id="elcqfog4zkb0u"
        className="animable"
      />
      <path
        d="M442.51,195.1a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,442.51,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "442.51px 196.3px",
        }}
        id="el5hba4l27crr"
        className="animable"
      />
      <path
        d="M446.11,184.33a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,446.11,184.33Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "446.1px 185.53px",
        }}
        id="el692wo3lv1bq"
        className="animable"
      />
      <path
        d="M442.51,187.92a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,442.51,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "442.51px 189.12px",
        }}
        id="elbub60urd2zr"
        className="animable"
      />
      <path
        d="M446.11,177.14a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,446.11,177.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "446.1px 178.34px",
        }}
        id="elzmtog0l64vs"
        className="animable"
      />
      <path
        d="M442.51,180.74a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "442.619px 181.935px",
        }}
        id="elbcxncgytjyq"
        className="animable"
      />
      <path
        d="M446.11,170a1.2,1.2,0,1,0,1.19,1.2A1.19,1.19,0,0,0,446.11,170Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "446.1px 171.2px",
        }}
        id="elonxvbwc7v7g"
        className="animable"
      />
      <path
        d="M449.7,168.76a1.19,1.19,0,0,0,1.19-1.2,1.13,1.13,0,0,0,0-.18l-2-.64a1.16,1.16,0,0,0-.34.82A1.2,1.2,0,0,0,449.7,168.76Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "449.722px 167.75px",
        }}
        id="elzz8xp94g76"
        className="animable"
      />
      <path
        d="M442.51,173.55a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,442.51,173.55Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "442.51px 174.75px",
        }}
        id="el4j74n08r21l"
        className="animable"
      />
      <path
        d="M442.51,166.37a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "442.619px 167.565px",
        }}
        id="el7t45an49vod"
        className="animable"
      />
      <path
        d="M452.67,213.26l.7-.18-.08,0A1.14,1.14,0,0,0,452.67,213.26Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "453.02px 213.17px",
        }}
        id="el0vud5s2lspy"
        className="animable"
      />
      <path
        d="M453.29,205.88a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,453.29,205.88Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "453.29px 207.08px",
        }}
        id="el3f32jsizqgj"
        className="animable"
      />
      <path
        d="M449.7,209.47a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "449.591px 210.665px",
        }}
        id="elqdttbiw1b2h"
        className="animable"
      />
      <path
        d="M453.29,198.69a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,453.29,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "453.29px 199.89px",
        }}
        id="elbvqt1ft8jgg"
        className="animable"
      />
      <path
        d="M449.7,202.29a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,449.7,202.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "449.69px 203.49px",
        }}
        id="elosj4igw77x8"
        className="animable"
      />
      <path
        d="M453.29,191.51a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,453.29,191.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "453.29px 192.71px",
        }}
        id="elf6qf3710g66"
        className="animable"
      />
      <path
        d="M449.7,195.1a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,449.7,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "449.69px 196.3px",
        }}
        id="elqjj9tai993o"
        className="animable"
      />
      <path
        d="M453.29,184.33a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,453.29,184.33Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "453.29px 185.53px",
        }}
        id="elwnmew24c6x"
        className="animable"
      />
      <path
        d="M449.7,187.92a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,449.7,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "449.69px 189.12px",
        }}
        id="el31m5a0y1puz"
        className="animable"
      />
      <path
        d="M453.29,177.14a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,453.29,177.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "453.29px 178.34px",
        }}
        id="el8dj2ic10tuq"
        className="animable"
      />
      <path
        d="M449.7,180.74a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,449.7,180.74Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "449.69px 181.94px",
        }}
        id="elvbletpiwtb"
        className="animable"
      />
      <path
        d="M453.29,170a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,453.29,170Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "453.29px 171.2px",
        }}
        id="elbw06ysiwwt"
        className="animable"
      />
      <path
        d="M449.7,173.55a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "449.591px 174.745px",
        }}
        id="eloniuci8anzq"
        className="animable"
      />
      <path
        d="M460.47,205.88a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,460.47,205.88Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "460.47px 207.08px",
        }}
        id="eleirvocut1jk"
        className="animable"
      />
      <path
        d="M456.88,209.47a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,456.88,209.47Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "456.88px 210.67px",
        }}
        id="elqbphs5b9ufp"
        className="animable"
      />
      <path
        d="M460.47,198.69a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,460.47,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "460.47px 199.89px",
        }}
        id="elxidemlxkrn"
        className="animable"
      />
      <path
        d="M456.88,202.29a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,456.88,202.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "456.88px 203.49px",
        }}
        id="elfyrekni4yfh"
        className="animable"
      />
      <path
        d="M460.47,191.51a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,460.47,191.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "460.47px 192.71px",
        }}
        id="el0tds2ip901q"
        className="animable"
      />
      <path
        d="M456.88,195.1a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,456.88,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "456.88px 196.3px",
        }}
        id="elgfophguajgv"
        className="animable"
      />
      <path
        d="M460.47,184.33a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "460.58px 185.525px",
        }}
        id="elrtesxkjtau"
        className="animable"
      />
      <path
        d="M456.88,187.92a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,456.88,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "456.88px 189.12px",
        }}
        id="elfpxotgw642p"
        className="animable"
      />
      <path
        d="M460.47,177.14a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,460.47,177.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "460.47px 178.34px",
        }}
        id="elaajd7bkeiyh"
        className="animable"
      />
      <path
        d="M456.88,180.74a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,456.88,180.74Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "456.88px 181.94px",
        }}
        id="elsb2bqt60rr"
        className="animable"
      />
      <path
        d="M459.28,171.16a1.2,1.2,0,0,0,2.39,0,1.17,1.17,0,0,0-.08-.38l-1.81-.57A1.17,1.17,0,0,0,459.28,171.16Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "460.475px 171.23px",
        }}
        id="el8zzatozve0c"
        className="animable"
      />
      <path
        d="M456.88,173.55a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,456.88,173.55Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "456.88px 174.75px",
        }}
        id="elb78vdq8msmt"
        className="animable"
      />
      <path
        d="M467.66,205.88a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,467.66,205.88Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "467.65px 207.08px",
        }}
        id="el4rg3h0rur"
        className="animable"
      />
      <path
        d="M464.06,209.47a1.2,1.2,0,0,0-1.19,1.18l2.22-.56A1.18,1.18,0,0,0,464.06,209.47Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "463.98px 210.06px",
        }}
        id="elah74l8hy52"
        className="animable"
      />
      <path
        d="M467.66,198.69a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,467.66,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "467.65px 199.89px",
        }}
        id="el2t23tbe389d"
        className="animable"
      />
      <path
        d="M464.06,202.29a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,464.06,202.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "464.06px 203.49px",
        }}
        id="el43kryyiad6j"
        className="animable"
      />
      <path
        d="M467.66,191.51a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "467.551px 192.705px",
        }}
        id="eldnk5m48j5xl"
        className="animable"
      />
      <path
        d="M464.06,195.1a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,464.06,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "464.06px 196.3px",
        }}
        id="elk69t7bez28j"
        className="animable"
      />
      <path
        d="M467.66,184.33a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,467.66,184.33Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "467.65px 185.53px",
        }}
        id="elc5xao6kwel"
        className="animable"
      />
      <path
        d="M464.06,187.92a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,464.06,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "464.06px 189.12px",
        }}
        id="elp1kry0x0xco"
        className="animable"
      />
      <path
        d="M467.66,177.14a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,467.66,177.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "467.65px 178.34px",
        }}
        id="el0yxh8gleblal"
        className="animable"
      />
      <path
        d="M464.06,180.74a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,464.06,180.74Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "464.06px 181.94px",
        }}
        id="elc4vbrgg57vt"
        className="animable"
      />
      <path
        d="M470.05,174.75a1.2,1.2,0,0,0,2.39,0,1.29,1.29,0,0,0-.16-.58l-1.55-.49A1.2,1.2,0,0,0,470.05,174.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "471.245px 174.76px",
        }}
        id="elrudvj6qpzkh"
        className="animable"
      />
      <path
        d="M464.06,173.55a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,464.06,173.55Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "464.06px 174.75px",
        }}
        id="elzh7dm00fxb"
        className="animable"
      />
      <path
        d="M474.84,205.88a1.19,1.19,0,0,0-1.2,1.19,1.21,1.21,0,0,0,.29.76l2.06-.53a.78.78,0,0,0,.05-.23A1.19,1.19,0,0,0,474.84,205.88Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "474.84px 206.855px",
        }}
        id="el3zcy6ksy6dj"
        className="animable"
      />
      <path
        d="M474.84,198.69a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,474.84,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "474.84px 199.89px",
        }}
        id="ele4s200szxcm"
        className="animable"
      />
      <path
        d="M471.25,202.29a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,471.25,202.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "471.24px 203.49px",
        }}
        id="eldls2568cbxt"
        className="animable"
      />
      <path
        d="M474.84,191.51a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,474.84,191.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "474.84px 192.71px",
        }}
        id="el0yzec5q5nxqa"
        className="animable"
      />
      <path
        d="M471.25,195.1a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,471.25,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "471.24px 196.3px",
        }}
        id="eljxtgu8f0g9b"
        className="animable"
      />
      <path
        d="M474.84,184.33a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,474.84,184.33Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "474.84px 185.53px",
        }}
        id="elhtyk5vmocj"
        className="animable"
      />
      <path
        d="M471.25,187.92a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,471.25,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "471.24px 189.12px",
        }}
        id="elp0d8y1o0p3h"
        className="animable"
      />
      <path
        d="M474.84,177.14a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,474.84,177.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "474.84px 178.34px",
        }}
        id="el9erzwtup8oj"
        className="animable"
      />
      <path
        d="M471.25,180.74a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,471.25,180.74Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "471.24px 181.94px",
        }}
        id="el0r13pcb3t1z"
        className="animable"
      />
      <path
        d="M482,198.69a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,482,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "482px 199.89px",
        }}
        id="elsv41t497358"
        className="animable"
      />
      <path
        d="M478.43,202.29a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "478.54px 203.485px",
        }}
        id="eln8dynnn4s9"
        className="animable"
      />
      <path
        d="M482,191.51a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,482,191.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "482px 192.71px",
        }}
        id="el7x5xw9st8e9"
        className="animable"
      />
      <path
        d="M478.43,195.1a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,478.43,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "478.43px 196.3px",
        }}
        id="elrr2wgbm8hn"
        className="animable"
      />
      <path
        d="M482,184.33a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,482,184.33Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "482px 185.53px",
        }}
        id="eldeg2qdfly7"
        className="animable"
      />
      <path
        d="M478.43,187.92a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,478.43,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "478.43px 189.12px",
        }}
        id="elw1bdqa7i4h"
        className="animable"
      />
      <path
        d="M480.83,178.34a1.2,1.2,0,1,0,2.39,0,1.16,1.16,0,0,0-.31-.79l-1.12-.36A1.17,1.17,0,0,0,480.83,178.34Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "482.025px 178.42px",
        }}
        id="elzl7gttqe1ee"
        className="animable"
      />
      <path
        d="M478.43,180.74a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "478.539px 181.935px",
        }}
        id="ele5sg9qrvjj"
        className="animable"
      />
      <path
        d="M489.21,198.69a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,489.21,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "489.2px 199.89px",
        }}
        id="el7fbraljhl4d"
        className="animable"
      />
      <path
        d="M485.62,202.29a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,485.62,202.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "485.61px 203.49px",
        }}
        id="ele7ikjek1ff5"
        className="animable"
      />
      <path
        d="M489.21,191.51a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "489.101px 192.705px",
        }}
        id="elbepuyjbf18"
        className="animable"
      />
      <path
        d="M485.62,195.1a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,485.62,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "485.61px 196.3px",
        }}
        id="elwal0jmu6gni"
        className="animable"
      />
      <path
        d="M489.21,184.33a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,489.21,184.33Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "489.2px 185.53px",
        }}
        id="elu15ktwm9nil"
        className="animable"
      />
      <path
        d="M485.62,187.92a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,485.62,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "485.61px 189.12px",
        }}
        id="el2v2r3jdgde2"
        className="animable"
      />
      <path
        d="M488.62,179.36a1.23,1.23,0,0,0,.5.16Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "488.87px 179.44px",
        }}
        id="elz9gjg8lj14"
        className="animable"
      />
      <path
        d="M491.6,181.93a1.2,1.2,0,0,0,2.4,0,1.19,1.19,0,0,0-.72-1.09l0,0a1.26,1.26,0,0,0-.43-.08A1.19,1.19,0,0,0,491.6,181.93Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "492.8px 181.944px",
        }}
        id="elfzaj8usfh36"
        className="animable"
      />
      <path
        d="M485.62,180.74a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,485.62,180.74Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "485.61px 181.94px",
        }}
        id="el2pfbj19127d"
        className="animable"
      />
      <path
        d="M496.39,198.69a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,496.39,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "496.39px 199.89px",
        }}
        id="el8zv1o6p028e"
        className="animable"
      />
      <path
        d="M492.8,202.29a1.17,1.17,0,0,0-1.16,1l2.1-.53A1.17,1.17,0,0,0,492.8,202.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "492.69px 202.79px",
        }}
        id="elnnnb6iwodi"
        className="animable"
      />
      <path
        d="M496.39,191.51a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,496.39,191.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "496.39px 192.71px",
        }}
        id="el9170wysgvkw"
        className="animable"
      />
      <path
        d="M492.8,195.1a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,492.8,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "492.8px 196.3px",
        }}
        id="elge5j602doi7"
        className="animable"
      />
      <path
        d="M496.39,184.33a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "496.5px 185.525px",
        }}
        id="elouc2z8p0pvc"
        className="animable"
      />
      <path
        d="M500,183.13a1.23,1.23,0,0,0,.32-.07l-1.23-.38A1.2,1.2,0,0,0,500,183.13Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "499.705px 182.905px",
        }}
        id="el7yy9fxw97v"
        className="animable"
      />
      <path
        d="M492.8,187.92a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,492.8,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "492.8px 189.12px",
        }}
        id="eluh3fpvcqgzs"
        className="animable"
      />
      <path
        d="M503.58,198.69a1.2,1.2,0,0,0-1.2,1.2,1.12,1.12,0,0,0,.2.62l2.18-.56s0,0,0-.06A1.19,1.19,0,0,0,503.58,198.69Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "503.57px 199.6px",
        }}
        id="elyr2gxuwvhr"
        className="animable"
      />
      <path
        d="M503.58,191.51a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "503.471px 192.705px",
        }}
        id="el962wdhcjuk4"
        className="animable"
      />
      <path
        d="M500,195.1a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,500,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "500px 196.3px",
        }}
        id="el7ybo1f76iit"
        className="animable"
      />
      <path
        d="M503.58,184.33a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,503.58,184.33Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "503.57px 185.53px",
        }}
        id="elqd76mgtz5e"
        className="animable"
      />
      <path
        d="M500,187.92a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,500,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "500px 189.12px",
        }}
        id="elq1fj46gql4"
        className="animable"
      />
      <path
        d="M509.56,192.71a1.2,1.2,0,1,0,1.2-1.2A1.19,1.19,0,0,0,509.56,192.71Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "510.76px 192.71px",
        }}
        id="el5nr7o2yk1xj"
        className="animable"
      />
      <path
        d="M507.17,195.1a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,507.17,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "507.16px 196.3px",
        }}
        id="el108jaavrsej"
        className="animable"
      />
      <path
        d="M510.76,186.72a1.18,1.18,0,0,0,.56-.16l-1.62-.51A1.18,1.18,0,0,0,510.76,186.72Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "510.51px 186.385px",
        }}
        id="eldosnsmmtd7"
        className="animable"
      />
      <path
        d="M513.16,189.12a1.2,1.2,0,1,0,1.19-1.2A1.19,1.19,0,0,0,513.16,189.12Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "514.36px 189.12px",
        }}
        id="elme84h730sx"
        className="animable"
      />
      <path
        d="M507.17,187.92a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,507.17,187.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "507.16px 189.12px",
        }}
        id="el6ni0g4mfjtt"
        className="animable"
      />
      <path
        d="M515.55,196.3a1.2,1.2,0,1,0-1.2,1.2,1,1,0,0,0,.17,0l.33-.08A1.21,1.21,0,0,0,515.55,196.3Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "514.35px 196.302px",
        }}
        id="el4vfiqwfj415"
        className="animable"
      />
      <path
        d="M516.75,192.71a1.2,1.2,0,0,0,2.39,0,1.2,1.2,0,1,0-2.39,0Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "517.945px 192.601px",
        }}
        id="elw88oqmfd0t"
        className="animable"
      />
      <path
        d="M521.54,190.31a1.19,1.19,0,0,0,.73-.27l-1.86-.59A1.17,1.17,0,0,0,521.54,190.31Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "521.34px 189.88px",
        }}
        id="elhtpk5f0b8jm"
        className="animable"
      />
      <path
        d="M523.93,192.71a1.2,1.2,0,1,0,1.2-1.2A1.19,1.19,0,0,0,523.93,192.71Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "525.13px 192.71px",
        }}
        id="eldnk0x1hnlj"
        className="animable"
      />
      <path
        d="M521.54,195.1a1.19,1.19,0,0,0-1.13.86l2-.51A1.19,1.19,0,0,0,521.54,195.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "521.41px 195.53px",
        }}
        id="elsx79bgnjv6l"
        className="animable"
      />
      <path
        d="M531.14,192.85a1.69,1.69,0,0,0,.08.34l.56-.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "531.46px 193.02px",
        }}
        id="elxq1mgg6eks"
        className="animable"
      />
      <polygon
        points="299.62 148.67 351.06 172.22 375 112.95 299.62 148.67"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "337.31px 142.585px",
        }}
        id="elme1o46kcr8"
        className="animable"
      />
      <path
        d="M450.65,194c-4.41,14.44-27.25,69.69-59,68.14-10.8-.52-14.78-43,5.57-85.87.61-1.27,32.93,3.38,32.78,11.16,0,.83-6.88,19.1-5.95,19.26.14,0,3.76-3.8,19.61-20C451.54,178.56,453.16,185.76,450.65,194Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "417.243px 219.117px",
        }}
        id="elx9kyy2755er"
        className="animable"
      />
      <path
        d="M424,206.52c-2.68,10.52-5.57,21-8.74,31.37-.89,2.9-1.75,5.8-2.62,8.7,0,.1.14.15.18,0a156.23,156.23,0,0,0,5.56-15.31c1.59-5.14,3.07-10.37,4.14-15.65.62-3,1.19-6.07,1.68-9.12A.1.1,0,0,0,424,206.52Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "418.42px 226.551px",
        }}
        id="el1xstq85iwmc"
        className="animable"
      />
      <path
        d="M425.34,203s-2.2,2.41-.64,3.9,4.53-.33,4.53-.33-1.32,2.8.45,4.1,4.37-1.12,4.37-1.12-1.28,2.87.46,4.18,4.24-1.23,4.24-1.23-1.17,2.94.34,4.16,3.76-1.39,3.76-1.39-1.13,2.91.45,3.91c1.75,1.1,3.82-2,3.82-2Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "435.63px 211.208px",
        }}
        id="elso0nkadw8y"
        className="animable"
      />
      <path
        d="M424.24,203.66l22.85,14.77s7.58-20.5,8-28.84-2.19-12.29-13.48-2.93A127.38,127.38,0,0,0,424.24,203.66Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "439.686px 199.837px",
        }}
        id="elaq98idc9uzj"
        className="animable"
      />
      <g id="el963898nxlpi">
        <path
          d="M447.08,218.43h0c.09-.24,2.19-5.92,4.21-12.44l.36-1.19c.24-.8.48-1.6.71-2.41.35-1.2.68-2.41,1-3.59q.15-.6.3-1.17a54.61,54.61,0,0,0,1.3-6.78c.05-.44.08-.85.1-1.25.17-3.65-.23-6.46-1.68-7.68L430.9,208Z"
          style={{
            opacity: 0.1,
            transformOrigin: "442.998px 200.175px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M394.13,184.2s4-13.37,11.23-21.08c5-5.32,11.91-13.83,33.16-9.11,5,1.13,13.71,8.84,10.83,12-2.75,3-9.94-5.13-17.26-5.39,0,0,7.33,7.13,6,12.13a95,95,0,0,1-4.38,12.84,19.94,19.94,0,0,1-2.47-.61s-1.39,4.65-7.91,8.1Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "422.025px 172.857px",
        }}
        id="el1v50c7uim2n"
        className="animable"
      />
      <path
        d="M410.92,158.8c3.81-.39,11.88-1.07,18.68.67,7.55,1.93,21.57,7.68,20.09,13.24-1.08,4.07-6.63,2.35-6.63,2.35s5.29,3.6,3.51,7.07c-1.63,3.16-5.84,1.56-5.84,1.56s2.83,2.74.68,5.48c-2.59,3.3-10-4.27-15.55-3.2C424.62,186.21,410.92,158.8,410.92,158.8Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "430.359px 174.173px",
        }}
        id="elndpty196ajn"
        className="animable"
      />
      <path
        d="M426.23,167.1A31.13,31.13,0,0,1,443,174.9c.11.11.09.26,0,.18a76.31,76.31,0,0,0-16.77-7.9A0,0,0,0,1,426.23,167.1Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "434.653px 171.101px",
        }}
        id="el82r84cxk0ls"
        className="animable"
      />
      <path
        d="M429.14,158.76a31.13,31.13,0,0,1,16.79,7.8c.11.11.09.26,0,.18a76.47,76.47,0,0,0-16.77-7.91S429.1,158.76,429.14,158.76Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "437.566px 162.761px",
        }}
        id="elatczpe2lx05"
        className="animable"
      />
      <path
        d="M424.5,174.78c3.12.52,11.37,3.6,16.3,8.79.11.11.07.26-.05.17a82.39,82.39,0,0,0-16.27-8.89S424.46,174.78,424.5,174.78Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "432.67px 179.274px",
        }}
        id="elhwo5jis7j0g"
        className="animable"
      />
      <path
        d="M430.84,186.57c-.45-.5-4.62-2.28-8.92-1.75a.1.1,0,0,0,0,.19c2.55.36,4.63.77,4.67.78-2.88,4.09-8.39,6.11-10.77,6.75-.15,0-.08.27.06.26,4.44-.3,9.19-2.75,11-6.92a38.08,38.08,0,0,0,3.88.81A.08.08,0,0,0,430.84,186.57Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "423.294px 188.761px",
        }}
        id="ellkw4c76kn6a"
        className="animable"
      />
      <path
        d="M438.06,258.08l1.64-27.43s-7.38-8.16-5.74-15.53c.82-3.73,13.56-32.11,14.8-33.27,5-4.68,13.48-2.81,17-.19C485,196.2,498.52,227.41,505,258.08Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "469.361px 218.531px",
        }}
        id="elgdcfdkgvh1q"
        className="animable"
      />
      <path
        d="M438.06,258.08l1.64-27.43s-7.38-8.16-5.74-15.53c.82-3.73,13.56-32.11,14.8-33.27,5-4.68,13.48-2.81,17-.19C485,196.2,498.52,227.41,505,258.08Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "469.361px 218.531px",
        }}
        id="eltsx8rsaitaj"
        className="animable"
      />
      <path
        d="M439.7,230.65l-1.63,27.43h67c-6.51-30.67-20.07-61.88-39.31-76.42a11.84,11.84,0,0,0-2.44-1.36c-4.1-1.74-10.45-2.25-14.52,1.55-1.25,1.16-14,29.54-14.8,33.27C432.32,222.49,439.7,230.65,439.7,230.65Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "469.411px 218.529px",
        }}
        id="el1xgmgtkganh"
        className="animable"
      />
      <path
        d="M439.7,230.65l-1.63,27.43h67c-6.51-30.67-20.07-61.88-39.31-76.42a11.84,11.84,0,0,0-2.44-1.36c-4.1-1.74-10.45-2.25-14.52,1.55-1.25,1.16-14,29.54-14.8,33.27C432.32,222.49,439.7,230.65,439.7,230.65Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "469.411px 218.529px",
        }}
        id="elv876h2wjljs"
        className="animable"
      />
      <g id="el2sac556k89z">
        <path
          d="M458.36,179.06l17,79H505c-6.5-30.67-20.06-61.88-39.3-76.42a11.84,11.84,0,0,0-2.44-1.36A17.48,17.48,0,0,0,458.36,179.06Z"
          style={{
            opacity: 0.1,
            transformOrigin: "481.68px 218.56px",
          }}
          className="animable"
        />
      </g>
      <rect
        x={395.11}
        y={286.23}
        width={15.63}
        height={2.44}
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "402.925px 287.45px",
        }}
        id="elh165twve1wu"
        className="animable"
      />
      <rect
        x={395.11}
        y={286.23}
        width={15.63}
        height={1.61}
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "402.925px 287.035px",
        }}
        id="elp9k53k3icxo"
        className="animable"
      />
      <path
        d="M429.29,279.68a8.87,8.87,0,0,1-8.8,3.76,9.08,9.08,0,0,1-1.59-.45,11.57,11.57,0,0,1-2.21-1.17l2-2.94a7.52,7.52,0,0,0,1.17.67,5.2,5.2,0,0,0,6.54-1.92c2.15-3,2.91-9.89.74-12.53-.14-.2-.77-.51-3.29,0-.46.1-1,.22-1.58.38a6.09,6.09,0,0,1-1.43.31l-.06-1.77-.18-1.76c.16,0,.42-.1.75-.2a22.72,22.72,0,0,1,3.55-.73c1.85-.2,3.8,0,5,1.49C433.4,267.17,432,275.9,429.29,279.68Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "424.337px 272.413px",
        }}
        id="eli72s7cu6x3h"
        className="animable"
      />
      <path
        d="M418.68,278.88a7.52,7.52,0,0,0,1.17.67l-1,3.44a11.57,11.57,0,0,1-2.21-1.17Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "418.245px 280.935px",
        }}
        id="elyswnkbbtsta"
        className="animable"
      />
      <path
        d="M424.89,261.36l-1.05,3.77c-.46.1-1,.22-1.58.38a6.09,6.09,0,0,1-1.43.31l-.06-1.77-.18-1.76c.16,0,.42-.1.75-.2A22.72,22.72,0,0,1,424.89,261.36Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "422.74px 263.59px",
        }}
        id="elmp0cex4dqyl"
        className="animable"
      />
      <path
        d="M382.14,256.75s1.87,15.93,5.11,22.63,7.76,7.45,10.57,7.45H408c2.81,0,7.33-.75,10.57-7.45s5.11-22.63,5.11-22.63Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "402.91px 271.79px",
        }}
        id="elwxvms29q9km"
        className="animable"
      />
      <path
        d="M385.19,267.18a1,1,0,1,1,1-1A1,1,0,0,1,385.19,267.18Zm0-1.82a.82.82,0,1,0,.82.82A.82.82,0,0,0,385.19,265.36Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "385.19px 266.18px",
        }}
        id="el2zxj8n2zr43"
        className="animable"
      />
      <path
        d="M414.82,262a1,1,0,1,1,1-1A1,1,0,0,1,414.82,262Zm0-1.82a.82.82,0,1,0,.82.82A.82.82,0,0,0,414.82,260.21Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "414.82px 261px",
        }}
        id="el4iuhm1o1r89"
        className="animable"
      />
      <path
        d="M413.34,276.71a1,1,0,1,1,1-1A1,1,0,0,1,413.34,276.71Zm0-1.82a.82.82,0,1,0,.82.82A.82.82,0,0,0,413.34,274.89Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "413.34px 275.71px",
        }}
        id="el67jdklc931s"
        className="animable"
      />
      <path
        d="M421.5,259.41a1,1,0,1,1,1-1A1,1,0,0,1,421.5,259.41Zm0-1.82a.82.82,0,1,0,.82.82A.82.82,0,0,0,421.5,257.59Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "421.5px 258.41px",
        }}
        id="el4bz9ewxlkfv"
        className="animable"
      />
      <path
        d="M407.56,265.65a1,1,0,1,1,1-1A1,1,0,0,1,407.56,265.65Zm0-1.82a.82.82,0,0,0-.82.81.82.82,0,0,0,1.64,0A.82.82,0,0,0,407.56,263.83Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "407.56px 264.65px",
        }}
        id="elwjqgq43uf5"
        className="animable"
      />
      <path
        d="M396.68,276.29a1,1,0,1,1,1-1A1,1,0,0,1,396.68,276.29Zm0-1.82a.82.82,0,0,0,0,1.64.82.82,0,0,0,0-1.64Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "396.68px 275.29px",
        }}
        id="elbktgly04zsk"
        className="animable"
      />
      <path
        d="M403.33,259.41a1,1,0,1,1,1-1A1,1,0,0,1,403.33,259.41Zm0-1.82a.82.82,0,1,0,.82.82A.82.82,0,0,0,403.33,257.59Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "403.33px 258.41px",
        }}
        id="elylyfi33d1vi"
        className="animable"
      />
      <path
        d="M404.57,272.28a1,1,0,1,1,1-1A1,1,0,0,1,404.57,272.28Zm0-1.82a.82.82,0,1,0,.82.82A.82.82,0,0,0,404.57,270.46Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "404.57px 271.28px",
        }}
        id="el9o6v4vtaxzt"
        className="animable"
      />
      <path
        d="M414.49,284.26a1,1,0,1,1,1-1A1,1,0,0,1,414.49,284.26Zm0-1.82a.82.82,0,0,0-.81.82.81.81,0,0,0,.81.81.82.82,0,0,0,.82-.81A.82.82,0,0,0,414.49,282.44Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "414.49px 283.26px",
        }}
        id="elwc60riq2km"
        className="animable"
      />
      <path
        d="M390.16,260.91a1,1,0,1,1,1-1A1,1,0,0,1,390.16,260.91Zm0-1.82a.82.82,0,1,0,.81.82A.82.82,0,0,0,390.16,259.09Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "390.16px 259.91px",
        }}
        id="el0gsc2c9tfzjt"
        className="animable"
      />
      <path
        d="M418.08,269.55a1,1,0,1,1,1-1A1,1,0,0,1,418.08,269.55Zm0-1.82a.82.82,0,0,0,0,1.64.82.82,0,0,0,0-1.64Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "418.08px 268.55px",
        }}
        id="ell7ai7o8nj7c"
        className="animable"
      />
      <path
        d="M397.59,266.55a1,1,0,0,1,0-2,1,1,0,0,1,0,2Zm0-1.81a.82.82,0,0,0-.82.81.82.82,0,0,0,.82.82.82.82,0,0,0,.81-.82A.81.81,0,0,0,397.59,264.74Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "397.59px 265.55px",
        }}
        id="el1iopgli1uex"
        className="animable"
      />
      <path
        d="M404.57,282.75a1,1,0,1,1,1-1A1,1,0,0,1,404.57,282.75Zm0-1.82a.82.82,0,1,0,.82.82A.82.82,0,0,0,404.57,280.93Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "404.57px 281.75px",
        }}
        id="elqli6frs4vw"
        className="animable"
      />
      <path
        d="M396.15,286.37a1,1,0,1,1,1-1A1,1,0,0,1,396.15,286.37Zm0-1.82a.82.82,0,0,0-.82.81.82.82,0,0,0,.82.82.82.82,0,0,0,.81-.82A.81.81,0,0,0,396.15,284.55Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "396.15px 285.37px",
        }}
        id="elrjdm7snp7k"
        className="animable"
      />
      <path
        d="M390.16,271.37a1,1,0,1,1,1-1A1,1,0,0,1,390.16,271.37Zm0-1.82a.82.82,0,1,0,.81.82A.82.82,0,0,0,390.16,269.55Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "390.16px 270.37px",
        }}
        id="ellyt94ozvbwi"
        className="animable"
      />
      <path
        d="M389.25,280.4a1,1,0,1,1,1-1A1,1,0,0,1,389.25,280.4Zm0-1.82a.82.82,0,1,0,.81.82A.82.82,0,0,0,389.25,278.58Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "389.25px 279.4px",
        }}
        id="elkn4a755a52p"
        className="animable"
      />
      <path
        d="M475.2,187.81c23.38,17.61,53.56,65.53,49.09,77-4.09,10.44-40,20.93-61.14,23.82-4,.55-6.84-33.77-2.56-34.52,7.65-1.35,25-8.79,25.51-8.92,1.09-.27-18.65-37.39-22.23-50.6C459.81,179.57,465,180.11,475.2,187.81Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "491.596px 235.577px",
        }}
        id="elzznyp6pz8ap"
        className="animable"
      />
      <path
        d="M417.73,262c.42,3.87,9.86,2,9.86,2a7.17,7.17,0,0,0-2.46,4.21c-.4,2.93,4,4.84,4,4.84a8.9,8.9,0,0,0-1.89,4.86c-.09,2.84,4.09,5.4,4.09,5.4a7.75,7.75,0,0,0,1,5.32c2.76,4.14,10.37,3.92,15.21,3.83s18.48-4.88,21.17-5.53l-.32-31.06s-21.82-5.76-27.49-4.84C436.17,251.85,417.09,256.1,417.73,262Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "443.212px 271.713px",
        }}
        id="el5d9eyd1bbba"
        className="animable"
      />
      <path
        d="M427.66,263.93c4.89-2,10.25-2.91,15.43-3.69.12,0,.15.17,0,.18-5.17.72-10.26,2.83-15.39,3.75A.13.13,0,0,1,427.66,263.93Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "435.393px 262.205px",
        }}
        id="eli7y1olubk6"
        className="animable"
      />
      <path
        d="M429.25,272.69a13.34,13.34,0,0,1,3.56-.6c1.27-.13,2.54-.24,3.82-.32q3.83-.25,7.67-.32c.12,0,.15.17,0,.18-2.55.18-5.09.39-7.63.67-1.27.14-2.53.29-3.8.45a16.77,16.77,0,0,1-3.6.29A.18.18,0,0,1,429.25,272.69Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "436.761px 272.248px",
        }}
        id="el84cv2j9od2q"
        className="animable"
      />
      <path
        d="M431.46,283.35a9.45,9.45,0,0,1,3.23-.37l3.5-.18c2.33-.14,4.65-.27,7-.48.11,0,.14.17,0,.18-2.36.23-4.71.49-7.07.69-1.16.11-2.32.2-3.48.29a9.18,9.18,0,0,1-3.18,0A.07.07,0,0,1,431.46,283.35Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "438.35px 282.969px",
        }}
        id="elhf2vz2dbb8t"
        className="animable"
      />
      <path
        d="M490.74,200.89s2.36,2.26.9,3.85-4.54,0-4.54,0,1.51,2.71-.17,4.12-4.44-.82-4.44-.82,1.48,2.78-.17,4.21-4.31-.94-4.31-.94,1.36,2.86-.06,4.17-3.84-1.13-3.84-1.13,1.33,2.83-.18,3.93c-1.67,1.22-3.95-1.73-3.95-1.73Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "481.048px 209.733px",
        }}
        id="elccf71hjzj6"
        className="animable"
      />
      <path
        d="M491.88,201.43,470.1,217.74s-9-19.92-9.93-28.22-1.72-13.83,10.88-5.47A129.24,129.24,0,0,1,491.88,201.43Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "475.793px 198.82px",
        }}
        id="elm4plwwvev8k"
        className="animable"
      />
      <path
        d="M448.69,181.47s-1.11,10,.7,10.26c5.58.86,12.28-6.47,12.7-7.67.1-.31.12-8.11.13-14.17,0-3.9,0-7.08,0-7.08l-14.82,7.34,1.21,10.56Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "454.81px 177.305px",
        }}
        id="elhfmkk42lt7q"
        className="animable"
      />
      <path
        d="M447.39,170.15l1.21,10.56a21.31,21.31,0,0,0,13.62-10.82c0-3.9,0-7.08,0-7.08l-1.87.85Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "454.805px 171.76px",
        }}
        id="elovaoqxq9c3m"
        className="animable"
      />
      <path
        d="M434.71,153.42c-2.86,18.45,7.87,22.24,11.85,22.86,3.62.55,16,2,20.73-16.08s-2.93-25.22-11.12-26.92S437.56,135,434.71,153.42Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "451.486px 154.725px",
        }}
        id="elekuqbtgfm6l"
        className="animable"
      />
      <path
        d="M445.5,175.9a13.51,13.51,0,0,1-7.88-6,21.08,21.08,0,0,1-3-11,42,42,0,0,1,.35-6.72s-.07-.07-.08,0c-.93,4.33-1.32,8.2-.3,12.56a15.68,15.68,0,0,0,5.67,9,14.28,14.28,0,0,0,5.22,2.32A.08.08,0,0,0,445.5,175.9Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "439.779px 164.105px",
        }}
        id="elpg5vgjhtri"
        className="animable"
      />
      <path
        d="M438.55,151.14c.44,0,.84-.1,1.28-.16a2.06,2.06,0,0,0,1.27-.42.65.65,0,0,0,.07-.8,1.65,1.65,0,0,0-1.59-.52A2.42,2.42,0,0,0,438,150,.71.71,0,0,0,438.55,151.14Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "439.565px 150.171px",
        }}
        id="el0fidu2angehb"
        className="animable"
      />
      <path
        d="M451.39,152.61a12.49,12.49,0,0,1-1.23-.37,2.09,2.09,0,0,1-1.19-.62.65.65,0,0,1,.07-.81,1.65,1.65,0,0,1,1.65-.24,2.38,2.38,0,0,1,1.44,1A.71.71,0,0,1,451.39,152.61Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "450.538px 151.534px",
        }}
        id="elhmjxpbws7ep"
        className="animable"
      />
      <path
        d="M448.52,157.1s.09.07.08.12c-.17,1.26-.16,2.73.94,3.27,0,0,0,.08,0,.07C448.14,160.22,448.15,158.26,448.52,157.1Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "448.924px 158.83px",
        }}
        id="elohi1a00ja8l"
        className="animable"
      />
      <path
        d="M449.79,155.9c2,.06,1.77,4.16-.13,4.1S448.07,155.85,449.79,155.9Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "449.777px 157.95px",
        }}
        id="el36obs23rn6u"
        className="animable"
      />
      <path
        d="M450.58,156.17c.34.26.66.69,1.09.76s.9-.32,1.26-.71c0,0,.07,0,.06.05,0,.79-.39,1.57-1.24,1.63s-1.28-.68-1.42-1.48C450.3,156.27,450.44,156.07,450.58,156.17Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "451.658px 157.023px",
        }}
        id="el416i556mieg"
        className="animable"
      />
      <path
        d="M440.61,156s-.1.05-.1.11c0,1.27-.29,2.72-1.47,3.07,0,0,0,.07,0,.07C440.46,159.12,440.78,157.19,440.61,156Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "439.846px 157.625px",
        }}
        id="elz22ke8ku6sl"
        className="animable"
      />
      <path
        d="M439.56,154.59c-2-.28-2.44,3.8-.56,4.06S441.26,154.82,439.56,154.59Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "439.223px 156.619px",
        }}
        id="elxqvu1e8b14"
        className="animable"
      />
      <path
        d="M438.68,154.77c-.34.18-.7.55-1.09.53s-.74-.49-1-.94c0-.05-.06,0-.07,0-.1.78.08,1.62.81,1.84s1.24-.41,1.51-1.17C438.91,154.93,438.82,154.7,438.68,154.77Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "437.679px 155.291px",
        }}
        id="elclxml8up0co"
        className="animable"
      />
      <path
        d="M447.23,166.79c-.31.29-.62.69-1.08.72a3.38,3.38,0,0,1-1.36-.35,0,0,0,0,0-.06.06,1.71,1.71,0,0,0,1.6.73,1.35,1.35,0,0,0,1-1.1C447.37,166.77,447.28,166.74,447.23,166.79Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "446.035px 167.363px",
        }}
        id="el7gz02eymf9"
        className="animable"
      />
      <path
        d="M442.3,161.78s-.41,1.87-.48,2.77c0,.08.2.14.5.18,0,0,0,0,0,0a4.24,4.24,0,0,0,4.25-1.25c.07-.08,0-.19-.13-.14a6.38,6.38,0,0,1-4,.8c0-.25.81-3.18.67-3.2a7.54,7.54,0,0,0-2.06.3c.74-3.93,2.11-7.7,2.8-11.62a.12.12,0,0,0-.23-.07,65.4,65.4,0,0,0-3.55,12.38C440,162.43,441.93,161.91,442.3,161.78Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "443.333px 157.171px",
        }}
        id="el178mvshdwx"
        className="animable"
      />
      <path
        d="M448.72,163.42a5.84,5.84,0,0,1-4.1-7.15h0a5.83,5.83,0,1,1,5.54,7.36A6,6,0,0,1,448.72,163.42Zm-3.35-6.95a5.05,5.05,0,1,0,6.2-3.55,4.87,4.87,0,0,0-1.24-.17A5.06,5.06,0,0,0,445.37,156.47Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "450.248px 157.801px",
        }}
        id="eljqpx7vbep7"
        className="animable"
      />
      <path
        d="M436.53,161.76c-2.38-.32-4-3.16-3.54-6.35a7,7,0,0,1,1.76-3.88,3.64,3.64,0,0,1,6.16.82,7,7,0,0,1,.69,4.21c-.4,3-2.46,5.26-4.69,5.23A2.34,2.34,0,0,1,436.53,161.76Zm-1.21-9.69a6.15,6.15,0,0,0-1.55,3.44c-.37,2.76.91,5.22,2.86,5.48s3.83-1.78,4.2-4.54a6.19,6.19,0,0,0-.6-3.72A3.09,3.09,0,0,0,438,151l-.29,0A3.16,3.16,0,0,0,435.32,152.07Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "437.294px 155.985px",
        }}
        id="elq54xqg0mv1j"
        className="animable"
      />
      <path
        d="M444.73,157l.57-.53c-.78-.85-2.67-2.11-4.28-.79l.49.6C443,155.07,444.65,156.89,444.73,157Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "443.16px 156.054px",
        }}
        id="elrpx0o6q4998"
        className="animable"
      />
      <path
        d="M470.27,161.88l.73-.26c-.12-.34-1.24-3.36-2.93-3.87s-12.06-.88-12.51-.9l0,.78c3,.12,11.1.5,12.31.87S470,161.05,470.27,161.88Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "463.28px 159.365px",
        }}
        id="elp3z34k53tto"
        className="animable"
      />
      <path
        d="M466.29,159c-4.31-.2-3.9-7-3.9-7s-4.86-.72-4.77-8.64a12,12,0,0,1-9.31,5.23c-1.42.13,3.49-3.24,3.49-3.24s-5.92,4.78-16.28,2.25c-.73-.18,1.47-11.13,9.9-14.43s15.69-.74,20.48,4.73c0,0,5.86,3.11,6.21,8.84S468,159.12,466.29,159Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "453.759px 145.352px",
        }}
        id="elsqj00gsyyd"
        className="animable"
      />
      <path
        d="M464.14,161.17s4.46-5.55,7-3.92-1.08,9.85-4.26,10.8a3.17,3.17,0,0,1-4.11-2Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "467.383px 162.597px",
        }}
        id="elwizqwwjsigd"
        className="animable"
      />
      <path
        d="M470,159.58a0,0,0,0,1,0,.09c-2.23.73-3.51,2.67-4.45,4.7a1.75,1.75,0,0,1,2.64-.3c.05,0,0,.13-.07.11a1.93,1.93,0,0,0-2.18.54,8.92,8.92,0,0,0-1.13,1.65c-.12.19-.46.06-.37-.16a.08.08,0,0,1,0,0C464.64,163.39,467.05,159.66,470,159.58Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "467.213px 163.021px",
        }}
        id="elx7glzu7apwq"
        className="animable"
      />
      <path
        d="M465.19,167.11a.66.66,0,0,0,.63.68.66.66,0,0,0,.05-1.31A.66.66,0,0,0,465.19,167.11Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "465.809px 167.135px",
        }}
        id="elvy41bzlrpoo"
        className="animable"
      />
      <path
        d="M467.07,166.05a.41.41,0,0,0,.39.43.41.41,0,0,0,.43-.4.41.41,0,0,0-.4-.42A.4.4,0,0,0,467.07,166.05Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "467.48px 166.07px",
        }}
        id="el2djo9qt2vzr"
        className="animable"
      />
      <path
        d="M470.68,160.93l1.25.36a.27.27,0,0,0,.33-.18h0a.26.26,0,0,0-.18-.32l-1.26-.36a.26.26,0,0,0-.32.17h0A.27.27,0,0,0,470.68,160.93Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "471.38px 160.86px",
        }}
        id="ellok31o438x"
        className="animable"
      />
      <path
        d="M470.88,160.11l1.3.18a.26.26,0,0,0,.29-.22h0a.26.26,0,0,0-.22-.29l-1.3-.18a.26.26,0,0,0-.29.22h0A.26.26,0,0,0,470.88,160.11Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "471.565px 159.945px",
        }}
        id="elx3o1kk4sgz"
        className="animable"
      />
      <path
        d="M447.34,184.24a32.86,32.86,0,0,0-.23,3.28,18.49,18.49,0,0,0,.16,3.25,5.38,5.38,0,0,0,1.16,2.87,2.81,2.81,0,0,0,2.85.36,19.66,19.66,0,0,0,5.72-3,29.41,29.41,0,0,0,2.59-2A23.16,23.16,0,0,0,462,186.7a24,24,0,0,1-4.71,4.63,24.6,24.6,0,0,1-2.76,1.85,14,14,0,0,1-3.07,1.35,5.21,5.21,0,0,1-1.72.2,2.7,2.7,0,0,1-.88-.21,2.37,2.37,0,0,1-.75-.53,3.77,3.77,0,0,1-.88-1.5,7.33,7.33,0,0,1-.35-1.66A21.41,21.41,0,0,1,447.34,184.24Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "454.401px 189.488px",
        }}
        id="elchik3o7blqk"
        className="animable"
      />
      <path
        d="M463.24,139.6c-3.14-2-10.87-17.85,2.59-26.35,13.26-8.37,30.78,8.45,21.42,22C481,144.34,469.89,144,463.24,139.6Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "474.031px 126.804px",
        }}
        id="el40n0rdnimsj"
        className="animable"
      />
      <rect
        x={189.06}
        y={100.42}
        width={93.19}
        height={194.72}
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "235.655px 197.78px",
        }}
        id="elp9tbw8v2f48"
        className="animable"
      />
      <path
        d="M214.35,121.78v7.08a.67.67,0,0,1-.67.66.66.66,0,0,1-.66-.66v-5.05l-1.72,2.4a.67.67,0,0,1-.54.27h0a.67.67,0,0,1-.55-.27l-1.71-2.4v5.05a.67.67,0,0,1-1.34,0v-7.08a.67.67,0,0,1,.66-.66h0a.66.66,0,0,1,.53.28l2.39,3.31,2.38-3.31a.66.66,0,0,1,.54-.28h0A.69.69,0,0,1,214.35,121.78Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "210.755px 125.325px",
        }}
        id="el1z1ps0p1zli"
        className="animable"
      />
      <path
        d="M225.14,122.17a4.41,4.41,0,0,1,0,6.07,3.9,3.9,0,0,1-2.86,1.28,4,4,0,0,1-2.94-1.28,4.47,4.47,0,0,1,0-6.07,4,4,0,0,1,2.94-1.29A3.87,3.87,0,0,1,225.14,122.17Zm-.15,3a3.14,3.14,0,0,0-.82-2.1,2.57,2.57,0,0,0-1.89-.85,2.63,2.63,0,0,0-1.95.85,3.08,3.08,0,0,0-.81,2.1,3,3,0,0,0,.81,2.09,2.64,2.64,0,0,0,1.95.86,2.58,2.58,0,0,0,1.89-.86A3.07,3.07,0,0,0,225,125.2Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "222.251px 125.2px",
        }}
        id="elalobaqzn93n"
        className="animable"
      />
      <path
        d="M231.53,128.86a.66.66,0,1,1-1.31,0v-7h0a.66.66,0,0,1,.27-.55.65.65,0,0,1,.91.14l3.87,5.36v-4.94a.66.66,0,0,1,.65-.65.66.66,0,0,1,.66.65v7a.65.65,0,0,1-1.15.42.19.19,0,0,1-.06-.07l-3.84-5.33Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "233.398px 125.392px",
        }}
        id="elkgxdyckvyi"
        className="animable"
      />
      <path
        d="M246.28,122.46h-1.91v6.4a.66.66,0,0,1-1.32,0v-6.4h-1.9a.67.67,0,0,1-.65-.68.64.64,0,0,1,.65-.66h5.13a.66.66,0,0,1,.66.66A.68.68,0,0,1,246.28,122.46Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "243.72px 125.32px",
        }}
        id="elyc5b5cfwhyq"
        className="animable"
      />
      <path
        d="M255.85,122.46H254v6.4a.66.66,0,0,1-1.32,0v-6.4h-1.9a.68.68,0,0,1-.66-.68.65.65,0,0,1,.66-.66h5.12a.66.66,0,0,1,.66.66A.68.68,0,0,1,255.85,122.46Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "253.34px 125.32px",
        }}
        id="elaeid2ec0cu7"
        className="animable"
      />
      <path
        d="M208.46,144.41l-.89,1.25a.1.1,0,0,1-.08,0h0s-.07,0-.08,0l-.9-1.24v2.3a.09.09,0,0,1-.1.09.1.1,0,0,1-.1-.09v-2.59a.09.09,0,0,1,0-.08h0a.11.11,0,0,1,.14,0l1,1.38,1-1.38a.11.11,0,0,1,.14,0l0,0a.13.13,0,0,1,0,.06v2.59a.09.09,0,0,1-.09.09.09.09,0,0,1-.1-.09Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "207.447px 145.418px",
        }}
        id="elcrzkcvv9zb8"
        className="animable"
      />
      <path
        d="M210.08,144.11a.1.1,0,0,1,.1-.1h1.43a.1.1,0,0,1,0,.2h-1.33v1.11h1.17a.09.09,0,0,1,.09.1.09.09,0,0,1-.09.09h-1.17v1.11h1.33a.09.09,0,0,1,.1.1.09.09,0,0,1-.1.09h-1.44a.09.09,0,0,1-.09-.09Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "210.895px 145.41px",
        }}
        id="elpqyiourm33b"
        className="animable"
      />
      <path
        d="M213.26,144.42v2.3a.1.1,0,0,1-.2,0v-2.57a.1.1,0,0,1,0-.11.11.11,0,0,1,.14,0l1.69,2.36v-2.28a.1.1,0,0,1,.1-.1.11.11,0,0,1,.1.1v2.58a.11.11,0,0,1-.18.06l0,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "214.067px 145.417px",
        }}
        id="el7tlbdqs4tx9"
        className="animable"
      />
      <path
        d="M216.54,144.11a.1.1,0,0,1,.1-.1.11.11,0,0,1,.1.1v1.57a.94.94,0,1,0,1.87,0v-1.57a.1.1,0,0,1,.09-.1.1.1,0,0,1,.1.1v1.57a1.13,1.13,0,1,1-2.26,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "217.67px 145.41px",
        }}
        id="eloeuqvq18em"
        className="animable"
      />
      <path
        d="M207.61,158.63a.09.09,0,0,1-.1-.09.09.09,0,0,1,.1-.1h.88a.09.09,0,0,1,.08.1v1h0a.08.08,0,0,1,0,.07,1.43,1.43,0,1,1,0-2.21.09.09,0,0,1,0,.13.11.11,0,0,1-.14,0,1.21,1.21,0,0,0-.78-.27,1.23,1.23,0,1,0,.74,2.21v-.83Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "207.415px 158.505px",
        }}
        id="elao8fer7t1fv"
        className="animable"
      />
      <path
        d="M211.69,159.17h-1.28l-.24.66a.1.1,0,0,1-.09.06h0a.1.1,0,0,1-.06-.13l1-2.6a.1.1,0,0,1,.09-.06.13.13,0,0,1,.1.06l1,2.6a.11.11,0,0,1-.05.13h0a.09.09,0,0,1-.09-.06Zm-1.2-.2h1.13l-.57-1.5Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "211.114px 158.495px",
        }}
        id="elggzk3odezx6"
        className="animable"
      />
      <path
        d="M213.45,159.89h0a.1.1,0,0,1-.1-.1v-2.6a.11.11,0,0,1,.1-.1.1.1,0,0,1,.1.1v2.51h1.34a.1.1,0,0,1,.1.09.1.1,0,0,1-.1.1Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "214.17px 158.49px",
        }}
        id="elmoqdrl2kzmp"
        className="animable"
      />
      <path
        d="M216.3,159.89h0a.09.09,0,0,1-.09-.1v-2.6a.1.1,0,0,1,.09-.1.1.1,0,0,1,.1.1v2.51h1.34a.09.09,0,0,1,.1.09.09.09,0,0,1-.1.1Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "217.025px 158.49px",
        }}
        id="eldxggc57nmm7"
        className="animable"
      />
      <path
        d="M219.05,157.19a.1.1,0,0,1,.09-.1h1.44a.1.1,0,0,1,0,.2h-1.34v1.11h1.17a.09.09,0,0,1,.1.1.09.09,0,0,1-.1.09h-1.17v1.11h1.34a.09.09,0,0,1,.1.09.09.09,0,0,1-.1.1h-1.44a.09.09,0,0,1-.09-.1Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "219.865px 158.49px",
        }}
        id="el24salmjaw79i"
        className="animable"
      />
      <path
        d="M223.49,159.26a.49.49,0,0,0-.23-.42.72.72,0,0,0-.32-.14h-.7v1.1a.09.09,0,0,1-.1.1.1.1,0,0,1-.1-.1v-2.6a.11.11,0,0,1,.09-.1h.8a.8.8,0,0,1,.8.8.81.81,0,0,1-.46.73l0,0a.69.69,0,0,1,.37.62,1,1,0,0,0,0,.32s0,.07,0,.07a.12.12,0,0,1,.11.07.11.11,0,0,1-.07.12h-.05a.21.21,0,0,1-.18-.13A.94.94,0,0,1,223.49,159.26Zm-.56-2h-.69v1.21H223a.61.61,0,0,0,0-1.21Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "222.896px 158.5px",
        }}
        id="el5e2zcrhuk77"
        className="animable"
      />
      <path
        d="M225.87,158.55l-.94-1.29a.09.09,0,0,1,0-.13.08.08,0,0,1,.13,0l.88,1.21.88-1.21a.09.09,0,0,1,.13,0,.08.08,0,0,1,0,.13l-.93,1.28v1.25a.09.09,0,0,1-.1.1.09.09,0,0,1-.09-.1Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "225.943px 158.494px",
        }}
        id="el9jazavfjo85"
        className="animable"
      />
      <path
        d="M207.83,172.25h-1.27l-.25.66a.09.09,0,0,1-.09.06h0a.11.11,0,0,1-.05-.13l1-2.6a.11.11,0,0,1,.09-.06.11.11,0,0,1,.1.06l1,2.6a.1.1,0,0,1,0,.13h0a.11.11,0,0,1-.09-.06Zm-1.2-.2h1.13l-.56-1.5Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "207.274px 171.575px",
        }}
        id="elihw5c1fvyqe"
        className="animable"
      />
      <path
        d="M209.49,170.27a.1.1,0,0,1,.09-.1h.63a.69.69,0,0,1,.69.7.72.72,0,0,1-.27.55.79.79,0,0,1,.55.75.8.8,0,0,1-.8.8h-.8a.09.09,0,0,1-.09-.09Zm.72,1.1a.51.51,0,0,0,.5-.5.5.5,0,0,0-.5-.5h-.53v1Zm.17,1.41a.61.61,0,1,0,0-1.21h-.7v1.21Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "210.335px 171.57px",
        }}
        id="el2kjyuqns9nt"
        className="animable"
      />
      <path
        d="M213.69,170.13a1.42,1.42,0,1,1-1.35,1.42A1.38,1.38,0,0,1,213.69,170.13Zm0,2.65a1.23,1.23,0,1,0-1.15-1.23A1.19,1.19,0,0,0,213.69,172.78Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "213.76px 171.548px",
        }}
        id="el0fz59n0m95v"
        className="animable"
      />
      <path
        d="M216.29,170.27a.1.1,0,0,1,.09-.1.1.1,0,0,1,.1.1v1.57a.94.94,0,1,0,1.87,0v-1.57a.11.11,0,0,1,.1-.1.1.1,0,0,1,.1.1v1.57a1.13,1.13,0,1,1-2.26,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "217.42px 171.57px",
        }}
        id="elf1bpd7divkk"
        className="animable"
      />
      <path
        d="M220.74,170.37h-.84a.1.1,0,0,1,0-.2h1.88a.1.1,0,0,1,0,.2h-.85v2.51a.09.09,0,0,1-.09.09.1.1,0,0,1-.1-.09Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "220.84px 171.57px",
        }}
        id="el6gl3txcmlwm"
        className="animable"
      />
      <path
        d="M225,170.27a.1.1,0,0,1,.09-.1.1.1,0,0,1,.1.1v1.57a.94.94,0,1,0,1.87,0v-1.57a.1.1,0,0,1,.2,0v1.57a1.13,1.13,0,1,1-2.26,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "226.13px 171.572px",
        }}
        id="el6otzii7x4b5"
        className="animable"
      />
      <path
        d="M228.74,172.45a.08.08,0,0,1,.12,0,1.63,1.63,0,0,0,.92.36,1.06,1.06,0,0,0,.62-.19.53.53,0,0,0,.24-.41.47.47,0,0,0-.23-.39,2,2,0,0,0-.65-.23h0a1.72,1.72,0,0,1-.65-.23.56.56,0,0,1-.29-.49.63.63,0,0,1,.29-.52,1.14,1.14,0,0,1,.67-.21,1.52,1.52,0,0,1,.87.33.09.09,0,1,1-.1.14,1.42,1.42,0,0,0-.77-.29,1,1,0,0,0-.57.17.48.48,0,0,0-.22.38.43.43,0,0,0,.21.34,1.61,1.61,0,0,0,.6.21h0a1.75,1.75,0,0,1,.71.26.64.64,0,0,1,.31.53.72.72,0,0,1-.32.56,1.28,1.28,0,0,1-.72.22,1.73,1.73,0,0,1-1-.41A.09.09,0,0,1,228.74,172.45Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "229.773px 171.565px",
        }}
        id="el80fzvfi0eu6"
        className="animable"
      />
      <path
        d="M206.3,183.35a.11.11,0,0,1,.1-.1h1.44a.1.1,0,0,1,0,.2H206.5v1.11h1.17a.09.09,0,0,1,.1.1.09.09,0,0,1-.1.09H206.5V186a.09.09,0,0,1-.1.09.1.1,0,0,1-.1-.09Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "207.12px 184.67px",
        }}
        id="el5b9kf1yqw1r"
        className="animable"
      />
      <path
        d="M210.53,185.33h-1.27L209,186a.09.09,0,0,1-.09.06h0a.11.11,0,0,1,0-.13l1-2.6a.12.12,0,0,1,.1-.06.1.1,0,0,1,.09.06l1,2.6a.1.1,0,0,1-.05.13h0a.11.11,0,0,1-.09-.06Zm-1.2-.2h1.13l-.56-1.5Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "209.998px 184.665px",
        }}
        id="el2rktkg19t0x"
        className="animable"
      />
      <path
        d="M215,186.39a1,1,0,0,1-.63.24h0a1.42,1.42,0,0,1-.7-.23.63.63,0,0,0-.33-.09.74.74,0,0,0-.37.09h0a.11.11,0,0,1-.11,0h0a.08.08,0,0,1,0,0,.09.09,0,0,1,0-.13l.37-.17a1.42,1.42,0,1,1,1.52-1.41,1.44,1.44,0,0,1-.82,1.31l-.41.19a.81.81,0,0,1,.25.08,1.12,1.12,0,0,0,.6.21.81.81,0,0,0,.51-.2.1.1,0,0,1,.14,0A.11.11,0,0,1,215,186.39Zm-1.59-.53a1.22,1.22,0,0,0,0-2.44,1.22,1.22,0,0,0,0,2.44Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "213.468px 184.947px",
        }}
        id="elq3t3gfvmbxq"
        className="animable"
      />
      <path
        d="M206.18,198.61a.08.08,0,0,1,.12,0,1.6,1.6,0,0,0,.92.36,1.05,1.05,0,0,0,.61-.19.5.5,0,0,0,.24-.41.46.46,0,0,0-.22-.39,2.12,2.12,0,0,0-.65-.23h0a1.71,1.71,0,0,1-.66-.23.58.58,0,0,1-.29-.49.64.64,0,0,1,.3-.52,1.11,1.11,0,0,1,.67-.21,1.49,1.49,0,0,1,.86.33.08.08,0,1,1-.09.14,1.42,1.42,0,0,0-.77-.29,1,1,0,0,0-.57.17.48.48,0,0,0-.22.38.4.4,0,0,0,.21.34,1.61,1.61,0,0,0,.6.21h0a1.86,1.86,0,0,1,.72.26.63.63,0,0,1,.3.53.7.7,0,0,1-.31.56,1.28,1.28,0,0,1-.72.22,1.73,1.73,0,0,1-1-.41A.08.08,0,0,1,206.18,198.61Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "207.211px 197.725px",
        }}
        id="eln226xj2ptn"
        className="animable"
      />
      <path
        d="M211.21,197.83h-1.44V199a.1.1,0,0,1-.2,0v-2.61a.1.1,0,0,1,.2,0v1.21h1.44v-1.21a.1.1,0,0,1,.09-.1.1.1,0,0,1,.1.1V199a.09.09,0,0,1-.1.09.09.09,0,0,1-.09-.09Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "210.485px 197.694px",
        }}
        id="elm46kiahmd6s"
        className="animable"
      />
      <path
        d="M214.11,196.29a1.42,1.42,0,1,1-1.35,1.42A1.39,1.39,0,0,1,214.11,196.29Zm0,2.65a1.23,1.23,0,1,0-1.15-1.23A1.19,1.19,0,0,0,214.11,198.94Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "214.18px 197.708px",
        }}
        id="eli7f5733c6wp"
        className="animable"
      />
      <path
        d="M216.89,196.43a.1.1,0,0,1,.09-.1h.79a.8.8,0,0,1,0,1.6h-.69V199a.09.09,0,0,1-.1.09.09.09,0,0,1-.09-.09Zm.88,1.31a.61.61,0,0,0,.61-.61.6.6,0,0,0-.61-.6h-.69v1.21Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "217.73px 197.71px",
        }}
        id="elueicmnbdqfo"
        className="animable"
      />
      <rect
        x={523.49}
        y={121.01}
        width={9.65}
        height={1.55}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "528.315px 121.785px",
        }}
        id="elk4t3u9jmkr"
        className="animable"
      />
      <rect
        x={523.49}
        y={127.97}
        width={9.65}
        height={1.55}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "528.315px 128.745px",
        }}
        id="el8y0ddfrrozn"
        className="animable"
      />
      <rect
        x={523.49}
        y={124.53}
        width={9.65}
        height={1.55}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "528.315px 125.305px",
        }}
        id="elgp9ffpwafga"
        className="animable"
      />
      <rect
        x={189.06}
        y={100.42}
        width={361.97}
        height={5.67}
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "370.045px 103.255px",
        }}
        id="elfndxy5vl8a5"
        className="animable"
      />
      <path
        d="M193.57,103.25a1.41,1.41,0,1,1-1.41-1.4A1.41,1.41,0,0,1,193.57,103.25Z"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "192.16px 103.26px",
        }}
        id="eloky8au1m7y"
        className="animable"
      />
      <path
        d="M199.3,103.25a1.41,1.41,0,1,1-1.4-1.4A1.4,1.4,0,0,1,199.3,103.25Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "197.89px 103.26px",
        }}
        id="el65ijdlj1yvw"
        className="animable"
      />
      <path
        d="M205,103.25a1.41,1.41,0,1,1-1.4-1.4A1.41,1.41,0,0,1,205,103.25Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "203.59px 103.26px",
        }}
        id="elyqfrhmlq07"
        className="animable"
      />
      <rect
        x={544.81}
        y={106.09}
        width={6.22}
        height={189.05}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "547.92px 200.615px",
        }}
        id="elyogy5p6hloo"
        className="animable"
      />
      <path
        d="M547.92,180h0a1.61,1.61,0,0,1-1.61-1.61V108.94a1.61,1.61,0,0,1,1.61-1.61h0a1.61,1.61,0,0,1,1.61,1.61v69.47A1.61,1.61,0,0,1,547.92,180Z"
        style={{
          fill: "rgb(166, 166, 166)",
          transformOrigin: "547.92px 143.665px",
        }}
        id="elgyzz0cfv6c"
        className="animable"
      />
    </g>
    <g
      id="freepik--Laptop--inject-409"
      className="animable"
      style={{
        transformOrigin: "227.34px 337.93px",
      }}
    >
      <rect
        x={94.33}
        y={236.18}
        width={266.03}
        height={177.95}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "227.345px 325.155px",
        }}
        id="eleno9an36mtm"
        className="animable"
      />
      <rect
        x={96.26}
        y={238.35}
        width={262.16}
        height={173.61}
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "227.34px 325.155px",
        }}
        id="el9216fn605x6"
        className="animable"
      />
      <rect
        x={59.68}
        y={427.47}
        width={335.32}
        height={12.21}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "227.34px 433.575px",
        }}
        id="elmf6qfj14nhi"
        className="animable"
      />
      <polygon
        points="59.68 427.47 94.33 414.13 360.35 414.13 395 427.47 59.68 427.47"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "227.34px 420.8px",
        }}
        id="elhassaii2h8i"
        className="animable"
      />
      <polygon
        points="97.42 422.74 119.78 414.13 334.9 414.13 357.26 422.74 97.42 422.74"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "227.34px 418.435px",
        }}
        id="eljtsguli2k1"
        className="animable"
      />
      <rect
        x={111.07}
        y={251.54}
        width={232.53}
        height={147.23}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "227.335px 325.155px",
        }}
        id="elpt5b7mcnds"
        className="animable"
      />
      <rect
        x={111.07}
        y={257.2}
        width={232.53}
        height={141.57}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "227.335px 327.985px",
        }}
        id="elj20eb51e8u"
        className="animable"
      />
      <polygon
        points="170.94 359.03 192.52 358.89 214.11 358.81 257.27 358.74 300.44 358.81 322.02 358.89 343.6 359.03 322.02 359.17 300.44 359.25 257.27 359.31 214.11 359.25 192.52 359.17 170.94 359.03"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "257.27px 359.025px",
        }}
        id="elhclxgucetw7"
        className="animable"
      />
      <path
        d="M200.05,352.36a.77.77,0,1,0,0-1.54.7.7,0,0,0-.14,0l-.15,1.45A.72.72,0,0,0,200.05,352.36Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "200.29px 351.588px",
        }}
        id="el0n5gmvs07f8o"
        className="animable"
      />
      <path
        d="M202.36,353.13a.77.77,0,1,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "202.36px 353.9px",
        }}
        id="elsjj9x38ba8"
        className="animable"
      />
      <path
        d="M202.36,348.51a.77.77,0,1,0,.77.77A.77.77,0,0,0,202.36,348.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "202.36px 349.28px",
        }}
        id="elkti4fvmcxg"
        className="animable"
      />
      <path
        d="M200.82,347a.76.76,0,0,0-.43-.68l-.14,1.4A.75.75,0,0,0,200.82,347Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "200.535px 347.02px",
        }}
        id="elaofqk4mwp8v"
        className="animable"
      />
      <circle
        cx={202.36}
        cy={344.67}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "202.36px 344.67px",
        }}
        id="el1lps4qc2g8o"
        className="animable"
      />
      <path
        d="M200.79,342.49a.61.61,0,0,0,0-.13v0Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "200.792px 342.425px",
        }}
        id="elkjmxb96bal"
        className="animable"
      />
      <path
        d="M202.36,339.28a.77.77,0,1,0,.77.77A.77.77,0,0,0,202.36,339.28Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "202.36px 340.05px",
        }}
        id="elgfhfrwo271p"
        className="animable"
      />
      <path
        d="M202.36,334.67a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "202.36px 335.44px",
        }}
        id="eluoynwa2aqrd"
        className="animable"
      />
      <path
        d="M203.13,330.82a.78.78,0,0,0-.77-.77.76.76,0,0,0-.26.06l-.14,1.35a.68.68,0,0,0,.4.13A.77.77,0,0,0,203.13,330.82Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "202.545px 330.82px",
        }}
        id="elic56xdgvvk"
        className="animable"
      />
      <path
        d="M203.13,326.21a.74.74,0,0,0-.55-.72l-.15,1.47A.75.75,0,0,0,203.13,326.21Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "202.78px 326.225px",
        }}
        id="eldz1sd9ikt8k"
        className="animable"
      />
      <path
        d="M203.13,321.59a.71.71,0,0,0-.1-.35l-.09.84A.79.79,0,0,0,203.13,321.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "203.035px 321.66px",
        }}
        id="eljgdefridz9s"
        className="animable"
      />
      <path
        d="M204.61,306.2h.06a.76.76,0,0,0,.1-1.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "205.044px 305.441px",
        }}
        id="elydageljs21h"
        className="animable"
      />
      <path
        d="M207,353.13a.75.75,0,0,0-.73.61l1.31-.34A.76.76,0,0,0,207,353.13Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "206.925px 353.435px",
        }}
        id="el9blu7o1mkrv"
        className="animable"
      />
      <path
        d="M207,348.51a.77.77,0,1,0,.77.77A.77.77,0,0,0,207,348.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "207px 349.28px",
        }}
        id="ellx68sjro24"
        className="animable"
      />
      <path
        d="M204.67,350.82a.77.77,0,1,0,.77.77A.77.77,0,0,0,204.67,350.82Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "204.67px 351.59px",
        }}
        id="elbugh2q9o9e"
        className="animable"
      />
      <circle
        cx={206.97}
        cy={344.67}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "206.97px 344.67px",
        }}
        id="eln4daky7hxte"
        className="animable"
      />
      <path
        d="M204.67,346.21a.77.77,0,1,0,.77.77A.76.76,0,0,0,204.67,346.21Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "204.67px 346.98px",
        }}
        id="elngfie9igxx8"
        className="animable"
      />
      <path
        d="M207,339.28a.77.77,0,1,0,.77.77A.77.77,0,0,0,207,339.28Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "207px 340.05px",
        }}
        id="elkgp5kgo83q"
        className="animable"
      />
      <path
        d="M204.67,341.59a.77.77,0,1,0,.77.77A.77.77,0,0,0,204.67,341.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "204.67px 342.36px",
        }}
        id="el0nm7d0zbzy2o"
        className="animable"
      />
      <path
        d="M207,334.67a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "207px 335.44px",
        }}
        id="elqj0o0d5knnc"
        className="animable"
      />
      <path
        d="M204.67,337a.77.77,0,1,0,.77.77A.76.76,0,0,0,204.67,337Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "204.67px 337.77px",
        }}
        id="elau0w3nt5pbn"
        className="animable"
      />
      <circle
        cx={206.97}
        cy={330.82}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "206.97px 330.82px",
        }}
        id="elhfncrd0x9fd"
        className="animable"
      />
      <path
        d="M204.67,332.36a.77.77,0,1,0,.77.77A.77.77,0,0,0,204.67,332.36Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "204.67px 333.13px",
        }}
        id="el6647p43baa3"
        className="animable"
      />
      <path
        d="M207,325.44a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "207px 326.21px",
        }}
        id="el4accvaepc3k"
        className="animable"
      />
      <path
        d="M204.67,327.75a.77.77,0,1,0,.77.77A.76.76,0,0,0,204.67,327.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "204.67px 328.52px",
        }}
        id="el43qakn3bma3"
        className="animable"
      />
      <path
        d="M207,320.83a.77.77,0,1,0,.77.76A.76.76,0,0,0,207,320.83Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "207px 321.6px",
        }}
        id="elnadnta64n1"
        className="animable"
      />
      <path
        d="M204.67,323.13a.77.77,0,1,0,.77.77A.77.77,0,0,0,204.67,323.13Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "204.67px 323.9px",
        }}
        id="elks9vlvttg6r"
        className="animable"
      />
      <circle
        cx={206.97}
        cy={316.98}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "206.97px 316.98px",
        }}
        id="elq9vlxct19p"
        className="animable"
      />
      <path
        d="M204.67,318.52a.77.77,0,1,0,.77.77A.76.76,0,0,0,204.67,318.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "204.67px 319.29px",
        }}
        id="elefrz8h0dirm"
        className="animable"
      />
      <path
        d="M207,311.6a.77.77,0,1,0,.77.76A.76.76,0,0,0,207,311.6Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "207px 312.37px",
        }}
        id="elttlhwwkphzj"
        className="animable"
      />
      <path
        d="M204.67,313.9a.77.77,0,1,0,.77.77A.77.77,0,0,0,204.67,313.9Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "204.67px 314.67px",
        }}
        id="elaksalmdknna"
        className="animable"
      />
      <path
        d="M205.44,310.06a.77.77,0,0,0-.77-.77.72.72,0,0,0-.39.12l-.13,1.21a.8.8,0,0,0,.52.2A.77.77,0,0,0,205.44,310.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "204.795px 310.055px",
        }}
        id="eliybkdznhmua"
        className="animable"
      />
      <path
        d="M207,307a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "207px 307.77px",
        }}
        id="elo617ms0ys9b"
        className="animable"
      />
      <circle
        cx={206.97}
        cy={303.13}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "206.97px 303.13px",
        }}
        id="els2sah76fy5n"
        className="animable"
      />
      <path
        d="M205.12,301.43a.74.74,0,0,0,.32-.6.72.72,0,0,0-.21-.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "205.28px 300.875px",
        }}
        id="elw4vyrgs6ivi"
        className="animable"
      />
      <path
        d="M207,297.75a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "207px 298.52px",
        }}
        id="elglea3jba6zo"
        className="animable"
      />
      <path
        d="M207,293.14a.77.77,0,1,0,.77.76A.76.76,0,0,0,207,293.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "207px 293.91px",
        }}
        id="el246poqdtaam"
        className="animable"
      />
      <path
        d="M207,290.06a.77.77,0,1,0,0-1.54.76.76,0,0,0-.52.21l-.1,1A.74.74,0,0,0,207,290.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "207.075px 289.29px",
        }}
        id="el0teyhtgc2pr"
        className="animable"
      />
      <path
        d="M207,285.44a.77.77,0,1,0,0-1.53h0l-.16,1.5A1,1,0,0,0,207,285.44Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "207.349px 284.675px",
        }}
        id="elonfvbrk12om"
        className="animable"
      />
      <path
        d="M208.84,283a.76.76,0,0,0,.44.16h0Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "209.06px 283.08px",
        }}
        id="eljn91u2pobml"
        className="animable"
      />
      <path
        d="M211.59,348.51a.77.77,0,1,0,.77.77A.77.77,0,0,0,211.59,348.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "211.59px 349.28px",
        }}
        id="el9iniqvy3m2w"
        className="animable"
      />
      <circle
        cx={209.28}
        cy={351.59}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "209.28px 351.59px",
        }}
        id="el3kk3oiqrz3t"
        className="animable"
      />
      <path
        d="M211.59,343.9a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "211.59px 344.67px",
        }}
        id="el62bd093xhaj"
        className="animable"
      />
      <path
        d="M209.28,346.21a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "209.28px 346.975px",
        }}
        id="el1czbn6l7o54"
        className="animable"
      />
      <path
        d="M211.59,339.28a.77.77,0,1,0,.77.77A.77.77,0,0,0,211.59,339.28Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "211.59px 340.05px",
        }}
        id="elagtwpf83l6i"
        className="animable"
      />
      <path
        d="M209.28,341.59a.77.77,0,1,0,.77.77A.78.78,0,0,0,209.28,341.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "209.28px 342.36px",
        }}
        id="elfk8iy6nlzs"
        className="animable"
      />
      <path
        d="M211.59,334.67a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "211.59px 335.44px",
        }}
        id="el2eiiavnfsu3"
        className="animable"
      />
      <circle
        cx={209.28}
        cy={337.75}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "209.28px 337.75px",
        }}
        id="elb4ohbn28fk9"
        className="animable"
      />
      <path
        d="M211.59,330.05a.77.77,0,1,0,.77.77A.77.77,0,0,0,211.59,330.05Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "211.59px 330.82px",
        }}
        id="eljvarxsdh48"
        className="animable"
      />
      <path
        d="M209.28,332.36a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "209.28px 333.13px",
        }}
        id="elq5d6x2k27t"
        className="animable"
      />
      <path
        d="M211.59,325.44a.77.77,0,1,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "211.59px 326.21px",
        }}
        id="el90c9e03wx6v"
        className="animable"
      />
      <path
        d="M209.28,327.75a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "209.28px 328.515px",
        }}
        id="el19k8zkwo7d9"
        className="animable"
      />
      <path
        d="M211.59,320.83a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "211.59px 321.595px",
        }}
        id="elnhfd64tuy3"
        className="animable"
      />
      <circle
        cx={209.28}
        cy={323.9}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "209.28px 323.9px",
        }}
        id="eldbnzj2sx32j"
        className="animable"
      />
      <path
        d="M211.59,316.21a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "211.59px 316.98px",
        }}
        id="elzkqdhj2z8fn"
        className="animable"
      />
      <path
        d="M209.28,318.52a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "209.28px 319.285px",
        }}
        id="ele80nsco24mf"
        className="animable"
      />
      <path
        d="M211.59,311.6a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "211.59px 312.365px",
        }}
        id="elurn7xfewtc8"
        className="animable"
      />
      <path
        d="M209.28,313.9a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "209.28px 314.67px",
        }}
        id="elbtldabewtao"
        className="animable"
      />
      <path
        d="M211.59,307a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "211.59px 307.77px",
        }}
        id="el6bev54ura59"
        className="animable"
      />
      <circle
        cx={209.28}
        cy={310.06}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "209.28px 310.06px",
        }}
        id="el849xvnld4dm"
        className="animable"
      />
      <path
        d="M211.59,302.37a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "211.59px 303.135px",
        }}
        id="ellj1e074nyu9"
        className="animable"
      />
      <path
        d="M209.28,304.67a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "209.28px 305.44px",
        }}
        id="elup8dg1x0pbm"
        className="animable"
      />
      <path
        d="M211.59,297.75a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "211.59px 298.52px",
        }}
        id="elj5jshri1z3j"
        className="animable"
      />
      <path
        d="M209.28,300.06a.77.77,0,1,0,.77.77A.77.77,0,0,0,209.28,300.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "209.28px 300.83px",
        }}
        id="el6mao80nqzvs"
        className="animable"
      />
      <path
        d="M211.59,293.14a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "211.59px 293.905px",
        }}
        id="elk1wvo99yj1"
        className="animable"
      />
      <circle
        cx={209.28}
        cy={296.21}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "209.28px 296.21px",
        }}
        id="el6qqhboygnj3"
        className="animable"
      />
      <path
        d="M211.59,288.52a.77.77,0,1,0,.77.77A.77.77,0,0,0,211.59,288.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "211.59px 289.29px",
        }}
        id="eljbxj3duufto"
        className="animable"
      />
      <path
        d="M209.28,290.83a.77.77,0,1,0,.77.77A.77.77,0,0,0,209.28,290.83Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "209.28px 291.6px",
        }}
        id="elxf0v5ifi1xl"
        className="animable"
      />
      <path
        d="M211.59,283.91a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "211.59px 284.675px",
        }}
        id="el6ym8lnuy2ri"
        className="animable"
      />
      <path
        d="M209.28,286.21a.77.77,0,1,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "209.28px 286.98px",
        }}
        id="elrx5zi17tqw9"
        className="animable"
      />
      <path
        d="M216.2,348.51a.77.77,0,1,0,.77.77A.77.77,0,0,0,216.2,348.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "216.2px 349.28px",
        }}
        id="el3g1zisoxkrj"
        className="animable"
      />
      <path
        d="M214.66,351.59a.76.76,0,0,0-.76-.77.77.77,0,0,0-.77.77.75.75,0,0,0,.1.36Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "213.895px 351.385px",
        }}
        id="elphdqmesx7a"
        className="animable"
      />
      <circle
        cx={216.2}
        cy={344.67}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "216.2px 344.67px",
        }}
        id="elpb1y2nlwrac"
        className="animable"
      />
      <path
        d="M213.9,346.21a.77.77,0,1,0,.76.77A.76.76,0,0,0,213.9,346.21Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "213.89px 346.98px",
        }}
        id="elkrqgwhsxl8h"
        className="animable"
      />
      <path
        d="M216.2,339.28a.77.77,0,1,0,.77.77A.77.77,0,0,0,216.2,339.28Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "216.2px 340.05px",
        }}
        id="el2urw9pgb40b"
        className="animable"
      />
      <path
        d="M213.9,341.59a.77.77,0,1,0,.76.77A.77.77,0,0,0,213.9,341.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "213.89px 342.36px",
        }}
        id="elvd8q457e94k"
        className="animable"
      />
      <path
        d="M216.2,334.67a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "216.2px 335.44px",
        }}
        id="elak6dm6e8j06"
        className="animable"
      />
      <circle
        cx={213.9}
        cy={337.75}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "213.9px 337.75px",
        }}
        id="eldfdwjdm6viu"
        className="animable"
      />
      <circle
        cx={216.2}
        cy={330.82}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "216.2px 330.82px",
        }}
        id="elag0tbe6d9kh"
        className="animable"
      />
      <path
        d="M213.9,332.36a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "213.9px 333.13px",
        }}
        id="elrj93b5nzir"
        className="animable"
      />
      <path
        d="M216.2,325.44a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "216.2px 326.21px",
        }}
        id="el4htov0cnkri"
        className="animable"
      />
      <path
        d="M213.9,327.75a.77.77,0,1,0,.76.77A.76.76,0,0,0,213.9,327.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "213.89px 328.52px",
        }}
        id="elp20pldoit4"
        className="animable"
      />
      <path
        d="M216.2,320.83a.77.77,0,1,0,.77.76A.76.76,0,0,0,216.2,320.83Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "216.2px 321.6px",
        }}
        id="elosipfzy8am"
        className="animable"
      />
      <circle
        cx={213.9}
        cy={323.9}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "213.9px 323.9px",
        }}
        id="elnfjcr2beck"
        className="animable"
      />
      <circle
        cx={216.2}
        cy={316.98}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "216.2px 316.98px",
        }}
        id="elcoei73mkuda"
        className="animable"
      />
      <path
        d="M213.9,318.52a.77.77,0,1,0,.76.77A.76.76,0,0,0,213.9,318.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "213.89px 319.29px",
        }}
        id="el1kl9ihngcr9"
        className="animable"
      />
      <path
        d="M216.2,311.6a.77.77,0,1,0,.77.76A.76.76,0,0,0,216.2,311.6Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "216.2px 312.37px",
        }}
        id="elbuu7brlhzj9"
        className="animable"
      />
      <path
        d="M213.9,313.9a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "213.9px 314.67px",
        }}
        id="eltqmlocr0u4"
        className="animable"
      />
      <path
        d="M216.2,307a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "216.2px 307.77px",
        }}
        id="elide5zcohgw9"
        className="animable"
      />
      <circle
        cx={213.9}
        cy={310.06}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "213.9px 310.06px",
        }}
        id="eldd9dom6y4d5"
        className="animable"
      />
      <circle
        cx={216.2}
        cy={303.13}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "216.2px 303.13px",
        }}
        id="el2nz7uti2zip"
        className="animable"
      />
      <path
        d="M213.9,304.67a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "213.9px 305.44px",
        }}
        id="elnsoe0b4wbza"
        className="animable"
      />
      <path
        d="M216.2,297.75a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "216.2px 298.52px",
        }}
        id="elxf9k44vebxm"
        className="animable"
      />
      <path
        d="M213.9,300.06a.77.77,0,1,0,.76.77A.76.76,0,0,0,213.9,300.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "213.89px 300.83px",
        }}
        id="elfh1vw4sh69"
        className="animable"
      />
      <path
        d="M216.2,293.14a.77.77,0,1,0,.77.76A.76.76,0,0,0,216.2,293.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "216.2px 293.91px",
        }}
        id="elj7235bjf12"
        className="animable"
      />
      <circle
        cx={213.9}
        cy={296.21}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "213.9px 296.21px",
        }}
        id="elbi5k4haoo3n"
        className="animable"
      />
      <circle
        cx={216.2}
        cy={289.29}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "216.2px 289.29px",
        }}
        id="elcc5dxrfc92t"
        className="animable"
      />
      <path
        d="M213.9,290.83a.77.77,0,1,0,.76.77A.76.76,0,0,0,213.9,290.83Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "213.89px 291.6px",
        }}
        id="el2qtduy4e0ck"
        className="animable"
      />
      <path
        d="M216.2,285.44a.7.7,0,0,0,.25-.05l-.85-.27A.74.74,0,0,0,216.2,285.44Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "216.025px 285.28px",
        }}
        id="elagv9w2mp149"
        className="animable"
      />
      <path
        d="M213.9,286.21a.77.77,0,1,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "213.9px 286.98px",
        }}
        id="elkr02gzttsej"
        className="animable"
      />
      <path
        d="M221.59,349.28a.77.77,0,1,0-.85.76l.5-.13A.77.77,0,0,0,221.59,349.28Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "220.82px 349.272px",
        }}
        id="el6z3p84g38ra"
        className="animable"
      />
      <circle
        cx={220.82}
        cy={344.67}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "220.82px 344.67px",
        }}
        id="elahh7yafu3cn"
        className="animable"
      />
      <path
        d="M218.51,346.21a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "218.51px 346.975px",
        }}
        id="eltzd9oh1wwn8"
        className="animable"
      />
      <path
        d="M220.82,339.28a.77.77,0,1,0,.77.77A.77.77,0,0,0,220.82,339.28Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "220.82px 340.05px",
        }}
        id="el4x0dotaqu5f"
        className="animable"
      />
      <path
        d="M218.51,341.59a.77.77,0,1,0,.77.77A.78.78,0,0,0,218.51,341.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "218.51px 342.36px",
        }}
        id="ela03yirevs2"
        className="animable"
      />
      <path
        d="M220.82,334.67a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "220.82px 335.44px",
        }}
        id="elcsey1ufcw86"
        className="animable"
      />
      <path
        d="M218.51,337a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "218.51px 337.765px",
        }}
        id="elgu922377fzd"
        className="animable"
      />
      <circle
        cx={220.82}
        cy={330.82}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "220.82px 330.82px",
        }}
        id="elv9w5jfzdve"
        className="animable"
      />
      <path
        d="M218.51,332.36a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "218.51px 333.13px",
        }}
        id="el5ms948fhfzg"
        className="animable"
      />
      <path
        d="M220.82,325.44a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "220.82px 326.21px",
        }}
        id="ellutfecj2ph"
        className="animable"
      />
      <path
        d="M218.51,327.75a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "218.51px 328.515px",
        }}
        id="elltothuw5ir"
        className="animable"
      />
      <path
        d="M220.82,320.83a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "220.82px 321.595px",
        }}
        id="elgnni0sanm2h"
        className="animable"
      />
      <path
        d="M218.51,323.13a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "218.51px 323.9px",
        }}
        id="el1512cpvvfdcj"
        className="animable"
      />
      <circle
        cx={220.82}
        cy={316.98}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "220.82px 316.98px",
        }}
        id="eluieybcbj2wg"
        className="animable"
      />
      <path
        d="M218.51,318.52a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "218.51px 319.285px",
        }}
        id="el3ep0pbyoiid"
        className="animable"
      />
      <path
        d="M220.82,311.6a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "220.82px 312.365px",
        }}
        id="elcm0zdu9e977"
        className="animable"
      />
      <path
        d="M218.51,313.9a.77.77,0,1,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "218.51px 314.67px",
        }}
        id="elu7tb7gkh3t"
        className="animable"
      />
      <path
        d="M220.82,307a.77.77,0,1,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "220.82px 307.77px",
        }}
        id="elc9one49piw"
        className="animable"
      />
      <path
        d="M218.51,309.29a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "218.51px 310.055px",
        }}
        id="elkzickdx2r2"
        className="animable"
      />
      <circle
        cx={220.82}
        cy={303.13}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "220.82px 303.13px",
        }}
        id="elphw0lj6a9f9"
        className="animable"
      />
      <path
        d="M218.51,304.67a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "218.51px 305.44px",
        }}
        id="elzfks4a7hfyh"
        className="animable"
      />
      <path
        d="M220.82,297.75a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "220.82px 298.52px",
        }}
        id="elkrriefi974b"
        className="animable"
      />
      <path
        d="M218.51,300.06a.77.77,0,1,0,.77.77A.77.77,0,0,0,218.51,300.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "218.51px 300.83px",
        }}
        id="elr0xihq3e0kj"
        className="animable"
      />
      <path
        d="M220.82,293.14a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "220.82px 293.905px",
        }}
        id="elegnfw49sksd"
        className="animable"
      />
      <path
        d="M218.51,295.44a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "218.51px 296.21px",
        }}
        id="elbtjq0wo6gue"
        className="animable"
      />
      <circle
        cx={220.82}
        cy={289.29}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "220.82px 289.29px",
        }}
        id="elwnylb0upobf"
        className="animable"
      />
      <path
        d="M223.13,287.75a.74.74,0,0,0,.38-.12l-1.09-.34A.77.77,0,0,0,223.13,287.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "222.965px 287.52px",
        }}
        id="el68ygxth94ot"
        className="animable"
      />
      <path
        d="M218.51,290.83a.77.77,0,1,0,.77.77A.77.77,0,0,0,218.51,290.83Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "218.51px 291.6px",
        }}
        id="el5t9pa3rd5jn"
        className="animable"
      />
      <path
        d="M218.51,286.21a.77.77,0,1,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "218.51px 286.98px",
        }}
        id="elp3kyj9adnu"
        className="animable"
      />
      <path
        d="M224.72,349l1.21-.31a.73.73,0,0,0-.5-.2A.77.77,0,0,0,224.72,349Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "225.325px 348.745px",
        }}
        id="ells5tfkrkdw"
        className="animable"
      />
      <circle
        cx={225.43}
        cy={344.67}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "225.43px 344.67px",
        }}
        id="elsjx042bdgge"
        className="animable"
      />
      <path
        d="M223.13,346.21a.77.77,0,1,0,.76.77A.76.76,0,0,0,223.13,346.21Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "223.12px 346.98px",
        }}
        id="eleouvow41gyw"
        className="animable"
      />
      <path
        d="M225.43,339.28a.77.77,0,1,0,.77.77A.77.77,0,0,0,225.43,339.28Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "225.43px 340.05px",
        }}
        id="eluwmo21hu1w8"
        className="animable"
      />
      <path
        d="M223.13,341.59a.77.77,0,1,0,.76.77A.77.77,0,0,0,223.13,341.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "223.12px 342.36px",
        }}
        id="elu5u52tynf1"
        className="animable"
      />
      <path
        d="M225.43,334.67a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "225.43px 335.44px",
        }}
        id="elpz6p4nqgml9"
        className="animable"
      />
      <path
        d="M223.13,337a.77.77,0,1,0,.76.77A.76.76,0,0,0,223.13,337Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "223.12px 337.77px",
        }}
        id="el9gxwhqg9dz"
        className="animable"
      />
      <circle
        cx={225.43}
        cy={330.82}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "225.43px 330.82px",
        }}
        id="el0lkj63n72m"
        className="animable"
      />
      <path
        d="M223.13,332.36a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "223.13px 333.13px",
        }}
        id="elfedzsrjktl"
        className="animable"
      />
      <path
        d="M225.43,325.44a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "225.43px 326.21px",
        }}
        id="elhlgccqr3j1f"
        className="animable"
      />
      <path
        d="M223.13,327.75a.77.77,0,1,0,.76.77A.76.76,0,0,0,223.13,327.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "223.12px 328.52px",
        }}
        id="elbvx25j2rpc"
        className="animable"
      />
      <path
        d="M225.43,320.83a.77.77,0,1,0,.77.76A.76.76,0,0,0,225.43,320.83Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "225.43px 321.6px",
        }}
        id="el4z1wxooa0a6"
        className="animable"
      />
      <path
        d="M223.13,323.13a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "223.13px 323.9px",
        }}
        id="el1l9llsxhhzk"
        className="animable"
      />
      <circle
        cx={225.43}
        cy={316.98}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "225.43px 316.98px",
        }}
        id="eltyv8eq72xz"
        className="animable"
      />
      <path
        d="M223.13,318.52a.77.77,0,1,0,.76.77A.76.76,0,0,0,223.13,318.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "223.12px 319.29px",
        }}
        id="elgd1kcrc36k6"
        className="animable"
      />
      <path
        d="M225.43,311.6a.77.77,0,1,0,.77.76A.76.76,0,0,0,225.43,311.6Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "225.43px 312.37px",
        }}
        id="elluyr6b0kjxp"
        className="animable"
      />
      <path
        d="M223.13,313.9a.77.77,0,1,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "223.13px 314.67px",
        }}
        id="elhmly539305p"
        className="animable"
      />
      <path
        d="M225.43,307a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "225.43px 307.77px",
        }}
        id="elbj4bjb33q8l"
        className="animable"
      />
      <path
        d="M223.13,309.29a.77.77,0,1,0,.76.77A.76.76,0,0,0,223.13,309.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "223.12px 310.06px",
        }}
        id="el6p9bno36kth"
        className="animable"
      />
      <circle
        cx={225.43}
        cy={303.13}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "225.43px 303.13px",
        }}
        id="elewajxlrsjms"
        className="animable"
      />
      <path
        d="M223.13,304.67a.77.77,0,1,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "223.13px 305.44px",
        }}
        id="ell6ellci591g"
        className="animable"
      />
      <path
        d="M225.43,297.75a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "225.43px 298.52px",
        }}
        id="elf80kyrp6dss"
        className="animable"
      />
      <path
        d="M223.13,300.06a.77.77,0,1,0,.76.77A.76.76,0,0,0,223.13,300.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "223.12px 300.83px",
        }}
        id="elqweo03ua1ye"
        className="animable"
      />
      <path
        d="M225.43,293.14a.77.77,0,1,0,.77.76A.76.76,0,0,0,225.43,293.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "225.43px 293.91px",
        }}
        id="elb0uf7ao39l"
        className="animable"
      />
      <path
        d="M223.13,295.44a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "223.13px 296.21px",
        }}
        id="elf8jrjwbclso"
        className="animable"
      />
      <circle
        cx={225.43}
        cy={289.29}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "225.43px 289.29px",
        }}
        id="elcmgozz8drx"
        className="animable"
      />
      <path
        d="M223.13,290.83a.77.77,0,1,0,.76.77A.76.76,0,0,0,223.13,290.83Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "223.12px 291.6px",
        }}
        id="elq0ltdfz4sjo"
        className="animable"
      />
      <path
        d="M230.05,343.9a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "230.05px 344.67px",
        }}
        id="elm7z3g0r7ndd"
        className="animable"
      />
      <path
        d="M227.74,346.21a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "227.74px 346.975px",
        }}
        id="elgb9wauh8cjl"
        className="animable"
      />
      <path
        d="M230.05,339.28a.77.77,0,1,0,.77.77A.77.77,0,0,0,230.05,339.28Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "230.05px 340.05px",
        }}
        id="el69s0zcr5dus"
        className="animable"
      />
      <path
        d="M227.74,341.59a.77.77,0,1,0,.77.77A.78.78,0,0,0,227.74,341.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "227.74px 342.36px",
        }}
        id="el5jsnqwv8lew"
        className="animable"
      />
      <path
        d="M230.05,334.67a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "230.05px 335.44px",
        }}
        id="el6i76yrvsg1v"
        className="animable"
      />
      <path
        d="M227.74,337a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "227.74px 337.765px",
        }}
        id="elx7jtqzadbvd"
        className="animable"
      />
      <path
        d="M230.05,330.05a.77.77,0,1,0,.77.77A.77.77,0,0,0,230.05,330.05Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "230.05px 330.82px",
        }}
        id="el6ho1jkek27c"
        className="animable"
      />
      <path
        d="M227.74,332.36a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "227.74px 333.13px",
        }}
        id="elaa3zid0plb9"
        className="animable"
      />
      <path
        d="M230.05,325.44a.77.77,0,1,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "230.05px 326.21px",
        }}
        id="eldh81pji8hpk"
        className="animable"
      />
      <path
        d="M227.74,327.75a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "227.74px 328.515px",
        }}
        id="elgpvbn8u7r5o"
        className="animable"
      />
      <path
        d="M230.05,320.83a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "230.05px 321.595px",
        }}
        id="el2xypjeoz526"
        className="animable"
      />
      <path
        d="M227.74,323.13a.77.77,0,1,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "227.74px 323.9px",
        }}
        id="elvfturwmd9b"
        className="animable"
      />
      <path
        d="M230.05,316.21a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "230.05px 316.98px",
        }}
        id="elbe46zzjni2u"
        className="animable"
      />
      <path
        d="M227.74,318.52a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "227.74px 319.285px",
        }}
        id="elgs8btf5xbh9"
        className="animable"
      />
      <path
        d="M230.05,311.6a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "230.05px 312.365px",
        }}
        id="elzammhhykshi"
        className="animable"
      />
      <path
        d="M227.74,313.9a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "227.74px 314.67px",
        }}
        id="el95377574jho"
        className="animable"
      />
      <path
        d="M230.05,307a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "230.05px 307.77px",
        }}
        id="elgiz49520dvg"
        className="animable"
      />
      <path
        d="M227.74,309.29a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "227.74px 310.055px",
        }}
        id="elbrxkr6l9dsm"
        className="animable"
      />
      <path
        d="M230.05,302.37a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "230.05px 303.135px",
        }}
        id="el7e23pwtqioo"
        className="animable"
      />
      <path
        d="M227.74,304.67a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "227.74px 305.44px",
        }}
        id="el45fgbju77n5"
        className="animable"
      />
      <path
        d="M230.05,297.75a.77.77,0,1,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "230.05px 298.52px",
        }}
        id="elm6rapiptndf"
        className="animable"
      />
      <path
        d="M227.74,300.06a.77.77,0,1,0,.77.77A.77.77,0,0,0,227.74,300.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "227.74px 300.83px",
        }}
        id="el60bzjxiqfh"
        className="animable"
      />
      <path
        d="M230.05,293.14a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "230.05px 293.905px",
        }}
        id="eli5hzvqx7qfo"
        className="animable"
      />
      <path
        d="M227.74,295.44a.77.77,0,1,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "227.74px 296.21px",
        }}
        id="elb8lj6vg3d85"
        className="animable"
      />
      <path
        d="M230.05,290.06a.76.76,0,0,0,.49-.19l-1.22-.39A.75.75,0,0,0,230.05,290.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "229.93px 289.77px",
        }}
        id="el6ii7k4xzy4f"
        className="animable"
      />
      <path
        d="M227.74,290.83a.77.77,0,1,0,.77.77A.77.77,0,0,0,227.74,290.83Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "227.74px 291.6px",
        }}
        id="elmy92wp8b89g"
        className="animable"
      />
      <circle
        cx={234.66}
        cy={344.67}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "234.66px 344.67px",
        }}
        id="elczzf111ru7"
        className="animable"
      />
      <path
        d="M232.36,346.21a.76.76,0,0,0-.77.77.81.81,0,0,0,0,.27l1.46-.37A.74.74,0,0,0,232.36,346.21Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "232.314px 346.73px",
        }}
        id="el6q0lxbzzrf"
        className="animable"
      />
      <path
        d="M234.66,339.28a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "234.66px 340.05px",
        }}
        id="elbu1467m8r1"
        className="animable"
      />
      <path
        d="M232.36,341.59a.77.77,0,1,0,.76.77A.77.77,0,0,0,232.36,341.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "232.35px 342.36px",
        }}
        id="elpxx235lrwg"
        className="animable"
      />
      <path
        d="M234.66,334.67a.77.77,0,1,0,.77.77A.77.77,0,0,0,234.66,334.67Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "234.66px 335.44px",
        }}
        id="eljttshxhe8q"
        className="animable"
      />
      <circle
        cx={232.36}
        cy={337.75}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "232.36px 337.75px",
        }}
        id="els1yvhltwbnr"
        className="animable"
      />
      <circle
        cx={234.66}
        cy={330.82}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "234.66px 330.82px",
        }}
        id="el5gh1obvht5"
        className="animable"
      />
      <path
        d="M232.36,332.36a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "232.36px 333.13px",
        }}
        id="elx0dvpefhm4"
        className="animable"
      />
      <path
        d="M234.66,325.44a.77.77,0,1,0,.77.77A.77.77,0,0,0,234.66,325.44Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "234.66px 326.21px",
        }}
        id="el55wjq18lxr3"
        className="animable"
      />
      <path
        d="M232.36,327.75a.77.77,0,1,0,.76.77A.76.76,0,0,0,232.36,327.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "232.35px 328.52px",
        }}
        id="el3q48t5oyj3w"
        className="animable"
      />
      <path
        d="M234.66,320.83a.77.77,0,1,0,.77.76A.77.77,0,0,0,234.66,320.83Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "234.66px 321.6px",
        }}
        id="elw3do0sxtsl"
        className="animable"
      />
      <circle
        cx={232.36}
        cy={323.9}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "232.36px 323.9px",
        }}
        id="ell3tcbovprc"
        className="animable"
      />
      <circle
        cx={234.66}
        cy={316.98}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "234.66px 316.98px",
        }}
        id="el0y6g1v6y1zma"
        className="animable"
      />
      <path
        d="M232.36,318.52a.77.77,0,1,0,.76.77A.76.76,0,0,0,232.36,318.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "232.35px 319.29px",
        }}
        id="elqyauyd5cgcn"
        className="animable"
      />
      <path
        d="M234.66,311.6a.77.77,0,1,0,.77.76A.77.77,0,0,0,234.66,311.6Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "234.66px 312.37px",
        }}
        id="elo645abk7gcr"
        className="animable"
      />
      <path
        d="M232.36,313.9a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "232.36px 314.67px",
        }}
        id="el73q81akn3r3"
        className="animable"
      />
      <path
        d="M234.66,307a.77.77,0,1,0,.77.77A.77.77,0,0,0,234.66,307Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "234.66px 307.77px",
        }}
        id="elsnkkji3jxd"
        className="animable"
      />
      <circle
        cx={232.36}
        cy={310.06}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "232.36px 310.06px",
        }}
        id="ell5mlddkma1o"
        className="animable"
      />
      <circle
        cx={234.66}
        cy={303.13}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "234.66px 303.13px",
        }}
        id="ela8dmb1cdwh"
        className="animable"
      />
      <path
        d="M232.36,304.67a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "232.36px 305.44px",
        }}
        id="el1y04holvi67"
        className="animable"
      />
      <path
        d="M234.66,297.75a.77.77,0,1,0,.77.77A.77.77,0,0,0,234.66,297.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "234.66px 298.52px",
        }}
        id="elcpske73s9uq"
        className="animable"
      />
      <path
        d="M232.36,300.06a.77.77,0,1,0,.76.77A.76.76,0,0,0,232.36,300.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "232.35px 300.83px",
        }}
        id="elc1ieoe065jd"
        className="animable"
      />
      <path
        d="M234.66,293.14a.77.77,0,1,0,.77.76A.77.77,0,0,0,234.66,293.14Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "234.66px 293.91px",
        }}
        id="elf3rn4awch4e"
        className="animable"
      />
      <path
        d="M237,292.37a.77.77,0,0,0,.58-.28l-1.33-.42A.75.75,0,0,0,237,292.37Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "236.915px 292.02px",
        }}
        id="el3npl5jqixzd"
        className="animable"
      />
      <circle
        cx={232.36}
        cy={296.21}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "232.36px 296.21px",
        }}
        id="elfnsnqnhoyh"
        className="animable"
      />
      <path
        d="M232.36,290.83a.77.77,0,1,0,.76.77A.76.76,0,0,0,232.36,290.83Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "232.35px 291.6px",
        }}
        id="elft1vlqizktt"
        className="animable"
      />
      <path
        d="M239.28,343.9a.76.76,0,0,0-.77.77.78.78,0,0,0,.47.71l.88-.23a.76.76,0,0,0,.19-.48A.77.77,0,0,0,239.28,343.9Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "239.28px 344.64px",
        }}
        id="el5bal2di3cdd"
        className="animable"
      />
      <path
        d="M239.28,339.28a.77.77,0,1,0,.77.77A.77.77,0,0,0,239.28,339.28Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "239.28px 340.05px",
        }}
        id="eltho167zvedl"
        className="animable"
      />
      <path
        d="M237,341.59a.77.77,0,1,0,.77.77A.78.78,0,0,0,237,341.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "237px 342.36px",
        }}
        id="elf1ttqayrl5c"
        className="animable"
      />
      <path
        d="M239.28,334.67a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "239.28px 335.44px",
        }}
        id="elfugelxk5vrj"
        className="animable"
      />
      <path
        d="M237,337a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "237px 337.765px",
        }}
        id="el9c2bi6kujse"
        className="animable"
      />
      <circle
        cx={239.28}
        cy={330.82}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "239.28px 330.82px",
        }}
        id="elin9c535ncxi"
        className="animable"
      />
      <path
        d="M237,332.36a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "237px 333.13px",
        }}
        id="el96szniv8zbe"
        className="animable"
      />
      <path
        d="M239.28,325.44a.77.77,0,1,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "239.28px 326.21px",
        }}
        id="elpk114bcilkf"
        className="animable"
      />
      <path
        d="M237,327.75a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "237px 328.515px",
        }}
        id="el3zcdpmbg3nq"
        className="animable"
      />
      <path
        d="M239.28,320.83a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "239.28px 321.595px",
        }}
        id="elqvrl11s8lf"
        className="animable"
      />
      <path
        d="M237,323.13a.77.77,0,1,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "237px 323.9px",
        }}
        id="el5ihxgbtw74u"
        className="animable"
      />
      <circle
        cx={239.28}
        cy={316.98}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "239.28px 316.98px",
        }}
        id="el10w1uj4ytew"
        className="animable"
      />
      <path
        d="M237,318.52a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "237px 319.285px",
        }}
        id="elur176enxc1m"
        className="animable"
      />
      <path
        d="M239.28,311.6a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "239.28px 312.365px",
        }}
        id="elbyjd1b4b5kt"
        className="animable"
      />
      <path
        d="M237,313.9a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "237px 314.67px",
        }}
        id="elfkqs8c20n2v"
        className="animable"
      />
      <path
        d="M239.28,307a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "239.28px 307.77px",
        }}
        id="ellc4y55ekeql"
        className="animable"
      />
      <path
        d="M237,309.29a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "237px 310.055px",
        }}
        id="elboex2c0x74i"
        className="animable"
      />
      <circle
        cx={239.28}
        cy={303.13}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "239.28px 303.13px",
        }}
        id="elywz1efyswi"
        className="animable"
      />
      <path
        d="M237,304.67a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "237px 305.44px",
        }}
        id="el7zv3hs966e8"
        className="animable"
      />
      <path
        d="M239.28,297.75a.77.77,0,1,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "239.28px 298.52px",
        }}
        id="elbks7c75mbak"
        className="animable"
      />
      <path
        d="M237,300.06a.77.77,0,1,0,.77.77A.77.77,0,0,0,237,300.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "237px 300.83px",
        }}
        id="elyk99ovfsle"
        className="animable"
      />
      <path
        d="M239.28,293.14a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "239.28px 293.905px",
        }}
        id="el5q6ghn7l5m7"
        className="animable"
      />
      <path
        d="M237,295.44a.77.77,0,1,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "237px 296.21px",
        }}
        id="elnaosmnxnndp"
        className="animable"
      />
      <path
        d="M243.24,344.29l1-.27a.78.78,0,0,0-.4-.12A.75.75,0,0,0,243.24,344.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "243.74px 344.095px",
        }}
        id="el2lvky4b9equ"
        className="animable"
      />
      <path
        d="M243.89,339.28a.77.77,0,1,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "243.89px 340.05px",
        }}
        id="el3ej7uiysm57"
        className="animable"
      />
      <path
        d="M241.59,341.59a.77.77,0,1,0,.76.77A.77.77,0,0,0,241.59,341.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "241.58px 342.36px",
        }}
        id="elavluh8tci7c"
        className="animable"
      />
      <path
        d="M243.89,334.67a.77.77,0,1,0,.77.77A.77.77,0,0,0,243.89,334.67Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "243.89px 335.44px",
        }}
        id="elrrazfb1l3n"
        className="animable"
      />
      <circle
        cx={241.59}
        cy={337.75}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "241.59px 337.75px",
        }}
        id="elpzldf71g3gs"
        className="animable"
      />
      <path
        d="M243.89,330.05a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "243.89px 330.82px",
        }}
        id="el3tsvb4na1ef"
        className="animable"
      />
      <path
        d="M241.59,332.36a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "241.59px 333.13px",
        }}
        id="elv5zd4rakhd"
        className="animable"
      />
      <path
        d="M243.89,325.44a.77.77,0,1,0,.77.77A.77.77,0,0,0,243.89,325.44Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "243.89px 326.21px",
        }}
        id="elz6kj89892ol"
        className="animable"
      />
      <path
        d="M241.59,327.75a.77.77,0,1,0,.76.77A.76.76,0,0,0,241.59,327.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "241.58px 328.52px",
        }}
        id="elmns9ymqhdxg"
        className="animable"
      />
      <path
        d="M243.89,320.83a.77.77,0,1,0,.77.76A.77.77,0,0,0,243.89,320.83Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "243.89px 321.6px",
        }}
        id="eldby3r9amie"
        className="animable"
      />
      <circle
        cx={241.59}
        cy={323.9}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "241.59px 323.9px",
        }}
        id="eli2rbbycb4v"
        className="animable"
      />
      <path
        d="M243.89,316.21a.77.77,0,1,0,.77.77A.77.77,0,0,0,243.89,316.21Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "243.89px 316.98px",
        }}
        id="elihe6apud9im"
        className="animable"
      />
      <path
        d="M241.59,318.52a.77.77,0,1,0,.76.77A.76.76,0,0,0,241.59,318.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "241.58px 319.29px",
        }}
        id="elmjfa7kdj9r"
        className="animable"
      />
      <path
        d="M243.89,311.6a.77.77,0,1,0,.77.76A.77.77,0,0,0,243.89,311.6Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "243.89px 312.37px",
        }}
        id="eli58izc4w7lg"
        className="animable"
      />
      <path
        d="M241.59,313.9a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "241.59px 314.67px",
        }}
        id="elz1xc5ttsed9"
        className="animable"
      />
      <path
        d="M243.89,307a.77.77,0,1,0,.77.77A.77.77,0,0,0,243.89,307Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "243.89px 307.77px",
        }}
        id="elo9j3fhuj2w"
        className="animable"
      />
      <circle
        cx={241.59}
        cy={310.06}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "241.59px 310.06px",
        }}
        id="elayxvx2j3i5"
        className="animable"
      />
      <path
        d="M243.89,302.37a.77.77,0,1,0,.77.76A.77.77,0,0,0,243.89,302.37Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "243.89px 303.14px",
        }}
        id="elqwerfbqt7oc"
        className="animable"
      />
      <path
        d="M241.59,304.67a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "241.59px 305.44px",
        }}
        id="el8nkksjrbavu"
        className="animable"
      />
      <path
        d="M243.89,297.75a.77.77,0,1,0,.77.77A.77.77,0,0,0,243.89,297.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "243.89px 298.52px",
        }}
        id="elqj5n10s5szn"
        className="animable"
      />
      <path
        d="M241.59,300.06a.77.77,0,1,0,.76.77A.76.76,0,0,0,241.59,300.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "241.58px 300.83px",
        }}
        id="eljl5eqv87ot"
        className="animable"
      />
      <path
        d="M243.89,294.67a.74.74,0,0,0,.64-.37l-1.4-.44s0,0,0,0A.77.77,0,0,0,243.89,294.67Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "243.829px 294.265px",
        }}
        id="eltvrknlfwe9n"
        className="animable"
      />
      <circle
        cx={241.59}
        cy={296.21}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "241.59px 296.21px",
        }}
        id="eltw3anux8oxe"
        className="animable"
      />
      <path
        d="M248.51,339.28a.77.77,0,1,0,.77.77A.77.77,0,0,0,248.51,339.28Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "248.51px 340.05px",
        }}
        id="elg0vo4o6ncic"
        className="animable"
      />
      <path
        d="M246.2,341.59a.77.77,0,1,0,.77.77A.78.78,0,0,0,246.2,341.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "246.2px 342.36px",
        }}
        id="elx9q0lphx7ff"
        className="animable"
      />
      <path
        d="M248.51,334.67a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "248.51px 335.44px",
        }}
        id="el2ojy1p1a9hd"
        className="animable"
      />
      <circle
        cx={246.2}
        cy={337.75}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "246.2px 337.75px",
        }}
        id="elfmfdmmvg0ko"
        className="animable"
      />
      <path
        d="M248.51,330.05a.77.77,0,1,0,.77.77A.77.77,0,0,0,248.51,330.05Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "248.51px 330.82px",
        }}
        id="elhklzpf2jl3"
        className="animable"
      />
      <path
        d="M246.2,332.36a.77.77,0,1,0,.77.77A.78.78,0,0,0,246.2,332.36Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "246.2px 333.13px",
        }}
        id="el459ee27fwkx"
        className="animable"
      />
      <path
        d="M248.51,325.44a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "248.51px 326.21px",
        }}
        id="elruvv9yp10vc"
        className="animable"
      />
      <path
        d="M246.2,327.75a.77.77,0,1,0,.77.77A.77.77,0,0,0,246.2,327.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "246.2px 328.52px",
        }}
        id="elklzkn58347"
        className="animable"
      />
      <path
        d="M248.51,320.83a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "248.51px 321.595px",
        }}
        id="elgja7yw7hjao"
        className="animable"
      />
      <circle
        cx={246.2}
        cy={323.9}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "246.2px 323.9px",
        }}
        id="el7nna7p3wyin"
        className="animable"
      />
      <path
        d="M248.51,316.21a.77.77,0,1,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "248.51px 316.98px",
        }}
        id="el6hy5w5q7j5v"
        className="animable"
      />
      <path
        d="M246.2,318.52a.77.77,0,1,0,.77.77A.77.77,0,0,0,246.2,318.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "246.2px 319.29px",
        }}
        id="elqn7jfkv7q4"
        className="animable"
      />
      <path
        d="M248.51,311.6a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "248.51px 312.365px",
        }}
        id="elmfy60fqup6i"
        className="animable"
      />
      <path
        d="M246.2,313.9a.77.77,0,1,0,.77.77A.78.78,0,0,0,246.2,313.9Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "246.2px 314.67px",
        }}
        id="elq9vl4up3th"
        className="animable"
      />
      <path
        d="M248.51,307a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "248.51px 307.77px",
        }}
        id="el3cc6xws9hmi"
        className="animable"
      />
      <circle
        cx={246.2}
        cy={310.06}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "246.2px 310.06px",
        }}
        id="elfpnkdvltpuk"
        className="animable"
      />
      <path
        d="M248.51,302.37a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "248.51px 303.135px",
        }}
        id="elp74divbgf0r"
        className="animable"
      />
      <path
        d="M246.2,304.67a.77.77,0,1,0,.77.77A.78.78,0,0,0,246.2,304.67Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "246.2px 305.44px",
        }}
        id="elyhus35asvon"
        className="animable"
      />
      <path
        d="M248.51,297.75a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "248.51px 298.52px",
        }}
        id="el0lt8j9j9a5t"
        className="animable"
      />
      <path
        d="M250.82,297a.77.77,0,0,0,.7-.46l-1.44-.46a.8.8,0,0,0,0,.15A.76.76,0,0,0,250.82,297Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "250.798px 296.54px",
        }}
        id="eltbim3smom6g"
        className="animable"
      />
      <path
        d="M246.2,300.06a.77.77,0,1,0,.77.77A.77.77,0,0,0,246.2,300.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "246.2px 300.83px",
        }}
        id="el9h4g5c3j1y"
        className="animable"
      />
      <circle
        cx={246.2}
        cy={296.21}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "246.2px 296.21px",
        }}
        id="el22v6k2acpho"
        className="animable"
      />
      <path
        d="M253.12,339.28a.77.77,0,0,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "253.12px 340.05px",
        }}
        id="elb7o6nrkq9vt"
        className="animable"
      />
      <path
        d="M250.82,341.59a.77.77,0,0,0-.77.77,1.13,1.13,0,0,0,0,.18l1.47-.37A.77.77,0,0,0,250.82,341.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "250.783px 342.065px",
        }}
        id="elp38324eqof"
        className="animable"
      />
      <path
        d="M253.12,334.67a.77.77,0,1,0,.77.77A.77.77,0,0,0,253.12,334.67Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "253.12px 335.44px",
        }}
        id="el7awqgo6uz4"
        className="animable"
      />
      <path
        d="M250.82,337a.77.77,0,1,0,.76.77A.76.76,0,0,0,250.82,337Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "250.81px 337.77px",
        }}
        id="elh9ghlkhr0ba"
        className="animable"
      />
      <circle
        cx={253.12}
        cy={330.82}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "253.12px 330.82px",
        }}
        id="eli0txklund4"
        className="animable"
      />
      <path
        d="M250.82,332.36a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "250.82px 333.13px",
        }}
        id="elwv02fxhhjq"
        className="animable"
      />
      <path
        d="M253.12,325.44a.77.77,0,1,0,.77.77A.77.77,0,0,0,253.12,325.44Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "253.12px 326.21px",
        }}
        id="elmcjjtei3yka"
        className="animable"
      />
      <path
        d="M250.82,327.75a.77.77,0,1,0,.76.77A.76.76,0,0,0,250.82,327.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "250.81px 328.52px",
        }}
        id="elpj0ii1v57eb"
        className="animable"
      />
      <path
        d="M253.12,320.83a.77.77,0,1,0,.77.76A.77.77,0,0,0,253.12,320.83Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "253.12px 321.6px",
        }}
        id="el2f3rkh7gbvx"
        className="animable"
      />
      <path
        d="M250.82,323.13a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "250.82px 323.9px",
        }}
        id="el3v2uughx96"
        className="animable"
      />
      <circle
        cx={253.12}
        cy={316.98}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "253.12px 316.98px",
        }}
        id="elz0bn8nechf"
        className="animable"
      />
      <path
        d="M250.82,318.52a.77.77,0,1,0,.76.77A.76.76,0,0,0,250.82,318.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "250.81px 319.29px",
        }}
        id="elfpho3q6nx44"
        className="animable"
      />
      <path
        d="M253.12,311.6a.77.77,0,1,0,.77.76A.77.77,0,0,0,253.12,311.6Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "253.12px 312.37px",
        }}
        id="elreydxdw0fp"
        className="animable"
      />
      <path
        d="M250.82,313.9a.77.77,0,1,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "250.82px 314.67px",
        }}
        id="ely49ankmfscm"
        className="animable"
      />
      <path
        d="M253.12,307a.77.77,0,1,0,.77.77A.77.77,0,0,0,253.12,307Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "253.12px 307.77px",
        }}
        id="elmnhnqm710u"
        className="animable"
      />
      <path
        d="M250.82,309.29a.77.77,0,1,0,.76.77A.76.76,0,0,0,250.82,309.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "250.81px 310.06px",
        }}
        id="elgqn9qf3n8sr"
        className="animable"
      />
      <circle
        cx={253.12}
        cy={303.13}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "253.12px 303.13px",
        }}
        id="eldff24t5i4z"
        className="animable"
      />
      <path
        d="M250.82,304.67a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "250.82px 305.44px",
        }}
        id="elyu1oump77zj"
        className="animable"
      />
      <path
        d="M253.12,297.75a.77.77,0,1,0,.77.77A.77.77,0,0,0,253.12,297.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "253.12px 298.52px",
        }}
        id="elys6mfe379o"
        className="animable"
      />
      <path
        d="M250.82,300.06a.77.77,0,1,0,.76.77A.76.76,0,0,0,250.82,300.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "250.81px 300.83px",
        }}
        id="elo7vfryy3ok9"
        className="animable"
      />
      <path
        d="M257.74,339.28a.77.77,0,0,0-.77.77.76.76,0,0,0,.36.64l1.07-.27a.76.76,0,0,0,.11-.37A.78.78,0,0,0,257.74,339.28Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "257.74px 339.985px",
        }}
        id="el5j8yy11ishd"
        className="animable"
      />
      <path
        d="M257.74,334.67a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "257.74px 335.44px",
        }}
        id="el7b3jitbfgk"
        className="animable"
      />
      <path
        d="M255.43,337a.77.77,0,1,0,.77.77A.77.77,0,0,0,255.43,337Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "255.43px 337.77px",
        }}
        id="elty4cvc1gb5"
        className="animable"
      />
      <path
        d="M257.74,330.05a.77.77,0,1,0,.77.77A.77.77,0,0,0,257.74,330.05Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "257.74px 330.82px",
        }}
        id="elp980wicfbg"
        className="animable"
      />
      <path
        d="M255.43,332.36a.77.77,0,1,0,.77.77A.78.78,0,0,0,255.43,332.36Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "255.43px 333.13px",
        }}
        id="elc3n4fgdyvh9"
        className="animable"
      />
      <path
        d="M257.74,325.44a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "257.74px 326.21px",
        }}
        id="eloy54i8jv0rd"
        className="animable"
      />
      <path
        d="M255.43,327.75a.77.77,0,1,0,.77.77A.77.77,0,0,0,255.43,327.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "255.43px 328.52px",
        }}
        id="el2cjhyxtxcl7"
        className="animable"
      />
      <path
        d="M257.74,320.83a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "257.74px 321.595px",
        }}
        id="elvusa53ngbu"
        className="animable"
      />
      <path
        d="M255.43,323.13a.77.77,0,1,0,.77.77A.78.78,0,0,0,255.43,323.13Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "255.43px 323.9px",
        }}
        id="elvckyprt549"
        className="animable"
      />
      <path
        d="M257.74,316.21a.77.77,0,1,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "257.74px 316.98px",
        }}
        id="elyhyzdrtmlw"
        className="animable"
      />
      <path
        d="M255.43,318.52a.77.77,0,1,0,.77.77A.77.77,0,0,0,255.43,318.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "255.43px 319.29px",
        }}
        id="elwanmukd8o9"
        className="animable"
      />
      <path
        d="M257.74,311.6a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "257.74px 312.365px",
        }}
        id="ellh1iomq4lu"
        className="animable"
      />
      <path
        d="M255.43,313.9a.77.77,0,1,0,.77.77A.78.78,0,0,0,255.43,313.9Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "255.43px 314.67px",
        }}
        id="el3md0fjms5ey"
        className="animable"
      />
      <path
        d="M257.74,307a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "257.74px 307.77px",
        }}
        id="ellp1a2zpcx6k"
        className="animable"
      />
      <path
        d="M255.43,309.29a.77.77,0,1,0,.77.77A.77.77,0,0,0,255.43,309.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "255.43px 310.06px",
        }}
        id="elsml3ajnylq"
        className="animable"
      />
      <path
        d="M257.74,302.37a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "257.74px 303.135px",
        }}
        id="elr4rvoxa6538"
        className="animable"
      />
      <path
        d="M255.43,304.67a.77.77,0,1,0,.77.77A.78.78,0,0,0,255.43,304.67Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "255.43px 305.44px",
        }}
        id="elzxkmudsr61"
        className="animable"
      />
      <path
        d="M257.74,299.29a.75.75,0,0,0,.72-.56l-1.44-.46a.7.7,0,0,0,0,.25A.77.77,0,0,0,257.74,299.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "257.734px 298.78px",
        }}
        id="elexuf9040fbe"
        className="animable"
      />
      <path
        d="M255.43,300.06a.77.77,0,1,0,.77.77A.77.77,0,0,0,255.43,300.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "255.43px 300.83px",
        }}
        id="eltppfobwsuw"
        className="animable"
      />
      <path
        d="M261.78,339.55l.84-.21a.71.71,0,0,0-.84.21Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "262.2px 339.415px",
        }}
        id="elydx7kfbwiqe"
        className="animable"
      />
      <path
        d="M262.35,334.67a.77.77,0,1,0,.77.77A.77.77,0,0,0,262.35,334.67Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "262.35px 335.44px",
        }}
        id="elt6xz6bzdm6"
        className="animable"
      />
      <circle
        cx={260.05}
        cy={337.75}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "260.05px 337.75px",
        }}
        id="elxa348uhi1"
        className="animable"
      />
      <path
        d="M262.35,330.05a.77.77,0,1,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "262.35px 330.82px",
        }}
        id="eldscmkwx7zbn"
        className="animable"
      />
      <path
        d="M260.05,332.36a.77.77,0,1,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "260.05px 333.13px",
        }}
        id="elm7jbbj42u3"
        className="animable"
      />
      <path
        d="M262.35,325.44a.77.77,0,1,0,.77.77A.77.77,0,0,0,262.35,325.44Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "262.35px 326.21px",
        }}
        id="elrx9ggrusv6q"
        className="animable"
      />
      <path
        d="M260.05,327.75a.77.77,0,1,0,.76.77A.76.76,0,0,0,260.05,327.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "260.04px 328.52px",
        }}
        id="el11zkefcftsli"
        className="animable"
      />
      <path
        d="M262.35,320.83a.77.77,0,1,0,.77.76A.77.77,0,0,0,262.35,320.83Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "262.35px 321.6px",
        }}
        id="el6a0oypzb3um"
        className="animable"
      />
      <circle
        cx={260.05}
        cy={323.9}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "260.05px 323.9px",
        }}
        id="elcpxi242am6"
        className="animable"
      />
      <path
        d="M262.35,316.21a.77.77,0,1,0,.77.77A.77.77,0,0,0,262.35,316.21Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "262.35px 316.98px",
        }}
        id="el21eisfyrtji"
        className="animable"
      />
      <path
        d="M260.05,318.52a.77.77,0,1,0,.76.77A.76.76,0,0,0,260.05,318.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "260.04px 319.29px",
        }}
        id="el3yrjjg31se"
        className="animable"
      />
      <path
        d="M262.35,311.6a.77.77,0,1,0,.77.76A.77.77,0,0,0,262.35,311.6Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "262.35px 312.37px",
        }}
        id="elxkjod4ctlwf"
        className="animable"
      />
      <path
        d="M260.05,313.9a.77.77,0,1,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "260.05px 314.67px",
        }}
        id="el7b8ykcgv35a"
        className="animable"
      />
      <path
        d="M262.35,307a.77.77,0,1,0,.77.77A.77.77,0,0,0,262.35,307Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "262.35px 307.77px",
        }}
        id="elkorls8b0gye"
        className="animable"
      />
      <circle
        cx={260.05}
        cy={310.06}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "260.05px 310.06px",
        }}
        id="elwk3fjgv99hp"
        className="animable"
      />
      <path
        d="M262.35,302.37a.77.77,0,1,0,.77.76A.77.77,0,0,0,262.35,302.37Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "262.35px 303.14px",
        }}
        id="elsgc4919o51f"
        className="animable"
      />
      <path
        d="M264.66,301.6a.77.77,0,0,0,.75-.67l-1.42-.45a.68.68,0,0,0-.1.35A.78.78,0,0,0,264.66,301.6Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "264.65px 301.04px",
        }}
        id="el4vg8h209bas"
        className="animable"
      />
      <path
        d="M260.05,304.67a.77.77,0,1,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "260.05px 305.44px",
        }}
        id="el00pj7vf7hzpeo"
        className="animable"
      />
      <path
        d="M260.05,300.06a.77.77,0,1,0,.76.77A.76.76,0,0,0,260.05,300.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "260.04px 300.83px",
        }}
        id="el2umn9b6dy4y"
        className="animable"
      />
      <path
        d="M267,334.67a.77.77,0,1,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "267px 335.44px",
        }}
        id="el9ytsru29mn"
        className="animable"
      />
      <path
        d="M264.66,337a.77.77,0,1,0,.77.77A.77.77,0,0,0,264.66,337Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "264.66px 337.77px",
        }}
        id="elpv254vbg2si"
        className="animable"
      />
      <circle
        cx={266.97}
        cy={330.82}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "266.97px 330.82px",
        }}
        id="elz9d62s10v09"
        className="animable"
      />
      <path
        d="M264.66,332.36a.77.77,0,1,0,.77.77A.78.78,0,0,0,264.66,332.36Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "264.66px 333.13px",
        }}
        id="elmjyunu5ma4k"
        className="animable"
      />
      <path
        d="M267,325.44a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "267px 326.21px",
        }}
        id="elnqoubapzjj"
        className="animable"
      />
      <path
        d="M264.66,327.75a.77.77,0,1,0,.77.77A.77.77,0,0,0,264.66,327.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "264.66px 328.52px",
        }}
        id="el91ldzjav89h"
        className="animable"
      />
      <path
        d="M267,320.83a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "267px 321.595px",
        }}
        id="elvy4yebtc77i"
        className="animable"
      />
      <path
        d="M264.66,323.13a.77.77,0,1,0,.77.77A.78.78,0,0,0,264.66,323.13Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "264.66px 323.9px",
        }}
        id="eltp7r6umny0d"
        className="animable"
      />
      <circle
        cx={266.97}
        cy={316.98}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "266.97px 316.98px",
        }}
        id="elt2u5e2xe9tc"
        className="animable"
      />
      <path
        d="M264.66,318.52a.77.77,0,1,0,.77.77A.77.77,0,0,0,264.66,318.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "264.66px 319.29px",
        }}
        id="el1d4h8088ain"
        className="animable"
      />
      <path
        d="M267,311.6a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "267px 312.365px",
        }}
        id="eld5v26gwrkh"
        className="animable"
      />
      <path
        d="M264.66,313.9a.77.77,0,1,0,.77.77A.78.78,0,0,0,264.66,313.9Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "264.66px 314.67px",
        }}
        id="eluzhqur75o17"
        className="animable"
      />
      <path
        d="M267,307a.77.77,0,1,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "267px 307.77px",
        }}
        id="el0ewnhqainzx"
        className="animable"
      />
      <path
        d="M264.66,309.29a.77.77,0,1,0,.77.77A.77.77,0,0,0,264.66,309.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "264.66px 310.06px",
        }}
        id="elssw0nxzi1a"
        className="animable"
      />
      <circle
        cx={266.97}
        cy={303.13}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "266.97px 303.13px",
        }}
        id="elmdou5vgopq7"
        className="animable"
      />
      <path
        d="M264.66,304.67a.77.77,0,1,0,.77.77A.78.78,0,0,0,264.66,304.67Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "264.66px 305.44px",
        }}
        id="elj31c8705uqe"
        className="animable"
      />
      <path
        d="M271.58,334.67a.77.77,0,1,0,.77.77A.77.77,0,0,0,271.58,334.67Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "271.58px 335.44px",
        }}
        id="ellacahu594d9"
        className="animable"
      />
      <path
        d="M269.27,337a.76.76,0,0,0-.76.77s0,.05,0,.08l1.47-.37A.78.78,0,0,0,269.27,337Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "269.245px 337.425px",
        }}
        id="eletgta1l3cpv"
        className="animable"
      />
      <path
        d="M271.58,330.05a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "271.58px 330.82px",
        }}
        id="eljrczi7bcdo"
        className="animable"
      />
      <path
        d="M269.27,332.36a.77.77,0,1,0,.77.77A.77.77,0,0,0,269.27,332.36Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "269.27px 333.13px",
        }}
        id="el6p6bhojdg1j"
        className="animable"
      />
      <path
        d="M271.58,325.44a.77.77,0,1,0,.77.77A.77.77,0,0,0,271.58,325.44Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "271.58px 326.21px",
        }}
        id="elm70refsfq8q"
        className="animable"
      />
      <path
        d="M269.27,327.75a.77.77,0,1,0,.77.77A.76.76,0,0,0,269.27,327.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "269.27px 328.52px",
        }}
        id="elqhihl79mrvo"
        className="animable"
      />
      <path
        d="M271.58,320.83a.77.77,0,1,0,.77.76A.77.77,0,0,0,271.58,320.83Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "271.58px 321.6px",
        }}
        id="elp13zox3bren"
        className="animable"
      />
      <path
        d="M269.27,323.13a.77.77,0,1,0,.77.77A.77.77,0,0,0,269.27,323.13Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "269.27px 323.9px",
        }}
        id="elmishoxeqn6o"
        className="animable"
      />
      <path
        d="M271.58,316.21a.77.77,0,1,0,.77.77A.77.77,0,0,0,271.58,316.21Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "271.58px 316.98px",
        }}
        id="eljgifh9pbf39"
        className="animable"
      />
      <path
        d="M269.27,318.52a.77.77,0,1,0,.77.77A.76.76,0,0,0,269.27,318.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "269.27px 319.29px",
        }}
        id="el1rvnsz2yzv"
        className="animable"
      />
      <path
        d="M271.58,311.6a.77.77,0,1,0,.77.76A.77.77,0,0,0,271.58,311.6Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "271.58px 312.37px",
        }}
        id="elzwow9blw71s"
        className="animable"
      />
      <path
        d="M269.27,313.9a.77.77,0,1,0,.77.77A.77.77,0,0,0,269.27,313.9Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "269.27px 314.67px",
        }}
        id="elsinty1frtji"
        className="animable"
      />
      <path
        d="M271.58,307a.77.77,0,1,0,.77.77A.77.77,0,0,0,271.58,307Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "271.58px 307.77px",
        }}
        id="eljaav87q145p"
        className="animable"
      />
      <path
        d="M269.27,309.29a.77.77,0,1,0,.77.77A.76.76,0,0,0,269.27,309.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "269.27px 310.06px",
        }}
        id="elmyjicsjchee"
        className="animable"
      />
      <path
        d="M271.58,303.9a.76.76,0,0,0,.77-.77h0l-1.38-.44a.79.79,0,0,0-.16.44A.77.77,0,0,0,271.58,303.9Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "271.58px 303.295px",
        }}
        id="el5bjb6pobgn5"
        className="animable"
      />
      <path
        d="M269.27,304.67a.77.77,0,1,0,.77.77A.77.77,0,0,0,269.27,304.67Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "269.27px 305.44px",
        }}
        id="elx671vipmmka"
        className="animable"
      />
      <path
        d="M276.2,334.67a.76.76,0,0,0-.77.77.74.74,0,0,0,.26.56l1.22-.31a.71.71,0,0,0,.06-.25A.77.77,0,0,0,276.2,334.67Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "276.2px 335.335px",
        }}
        id="elvu8pj1m1gyp"
        className="animable"
      />
      <path
        d="M276.2,330.05a.77.77,0,1,0,.77.77A.77.77,0,0,0,276.2,330.05Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "276.2px 330.82px",
        }}
        id="elueb9cnyth1b"
        className="animable"
      />
      <path
        d="M273.89,332.36a.77.77,0,1,0,.77.77A.78.78,0,0,0,273.89,332.36Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "273.89px 333.13px",
        }}
        id="elgxm4xyaupu7"
        className="animable"
      />
      <path
        d="M276.2,325.44a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "276.2px 326.21px",
        }}
        id="elrm6wpsxsysn"
        className="animable"
      />
      <path
        d="M273.89,327.75a.77.77,0,1,0,.77.77A.77.77,0,0,0,273.89,327.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "273.89px 328.52px",
        }}
        id="elcglqprjz06g"
        className="animable"
      />
      <path
        d="M276.2,320.83a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "276.2px 321.595px",
        }}
        id="el9pvhsipf7pq"
        className="animable"
      />
      <circle
        cx={273.89}
        cy={323.9}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "273.89px 323.9px",
        }}
        id="elh8ln13wqsot"
        className="animable"
      />
      <path
        d="M276.2,316.21a.77.77,0,1,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "276.2px 316.98px",
        }}
        id="el33tz3nhjltl"
        className="animable"
      />
      <path
        d="M273.89,318.52a.77.77,0,1,0,.77.77A.77.77,0,0,0,273.89,318.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "273.89px 319.29px",
        }}
        id="el1bzt8gkhaja"
        className="animable"
      />
      <path
        d="M276.2,311.6a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "276.2px 312.365px",
        }}
        id="elllib0f5myhe"
        className="animable"
      />
      <path
        d="M273.89,313.9a.77.77,0,1,0,.77.77A.78.78,0,0,0,273.89,313.9Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "273.89px 314.67px",
        }}
        id="el8774mrw41sd"
        className="animable"
      />
      <path
        d="M276.2,307a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "276.2px 307.77px",
        }}
        id="elstffbpxy5lt"
        className="animable"
      />
      <path
        d="M278.5,306.21a.76.76,0,0,0,.77-.77.5.5,0,0,0,0-.12l-1.3-.41a.77.77,0,0,0,.55,1.3Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "278.516px 305.56px",
        }}
        id="el5qknj0ukeq4"
        className="animable"
      />
      <circle
        cx={273.89}
        cy={310.06}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "273.89px 310.06px",
        }}
        id="elq5ukeftvhmk"
        className="animable"
      />
      <path
        d="M273.89,304.67a.77.77,0,1,0,.77.77A.78.78,0,0,0,273.89,304.67Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "273.89px 305.44px",
        }}
        id="el3fian6bvamj"
        className="animable"
      />
      <path
        d="M280.42,334.79l.44-.11h-.05A.72.72,0,0,0,280.42,334.79Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "280.64px 334.735px",
        }}
        id="elgzctenuxkeq"
        className="animable"
      />
      <path
        d="M280.81,330.05a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "280.81px 330.82px",
        }}
        id="el9wwfx84ao2j"
        className="animable"
      />
      <path
        d="M278.5,332.36a.77.77,0,1,0,.77.77A.77.77,0,0,0,278.5,332.36Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "278.5px 333.13px",
        }}
        id="elqfy5lc2wdn"
        className="animable"
      />
      <path
        d="M280.81,325.44a.77.77,0,1,0,.77.77A.77.77,0,0,0,280.81,325.44Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "280.81px 326.21px",
        }}
        id="el5zvrivr0xw"
        className="animable"
      />
      <path
        d="M278.5,327.75a.77.77,0,1,0,.77.77A.76.76,0,0,0,278.5,327.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "278.5px 328.52px",
        }}
        id="el2wcwn8m5ds7"
        className="animable"
      />
      <path
        d="M280.81,320.83a.77.77,0,1,0,.77.76A.77.77,0,0,0,280.81,320.83Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "280.81px 321.6px",
        }}
        id="elyhoudeyibd"
        className="animable"
      />
      <path
        d="M278.5,323.13a.77.77,0,1,0,.77.77A.77.77,0,0,0,278.5,323.13Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "278.5px 323.9px",
        }}
        id="el9txao4rzqu6"
        className="animable"
      />
      <path
        d="M280.81,316.21a.77.77,0,1,0,.77.77A.77.77,0,0,0,280.81,316.21Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "280.81px 316.98px",
        }}
        id="elj7i58gl3kyg"
        className="animable"
      />
      <path
        d="M278.5,318.52a.77.77,0,1,0,.77.77A.76.76,0,0,0,278.5,318.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "278.5px 319.29px",
        }}
        id="elrooj3264tw"
        className="animable"
      />
      <path
        d="M280.81,311.6a.77.77,0,1,0,.77.76A.77.77,0,0,0,280.81,311.6Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "280.81px 312.37px",
        }}
        id="elit3eop8mnza"
        className="animable"
      />
      <path
        d="M278.5,313.9a.77.77,0,1,0,.77.77A.77.77,0,0,0,278.5,313.9Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "278.5px 314.67px",
        }}
        id="el0f0kk74n8uhn"
        className="animable"
      />
      <path
        d="M280.81,307a.77.77,0,1,0,.77.77A.77.77,0,0,0,280.81,307Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "280.81px 307.77px",
        }}
        id="els6q4fnmaf"
        className="animable"
      />
      <path
        d="M278.5,309.29a.77.77,0,1,0,.77.77A.76.76,0,0,0,278.5,309.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "278.5px 310.06px",
        }}
        id="elvlg72q6si6"
        className="animable"
      />
      <path
        d="M285.43,330.05a.77.77,0,1,0,.77.77A.77.77,0,0,0,285.43,330.05Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "285.43px 330.82px",
        }}
        id="elqau0q9fiha"
        className="animable"
      />
      <path
        d="M283.12,332.36a.77.77,0,1,0,.77.77A.78.78,0,0,0,283.12,332.36Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "283.12px 333.13px",
        }}
        id="elbyn53e9ld45"
        className="animable"
      />
      <path
        d="M285.43,325.44a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "285.43px 326.21px",
        }}
        id="el11hs6s4zcokr"
        className="animable"
      />
      <path
        d="M283.12,327.75a.77.77,0,1,0,.77.77A.77.77,0,0,0,283.12,327.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "283.12px 328.52px",
        }}
        id="elzf2r5bwxfbe"
        className="animable"
      />
      <path
        d="M285.43,320.83a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "285.43px 321.595px",
        }}
        id="elzz07qzn0l"
        className="animable"
      />
      <path
        d="M283.12,323.13a.77.77,0,1,0,.77.77A.78.78,0,0,0,283.12,323.13Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "283.12px 323.9px",
        }}
        id="elllkmx9gdwrb"
        className="animable"
      />
      <path
        d="M285.43,316.21a.77.77,0,1,0,0,1.54.77.77,0,1,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "285.43px 316.98px",
        }}
        id="elzdrtmsoc41a"
        className="animable"
      />
      <path
        d="M283.12,318.52a.77.77,0,1,0,.77.77A.77.77,0,0,0,283.12,318.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "283.12px 319.29px",
        }}
        id="eljh0topzmcfh"
        className="animable"
      />
      <path
        d="M285.43,311.6a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "285.43px 312.365px",
        }}
        id="elx3ipeczib4"
        className="animable"
      />
      <path
        d="M283.12,313.9a.77.77,0,1,0,.77.77A.78.78,0,0,0,283.12,313.9Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "283.12px 314.67px",
        }}
        id="elbqnqnhugwx"
        className="animable"
      />
      <path
        d="M284.66,307.75a.77.77,0,0,0,1.54,0,.93.93,0,0,0-.05-.24l-1.17-.37A.75.75,0,0,0,284.66,307.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "285.43px 307.83px",
        }}
        id="el8bvafgviljo"
        className="animable"
      />
      <path
        d="M283.12,309.29a.77.77,0,1,0,.77.77A.77.77,0,0,0,283.12,309.29Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "283.12px 310.06px",
        }}
        id="el0ortan7pkbjk"
        className="animable"
      />
      <path
        d="M290,330.05a.77.77,0,1,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "290px 330.82px",
        }}
        id="elubk2xxibxr"
        className="animable"
      />
      <path
        d="M287.73,332.36a.76.76,0,0,0-.76.76l1.42-.36A.76.76,0,0,0,287.73,332.36Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "287.68px 332.74px",
        }}
        id="el4jw3r6sh233"
        className="animable"
      />
      <path
        d="M290,325.44a.77.77,0,1,0,.77.77A.77.77,0,0,0,290,325.44Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "290px 326.21px",
        }}
        id="el8ytu8ss3t94"
        className="animable"
      />
      <path
        d="M287.73,327.75a.77.77,0,1,0,.77.77A.76.76,0,0,0,287.73,327.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "287.73px 328.52px",
        }}
        id="elnn5mwim3knh"
        className="animable"
      />
      <path
        d="M290,320.83a.77.77,0,1,0,.77.76A.77.77,0,0,0,290,320.83Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "290px 321.6px",
        }}
        id="eljv94iz87spo"
        className="animable"
      />
      <circle
        cx={287.73}
        cy={323.9}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "287.73px 323.9px",
        }}
        id="elgcqunebt5tc"
        className="animable"
      />
      <path
        d="M290,316.21a.77.77,0,1,0,.77.77A.77.77,0,0,0,290,316.21Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "290px 316.98px",
        }}
        id="elz3aisgmpszg"
        className="animable"
      />
      <path
        d="M287.73,318.52a.77.77,0,1,0,.77.77A.76.76,0,0,0,287.73,318.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "287.73px 319.29px",
        }}
        id="el3lq2jcibbe6"
        className="animable"
      />
      <path
        d="M290,311.6a.77.77,0,1,0,.77.76A.77.77,0,0,0,290,311.6Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "290px 312.37px",
        }}
        id="elfnuki8aj047"
        className="animable"
      />
      <path
        d="M287.73,313.9a.77.77,0,1,0,.77.77A.77.77,0,0,0,287.73,313.9Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "287.73px 314.67px",
        }}
        id="elmg1wjavv8hd"
        className="animable"
      />
      <path
        d="M291.58,310.06a.77.77,0,1,0,1.43-.37l-1-.32A.77.77,0,0,0,291.58,310.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "292.35px 310.113px",
        }}
        id="elu55rn11r86h"
        className="animable"
      />
      <circle
        cx={287.73}
        cy={310.06}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "287.73px 310.06px",
        }}
        id="ellgfs23w1iet"
        className="animable"
      />
      <path
        d="M294.66,330.05a.77.77,0,0,0-.59,1.26l1.33-.34a.66.66,0,0,0,0-.15A.78.78,0,0,0,294.66,330.05Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "294.649px 330.68px",
        }}
        id="eljtq00pn6998"
        className="animable"
      />
      <path
        d="M294.66,325.44a.77.77,0,0,0,0,1.54.77.77,0,0,0,0-1.54Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "294.66px 326.21px",
        }}
        id="elbvvh34e8miv"
        className="animable"
      />
      <path
        d="M292.35,327.75a.77.77,0,1,0,.77.77A.76.76,0,0,0,292.35,327.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "292.35px 328.52px",
        }}
        id="el50dp2axznzw"
        className="animable"
      />
      <path
        d="M294.66,320.83a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "294.66px 321.595px",
        }}
        id="elw3bqhk2s2o"
        className="animable"
      />
      <path
        d="M292.35,323.13a.77.77,0,1,0,.77.77A.77.77,0,0,0,292.35,323.13Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "292.35px 323.9px",
        }}
        id="el5n8th55prs7"
        className="animable"
      />
      <circle
        cx={294.66}
        cy={316.98}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "294.66px 316.98px",
        }}
        id="elrn3mknheo3s"
        className="animable"
      />
      <path
        d="M292.35,318.52a.77.77,0,1,0,.77.77A.76.76,0,0,0,292.35,318.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "292.35px 319.29px",
        }}
        id="el0ft039n15j5k"
        className="animable"
      />
      <path
        d="M294.66,311.6a.77.77,0,1,0,0,1.53.77.77,0,1,0,0-1.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "294.66px 312.365px",
        }}
        id="elpd3gdzw40za"
        className="animable"
      />
      <path
        d="M292.35,313.9a.77.77,0,1,0,.77.77A.77.77,0,0,0,292.35,313.9Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "292.35px 314.67px",
        }}
        id="eloyuwhpusv3"
        className="animable"
      />
      <path
        d="M299.27,325.44a.77.77,0,1,0,.77.77A.77.77,0,0,0,299.27,325.44Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "299.27px 326.21px",
        }}
        id="eltg4rsihre3j"
        className="animable"
      />
      <path
        d="M297,327.75a.77.77,0,1,0,.77.77A.76.76,0,0,0,297,327.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "297px 328.52px",
        }}
        id="elm3wilc322m"
        className="animable"
      />
      <path
        d="M299.27,320.83a.77.77,0,1,0,.77.76A.77.77,0,0,0,299.27,320.83Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "299.27px 321.6px",
        }}
        id="ellersmi7vjkm"
        className="animable"
      />
      <path
        d="M297,323.13a.77.77,0,1,0,.77.77A.77.77,0,0,0,297,323.13Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "297px 323.9px",
        }}
        id="elf1rw0htq4fk"
        className="animable"
      />
      <circle
        cx={299.27}
        cy={316.98}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "299.27px 316.98px",
        }}
        id="elfqqncc65cnp"
        className="animable"
      />
      <path
        d="M297,318.52a.77.77,0,1,0,.77.77A.76.76,0,0,0,297,318.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "297px 319.29px",
        }}
        id="el9iuo0fki4db"
        className="animable"
      />
      <path
        d="M298.5,312.36a.77.77,0,1,0,1.54,0,.79.79,0,0,0-.2-.51l-.72-.22A.75.75,0,0,0,298.5,312.36Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "299.27px 312.38px",
        }}
        id="el06qvp3e6rrav"
        className="animable"
      />
      <path
        d="M297,313.9a.77.77,0,1,0,.77.77A.77.77,0,0,0,297,313.9Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "297px 314.67px",
        }}
        id="el0pjilb03skdk"
        className="animable"
      />
      <path
        d="M303.89,325.44a.77.77,0,1,0,.76.77A.76.76,0,0,0,303.89,325.44Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "303.88px 326.21px",
        }}
        id="elppwqyup6see"
        className="animable"
      />
      <path
        d="M301.58,327.75a.77.77,0,1,0,.77.77A.76.76,0,0,0,301.58,327.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "301.58px 328.52px",
        }}
        id="el1t1vu6d6lgh"
        className="animable"
      />
      <path
        d="M303.89,320.83a.77.77,0,1,0,.76.76A.76.76,0,0,0,303.89,320.83Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "303.88px 321.6px",
        }}
        id="eleziullm81l5"
        className="animable"
      />
      <path
        d="M301.58,323.13a.77.77,0,1,0,.77.77A.77.77,0,0,0,301.58,323.13Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "301.58px 323.9px",
        }}
        id="elciiidmd0i4"
        className="animable"
      />
      <path
        d="M303.89,316.21a.77.77,0,1,0,.76.77A.76.76,0,0,0,303.89,316.21Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "303.88px 316.98px",
        }}
        id="elj3ulzpz0co"
        className="animable"
      />
      <path
        d="M301.58,318.52a.77.77,0,1,0,.77.77A.76.76,0,0,0,301.58,318.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "301.58px 319.29px",
        }}
        id="el1oze3szv9oc"
        className="animable"
      />
      <path
        d="M303.51,313a.71.71,0,0,0,.32.1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "303.67px 313.05px",
        }}
        id="el9kq21z53kng"
        className="animable"
      />
      <path
        d="M305.43,314.67a.77.77,0,1,0,1.53,0,.77.77,0,0,0-.46-.7h0a.88.88,0,0,0-.28-.06A.77.77,0,0,0,305.43,314.67Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "306.195px 314.719px",
        }}
        id="el0nvmri6wcu1l"
        className="animable"
      />
      <path
        d="M301.58,313.9a.77.77,0,1,0,.77.77A.77.77,0,0,0,301.58,313.9Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "301.58px 314.67px",
        }}
        id="elykj6plcyjk"
        className="animable"
      />
      <path
        d="M308.5,325.44a.77.77,0,1,0,.77.77A.77.77,0,0,0,308.5,325.44Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "308.5px 326.21px",
        }}
        id="el9wykblu66q6"
        className="animable"
      />
      <path
        d="M306.19,327.75a.75.75,0,0,0-.74.65l1.34-.34A.73.73,0,0,0,306.19,327.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "306.12px 328.075px",
        }}
        id="el0r2wbr8bjur"
        className="animable"
      />
      <path
        d="M308.5,320.83a.77.77,0,1,0,.77.76A.77.77,0,0,0,308.5,320.83Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "308.5px 321.6px",
        }}
        id="elax8zmvpjub6"
        className="animable"
      />
      <circle
        cx={306.19}
        cy={323.9}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "306.19px 323.9px",
        }}
        id="elfq8pq0n6g3"
        className="animable"
      />
      <path
        d="M308.5,316.21a.77.77,0,1,0,.77.77A.77.77,0,0,0,308.5,316.21Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "308.5px 316.98px",
        }}
        id="ele26kcomxsg"
        className="animable"
      />
      <path
        d="M310.81,315.44a.71.71,0,0,0,.2,0l-.79-.25A.77.77,0,0,0,310.81,315.44Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "310.615px 315.319px",
        }}
        id="elkedcz26mz6"
        className="animable"
      />
      <path
        d="M306.19,318.52a.77.77,0,1,0,.77.77A.76.76,0,0,0,306.19,318.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "306.19px 319.29px",
        }}
        id="elms5hawre7"
        className="animable"
      />
      <path
        d="M313.12,325.44a.76.76,0,0,0-.77.77.71.71,0,0,0,.13.4l1.4-.36v0A.76.76,0,0,0,313.12,325.44Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "313.116px 326.025px",
        }}
        id="elgr7tsk4b4k"
        className="animable"
      />
      <path
        d="M313.12,320.83a.77.77,0,1,0,.76.76A.76.76,0,0,0,313.12,320.83Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "313.11px 321.6px",
        }}
        id="el3rw97eb5eve"
        className="animable"
      />
      <path
        d="M310.81,323.13a.77.77,0,1,0,.77.77A.77.77,0,0,0,310.81,323.13Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "310.81px 323.9px",
        }}
        id="elwvciu45zk0l"
        className="animable"
      />
      <circle
        cx={313.12}
        cy={316.98}
        r={0.77}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "313.12px 316.98px",
        }}
        id="el25778qfqs7u"
        className="animable"
      />
      <path
        d="M310.81,318.52a.77.77,0,1,0,.77.77A.76.76,0,0,0,310.81,318.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "310.81px 319.29px",
        }}
        id="eluec2pdbfngo"
        className="animable"
      />
      <path
        d="M317,321.59a.77.77,0,1,0,1.54,0,.77.77,0,0,0-1.54,0Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "317.77px 321.59px",
        }}
        id="el2upqvaxfcue"
        className="animable"
      />
      <path
        d="M315.42,323.13a.77.77,0,1,0,.77.77A.77.77,0,0,0,315.42,323.13Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "315.42px 323.9px",
        }}
        id="elkqei41u6up"
        className="animable"
      />
      <path
        d="M317.73,317.75a.88.88,0,0,0,.36-.1l-1-.33A.76.76,0,0,0,317.73,317.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "317.59px 317.535px",
        }}
        id="elfnc384dit2"
        className="animable"
      />
      <path
        d="M319.27,319.29a.77.77,0,0,0,1.54,0,.77.77,0,0,0-1.54,0Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "320.04px 319.29px",
        }}
        id="elmdvp2091y9p"
        className="animable"
      />
      <path
        d="M315.42,318.52a.77.77,0,1,0,.77.77A.76.76,0,0,0,315.42,318.52Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "315.42px 319.29px",
        }}
        id="el7foitwnhgi9"
        className="animable"
      />
      <path
        d="M320.81,323.9a.77.77,0,0,0-1.54,0,.76.76,0,0,0,.77.77l.11,0,.21-.06A.76.76,0,0,0,320.81,323.9Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "320.04px 323.9px",
        }}
        id="elxin31at2rw"
        className="animable"
      />
      <path
        d="M321.58,321.59a.77.77,0,1,0,.77-.76A.76.76,0,0,0,321.58,321.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "322.35px 321.6px",
        }}
        id="el35t4in5zv5p"
        className="animable"
      />
      <path
        d="M324.65,320.05a.77.77,0,0,0,.47-.17l-1.19-.38A.74.74,0,0,0,324.65,320.05Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "324.525px 319.775px",
        }}
        id="el23tjtlqyv2xj"
        className="animable"
      />
      <path
        d="M326.19,321.59a.77.77,0,1,0,1.54,0,.77.77,0,0,0-1.54,0Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "326.96px 321.59px",
        }}
        id="elyqyurgq5ffn"
        className="animable"
      />
      <path
        d="M324.65,323.13a.76.76,0,0,0-.72.55l1.27-.32A.77.77,0,0,0,324.65,323.13Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "324.565px 323.405px",
        }}
        id="elq2mk2fka2o"
        className="animable"
      />
      <path
        d="M330.83,321.69a.87.87,0,0,0,.05.22l.35-.09Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "331.03px 321.8px",
        }}
        id="elk5awch4wyao"
        className="animable"
      />
      <polygon
        points="182.1 293.31 215.14 308.43 230.52 270.36 182.1 293.31"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "206.31px 289.395px",
        }}
        id="eloe17yv6c06a"
        className="animable"
      />
      <path
        d="M275.54,322.42c-2.83,9.28-17.51,44.76-37.93,43.77-6.94-.33-9.5-27.61,3.58-55.16.38-.82,21.15,2.17,21.05,7.17,0,.53-4.42,12.27-3.82,12.37.09,0,2.41-2.44,12.6-12.86C276.11,312.51,277.15,317.13,275.54,322.42Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "254.063px 338.553px",
        }}
        id="elga4umtfugvn"
        className="animable"
      />
      <path
        d="M258.42,330.47q-2.58,10.14-5.62,20.15l-1.68,5.59c0,.06.09.09.12,0a101.19,101.19,0,0,0,3.57-9.83c1-3.31,2-6.67,2.66-10.06.4-1.94.76-3.9,1.08-5.86A.07.07,0,0,0,258.42,330.47Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "254.835px 343.344px",
        }}
        id="el96hr0kv8qod"
        className="animable"
      />
      <path
        d="M259.28,328.23s-1.41,1.55-.41,2.51,2.91-.22,2.91-.22-.85,1.81.29,2.64,2.81-.72,2.81-.72-.83,1.84.29,2.68,2.72-.79,2.72-.79-.74,1.9.23,2.67,2.41-.89,2.41-.89-.73,1.87.29,2.51,2.45-1.28,2.45-1.28Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "265.891px 333.49px",
        }}
        id="el7hmsxqaa1b4"
        className="animable"
      />
      <path
        d="M258.57,328.63l14.68,9.49s4.87-13.17,5.12-18.53-1.41-7.89-8.66-1.88A82,82,0,0,0,258.57,328.63Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "268.482px 326.175px",
        }}
        id="elou842gg1mzr"
        className="animable"
      />
      <g id="elt765l1hdkkr">
        <path
          d="M273.25,338.12h0c.06-.15,1.4-3.8,2.7-8,.08-.25.16-.51.23-.77.16-.51.31-1,.46-1.54.22-.78.44-1.55.63-2.31.07-.25.13-.5.2-.75a36.06,36.06,0,0,0,.83-4.35c0-.29.06-.55.07-.81.1-2.34-.15-4.15-1.08-4.93L262.85,331.4Z"
          style={{
            opacity: 0.1,
            transformOrigin: "270.62px 326.39px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M239.23,316.13s2.59-8.59,7.21-13.54c3.2-3.42,7.66-8.89,21.31-5.85,3.24.72,8.8,5.67,6.95,7.68s-6.38-3.29-11.08-3.46c0,0,4.71,4.58,3.86,7.8a61.5,61.5,0,0,1-2.81,8.24,11.8,11.8,0,0,1-1.59-.39s-.89,3-5.08,5.21Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "257.149px 308.836px",
        }}
        id="elg6i0gsw5upp"
        className="animable"
      />
      <path
        d="M250,299.81c2.44-.25,7.63-.69,12,.43,4.85,1.24,13.86,4.93,12.91,8.51-.7,2.61-4.27,1.51-4.27,1.51s3.41,2.31,2.26,4.54c-1.05,2-3.75,1-3.75,1s1.82,1.76.43,3.52c-1.66,2.12-6.44-2.74-10-2.05C258.82,317.42,250,299.81,250,299.81Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "262.49px 309.685px",
        }}
        id="eljcupng9kfv"
        className="animable"
      />
      <path
        d="M259.85,305.15a19.92,19.92,0,0,1,10.79,5c.07.07.06.16,0,.11a49,49,0,0,0-10.77-5.08S259.83,305.15,259.85,305.15Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "265.267px 307.712px",
        }}
        id="eliiq99sckr4"
        className="animable"
      />
      <path
        d="M261.72,299.79a19.92,19.92,0,0,1,10.79,5c.07.07.06.16,0,.11a49.77,49.77,0,0,0-10.78-5.08S261.7,299.79,261.72,299.79Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "267.136px 302.352px",
        }}
        id="elmetrvmr5r6g"
        className="animable"
      />
      <path
        d="M258.74,310.08a22.42,22.42,0,0,1,10.47,5.64c.07.08.05.17,0,.12a52.4,52.4,0,0,0-10.45-5.72S258.72,310.08,258.74,310.08Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "263.995px 312.967px",
        }}
        id="ellqraa8rgnys"
        className="animable"
      />
      <path
        d="M262.82,317.65a9.54,9.54,0,0,0-5.74-1.12.06.06,0,0,0,0,.12c1.64.23,3,.49,3,.5-1.85,2.63-5.39,3.92-6.92,4.34-.1,0,0,.17,0,.16,2.85-.19,5.9-1.76,7.09-4.45a23.89,23.89,0,0,0,2.49.53A.05.05,0,0,0,262.82,317.65Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "257.976px 319.057px",
        }}
        id="elonro0cn5zhb"
        className="animable"
      />
      <path
        d="M267.46,363.59,268.51,346s-4.74-5.24-3.69-10c.53-2.4,8.71-20.63,9.51-21.37,3.22-3,8.66-1.81,10.89-.12,12.36,9.34,21.07,29.38,25.25,49.09Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "287.569px 338.195px",
        }}
        id="elzr6dgn2g1x"
        className="animable"
      />
      <path
        d="M267.46,363.59,268.51,346s-4.74-5.24-3.69-10c.53-2.4,8.71-20.63,9.51-21.37,3.22-3,8.66-1.81,10.89-.12,12.36,9.34,21.07,29.38,25.25,49.09Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "287.569px 338.195px",
        }}
        id="el5y2om5amxby"
        className="animable"
      />
      <path
        d="M268.51,346l-1.05,17.62h43c-4.18-19.71-12.89-39.75-25.25-49.09a7.19,7.19,0,0,0-1.57-.88c-2.63-1.11-6.71-1.44-9.32,1-.8.75-9,19-9.51,21.37C263.77,340.73,268.51,346,268.51,346Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "287.56px 338.213px",
        }}
        id="elio10sap576"
        className="animable"
      />
      <path
        d="M268.51,346l-1.05,17.62h43c-4.18-19.71-12.89-39.75-25.25-49.09a7.19,7.19,0,0,0-1.57-.88c-2.63-1.11-6.71-1.44-9.32,1-.8.75-9,19-9.51,21.37C263.77,340.73,268.51,346,268.51,346Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "287.56px 338.213px",
        }}
        id="el87kxkqd1d17"
        className="animable"
      />
      <g id="elstbmz2hfmom">
        <path
          d="M280.49,312.83l10.93,50.76h19.05c-4.18-19.7-12.89-39.75-25.25-49.09a7.19,7.19,0,0,0-1.57-.88A11.18,11.18,0,0,0,280.49,312.83Z"
          style={{
            opacity: 0.1,
            transformOrigin: "295.48px 338.21px",
          }}
          className="animable"
        />
      </g>
      <rect
        x={239.86}
        y={381.68}
        width={10.04}
        height={1.57}
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "244.88px 382.465px",
        }}
        id="el0fdxolyfa9"
        className="animable"
      />
      <rect
        x={239.86}
        y={381.68}
        width={10.04}
        height={1.03}
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "244.88px 382.195px",
        }}
        id="el9uifiabapu6"
        className="animable"
      />
      <path
        d="M261.82,377.46a5.67,5.67,0,0,1-5.65,2.42,5.58,5.58,0,0,1-1-.29,7.23,7.23,0,0,1-1.42-.75L255,377a4.54,4.54,0,0,0,.76.43,3.34,3.34,0,0,0,4.2-1.23c1.37-1.94,1.87-6.35.47-8-.09-.13-.49-.33-2.11,0q-.45.09-1,.24a4.17,4.17,0,0,1-.92.2l0-1.13-.11-1.14.48-.12a14.12,14.12,0,0,1,2.28-.48,3.51,3.51,0,0,1,3.21,1C264.46,369.43,263.54,375,261.82,377.46Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "258.651px 372.831px",
        }}
        id="el3m0nq22h9ws"
        className="animable"
      />
      <path
        d="M255,377a4.54,4.54,0,0,0,.76.43l-.61,2.21a7.23,7.23,0,0,1-1.42-.75Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "254.745px 378.32px",
        }}
        id="elws9crfhaewp"
        className="animable"
      />
      <path
        d="M259,365.69l-.67,2.43q-.45.09-1,.24a4.17,4.17,0,0,1-.92.2l0-1.13-.11-1.14.48-.12A14.12,14.12,0,0,1,259,365.69Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "257.65px 367.125px",
        }}
        id="elxmjjwramxks"
        className="animable"
      />
      <path
        d="M231.53,362.73s1.2,10.24,3.28,14.54,5,4.79,6.79,4.79h6.56c1.81,0,4.71-.48,6.79-4.79s3.29-14.54,3.29-14.54Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "244.885px 372.395px",
        }}
        id="elmgf0em7tc3e"
        className="animable"
      />
      <path
        d="M233.49,369.44a.65.65,0,1,1,.64-.65A.65.65,0,0,1,233.49,369.44Zm0-1.17a.52.52,0,0,0-.53.52.53.53,0,1,0,1.06,0A.52.52,0,0,0,233.49,368.27Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "233.48px 368.79px",
        }}
        id="elyww6y0qreij"
        className="animable"
      />
      <path
        d="M252.52,366.13a.65.65,0,1,1,.65-.64A.64.64,0,0,1,252.52,366.13Zm0-1.17a.52.52,0,0,0-.52.53.52.52,0,0,0,.52.52.52.52,0,0,0,.53-.52A.53.53,0,0,0,252.52,365Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "252.52px 365.48px",
        }}
        id="el4c42w1h3z2g"
        className="animable"
      />
      <path
        d="M251.57,375.56a.65.65,0,1,1,.65-.65A.65.65,0,0,1,251.57,375.56Zm0-1.17a.52.52,0,0,0-.52.52.52.52,0,0,0,.52.53.53.53,0,0,0,.53-.53A.52.52,0,0,0,251.57,374.39Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "251.57px 374.91px",
        }}
        id="elodepsj3yl9s"
        className="animable"
      />
      <path
        d="M256.82,364.44a.64.64,0,1,1,.64-.64A.64.64,0,0,1,256.82,364.44Zm0-1.16a.52.52,0,0,0-.53.52.53.53,0,0,0,.53.53.52.52,0,0,0,.52-.53A.52.52,0,0,0,256.82,363.28Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "256.82px 363.8px",
        }}
        id="eltlo6zylo9ck"
        className="animable"
      />
      <path
        d="M247.86,368.45a.65.65,0,0,1,0-1.29.65.65,0,0,1,0,1.29Zm0-1.17a.53.53,0,0,0-.53.53.52.52,0,0,0,.53.52.52.52,0,0,0,.52-.52A.52.52,0,0,0,247.86,367.28Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "247.86px 367.805px",
        }}
        id="elx8gn7uv5cfb"
        className="animable"
      />
      <path
        d="M240.87,375.29a.65.65,0,1,1,.64-.65A.64.64,0,0,1,240.87,375.29Zm0-1.17a.52.52,0,0,0-.53.52.53.53,0,0,0,.53.53.52.52,0,0,0,.52-.53A.52.52,0,0,0,240.87,374.12Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "240.86px 374.64px",
        }}
        id="el90gfysu186"
        className="animable"
      />
      <path
        d="M245.14,364.44a.64.64,0,0,1-.64-.64.65.65,0,1,1,.64.64Zm0-1.16a.52.52,0,0,0-.52.52.52.52,0,0,0,.52.53.53.53,0,0,0,.53-.53A.52.52,0,0,0,245.14,363.28Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "245.15px 363.79px",
        }}
        id="elwslsz8y9ore"
        className="animable"
      />
      <path
        d="M245.94,372.71a.64.64,0,1,1,.64-.64A.64.64,0,0,1,245.94,372.71Zm0-1.17a.53.53,0,0,0-.53.53.52.52,0,0,0,.53.52.52.52,0,0,0,.52-.52A.52.52,0,0,0,245.94,371.54Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "245.94px 372.07px",
        }}
        id="els2ho5dzkwve"
        className="animable"
      />
      <path
        d="M252.31,380.41a.65.65,0,1,1,.65-.65A.65.65,0,0,1,252.31,380.41Zm0-1.17a.52.52,0,0,0-.52.52.52.52,0,0,0,.52.53.53.53,0,0,0,.53-.53A.52.52,0,0,0,252.31,379.24Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "252.31px 379.76px",
        }}
        id="elb6ybon289xd"
        className="animable"
      />
      <path
        d="M236.68,365.41a.65.65,0,1,1,.64-.65A.64.64,0,0,1,236.68,365.41Zm0-1.17a.53.53,0,1,0,0,1.05.53.53,0,0,0,0-1.05Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "236.67px 364.76px",
        }}
        id="elxkcqkcnp7d"
        className="animable"
      />
      <path
        d="M254.62,371a.65.65,0,0,1,0-1.29.65.65,0,0,1,0,1.29Zm0-1.17a.53.53,0,0,0-.53.53.53.53,0,0,0,1.06,0A.53.53,0,0,0,254.62,369.79Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "254.62px 370.355px",
        }}
        id="el5058tqoijdt"
        className="animable"
      />
      <path
        d="M241.45,369a.64.64,0,0,1-.64-.64.65.65,0,0,1,1.29,0A.65.65,0,0,1,241.45,369Zm0-1.17a.53.53,0,0,0,0,1.06.53.53,0,1,0,0-1.06Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "241.455px 368.395px",
        }}
        id="el3ef03u14zau"
        className="animable"
      />
      <path
        d="M245.94,379.44a.65.65,0,1,1,.64-.65A.64.64,0,0,1,245.94,379.44Zm0-1.17a.52.52,0,0,0-.53.52.53.53,0,0,0,.53.53.52.52,0,0,0,.52-.53A.52.52,0,0,0,245.94,378.27Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "245.93px 378.79px",
        }}
        id="el0lsaam16e64"
        className="animable"
      />
      <path
        d="M240.53,381.76a.65.65,0,1,1,.64-.64A.65.65,0,0,1,240.53,381.76Zm0-1.17a.53.53,0,0,0-.53.53.52.52,0,0,0,.53.52.52.52,0,0,0,.52-.52A.52.52,0,0,0,240.53,380.59Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "240.52px 381.11px",
        }}
        id="el7385wp0zgqf"
        className="animable"
      />
      <path
        d="M236.68,372.13a.65.65,0,1,1,.64-.65A.64.64,0,0,1,236.68,372.13Zm0-1.17a.52.52,0,0,0-.53.52.53.53,0,0,0,.53.53.52.52,0,0,0,.52-.53A.52.52,0,0,0,236.68,371Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "236.67px 371.48px",
        }}
        id="elwkdfgdeh8ha"
        className="animable"
      />
      <path
        d="M236.09,377.93a.65.65,0,1,1,.65-.65A.65.65,0,0,1,236.09,377.93Zm0-1.17a.52.52,0,0,0-.52.52.52.52,0,0,0,.52.53.53.53,0,0,0,.53-.53A.52.52,0,0,0,236.09,376.76Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "236.09px 377.28px",
        }}
        id="elysimgtgqrr"
        className="animable"
      />
      <path
        d="M291.31,318.45c15,11.31,34.41,42.09,31.53,49.44-2.62,6.71-25.69,13.45-39.27,15.31-2.58.35-4.39-21.7-1.65-22.18,4.92-.87,16.06-5.65,16.39-5.73.71-.18-12-24-14.27-32.51C281.43,313.16,284.74,313.5,291.31,318.45Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "301.84px 349.126px",
        }}
        id="elbccsjz54cec"
        className="animable"
      />
      <path
        d="M254.39,366.12c.27,2.48,6.34,1.3,6.34,1.3a4.62,4.62,0,0,0-1.58,2.71c-.26,1.88,2.58,3.11,2.58,3.11a5.68,5.68,0,0,0-1.21,3.12c-.07,1.82,2.62,3.47,2.62,3.47a5,5,0,0,0,.66,3.41c1.78,2.67,6.67,2.53,9.78,2.47s11.86-3.14,13.59-3.56L287,362.2s-14-3.7-17.66-3.11C266.24,359.59,254,362.32,254.39,366.12Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "270.775px 372.38px",
        }}
        id="elvy88z5ikose"
        className="animable"
      />
      <path
        d="M260.77,367.35a41.83,41.83,0,0,1,9.92-2.37.06.06,0,1,1,0,.11c-3.32.46-6.59,1.82-9.88,2.41C260.73,367.52,260.69,367.38,260.77,367.35Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "265.75px 366.238px",
        }}
        id="el6ilemxfh5aa"
        className="animable"
      />
      <path
        d="M261.79,373a8.85,8.85,0,0,1,2.29-.38c.82-.09,1.63-.15,2.45-.21,1.64-.11,3.29-.18,4.93-.2.08,0,.1.11,0,.12-1.64.11-3.27.24-4.9.42l-2.44.29a10.64,10.64,0,0,1-2.32.19A.12.12,0,0,1,261.79,373Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "266.618px 372.722px",
        }}
        id="elq7imgj5rps"
        className="animable"
      />
      <path
        d="M263.21,379.82a6.31,6.31,0,0,1,2.08-.24l2.24-.11c1.5-.09,3-.17,4.49-.31.07,0,.09.11,0,.12-1.51.14-3,.31-4.54.44l-2.24.19a5.82,5.82,0,0,1-2,0A0,0,0,0,1,263.21,379.82Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "267.645px 379.578px",
        }}
        id="el1mclteig9he"
        className="animable"
      />
      <path
        d="M301.29,326.85s1.52,1.45.58,2.48-2.91,0-2.91,0,1,1.74-.11,2.65-2.85-.53-2.85-.53.95,1.79-.11,2.7-2.77-.6-2.77-.6.87,1.84,0,2.68-2.47-.72-2.47-.72.85,1.82-.11,2.52S288,336.9,288,336.9Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "295.088px 332.52px",
        }}
        id="el1j2afmuz5yq"
        className="animable"
      />
      <path
        d="M302,327.2l-14,10.48s-5.76-12.8-6.37-18.13-1.11-8.89,7-3.52A82.52,82.52,0,0,1,302,327.2Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "291.663px 325.523px",
        }}
        id="el3k4gatz3szw"
        className="animable"
      />
      <path
        d="M274.28,314.38s-.71,6.4.45,6.58c3.58.56,7.89-4.15,8.16-4.92.07-.2.08-5.21.08-9.1v-4.55l-9.53,4.71.78,6.79Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "278.205px 311.698px",
        }}
        id="elv4iy2ejwddp"
        className="animable"
      />
      <path
        d="M273.44,307.1l.78,6.79a13.68,13.68,0,0,0,8.75-6.95v-4.55l-1.2.54Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "278.205px 308.14px",
        }}
        id="elnzdhpeq6ftc"
        className="animable"
      />
      <path
        d="M265.3,296.35c-1.83,11.86,5.06,14.3,7.61,14.69,2.33.35,10.27,1.27,13.32-10.33s-1.88-16.2-7.14-17.29S267.13,284.5,265.3,296.35Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "276.081px 297.193px",
        }}
        id="elb75ac2mgxum"
        className="animable"
      />
      <path
        d="M272.15,310.84a8.64,8.64,0,0,1-5-3.83,13.56,13.56,0,0,1-1.93-7,26.83,26.83,0,0,1,.23-4.29s-.05-.05-.05,0a17.26,17.26,0,0,0-.19,8,10.08,10.08,0,0,0,3.61,5.77,9.13,9.13,0,0,0,3.34,1.48A.05.05,0,0,0,272.15,310.84Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "268.524px 303.334px",
        }}
        id="elv77j9rsxh8"
        className="animable"
      />
      <path
        d="M267.77,294.89c.28,0,.54-.07.82-.1a1.41,1.41,0,0,0,.82-.27.44.44,0,0,0,0-.52,1.07,1.07,0,0,0-1-.33,1.58,1.58,0,0,0-1,.46A.46.46,0,0,0,267.77,294.89Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "268.397px 294.27px",
        }}
        id="el6nfsqq4oaxf"
        className="animable"
      />
      <path
        d="M276,295.84c-.28-.06-.53-.16-.8-.24a1.37,1.37,0,0,1-.76-.4.44.44,0,0,1,0-.52,1.06,1.06,0,0,1,1.06-.16,1.57,1.57,0,0,1,.93.63A.46.46,0,0,1,276,295.84Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "275.424px 295.143px",
        }}
        id="el4wyd0ss05e8"
        className="animable"
      />
      <path
        d="M274.17,298.72s.06,0,.06.08c-.12.81-.11,1.75.6,2.1a0,0,0,0,1,0,0C273.93,300.72,273.93,299.47,274.17,298.72Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "274.432px 299.81px",
        }}
        id="el3u5cqpiy31n"
        className="animable"
      />
      <path
        d="M275,298c1.31,0,1.14,2.67-.08,2.63S273.88,297.91,275,298Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "274.995px 299.314px",
        }}
        id="eli59du5ngvu"
        className="animable"
      />
      <path
        d="M275.49,298.12c.22.17.43.44.71.49s.58-.2.8-.46c0,0,.05,0,.05,0,0,.5-.26,1-.81,1.05s-.82-.44-.91-1C275.32,298.19,275.41,298.06,275.49,298.12Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "276.19px 298.654px",
        }}
        id="ela2176183h3b"
        className="animable"
      />
      <path
        d="M269.09,298s-.06,0-.07.07c0,.81-.18,1.75-.94,2,0,0,0,0,0,0C269,300,269.2,298.78,269.09,298Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "268.599px 299.035px",
        }}
        id="elbhr4ww9v4as"
        className="animable"
      />
      <path
        d="M268.41,297.1c-1.3-.17-1.56,2.45-.35,2.62S269.51,297.25,268.41,297.1Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "268.198px 298.41px",
        }}
        id="eldfltcw8y65"
        className="animable"
      />
      <path
        d="M267.85,297.22c-.22.12-.45.35-.7.35s-.47-.32-.63-.61c0,0,0,0,0,0-.07.5,0,1,.52,1.19s.8-.27,1-.76C268,297.32,267.94,297.18,267.85,297.22Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "267.269px 297.577px",
        }}
        id="el463k7w2im7t"
        className="animable"
      />
      <path
        d="M273.34,304.94c-.19.19-.4.45-.69.47a2.25,2.25,0,0,1-.88-.23s0,0,0,0a1.08,1.08,0,0,0,1,.47.85.85,0,0,0,.66-.71C273.44,304.93,273.37,304.92,273.34,304.94Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "272.6px 305.292px",
        }}
        id="el7is73ruxi98"
        className="animable"
      />
      <path
        d="M270.18,301.73s-.27,1.2-.32,1.77c0,.06.14.09.33.12h0a2.75,2.75,0,0,0,2.73-.8c.05-.06,0-.12-.08-.09a4.11,4.11,0,0,1-2.54.52c0-.17.52-2.05.43-2.06a4.69,4.69,0,0,0-1.33.19c.48-2.52,1.36-4.95,1.8-7.46,0-.09-.11-.13-.14-.05a40.69,40.69,0,0,0-2.28,7.95C268.72,302.14,269.94,301.81,270.18,301.73Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "270.859px 298.769px",
        }}
        id="elo0yhu2uwg5"
        className="animable"
      />
      <path
        d="M274.3,302.78a3.75,3.75,0,0,1-2.63-4.59h0a3.74,3.74,0,1,1,2.63,4.59Zm-2.15-4.46a3.24,3.24,0,1,0,4-2.28,3.16,3.16,0,0,0-.8-.12A3.26,3.26,0,0,0,272.15,298.32Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "275.28px 299.17px",
        }}
        id="elfems40ro7jq"
        className="animable"
      />
      <path
        d="M266.47,301.71c-1.53-.2-2.54-2-2.27-4.08a4.49,4.49,0,0,1,1.13-2.49,2.34,2.34,0,0,1,4,.53,4.49,4.49,0,0,1,.44,2.7c-.26,1.94-1.59,3.38-3,3.36Zm-.78-6.22a4,4,0,0,0-1,2.21c-.23,1.77.59,3.35,1.84,3.52s2.46-1.14,2.7-2.92a4,4,0,0,0-.38-2.39,2,2,0,0,0-1.46-1.12l-.19,0A2,2,0,0,0,265.69,295.49Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "266.988px 297.985px",
        }}
        id="eln5vi6de53b"
        className="animable"
      />
      <path
        d="M271.74,298.63l.37-.33c-.5-.55-1.72-1.36-2.76-.51l.32.38C270.6,297.41,271.69,298.58,271.74,298.63Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "270.73px 298.025px",
        }}
        id="ely8j1v9ldrs"
        className="animable"
      />
      <path
        d="M288.15,301.79l.47-.17c-.08-.22-.81-2.15-1.89-2.48s-7.75-.57-8-.58l0,.5c1.93.08,7.12.32,7.91.56S288,301.26,288.15,301.79Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "283.675px 300.175px",
        }}
        id="elm0jj1goybm9"
        className="animable"
      />
      <path
        d="M285.59,300c-2.77-.13-2.5-4.5-2.5-4.5S280,295,280,289.92a7.7,7.7,0,0,1-6,3.36c-.91.08,2.24-2.09,2.24-2.09s-3.81,3.08-10.46,1.45c-.47-.11.95-7.15,6.36-9.26a11.22,11.22,0,0,1,13.16,3s3.76,2,4,5.68S286.67,300,285.59,300Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "277.505px 291.2px",
        }}
        id="elpiqe3x8isb"
        className="animable"
      />
      <path
        d="M284.21,301.33s2.86-3.56,4.49-2.51-.69,6.32-2.73,6.93a2,2,0,0,1-2.64-1.27Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "286.29px 302.257px",
        }}
        id="el8ubeidu4p85"
        className="animable"
      />
      <path
        d="M288,300.31s0,.05,0,.06a5.08,5.08,0,0,0-2.86,3,1.12,1.12,0,0,1,1.69-.19s0,.08,0,.07a1.25,1.25,0,0,0-1.4.34,6,6,0,0,0-.73,1.07c-.08.12-.3,0-.24-.11v0C284.53,302.76,286.08,300.36,288,300.31Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "286.225px 302.51px",
        }}
        id="elytot90acsnf"
        className="animable"
      />
      <path
        d="M284.88,305.15a.41.41,0,0,0,.4.43.42.42,0,0,0,.44-.4.41.41,0,0,0-.4-.43A.42.42,0,0,0,284.88,305.15Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "285.3px 305.165px",
        }}
        id="el06cw1su2frbl"
        className="animable"
      />
      <path
        d="M286.09,304.47a.26.26,0,0,0,.25.28.28.28,0,0,0,.28-.26.26.26,0,0,0-.26-.27A.25.25,0,0,0,286.09,304.47Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "286.355px 304.485px",
        }}
        id="el4y01k15as1"
        className="animable"
      />
      <path
        d="M288.41,301.18l.8.23a.16.16,0,0,0,.21-.11h0a.17.17,0,0,0-.11-.21l-.81-.23a.16.16,0,0,0-.21.11h0A.18.18,0,0,0,288.41,301.18Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "288.856px 301.135px",
        }}
        id="el6ncm9xdneqg"
        className="animable"
      />
      <path
        d="M288.54,300.66l.83.11a.17.17,0,0,0,.19-.14h0a.17.17,0,0,0-.14-.19l-.84-.12a.16.16,0,0,0-.18.15h0A.17.17,0,0,0,288.54,300.66Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "288.98px 300.545px",
        }}
        id="elha174wtssxa"
        className="animable"
      />
      <path
        d="M273.42,316.15c-.08.71-.14,1.41-.15,2.11a11.18,11.18,0,0,0,.1,2.09,3.41,3.41,0,0,0,.75,1.84,1.79,1.79,0,0,0,1.83.23,12.67,12.67,0,0,0,3.67-1.93c.58-.4,1.13-.84,1.67-1.29a16.15,16.15,0,0,0,1.52-1.46,15.73,15.73,0,0,1-3,3A15.32,15.32,0,0,1,278,321.9a9.15,9.15,0,0,1-2,.87,3.35,3.35,0,0,1-1.1.12,1.82,1.82,0,0,1-.57-.13,1.67,1.67,0,0,1-.48-.34,2.38,2.38,0,0,1-.56-1,4.37,4.37,0,0,1-.23-1.06A13.7,13.7,0,0,1,273.42,316.15Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "277.918px 319.523px",
        }}
        id="elhl2esxkoddi"
        className="animable"
      />
      <path
        d="M283.63,287.48c-2-1.32-7-11.47,1.66-16.93s19.78,5.43,13.76,14.13C295,290.52,287.9,290.29,283.63,287.48Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "290.567px 279.239px",
        }}
        id="elk7o25rff0d9"
        className="animable"
      />
      <rect
        x={111.07}
        y={257.2}
        width={59.87}
        height={141.57}
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "141.005px 327.985px",
        }}
        id="el5wzeoylhms9"
        className="animable"
      />
      <path
        d="M127.32,270.93v4.54a.44.44,0,0,1-.43.43.42.42,0,0,1-.42-.43v-3.24l-1.11,1.54a.45.45,0,0,1-.35.18h0a.47.47,0,0,1-.36-.18l-1.1-1.54v3.24a.43.43,0,0,1-.43.43.44.44,0,0,1-.43-.43v-4.54a.43.43,0,0,1,.43-.43h0a.42.42,0,0,1,.34.18l1.53,2.13,1.54-2.13a.39.39,0,0,1,.34-.18h0A.44.44,0,0,1,127.32,270.93Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "125.005px 273.2px",
        }}
        id="elwe0d8re6nq9"
        className="animable"
      />
      <path
        d="M134.25,271.17a2.83,2.83,0,0,1,0,3.9,2.52,2.52,0,0,1-1.84.83,2.61,2.61,0,0,1-1.89-.83,2.88,2.88,0,0,1,0-3.9,2.57,2.57,0,0,1,1.89-.82A2.47,2.47,0,0,1,134.25,271.17Zm-.1,1.95a2,2,0,0,0-.52-1.35,1.68,1.68,0,0,0-2.47,0,2,2,0,0,0-.52,1.35,1.93,1.93,0,0,0,.52,1.34,1.64,1.64,0,0,0,2.47,0A2,2,0,0,0,134.15,273.12Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "132.394px 273.125px",
        }}
        id="el07s3c0966f2m"
        className="animable"
      />
      <path
        d="M138.35,275.47a.43.43,0,0,1-.41.43.42.42,0,0,1-.42-.43V271h0a.41.41,0,0,1,.75-.27l2.49,3.45V271a.42.42,0,0,1,.42-.42.42.42,0,0,1,.42.42v4.47a.41.41,0,0,1-.74.27l0,0-2.47-3.43Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "139.559px 273.227px",
        }}
        id="elh9rv6qzon5"
        className="animable"
      />
      <path
        d="M147.83,271.36H146.6v4.11a.42.42,0,0,1-.42.43.43.43,0,0,1-.43-.43v-4.11h-1.21a.43.43,0,0,1-.43-.43.43.43,0,0,1,.43-.43h3.29a.42.42,0,0,1,.42.43A.43.43,0,0,1,147.83,271.36Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "146.18px 273.2px",
        }}
        id="eltg0szx5axtc"
        className="animable"
      />
      <path
        d="M154,271.36h-1.23v4.11a.42.42,0,0,1-.42.43.42.42,0,0,1-.42-.43v-4.11h-1.22a.42.42,0,0,1-.42-.43.42.42,0,0,1,.42-.43H154a.42.42,0,0,1,.42.43A.43.43,0,0,1,154,271.36Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "152.355px 273.2px",
        }}
        id="elgmz9x3c8huo"
        className="animable"
      />
      <path
        d="M123.53,285.47l-.57.79s0,0,0,0h0s0,0-.05,0l-.58-.79v1.47s0,.07-.06.07a.07.07,0,0,1-.06-.07v-1.66s0,0,0,0h0a.07.07,0,0,1,.09,0l.63.88.64-.88a.07.07,0,0,1,.09,0l0,0v1.69a.07.07,0,0,1-.07.07.07.07,0,0,1-.06-.07Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "122.935px 286.152px",
        }}
        id="el7emvgp4heve"
        className="animable"
      />
      <path
        d="M124.57,285.27a.07.07,0,0,1,.07-.06h.92a.06.06,0,0,1,.06.06.07.07,0,0,1-.06.07h-.86v.71h.75a.06.06,0,1,1,0,.12h-.75v.71h.86a.06.06,0,0,1,.06.06.07.07,0,0,1-.06.07h-.93a.07.07,0,0,1-.06-.07Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "125.095px 286.11px",
        }}
        id="el0lgata81oou"
        className="animable"
      />
      <path
        d="M126.61,285.48v1.46s0,.07-.06.07a.07.07,0,0,1-.06-.07V285.3a.06.06,0,0,1,0-.07.07.07,0,0,1,.09,0l1.09,1.5v-1.46a.06.06,0,0,1,.06-.06.06.06,0,0,1,.06.06v1.65s0,.07-.06.07a.05.05,0,0,1,0,0h0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "127.134px 286.11px",
        }}
        id="elgskdkfk8h8q"
        className="animable"
      />
      <path
        d="M128.72,285.27a.07.07,0,0,1,.07-.06.06.06,0,0,1,.06.06v1a.6.6,0,1,0,1.2,0v-1a.06.06,0,0,1,.06-.06.07.07,0,0,1,.07.06v1a.73.73,0,1,1-1.46,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "129.45px 286.105px",
        }}
        id="el134ah0zigix"
        className="animable"
      />
      <path
        d="M123,294.6a.07.07,0,0,1-.07-.06.07.07,0,0,1,.07-.07h.56a.07.07,0,0,1,0,.07v.61h0a.08.08,0,0,1,0,0,.91.91,0,0,1-.58.2.93.93,0,0,1-.93-.92.92.92,0,0,1,.93-.9.89.89,0,0,1,.58.2.06.06,0,0,1,0,.09.07.07,0,0,1-.09,0,.78.78,0,0,0-.5-.18.79.79,0,1,0,0,1.57.81.81,0,0,0,.48-.15v-.53Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "122.815px 294.44px",
        }}
        id="el9acd5q6wjrg"
        className="animable"
      />
      <path
        d="M125.61,294.94h-.82l-.16.43a.07.07,0,0,1-.06,0h0a.07.07,0,0,1,0-.08l.63-1.66a.06.06,0,0,1,.12,0l.62,1.66a.06.06,0,0,1,0,.08h0a.07.07,0,0,1-.06,0Zm-.77-.12h.72l-.36-1Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "125.256px 294.473px",
        }}
        id="elpv5b9ve4l8n"
        className="animable"
      />
      <path
        d="M126.74,295.41h0a.06.06,0,0,1-.06-.06v-1.68a.06.06,0,0,1,.06-.06.07.07,0,0,1,.07.06v1.61h.86a.07.07,0,0,1,.06.07.06.06,0,0,1-.06.06Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "127.205px 294.51px",
        }}
        id="elnx4qeyadj3"
        className="animable"
      />
      <path
        d="M128.57,295.41h0a.06.06,0,0,1-.06-.06v-1.68a.06.06,0,0,1,.06-.06.07.07,0,0,1,.07.06v1.61h.86a.07.07,0,0,1,.06.07.06.06,0,0,1-.06.06Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "129.035px 294.51px",
        }}
        id="elft96dqmb1ef"
        className="animable"
      />
      <path
        d="M130.34,293.67a.06.06,0,0,1,.06-.06h.92a.06.06,0,0,1,.06.06s0,.07-.06.07h-.86v.71h.75a.06.06,0,0,1,.06.06.05.05,0,0,1-.06.06h-.75v.71h.86s.06,0,.06.07a.06.06,0,0,1-.06.06h-.93a.06.06,0,0,1,0-.06Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "130.86px 294.51px",
        }}
        id="elx57jl527b3"
        className="animable"
      />
      <path
        d="M133.19,295a.32.32,0,0,0-.15-.27.56.56,0,0,0-.21-.09h-.44v.71a.07.07,0,0,1-.07.06.06.06,0,0,1-.06-.06v-1.68s0-.05.06-.06h.51a.51.51,0,0,1,.51.52.51.51,0,0,1-.3.46h0a.46.46,0,0,1,.24.4.84.84,0,0,0,0,.21.07.07,0,0,0,0,0,.08.08,0,0,1,.07,0,.08.08,0,0,1,0,.09h0a.14.14,0,0,1-.11-.08A.67.67,0,0,1,133.19,295Zm-.36-1.26h-.44v.77h.45a.38.38,0,0,0,.38-.38A.39.39,0,0,0,132.83,293.74Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "132.812px 294.51px",
        }}
        id="elzil2fat7scd"
        className="animable"
      />
      <path
        d="M134.71,294.55l-.6-.83a.05.05,0,0,1,0-.08s.06,0,.08,0l.56.77.57-.77s.06,0,.08,0a.05.05,0,0,1,0,.08l-.6.82v.81a.06.06,0,0,1-.06.06.07.07,0,0,1-.07-.06Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "134.755px 294.525px",
        }}
        id="elnuf4xcsoup"
        className="animable"
      />
      <path
        d="M123.13,303.35h-.82l-.16.42a.07.07,0,0,1-.06,0h0s0-.05,0-.08l.62-1.67a.06.06,0,0,1,.12,0l.62,1.67s0,.06,0,.08h0a.08.08,0,0,1-.06,0Zm-.77-.13h.72l-.36-1Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "122.77px 302.868px",
        }}
        id="elo884r2rv5yo"
        className="animable"
      />
      <path
        d="M124.19,302.08s0-.06.07-.06h.4a.44.44,0,0,1,.27.79.52.52,0,0,1,.35.49.51.51,0,0,1-.51.51h-.52a.06.06,0,0,1-.06-.06Zm.47.7a.32.32,0,0,0,.32-.32.33.33,0,0,0-.32-.32h-.34v.64Zm.11.9a.39.39,0,1,0,0-.77h-.45v.77Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "124.735px 302.915px",
        }}
        id="elahruv4kr8fs"
        className="animable"
      />
      <path
        d="M126.89,302a.91.91,0,1,1-.86.91A.88.88,0,0,1,126.89,302Zm0,1.69a.78.78,0,1,0-.74-.78A.75.75,0,0,0,126.89,303.68Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "126.94px 302.909px",
        }}
        id="eltmisemo689j"
        className="animable"
      />
      <path
        d="M128.56,302.08a.06.06,0,0,1,.06-.06s.07,0,.07.06v1a.6.6,0,0,0,1.2,0v-1a.06.06,0,0,1,.12,0v1a.72.72,0,0,1-.72.73.73.73,0,0,1-.73-.73Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "129.285px 302.915px",
        }}
        id="elubjdleqx8r"
        className="animable"
      />
      <path
        d="M131.42,302.14h-.54a.06.06,0,0,1-.06-.06.06.06,0,0,1,.06-.06h1.21a.06.06,0,0,1,.06.06.06.06,0,0,1-.06.06h-.54v1.61a.07.07,0,0,1-.07.06.06.06,0,0,1-.06-.06Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "131.485px 302.915px",
        }}
        id="elrmrh0vs6pmn"
        className="animable"
      />
      <path
        d="M134.17,302.08a.06.06,0,0,1,.06-.06.05.05,0,0,1,.06.06v1a.6.6,0,0,0,.6.6.59.59,0,0,0,.6-.6v-1s0-.06.07-.06a.06.06,0,0,1,.06.06v1a.73.73,0,0,1-.73.73.72.72,0,0,1-.72-.73Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "134.895px 302.914px",
        }}
        id="elayqz38ckgyr"
        className="animable"
      />
      <path
        d="M136.56,303.47a.06.06,0,0,1,.08,0,1.05,1.05,0,0,0,.59.23.7.7,0,0,0,.4-.12.33.33,0,0,0,.15-.27.27.27,0,0,0-.15-.24,1,1,0,0,0-.41-.15h0a1.22,1.22,0,0,1-.42-.15.38.38,0,0,1-.19-.31.43.43,0,0,1,.19-.34.79.79,0,0,1,.44-.13,1,1,0,0,1,.55.21.06.06,0,0,1,0,.08,0,0,0,0,1-.07,0,.92.92,0,0,0-.49-.19.68.68,0,0,0-.37.11.33.33,0,0,0-.14.25.24.24,0,0,0,.13.21.89.89,0,0,0,.39.14h0a1.38,1.38,0,0,1,.46.16.43.43,0,0,1,.19.34.45.45,0,0,1-.2.36.76.76,0,0,1-.46.14,1.1,1.1,0,0,1-.66-.26A.06.06,0,0,1,136.56,303.47Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "137.221px 302.895px",
        }}
        id="el8gqne0qt97b"
        className="animable"
      />
      <path
        d="M122.15,310.48a.06.06,0,0,1,.06-.06h.93a.06.06,0,0,1,.06.06.06.06,0,0,1-.06.06h-.87v.71H123a.07.07,0,0,1,.06.07.06.06,0,0,1-.06.06h-.76v.77a.06.06,0,0,1-.06.06.06.06,0,0,1-.06-.06Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "122.66px 311.315px",
        }}
        id="el3iz2heshyvd"
        className="animable"
      />
      <path
        d="M124.86,311.75h-.81l-.16.42a.06.06,0,0,1-.06,0h0a.07.07,0,0,1,0-.08l.62-1.67a.07.07,0,0,1,.06,0,.08.08,0,0,1,.07,0l.62,1.67a.06.06,0,0,1,0,.08h0a.06.06,0,0,1-.06,0Zm-.77-.13h.73l-.36-1Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "124.516px 311.295px",
        }}
        id="elydd5nlvb8zk"
        className="animable"
      />
      <path
        d="M127.71,312.43a.63.63,0,0,1-.4.15h0a.88.88,0,0,1-.45-.14.38.38,0,0,0-.21-.06.44.44,0,0,0-.24.06h0a.08.08,0,0,1-.08,0h0l0,0a.06.06,0,0,1,0-.08l.24-.11a.91.91,0,1,1,1-.9.93.93,0,0,1-.52.84l-.26.12a.44.44,0,0,1,.15.05.77.77,0,0,0,.39.14.53.53,0,0,0,.32-.13.06.06,0,0,1,.08.1Zm-1-.34a.79.79,0,1,0-.73-.78A.76.76,0,0,0,126.69,312.09Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "126.752px 311.507px",
        }}
        id="el4338zs22k6y"
        className="animable"
      />
      <path
        d="M122.07,320.28a.05.05,0,0,1,.08,0,1,1,0,0,0,.59.23.62.62,0,0,0,.39-.12.31.31,0,0,0,.15-.26.29.29,0,0,0-.14-.25,1.21,1.21,0,0,0-.42-.14h0a1,1,0,0,1-.43-.16.37.37,0,0,1-.18-.31.4.4,0,0,1,.19-.33.67.67,0,0,1,.43-.14,1,1,0,0,1,.55.21.06.06,0,0,1-.06.1.89.89,0,0,0-.49-.19.6.6,0,0,0-.37.11.29.29,0,0,0-.14.24.29.29,0,0,0,.14.22,1.27,1.27,0,0,0,.38.14h0a1.14,1.14,0,0,1,.45.16.4.4,0,0,1,.2.34.46.46,0,0,1-.2.36.86.86,0,0,1-.46.14,1.11,1.11,0,0,1-.67-.26A.06.06,0,0,1,122.07,320.28Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "122.717px 319.715px",
        }}
        id="eljwxxgch4op"
        className="animable"
      />
      <path
        d="M125.3,319.78h-.93v.77a.06.06,0,1,1-.12,0v-1.67a.06.06,0,0,1,.12,0v.78h.93v-.78a.06.06,0,0,1,.06-.06.06.06,0,0,1,.06.06v1.67a.07.07,0,0,1-.06.07s-.06,0-.06-.07Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "124.835px 319.72px",
        }}
        id="el0mzzc1s8pwn"
        className="animable"
      />
      <path
        d="M127.16,318.79a.92.92,0,1,1-.86.92A.89.89,0,0,1,127.16,318.79Zm0,1.7a.79.79,0,0,0,0-1.57.79.79,0,0,0,0,1.57Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "127.22px 319.708px",
        }}
        id="els6bnx0pg9nh"
        className="animable"
      />
      <path
        d="M129,318.88a.06.06,0,0,1,.06-.06h.51a.51.51,0,0,1,.51.51.51.51,0,0,1-.51.51h-.45v.7a.06.06,0,1,1-.12,0Zm.57.84a.39.39,0,0,0,0-.77h-.45v.77Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "129.54px 319.71px",
        }}
        id="eldfdjohkno7"
        className="animable"
      />
      <rect
        x={325.91}
        y={270.43}
        width={6.2}
        height={1}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "329.01px 270.93px",
        }}
        id="el3tallhd9m16"
        className="animable"
      />
      <rect
        x={325.91}
        y={274.9}
        width={6.2}
        height={1}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "329.01px 275.4px",
        }}
        id="els0de2gs9vdd"
        className="animable"
      />
      <rect
        x={325.91}
        y={272.69}
        width={6.2}
        height={1}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "329.01px 273.19px",
        }}
        id="el6zxqne0c3ww"
        className="animable"
      />
      <rect
        x={111.07}
        y={251.54}
        width={232.53}
        height={5.67}
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "227.335px 254.375px",
        }}
        id="el9qxep9lylc7"
        className="animable"
      />
      <path
        d="M115.58,254.37a1.41,1.41,0,1,1-1.41-1.4A1.41,1.41,0,0,1,115.58,254.37Z"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "114.17px 254.38px",
        }}
        id="el3liblbbzoax"
        className="animable"
      />
      <circle
        cx={119.91}
        cy={254.37}
        r={1.41}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "119.91px 254.37px",
        }}
        id="el35z39ajj2x4"
        className="animable"
      />
      <path
        d="M127.05,254.37a1.41,1.41,0,1,1-1.4-1.4A1.41,1.41,0,0,1,127.05,254.37Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "125.64px 254.38px",
        }}
        id="el4xadzcvtfgw"
        className="animable"
      />
      <rect
        x={337.38}
        y={257.2}
        width={6.22}
        height={141.57}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "340.49px 327.985px",
        }}
        id="elg7twggendrh"
        className="animable"
      />
      <path
        d="M340.49,331.29h0a1.61,1.61,0,0,1-1.61-1.6V260.21a1.61,1.61,0,0,1,1.61-1.6h0a1.61,1.61,0,0,1,1.61,1.6v69.48A1.61,1.61,0,0,1,340.49,331.29Z"
        style={{
          fill: "rgb(166, 166, 166)",
          transformOrigin: "340.49px 294.95px",
        }}
        id="eljelgxezo35"
        className="animable"
      />
    </g>
    <g
      id="freepik--Tablet--inject-409"
      className="animable"
      style={{
        transformOrigin: "614.77px 328.64px",
      }}
    >
      <path
        d="M665,415.82H564.56a8.18,8.18,0,0,1-8.11-8.25V249.7a8.18,8.18,0,0,1,8.11-8.24H665a8.17,8.17,0,0,1,8.09,8.24V407.57A8.17,8.17,0,0,1,665,415.82Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "614.77px 328.64px",
        }}
        id="elnkxvsla8bht"
        className="animable"
      />
      <rect
        x={561.51}
        y={253.77}
        width={106.69}
        height={150.08}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "614.855px 328.81px",
        }}
        id="elv3bdq5aet3g"
        className="animable"
      />
      <rect
        x={561.51}
        y={253.77}
        width={106.69}
        height={150.08}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "614.855px 328.81px",
        }}
        id="elfpv9yocozrl"
        className="animable"
      />
      <polygon
        points="572.15 358.42 582.81 358.32 593.47 358.26 614.79 358.22 636.11 358.26 646.77 358.32 657.43 358.42 646.77 358.51 636.11 358.57 614.79 358.61 593.47 358.57 582.81 358.51 572.15 358.42"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "614.79px 358.415px",
        }}
        id="el7ydx29ybshs"
        className="animable"
      />
      <path
        d="M578.05,335.19a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,578.05,335.19Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "578.04px 336.39px",
        }}
        id="eljlkjo2cgf2g"
        className="animable"
      />
      <path
        d="M575.65,332.79a1.16,1.16,0,0,0-.31-.78l-.94,2h.05A1.2,1.2,0,0,0,575.65,332.79Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "575.025px 333.01px",
        }}
        id="elvxo25z5d2"
        className="animable"
      />
      <path
        d="M578.05,328a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,578.05,328Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "578.04px 329.2px",
        }}
        id="elcn1dkx50of"
        className="animable"
      />
      <path
        d="M586.43,336.38a1.2,1.2,0,0,0-2.4,0,1.17,1.17,0,0,0,.32.79l1.91-.21A1.23,1.23,0,0,0,586.43,336.38Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "585.23px 336.175px",
        }}
        id="elyz7a9hn3pik"
        className="animable"
      />
      <path
        d="M585.23,328a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,585.23,328Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "585.23px 329.2px",
        }}
        id="eltnursy2duak"
        className="animable"
      />
      <path
        d="M581.64,331.59a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,581.64,331.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "581.63px 332.79px",
        }}
        id="el6p9k2hsnv5"
        className="animable"
      />
      <path
        d="M585.23,320.82a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,585.23,320.82Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "585.23px 322.02px",
        }}
        id="el2qzka2spjy2"
        className="animable"
      />
      <path
        d="M581.64,324.41a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "581.531px 325.605px",
        }}
        id="el3szsog5j52e"
        className="animable"
      />
      <path
        d="M582.83,318.42a1.16,1.16,0,0,0-.51-.95l-1,2.08a1.07,1.07,0,0,0,.32.07A1.19,1.19,0,0,0,582.83,318.42Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "582.075px 318.545px",
        }}
        id="el67zqg0tvqd"
        className="animable"
      />
      <path
        d="M585.23,313.64a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,585.23,313.64Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "585.23px 314.84px",
        }}
        id="el02tek6z07yj9"
        className="animable"
      />
      <path
        d="M592.41,335.19a1.19,1.19,0,0,0-1.19,1.19v0l2.34-.26A1.17,1.17,0,0,0,592.41,335.19Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "592.39px 335.785px",
        }}
        id="elwv6l8v2ym4o"
        className="animable"
      />
      <path
        d="M592.41,328a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,592.41,328Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "592.41px 329.2px",
        }}
        id="ele7wlryfsfup"
        className="animable"
      />
      <path
        d="M588.82,331.59a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,588.82,331.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "588.82px 332.79px",
        }}
        id="el24m9hxju1pu"
        className="animable"
      />
      <path
        d="M592.41,320.82a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,592.41,320.82Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "592.41px 322.02px",
        }}
        id="elp5ra536419"
        className="animable"
      />
      <path
        d="M588.82,324.41a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,588.82,324.41Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "588.82px 325.61px",
        }}
        id="elo321thxquh"
        className="animable"
      />
      <path
        d="M592.41,313.64a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "592.519px 314.835px",
        }}
        id="elwlw23vocb8f"
        className="animable"
      />
      <path
        d="M588.82,317.23a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "588.929px 318.425px",
        }}
        id="eldsnl7m8uaih"
        className="animable"
      />
      <path
        d="M592.41,306.45a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,592.41,306.45Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "592.41px 307.65px",
        }}
        id="el164eok6mi6c"
        className="animable"
      />
      <path
        d="M588.82,310a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,588.82,310Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "588.82px 311.2px",
        }}
        id="el8pvqvj40qo8"
        className="animable"
      />
      <path
        d="M590,304.06a1.21,1.21,0,0,0-.74-1.11l-1,2.14a1.18,1.18,0,0,0,.56.16A1.19,1.19,0,0,0,590,304.06Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "589.13px 304.1px",
        }}
        id="el2f6ko1urx3x"
        className="animable"
      />
      <path
        d="M599.6,335.19a1.19,1.19,0,0,0-.87.38l1.52-.17A1.22,1.22,0,0,0,599.6,335.19Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "599.49px 335.38px",
        }}
        id="el2mswf9hnddp"
        className="animable"
      />
      <path
        d="M599.6,328a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,599.6,328Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "599.59px 329.2px",
        }}
        id="eljbtutldyrxl"
        className="animable"
      />
      <path
        d="M596,331.59a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,596,331.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "595.99px 332.79px",
        }}
        id="el197msw9shbz"
        className="animable"
      />
      <path
        d="M599.6,320.82a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,599.6,320.82Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "599.59px 322.02px",
        }}
        id="elr58ap201fi"
        className="animable"
      />
      <path
        d="M596,324.41a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "595.891px 325.605px",
        }}
        id="elx0pilhjdlnd"
        className="animable"
      />
      <path
        d="M599.6,313.64a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,599.6,313.64Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "599.59px 314.84px",
        }}
        id="elz1uv220wa6"
        className="animable"
      />
      <path
        d="M596,317.23a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,596,317.23Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "595.99px 318.43px",
        }}
        id="el36qrbsj8xlk"
        className="animable"
      />
      <path
        d="M598.4,307.65a1.2,1.2,0,0,0,2.39,0s0-.08,0-.12l-1.94-.78A1.18,1.18,0,0,0,598.4,307.65Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "599.595px 307.745px",
        }}
        id="el8l6k3sc2bup"
        className="animable"
      />
      <path
        d="M596,310a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,596,310Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "595.99px 311.2px",
        }}
        id="elexb7gcyonm"
        className="animable"
      />
      <path
        d="M606.78,328a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,606.78,328Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "606.78px 329.2px",
        }}
        id="el9nyh8gjbug"
        className="animable"
      />
      <path
        d="M603.19,331.59a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,603.19,331.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "603.19px 332.79px",
        }}
        id="el71worm0l2rk"
        className="animable"
      />
      <path
        d="M606.78,320.82A1.2,1.2,0,1,0,608,322,1.19,1.19,0,0,0,606.78,320.82Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "606.8px 322.02px",
        }}
        id="elf1vmwhx4bei"
        className="animable"
      />
      <path
        d="M603.19,324.41a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,603.19,324.41Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "603.19px 325.61px",
        }}
        id="elhopawyhltdu"
        className="animable"
      />
      <path
        d="M606.78,313.64a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "606.889px 314.835px",
        }}
        id="elj05t6737ae"
        className="animable"
      />
      <path
        d="M610.37,312.44a1.2,1.2,0,0,0,1.06-.66l-2.19-.87a1.14,1.14,0,0,0-.06.33A1.19,1.19,0,0,0,610.37,312.44Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "610.305px 311.675px",
        }}
        id="eld9e2ce2l2bp"
        className="animable"
      />
      <path
        d="M603.19,317.23a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,603.19,317.23Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "603.19px 318.43px",
        }}
        id="eldtid65kmv1h"
        className="animable"
      />
      <path
        d="M603.19,310a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,603.19,310Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "603.19px 311.2px",
        }}
        id="eln9pgmc0pwpl"
        className="animable"
      />
      <path
        d="M614,328a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,614,328Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "613.99px 329.2px",
        }}
        id="elox5ui005ee"
        className="animable"
      />
      <path
        d="M610.37,331.59a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,610.37,331.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "610.37px 332.79px",
        }}
        id="elnraurkorzco"
        className="animable"
      />
      <path
        d="M614,320.82a1.2,1.2,0,1,0,1.19,1.2A1.19,1.19,0,0,0,614,320.82Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "613.99px 322.02px",
        }}
        id="el0wz40wxd03o"
        className="animable"
      />
      <path
        d="M610.37,324.41a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,610.37,324.41Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "610.37px 325.61px",
        }}
        id="el99yrydc2uth"
        className="animable"
      />
      <path
        d="M614,313.64a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,614,313.64Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "613.99px 314.84px",
        }}
        id="el61vnrjvecbl"
        className="animable"
      />
      <path
        d="M610.37,317.23a1.2,1.2,0,0,0,0,2.39,1.2,1.2,0,1,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "610.479px 318.425px",
        }}
        id="elry8wsmibxzi"
        className="animable"
      />
      <path
        d="M621.15,328a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,621.15,328Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "621.15px 329.2px",
        }}
        id="el5w4w8l1ihsv"
        className="animable"
      />
      <path
        d="M617.56,331.59a1.2,1.2,0,0,0-1.2,1.2,1.16,1.16,0,0,0,.31.79l1.92-.21a1.29,1.29,0,0,0,.16-.58A1.19,1.19,0,0,0,617.56,331.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "617.555px 332.585px",
        }}
        id="elxf7t6f67g4"
        className="animable"
      />
      <path
        d="M621.15,320.82a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,621.15,320.82Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "621.15px 322.02px",
        }}
        id="el6z53hqpdckb"
        className="animable"
      />
      <path
        d="M617.56,324.41a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "617.451px 325.605px",
        }}
        id="elmnlhvnji3me"
        className="animable"
      />
      <path
        d="M621.15,316a1.21,1.21,0,0,0,.56-.16L620,315.2A1.18,1.18,0,0,0,621.15,316Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "620.855px 315.6px",
        }}
        id="el5ex72cqj3s7"
        className="animable"
      />
      <path
        d="M617.56,317.23a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,617.56,317.23Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "617.55px 318.43px",
        }}
        id="elpcr4mz9pxmn"
        className="animable"
      />
      <path
        d="M628.33,328a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,628.33,328Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "628.33px 329.2px",
        }}
        id="el8e1wg96c0tn"
        className="animable"
      />
      <path
        d="M631.06,332l1.51-.17a1.21,1.21,0,0,0-.65-.22A1.18,1.18,0,0,0,631.06,332Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "631.815px 331.805px",
        }}
        id="elq7v3wxo7hy"
        className="animable"
      />
      <path
        d="M624.74,331.59a1.2,1.2,0,0,0-1.2,1.2v0l2.34-.26A1.18,1.18,0,0,0,624.74,331.59Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "624.71px 332.19px",
        }}
        id="elcfbamczpgx9"
        className="animable"
      />
      <path
        d="M628.33,320.82a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,628.33,320.82Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "628.33px 322.02px",
        }}
        id="elwtl1yqkvgtg"
        className="animable"
      />
      <path
        d="M630.73,325.61a1.2,1.2,0,1,0,1.19-1.2A1.19,1.19,0,0,0,630.73,325.61Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "631.93px 325.61px",
        }}
        id="el2akxu665tzq"
        className="animable"
      />
      <path
        d="M624.74,324.41a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,624.74,324.41Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "624.74px 325.61px",
        }}
        id="elh40ky5k1ps"
        className="animable"
      />
      <path
        d="M624.74,317.23a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,624.74,317.23Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "624.74px 318.43px",
        }}
        id="eljifzyknv1xn"
        className="animable"
      />
      <path
        d="M635.52,328a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,635.52,328Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "635.51px 329.2px",
        }}
        id="elxxgcmw7rbqh"
        className="animable"
      />
      <path
        d="M634.32,322a1.2,1.2,0,0,0,2.39,0,1,1,0,0,0,0-.18l-1.89-.75A1.17,1.17,0,0,0,634.32,322Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "635.517px 322.08px",
        }}
        id="elrkgrv5fn5mh"
        className="animable"
      />
      <path
        d="M637.91,325.61a1.2,1.2,0,1,0,1.2-1.2A1.19,1.19,0,0,0,637.91,325.61Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "639.11px 325.61px",
        }}
        id="elztb0dh0t7g"
        className="animable"
      />
      <path
        d="M641.5,329.2a1.2,1.2,0,1,0,1.2-1.2A1.2,1.2,0,0,0,641.5,329.2Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "642.7px 329.2px",
        }}
        id="el663bg9wccvj"
        className="animable"
      />
      <path
        d="M645.17,325.23a1.17,1.17,0,0,0-.07.38,1.19,1.19,0,0,0,2.27.49Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "646.235px 326.01px",
        }}
        id="elq00yi2zs4m"
        className="animable"
      />
      <path
        d="M649.88,328a1.19,1.19,0,0,0-1.19,1.2,1.16,1.16,0,0,0,.31.78l1.92-.21a1.22,1.22,0,0,0,.16-.57A1.2,1.2,0,0,0,649.88,328Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "649.885px 328.99px",
        }}
        id="el8eseizbszb4"
        className="animable"
      />
      <polygon
        points="570.85 305.8 594.96 316.84 606.18 289.06 570.85 305.8"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "588.515px 302.95px",
        }}
        id="el5iiybiqaqiy"
        className="animable"
      />
      <path
        d="M613.76,333c-2,6.45-12.18,31.13-26.38,30.44-4.82-.24-6.6-19.2,2.49-38.36.27-.56,14.71,1.51,14.64,5,0,.38-3.07,8.54-2.66,8.61.07,0,1.68-1.7,8.76-8.94C614.15,326.07,614.88,329.28,613.76,333Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "598.825px 344.222px",
        }}
        id="elfngsxl1ifng"
        className="animable"
      />
      <path
        d="M601.85,338.56c-1.19,4.7-2.48,9.37-3.9,14-.4,1.29-.78,2.59-1.17,3.88a0,0,0,0,0,.08,0,72.58,72.58,0,0,0,2.49-6.84c.7-2.3,1.37-4.63,1.84-7q.42-2,.75-4.08C602,338.51,601.87,338.5,601.85,338.56Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "599.368px 347.476px",
        }}
        id="elgvyb5au6euh"
        className="animable"
      />
      <path
        d="M602.45,337s-1,1.08-.28,1.75,2-.15,2-.15-.59,1.25.2,1.83,2-.5,2-.5-.57,1.28.21,1.87,1.89-.56,1.89-.56-.52,1.32.16,1.86,1.67-.62,1.67-.62-.5,1.3.2,1.75,1.71-.89,1.71-.89Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "607.06px 340.661px",
        }}
        id="el9ba6rc5dwm5"
        className="animable"
      />
      <path
        d="M602,337.28l10.21,6.6s3.38-9.16,3.55-12.88-1-5.49-6-1.32A57.1,57.1,0,0,0,602,337.28Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "608.888px 335.576px",
        }}
        id="ellw3c3qa19j"
        className="animable"
      />
      <g id="el922bqkq083">
        <path
          d="M612.16,343.88h0c0-.11,1-2.64,1.88-5.56.06-.17.11-.35.16-.53.11-.35.22-.71.32-1.07.16-.54.31-1.08.44-1.61,0-.17.09-.35.14-.52a24.7,24.7,0,0,0,.58-3c0-.19,0-.38,0-.55.08-1.63-.1-2.89-.75-3.44l-10,11.64Z"
          style={{
            opacity: 0.1,
            transformOrigin: "610.314px 335.74px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M588.51,328.59s1.8-6,5-9.42c2.22-2.38,5.32-6.18,14.81-4.07,2.25.51,6.12,3.95,4.83,5.35s-4.43-2.29-7.7-2.41c0,0,3.27,3.19,2.68,5.42a41.74,41.74,0,0,1-1.95,5.73,8.88,8.88,0,0,1-1.11-.27,6.91,6.91,0,0,1-3.53,3.62Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "600.959px 323.512px",
        }}
        id="el7nqv92j789i"
        className="animable"
      />
      <path
        d="M596,317.24a25.54,25.54,0,0,1,8.34.3c3.38.86,9.64,3.43,9,5.91-.49,1.82-3,1.05-3,1.05s2.36,1.61,1.56,3.16-2.61.7-2.61.7a1.88,1.88,0,0,1,.31,2.45c-1.16,1.47-4.48-1.91-6.94-1.43C602.13,329.49,596,317.24,596,317.24Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "604.693px 324.107px",
        }}
        id="elg3bktbd4s9u"
        className="animable"
      />
      <path
        d="M602.85,321a13.9,13.9,0,0,1,7.5,3.48c0,.05,0,.12,0,.08a34.33,34.33,0,0,0-7.49-3.53S602.83,321,602.85,321Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "606.597px 322.786px",
        }}
        id="elc6oqnmhamx9"
        className="animable"
      />
      <path
        d="M604.15,317.23a13.9,13.9,0,0,1,7.5,3.48c.05.05,0,.11,0,.08a33.86,33.86,0,0,0-7.49-3.53S604.13,317.22,604.15,317.23Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "607.908px 319.013px",
        }}
        id="elf6oornc05ah"
        className="animable"
      />
      <path
        d="M602.08,324.38a15.6,15.6,0,0,1,7.28,3.93c0,.05,0,.11,0,.07a37.31,37.31,0,0,0-7.27-4S602.06,324.38,602.08,324.38Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "605.717px 326.387px",
        }}
        id="el5l1rp7kp2z"
        className="animable"
      />
      <path
        d="M604.91,329.64a6.61,6.61,0,0,0-4-.77s0,.07,0,.08c1.14.16,2.07.34,2.09.35a9.14,9.14,0,0,1-4.81,3c-.07,0,0,.12,0,.12a5.81,5.81,0,0,0,4.93-3.09,17.49,17.49,0,0,0,1.74.36A0,0,0,0,0,604.91,329.64Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "601.534px 330.62px",
        }}
        id="elqutv8cmdbe"
        className="animable"
      />
      <path
        d="M608.14,361.59l.73-12.25s-3.3-3.65-2.57-6.94c.37-1.67,6.06-14.34,6.62-14.86,2.24-2.09,6-1.26,7.57-.09,8.59,6.5,14.65,20.44,17.56,34.14Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "622.122px 343.924px",
        }}
        id="el02rsrsxhq7mv"
        className="animable"
      />
      <path
        d="M608.14,361.59l.73-12.25s-3.3-3.65-2.57-6.94c.37-1.67,6.06-14.34,6.62-14.86,2.24-2.09,6-1.26,7.57-.09,8.59,6.5,14.65,20.44,17.56,34.14Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "622.122px 343.924px",
        }}
        id="elj5ntqnykr3"
        className="animable"
      />
      <path
        d="M608.87,349.34l-.73,12.25h29.91c-2.91-13.7-9-27.64-17.56-34.14a4.85,4.85,0,0,0-1.09-.6c-1.83-.78-4.67-1-6.49.69-.55.52-6.24,13.19-6.61,14.86C605.57,345.69,608.87,349.34,608.87,349.34Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "622.122px 343.925px",
        }}
        id="el4mptmkidsde"
        className="animable"
      />
      <path
        d="M608.87,349.34l-.73,12.25h29.91c-2.91-13.7-9-27.64-17.56-34.14a4.85,4.85,0,0,0-1.09-.6c-1.83-.78-4.67-1-6.49.69-.55.52-6.24,13.19-6.61,14.86C605.57,345.69,608.87,349.34,608.87,349.34Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "622.122px 343.925px",
        }}
        id="eljiciv9vvhf8"
        className="animable"
      />
      <g id="els4fgkz0yzo">
        <path
          d="M617.2,326.29l7.6,35.3H638c-2.9-13.7-9-27.64-17.55-34.14a5.08,5.08,0,0,0-1.09-.6A7.72,7.72,0,0,0,617.2,326.29Z"
          style={{
            opacity: 0.1,
            transformOrigin: "627.6px 343.94px",
          }}
          className="animable"
        />
      </g>
      <rect
        x={588.95}
        y={374.16}
        width={6.98}
        height={1.09}
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "592.44px 374.705px",
        }}
        id="elivajp86yq"
        className="animable"
      />
      <rect
        x={588.95}
        y={374.16}
        width={6.98}
        height={0.72}
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "592.44px 374.52px",
        }}
        id="el7qlcbilgxkp"
        className="animable"
      />
      <path
        d="M604.22,371.23a4,4,0,0,1-3.93,1.68,3.15,3.15,0,0,1-.71-.2,4.29,4.29,0,0,1-1-.52l.89-1.31a4.05,4.05,0,0,0,.52.3,2.32,2.32,0,0,0,2.92-.86c1-1.35,1.3-4.42.33-5.6-.06-.09-.34-.22-1.47,0-.2,0-.44.1-.7.18a2.74,2.74,0,0,1-.64.13l0-.79-.08-.78.34-.09a10.34,10.34,0,0,1,1.58-.33,2.4,2.4,0,0,1,2.23.67C606.05,365.65,605.41,369.55,604.22,371.23Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "601.998px 367.973px",
        }}
        id="elvawkikl4gj"
        className="animable"
      />
      <path
        d="M599.48,370.88a4.05,4.05,0,0,0,.52.3l-.42,1.53a4.29,4.29,0,0,1-1-.52Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "599.29px 371.795px",
        }}
        id="elop200g5evd"
        className="animable"
      />
      <path
        d="M602.25,363.05l-.47,1.68c-.2,0-.44.1-.7.18a2.74,2.74,0,0,1-.64.13l0-.79-.08-.78.34-.09A10.34,10.34,0,0,1,602.25,363.05Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "601.305px 364.045px",
        }}
        id="el4y9q8vzn5n4"
        className="animable"
      />
      <path
        d="M583.15,361s.84,7.12,2.29,10.11,3.46,3.33,4.72,3.33h4.56c1.26,0,3.28-.34,4.72-3.33S601.73,361,601.73,361Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "592.44px 367.72px",
        }}
        id="el3awwcakif3k"
        className="animable"
      />
      <path
        d="M584.52,365.65a.45.45,0,1,1,.45-.45A.45.45,0,0,1,584.52,365.65Zm0-.81a.37.37,0,1,0,0,.73.37.37,0,0,0,0-.73Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "584.52px 365.2px",
        }}
        id="eljpbpzh2lbw"
        className="animable"
      />
      <path
        d="M597.75,363.35a.45.45,0,1,1,.45-.44A.44.44,0,0,1,597.75,363.35Zm0-.81a.37.37,0,0,0,0,.73.37.37,0,1,0,0-.73Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "597.75px 362.9px",
        }}
        id="eli3ju7nczbq"
        className="animable"
      />
      <path
        d="M597.09,369.91a.45.45,0,0,1,0-.9.45.45,0,0,1,0,.9Zm0-.81a.36.36,0,0,0-.36.36.37.37,0,0,0,.36.37.38.38,0,0,0,.37-.37A.37.37,0,0,0,597.09,369.1Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "597.09px 369.46px",
        }}
        id="el64xndkmtv0y"
        className="animable"
      />
      <path
        d="M600.74,362.18a.45.45,0,1,1,0-.89.45.45,0,1,1,0,.89Zm0-.81a.37.37,0,1,0,0,.73.37.37,0,0,0,0-.73Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "600.74px 361.735px",
        }}
        id="elaa29iafc0ce"
        className="animable"
      />
      <path
        d="M594.51,365a.45.45,0,1,1,.45-.45A.45.45,0,0,1,594.51,365Zm0-.82a.37.37,0,0,0-.36.37.36.36,0,0,0,.36.36.37.37,0,0,0,.37-.36A.38.38,0,0,0,594.51,364.15Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "594.51px 364.55px",
        }}
        id="elmqg83s4lqgd"
        className="animable"
      />
      <path
        d="M589.65,369.72a.45.45,0,1,1,.45-.45A.45.45,0,0,1,589.65,369.72Zm0-.81a.37.37,0,1,0,0,.73.37.37,0,0,0,0-.73Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "589.65px 369.27px",
        }}
        id="elhaa9q5f3u8i"
        className="animable"
      />
      <path
        d="M592.62,362.18a.45.45,0,1,1,.45-.45A.45.45,0,0,1,592.62,362.18Zm0-.81a.37.37,0,0,0,0,.73.37.37,0,1,0,0-.73Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "592.62px 361.73px",
        }}
        id="eldnas3b3w9n9"
        className="animable"
      />
      <path
        d="M593.18,367.93a.45.45,0,1,1,.44-.45A.45.45,0,0,1,593.18,367.93Zm0-.81a.36.36,0,0,0-.37.36.37.37,0,1,0,.73,0A.36.36,0,0,0,593.18,367.12Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "593.17px 367.48px",
        }}
        id="elruozftulvk"
        className="animable"
      />
      <path
        d="M597.61,373.28a.45.45,0,1,1,0-.89.45.45,0,1,1,0,.89Zm0-.81a.37.37,0,0,0-.37.36.38.38,0,0,0,.37.37.37.37,0,0,0,.36-.37A.36.36,0,0,0,597.61,372.47Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "597.61px 372.835px",
        }}
        id="elc4pxcrwgm5"
        className="animable"
      />
      <path
        d="M586.74,362.85a.45.45,0,1,1,.44-.45A.45.45,0,0,1,586.74,362.85Zm0-.81a.37.37,0,1,0,.36.36A.36.36,0,0,0,586.74,362Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "586.73px 362.4px",
        }}
        id="elf1vj9sd92wm"
        className="animable"
      />
      <path
        d="M599.21,366.71a.45.45,0,1,1,.45-.45A.45.45,0,0,1,599.21,366.71Zm0-.81a.37.37,0,0,0,0,.73.37.37,0,1,0,0-.73Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "599.21px 366.26px",
        }}
        id="ellcpsl627yc"
        className="animable"
      />
      <path
        d="M590.06,365.37a.45.45,0,1,1,.44-.44A.45.45,0,0,1,590.06,365.37Zm0-.81a.37.37,0,1,0,0,.73.37.37,0,0,0,0-.73Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "590.05px 364.92px",
        }}
        id="elhlqc1m7ihdl"
        className="animable"
      />
      <path
        d="M593.18,372.61a.45.45,0,1,1,.44-.45A.45.45,0,0,1,593.18,372.61Zm0-.82a.37.37,0,0,0-.37.37.37.37,0,0,0,.73,0A.37.37,0,0,0,593.18,371.79Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "593.17px 372.16px",
        }}
        id="elqsfu0jj4lef"
        className="animable"
      />
      <path
        d="M589.41,374.22a.45.45,0,1,1,.45-.45A.45.45,0,0,1,589.41,374.22Zm0-.81a.37.37,0,0,0,0,.73.37.37,0,1,0,0-.73Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "589.41px 373.77px",
        }}
        id="ellc6mqq3n0om"
        className="animable"
      />
      <path
        d="M586.74,367.52a.45.45,0,1,1,.44-.44A.45.45,0,0,1,586.74,367.52Zm0-.81a.37.37,0,0,0-.37.37.37.37,0,0,0,.73,0A.37.37,0,0,0,586.74,366.71Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "586.73px 367.07px",
        }}
        id="els0mcbcoyjoi"
        className="animable"
      />
      <path
        d="M586.33,371.56a.45.45,0,1,1,.45-.45A.45.45,0,0,1,586.33,371.56Zm0-.82a.38.38,0,0,0-.37.37.37.37,0,0,0,.74,0A.38.38,0,0,0,586.33,370.74Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "586.33px 371.11px",
        }}
        id="elydgbckpxfjl"
        className="animable"
      />
      <path
        d="M624.72,330.2c10.45,7.87,23.93,29.27,21.93,34.38-1.83,4.66-17.86,9.35-27.31,10.64-1.8.25-3.06-15.09-1.14-15.42,3.41-.6,11.16-3.93,11.39-4,.49-.13-8.33-16.71-9.93-22.61C617.85,326.52,620.15,326.76,624.72,330.2Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "632.044px 351.528px",
        }}
        id="elvk31m1azbqt"
        className="animable"
      />
      <path
        d="M599.05,363.34c.19,1.73,4.41.91,4.41.91a3.18,3.18,0,0,0-1.1,1.88c-.18,1.31,1.8,2.16,1.8,2.16a3.93,3.93,0,0,0-.85,2.17c0,1.27,1.83,2.42,1.83,2.42a3.46,3.46,0,0,0,.46,2.37c1.23,1.86,4.63,1.76,6.79,1.72s8.25-2.18,9.46-2.48l-.15-13.87s-9.74-2.57-12.28-2.16C607.29,358.8,598.77,360.7,599.05,363.34Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "610.447px 367.701px",
        }}
        id="el0zy2k49bfjlb"
        className="animable"
      />
      <path
        d="M603.49,364.2a29.21,29.21,0,0,1,6.89-1.65s.07.08,0,.08c-2.3.32-4.58,1.27-6.87,1.68C603.46,364.32,603.43,364.22,603.49,364.2Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "606.934px 363.43px",
        }}
        id="el5rf6r8jas"
        className="animable"
      />
      <path
        d="M604.2,368.11a6.53,6.53,0,0,1,1.59-.27l1.71-.14c1.14-.07,2.28-.12,3.42-.14.05,0,.07.08,0,.08-1.13.08-2.27.17-3.4.3l-1.7.2a7.29,7.29,0,0,1-1.61.13A.08.08,0,0,1,604.2,368.11Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "607.545px 367.917px",
        }}
        id="elx3b3q11a2h8"
        className="animable"
      />
      <path
        d="M605.19,372.87a4.31,4.31,0,0,1,1.44-.16l1.56-.08c1-.06,2.08-.12,3.12-.22.05,0,.06.08,0,.08l-3.16.32-1.56.12a3.93,3.93,0,0,1-1.41,0A0,0,0,0,1,605.19,372.87Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "608.266px 372.702px",
        }}
        id="elg5btol566h4"
        className="animable"
      />
      <path
        d="M631.66,336s1.06,1,.41,1.72-2,0-2,0,.67,1.21-.08,1.84-2-.36-2-.36.66,1.24-.08,1.88-1.92-.42-1.92-.42.61,1.27,0,1.86-1.72-.5-1.72-.5.6,1.26-.07,1.75-1.77-.77-1.77-.77Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "627.355px 339.942px",
        }}
        id="elf62x7mdjmk"
        className="animable"
      />
      <path
        d="M632.17,336.28l-9.73,7.29s-4-8.9-4.43-12.61-.77-6.18,4.86-2.44A57.72,57.72,0,0,1,632.17,336.28Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "624.983px 335.116px",
        }}
        id="elbsvc3qf0xzm"
        className="animable"
      />
      <path
        d="M612.88,327.37s-.49,4.46.32,4.58c2.49.39,5.48-2.89,5.67-3.43,0-.13,0-3.62,0-6.32V319l-6.62,3.28.54,4.72Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "615.56px 325.491px",
        }}
        id="eld9g9zh4e046"
        className="animable"
      />
      <path
        d="M612.3,322.31l.54,4.72a9.52,9.52,0,0,0,6.08-4.83V319l-.83.38Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "615.61px 323.015px",
        }}
        id="el7fc7mecrueb"
        className="animable"
      />
      <path
        d="M606.64,314.84c-1.28,8.24,3.51,9.93,5.29,10.21,1.62.25,7.14.88,9.26-7.18s-1.31-11.27-5-12S607.91,306.6,606.64,314.84Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "614.133px 315.439px",
        }}
        id="elsjqztf9d1be"
        className="animable"
      />
      <path
        d="M608.35,313.82c.2,0,.38-.05.57-.07a.89.89,0,0,0,.57-.19.3.3,0,0,0,0-.36.76.76,0,0,0-.71-.23,1.05,1.05,0,0,0-.7.33A.31.31,0,0,0,608.35,313.82Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "608.772px 313.389px",
        }}
        id="elfdd76pc401"
        className="animable"
      />
      <path
        d="M614.09,314.48a5,5,0,0,1-.55-.17A.88.88,0,0,1,613,314a.29.29,0,0,1,0-.36.74.74,0,0,1,.73-.12,1.14,1.14,0,0,1,.65.44A.32.32,0,0,1,614.09,314.48Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "613.695px 313.978px",
        }}
        id="elpkvv3knsek"
        className="animable"
      />
      <path
        d="M612.81,316.48s0,0,0,.06c-.08.56-.07,1.22.42,1.46,0,0,0,0,0,0C612.64,317.88,612.64,317,612.81,316.48Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "612.971px 317.24px",
        }}
        id="elvk9fy8vv8f"
        className="animable"
      />
      <path
        d="M613.37,316c.92,0,.79,1.85,0,1.83S612.61,315.92,613.37,316Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "613.4px 316.914px",
        }}
        id="eltpqkz06b4tb"
        className="animable"
      />
      <path
        d="M613.73,316.07c.15.11.29.31.48.34s.41-.15.56-.32c0,0,0,0,0,0,0,.35-.17.7-.55.73s-.57-.3-.64-.66C613.6,316.11,613.66,316,613.73,316.07Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "614.175px 316.435px",
        }}
        id="el0bbt734yohvn"
        className="animable"
      />
      <path
        d="M609.27,316s0,0,0,0c0,.57-.13,1.22-.66,1.37,0,0,0,0,0,0C609.21,317.38,609.35,316.52,609.27,316Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "608.95px 316.685px",
        }}
        id="el3hcqsh8jisk"
        className="animable"
      />
      <path
        d="M608.8,315.36c-.9-.12-1.09,1.7-.25,1.82S609.56,315.46,608.8,315.36Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "608.649px 316.27px",
        }}
        id="el371p6duvhsl"
        className="animable"
      />
      <path
        d="M608.41,315.44c-.15.08-.31.25-.49.24s-.33-.22-.43-.42c0,0,0,0,0,0-.05.34,0,.72.36.82s.55-.19.67-.53C608.51,315.51,608.47,315.41,608.41,315.44Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "607.997px 315.68px",
        }}
        id="eldqool02a5d"
        className="animable"
      />
      <path
        d="M612.23,320.81c-.14.13-.28.31-.48.32a1.61,1.61,0,0,1-.61-.15s0,0,0,0a.76.76,0,0,0,.72.33.6.6,0,0,0,.45-.49A0,0,0,0,0,612.23,320.81Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "611.725px 321.063px",
        }}
        id="elsoyxo24nd"
        className="animable"
      />
      <path
        d="M610,318.57a12.37,12.37,0,0,0-.22,1.24s.09.06.23.08h0a1.89,1.89,0,0,0,1.9-.56,0,0,0,0,0-.05-.06,2.83,2.83,0,0,1-1.77.36c0-.11.36-1.42.3-1.43a3.58,3.58,0,0,0-.93.13c.34-1.75,1-3.44,1.26-5.19a.06.06,0,0,0-.11,0,29.26,29.26,0,0,0-1.58,5.53C609,318.86,609.86,318.63,610,318.57Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "610.47px 316.53px",
        }}
        id="elxq8349k4bg"
        className="animable"
      />
      <path
        d="M620.74,317.35c-1.92-.09-1.74-3.13-1.74-3.13s-2.17-.32-2.13-3.85a5.36,5.36,0,0,1-4.16,2.33c-.63.06,1.56-1.45,1.56-1.45s-2.64,2.14-7.27,1c-.33-.08.66-5,4.42-6.45a7.81,7.81,0,0,1,9.15,2.11s2.62,1.39,2.77,4S621.49,317.39,620.74,317.35Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "615.144px 311.238px",
        }}
        id="elgjtyeu7i32"
        className="animable"
      />
      <path
        d="M611.63,325a6.22,6.22,0,0,1-3.63-2.76,9.77,9.77,0,0,1-1.39-5.09,19,19,0,0,1,.16-3.1s0,0,0,0a12.59,12.59,0,0,0-.14,5.79,7.3,7.3,0,0,0,2.62,4.16,6.46,6.46,0,0,0,2.41,1.07S611.68,325,611.63,325Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "609.011px 319.56px",
        }}
        id="el6elnmc6n3w8"
        className="animable"
      />
      <path
        d="M612.9,319.31a2.62,2.62,0,0,1-1.84-3.2h0a2.61,2.61,0,1,1,2.48,3.29A3.52,3.52,0,0,1,612.9,319.31Zm-1.5-3.11a2.25,2.25,0,1,0,4.35,1.18,2.24,2.24,0,0,0-1.58-2.76,2.16,2.16,0,0,0-.56-.08A2.26,2.26,0,0,0,611.4,316.2Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "613.581px 316.79px",
        }}
        id="elkx5l9o4y3a"
        className="animable"
      />
      <path
        d="M607.45,318.56c-1.06-.14-1.77-1.41-1.58-2.83a3.06,3.06,0,0,1,.79-1.73,1.7,1.7,0,0,1,1.48-.6,1.73,1.73,0,0,1,1.27,1,3.1,3.1,0,0,1,.3,1.88,2.43,2.43,0,0,1-2.09,2.34Zm-.54-4.32a2.71,2.71,0,0,0-.69,1.53c-.17,1.24.41,2.33,1.27,2.45s1.71-.79,1.88-2a2.71,2.71,0,0,0-.27-1.66,1.37,1.37,0,0,0-1-.78H608A1.41,1.41,0,0,0,606.91,314.24Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "607.793px 316.005px",
        }}
        id="els4vqn9t99v"
        className="animable"
      />
      <path
        d="M611.11,316.42l.26-.23c-.35-.38-1.2-.95-1.92-.35l.23.26C610.32,315.57,611.08,316.39,611.11,316.42Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "610.41px 315.999px",
        }}
        id="el5izmrk70x0o"
        className="animable"
      />
      <path
        d="M622.52,318.62l.33-.12c-.06-.15-.56-1.5-1.31-1.73s-5.39-.39-5.59-.4v.35c1.34,0,4.95.22,5.5.39S622.39,318.25,622.52,318.62Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "619.4px 317.495px",
        }}
        id="el5lp36kur4yl"
        className="animable"
      />
      <path
        d="M619.78,318.3s2-2.48,3.13-1.75-.48,4.4-1.9,4.82a1.42,1.42,0,0,1-1.84-.88Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "621.23px 318.937px",
        }}
        id="el62p39r92axb"
        className="animable"
      />
      <path
        d="M622.42,317.59s0,0,0,0a3.57,3.57,0,0,0-2,2.1.77.77,0,0,1,1.17-.13s0,0,0,0a.85.85,0,0,0-1,.23,5.4,5.4,0,0,0-.51.74c-.05.09-.2,0-.16-.07v0C620,319.29,621.08,317.63,622.42,317.59Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "621.167px 319.079px",
        }}
        id="elks1tv2y8prj"
        className="animable"
      />
      <path
        d="M620.25,321a.31.31,0,0,0,.28.31.3.3,0,0,0,0-.59A.3.3,0,0,0,620.25,321Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "620.513px 321.015px",
        }}
        id="elsoexee3c1cj"
        className="animable"
      />
      <path
        d="M621.09,320.48a.18.18,0,0,0,.18.19.18.18,0,0,0,.19-.17.2.2,0,0,0-.18-.2A.19.19,0,0,0,621.09,320.48Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "621.275px 320.485px",
        }}
        id="elrwpzcqrmwxq"
        className="animable"
      />
      <path
        d="M622.7,318.19l.56.16a.11.11,0,0,0,.15-.07h0a.12.12,0,0,0-.08-.15l-.56-.16a.12.12,0,0,0-.15.08h0A.12.12,0,0,0,622.7,318.19Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "623.016px 318.162px",
        }}
        id="eljw13qr0mdrl"
        className="animable"
      />
      <path
        d="M622.79,317.83l.58.08a.11.11,0,0,0,.13-.1h0a.11.11,0,0,0-.1-.13l-.57-.08a.1.1,0,0,0-.13.1h0A.11.11,0,0,0,622.79,317.83Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "623.1px 317.754px",
        }}
        id="eldjtxb6moj6v"
        className="animable"
      />
      <path
        d="M612.28,328.6a12.1,12.1,0,0,0-.1,1.47,7,7,0,0,0,.07,1.45,2.33,2.33,0,0,0,.52,1.28A1.24,1.24,0,0,0,614,333a8.72,8.72,0,0,0,2.56-1.34,14.23,14.23,0,0,0,1.15-.9,9.22,9.22,0,0,0,1.06-1,10.51,10.51,0,0,1-2.1,2.06,11,11,0,0,1-1.23.83,6.27,6.27,0,0,1-1.38.6,2.15,2.15,0,0,1-.76.09,1.37,1.37,0,0,1-.4-.09,1.12,1.12,0,0,1-.33-.24,1.69,1.69,0,0,1-.39-.67,3.16,3.16,0,0,1-.16-.74A9.4,9.4,0,0,1,612.28,328.6Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "615.382px 330.972px",
        }}
        id="elfsvbnmxxbpf"
        className="animable"
      />
      <path
        d="M619.38,308.67c-1.4-.92-4.86-8,1.16-11.77s13.75,3.77,9.56,9.82A7.75,7.75,0,0,1,619.38,308.67Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "624.204px 302.955px",
        }}
        id="el13aunzwfj7bo"
        className="animable"
      />
      <rect
        x={561.51}
        y={253.77}
        width={106.69}
        height={28.49}
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "614.855px 268.015px",
        }}
        id="els9j3wiic2"
        className="animable"
      />
      <path
        d="M575.87,263.9v4a.39.39,0,0,1-.38.38.38.38,0,0,1-.38-.38v-2.86l-1,1.36a.37.37,0,0,1-.3.16h0a.38.38,0,0,1-.31-.16l-1-1.36v2.86a.38.38,0,0,1-.39.38.38.38,0,0,1-.37-.38v-4a.38.38,0,0,1,.37-.38h0a.36.36,0,0,1,.29.16l1.36,1.88,1.35-1.88a.4.4,0,0,1,.31-.16h0A.39.39,0,0,1,575.87,263.9Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "573.805px 265.898px",
        }}
        id="el4sthllgc583"
        className="animable"
      />
      <path
        d="M582,264.12a2.49,2.49,0,0,1,0,3.44,2.23,2.23,0,0,1-1.62.73,2.29,2.29,0,0,1-1.67-.73,2.52,2.52,0,0,1,0-3.44,2.26,2.26,0,0,1,1.67-.73A2.2,2.2,0,0,1,582,264.12Zm-.09,1.72a1.74,1.74,0,0,0-.46-1.19,1.47,1.47,0,0,0-1.07-.49,1.49,1.49,0,0,0-1.11.49,1.74,1.74,0,0,0-.46,1.19,1.72,1.72,0,0,0,.46,1.18,1.49,1.49,0,0,0,1.11.49,1.47,1.47,0,0,0,1.07-.49A1.72,1.72,0,0,0,581.9,265.84Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "580.361px 265.84px",
        }}
        id="eljb8zcdf0lub"
        className="animable"
      />
      <path
        d="M585.62,267.91a.38.38,0,0,1-.37.38.37.37,0,0,1-.37-.38v-4h0a.34.34,0,0,1,.15-.31.36.36,0,0,1,.51.08l2.2,3V264a.37.37,0,0,1,.74,0v4a.38.38,0,0,1-.37.38.38.38,0,0,1-.28-.14l0,0-2.18-3Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "586.679px 265.954px",
        }}
        id="elltx7fxwdeg"
        className="animable"
      />
      <path
        d="M594,264.28h-1.08v3.63a.38.38,0,1,1-.75,0v-3.63h-1.08a.38.38,0,0,1-.37-.38.37.37,0,0,1,.37-.38H594a.38.38,0,0,1,.37.38A.38.38,0,0,1,594,264.28Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "592.545px 265.936px",
        }}
        id="elil3654ktvg"
        className="animable"
      />
      <path
        d="M599.42,264.28h-1.08v3.63a.37.37,0,0,1-.37.38.38.38,0,0,1-.38-.38v-3.63h-1.07a.39.39,0,0,1-.38-.38.38.38,0,0,1,.38-.38h2.9a.38.38,0,0,1,.38.38A.39.39,0,0,1,599.42,264.28Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "597.97px 265.905px",
        }}
        id="elmwhiiulym5"
        className="animable"
      />
      <path
        d="M572.53,276.74l-.51.71,0,0h0l-.05,0-.51-.71v1.31a0,0,0,0,1,0,.05s-.06,0-.06-.05v-1.47a.08.08,0,0,1,0-.05h0a0,0,0,0,1,.07,0l.57.79.56-.79a.06.06,0,0,1,.08,0l0,0v1.5a.06.06,0,0,1-.06.05,0,0,0,0,1-.05-.05Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "572.038px 277.307px",
        }}
        id="elexk2tn6laww"
        className="animable"
      />
      <path
        d="M573.44,276.57a.06.06,0,0,1,.06-.06h.82a.06.06,0,0,1,0,.06,0,0,0,0,1,0,.05h-.77v.63h.67a.06.06,0,0,1,0,.06,0,0,0,0,1,0,.05h-.67V278h.77a.06.06,0,0,1,0,.06,0,0,0,0,1,0,.05h-.83a0,0,0,0,1,0-.05Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "573.884px 277.31px",
        }}
        id="elj5csp8oce1"
        className="animable"
      />
      <path
        d="M575.25,276.75v1.3a.06.06,0,0,1-.06.05,0,0,0,0,1-.05-.05v-1.46a.05.05,0,0,1,.09,0l1,1.34v-1.3a0,0,0,0,1,0-.05s.06,0,.06.05v1.47s0,.05-.06.05a0,0,0,0,1,0,0h0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "575.715px 277.356px",
        }}
        id="el3ey9pue3rhh"
        className="animable"
      />
      <path
        d="M577.11,276.57a.06.06,0,0,1,0-.06.06.06,0,0,1,.06.06v.89a.54.54,0,0,0,1.07,0v-.89a.06.06,0,0,1,.05-.06.06.06,0,0,1,.06.06v.89a.65.65,0,0,1-1.29,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "577.705px 277.27px",
        }}
        id="el5st8dd5ulbb"
        className="animable"
      />
      <path
        d="M585.5,277.39a.06.06,0,0,1-.06-.06.06.06,0,0,1,.06-.05h.5a.05.05,0,0,1,0,.05v.55h0s0,0,0,0a.83.83,0,0,1-.52.17.81.81,0,1,1,0-1.61.84.84,0,0,1,.52.18.06.06,0,0,1,0,.08.06.06,0,0,1-.08,0,.76.76,0,0,0-.45-.15.7.7,0,1,0,0,1.39.72.72,0,0,0,.42-.13v-.47Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "585.298px 277.245px",
        }}
        id="elao3cqhh313h"
        className="animable"
      />
      <path
        d="M587.82,277.69h-.73l-.14.38a.05.05,0,0,1-.05,0h0a0,0,0,0,1,0-.07l.55-1.48a.05.05,0,0,1,.05,0,.06.06,0,0,1,.06,0l.55,1.48a.05.05,0,0,1,0,.07h0a.05.05,0,0,1,0,0Zm-.69-.11h.65l-.32-.85Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "587.512px 277.294px",
        }}
        id="elci95smgrxo"
        className="animable"
      />
      <path
        d="M588.81,278.1h0a.06.06,0,0,1-.06-.05v-1.48a.06.06,0,0,1,.06-.06.06.06,0,0,1,.05.06V278h.77a.06.06,0,0,1,0,.06,0,0,0,0,1,0,.05Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "589.194px 277.31px",
        }}
        id="elhsxcqv1jcu"
        className="animable"
      />
      <path
        d="M590.43,278.1h0a.06.06,0,0,1-.06-.05v-1.48a.06.06,0,0,1,.06-.06.06.06,0,0,1,.05.06V278h.76a.06.06,0,0,1,.06.06s0,.05-.06.05Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "590.835px 277.31px",
        }}
        id="el2npny3q3juf"
        className="animable"
      />
      <path
        d="M592,276.57a.06.06,0,0,1,0-.06h.82a.06.06,0,0,1,.06.06s0,.05-.06.05h-.76v.63h.67a.06.06,0,0,1,.05.06,0,0,0,0,1-.05.05h-.67V278h.76a.06.06,0,0,1,.06.06s0,.05-.06.05H592a0,0,0,0,1,0-.05Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "592.436px 277.31px",
        }}
        id="elsn2ovrk8o2"
        className="animable"
      />
      <path
        d="M594.51,277.74a.28.28,0,0,0-.13-.24.62.62,0,0,0-.18-.08h-.4v.63a0,0,0,0,1,0,.05.06.06,0,0,1-.06-.05v-1.48a.06.06,0,0,1,0-.06h.46a.45.45,0,0,1,.45.46.46.46,0,0,1-.26.41h0a.4.4,0,0,1,.21.35.71.71,0,0,0,0,.19.07.07,0,0,0,0,0s.05,0,.06,0a.09.09,0,0,1,0,.08h0a.11.11,0,0,1-.1-.07A.56.56,0,0,1,594.51,277.74Zm-.31-1.12h-.4v.69h.41a.34.34,0,0,0,.33-.34A.35.35,0,0,0,594.2,276.62Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "594.201px 277.305px",
        }}
        id="el7zi98enioi7"
        className="animable"
      />
      <path
        d="M595.86,277.34l-.53-.73a.06.06,0,0,1,0-.08.06.06,0,0,1,.08,0l.5.69.5-.69a0,0,0,0,1,.07,0,.05.05,0,0,1,0,.08l-.53.72v.72a0,0,0,0,1-.05.05.06.06,0,0,1-.06-.05Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "595.907px 277.307px",
        }}
        id="elwe1gunj1zps"
        className="animable"
      />
      <path
        d="M604.9,277.69h-.73l-.14.38a.05.05,0,0,1,0,0h0a0,0,0,0,1,0-.07l.55-1.48a.05.05,0,0,1,0,0,.06.06,0,0,1,.06,0l.55,1.48a.05.05,0,0,1,0,.07h0a.05.05,0,0,1-.05,0Zm-.69-.11h.65l-.32-.85Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "604.617px 277.294px",
        }}
        id="el33iajuix0u3"
        className="animable"
      />
      <path
        d="M605.83,276.57a.06.06,0,0,1,.06-.06h.35a.4.4,0,0,1,.4.4.39.39,0,0,1-.15.31.45.45,0,0,1-.15.88h-.45a.06.06,0,0,1-.06-.05Zm.41.62a.29.29,0,1,0,0-.57h-.3v.57Zm.1.8a.34.34,0,1,0,0-.68h-.4V278Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "606.319px 277.305px",
        }}
        id="elhmys9b2impg"
        className="animable"
      />
      <path
        d="M608.22,276.49a.81.81,0,1,1-.77.81A.79.79,0,0,1,608.22,276.49Zm0,1.5a.7.7,0,1,0-.66-.69A.67.67,0,0,0,608.22,278Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "608.26px 277.299px",
        }}
        id="elk5wpnqpgce"
        className="animable"
      />
      <path
        d="M609.69,276.57a.06.06,0,0,1,.06-.06.06.06,0,0,1,0,.06v.89a.54.54,0,0,0,1.07,0v-.89a.06.06,0,0,1,0-.06.06.06,0,0,1,.06.06v.89a.65.65,0,0,1-1.29,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "610.235px 277.27px",
        }}
        id="el4wex8ecc8j7"
        className="animable"
      />
      <path
        d="M612.22,276.62h-.48a0,0,0,0,1,0-.05.06.06,0,0,1,0-.06h1.07a.06.06,0,0,1,.06.06.06.06,0,0,1-.06.05h-.48v1.43a0,0,0,0,1-.05.05.06.06,0,0,1-.06-.05Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "612.301px 277.305px",
        }}
        id="elngk8q9k0vug"
        className="animable"
      />
      <path
        d="M614.65,276.57a.06.06,0,0,1,.05-.06.06.06,0,0,1,.06.06v.89a.53.53,0,1,0,1.06,0v-.89a.06.06,0,0,1,.06-.06.06.06,0,0,1,0,.06v.89a.64.64,0,0,1-.64.64.64.64,0,0,1-.64-.64Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "615.244px 277.305px",
        }}
        id="elqqy40h6smxi"
        className="animable"
      />
      <path
        d="M616.76,277.81a0,0,0,0,1,.07,0,.87.87,0,0,0,.53.2.65.65,0,0,0,.35-.1.33.33,0,0,0,.13-.24.25.25,0,0,0-.13-.22,1.12,1.12,0,0,0-.37-.13h0a.86.86,0,0,1-.38-.13.34.34,0,0,1-.16-.28.36.36,0,0,1,.17-.29.6.6,0,0,1,.38-.12.87.87,0,0,1,.49.18.05.05,0,1,1-.05.09.82.82,0,0,0-.44-.17.61.61,0,0,0-.32.09.29.29,0,0,0-.13.22.24.24,0,0,0,.12.2,1.11,1.11,0,0,0,.34.12h0a1.11,1.11,0,0,1,.4.14.38.38,0,0,1,.17.3.4.4,0,0,1-.18.32.66.66,0,0,1-.4.13,1.06,1.06,0,0,1-.59-.23A.06.06,0,0,1,616.76,277.81Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "617.337px 277.31px",
        }}
        id="elmc3ohf4dgq"
        className="animable"
      />
      <path
        d="M625.92,276.57a.06.06,0,0,1,.05-.06h.82a.06.06,0,0,1,.06.06.06.06,0,0,1-.06.05H626v.63h.67a.06.06,0,0,1,0,.06,0,0,0,0,1,0,.05H626v.69a.06.06,0,0,1-.06.05,0,0,0,0,1-.05-.05Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "626.37px 277.305px",
        }}
        id="el4tqurrvx7rd"
        className="animable"
      />
      <path
        d="M628.32,277.69h-.72l-.14.38a.06.06,0,0,1-.06,0h0a.06.06,0,0,1,0-.07l.55-1.48a.07.07,0,0,1,.06,0s0,0,0,0l.56,1.48a.06.06,0,0,1,0,.07h0a.07.07,0,0,1-.06,0Zm-.68-.11h.64l-.32-.85Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "627.985px 277.296px",
        }}
        id="elw7n7yf1hlmc"
        className="animable"
      />
      <path
        d="M630.84,278.3a.59.59,0,0,1-.36.13h0a.79.79,0,0,1-.39-.13.39.39,0,0,0-.2-.05.52.52,0,0,0-.21.05h-.06l0,0a0,0,0,0,1,0-.07l.21-.1a.78.78,0,1,1,.4-.06l-.23.11.14.05a.7.7,0,0,0,.34.12.48.48,0,0,0,.29-.11.06.06,0,0,1,.08,0A.06.06,0,0,1,630.84,278.3Zm-.9-.31a.69.69,0,1,0-.65-.69A.67.67,0,0,0,629.94,278Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "629.996px 277.502px",
        }}
        id="elrjobypx3qce"
        className="animable"
      />
      <path
        d="M639.31,277.81a0,0,0,0,1,.07,0,.87.87,0,0,0,.53.2.65.65,0,0,0,.35-.1.3.3,0,0,0,.13-.24.25.25,0,0,0-.13-.22,1.14,1.14,0,0,0-.36-.13h0a.77.77,0,0,1-.37-.13.33.33,0,0,1-.17-.28.36.36,0,0,1,.17-.29.6.6,0,0,1,.38-.12.87.87,0,0,1,.49.18.05.05,0,1,1,0,.09.82.82,0,0,0-.44-.17.61.61,0,0,0-.32.09.29.29,0,0,0-.13.22.24.24,0,0,0,.12.2,1.11,1.11,0,0,0,.34.12h0a1,1,0,0,1,.4.14.39.39,0,0,1,.18.3.42.42,0,0,1-.18.32.69.69,0,0,1-.41.13,1.06,1.06,0,0,1-.59-.23A.06.06,0,0,1,639.31,277.81Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "639.928px 277.31px",
        }}
        id="el9u9uj1p4hz"
        className="animable"
      />
      <path
        d="M642.17,277.36h-.82v.69a0,0,0,0,1-.05.05.06.06,0,0,1-.06-.05v-1.48a.06.06,0,0,1,.06-.06.06.06,0,0,1,.05.06v.68h.82v-.68a.06.06,0,0,1,.06-.06.06.06,0,0,1,0,.06v1.48a0,0,0,0,1,0,.05.06.06,0,0,1-.06-.05Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "641.739px 277.305px",
        }}
        id="elscgswny2ky"
        className="animable"
      />
      <path
        d="M643.82,276.49a.81.81,0,1,1-.77.81A.79.79,0,0,1,643.82,276.49Zm0,1.5a.7.7,0,1,0-.66-.69A.67.67,0,0,0,643.82,278Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "643.86px 277.299px",
        }}
        id="elqqyctew27b"
        className="animable"
      />
      <path
        d="M645.39,276.57a.06.06,0,0,1,.06-.06h.45a.46.46,0,0,1,.45.46.45.45,0,0,1-.45.45h-.39v.63a.06.06,0,0,1-.12,0Zm.51.74a.34.34,0,0,0,.34-.34.35.35,0,0,0-.34-.35h-.39v.69Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "645.87px 277.31px",
        }}
        id="elserorbecqgh"
        className="animable"
      />
      <rect
        x={656.4}
        y={263.46}
        width={5.48}
        height={0.88}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "659.14px 263.9px",
        }}
        id="elx2btnsuvvis"
        className="animable"
      />
      <rect
        x={656.4}
        y={267.41}
        width={5.48}
        height={0.88}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "659.14px 267.85px",
        }}
        id="elh0pdh62xxtr"
        className="animable"
      />
      <rect
        x={656.4}
        y={265.46}
        width={5.48}
        height={0.88}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "659.14px 265.9px",
        }}
        id="elnei3ju55to"
        className="animable"
      />
      <rect
        x={561.51}
        y={383.21}
        width={106.82}
        height={20.63}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "614.92px 393.525px",
        }}
        id="el9ub50waa8ik"
        className="animable"
      />
      <path
        d="M602.09,248.65h0a.91.91,0,0,1,.91-.91h23.58a.9.9,0,0,1,.9.91h0a.9.9,0,0,1-.9.9H603A.9.9,0,0,1,602.09,248.65Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "614.785px 248.645px",
        }}
        id="ely7zl1n5d0h"
        className="animable"
      />
      <path
        d="M614.79,413.7a3.29,3.29,0,1,1,3.28-3.28A3.28,3.28,0,0,1,614.79,413.7Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "614.78px 410.41px",
        }}
        id="elourjiaowduj"
        className="animable"
      />
    </g>
    <g
      id="freepik--Mobile--inject-409"
      className="animable"
      style={{
        transformOrigin: "543.925px 370.555px",
      }}
    >
      <path
        d="M574.45,301.42H513.4a6.23,6.23,0,0,0-6.18,6.29V433.4a6.24,6.24,0,0,0,6.18,6.29h61.05a6.24,6.24,0,0,0,6.18-6.29V307.71A6.23,6.23,0,0,0,574.45,301.42Zm2.32,128.09a5.77,5.77,0,0,1-5.53,6H516.62a5.76,5.76,0,0,1-5.54-6v-117c0-6.74,2.47-7.07,5.54-7.07h10.94c.63,0,1.15.72,1.15,1.62v.38c0,2.1,1.15,2.11,2.56,2.11h25.3c1.43,0,2.57,0,2.57-2.11V307c0-.9.51-1.62,1.15-1.62h11c3,0,5.53.89,5.53,7.07Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "543.925px 370.555px",
        }}
        id="elyuwlo6noi5"
        className="animable"
      />
      <path
        d="M547.37,306.9h-6.9a.42.42,0,0,1-.42-.43h0a.43.43,0,0,1,.42-.43h6.9a.43.43,0,0,1,.43.43h0A.43.43,0,0,1,547.37,306.9Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "543.925px 306.47px",
        }}
        id="elmgp8o5f8sid"
        className="animable"
      />
      <path
        d="M576.77,312.48v117a5.77,5.77,0,0,1-5.53,6H516.62a5.76,5.76,0,0,1-5.54-6v-117c0-6.74,2.47-7.07,5.54-7.07h10.94c.63,0,1.15.72,1.15,1.62v.38c0,2.1,1.15,2.11,2.56,2.11h25.3c1.43,0,2.57,0,2.57-2.11V307c0-.9.51-1.62,1.15-1.62h11C574.29,305.41,576.77,306.3,576.77,312.48Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "543.925px 370.43px",
        }}
        id="els58ztkmg38b"
        className="animable"
      />
      <path
        d="M576.77,312.48V332H511.08v-19.5c0-6.74,2.47-7.07,5.54-7.07h10.94c.63,0,1.15.72,1.15,1.62v.38c0,2.1,1.15,2.11,2.56,2.11h25.3c1.43,0,2.57,0,2.57-2.11V307c0-.9.51-1.62,1.16-1.62h10.94C574.29,305.41,576.77,306.3,576.77,312.48Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "543.925px 318.69px",
        }}
        id="eltfrzetukadk"
        className="animable"
      />
      <g
        id="freepik--u5Zjqx--inject-409"
        className="animable"
        style={{
          transformOrigin: "524.52px 308.345px",
        }}
      >
        <path
          d="M525.67,308l-.11.1-.06.06a1.42,1.42,0,0,0-1-.42,1.47,1.47,0,0,0-1,.42l-.05-.06-.11-.1h0l.13-.12a2,2,0,0,1,.31-.2,2,2,0,0,1,.41-.14l.21,0h.31a2.34,2.34,0,0,1,.38.09l.25.12a2.2,2.2,0,0,1,.25.17.52.52,0,0,0,.11.1Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "524.52px 307.85px",
          }}
          id="eltmqwurbeec8"
          className="animable"
        />
        <path
          d="M523.8,308.53l-.17-.16a1.14,1.14,0,0,1,.68-.34,1.16,1.16,0,0,1,1,.34l-.17.16a1,1,0,0,0-1.37,0Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "524.47px 308.274px",
          }}
          id="el70ixd2ld586"
          className="animable"
        />
        <path
          d="M525,308.7l-.17.17a.45.45,0,0,0-.35-.15.49.49,0,0,0-.35.15l-.16-.17A.72.72,0,0,1,525,308.7Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "524.485px 308.677px",
          }}
          id="elaar7vlnucm"
          className="animable"
        />
        <path
          d="M524.63,309a.15.15,0,0,1-.15.15.15.15,0,0,1-.15-.15.15.15,0,0,1,.15-.15A.14.14,0,0,1,524.63,309Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "524.48px 309px",
          }}
          id="elyyuw7j08ewr"
          className="animable"
        />
      </g>
      <path
        d="M515.36,308.36a.44.44,0,0,1,.44-.44.44.44,0,0,1,.43.44.43.43,0,0,1-.43.43A.44.44,0,0,1,515.36,308.36Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "515.795px 308.355px",
        }}
        id="ela5ygxbohts5"
        className="animable"
      />
      <path
        d="M516.73,308.36a.44.44,0,0,1,.44-.44.44.44,0,0,1,.43.44.43.43,0,0,1-.43.43A.44.44,0,0,1,516.73,308.36Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "517.165px 308.355px",
        }}
        id="eldz7rbv9vojk"
        className="animable"
      />
      <path
        d="M518.1,308.36a.44.44,0,0,1,.44-.44.44.44,0,0,1,.43.44.43.43,0,0,1-.43.43A.44.44,0,0,1,518.1,308.36Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "518.535px 308.355px",
        }}
        id="elbehgb9dmwxc"
        className="animable"
      />
      <path
        d="M519.47,308.36a.44.44,0,0,1,.44-.44.44.44,0,0,1,.43.44.43.43,0,0,1-.43.43A.44.44,0,0,1,519.47,308.36Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "519.905px 308.355px",
        }}
        id="elgkb83o3yucr"
        className="animable"
      />
      <path
        d="M520.85,308.36a.44.44,0,0,1,.43-.44.44.44,0,0,1,.44.44.44.44,0,0,1-.44.43A.43.43,0,0,1,520.85,308.36Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "521.285px 308.355px",
        }}
        id="el8qrbigx1v3"
        className="animable"
      />
      <path
        d="M571.84,309.3H568.3v-1.63h3.54Zm-3.37-.17h3.2v-1.29h-3.2Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "570.07px 308.485px",
        }}
        id="ele29yh5asnbe"
        className="animable"
      />
      <rect
        x={568.59}
        y={307.94}
        width={2.28}
        height={1.08}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "569.73px 308.48px",
        }}
        id="el9xaiaaur0jj"
        className="animable"
      />
      <rect
        x={571.84}
        y={308.05}
        width={0.32}
        height={0.88}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "572px 308.49px",
        }}
        id="elbn2cuik93aq"
        className="animable"
      />
      <polygon
        points="514.81 384.42 522.08 384.36 529.35 384.32 543.88 384.29 558.42 384.32 565.69 384.36 572.96 384.42 565.69 384.49 558.42 384.53 543.88 384.56 529.35 384.53 522.08 384.49 514.81 384.42"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "543.885px 384.425px",
        }}
        id="elwpz02mtu8yn"
        className="animable"
      />
      <path
        d="M516.52,369.81a1.19,1.19,0,0,0,.46.1,1.2,1.2,0,0,0,1.2-1.2,1.17,1.17,0,0,0-.64-1Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "517.35px 368.81px",
        }}
        id="elemtou7gedkw"
        className="animable"
      />
      <path
        d="M519.38,365.12a1.2,1.2,0,1,0,1.2-1.2A1.19,1.19,0,0,0,519.38,365.12Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "520.58px 365.12px",
        }}
        id="elhddrxovrl3t"
        className="animable"
      />
      <path
        d="M527.76,366.31a1.2,1.2,0,1,0-1.2-1.19A1.19,1.19,0,0,0,527.76,366.31Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "527.76px 365.11px",
        }}
        id="el8qrjzx3ky6d"
        className="animable"
      />
      <path
        d="M531.35,362.72a1.2,1.2,0,1,0-1.19-1.19A1.19,1.19,0,0,0,531.35,362.72Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "531.36px 361.52px",
        }}
        id="elrsax5z937jh"
        className="animable"
      />
      <path
        d="M524.17,367.51a1.2,1.2,0,1,0,1.19,1.2A1.2,1.2,0,0,0,524.17,367.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "524.16px 368.71px",
        }}
        id="elq55c5kvgdef"
        className="animable"
      />
      <path
        d="M527.76,359.13a1.2,1.2,0,1,0-1.2-1.2A1.2,1.2,0,0,0,527.76,359.13Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "527.76px 357.93px",
        }}
        id="elpvmv5fqnzbl"
        className="animable"
      />
      <path
        d="M524.17,360.33a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "524.061px 361.525px",
        }}
        id="elo5aczuhl4z"
        className="animable"
      />
      <path
        d="M525.36,354.34a1.17,1.17,0,0,0-.88-1.13l-1,2.08a1.14,1.14,0,0,0,.69.25A1.19,1.19,0,0,0,525.36,354.34Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "524.42px 354.375px",
        }}
        id="elmhevnh1y8o"
        className="animable"
      />
      <path
        d="M534.94,366.31a1.2,1.2,0,1,0-1.19-1.19A1.19,1.19,0,0,0,534.94,366.31Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "534.95px 365.11px",
        }}
        id="el5izwp0wu0d8"
        className="animable"
      />
      <path
        d="M538.54,362.72a1.2,1.2,0,1,0-1.2-1.19A1.19,1.19,0,0,0,538.54,362.72Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "538.54px 361.52px",
        }}
        id="el5444nc6y6lx"
        className="animable"
      />
      <path
        d="M531.35,367.51a1.19,1.19,0,0,0-1.19,1.2,1.17,1.17,0,0,0,.15.56l2.17-.24a1.14,1.14,0,0,0,.07-.32A1.2,1.2,0,0,0,531.35,367.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "531.355px 368.39px",
        }}
        id="el71w3k4jhgfo"
        className="animable"
      />
      <path
        d="M534.94,359.13a1.2,1.2,0,1,0,0-2.39,1.2,1.2,0,0,0,0,2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "535.049px 357.935px",
        }}
        id="eldgbc707p9hl"
        className="animable"
      />
      <path
        d="M531.77,355.45a1.18,1.18,0,0,0,.78-1.11,1.14,1.14,0,0,0-.13-.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "532.16px 354.64px",
        }}
        id="eltggf8vk7yg"
        className="animable"
      />
      <path
        d="M533.75,350.75a1.2,1.2,0,1,0,1.84-1l-.44-.17a.72.72,0,0,0-.21-.05,1.17,1.17,0,0,0-1.06.68l0,.13A1,1,0,0,0,533.75,350.75Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "534.95px 350.748px",
        }}
        id="ela5ss966cdlc"
        className="animable"
      />
      <path
        d="M537.34,354.34a1.2,1.2,0,1,0,1.2-1.19A1.2,1.2,0,0,0,537.34,354.34Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "538.54px 354.35px",
        }}
        id="elq51kkqd1q5c"
        className="animable"
      />
      <path
        d="M542.13,363.92a1.2,1.2,0,1,0,0,2.39,1.2,1.2,0,0,0,0-2.39Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "542.021px 365.115px",
        }}
        id="el55xzysvbyee"
        className="animable"
      />
      <path
        d="M545.25,367.61l.61-.07c-.05,0-.09,0-.14,0A1.24,1.24,0,0,0,545.25,367.61Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "545.555px 367.574px",
        }}
        id="eln6t6wttbc6a"
        className="animable"
      />
      <path
        d="M538.54,367.51a1.19,1.19,0,0,0-1.16,1l2.25-.25A1.19,1.19,0,0,0,538.54,367.51Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "538.505px 368.01px",
        }}
        id="elccjfrjjb4xg"
        className="animable"
      />
      <path
        d="M542.13,356.74a1.2,1.2,0,1,0,1.19,1.19A1.19,1.19,0,0,0,542.13,356.74Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "542.12px 357.94px",
        }}
        id="elxkazmxsvjyf"
        className="animable"
      />
      <path
        d="M544.52,354.34a1.2,1.2,0,1,0,2.4,0s0,0,0-.06l-2-.8A1.19,1.19,0,0,0,544.52,354.34Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "545.72px 354.51px",
        }}
        id="eljjsz0e17qqe"
        className="animable"
      />
      <path
        d="M549.31,363.92a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,549.31,363.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "549.31px 365.12px",
        }}
        id="elg6qdtc5ldmn"
        className="animable"
      />
      <path
        d="M549.31,356.74a1.2,1.2,0,1,0,1.2,1.19A1.19,1.19,0,0,0,549.31,356.74Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "549.31px 357.94px",
        }}
        id="el58u0e9qimbw"
        className="animable"
      />
      <path
        d="M551.71,361.53a1.2,1.2,0,0,0,2.39,0,1.2,1.2,0,1,0-2.39,0Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "552.905px 361.421px",
        }}
        id="elmtio0zu88f"
        className="animable"
      />
      <path
        d="M545.72,360.33a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,545.72,360.33Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "545.72px 361.53px",
        }}
        id="elpuxg04ngmh"
        className="animable"
      />
      <path
        d="M556.49,363.92a1.2,1.2,0,1,0,1.2,1.2A1.19,1.19,0,0,0,556.49,363.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "556.49px 365.12px",
        }}
        id="el46ua3fk88qe"
        className="animable"
      />
      <path
        d="M556.49,359.13a1.16,1.16,0,0,0,1-.62l-2.16-.86a.88.88,0,0,0-.06.28A1.19,1.19,0,0,0,556.49,359.13Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "556.38px 358.39px",
        }}
        id="eln7ku78q676l"
        className="animable"
      />
      <path
        d="M558.89,361.53a1.2,1.2,0,1,0,1.2-1.2A1.19,1.19,0,0,0,558.89,361.53Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "560.09px 361.53px",
        }}
        id="elipcmwkaqf3"
        className="animable"
      />
      <path
        d="M563.68,363.92a1.17,1.17,0,0,0-1,1.76l2.17-.25a1.07,1.07,0,0,0,.06-.31A1.19,1.19,0,0,0,563.68,363.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "563.715px 364.8px",
        }}
        id="elva2u0pw90wg"
        className="animable"
      />
      <path
        d="M567.27,362.72a1.27,1.27,0,0,0,.51-.12l-1.62-.64A1.2,1.2,0,0,0,567.27,362.72Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "566.97px 362.34px",
        }}
        id="ell6zz9bca12f"
        className="animable"
      />
      <path
        d="M570.86,363.92a1.18,1.18,0,0,0-1.15,1l2.25-.25a1.23,1.23,0,0,0-.63-.62h0A1.34,1.34,0,0,0,570.86,363.92Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "570.835px 364.42px",
        }}
        id="elxbhmnmrjxqr"
        className="animable"
      />
      <polygon
        points="531.52 356.07 531.77 355.45 532.42 353.83 533.83 350.36 533.88 350.23 539.17 337.13 515.08 348.55 531.52 356.07"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "527.125px 346.6px",
        }}
        id="elmjf9uoixxyp"
        className="animable"
      />
      <path
        d="M543.18,367.07c-1.34,4.4-8.3,21.22-18,20.75-3.29-.16-4.5-13.09,1.69-26.15.19-.39,10,1,10,3.4,0,.25-2.09,5.81-1.81,5.86.05,0,1.15-1.15,6-6.09C543.45,362.37,543.94,364.56,543.18,367.07Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "532.989px 374.717px",
        }}
        id="el68dgg6ayprq"
        className="animable"
      />
      <path
        d="M535.06,370.88q-1.21,4.81-2.66,9.56c-.27.88-.53,1.76-.8,2.65a0,0,0,0,0,.06,0,45.47,45.47,0,0,0,1.69-4.66,48.48,48.48,0,0,0,1.26-4.77c.19-.92.36-1.84.52-2.77C535.13,370.86,535.07,370.85,535.06,370.88Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "533.365px 376.976px",
        }}
        id="elm10gs6ady3"
        className="animable"
      />
      <path
        d="M535.47,369.83s-.67.73-.19,1.18,1.38-.1,1.38-.1-.41.86.13,1.25,1.34-.34,1.34-.34-.4.87.13,1.27,1.29-.37,1.29-.37-.35.89.11,1.26,1.14-.42,1.14-.42-.34.89.14,1.19,1.16-.61,1.16-.61Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "538.603px 372.32px",
        }}
        id="elw8l6qcw9xkj"
        className="animable"
      />
      <path
        d="M535.14,370l7,4.5s2.31-6.24,2.43-8.78-.67-3.74-4.1-.9A38.87,38.87,0,0,0,535.14,370Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "539.861px 368.839px",
        }}
        id="elmwnmztnw3m"
        className="animable"
      />
      <g id="eld7elc0xf0c">
        <path
          d="M542.09,374.51h0c0-.08.67-1.8,1.28-3.79,0-.12.08-.24.11-.36s.15-.49.22-.74.21-.73.3-1.09l.09-.36a15.67,15.67,0,0,0,.4-2.06l0-.38c.05-1.11-.07-2-.51-2.34l-6.84,7.94Z"
          style={{
            opacity: 0.1,
            transformOrigin: "540.82px 368.95px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M526,364.09a19,19,0,0,1,3.42-6.42c1.51-1.62,3.63-4.21,10.1-2.78,1.53.35,4.17,2.69,3.29,3.65s-3-1.57-5.25-1.64c0,0,2.23,2.17,1.83,3.69A28.07,28.07,0,0,1,538,364.5a5.72,5.72,0,0,1-.75-.18,4.7,4.7,0,0,1-2.41,2.46Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "534.493px 360.627px",
        }}
        id="el1gsudf2mz2g"
        className="animable"
      />
      <path
        d="M531.08,356.35a17.35,17.35,0,0,1,5.69.2c2.3.59,6.57,2.34,6.12,4-.33,1.24-2,.71-2,.71s1.61,1.1,1.07,2.16-1.78.47-1.78.47a1.29,1.29,0,0,1,.21,1.67c-.79,1-3.06-1.3-4.74-1C535.25,364.7,531.08,356.35,531.08,356.35Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "537.001px 361.009px",
        }}
        id="eljtydz9851m"
        className="animable"
      />
      <path
        d="M535.74,358.88a9.55,9.55,0,0,1,5.12,2.37s0,.08,0,.06a23.16,23.16,0,0,0-5.1-2.41S535.73,358.88,535.74,358.88Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "538.299px 360.097px",
        }}
        id="el7psdznelwbk"
        className="animable"
      />
      <path
        d="M536.63,356.34a9.49,9.49,0,0,1,5.11,2.37s0,.08,0,.06a23.39,23.39,0,0,0-5.11-2.41S536.62,356.34,536.63,356.34Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "539.183px 357.557px",
        }}
        id="elnux1bh4x3d"
        className="animable"
      />
      <path
        d="M535.22,361.22a10.63,10.63,0,0,1,5,2.67s0,.08,0,.06a25,25,0,0,0-4.95-2.71S535.2,361.22,535.22,361.22Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "537.718px 362.587px",
        }}
        id="elcp7s2vezksi"
        className="animable"
      />
      <path
        d="M537.15,364.81a4.54,4.54,0,0,0-2.72-.53,0,0,0,0,0,0,.05c.77.11,1.41.24,1.42.24a6.26,6.26,0,0,1-3.28,2.06c-.05,0,0,.08,0,.08A4,4,0,0,0,536,364.6c.17,0,1.07.26,1.18.25S537.16,364.82,537.15,364.81Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "534.886px 365.479px",
        }}
        id="el7ay2wn1g1bw"
        className="animable"
      />
      <path
        d="M539.35,386.59l.5-8.36s-2.25-2.48-1.75-4.73a93.9,93.9,0,0,1,4.51-10.13c1.52-1.42,4.1-.86,5.16-.06,5.86,4.43,10,13.94,12,23.28Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "548.899px 374.544px",
        }}
        id="el1psw0n2tuc"
        className="animable"
      />
      <path
        d="M539.35,386.59l.5-8.36s-2.25-2.48-1.75-4.73a93.9,93.9,0,0,1,4.51-10.13c1.52-1.42,4.1-.86,5.16-.06,5.86,4.43,10,13.94,12,23.28Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "548.899px 374.544px",
        }}
        id="elby6vpfgecfs"
        className="animable"
      />
      <path
        d="M539.85,378.23l-.5,8.36h20.39c-2-9.34-6.11-18.85-12-23.28a3.42,3.42,0,0,0-.74-.41,4.25,4.25,0,0,0-4.43.47,94.83,94.83,0,0,0-4.5,10.13C537.6,375.75,539.85,378.23,539.85,378.23Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "548.873px 374.537px",
        }}
        id="elbpdxrgq97o"
        className="animable"
      />
      <path
        d="M539.85,378.23l-.5,8.36h20.39c-2-9.34-6.11-18.85-12-23.28a3.42,3.42,0,0,0-.74-.41,4.25,4.25,0,0,0-4.43.47,94.83,94.83,0,0,0-4.5,10.13C537.6,375.75,539.85,378.23,539.85,378.23Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "548.873px 374.537px",
        }}
        id="elrbiy7as0rl"
        className="animable"
      />
      <g id="elk2vlobuvwq">
        <path
          d="M545.53,362.52l5.18,24.07h9c-2-9.34-6.11-18.85-12-23.28a3.42,3.42,0,0,0-.74-.41A5.69,5.69,0,0,0,545.53,362.52Z"
          style={{
            opacity: 0.1,
            transformOrigin: "552.62px 374.555px",
          }}
          className="animable"
        />
      </g>
      <rect
        x={526.26}
        y={395.16}
        width={4.76}
        height={0.74}
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "528.64px 395.53px",
        }}
        id="elzn48wq3rygg"
        className="animable"
      />
      <rect
        x={526.26}
        y={395.16}
        width={4.76}
        height={0.49}
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "528.64px 395.405px",
        }}
        id="el4j9i4282ukd"
        className="animable"
      />
      <path
        d="M536.67,393.16a2.67,2.67,0,0,1-2.68,1.15,2.54,2.54,0,0,1-.48-.14,3.32,3.32,0,0,1-.67-.35l.6-.9a2.23,2.23,0,0,0,.36.2,1.58,1.58,0,0,0,2-.58c.65-.92.89-3,.23-3.82,0-.06-.24-.15-1,0l-.49.12a1.56,1.56,0,0,1-.43.09l0-.53-.05-.54.22-.06a7.75,7.75,0,0,1,1.08-.23,1.66,1.66,0,0,1,1.52.46C537.93,389.35,537.49,392,536.67,393.16Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "535.168px 390.942px",
        }}
        id="elq4ezb7pzpym"
        className="animable"
      />
      <path
        d="M533.44,392.92a2.23,2.23,0,0,0,.36.2l-.29,1.05a3.32,3.32,0,0,1-.67-.35Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "533.32px 393.545px",
        }}
        id="el682496oly9w"
        className="animable"
      />
      <path
        d="M535.33,387.58l-.31,1.15-.49.12a1.56,1.56,0,0,1-.43.09l0-.53-.05-.54.22-.06A7.75,7.75,0,0,1,535.33,387.58Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "534.69px 388.26px",
        }}
        id="elsawtuf2aghg"
        className="animable"
      />
      <path
        d="M522.31,386.18s.57,4.85,1.56,6.9a3.39,3.39,0,0,0,3.22,2.26h3.11a3.39,3.39,0,0,0,3.22-2.26c1-2.05,1.56-6.9,1.56-6.9Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "528.645px 390.76px",
        }}
        id="elxmbkywi5thc"
        className="animable"
      />
      <path
        d="M523.24,389.36a.31.31,0,1,1,.31-.31A.31.31,0,0,1,523.24,389.36Zm0-.56a.25.25,0,0,0-.24.25.25.25,0,1,0,.49,0A.25.25,0,0,0,523.24,388.8Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "523.24px 389.05px",
        }}
        id="ely496xwsiy7k"
        className="animable"
      />
      <path
        d="M532.27,387.79a.31.31,0,1,1,0-.61.31.31,0,0,1,0,.61Zm0-.55a.25.25,0,0,0-.25.25.25.25,0,0,0,.25.25.26.26,0,0,0,.25-.25A.25.25,0,0,0,532.27,387.24Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "532.215px 387.485px",
        }}
        id="el9m7gbwvz0ue"
        className="animable"
      />
      <path
        d="M531.82,392.26a.31.31,0,1,1,0-.61.31.31,0,0,1,0,.61Zm0-.55a.25.25,0,1,0,0,.49.25.25,0,1,0,0-.49Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "531.787px 391.955px",
        }}
        id="elp96kdr0ieoe"
        className="animable"
      />
      <path
        d="M534.3,387a.29.29,0,0,1-.3-.3.3.3,0,0,1,.3-.31.31.31,0,0,1,.31.31A.3.3,0,0,1,534.3,387Zm0-.55a.25.25,0,0,0-.25.25.26.26,0,0,0,.25.25.25.25,0,0,0,.25-.25A.25.25,0,0,0,534.3,386.44Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "534.305px 386.695px",
        }}
        id="ela19m0fugpbf"
        className="animable"
      />
      <path
        d="M530.06,388.89a.31.31,0,1,1,.3-.3A.31.31,0,0,1,530.06,388.89Zm0-.55a.25.25,0,1,0,0,.49.25.25,0,1,0,0-.49Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "530.05px 388.58px",
        }}
        id="elj04a3471mr"
        className="animable"
      />
      <path
        d="M526.74,392.13a.29.29,0,0,1-.3-.3.3.3,0,0,1,.3-.31.31.31,0,0,1,.31.31A.3.3,0,0,1,526.74,392.13Zm0-.55a.25.25,0,1,0,.25.25A.25.25,0,0,0,526.74,391.58Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "526.745px 391.825px",
        }}
        id="elq213n29izs"
        className="animable"
      />
      <path
        d="M528.77,387a.3.3,0,0,1-.31-.3.31.31,0,0,1,.31-.31.3.3,0,0,1,.3.31A.29.29,0,0,1,528.77,387Zm0-.55a.25.25,0,0,0-.25.25.25.25,0,1,0,.5,0A.25.25,0,0,0,528.77,386.44Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "528.765px 386.695px",
        }}
        id="els3hjbbknhqr"
        className="animable"
      />
      <path
        d="M529.15,390.91a.3.3,0,0,1-.31-.3.31.31,0,1,1,.61,0A.3.3,0,0,1,529.15,390.91Zm0-.55a.25.25,0,0,0-.25.25.25.25,0,0,0,.25.25.26.26,0,0,0,.25-.25A.25.25,0,0,0,529.15,390.36Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "529.145px 390.577px",
        }}
        id="eliqdok214ee"
        className="animable"
      />
      <path
        d="M532.17,394.56a.31.31,0,1,1,0-.61.31.31,0,0,1,0,.61Zm0-.55a.25.25,0,1,0,.25.24A.25.25,0,0,0,532.17,394Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "532.115px 394.255px",
        }}
        id="eltcx7nbsd2s"
        className="animable"
      />
      <path
        d="M524.76,387.45a.31.31,0,1,1,0-.61.31.31,0,0,1,0,.61Zm0-.55a.25.25,0,1,0,.24.24A.25.25,0,0,0,524.76,386.9Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "524.705px 387.145px",
        }}
        id="else9i6zv4lf"
        className="animable"
      />
      <path
        d="M533.26,390.08a.31.31,0,0,1,0-.61.31.31,0,1,1,0,.61Zm0-.55a.25.25,0,1,0,.25.25A.25.25,0,0,0,533.26,389.53Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "533.316px 389.775px",
        }}
        id="eldyvg5rqiwus"
        className="animable"
      />
      <path
        d="M527,389.17a.31.31,0,0,1-.31-.31.3.3,0,0,1,.31-.3.29.29,0,0,1,.3.3A.3.3,0,0,1,527,389.17Zm0-.56a.25.25,0,0,0-.25.25.25.25,0,0,0,.5,0A.25.25,0,0,0,527,388.61Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "526.995px 388.865px",
        }}
        id="elhlhsyr7tksd"
        className="animable"
      />
      <path
        d="M529.15,394.1a.31.31,0,1,1,.3-.3A.31.31,0,0,1,529.15,394.1Zm0-.55a.25.25,0,1,0,0,.49.25.25,0,1,0,0-.49Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "529.14px 393.79px",
        }}
        id="elo7ekwa807p"
        className="animable"
      />
      <path
        d="M526.58,395.2a.31.31,0,0,1,0-.61.31.31,0,1,1,0,.61Zm0-.55a.25.25,0,0,0-.25.25.25.25,0,0,0,.5,0A.25.25,0,0,0,526.58,394.65Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "526.636px 394.895px",
        }}
        id="elbhckdpqmd7p"
        className="animable"
      />
      <path
        d="M524.76,390.63a.3.3,0,0,1-.31-.3.31.31,0,1,1,.61,0A.3.3,0,0,1,524.76,390.63Zm0-.55a.25.25,0,1,0,.24.25A.25.25,0,0,0,524.76,390.08Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "524.755px 390.297px",
        }}
        id="el0jt9xa71p4l"
        className="animable"
      />
      <path
        d="M524.48,393.38a.3.3,0,0,1-.31-.3.31.31,0,0,1,.31-.31.3.3,0,0,1,.3.31A.29.29,0,0,1,524.48,393.38Zm0-.55a.25.25,0,1,0,.25.25A.25.25,0,0,0,524.48,392.83Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "524.475px 393.075px",
        }}
        id="elfdhgw762nhw"
        className="animable"
      />
      <path
        d="M550.66,365.19c7.12,5.36,16.31,19.95,15,23.44-1.25,3.18-12.18,6.37-18.62,7.25-1.23.17-2.09-10.28-.78-10.51a59.1,59.1,0,0,0,7.77-2.72c.33-.08-5.68-11.39-6.77-15.41C546,362.68,547.54,362.84,550.66,365.19Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "555.695px 379.728px",
        }}
        id="elgdn3pt91tuu"
        className="animable"
      />
      <path
        d="M533.15,387.79c.13,1.17,3,.61,3,.61a2.18,2.18,0,0,0-.75,1.29c-.12.89,1.22,1.47,1.22,1.47a2.74,2.74,0,0,0-.57,1.48c0,.87,1.24,1.65,1.24,1.65a2.34,2.34,0,0,0,.32,1.62c.84,1.26,3.15,1.19,4.63,1.16s5.62-1.48,6.44-1.68l-.09-9.46s-6.65-1.75-8.37-1.48C538.77,384.69,533,386,533.15,387.79Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "540.914px 390.752px",
        }}
        id="eli5behnnks6j"
        className="animable"
      />
      <path
        d="M536.18,388.37a19.52,19.52,0,0,1,4.7-1.12s0,.05,0,.05c-1.58.22-3.13.86-4.69,1.14C536.16,388.45,536.14,388.38,536.18,388.37Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "538.519px 387.845px",
        }}
        id="eltkrc7wpbns"
        className="animable"
      />
      <path
        d="M536.66,391a4.45,4.45,0,0,1,1.09-.19c.38,0,.77-.07,1.16-.09.78-.06,1.56-.09,2.34-.1,0,0,0,0,0,0-.77.06-1.55.12-2.32.21l-1.16.13a4.46,4.46,0,0,1-1.09.09A.05.05,0,0,1,536.66,391Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "538.955px 390.837px",
        }}
        id="elw0rfd2dvq2g"
        className="animable"
      />
      <path
        d="M537.33,394.28a3,3,0,0,1,1-.11l1.06-.06c.71,0,1.42-.08,2.13-.14,0,0,0,0,0,0l-2.16.22-1.06.08a2.58,2.58,0,0,1-1,0A0,0,0,0,1,537.33,394.28Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "539.41px 394.144px",
        }}
        id="elkhpwvd6h71p"
        className="animable"
      />
      <path
        d="M555.39,369.17s.72.69.27,1.17-1.38,0-1.38,0,.46.82,0,1.25-1.35-.25-1.35-.25.45.85,0,1.28-1.32-.28-1.32-.28.42.87,0,1.27-1.17-.35-1.17-.35.41.87-.05,1.2-1.2-.53-1.2-.53Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "552.499px 371.853px",
        }}
        id="el2bj36bcg26i"
        className="animable"
      />
      <path
        d="M555.74,369.33l-6.64,5s-2.73-6.07-3-8.59-.52-4.22,3.31-1.67A39.59,39.59,0,0,1,555.74,369.33Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "550.852px 368.569px",
        }}
        id="eltg3g19cehno"
        className="animable"
      />
      <path
        d="M542.58,363.26s-.33,3,.22,3.12c1.69.26,3.74-2,3.86-2.34,0-.09,0-2.46,0-4.31v-2.16l-4.51,2.24.36,3.22Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "544.405px 361.985px",
        }}
        id="el1rclk75lns7"
        className="animable"
      />
      <path
        d="M542.19,359.81l.36,3.22a6.49,6.49,0,0,0,4.15-3.3v-2.16l-.57.26Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "544.445px 360.3px",
        }}
        id="ell0c6m8ylw9n"
        className="animable"
      />
      <path
        d="M538.32,354.71c-.87,5.62,2.4,6.78,3.61,7s4.87.61,6.32-4.89-.89-7.68-3.39-8.2S539.19,349.09,538.32,354.71Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "543.435px 355.157px",
        }}
        id="elwt6endnuo8"
        className="animable"
      />
      <path
        d="M541.64,361.59a4.12,4.12,0,0,1-2.42-1.85,6.49,6.49,0,0,1-.94-3.39,14.22,14.22,0,0,1,.11-2.06s0,0,0,0a8.26,8.26,0,0,0-.09,3.86,4.81,4.81,0,0,0,1.74,2.78,4.5,4.5,0,0,0,1.61.72A0,0,0,0,0,541.64,361.59Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "539.882px 357.97px",
        }}
        id="elbfp8mpc0gqp"
        className="animable"
      />
      <path
        d="M539.49,354l.39,0a.61.61,0,0,0,.39-.13.2.2,0,0,0,0-.24.48.48,0,0,0-.48-.16.72.72,0,0,0-.48.22A.22.22,0,0,0,539.49,354Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "539.8px 353.729px",
        }}
        id="elhj1rz5f7a9s"
        className="animable"
      />
      <path
        d="M543.41,354.47c-.14,0-.25-.08-.38-.12a.62.62,0,0,1-.36-.18.2.2,0,0,1,0-.25.53.53,0,0,1,.5-.08.75.75,0,0,1,.44.3A.22.22,0,0,1,543.41,354.47Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "543.133px 354.14px",
        }}
        id="eljtjuqslnihp"
        className="animable"
      />
      <path
        d="M542.53,355.83s0,0,0,0c-.06.38,0,.83.28,1,0,0,0,0,0,0C542.42,356.78,542.42,356.19,542.53,355.83Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "542.638px 356.33px",
        }}
        id="elnbrlkspxvs"
        className="animable"
      />
      <path
        d="M542.92,355.47c.62,0,.54,1.26,0,1.25S542.39,355.45,542.92,355.47Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "542.937px 356.095px",
        }}
        id="elgc1qn15ssj6"
        className="animable"
      />
      <path
        d="M543.16,355.55c.1.08.2.21.33.23s.28-.1.38-.21,0,0,0,0c0,.24-.12.48-.38.5s-.39-.21-.43-.45C543.07,355.58,543.12,355.52,543.16,355.55Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "543.487px 355.796px",
        }}
        id="elkf0bu1etigf"
        className="animable"
      />
      <path
        d="M540.12,355.49l0,0c0,.39-.09.83-.45.94,0,0,0,0,0,0C540.08,356.45,540.17,355.86,540.12,355.49Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "539.901px 355.96px",
        }}
        id="el26678zq4wv"
        className="animable"
      />
      <path
        d="M539.8,355.07c-.62-.09-.74,1.16-.17,1.24S540.32,355.14,539.8,355.07Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "539.697px 355.69px",
        }}
        id="el3lfoi7gowep"
        className="animable"
      />
      <path
        d="M539.53,355.12c-.1.06-.21.17-.33.17s-.22-.15-.3-.29,0,0,0,0c0,.24,0,.49.25.56s.38-.12.46-.36C539.6,355.17,539.58,355.1,539.53,355.12Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "539.237px 355.256px",
        }}
        id="elws6mb3je3jb"
        className="animable"
      />
      <path
        d="M542.14,358.78c-.1.09-.19.21-.33.22a1,1,0,0,1-.42-.1s0,0,0,0a.52.52,0,0,0,.48.22.43.43,0,0,0,.32-.34A0,0,0,0,0,542.14,358.78Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "541.79px 358.951px",
        }}
        id="el8a9q5by0kq3"
        className="animable"
      />
      <path
        d="M540.64,357.26a8.25,8.25,0,0,0-.15.84s.06,0,.15.06h0a1.33,1.33,0,0,0,1.3-.38,0,0,0,0,0,0,0,2,2,0,0,1-1.21.24c0-.08.25-1,.21-1a3,3,0,0,0-.63.09c.23-1.19.64-2.34.85-3.53a0,0,0,0,0-.07,0A20.44,20.44,0,0,0,540,357.3C540,357.46,540.52,357.3,540.64,357.26Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "540.97px 355.891px",
        }}
        id="el25de9p8v1go"
        className="animable"
      />
      <path
        d="M542.59,357.76a1.79,1.79,0,0,1-1.25-2.18h0a1.78,1.78,0,1,1,1.69,2.24A1.67,1.67,0,0,1,542.59,357.76Zm-1-2.12a1.54,1.54,0,1,0,1.89-1.08,1.65,1.65,0,0,0-.38-.05A1.54,1.54,0,0,0,541.57,355.64Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "543.06px 356.04px",
        }}
        id="elipe4gda4d3"
        className="animable"
      />
      <path
        d="M538.88,357.25a1.6,1.6,0,0,1-1.08-1.93,2.09,2.09,0,0,1,.54-1.18,1.19,1.19,0,0,1,1-.41,1.17,1.17,0,0,1,.86.66,2.09,2.09,0,0,1,.21,1.28,1.65,1.65,0,0,1-1.43,1.59Zm-.37-2.95a1.85,1.85,0,0,0-.47,1.05c-.11.84.28,1.59.87,1.67s1.17-.54,1.28-1.38a2,2,0,0,0-.18-1.14,1,1,0,0,0-.69-.53h-.09A1,1,0,0,0,538.51,354.3Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "539.092px 355.493px",
        }}
        id="elsv4i69ssyw"
        className="animable"
      />
      <path
        d="M541.38,355.79l.17-.16a1,1,0,0,0-1.3-.24l.15.19C540.84,355.21,541.35,355.77,541.38,355.79Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "540.9px 355.519px",
        }}
        id="el8h1jzbb27ib"
        className="animable"
      />
      <path
        d="M549.16,357.29l.22-.08c0-.1-.38-1-.9-1.18s-3.67-.27-3.8-.27V356c.91,0,3.37.15,3.75.26S549.06,357,549.16,357.29Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "547.03px 356.525px",
        }}
        id="elzx20wp5r5ej"
        className="animable"
      />
      <path
        d="M547.94,356.43c-1.31-.07-1.18-2.14-1.18-2.14s-1.49-.22-1.46-2.63a3.65,3.65,0,0,1-2.83,1.59c-.43,0,1.06-1,1.06-1a5.9,5.9,0,0,1-5,.68c-.22-.05.45-3.39,3-4.39a5.29,5.29,0,0,1,6.23,1.44,3.72,3.72,0,0,1,1.9,2.69C549.82,354.44,548.45,356.45,547.94,356.43Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "544.082px 352.253px",
        }}
        id="el4caw1mlj68"
        className="animable"
      />
      <path
        d="M547.29,357.07s1.36-1.69,2.13-1.19-.33,3-1.3,3.29a1,1,0,0,1-1.25-.6Z"
        style={{
          fill: "rgb(173, 99, 89)",
          transformOrigin: "548.274px 357.504px",
        }}
        id="el7hpnt9z12w6"
        className="animable"
      />
      <path
        d="M549.09,356.59s0,0,0,0a2.48,2.48,0,0,0-1.36,1.44.54.54,0,0,1,.81-.09s0,0,0,0a.6.6,0,0,0-.67.16,2.83,2.83,0,0,0-.34.51s-.14,0-.12-.05v0C547.44,357.75,548.17,356.61,549.09,356.59Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "548.249px 357.6px",
        }}
        id="elcwlnt4u470b"
        className="animable"
      />
      <path
        d="M547.61,358.88a.2.2,0,0,0,.19.21.2.2,0,1,0,0-.4A.19.19,0,0,0,547.61,358.88Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "547.805px 358.89px",
        }}
        id="elqch0djne7zq"
        className="animable"
      />
      <path
        d="M548.18,358.56a.13.13,0,1,0,.13-.12A.12.12,0,0,0,548.18,358.56Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "548.31px 358.57px",
        }}
        id="elafgnrabqms"
        className="animable"
      />
      <path
        d="M549.28,357l.38.11a.08.08,0,0,0,.1-.05h0a.08.08,0,0,0,0-.1l-.39-.11s-.08,0-.1,0h0A.09.09,0,0,0,549.28,357Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "549.506px 356.982px",
        }}
        id="elejpkftbf8c"
        className="animable"
      />
      <path
        d="M549.34,356.75l.4.06a.1.1,0,0,0,.09-.07h0a.08.08,0,0,0-.07-.09l-.4-.06a.1.1,0,0,0-.09.07h0A.08.08,0,0,0,549.34,356.75Z"
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "549.55px 356.7px",
        }}
        id="elivya8m5hvwj"
        className="animable"
      />
      <path
        d="M542.17,364.1c0,.33-.06.66-.07,1a5.48,5.48,0,0,0,0,1,1.69,1.69,0,0,0,.35.87.84.84,0,0,0,.87.11,5.71,5.71,0,0,0,1.74-.92,7.9,7.9,0,0,0,.79-.61,6.78,6.78,0,0,0,.73-.69,7.4,7.4,0,0,1-1.44,1.41,6.81,6.81,0,0,1-.84.56,3.87,3.87,0,0,1-.93.41,1.35,1.35,0,0,1-.53.06.66.66,0,0,1-.27-.06.69.69,0,0,1-.22-.16,1.14,1.14,0,0,1-.27-.46,2.13,2.13,0,0,1-.11-.5A6.44,6.44,0,0,1,542.17,364.1Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "544.269px 365.702px",
        }}
        id="ele9dzc5qchum"
        className="animable"
      />
      <path
        d="M547,350.51c-1-.63-3.31-5.44.79-8s9.38,2.57,6.53,6.7A5.3,5.3,0,0,1,547,350.51Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "550.286px 346.632px",
        }}
        id="el8ms08vmkyxm"
        className="animable"
      />
      <path
        d="M519.93,318.92v2.48a.24.24,0,0,1-.24.23.23.23,0,0,1-.23-.23v-1.77l-.6.84a.23.23,0,0,1-.19.09h0a.23.23,0,0,1-.19-.09l-.6-.84v1.77a.24.24,0,0,1-.47,0v-2.48a.23.23,0,0,1,.23-.23h0a.21.21,0,0,1,.18.1l.84,1.15.83-1.15a.22.22,0,0,1,.19-.1h0A.23.23,0,0,1,519.93,318.92Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "518.67px 320.16px",
        }}
        id="el86eltg06o1t"
        className="animable"
      />
      <path
        d="M523.7,319.05a1.53,1.53,0,0,1,0,2.13,1.36,1.36,0,0,1-1,.45,1.44,1.44,0,0,1-1-.45,1.59,1.59,0,0,1,0-2.13,1.44,1.44,0,0,1,1-.45A1.36,1.36,0,0,1,523.7,319.05Zm-.05,1.07a1.07,1.07,0,0,0-.29-.74.89.89,0,0,0-.66-.3.92.92,0,0,0-.68.3,1.06,1.06,0,0,0-.28.74,1,1,0,0,0,.28.73,1,1,0,0,0,.68.3.93.93,0,0,0,.66-.3A1,1,0,0,0,523.65,320.12Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "522.711px 320.115px",
        }}
        id="el9cofscnicev"
        className="animable"
      />
      <path
        d="M525.94,321.4a.24.24,0,0,1-.23.23.23.23,0,0,1-.23-.23V319h0a.23.23,0,0,1,.1-.19.22.22,0,0,1,.31.05l1.36,1.88V319a.23.23,0,0,1,.23-.23.24.24,0,0,1,.23.23v2.44a.24.24,0,0,1-.23.23.26.26,0,0,1-.18-.09l0,0-1.34-1.87Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "526.595px 320.219px",
        }}
        id="eloboye3fkh1p"
        className="animable"
      />
      <path
        d="M531.1,319.15h-.67v2.25a.23.23,0,0,1-.23.23.23.23,0,0,1-.23-.23v-2.25h-.66a.23.23,0,0,1-.23-.23.23.23,0,0,1,.23-.23h1.79a.23.23,0,0,1,.23.23A.23.23,0,0,1,531.1,319.15Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "530.205px 320.16px",
        }}
        id="elyxkk8t6bbhf"
        className="animable"
      />
      <path
        d="M534.45,319.15h-.66v2.25a.23.23,0,0,1-.24.23.23.23,0,0,1-.23-.23v-2.25h-.66a.23.23,0,0,1-.23-.23.23.23,0,0,1,.23-.23h1.79a.23.23,0,0,1,.23.23A.23.23,0,0,1,534.45,319.15Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "533.555px 320.16px",
        }}
        id="elytyo2wqc9l9"
        className="animable"
      />
      <path
        d="M517.87,326.84l-.31.43s0,0,0,0h0s0,0,0,0l-.32-.43v.81a0,0,0,0,1,0,0,0,0,0,0,1,0,0v-.91s0,0,0,0h0l.35.49.34-.49h.06v.93a0,0,0,0,1,0,0,0,0,0,0,1,0,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "517.615px 327.205px",
        }}
        id="el6ilw5quhgxn"
        className="animable"
      />
      <path
        d="M518.43,326.73a0,0,0,0,1,0,0H519a0,0,0,0,1,0,0s0,0,0,0h-.47v.38h.41s0,0,0,0a0,0,0,0,1,0,0h-.41v.39H519s0,0,0,0a0,0,0,0,1,0,0h-.51a0,0,0,0,1,0,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "518.715px 327.115px",
        }}
        id="elro2dsu4mh1j"
        className="animable"
      />
      <path
        d="M519.54,326.84v.81a0,0,0,0,1-.07,0v-.9s0,0,0,0h0l.6.83v-.8a0,0,0,0,1,0,0s0,0,0,0v.91a0,0,0,0,1,0,0h0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "519.77px 327.22px",
        }}
        id="el5uqreh39v7h"
        className="animable"
      />
      <path
        d="M520.69,326.73a0,0,0,0,1,.06,0v.55a.33.33,0,1,0,.66,0v-.55s0,0,0,0a0,0,0,0,1,0,0v.55a.4.4,0,0,1-.4.4.39.39,0,0,1-.39-.4Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "521.015px 327.205px",
        }}
        id="elltkt6zp6hc"
        className="animable"
      />
      <path
        d="M525.87,327.24a0,0,0,0,1,0,0s0,0,0,0h.3a0,0,0,0,1,0,0v.34h0l0,0a.47.47,0,0,1-.31.11.5.5,0,0,1-.51-.5.5.5,0,0,1,.51-.5.52.52,0,0,1,.32.11,0,0,0,0,1,0,0s0,0-.05,0a.43.43,0,0,0-.27-.1.43.43,0,1,0,0,.86.5.5,0,0,0,.26-.08v-.29Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "525.765px 327.19px",
        }}
        id="elti1ppap67c9"
        className="animable"
      />
      <path
        d="M527.29,327.43h-.45l-.08.23,0,0h0a.08.08,0,0,1,0-.05l.34-.91a.05.05,0,0,1,0,0s0,0,0,0l.34.91a0,0,0,0,1,0,.05h0l0,0Zm-.42-.07h.4l-.2-.53Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "527.098px 327.18px",
        }}
        id="eln96xqhdiu2r"
        className="animable"
      />
      <path
        d="M527.9,327.68h0a0,0,0,0,1,0,0v-.92a0,0,0,0,1,0,0s0,0,0,0v.88h.47s0,0,0,0a0,0,0,0,1,0,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "528.135px 327.22px",
        }}
        id="elpf56fwupkjr"
        className="animable"
      />
      <path
        d="M528.9,327.68h0a0,0,0,0,1,0,0v-.92s0,0,0,0a0,0,0,0,1,0,0v.88h.47a0,0,0,0,1,0,0,0,0,0,0,1,0,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "529.135px 327.22px",
        }}
        id="elxyo7znwpew9"
        className="animable"
      />
      <path
        d="M529.86,326.73a0,0,0,0,1,0,0h.51a0,0,0,0,1,0,0s0,0,0,0h-.47v.38h.41s0,0,0,0a0,0,0,0,1,0,0h-.41v.39h.47s0,0,0,0a0,0,0,0,1,0,0h-.51a0,0,0,0,1,0,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "530.115px 327.115px",
        }}
        id="elz588yvir53i"
        className="animable"
      />
      <path
        d="M531.41,327.46a.16.16,0,0,0-.08-.15.25.25,0,0,0-.11-.05H531v.39a0,0,0,0,1,0,0,0,0,0,0,1,0,0v-.92a0,0,0,0,1,0,0h.28a.28.28,0,0,1,.29.28.27.27,0,0,1-.17.25h0a.26.26,0,0,1,.13.22.28.28,0,0,0,0,.11l0,0s0,0,0,0,0,0,0,0h0a.06.06,0,0,1-.06,0A.3.3,0,0,1,531.41,327.46Zm-.2-.69H531v.42h.25a.22.22,0,0,0,.21-.21A.21.21,0,0,0,531.21,326.77Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "531.285px 327.19px",
        }}
        id="elwpmpjvm307"
        className="animable"
      />
      <path
        d="M532.24,327.21l-.33-.45s0,0,0-.05h0l.31.43.31-.43h0a0,0,0,0,1,0,.05l-.33.45v.44a0,0,0,0,1,0,0,0,0,0,0,1,0,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "532.22px 327.18px",
        }}
        id="elg7xjtrbif69"
        className="animable"
      />
      <path
        d="M537.83,327.43h-.45l-.09.23,0,0h0a0,0,0,0,1,0-.05l.34-.91s0,0,0,0a.05.05,0,0,1,0,0l.34.91a.08.08,0,0,1,0,.05h0a.05.05,0,0,1,0,0Zm-.43-.07h.4l-.2-.53Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "537.632px 327.18px",
        }}
        id="el0gvbs1dzs1lq"
        className="animable"
      />
      <path
        d="M538.4,326.73a0,0,0,0,1,0,0h.22a.25.25,0,0,1,.25.24.23.23,0,0,1-.1.19.3.3,0,0,1,.2.27.28.28,0,0,1-.29.28h-.28a0,0,0,0,1,0,0Zm.25.39a.18.18,0,0,0,.18-.18.18.18,0,0,0-.18-.17h-.18v.35Zm.06.49a.21.21,0,1,0,0-.42h-.24v.42Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "538.685px 327.22px",
        }}
        id="el0swrr19pchnh"
        className="animable"
      />
      <path
        d="M539.87,326.68a.49.49,0,0,1,.47.5.48.48,0,0,1-.47.5.48.48,0,0,1-.47-.5A.48.48,0,0,1,539.87,326.68Zm0,.93a.43.43,0,0,0,0-.86.43.43,0,0,0,0,.86Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "539.87px 327.18px",
        }}
        id="elqial9it7g8m"
        className="animable"
      />
      <path
        d="M540.77,326.73s0,0,0,0a0,0,0,0,1,0,0v.55a.33.33,0,0,0,.66,0v-.55a0,0,0,0,1,0,0s0,0,0,0v.55a.4.4,0,0,1-.8,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "541.03px 327.205px",
        }}
        id="elhf4ugao91pl"
        className="animable"
      />
      <path
        d="M542.33,326.77H542a0,0,0,0,1,0,0,0,0,0,0,1,0,0h.66s0,0,0,0a0,0,0,0,1,0,0h-.29v.88s0,0,0,0a0,0,0,0,1,0,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "542.33px 327.21px",
        }}
        id="el7zsooi0ox07"
        className="animable"
      />
      <path
        d="M543.82,326.73a0,0,0,0,1,0,0s0,0,0,0v.55a.33.33,0,1,0,.66,0v-.55a0,0,0,0,1,0,0s0,0,0,0v.55a.4.4,0,0,1-.8,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "544.08px 327.205px",
        }}
        id="elsj7umdghlck"
        className="animable"
      />
      <path
        d="M545.12,327.5a0,0,0,0,1,0,0,.58.58,0,0,0,.33.13.35.35,0,0,0,.21-.07.16.16,0,0,0,0-.28.87.87,0,0,0-.23-.08h0a.52.52,0,0,1-.23-.08.22.22,0,0,1-.11-.17.23.23,0,0,1,.11-.18.36.36,0,0,1,.23-.08.58.58,0,0,1,.31.12s0,0,0,0,0,0,0,0a.46.46,0,0,0-.27-.1.39.39,0,0,0-.2.05.17.17,0,0,0-.07.14.13.13,0,0,0,.07.12.52.52,0,0,0,.21.07h0a.73.73,0,0,1,.25.09.23.23,0,0,1,.11.19.21.21,0,0,1-.11.19.44.44,0,0,1-.25.08.62.62,0,0,1-.36-.14A0,0,0,0,1,545.12,327.5Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "545.465px 327.165px",
        }}
        id="eluai28vhyg2d"
        className="animable"
      />
      <path
        d="M550.79,326.73a0,0,0,0,1,0,0h.51a0,0,0,0,1,0,0s0,0,0,0h-.47v.38h.41s0,0,0,0a0,0,0,0,1,0,0h-.41v.43a0,0,0,0,1,0,0,0,0,0,0,1,0,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "551.045px 327.135px",
        }}
        id="elt564d6ozged"
        className="animable"
      />
      <path
        d="M552.27,327.43h-.45l-.09.23,0,0h0a0,0,0,0,1,0-.05l.34-.91s0,0,0,0l0,0,.34.91s0,0,0,.05h0l0,0Zm-.43-.07h.4l-.2-.53Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "552.07px 327.18px",
        }}
        id="elyyiol6rbfa"
        className="animable"
      />
      <path
        d="M553.82,327.8a.35.35,0,0,1-.22.08h0a.46.46,0,0,1-.25-.08l-.12,0a.27.27,0,0,0-.13,0h0s0,0,0,0,0,0,0,0l.13-.06a.49.49,0,1,1,.24,0l-.14.07a.11.11,0,0,1,.08,0,.51.51,0,0,0,.22.07.27.27,0,0,0,.17-.07s0,0,.05,0A0,0,0,0,1,553.82,327.8Zm-.56-.19a.43.43,0,1,0-.4-.43A.41.41,0,0,0,553.26,327.61Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "553.355px 327.316px",
        }}
        id="el9uqo0uf4wcq"
        className="animable"
      />
      <path
        d="M559,327.5s0,0,.05,0a.52.52,0,0,0,.32.13.38.38,0,0,0,.22-.07.19.19,0,0,0,.08-.14.17.17,0,0,0-.08-.14.76.76,0,0,0-.23-.08h0a.52.52,0,0,1-.23-.08.21.21,0,0,1-.1-.17.24.24,0,0,1,.1-.19.45.45,0,0,1,.24-.07.55.55,0,0,1,.3.12,0,0,0,0,1,0,0s0,0,0,0a.49.49,0,0,0-.27-.1.39.39,0,0,0-.2.05.19.19,0,0,0-.08.14.14.14,0,0,0,.07.12.58.58,0,0,0,.21.07h0a.92.92,0,0,1,.25.09.23.23,0,0,1,.1.19.21.21,0,0,1-.11.19.39.39,0,0,1-.25.08.62.62,0,0,1-.36-.14S559,327.51,559,327.5Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "559.375px 327.165px",
        }}
        id="el0fsc0vqzyyer"
        className="animable"
      />
      <path
        d="M560.8,327.22h-.5v.43a0,0,0,0,1-.07,0v-.92a0,0,0,0,1,0,0s0,0,0,0v.42h.5v-.42s0,0,0,0a0,0,0,0,1,0,0v.92a0,0,0,0,1,0,0,0,0,0,0,1,0,0Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "560.515px 327.19px",
        }}
        id="el6sl1essc2q3"
        className="animable"
      />
      <path
        d="M561.82,326.68a.49.49,0,0,1,.47.5.48.48,0,0,1-.47.5.49.49,0,0,1-.48-.5A.5.5,0,0,1,561.82,326.68Zm0,.93a.43.43,0,0,0,0-.86.43.43,0,0,0,0,.86Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "561.815px 327.18px",
        }}
        id="elzzw1mvo0r38"
        className="animable"
      />
      <path
        d="M562.79,326.73a0,0,0,0,1,0,0h.28a.28.28,0,0,1,.28.28.29.29,0,0,1-.28.28h-.25v.39a0,0,0,0,1-.06,0Zm.31.46a.22.22,0,0,0,.21-.21.21.21,0,0,0-.21-.21h-.25v.42Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "563.055px 327.205px",
        }}
        id="elwg0xd9k7wo"
        className="animable"
      />
      <rect
        x={569.58}
        y={318.46}
        width={3.38}
        height={0.54}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "571.27px 318.73px",
        }}
        id="elugwk4lotia"
        className="animable"
      />
      <rect
        x={569.58}
        y={320.9}
        width={3.38}
        height={0.54}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "571.27px 321.17px",
        }}
        id="eli7ye6f01vf"
        className="animable"
      />
      <rect
        x={569.58}
        y={319.69}
        width={3.38}
        height={0.54}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "571.27px 319.96px",
        }}
        id="el1lsvr777gzm"
        className="animable"
      />
      <rect
        x={511.08}
        y={401.7}
        width={65.69}
        height={12.69}
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "543.925px 408.045px",
        }}
        id="els8xixfxbvna"
        className="animable"
      />
      <rect
        x={517.83}
        y={417.14}
        width={52.19}
        height={18.36}
        style={{
          fill: "rgb(176, 190, 197)",
          transformOrigin: "543.925px 426.32px",
        }}
        id="elzx9c1hrhmcf"
        className="animable"
      />
      <g id="elwlobcv77r6b">
        <ellipse
          cx={543.92}
          cy={433.37}
          rx={12.42}
          ry={1.18}
          style={{
            opacity: 0.1,
            transformOrigin: "543.92px 433.37px",
          }}
          className="animable"
        />
      </g>
      <rect
        x={538.91}
        y={432.46}
        width={6.56}
        height={1.01}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "542.19px 432.965px",
        }}
        id="elq6aqieb5n5"
        className="animable"
      />
      <rect
        x={538.91}
        y={432.46}
        width={6.56}
        height={0.67}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "542.19px 432.795px",
        }}
        id="elywlgj52cnvl"
        className="animable"
      />
      <path
        d="M553.26,429.75a3.74,3.74,0,0,1-3.69,1.55,4.46,4.46,0,0,1-.67-.18,4.92,4.92,0,0,1-.93-.49l.84-1.21a3,3,0,0,0,.49.27,2.18,2.18,0,0,0,2.74-.79c.9-1.25,1.23-4.09.31-5.19-.06-.08-.32-.21-1.38,0l-.66.16a2.85,2.85,0,0,1-.6.13l0-.73-.07-.73.31-.08a9.68,9.68,0,0,1,1.49-.31,2.32,2.32,0,0,1,2.1.62C555,424.57,554.39,428.18,553.26,429.75Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "551.185px 426.727px",
        }}
        id="eljcxzwhpb9ep"
        className="animable"
      />
      <path
        d="M548.81,429.42a3,3,0,0,0,.49.27l-.4,1.43a4.92,4.92,0,0,1-.93-.49Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "548.635px 430.27px",
        }}
        id="elo7kj67cjcp"
        className="animable"
      />
      <path
        d="M551.41,422.16l-.44,1.56-.66.16a2.85,2.85,0,0,1-.6.13l0-.73-.07-.73.31-.08A9.68,9.68,0,0,1,551.41,422.16Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "550.525px 423.085px",
        }}
        id="elo3jr15kz95h"
        className="animable"
      />
      <path
        d="M533.46,420.26s.79,6.59,2.15,9.36,3.25,3.09,4.43,3.09h4.29c1.18,0,3.08-.31,4.44-3.09s2.15-9.36,2.15-9.36Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "542.19px 426.485px",
        }}
        id="elkre7syt9s9"
        className="animable"
      />
    </g>
    <defs>
      <filter id="active" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter id="hover" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#ff0000" floodOpacity={0.5} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
        <feColorMatrix
          type="matrix"
          values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
        />
      </filter>
    </defs>
  </svg>
);
export default SvgComponent;

import * as React from "react";
import "./MainServicesSvg.css";
const SvgComponent = () => (
  <svg
    className="animated"
    id="freepik_stories-security-on"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 750 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlnssvgjs="http://svgjs.com/svgjs"
  >
    <g
      id="freepik--background-complete--inject-8"
      className="animable animator-active"
      style={{
        transformOrigin: "375.6px 206.155px",
      }}
    >
      <path
        d="M244.2,148.3H117.56V61.67H244.2Zm-125.64-1H243.2V62.67H118.56Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "180.88px 104.985px",
        }}
        id="el6y0y63xv98a"
        className="animable"
      />
      <path
        d="M127.46,67.25a2.52,2.52,0,1,1-2.52-2.52A2.52,2.52,0,0,1,127.46,67.25Z"
        style={{
          fill: "rgb(166, 166, 166)",
          transformOrigin: "124.94px 67.25px",
        }}
        id="elbsuwayib1zg"
        className="animable"
      />
      <path
        d="M137.75,67.25a2.52,2.52,0,1,1-2.52-2.52A2.52,2.52,0,0,1,137.75,67.25Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "135.23px 67.25px",
        }}
        id="elh3cop647kse"
        className="animable"
      />
      <path
        d="M148.05,67.25a2.53,2.53,0,1,1-2.52-2.52A2.52,2.52,0,0,1,148.05,67.25Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "145.52px 67.26px",
        }}
        id="elevw3dpfljdr"
        className="animable"
      />
      <rect
        x={118.06}
        y={71.68}
        width={125.64}
        height={1}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "180.88px 72.18px",
        }}
        id="elrkejsheolf"
        className="animable"
      />
      <polygon
        points="658.51 350.64 657.51 350.64 657.51 224.81 684.24 188.33 684.24 120.16 685.24 120.16 685.24 188.66 658.51 225.14 658.51 350.64"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "671.375px 235.4px",
        }}
        id="eluvb54i5zs9c"
        className="animable"
      />
      <path
        d="M684.74,120.7a2.88,2.88,0,1,1,2.88-2.88A2.88,2.88,0,0,1,684.74,120.7Zm0-4.75a1.88,1.88,0,1,0,1.88,1.87A1.87,1.87,0,0,0,684.74,116Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "684.74px 117.82px",
        }}
        id="elcmxw97qa7hj"
        className="animable"
      />
      <polygon
        points="665.4 350.64 664.4 350.64 664.4 229.22 691.13 192.74 691.13 145.15 692.13 145.15 692.13 193.06 665.4 229.55 665.4 350.64"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "678.265px 247.895px",
        }}
        id="elvy93msr34wi"
        className="animable"
      />
      <path
        d="M691.63,145.7a2.88,2.88,0,1,1,2.87-2.88A2.88,2.88,0,0,1,691.63,145.7Zm0-4.76a1.88,1.88,0,1,0,1.87,1.88A1.88,1.88,0,0,0,691.63,140.94Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "691.62px 142.82px",
        }}
        id="elda2r72s984v"
        className="animable"
      />
      <rect
        x={650.88}
        y={184.23}
        width={1}
        height={166.41}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "651.38px 267.435px",
        }}
        id="eltudsje403bq"
        className="animable"
      />
      <path
        d="M651.38,184.73a2.88,2.88,0,1,1,2.87-2.88A2.88,2.88,0,0,1,651.38,184.73Zm0-4.75a1.88,1.88,0,1,0,1.87,1.87A1.88,1.88,0,0,0,651.38,180Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "651.37px 181.85px",
        }}
        id="el6bl3lknac0n"
        className="animable"
      />
      <polygon
        points="645.49 350.64 644.49 350.64 644.49 266.19 626.35 231.96 626.35 170.47 627.35 170.47 627.35 231.71 645.49 265.94 645.49 350.64"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "635.92px 260.555px",
        }}
        id="eljs18w4anjz"
        className="animable"
      />
      <path
        d="M626.84,171a2.88,2.88,0,1,1,2.88-2.88A2.88,2.88,0,0,1,626.84,171Zm0-4.75a1.88,1.88,0,1,0,1.88,1.87A1.87,1.87,0,0,0,626.84,166.22Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "626.84px 168.12px",
        }}
        id="el61vll4qpnsb"
        className="animable"
      />
      <polygon
        points="639.14 350.64 638.14 350.64 638.14 269.85 616.75 232.01 616.75 191.46 617.75 191.46 617.75 231.75 639.14 269.59 639.14 350.64"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "627.945px 271.05px",
        }}
        id="elzyovr7bm9c"
        className="animable"
      />
      <path
        d="M617.25,192a2.88,2.88,0,1,1,2.87-2.87A2.88,2.88,0,0,1,617.25,192Zm0-4.75a1.88,1.88,0,1,0,1.87,1.88A1.88,1.88,0,0,0,617.25,187.21Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "617.24px 189.12px",
        }}
        id="el9yx0r4zdup9"
        className="animable"
      />
      <polygon
        points="96.1 350.64 95.1 350.64 95.1 285.14 68.37 248.66 68.37 210.16 69.37 210.16 69.37 248.33 96.1 284.81 96.1 350.64"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "82.235px 280.4px",
        }}
        id="el4r1qy1oopc"
        className="animable"
      />
      <path
        d="M68.87,210.7a2.88,2.88,0,1,1,2.87-2.88A2.88,2.88,0,0,1,68.87,210.7Zm0-4.75a1.88,1.88,0,1,0,1.87,1.87A1.88,1.88,0,0,0,68.87,206Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "68.86px 207.82px",
        }}
        id="eloxxc3aj8eo"
        className="animable"
      />
      <polygon
        points="89.21 350.64 88.21 350.64 88.21 289.55 61.48 253.06 61.48 235.15 62.48 235.15 62.48 252.74 89.21 289.22 89.21 350.64"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "75.345px 292.895px",
        }}
        id="el0oiuyo84zi3o"
        className="animable"
      />
      <path
        d="M62,235.7a2.88,2.88,0,1,1,2.88-2.88A2.88,2.88,0,0,1,62,235.7Zm0-4.76a1.88,1.88,0,1,0,1.88,1.88A1.88,1.88,0,0,0,62,230.94Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "62px 232.82px",
        }}
        id="elbc06yzq10w5"
        className="animable"
      />
      <rect
        x={101.73}
        y={244.23}
        width={1}
        height={106.41}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "102.23px 297.435px",
        }}
        id="elmmmkydmk50h"
        className="animable"
      />
      <path
        d="M102.23,244.73a2.88,2.88,0,1,1,2.88-2.88A2.88,2.88,0,0,1,102.23,244.73Zm0-4.75a1.88,1.88,0,1,0,1.88,1.87A1.87,1.87,0,0,0,102.23,240Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "102.23px 241.85px",
        }}
        id="elw00vynpm7k"
        className="animable"
      />
      <polygon
        points="109.12 350.64 108.12 350.64 108.12 295.94 108.17 295.83 126.26 261.71 126.26 200.47 127.26 200.47 127.26 261.96 127.21 262.07 109.12 296.19 109.12 350.64"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "117.69px 275.555px",
        }}
        id="elu1yq98adv8"
        className="animable"
      />
      <path
        d="M126.76,201a2.88,2.88,0,1,1,2.88-2.88A2.88,2.88,0,0,1,126.76,201Zm0-4.75a1.88,1.88,0,1,0,1.88,1.87A1.87,1.87,0,0,0,126.76,196.22Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "126.76px 198.12px",
        }}
        id="ely0b1k5wj1so"
        className="animable"
      />
      <polygon
        points="115.47 350.64 114.47 350.64 114.47 299.59 114.53 299.47 135.86 261.75 135.86 221.46 136.86 221.46 136.86 262.01 136.79 262.12 115.47 299.85 115.47 350.64"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "125.665px 286.05px",
        }}
        id="elshepnlonqz"
        className="animable"
      />
      <path
        d="M136.36,222a2.88,2.88,0,1,1,2.87-2.87A2.88,2.88,0,0,1,136.36,222Zm0-4.75a1.88,1.88,0,1,0,1.87,1.88A1.88,1.88,0,0,0,136.36,217.21Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "136.35px 219.12px",
        }}
        id="el8w1bt6dc0y3"
        className="animable"
      />
      <path
        d="M686,127.75l7-11.25-3.44-2.13a3.13,3.13,0,0,1-1.46-3q.08-1,.06-2a3.13,3.13,0,0,1,1.64-2.85l3.57-1.92L687,93l-3.57,1.92a3.15,3.15,0,0,1-3.29-.22c-.54-.37-1.1-.72-1.68-1a3.13,3.13,0,0,1-1.66-2.85l.13-4.06-13.23-.41-.13,4.07a3.14,3.14,0,0,1-1.84,2.74c-.3.14-.59.28-.87.44s-.57.31-.85.48a3.14,3.14,0,0,1-3.29,0l-3.47-2.15-7,11.25,3.45,2.15a3.12,3.12,0,0,1,1.46,3,18.52,18.52,0,0,0-.06,2,3.16,3.16,0,0,1-1.65,2.86L645.94,115l6.26,11.67,3.57-1.91a3.15,3.15,0,0,1,3.29.21,18.07,18.07,0,0,0,1.68,1,3.13,3.13,0,0,1,1.66,2.85l-.12,4.06,13.23.41.13-4.06a3.12,3.12,0,0,1,1.84-2.73l.87-.44.85-.49a3.16,3.16,0,0,1,3.3,0Zm-11.2-8.35a10.85,10.85,0,1,1,4.44-14.69A10.85,10.85,0,0,1,674.75,119.4Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "669.655px 109.835px",
        }}
        id="el9vfvwcyo67r"
        className="animable"
      />
      <path
        d="M77.18,227.76l8-4.64-1.42-2.46a2.19,2.19,0,0,1,.08-2.31q.38-.58.69-1.2a2.21,2.21,0,0,1,2-1.22h2.84v-9.28H86.53a2.21,2.21,0,0,1-2-1.22q-.31-.62-.69-1.2a2.19,2.19,0,0,1-.08-2.31l1.42-2.46-8-4.64-1.42,2.47a2.17,2.17,0,0,1-2,1.08H72.35a2.19,2.19,0,0,1-2-1.07l-1.43-2.48-8,4.64,1.42,2.47a2.19,2.19,0,0,1-.08,2.31,12.71,12.71,0,0,0-.69,1.19,2.21,2.21,0,0,1-2,1.22H56.7v9.28h2.84a2.21,2.21,0,0,1,2,1.22,12.71,12.71,0,0,0,.69,1.19,2.21,2.21,0,0,1,.08,2.32l-1.42,2.46,8,4.64,1.43-2.47a2.2,2.2,0,0,1,2-1.08c.22,0,.45,0,.68,0s.46,0,.69,0a2.21,2.21,0,0,1,2,1.08ZM73,218.89a7.6,7.6,0,1,1,7.61-7.6A7.59,7.59,0,0,1,73,218.89Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "73.035px 211.29px",
        }}
        id="el282sy0nweo3"
        className="animable"
      />
      <path
        d="M645.08,135.89l-4.63-8L638,129.28a2.21,2.21,0,0,1-2.32-.08,11.7,11.7,0,0,0-1.2-.69,2.21,2.21,0,0,1-1.22-2v-2.84H624v2.84a2.21,2.21,0,0,1-1.22,2,13,13,0,0,0-1.21.69,2.19,2.19,0,0,1-2.31.08l-2.46-1.42-4.63,8,2.47,1.42a2.23,2.23,0,0,1,1.08,2.05c0,.23,0,.46,0,.68s0,.46,0,.69a2.19,2.19,0,0,1-1.08,2l-2.47,1.43,4.63,8,2.47-1.42a2.19,2.19,0,0,1,2.31.08q.59.38,1.2.69a2.21,2.21,0,0,1,1.22,2v2.84h9.27v-2.84a2.21,2.21,0,0,1,1.22-2q.62-.32,1.2-.69a2.19,2.19,0,0,1,2.31-.08l2.47,1.42,4.63-8-2.47-1.42a2.2,2.2,0,0,1-1.07-2c0-.23,0-.46,0-.69s0-.45,0-.68a2.22,2.22,0,0,1,1.08-2.05ZM636.21,140a7.6,7.6,0,1,1-7.59-7.6A7.59,7.59,0,0,1,636.21,140Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "628.635px 139.995px",
        }}
        id="elrfre5a6pel"
        className="animable"
      />
      <path
        d="M672.37,317.38l-13.12.28-6.66.11c-2.23.05-4.47,0-6.7.06h-.44v-.45l-.06-11.19v-.5h.5l13.24.07c4.42,0,8.83.11,13.24.16h.28v.27c0,1.9-.08,3.79-.13,5.66Zm0,0-.14-5.65c-.05-1.86-.11-3.71-.13-5.54l.27.27c-4.41.06-8.82.16-13.24.17l-13.24.06.5-.5,0,11.19-.45-.44c2.18,0,4.36,0,6.55.05l6.58.11Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "659.02px 311.76px",
        }}
        id="ellsiqf8buaz9"
        className="animable"
      />
      <path
        d="M657.6,331.49l-13.12.28-6.67.11c-2.22.05-4.46,0-6.69.06h-.44v-.45l0-11.19v-.5h.5l13.24.07c4.41,0,8.83.11,13.24.16h.28v.27c0,1.9-.08,3.79-.13,5.66Zm0,0-.14-5.65c-.05-1.87-.11-3.71-.13-5.54l.27.27c-4.41.06-8.83.16-13.24.17l-13.24.06.5-.5,0,11.19-.45-.44c2.18,0,4.36,0,6.55.05l6.57.11Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "644.31px 325.87px",
        }}
        id="elreh62z0wj0p"
        className="animable"
      />
      <path
        d="M687.14,331.49l-13.12.28-6.66.11c-2.23.05-4.47,0-6.7.06h-.44v-.45l-.06-11.19v-.5h.5l13.24.07c4.42,0,8.83.11,13.24.16h.28v.27c0,1.9-.09,3.79-.13,5.66Zm0,0-.14-5.65c0-1.87-.11-3.71-.13-5.54l.27.27c-4.41.06-8.82.16-13.24.17l-13.24.06.5-.5,0,11.19-.45-.44c2.18,0,4.36,0,6.55.05l6.58.11Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "673.79px 325.87px",
        }}
        id="eltzb9rtlat9l"
        className="animable"
      />
      <path
        d="M294.79,194.22l-13.12.28-6.66.12c-2.23,0-4.47,0-6.7,0h-.44v-.45L267.81,183v-.5h.5l13.24.06c4.42,0,8.83.11,13.24.16h.28V183c0,1.9-.09,3.79-.14,5.66Zm0,0-.14-5.65c0-1.86-.11-3.7-.13-5.54l.27.28c-4.41.05-8.82.16-13.24.16l-13.24.06.5-.5-.05,11.19-.45-.44c2.18,0,4.36,0,6.55.05l6.58.12Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "281.44px 188.56px",
        }}
        id="els25hxjj6rr"
        className="animable"
      />
      <path
        d="M280,208.33l-13.12.28-6.67.11c-2.22.05-4.46,0-6.69.06h-.44v-.45L253,197.14v-.5h.5l13.24.07c4.41,0,8.83.11,13.24.16h.28v.27c0,1.9-.08,3.79-.13,5.66Zm0,0-.14-5.65c-.05-1.86-.11-3.7-.13-5.54l.27.28c-4.41.05-8.83.16-13.24.16l-13.24.06.5-.5,0,11.19-.45-.44c2.18,0,4.36,0,6.55.05l6.58.12Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "266.63px 202.71px",
        }}
        id="elj4rwbmaji2"
        className="animable"
      />
      <path
        d="M104.11,316.91,91,317.19l-6.66.12c-2.22,0-4.46,0-6.69.05h-.45v-.45l-.05-11.19v-.5h.5l13.24.06c4.41,0,8.83.11,13.24.16h.27v.27c0,1.9-.08,3.79-.13,5.66Zm0,0-.14-5.65c0-1.86-.12-3.7-.13-5.54l.27.28c-4.41.05-8.83.16-13.24.16l-13.24.06.5-.5-.06,11.19-.44-.44c2.18,0,4.36,0,6.54,0l6.58.12Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "90.775px 311.29px",
        }}
        id="elf8rbrj4hha"
        className="animable"
      />
      <path
        d="M89.34,331l-13.13.28-6.66.11c-2.22,0-4.46,0-6.69.06h-.45V331l0-11.19v-.5h.51l13.24.06c4.41,0,8.82.11,13.24.16h.27v.27c0,1.9-.09,3.79-.13,5.66Zm0,0-.14-5.65c0-1.86-.12-3.7-.14-5.54l.28.28c-4.42.05-8.83.16-13.24.16l-13.24.06.5-.5L63.3,331l-.44-.44c2.18,0,4.35,0,6.54.05l6.58.12Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "76.04px 325.38px",
        }}
        id="elk9okw00fmt"
        className="animable"
      />
      <path
        d="M309.56,208.33l-13.12.28-6.66.11c-2.23.05-4.46,0-6.7.06h-.44v-.45l-.06-11.19v-.5h.5l13.24.07c4.42,0,8.83.11,13.24.16h.28v.27c0,1.9-.09,3.79-.13,5.66Zm0,0-.14-5.65c0-1.86-.11-3.7-.13-5.54l.27.28c-4.41.05-8.82.16-13.24.16l-13.24.06.5-.5-.05,11.19-.45-.44c2.19,0,4.36,0,6.55.05l6.58.12Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "296.21px 202.71px",
        }}
        id="ellhb21kf30t"
        className="animable"
      />
      <path
        d="M613.2,176.48l-13.12.28-6.66.11c-2.23,0-4.47,0-6.7.06h-.44v-.45l-.06-11.19v-.5h.5l13.24.07c4.41,0,8.83.11,13.24.16h.28v.27c0,1.9-.08,3.79-.13,5.66Zm0,0-.14-5.65c0-1.86-.11-3.71-.13-5.54l.27.27c-4.41.06-8.83.16-13.24.17l-13.24.06.5-.5-.05,11.19-.45-.44c2.18,0,4.36,0,6.55.05l6.58.11Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "599.85px 170.86px",
        }}
        id="el92gwv7iyb1d"
        className="animable"
      />
      <path
        d="M670.84,75.54l-13.12.28-6.67.11c-2.22,0-4.46,0-6.69,0h-.44v-.45l0-11.19v-.5h.5l13.24.06c4.41,0,8.83.11,13.24.16h.28v.27c0,1.9-.08,3.78-.13,5.65Zm0,0-.14-5.66c-.05-1.86-.11-3.7-.13-5.53l.27.27c-4.41,0-8.83.16-13.24.16l-13.24.07.5-.5-.05,11.19-.45-.45c2.18,0,4.36,0,6.55.06l6.57.11Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "657.55px 69.86px",
        }}
        id="el42bv1pftul9"
        className="animable"
      />
      <path
        d="M685.61,89.65l-13.12.28-6.66.11c-2.23,0-4.47,0-6.7,0h-.44v-.45l-.06-11.19V78h.5l13.24.06c4.42,0,8.83.11,13.24.16h.28v.27c0,1.9-.09,3.78-.14,5.65Zm0,0L685.47,84c0-1.86-.11-3.7-.13-5.53l.27.27c-4.41,0-8.82.16-13.24.16L659.13,79l.5-.5,0,11.19-.45-.45c2.18,0,4.36,0,6.55.06l6.58.11Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "672.26px 84.02px",
        }}
        id="el2nt8b8u61ju"
        className="animable"
      />
      <path
        d="M89.34,75.55l-13.13.28-6.66.11c-2.22,0-4.46,0-6.69,0h-.45v-.45l0-11.19v-.5h.51l13.24.06c4.41,0,8.82.11,13.24.17h.27v.27c0,1.9-.09,3.79-.13,5.65Zm0,0L89.2,69.9c0-1.87-.12-3.71-.14-5.54l.28.27c-4.42.05-8.83.16-13.24.17l-13.24.06.5-.5L63.3,75.55l-.44-.45c2.18,0,4.35,0,6.54.06l6.58.11Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "76.04px 69.87px",
        }}
        id="elovedkvdlw0l"
        className="animable"
      />
    </g>
    <g
      id="freepik--Plant--inject-8"
      className="animable"
      style={{
        transformOrigin: "647.488px 282.728px",
      }}
    >
      <path
        d="M681.42,250.39c-13.7,18.56-28,40.09-31.47,63.36a.31.31,0,0,0,.61.12,139.2,139.2,0,0,1,6.05-18.63c9-2.48,17.85-4.52,22.58-13.46,4.83-9.19,4.22-21.17,3.3-31.18C682.42,250.06,681.7,250,681.42,250.39Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "666.534px 282.13px",
        }}
        id="elft8ju22205"
        className="animable"
      />
      <path
        d="M656.75,295.06c6.16-13.81,14.06-27.68,24.1-39,.07-.07.17,0,.11.11-6.57,8.09-11.57,16.9-16.45,25.87,4.54-4.61,9.51-8.73,13.8-13.62.09-.09.22,0,.14.14-4.26,5.12-8.93,10.68-14.48,14.47q-2.25,4.13-4.52,8.27c.37-.21.76-.39,1.14-.58a.06.06,0,1,1,.06.1c-.43.21-.84.44-1.28.62-.72,1.31-1.44,2.62-2.18,3.92C657,295.61,656.62,295.35,656.75,295.06Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "668.851px 275.746px",
        }}
        id="eljhmxuzxffab"
        className="animable"
      />
      <path
        d="M661.33,290.24a80.22,80.22,0,0,0,10.52-7c.09-.07.22,0,.13.13a55.44,55.44,0,0,1-10.5,7.15A.15.15,0,0,1,661.33,290.24Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "666.629px 286.875px",
        }}
        id="elidfdbjf50x"
        className="animable"
      />
      <path
        d="M648.37,310.29c0,.28.5.28.5,0,.07-7.66,0-15.36.12-23.06,2-4,6.18-6.16,9.11-9.33a40.77,40.77,0,0,0,5.46-7.61,54.43,54.43,0,0,0,6.34-21.14c.81-8.2-2.3-35.25-5.75-34.31-1.8.49-7.6,13.89-9.13,18.32a128.08,128.08,0,0,0-5.46,22.45C646.76,273.63,647,292.14,648.37,310.29Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "658.705px 262.658px",
        }}
        id="elifh1iyjfwze"
        className="animable"
      />
      <path
        d="M648.31,277.23l0,0c.42-9.69,2.6-19.48,4.65-28.89,2.2-10.13,4.77-19.67,10-28.71.09-.16.34,0,.26.15a106.65,106.65,0,0,0-8,22c.35-.55.77-1.06,1.14-1.58.14-.2.47,0,.33.2a14.28,14.28,0,0,1-1.63,2.3l-.06,0c-1.8,7.24-3.09,14.65-4.19,22a3.47,3.47,0,0,1,.25-.29c.05-.06.15,0,.11.09l-.44.73c-.56,3.75-1.07,7.49-1.59,11.2,6.68-5.7,12.38-12.43,15.6-20.72,0,0,.08,0,.06,0-3.15,8.59-8.12,16.24-15.77,21.45q-.19,1.5-.42,3c0,.21-.36.15-.35-.05,0-.88,0-1.77.08-2.65A.21.21,0,0,1,648.31,277.23Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "656.532px 249.927px",
        }}
        id="elhauebpfambq"
        className="animable"
      />
      <path
        d="M651.46,263.18c5.1-7.61,9.87-15,13.6-23.43,0-.09.2,0,.17.07-3.15,8.37-7.56,16.84-13.52,23.56C651.59,263.52,651.35,263.34,651.46,263.18Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "658.332px 251.571px",
        }}
        id="elmejun18vvaa"
        className="animable"
      />
      <path
        d="M658.24,237.87c2.11-3.74,4.34-7.38,6.33-11.19.06-.11.2,0,.16.09a58.53,58.53,0,0,1-6.16,11.29A.19.19,0,0,1,658.24,237.87Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "661.476px 232.395px",
        }}
        id="elqghsygv2lq"
        className="animable"
      />
      <path
        d="M657.41,239.5c.05,0,.05.06,0,.06A0,0,0,1,1,657.41,239.5Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "657.429px 239.53px",
        }}
        id="el1riagvweqgh"
        className="animable"
      />
      <path
        d="M643.18,288.13a78.06,78.06,0,0,1-1.79,15.1q.12.81.24,1.65a72.35,72.35,0,0,0,2.7-16.83.53.53,0,0,0,0-.52v0a111.91,111.91,0,0,0-1.69-22.8c-2.37-14.08-7.56-27.6-17.38-38.15a.7.7,0,0,0-1.09.22c-4,10.08-2.58,22.1.8,32.16a59,59,0,0,0,7.79,15.27C636.15,279,640.44,283,643.18,288.13Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "633.07px 265.643px",
        }}
        id="elm9jfvc6qkj"
        className="animable"
      />
      <path
        d="M626.44,231.27c9.31,15.11,15.79,33,16.58,50.87,0,.31-.52.4-.57.08-.22-1.61-.46-3.2-.72-4.78h0c-3.86-3.68-7.81-7.58-10.57-12.19a.09.09,0,0,1,.14-.1c3.35,3.83,6.47,7.75,10.26,11.17-.67-3.95-1.46-7.83-2.4-11.66l-.73-.86c-.16-.19.11-.47.27-.28l.23.25a134.4,134.4,0,0,0-4.87-15.45c-2.37-2.83-5-5.27-6.74-8.68,0-.08.07-.14.12-.07,1.39,1.95,2.73,3.91,4.3,5.71.63.72,1.32,1.4,2,2.1a119,119,0,0,0-7.62-15.89A.21.21,0,0,1,626.44,231.27Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "634.568px 256.828px",
        }}
        id="elz0juo03o7ze"
        className="animable"
      />
      <path
        d="M628.32,249.91c3,4.05,6.44,7.91,9.24,12.1.08.12-.1.24-.2.15-3.59-3.37-6.63-8-9.24-12.13C628,249.9,628.23,249.79,628.32,249.91Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "632.833px 256.025px",
        }}
        id="ely09j7ss3cc"
        className="animable"
      />
      <path
        d="M638,263l.13.12c.08.09-.05.23-.14.14l-.13-.13C637.75,263,637.89,262.86,638,263Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "637.987px 263.115px",
        }}
        id="elzqdg44gvt2o"
        className="animable"
      />
      <path
        d="M611.86,260.32c.74,7.77,4.83,17.05,9.82,23a24.49,24.49,0,0,0,8,6.22c3.45,1.7,7.07,3.67,10.63,5.07A69.79,69.79,0,0,1,644,308l.34-1.18c-2.29-19.68-15-36.5-31.31-47.3A.79.79,0,0,0,611.86,260.32Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "628.096px 283.709px",
        }}
        id="el30tjhj5w5od"
        className="animable"
      />
      <path
        d="M617.48,266.13c-.11-.1,0-.31.17-.21a66.54,66.54,0,0,1,20.75,25.57c.12.27-.25.51-.4.24q-1.14-2-2.32-3.88h0c-3.71-.7-7.24-3.32-10-5.8-.08-.07,0-.18.11-.11a22.61,22.61,0,0,0,4.7,3.3,50.32,50.32,0,0,0,4.72,1.87c-1.37-2.18-2.81-4.28-4.31-6.32a43.25,43.25,0,0,1-11.17-6.28c-.11-.07,0-.25.1-.18a109.29,109.29,0,0,0,10.39,5.55,95.26,95.26,0,0,0-7.33-8.54c-1.27-.67-2.55-1.34-3.77-2.06a.1.1,0,0,1,.08-.19,13.62,13.62,0,0,1,2.89,1.43C620.62,269,619.1,267.56,617.48,266.13Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "627.93px 278.871px",
        }}
        id="elxhsjquu9eu"
        className="animable"
      />
      <path
        d="M617.83,272.92a7.17,7.17,0,0,1,.63.45c.06.06,0,.13-.08.1a4.28,4.28,0,0,1-.66-.37C617.61,273,617.72,272.85,617.83,272.92Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "618.079px 273.19px",
        }}
        id="el0c230pytbv9t"
        className="animable"
      />
      <polygon
        points="669.8 350.64 625.17 350.64 628.77 309.99 629.56 301.03 665.41 301.03 666.2 309.99 669.8 350.64"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "647.485px 325.835px",
        }}
        id="el6fbwhscyo3e"
        className="animable"
      />
      <polygon
        points="666.2 309.99 628.77 309.99 629.56 301.03 665.41 301.03 666.2 309.99"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "647.485px 305.51px",
        }}
        id="elrpcg15gf6c"
        className="animable"
      />
      <rect
        x={627.43}
        y={294.76}
        width={40.12}
        height={8.87}
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "647.49px 299.195px",
        }}
        id="els49k3e11nkb"
        className="animable"
      />
    </g>
    <g
      id="freepik--Floor--inject-8"
      className="animable"
      style={{
        transformOrigin: "375px 350.64px",
      }}
    >
      <polygon
        points="50.72 350.64 131.79 350.39 212.86 350.31 375 350.14 537.14 350.31 618.21 350.39 699.28 350.64 618.21 350.88 537.14 350.97 375 351.14 212.86 350.97 131.79 350.88 50.72 350.64"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "375px 350.64px",
        }}
        id="el7mhk9n9nc1g"
        className="animable"
      />
    </g>
    <g
      id="freepik--Shadow--inject-8"
      className="animable"
      style={{
        transformOrigin: "375px 427.5px",
      }}
    >
      <ellipse
        cx={375}
        cy={427.5}
        rx={319.84}
        ry={31.12}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "375px 427.5px",
        }}
        id="el24387z2byy2"
        className="animable"
      />
    </g>
    <g
      id="freepik--Device--inject-8"
      className="animable"
      style={{
        transformOrigin: "374.16px 239.905px",
      }}
    >
      <rect
        x={148.36}
        y={67.17}
        width={451.6}
        height={302.09}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "374.16px 218.215px",
        }}
        id="eldqf9drxwjya"
        className="animable"
      />
      <rect
        x={151.64}
        y={70.85}
        width={445.04}
        height={294.72}
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "374.16px 218.21px",
        }}
        id="elyjjzglg40ah"
        className="animable"
      />
      <rect
        x={89.54}
        y={391.91}
        width={569.24}
        height={20.73}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "374.16px 402.275px",
        }}
        id="elfzzyj7bwhr"
        className="animable"
      />
      <polygon
        points="89.54 391.91 148.36 369.26 599.96 369.26 658.78 391.91 89.54 391.91"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "374.16px 380.585px",
        }}
        id="elw6tq5wert5"
        className="animable"
      />
      <polygon
        points="153.61 383.88 191.57 369.26 556.75 369.26 594.71 383.88 153.61 383.88"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "374.16px 376.57px",
        }}
        id="el6bzm7aajw65"
        className="animable"
      />
      <rect
        x={176.79}
        y={93.25}
        width={394.74}
        height={249.94}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "374.16px 218.22px",
        }}
        id="eln8tca4e7y2"
        className="animable"
      />
      <rect
        x={176.46}
        y={93.25}
        width={395.07}
        height={9.62}
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "373.995px 98.06px",
        }}
        id="elf21khxjkytv"
        className="animable"
      />
      <path
        d="M184.1,98.05a2.39,2.39,0,1,1-2.38-2.38A2.39,2.39,0,0,1,184.1,98.05Z"
        style={{
          fill: "rgb(199, 199, 199)",
          transformOrigin: "181.71px 98.06px",
        }}
        id="el0g8jhtgrsg0m"
        className="animable"
      />
      <path
        d="M193.84,98.05a2.39,2.39,0,1,1-2.38-2.38A2.39,2.39,0,0,1,193.84,98.05Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "191.45px 98.06px",
        }}
        id="el3ik4vt1h1o"
        className="animable"
      />
      <path
        d="M203.58,98.05a2.39,2.39,0,1,1-2.39-2.38A2.39,2.39,0,0,1,203.58,98.05Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "201.19px 98.06px",
        }}
        id="elowi1dheokf"
        className="animable"
      />
      <rect
        x={564.52}
        y={102.86}
        width={7.01}
        height={240.32}
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "568.025px 223.02px",
        }}
        id="elwni1wya1029"
        className="animable"
      />
      <path
        d="M568,186.18h0a1.81,1.81,0,0,1-1.81-1.81V106.08a1.81,1.81,0,0,1,1.81-1.81h0a1.81,1.81,0,0,1,1.81,1.81v78.29A1.81,1.81,0,0,1,568,186.18Z"
        style={{
          fill: "rgb(166, 166, 166)",
          transformOrigin: "568px 145.225px",
        }}
        id="elgi5h4gdepfv"
        className="animable"
      />
      <rect
        x={295.39}
        y={252.11}
        width={159.15}
        height={24.12}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "374.965px 264.17px",
        }}
        id="ellf3gqjn83xk"
        className="animable"
      />
      <path
        d="M454.81,276.5H295.12V251.84H454.81ZM295.66,276H454.27V252.38H295.66Z"
        style={{
          fill: "rgb(219, 219, 219)",
          transformOrigin: "374.965px 264.17px",
        }}
        id="elyk3td46z6m8"
        className="animable"
      />
      <path
        d="M309.44,258.64a13,13,0,0,1,.63,2.77,21.32,21.32,0,0,1,.18,2.76,23.24,23.24,0,0,1-.17,2.76,13.12,13.12,0,0,1-.64,2.76,13.12,13.12,0,0,1-.64-2.76,23.24,23.24,0,0,1-.17-2.76,21.32,21.32,0,0,1,.18-2.76A13,13,0,0,1,309.44,258.64Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "309.44px 264.165px",
        }}
        id="el6xs8sfckkid"
        className="animable"
      />
      <path
        d="M315,264.17a13.09,13.09,0,0,1-2.76.63,23.25,23.25,0,0,1-2.76.18,21.32,21.32,0,0,1-2.76-.18,13.09,13.09,0,0,1-2.76-.63,13.12,13.12,0,0,1,2.76-.64,22.49,22.49,0,0,1,5.52,0A13.12,13.12,0,0,1,315,264.17Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "309.48px 264.17px",
        }}
        id="elouekv8xoib"
        className="animable"
      />
      <path
        d="M313.35,260.26a13.54,13.54,0,0,1-1.51,2.4,21.43,21.43,0,0,1-1.83,2.08,22.55,22.55,0,0,1-2.07,1.83,13,13,0,0,1-2.41,1.5,13,13,0,0,1,1.51-2.4,21.15,21.15,0,0,1,1.83-2.07,19,19,0,0,1,2.08-1.83A12.67,12.67,0,0,1,313.35,260.26Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "309.44px 264.165px",
        }}
        id="el1hw1ojhaaze"
        className="animable"
      />
      <path
        d="M313.35,268.07a12.63,12.63,0,0,1-2.4-1.5,21.43,21.43,0,0,1-2.08-1.83,20.17,20.17,0,0,1-1.83-2.08,13,13,0,0,1-1.51-2.4,13,13,0,0,1,2.41,1.5A21.26,21.26,0,0,1,310,263.6a22.55,22.55,0,0,1,1.83,2.07A13.92,13.92,0,0,1,313.35,268.07Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "309.44px 264.165px",
        }}
        id="eltz9b9ptb5r"
        className="animable"
      />
      <path
        d="M331,258.64a13.06,13.06,0,0,1,.64,2.77,22.49,22.49,0,0,1,0,5.52,12.52,12.52,0,0,1-.64,2.76,13.09,13.09,0,0,1-.63-2.76,19.67,19.67,0,0,1-.17-2.76,21.3,21.3,0,0,1,.17-2.76A13.67,13.67,0,0,1,331,258.64Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "331.005px 264.165px",
        }}
        id="eljn1tdqckbno"
        className="animable"
      />
      <path
        d="M336.5,264.17a13.09,13.09,0,0,1-2.76.63A23.28,23.28,0,0,1,331,265a21.32,21.32,0,0,1-2.76-.18,13.09,13.09,0,0,1-2.76-.63,13.12,13.12,0,0,1,2.76-.64,22.57,22.57,0,0,1,5.53,0A13.12,13.12,0,0,1,336.5,264.17Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "330.99px 264.19px",
        }}
        id="elnibx3n7jtra"
        className="animable"
      />
      <path
        d="M334.88,260.26a13,13,0,0,1-1.5,2.4,21.71,21.71,0,0,1-3.91,3.91,13,13,0,0,1-2.4,1.5,13,13,0,0,1,1.5-2.4,22.55,22.55,0,0,1,1.83-2.07,19,19,0,0,1,2.08-1.83A13,13,0,0,1,334.88,260.26Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "330.975px 264.165px",
        }}
        id="elcn4458708x5"
        className="animable"
      />
      <path
        d="M334.88,268.07a13,13,0,0,1-2.4-1.5,21.71,21.71,0,0,1-3.91-3.91,13,13,0,0,1-1.5-2.4,13,13,0,0,1,2.4,1.5,20.26,20.26,0,0,1,2.08,1.84,22.55,22.55,0,0,1,1.83,2.07A13.29,13.29,0,0,1,334.88,268.07Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "330.975px 264.165px",
        }}
        id="eltcfafx6d3r"
        className="animable"
      />
      <path
        d="M352.51,258.64a13,13,0,0,1,.63,2.77,21.32,21.32,0,0,1,.18,2.76,23.24,23.24,0,0,1-.17,2.76,13.12,13.12,0,0,1-.64,2.76,13.12,13.12,0,0,1-.64-2.76,21.85,21.85,0,0,1,0-5.52A12.45,12.45,0,0,1,352.51,258.64Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "352.507px 264.165px",
        }}
        id="elh0w9vjb5a1s"
        className="animable"
      />
      <path
        d="M358,264.17a13.09,13.09,0,0,1-2.76.63,23.25,23.25,0,0,1-2.76.18,21.32,21.32,0,0,1-2.76-.18,13.09,13.09,0,0,1-2.76-.63,13.12,13.12,0,0,1,2.76-.64,22.49,22.49,0,0,1,5.52,0A13.12,13.12,0,0,1,358,264.17Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "352.48px 264.17px",
        }}
        id="elb6af0ngcr4w"
        className="animable"
      />
      <path
        d="M356.42,260.26a13.54,13.54,0,0,1-1.51,2.4,21.43,21.43,0,0,1-1.83,2.08,22.55,22.55,0,0,1-2.07,1.83,13,13,0,0,1-2.41,1.5,13,13,0,0,1,1.51-2.4,21.15,21.15,0,0,1,1.83-2.07,19.92,19.92,0,0,1,2.07-1.83A13.08,13.08,0,0,1,356.42,260.26Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "352.51px 264.165px",
        }}
        id="elevbergoi34v"
        className="animable"
      />
      <path
        d="M356.42,268.07a13,13,0,0,1-2.41-1.5,22.55,22.55,0,0,1-2.07-1.83,20.17,20.17,0,0,1-1.83-2.08,13,13,0,0,1-1.51-2.4,13,13,0,0,1,2.41,1.5,21.26,21.26,0,0,1,2.07,1.84,22.55,22.55,0,0,1,1.83,2.07A13.92,13.92,0,0,1,356.42,268.07Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "352.51px 264.165px",
        }}
        id="elp044fvhy5vd"
        className="animable"
      />
      <path
        d="M374,258.64a13.06,13.06,0,0,1,.64,2.77,22.49,22.49,0,0,1,0,5.52,12.52,12.52,0,0,1-.64,2.76,13.09,13.09,0,0,1-.63-2.76,19.67,19.67,0,0,1-.17-2.76,21.3,21.3,0,0,1,.17-2.76A13.67,13.67,0,0,1,374,258.64Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "374.005px 264.165px",
        }}
        id="eljrpcdu0dm9g"
        className="animable"
      />
      <path
        d="M379.57,264.17a13.09,13.09,0,0,1-2.76.63A23.42,23.42,0,0,1,374,265a21.32,21.32,0,0,1-2.76-.18,13.09,13.09,0,0,1-2.76-.63,13.12,13.12,0,0,1,2.76-.64,22.57,22.57,0,0,1,5.53,0A13.12,13.12,0,0,1,379.57,264.17Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "374.025px 264.19px",
        }}
        id="elozeksnes5k"
        className="animable"
      />
      <path
        d="M378,260.26a13,13,0,0,1-1.5,2.4,21.71,21.71,0,0,1-3.91,3.91,13,13,0,0,1-2.4,1.5,13,13,0,0,1,1.5-2.4,22.55,22.55,0,0,1,1.83-2.07,19,19,0,0,1,2.08-1.83A13,13,0,0,1,378,260.26Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "374.095px 264.165px",
        }}
        id="el8kqjw3ts73r"
        className="animable"
      />
      <path
        d="M378,268.07a13,13,0,0,1-2.4-1.5,21.71,21.71,0,0,1-3.91-3.91,13,13,0,0,1-1.5-2.4,13,13,0,0,1,2.4,1.5,20.26,20.26,0,0,1,2.08,1.84,22.55,22.55,0,0,1,1.83,2.07A13.29,13.29,0,0,1,378,268.07Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "374.095px 264.165px",
        }}
        id="el9ukh8py9yzi"
        className="animable"
      />
      <path
        d="M395.58,258.64a13,13,0,0,1,.63,2.77,21.25,21.25,0,0,1,0,5.52,12.49,12.49,0,0,1-.63,2.76,13.12,13.12,0,0,1-.64-2.76,21.3,21.3,0,0,1-.17-2.76,23.24,23.24,0,0,1,.17-2.76A13.06,13.06,0,0,1,395.58,258.64Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "395.58px 264.165px",
        }}
        id="elik8m6fya3da"
        className="animable"
      />
      <path
        d="M401.1,264.17a13.09,13.09,0,0,1-2.76.63,23.25,23.25,0,0,1-2.76.18,21.32,21.32,0,0,1-2.76-.18,13.32,13.32,0,0,1-2.77-.63,13.35,13.35,0,0,1,2.77-.64,22.49,22.49,0,0,1,5.52,0A13.12,13.12,0,0,1,401.1,264.17Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "395.575px 264.17px",
        }}
        id="elorcv0ikgvd"
        className="animable"
      />
      <path
        d="M399.48,260.26a13,13,0,0,1-1.5,2.4,21.43,21.43,0,0,1-1.83,2.08,22.55,22.55,0,0,1-2.07,1.83,13,13,0,0,1-2.41,1.5,13,13,0,0,1,1.51-2.4,22.49,22.49,0,0,1,3.9-3.9A13.34,13.34,0,0,1,399.48,260.26Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "395.575px 264.165px",
        }}
        id="elm08bkau6fjm"
        className="animable"
      />
      <path
        d="M399.48,268.07a13.29,13.29,0,0,1-2.4-1.5,22.55,22.55,0,0,1-2.07-1.83,19,19,0,0,1-1.83-2.08,13,13,0,0,1-1.51-2.4,13,13,0,0,1,2.41,1.5,21.26,21.26,0,0,1,2.07,1.84,22.55,22.55,0,0,1,1.83,2.07A13.29,13.29,0,0,1,399.48,268.07Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "395.575px 264.165px",
        }}
        id="elwd2qzeb8s5d"
        className="animable"
      />
      <path
        d="M417.11,258.64a13.06,13.06,0,0,1,.64,2.77,22.49,22.49,0,0,1,0,5.52,13.12,13.12,0,0,1-.64,2.76,13.09,13.09,0,0,1-.63-2.76,21.32,21.32,0,0,1-.18-2.76,23.25,23.25,0,0,1,.18-2.76A13,13,0,0,1,417.11,258.64Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "417.11px 264.165px",
        }}
        id="elm85868mzyr"
        className="animable"
      />
      <path
        d="M422.64,264.17a13.09,13.09,0,0,1-2.76.63,23.56,23.56,0,0,1-2.77.18,21.32,21.32,0,0,1-2.76-.18,13.09,13.09,0,0,1-2.76-.63,13.12,13.12,0,0,1,2.76-.64,21.3,21.3,0,0,1,2.76-.17,21.57,21.57,0,0,1,2.77.17A13.12,13.12,0,0,1,422.64,264.17Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "417.115px 264.17px",
        }}
        id="elcmesuofl5g"
        className="animable"
      />
      <path
        d="M421,260.26a13,13,0,0,1-1.51,2.4,19,19,0,0,1-1.83,2.08,22.55,22.55,0,0,1-2.07,1.83,13,13,0,0,1-2.4,1.5,13,13,0,0,1,1.5-2.4,22.55,22.55,0,0,1,1.83-2.07,19,19,0,0,1,2.08-1.83A13,13,0,0,1,421,260.26Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "417.095px 264.165px",
        }}
        id="elc9ulz7j48n9"
        className="animable"
      />
      <path
        d="M421,268.07a13,13,0,0,1-2.4-1.5,21.71,21.71,0,0,1-3.91-3.91,13,13,0,0,1-1.5-2.4,13,13,0,0,1,2.4,1.5,21.26,21.26,0,0,1,2.07,1.84,19.92,19.92,0,0,1,1.83,2.07A13.34,13.34,0,0,1,421,268.07Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "417.095px 264.165px",
        }}
        id="el085pqk0r173d"
        className="animable"
      />
      <path
        d="M394.94,231.88A38.74,38.74,0,1,1,412.71,190,38.44,38.44,0,0,1,394.94,231.88Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "374.978px 198.691px",
        }}
        id="elp38q2nfu02"
        className="animable"
      />
      <path
        d="M386.8,194.39a11.79,11.79,0,1,1-11.65-11.92A11.79,11.79,0,0,1,386.8,194.39Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "375.011px 194.259px",
        }}
        id="elgauu6avtww"
        className="animable"
      />
      <path
        d="M395,227l-.05,4.87a38.78,38.78,0,0,1-40.75-.5l.06-4.85A20.37,20.37,0,0,1,395,227Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "374.601px 221.905px",
        }}
        id="elzhxd8ak3uy"
        className="animable"
      />
    </g>
    <g
      id="freepik--Shield--inject-8"
      className="animable"
      style={{
        transformOrigin: "375.019px 217.721px",
      }}
    >
      <path
        d="M408.11,217.75a289.09,289.09,0,0,1-5.35,57.44,141.76,141.76,0,0,1-8.36,27.63,55.12,55.12,0,0,1-7.26,12.49,23.7,23.7,0,0,1-5.27,5,11.71,11.71,0,0,1-13.75,0,23.8,23.8,0,0,1-5.3-5,55.4,55.4,0,0,1-7.31-12.48,142,142,0,0,1-8.45-27.63,288,288,0,0,1-5.54-57.48,288.21,288.21,0,0,1,5.54-57.49,142,142,0,0,1,8.45-27.63,55.56,55.56,0,0,1,7.31-12.47,23.78,23.78,0,0,1,5.29-5,11.73,11.73,0,0,1,13.76,0,23.75,23.75,0,0,1,5.27,5,54.84,54.84,0,0,1,7.26,12.48,141.76,141.76,0,0,1,8.36,27.63A288.45,288.45,0,0,1,408.11,217.75Zm0,0a288.75,288.75,0,0,0-5.53-57.41,141.3,141.3,0,0,0-8.44-27.55,55,55,0,0,0-7.25-12.4,23.43,23.43,0,0,0-5.21-4.88,11.35,11.35,0,0,0-13.35,0,23.34,23.34,0,0,0-5.18,4.89,55.24,55.24,0,0,0-7.21,12.4,141.5,141.5,0,0,0-8.33,27.56,288.26,288.26,0,0,0-5.35,57.37,288.33,288.33,0,0,0,5.35,57.37,141.2,141.2,0,0,0,8.33,27.55,54.81,54.81,0,0,0,7.21,12.4,23.62,23.62,0,0,0,5.18,4.9,11.35,11.35,0,0,0,13.35,0,23.48,23.48,0,0,0,5.21-4.89,54.52,54.52,0,0,0,7.25-12.39,141.51,141.51,0,0,0,8.44-27.55A288.75,288.75,0,0,0,408.11,217.75Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "374.818px 217.721px",
        }}
        id="els1dhhl1wogb"
        className="animable"
      />
      <path
        d="M391.56,189.07a288.8,288.8,0,0,1,47.07,33.36,142.08,142.08,0,0,1,19.75,21A55.29,55.29,0,0,1,465.56,256a23.79,23.79,0,0,1,1.67,7.05,12.6,12.6,0,0,1-1.49,7.07,12.74,12.74,0,0,1-5.37,4.86,23.9,23.9,0,0,1-6.94,2.1,55.12,55.12,0,0,1-14.46.09,141.69,141.69,0,0,1-28.16-6.49,289.87,289.87,0,0,1-52.55-23.94,289.18,289.18,0,0,1-47-33.54,142.29,142.29,0,0,1-19.69-21.14,55.23,55.23,0,0,1-7.15-12.57,23.83,23.83,0,0,1-1.66-7.07,11.72,11.72,0,0,1,6.9-11.9,23.56,23.56,0,0,1,6.94-2.08,55,55,0,0,1,14.44,0A141.93,141.93,0,0,1,339.13,165,288.78,288.78,0,0,1,391.56,189.07Zm0,0a288.83,288.83,0,0,0-52.49-23.91A141.9,141.9,0,0,0,311,158.69a54.36,54.36,0,0,0-14.36.09,23.56,23.56,0,0,0-6.83,2.06,11.37,11.37,0,0,0-6.66,11.57,23.52,23.52,0,0,0,1.65,6.94,54.6,54.6,0,0,0,7.14,12.44,141.19,141.19,0,0,0,19.69,21,288.69,288.69,0,0,0,47,33.32A288.33,288.33,0,0,0,411,270.16a140.75,140.75,0,0,0,28,6.55,54.21,54.21,0,0,0,14.34,0,23.53,23.53,0,0,0,6.83-2,11.34,11.34,0,0,0,6.69-11.55,23.31,23.31,0,0,0-1.63-6.95,54.71,54.71,0,0,0-7.1-12.48,141.49,141.49,0,0,0-19.64-21.08A289,289,0,0,0,391.56,189.07Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "374.986px 217.783px",
        }}
        id="elef4p3mo01ko"
        className="animable"
      />
      <path
        d="M358.44,189.07A289.09,289.09,0,0,1,410.87,165,141.93,141.93,0,0,1,439,158.41a55,55,0,0,1,14.44,0,24.17,24.17,0,0,1,6.94,2.08,11.72,11.72,0,0,1,6.9,11.9,23.83,23.83,0,0,1-1.66,7.07,55.23,55.23,0,0,1-7.15,12.57,141.73,141.73,0,0,1-19.69,21.13,289.67,289.67,0,0,1-47,33.55,288.2,288.2,0,0,1-52.55,23.94A141.69,141.69,0,0,1,311,277.18a55.12,55.12,0,0,1-14.46-.09,23.9,23.9,0,0,1-6.94-2.1,12.74,12.74,0,0,1-5.37-4.86,12.6,12.6,0,0,1-1.49-7.07,23.79,23.79,0,0,1,1.67-7.05,55.08,55.08,0,0,1,7.18-12.53,142.08,142.08,0,0,1,19.75-21A288.8,288.8,0,0,1,358.44,189.07Zm0,0a289,289,0,0,0-46.95,33.5,141.49,141.49,0,0,0-19.64,21.08,54.71,54.71,0,0,0-7.1,12.48,23.31,23.31,0,0,0-1.63,6.95,11.34,11.34,0,0,0,6.69,11.55,23.53,23.53,0,0,0,6.83,2,54.21,54.21,0,0,0,14.34,0,140.08,140.08,0,0,0,28-6.56,287.61,287.61,0,0,0,52.36-24,289.25,289.25,0,0,0,47-33.31,140.84,140.84,0,0,0,19.7-21,54.72,54.72,0,0,0,7.13-12.44,23.52,23.52,0,0,0,1.65-6.94,11.37,11.37,0,0,0-6.66-11.57,23.26,23.26,0,0,0-6.83-2.06,54.36,54.36,0,0,0-14.36-.09,141.9,141.9,0,0,0-28.08,6.47A288.15,288.15,0,0,0,358.44,189.07Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "375.019px 217.773px",
        }}
        id="eltlcl547wgdh"
        className="animable"
      />
      <path
        d="M440.07,182s-9.5,68.86-25.13,84.35C396.19,285,382.58,282.23,375,291.81a0,0,0,0,0,0,0c-7.57-9.6-21.18-6.87-39.95-25.47C319.42,250.87,309.93,182,309.93,182,351.09,163.93,375,143.67,375,143.67h0C375.74,144.3,399.54,164.22,440.07,182Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "375px 217.74px",
        }}
        id="el29onbv5a7w9"
        className="animable"
      />
      <g id="elorxig7f9nsr">
        <path
          d="M375,143.68V291.81a0,0,0,0,0,0,0c-7.57-9.6-21.18-6.87-39.95-25.47C319.42,250.87,309.93,182,309.93,182,351.09,163.93,375,143.67,375,143.67Z"
          style={{
            opacity: 0.1,
            transformOrigin: "342.465px 217.74px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M430,187.56s-8,58.16-21.22,71.26c-15.86,15.72-27.36,13.41-33.76,21.51-6.4-8.1-17.9-5.79-33.76-21.51C328,245.72,320,187.56,320,187.56c34.77-15.28,55-32.4,55-32.4S395.2,172.28,430,187.56Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "375px 217.745px",
        }}
        id="el5btu3tmzgzp"
        className="animable"
      />
      <g id="el17at7s64l2s">
        <path
          d="M430,187.56s-8,58.16-21.22,71.26c-15.84,15.71-27.34,13.42-33.74,21.49V155.18C375.62,155.7,395.74,172.52,430,187.56Z"
          style={{
            opacity: 0.1,
            transformOrigin: "402.52px 217.745px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M380.15,213.15l5.09,24.16H363.63l5.09-24.16a10.81,10.81,0,1,1,11.43,0Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "374.435px 215.237px",
        }}
        id="elgqai23qb9fg"
        className="animable"
      />
    </g>
    <g
      id="freepik--Character--inject-8"
      className="animable"
      style={{
        transformOrigin: "264.655px 291.295px",
      }}
    >
      <path
        d="M179.39,370.35s-17.71,35.15-17.73,35.17c-3.42,6.71-6.3,12.64-7.5,15.85a.14.14,0,0,0,0,.06,5.08,5.08,0,0,0-.56,2.32s0,.05,0,.06c1.31,2.37,54.08,31.28,56.26,31.67s3.46-5.79,2.31-8.55-16.42-22.86-16.42-22.86l17.37-35.3Z"
        style={{
          fill: "rgb(211, 118, 106)",
          transformOrigin: "183.36px 412.924px",
        }}
        id="elgpgdyyf203w"
        className="animable"
      />
      <path
        d="M165.3,398.31s-3.62,7.19-3.64,7.21c-3.42,6.71-6.3,12.64-7.5,15.85a.14.14,0,0,0,0,.06,10.08,10.08,0,0,0-.56,2.32s0,.05,0,.06c1.31,2.37,54.08,31.28,56.26,31.67s3.46-5.79,2.31-8.55-16.42-22.86-16.42-22.86l3.79-7.28Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "183.101px 426.904px",
        }}
        id="ela1s52ylddxf"
        className="animable"
      />
      <path
        d="M199,425.77c-3.26-2.42-7.91-4-11.9-2.79-.16,0-.09.27,0,.29a55.81,55.81,0,0,1,11.62,3A.26.26,0,0,0,199,425.77Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "193.075px 424.435px",
        }}
        id="elp92zqfo6zyk"
        className="animable"
      />
      <path
        d="M200.84,428.59c-3.26-2.41-7.92-4-11.9-2.79-.16.05-.09.27,0,.29a56.12,56.12,0,0,1,11.62,3A.26.26,0,0,0,200.84,428.59Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "194.916px 427.256px",
        }}
        id="elzmtabyrts"
        className="animable"
      />
      <path
        d="M202.67,431.42c-3.26-2.42-7.92-4-11.91-2.79-.15,0-.08.26.05.28a56.12,56.12,0,0,1,11.62,2.95A.25.25,0,0,0,202.67,431.42Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "196.737px 430.052px",
        }}
        id="elci06ehbcjtd"
        className="animable"
      />
      <path
        d="M209.85,455.48c-2.18-.39-54.95-29.29-56.26-31.67-.51-.89,3.25-8.83,8.08-18.29l.15-.29,34.25,18.27-.33.57s15.25,20.1,16.42,22.86S212,455.88,209.85,455.48Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "183.069px 430.364px",
        }}
        id="el8bm1kj06d23"
        className="animable"
      />
      <path
        d="M210.38,453.11c-8.78-5.09-44.65-24.8-53.72-29.16-.07,0-.1,0,0,.05,8.59,5.24,44.63,24.65,53.67,29.26C210.49,453.35,210.56,453.22,210.38,453.11Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "183.541px 438.62px",
        }}
        id="elbzyhvr0c34n"
        className="animable"
      />
      <path
        d="M204,412.5c.42-.2,23.44-43.39,32.09-65.35s19.67-68.93,21.83-78.55c0,0,6.9-21.31,8.95-39.46,0,0-46.57,0-46.57,0s-7.66,18.7-5.91,44.8c.11,1.61-13.75,55.27-17.67,62.41-6,10.93-30.73,55.31-31.5,55.71Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "216.045px 320.82px",
        }}
        id="el9qv23lasxjh"
        className="animable"
      />
      <path
        d="M248.5,243.39c-2.45,10.56-4.58,21.2-6.83,31.8s-4.51,21.31-6.77,32c-1.11,5.25-2.19,10.52-3.35,15.76A94.65,94.65,0,0,1,227,338.1c-2,4.85-4.24,9.59-6.47,14.32s-4.52,9.51-6.83,14.24q-7,14.26-14.33,28.32c-.6,1.16-1.21,2.31-1.82,3.46-.05.09.08.17.13.08,5.18-9.22,10.2-18.54,15-28s9.47-18.89,13.75-28.54a89.67,89.67,0,0,0,5.11-14.78c1.27-5.16,2.27-10.4,3.36-15.61l6.69-32.15c2.22-10.67,4.45-21.33,6.62-32,.27-1.32.53-2.63.78-4C249,243.2,248.57,243.08,248.5,243.39Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "223.264px 320.882px",
        }}
        id="elr98svkeyy2g"
        className="animable"
      />
      <path
        d="M171,386.24c1,.39,2.08,1,3.09,1.48s2.26,1.14,3.4,1.69q3.3,1.59,6.57,3.27c4.38,2.23,8.73,4.57,13,7l3.52,2c1.25.72,2.46,1.51,3.74,2.18.1,0,0,.17-.06.12-2.19-1.17-4.51-2.15-6.74-3.24s-4.38-2.17-6.55-3.28c-4.38-2.25-8.73-4.6-13-7.07-1.18-.68-2.37-1.33-3.54-2-.63-.36-1.25-.73-1.87-1.1a18.65,18.65,0,0,1-1.68-1C170.78,386.32,170.86,386.2,171,386.24Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "187.599px 395.11px",
        }}
        id="elvz044v47y3"
        className="animable"
      />
      <path
        d="M247.55,396.71s.33,39.35.33,39.38c0,7.52.18,14.12.58,17.53a.07.07,0,0,0,0,.05A5.32,5.32,0,0,0,249,456l0,0c2.25,1.51,62.4,3.08,64.52,2.42s.42-6.73-1.86-8.65S286.67,437,286.67,437L286,397.65Z"
        style={{
          fill: "rgb(211, 118, 106)",
          transformOrigin: "281.019px 427.642px",
        }}
        id="el0ilu0uibz0lc"
        className="animable"
      />
      <path
        d="M247.81,428s.07,8.05.07,8.08c0,7.52.18,14.12.58,17.53a.07.07,0,0,0,0,.05A10.25,10.25,0,0,0,249,456l0,0c2.25,1.51,62.4,3.08,64.52,2.42s.42-6.73-1.86-8.65S286.67,437,286.67,437l0-8.21Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "281.149px 443.287px",
        }}
        id="eliqdug2tk8q"
        className="animable"
      />
      <path
        d="M290.35,437c-4-.66-8.87.05-11.86,3-.11.11.05.28.18.23a56.25,56.25,0,0,1,11.68-2.69A.25.25,0,0,0,290.35,437Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "284.537px 438.506px",
        }}
        id="ell7hef1sgh8j"
        className="animable"
      />
      <path
        d="M293.27,438.68c-4-.66-8.87.06-11.86,3-.12.11,0,.27.17.23a55.57,55.57,0,0,1,11.69-2.69A.26.26,0,0,0,293.27,438.68Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "287.451px 440.185px",
        }}
        id="el7xqqift4n9"
        className="animable"
      />
      <path
        d="M296.19,440.36c-4-.66-8.87.05-11.86,3-.12.11,0,.28.17.23a56.25,56.25,0,0,1,11.68-2.69A.25.25,0,0,0,296.19,440.36Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "290.368px 441.866px",
        }}
        id="el79evfrcfh98"
        className="animable"
      />
      <path
        d="M313.59,458.47c-2.12.65-62.27-.91-64.52-2.43-.86-.56-1.15-9.34-1.19-20v-.33l38.81.57,0,.66s22.76,10.89,25.06,12.82S315.71,457.82,313.59,458.47Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "281.224px 447.166px",
        }}
        id="el8xod1m7bg64"
        className="animable"
      />
      <path
        d="M313,456.12c-10.13-.52-51.05-1.63-61.11-1.36-.08,0-.08.06,0,.06,10,.73,51,1.51,61.11,1.46C313.18,456.28,313.18,456.13,313,456.12Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "282.483px 455.5px",
        }}
        id="eln61pvsohomi"
        className="animable"
      />
      <path
        d="M240.43,270.8s6.63,65.09,6.74,66.62c0,.61-.89,85.93-.89,85.93l41.17,1.17s.35-79.8-.32-89c-.08-1.07-4.07-68.93-16.84-106.39H221.55s.43,22,10.44,37.92A82.25,82.25,0,0,1,240.43,270.8Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "254.546px 326.825px",
        }}
        id="elok3ejqig7qe"
        className="animable"
      />
      <path
        d="M276.58,344.71c-.19-12-.69-24.06-1.63-36.06s-2.41-23.7-4.35-35.46a291.89,291.89,0,0,0-7.74-35.08c-.43-1.44-.86-2.88-1.3-4.32a.16.16,0,0,0-.3.09c3.1,11.54,5.78,23.17,7.81,34.94s3.37,23.76,4.38,35.71,1.59,24,1.89,36,.33,24,.21,36-.58,16.68-.62,28.68c0,1.51,0,3-.05,4.51,0,.11.16.1.16,0,.22-6,.58-12,.78-18.07s.38-4.75.52-10.79C276.62,368.87,276.77,356.79,276.58,344.71Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "268.962px 321.753px",
        }}
        id="eliwz541hp1an"
        className="animable"
      />
      <path
        d="M246.94,273.79c-2.4-1.31-4.85-2.49-7.36-3.56s-5.09-2.05-7.59-3.17c-.1,0-.18.1-.08.15,2.46,1.27,4.87,2.63,7.36,3.83a58.67,58.67,0,0,0,7.56,3C247,274.11,247.08,273.87,246.94,273.79Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "239.438px 270.556px",
        }}
        id="elj88izk5mh"
        className="animable"
      />
      <path
        d="M243.91,299.74c-.61-6.14-1.27-12.27-2-18.39-.22-1.73-.44-3.46-.67-5.18a39.31,39.31,0,0,0-.82-4.95c0-.09-.15-.06-.15,0a38.91,38.91,0,0,0,.31,4.7c.15,1.48.28,3,.43,4.44.29,3,.56,6.09.87,9.13.62,6.1,1.16,12.2,1.78,18.29.36,3.47.68,6.93,1.18,10.38a.2.2,0,0,0,.4,0C245.1,312,244.51,305.86,243.91,299.74Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "242.755px 294.76px",
        }}
        id="elywwygm8k91"
        className="animable"
      />
      <path
        d="M249.08,415c1.1-.1,2.3,0,3.42,0,1.27,0,2.54.05,3.81.05q3.66,0,7.33.08c4.92.11,9.85.33,14.76.68l4.06.3c1.44.11,2.88.29,4.32.34.11,0,.1.14,0,.13-2.48-.1-5,0-7.47,0s-4.89-.06-7.33-.12c-4.92-.12-9.86-.35-14.76-.72-1.36-.11-2.71-.18-4.06-.27l-2.16-.18c-.65-.05-1.29,0-1.93-.13C249,415.11,249,415,249.08,415Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "267.939px 415.768px",
        }}
        id="elp3e39j94d8o"
        className="animable"
      />
      <path
        d="M251.3,180.32c2.06,7,13,28.11,24.16,31,10,2.57,37-20.34,44.4-31.8,2.61-4-17.64-9-21.83-7.86-3.6,1-19.32,17-20.71,16.49-2.35-.9-7.83-5.73-16.74-13.26C252.2,167.79,249.85,175.39,251.3,180.32Z"
        style={{
          fill: "rgb(211, 118, 106)",
          transformOrigin: "285.495px 191.507px",
        }}
        id="elbil418qcci"
        className="animable"
      />
      <path
        d="M293.18,173.66l24.93,10.49s-28.39,31.08-42.93,28.07c-3.35-.69-18.09-12.08-24.1-29.86-1.86-5.51-1.52-17,10.94-6.8s15.05,11.71,15.54,11.66S293.18,173.66,293.18,173.66Z"
        style={{
          fill: "rgb(166, 166, 166)",
          transformOrigin: "284.18px 191.954px",
        }}
        id="el68mgp9a8g3d"
        className="animable"
      />
      <path
        d="M313,187.83c-1.84-1.08-3.84-1.92-5.77-2.81s-3.81-1.75-5.72-2.61c-3.87-1.73-7.72-3.57-11.69-5a.08.08,0,0,0-.07.14c3.71,2.12,7.65,3.86,11.54,5.63q2.91,1.32,5.87,2.58a55.07,55.07,0,0,0,5.79,2.26C313,188,313.06,187.87,313,187.83Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "301.372px 182.714px",
        }}
        id="elfyjku4tgoth"
        className="animable"
      />
      <path
        d="M375.28,198.59l.35-1.42a4.91,4.91,0,0,0-3.61-5.92l-127.5-31-2.66,10.95,127.5,31A4.9,4.9,0,0,0,375.28,198.59Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "308.813px 181.294px",
        }}
        id="el9ex0mo2jvc"
        className="animable"
      />
      <g id="eli29c8rc3xkm">
        <path
          d="M375.28,198.59l.35-1.42a4.91,4.91,0,0,0-3.61-5.92l-127.5-31-2.66,10.95,127.5,31A4.9,4.9,0,0,0,375.28,198.59Z"
          style={{
            opacity: 0.2,
            transformOrigin: "308.813px 181.294px",
          }}
          className="animable"
        />
      </g>
      <g id="el34ytw6q8sb3">
        <rect
          x={245.27}
          y={159.69}
          width={11.27}
          height={15.89}
          style={{
            opacity: 0.1,
            transformOrigin: "250.905px 167.635px",
            transform: "rotate(-76.35deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M209.94,194.28a25.56,25.56,0,0,0,30.88-18.81l4.72-19.42A25.57,25.57,0,1,0,195.85,144l-4.72,19.43A25.56,25.56,0,0,0,209.94,194.28Zm1.87-7.69c-9-2.18-14.77-10.21-12.89-17.94,1.41-5.78,6.69-9.75,13-10.47-5.3-3.55-8.18-9.5-6.77-15.28,1.87-7.72,10.68-12.23,19.68-10.05s14.76,10.24,12.89,18c-1.4,5.78-6.68,9.74-13,10.47,5.31,3.55,8.18,9.5,6.78,15.28C229.62,184.29,220.8,188.78,211.81,186.59Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "218.398px 159.486px",
        }}
        id="eltpui2w7fbmh"
        className="animable"
      />
      <g id="el9lg2uls9ywo">
        <path
          d="M209.94,194.28a25.56,25.56,0,0,0,30.88-18.81l4.72-19.42A25.57,25.57,0,1,0,195.85,144l-4.72,19.43A25.56,25.56,0,0,0,209.94,194.28Zm1.87-7.69c-9-2.18-14.77-10.21-12.89-17.94,1.41-5.78,6.69-9.75,13-10.47-5.3-3.55-8.18-9.5-6.77-15.28,1.87-7.72,10.68-12.23,19.68-10.05s14.76,10.24,12.89,18c-1.4,5.78-6.68,9.74-13,10.47,5.31,3.55,8.18,9.5,6.78,15.28C229.62,184.29,220.8,188.78,211.81,186.59Z"
          style={{
            opacity: 0.2,
            transformOrigin: "218.398px 159.486px",
          }}
          className="animable"
        />
      </g>
      <g id="elklha1o0r47">
        <rect
          x={232.91}
          y={163.77}
          width={25.61}
          height={5.2}
          rx={2.26}
          style={{
            fill: "rgb(255, 199, 39)",
            transformOrigin: "245.715px 166.37px",
            transform: "rotate(103.65deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elt8hrvoj2r4">
        <rect
          x={232.91}
          y={163.77}
          width={25.61}
          height={5.2}
          rx={2.26}
          style={{
            opacity: 0.2,
            transformOrigin: "245.715px 166.37px",
            transform: "rotate(103.65deg)",
          }}
          className="animable"
        />
      </g>
      <g id="el39bgjni7ls8">
        <rect
          x={237.96}
          y={165}
          width={25.61}
          height={5.2}
          rx={2.26}
          style={{
            fill: "rgb(255, 199, 39)",
            transformOrigin: "250.765px 167.6px",
            transform: "rotate(103.65deg)",
          }}
          className="animable"
        />
      </g>
      <g id="elxwxcamib47">
        <rect
          x={237.96}
          y={165}
          width={25.61}
          height={5.2}
          rx={2.26}
          style={{
            opacity: 0.2,
            transformOrigin: "250.765px 167.6px",
            transform: "rotate(103.65deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="337.2 199.15 341.01 200.08 338.32 211.14 341.57 211.93 342.98 206.16 346.77 207.09 345.38 212.85 348.26 213.55 350.47 204.44 354.28 205.36 352.07 214.48 356.16 215.47 359.12 203.29 362.93 204.22 359.97 216.4 363.83 217.34 367.62 201.77 333.59 193.51 329.81 209.07 334.51 210.22 337.2 199.15"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "348.715px 205.425px",
        }}
        id="ell21jxyye0m"
        className="animable"
      />
      <g id="elx0eug3jwdnj">
        <polygon
          points="337.2 199.15 341.01 200.08 338.32 211.14 341.57 211.93 342.98 206.16 346.77 207.09 345.38 212.85 348.26 213.55 350.47 204.44 354.28 205.36 352.07 214.48 356.16 215.47 359.12 203.29 362.93 204.22 359.97 216.4 363.83 217.34 367.62 201.77 333.59 193.51 329.81 209.07 334.51 210.22 337.2 199.15"
          style={{
            opacity: 0.2,
            transformOrigin: "348.715px 205.425px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M288.76,167.69c3.93-1.19,9.15-4.89,12.2-4.8a3.51,3.51,0,0,1,3.38,2.47s1.94-2.76,4.7-2.22c1.41.27,2.22,2.78,2.22,2.78a4.17,4.17,0,0,1,4.07-1.52c1.93.51,2.45,2.95,2.45,2.95s2.55-1.33,4.35-.18c3.65,2.31,1.11,19.25-5.52,20.92-3.09.79-2.25-3.32-2.25-3.32s-2.64,5.61-6.14,5.17-2.08-5.7-2.08-5.7-2.61,5.41-6.2,4.59-1.41-5.87-1.41-5.87-3.12,3.38-5.72,2.13c-4.3-2.07,2.44-8.35,2.12-12.2l-5.83-3Z"
        style={{
          fill: "rgb(211, 118, 106)",
          transformOrigin: "306.281px 176.426px",
        }}
        id="eldt4rp1byfve"
        className="animable"
      />
      <path
        d="M294.6,165.1c-3.82.8-7.62-4.35-11.39-4.11-5.09.33-1.09,6.61,5.89,8.92l5.74.84Z"
        style={{
          fill: "rgb(211, 118, 106)",
          transformOrigin: "287.877px 165.866px",
        }}
        id="elsyo45k58ewr"
        className="animable"
      />
      <path
        d="M317.92,167.72c0-.06.08-.06.09,0A29.64,29.64,0,0,1,314.34,185c-.09.17-.4.08-.33-.12A60.87,60.87,0,0,0,317.92,167.72Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "316.069px 176.382px",
        }}
        id="elvb44toco7oh"
        className="animable"
      />
      <path
        d="M311.31,165.85c0-.07.11-.06.12,0a27.84,27.84,0,0,1-5.17,18.61c-.14.18-.45.2-.31,0C308.42,180.67,311,171.37,311.31,165.85Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "308.72px 175.202px",
        }}
        id="el1ijfqqbv15r"
        className="animable"
      />
      <path
        d="M298.11,183.26c3.75-5.3,5.42-11.27,6.26-17.65,0-.09.13-.06.14,0,.44,3.23-.52,7.24-1.53,10.3a17.14,17.14,0,0,1-4.69,7.47C298.2,183.47,298,183.37,298.11,183.26Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "301.349px 174.484px",
        }}
        id="el1golkyifx6k"
        className="animable"
      />
      <path
        d="M259.4,183.89c-2.64-.12-7.64-5.51-9.39-9.84a68.86,68.86,0,0,1,1.42-6.74c.46-2.16.92-4.57,1.18-6.8,0-.46,1.51.56,1.51.56l2.81,1,9.29,3.44a36.22,36.22,0,0,0-2.36,8.41,5.81,5.81,0,0,0,0,.93s0,.09,0,.15C263.94,176.82,263.52,184.09,259.4,183.89Z"
        style={{
          fill: "rgb(211, 118, 106)",
          transformOrigin: "258.115px 172.142px",
        }}
        id="elkieqgtvndfn"
        className="animable"
      />
      <path
        d="M263.85,174.89s0,.09,0,.15a9.32,9.32,0,0,1-1.45-.09c-9.41-1.31-9.19-13.44-9.19-13.44l3.71.6,9.29,3.44a36.22,36.22,0,0,0-2.36,8.41A5.81,5.81,0,0,0,263.85,174.89Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "259.71px 168.276px",
        }}
        id="eldz4jfetdz1s"
        className="animable"
      />
      <path
        d="M254.57,141.55c-2.66,4.5-3.49,19.16-.79,23.13,3.92,5.75,12,8.16,17.33,3.07,5.15-4.93,5.89-24.05,2.71-27.83C269.15,134.35,258.5,134.9,254.57,141.55Z"
        style={{
          fill: "rgb(211, 118, 106)",
          transformOrigin: "263.922px 153.324px",
        }}
        id="ellp401yfeew9"
        className="animable"
      />
      <path
        d="M267.1,153.17s-.07,0-.07.08c0,1-.14,2.13-1,2.44,0,0,0,.06,0,.05C267.09,155.61,267.28,154.1,267.1,153.17Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "266.595px 154.456px",
        }}
        id="elyxl6gbro7c"
        className="animable"
      />
      <path
        d="M266.24,152.12c-1.59-.15-1.77,3-.3,3.17S267.57,152.24,266.24,152.12Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "266.041px 153.706px",
        }}
        id="elta4szut3mh"
        className="animable"
      />
      <path
        d="M272.37,153.29s.07.05.07.09c-.09,1,0,2.13.85,2.51,0,0,0,.06,0,.05C272.2,155.73,272.13,154.21,272.37,153.29Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "272.773px 154.616px",
        }}
        id="eld7r0b56nwx6"
        className="animable"
      />
      <path
        d="M273.31,152.31c1.59,0,1.54,3.16.06,3.19S272,152.34,273.31,152.31Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "273.392px 153.905px",
        }}
        id="elp3pe7ysmi4"
        className="animable"
      />
      <path
        d="M265.06,150.59a13.07,13.07,0,0,0,1.26-.37,2.13,2.13,0,0,0,1.22-.61.66.66,0,0,0-.07-.82,1.67,1.67,0,0,0-1.67-.27,2.44,2.44,0,0,0-1.48,1A.72.72,0,0,0,265.06,150.59Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "265.937px 149.503px",
        }}
        id="elgc4i77z8dch"
        className="animable"
      />
      <path
        d="M274.49,151.13a13,13,0,0,1-1.25-.4,2.19,2.19,0,0,1-1.2-.65.68.68,0,0,1,.09-.82,1.66,1.66,0,0,1,1.68-.22,2.47,2.47,0,0,1,1.45,1A.72.72,0,0,1,274.49,151.13Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "273.658px 150.027px",
        }}
        id="el263uxg5wkbq"
        className="animable"
      />
      <path
        d="M265.08,162.27c.23.25.44.58.8.63a2.55,2.55,0,0,0,1.08-.19s.06,0,0,0a1.33,1.33,0,0,1-1.29.47,1.06,1.06,0,0,1-.73-.92C265,162.25,265.05,162.24,265.08,162.27Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "265.963px 162.727px",
        }}
        id="elklark5slsai"
        className="animable"
      />
      <path
        d="M266.29,159.38a3.38,3.38,0,0,0,2.16,1.62,4,4,0,0,0,1.2.12,1,1,0,0,0,.24,0l.21,0a.23.23,0,0,0,.21-.21h0a.17.17,0,0,0,0-.1h0v-.08c.07-.76.06-1.92.06-1.92.27.15,1.65.87,1.65.47A51.16,51.16,0,0,0,271.3,149a.1.1,0,0,0-.19,0c-.11,3.17.33,6.34.26,9.53a6,6,0,0,0-1.56-.58c-.11,0,.06,2.25,0,2.58a.1.1,0,0,1,0,0,4.65,4.65,0,0,1-3.41-1.28C266.32,159.23,266.23,159.29,266.29,159.38Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "269.157px 155.029px",
        }}
        id="el3lhcdchs7at"
        className="animable"
      />
      <path
        d="M269.23,160.71a4.06,4.06,0,0,1-1.67,1.15,1.76,1.76,0,0,1-1.09,0c-.78-.25-.76-1-.58-1.58a4.28,4.28,0,0,1,.41-.93A5.19,5.19,0,0,0,269.23,160.71Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "267.509px 160.648px",
        }}
        id="el7n5uk6zu0yo"
        className="animable"
      />
      <path
        d="M267.56,161.86a1.76,1.76,0,0,1-1.09,0c-.78-.25-.76-1-.58-1.58A1.85,1.85,0,0,1,267.56,161.86Z"
        style={{
          fill: "rgb(211, 118, 106)",
          transformOrigin: "266.674px 161.113px",
        }}
        id="elflr8zj7611l"
        className="animable"
      />
      <path
        d="M252.32,156.93c2.78-.75,4.32-10,4.32-10s-.08,3.95.66,3.86,3.85-5.4,4.24-9.23a30.31,30.31,0,0,0,7,3.52c3.49,1.1,7.38.92,7.39.32,0-1.78-1.53-6.44-3.92-8.12a12.56,12.56,0,0,0-12.46-1.71c-5.77,2.12-7.32,5.79-8.21,8.63S251.3,157.21,252.32,156.93Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "263.448px 145.786px",
        }}
        id="elblp8kn74f"
        className="animable"
      />
      <path
        d="M264,158.33a4.64,4.64,0,1,1,5.57-4.77h0a4.65,4.65,0,0,1-4.42,4.86A4.37,4.37,0,0,1,264,158.33Zm1.74-8.5a4.14,4.14,0,0,0-1-.07,4,4,0,1,0,1,.07Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "264.935px 153.786px",
        }}
        id="ele9ynjl1ipb"
        className="animable"
      />
      <path
        d="M274.62,159.18c-1.74-.36-3-2.46-2.75-4.88a5.53,5.53,0,0,1,1.25-3.15,2.9,2.9,0,0,1,4.94.42,5.62,5.62,0,0,1,.7,3.32c-.22,2.55-1.94,4.5-3.84,4.33A1.54,1.54,0,0,1,274.62,159.18Zm1.27-8.56a1,1,0,0,0-.24,0,2.47,2.47,0,0,0-2.06,1,5,5,0,0,0-1.11,2.8c-.18,2.21.93,4.12,2.49,4.25s3-1.55,3.17-3.76a5,5,0,0,0-.61-3A2.55,2.55,0,0,0,275.89,150.62Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "275.313px 154.595px",
        }}
        id="elsboypow3n2d"
        className="animable"
      />
      <path
        d="M269.36,154.08l-.36-.51c.76-.53,2.45-1.18,3.47.12l-.48.39C271.07,152.91,269.43,154,269.36,154.08Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "270.735px 153.505px",
        }}
        id="el5djnf489mzo"
        className="animable"
      />
      <path
        d="M248.63,153.51l-.52-.33c.15-.24,1.55-2.4,2.95-2.5s9.54,1.39,9.89,1.45l-.12.61c-2.35-.43-8.71-1.52-9.73-1.44S249,152.92,248.63,153.51Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "254.53px 152.093px",
        }}
        id="eldwkpm65hoak"
        className="animable"
      />
      <path
        d="M254.8,153.72s-2.18-5.27-4.5-4.58-1.38,7.82.85,9.26a2.53,2.53,0,0,0,3.6-.6Z"
        style={{
          fill: "rgb(211, 118, 106)",
          transformOrigin: "251.877px 153.99px",
        }}
        id="eli671esdnpgo"
        className="animable"
      />
      <path
        d="M250.62,151.18s-.06,0,0,.06c1.56,1.06,2.1,2.84,2.37,4.62a1.4,1.4,0,0,0-2-.83c-.05,0,0,.1,0,.11a1.54,1.54,0,0,1,1.55.89,7.43,7.43,0,0,1,.51,1.53c0,.17.33.15.32,0v-.05C253.92,155.32,252.9,151.91,250.62,151.18Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "252.055px 154.43px",
        }}
        id="elq9jbscvstp"
        className="animable"
      />
      <path
        d="M269.07,135.45a21.23,21.23,0,0,0-5.68-2.58c-2.25-.6-9.56-1-11.5.18s-3.32,8-3.5,9-.56,7.08,1.42,8.83,3.59-8.46,7.5-11.38S269.07,135.45,269.07,135.45Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "258.654px 141.685px",
        }}
        id="elezfq699b2"
        className="animable"
      />
      <g id="elwmsdgqvlulm">
        <path
          d="M269.07,135.45s-7.85,1.08-11.75,4-5.52,13.13-7.51,11.38a4.86,4.86,0,0,1-1.39-3.44c.87-2.93,2.71-7.41,5.65-10.28,2.74-2.69,7-3.67,11.34-3.57A24.93,24.93,0,0,1,269.07,135.45Z"
          style={{
            opacity: 0.1,
            transformOrigin: "258.745px 142.282px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M249.58,157c.57-2.7,4.34-9.63,7.82-13.42s12.37-5.06,13.56-4.83c2.88.57,3.2,2.11,3.7,1.91s-1.66-3.32-2.41-3.92-2.79-2.05-4.05-2.19-9.16-.44-12.44,2.5-8.56,11.68-8.15,14.17c.1.59.81,1.06.94,1.91.17,1.1.22,2.36.44,3.15C249.17,156.92,249.53,157.23,249.58,157Z"
        style={{
          fill: "rgb(255, 199, 39)",
          transformOrigin: "261.161px 145.772px",
        }}
        id="elgx2koy8kl5"
        className="animable"
      />
      <path
        d="M258.33,141.8a13.26,13.26,0,0,1-1.7-1.65,9.77,9.77,0,0,1-1.38-1.94c0-.05-.11,0-.08,0a7.4,7.4,0,0,0,1.2,2.14,6.65,6.65,0,0,0,1.87,1.55C258.32,142,258.39,141.85,258.33,141.8Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "256.759px 140.061px",
        }}
        id="elacy9ivpvq0c"
        className="animable"
      />
      <path
        d="M249.92,154.27a6.13,6.13,0,0,0-.22-.6,3.47,3.47,0,0,1-.16-.71,6.3,6.3,0,0,0-.13-1.37,4.93,4.93,0,0,0-.52-1,1.83,1.83,0,0,1-.38-1.18c0-.06-.08-.08-.09,0a4.72,4.72,0,0,0,.62,2.24,7.11,7.11,0,0,1,.18,1.46,2.16,2.16,0,0,0,.57,1.24C249.84,154.38,249.94,154.34,249.92,154.27Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "249.171px 151.859px",
        }}
        id="elpuimva2jlvd"
        className="animable"
      />
      <path
        d="M251.27,151.45a11,11,0,0,1-.64-1,8.75,8.75,0,0,1-.45-1.1c-.12-.37-.22-.75-.34-1.12s-.33-.87-.44-1.31c0-.06-.1,0-.09,0,.12.46.16.94.27,1.41a8.6,8.6,0,0,0,.37,1.19,6.17,6.17,0,0,0,.51,1.07,2.87,2.87,0,0,0,.77.89S251.28,151.48,251.27,151.45Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "250.29px 149.187px",
        }}
        id="elu065r0p89rd"
        className="animable"
      />
      <path
        d="M252.62,148.92a7.89,7.89,0,0,1-1.25-1.88c-.31-.67-.59-1.36-.85-2,0-.05-.1,0-.09,0a8.86,8.86,0,0,0,.66,2.2,4.86,4.86,0,0,0,.57,1,5.26,5.26,0,0,0,.89.81S252.66,149,252.62,148.92Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "251.529px 147.034px",
        }}
        id="elaw6c3bhy2z"
        className="animable"
      />
      <path
        d="M254.05,146.51a2.7,2.7,0,0,1-.62-.83c-.19-.33-.38-.68-.56-1s-.34-.66-.51-1a2.6,2.6,0,0,1-.35-1.07c0-.05-.09-.06-.09,0a2.77,2.77,0,0,0,.16,1.11,6.91,6.91,0,0,0,.48,1.16,9.41,9.41,0,0,0,.63,1.05,1.75,1.75,0,0,0,.8.72A.06.06,0,0,0,254.05,146.51Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "253.005px 144.612px",
        }}
        id="elvslcza7ih4j"
        className="animable"
      />
      <path
        d="M255.28,143.38c-.22-.28-.44-.56-.64-.86s-.37-.61-.55-.92-.38-.65-.54-1c0-.06-.1,0-.09,0a7.82,7.82,0,0,0,.37,1.06,8.3,8.3,0,0,0,.54,1,9.57,9.57,0,0,0,.64.89,2.85,2.85,0,0,0,.81.74s.11,0,.08-.08A5.13,5.13,0,0,0,255.28,143.38Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "254.682px 142.432px",
        }}
        id="elwjrncbaabsn"
        className="animable"
      />
      <path
        d="M260.63,140.53a12.43,12.43,0,0,0-.7-1.08,10.44,10.44,0,0,0-.85-1,12.1,12.1,0,0,0-1.92-1.72s-.1,0-.06.07c1.13,1.28,2.33,2.51,3.46,3.81C260.59,140.63,260.66,140.58,260.63,140.53Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "258.864px 138.672px",
        }}
        id="elt2ehqqe2ln"
        className="animable"
      />
      <path
        d="M262.6,138.42a5.54,5.54,0,0,0-.81-1,11.83,11.83,0,0,0-2.16-1.62c-.05,0-.1,0-.05.07.64.56,1.29,1.12,1.9,1.72.3.3.6.61.87.94s.45.71.71,1.05a0,0,0,0,0,.06,0A3.42,3.42,0,0,0,262.6,138.42Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "261.34px 137.69px",
        }}
        id="el5n1qlpe3ato"
        className="animable"
      />
      <path
        d="M265,137.66a5.94,5.94,0,0,0-.74-1,6.74,6.74,0,0,0-1.83-1.44,0,0,0,0,0-.05.07,13.64,13.64,0,0,1,1.69,1.62c.26.3.51.61.75.91s.44.67.72.95c0,0,.05,0,.05,0A6.67,6.67,0,0,0,265,137.66Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "263.985px 136.995px",
        }}
        id="elqvyoki6x939"
        className="animable"
      />
      <path
        d="M266.84,136.53c-.25-.27-.52-.53-.79-.79a3.7,3.7,0,0,0-.87-.66c-.05,0-.09,0-.05.08.5.52,1,1.05,1.47,1.58a9.2,9.2,0,0,1,1.07,1.68s.06,0,.05,0A3.43,3.43,0,0,0,266.84,136.53Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "266.417px 136.75px",
        }}
        id="el9fhqt8kq0pq"
        className="animable"
      />
      <path
        d="M269,136.37a5.83,5.83,0,0,0-1.66-1.39c-.05,0-.09.05-.05.08a6.88,6.88,0,0,1,1.48,1.47,10,10,0,0,1,1.09,1.8s.07,0,.06,0A4.56,4.56,0,0,0,269,136.37Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "268.597px 136.655px",
        }}
        id="elm4zv2g4nz38"
        className="animable"
      />
      <path
        d="M271.47,138a3,3,0,0,0-.18-.45,5.44,5.44,0,0,0-.51-.83,9.61,9.61,0,0,0-1.38-1.4s-.1,0-.06.07a7.83,7.83,0,0,1,1.28,1.42,6.71,6.71,0,0,1,.49.81c.07.14.13.28.19.42a2.17,2.17,0,0,0,.17.42s.08,0,.08,0A1.32,1.32,0,0,0,271.47,138Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "270.44px 136.89px",
        }}
        id="el7unkqwgo9jf"
        className="animable"
      />
      <path
        d="M273.09,138.33a7.42,7.42,0,0,0-.91-1.31c-.18-.2-.37-.39-.55-.6,0,0-.11,0-.06.06a5.34,5.34,0,0,1,.5.65l.43.64.43.64a5,5,0,0,0,.43.66s.07,0,.06,0A3.06,3.06,0,0,0,273.09,138.33Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "272.489px 137.745px",
        }}
        id="elqcrgg3ov26p"
        className="animable"
      />
      <path
        d="M213.8,249c.13.18,62.38,5.27,63,4.67.23-.22,2.19-4.83,1.49-11.84-1.94-19.19-9.33-55.92-16-66.35-2.27-3.56-11-6.44-14.86-3.21-8.06,6.67-24.28,42.62-31.43,62.86C213.18,243.26,213.67,248.85,213.8,249Z"
        style={{
          fill: "rgb(166, 166, 166)",
          transformOrigin: "246.062px 212.292px",
        }}
        id="elpgyisfr7ln"
        className="animable"
      />
      <path
        d="M254.91,183.2c3.09,8.15,18.66,38.43,30,40.47,13.74,2.47,31.57.67,39.52-2.51,4.46-1.78-5.18-23.65-9.39-22.55-6.63,1.73-19.3,1.9-21.82,1.9S274.34,185,265.13,177C256.84,169.82,253.09,178.4,254.91,183.2Z"
        style={{
          fill: "rgb(211, 118, 106)",
          transformOrigin: "290.021px 199.535px",
        }}
        id="elbru4eo82o4h"
        className="animable"
      />
      <path
        d="M320.13,222.21c12.68-2.68,23.5-12.17,25-14.16,1.26-1.7,1.89-2.58,1.89-2.58,2.32-3,1.91-5.21,1.91-5.21s3.7-2.68,2.45-5.18c-1.39-2.81-6.31,1.5-10.47,2.21-9.53,1.64-23.53,1.3-26.32,1.5Z"
        style={{
          fill: "rgb(211, 118, 106)",
          transformOrigin: "333.114px 208.174px",
        }}
        id="eloq2bw1mj1sb"
        className="animable"
      />
      <path
        d="M332.1,206a63.33,63.33,0,0,0,8.26-1.9,63.08,63.08,0,0,0,8.43-3.89.08.08,0,0,1,.08.13,33,33,0,0,1-16.73,5.91C332,206.27,332,206.05,332.1,206Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "340.459px 203.229px",
        }}
        id="el1jf2sspl8k9"
        className="animable"
      />
      <path
        d="M332.25,211.91c2.69-.67,7.41-1.88,14.69-6.52,0,0,.06,0,0,0a29.56,29.56,0,0,1-14.65,6.75C332.16,212.21,332.07,212,332.25,211.91Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "339.558px 208.772px",
        }}
        id="el5zfptlcjjop"
        className="animable"
      />
      <path
        d="M329.27,198.54c-1.32-.28-1.18-1,1-2s11.61-3.46,10.49-6.68-14.39.34-17.84,2.4a63.72,63.72,0,0,0-8.33,6.58C317.28,198.61,323.93,198.81,329.27,198.54Z"
        style={{
          fill: "rgb(211, 118, 106)",
          transformOrigin: "327.721px 193.704px",
        }}
        id="eluokfkn2d2i"
        className="animable"
      />
      <path
        d="M309.78,198.32l7.51,26.76s-25,3.37-34.56-1.05-21.2-24.75-26.54-36.53-1.4-21.06,13.55-7.27,22.26,19.2,23.35,19.51S309.78,198.32,309.78,198.32Z"
        style={{
          fill: "rgb(166, 166, 166)",
          transformOrigin: "285.608px 199.827px",
        }}
        id="elh07kzputcfn"
        className="animable"
      />
      <path
        d="M272.53,214.87c-.72-1.19-6.75-10-7.32-11-1.17-1.93-2.33-3.86-3.46-5.81-2.29-3.93-4.5-7.92-7-11.7a0,0,0,0,0-.07.05c1.16,2.67,2.49,5.28,3.91,7.84l-.34-.35a39,39,0,0,1-2.89-3c-.06-.07-.17,0-.11.11.91,1,1.72,2.15,2.6,3.2.42.52.85,1,1.31,1.5l.57.56c.45.78.91,1.56,1.38,2.33,1.18,2,2.48,3.86,3.71,5.8,2.16,3.41,7,9.64,7.63,10.55C272.48,215.06,272.59,215,272.53,214.87Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "263.614px 200.684px",
        }}
        id="el7jvn4qgbnel"
        className="animable"
      />
      <path
        d="M311.7,224.69c-.33-2.1-1-4.17-1.51-6.23s-1.09-4.05-1.65-6.07c-1.14-4.08-2.19-8.22-3.59-12.21a.08.08,0,1,0-.15,0c.74,4.21,1.91,8.35,3,12.47.57,2.07,1.16,4.12,1.77,6.17a58.19,58.19,0,0,0,1.95,5.9C311.59,224.84,311.72,224.78,311.7,224.69Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "308.249px 212.43px",
        }}
        id="elriwec6c6gua"
        className="animable"
      />
      <path
        d="M302.47,198.92c-2.47.07-4.92.41-7.39.47a5.06,5.06,0,0,1-3.09-.87c-1-.68-11.73-8.87-13.57-10.48-.07-.07-.18.05-.11.11,1.94,1.61,8.85,7.64,10.85,9.26a22.69,22.69,0,0,0,2.78,2.09,4.64,4.64,0,0,0,3.05.57c2.5-.3,5-.54,7.51-.95C302.61,199.1,302.58,198.92,302.47,198.92Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "290.428px 194.073px",
        }}
        id="elvfczkon4rni"
        className="animable"
      />
      <path
        d="M259.81,173.18c-4.78-3-18.29-5-24-1.65-7.2,4.2-15.24,30.66-12.52,32.42,4.85,3.15,23.23-11.36,27.42-17.53S259.81,173.18,259.81,173.18Z"
        style={{
          fill: "rgb(166, 166, 166)",
          transformOrigin: "241.279px 187.09px",
        }}
        id="elbkkodoe97hq"
        className="animable"
      />
      <path
        d="M255.79,181c-.94.87-1.92,1.73-2.85,2.62,1.28-1.79,2.58-3.57,3.81-5.39,0-.06-.05-.14-.1-.08a70.21,70.21,0,0,0-4.69,5.74c-1.55,2-3.12,4-4.78,5.86a50.62,50.62,0,0,1-5.29,5.37,55.3,55.3,0,0,1-6.46,4.61q-1.91,1.18-3.86,2.29a31.1,31.1,0,0,1-4.36,2c-.27.09-.1.19,0,.16a21.62,21.62,0,0,0,3.71-1.49c1.24-.61,2.49-1.22,3.72-1.86a47.26,47.26,0,0,0,6.78-4.11c4.16-3.11,7.31-7.16,10.27-11.38a2.2,2.2,0,0,0,.17-.25c.56-.53,1.11-1.08,1.64-1.65.77-.82,1.57-1.59,2.4-2.35A.08.08,0,0,0,255.79,181Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "241.907px 191.157px",
        }}
        id="el80teb8nyqo"
        className="animable"
      />
    </g>
    <defs>
      <filter id="active" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter id="hover" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#ff0000" floodOpacity={0.5} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
        <feColorMatrix
          type="matrix"
          values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
        />
      </filter>
    </defs>
  </svg>
);
export default SvgComponent;

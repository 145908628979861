import React from "react";
import FooterBannerPoster from "../Images/Postar/Postart Nxt skills.jpg";
import FooterBannerPoster1 from "../Images/Postar/2.jpg";
import FooterBannerPoster2 from "../Images/Postar/3.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "./FooterBanner.css";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper/modules";

const FooterBanner = () => {
  return (
    <>
      <div className="swiper-footer-banner">
        <Swiper
          className="main-swiper-footer"
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: false,
          }}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
        >
          <SwiperSlide className="footer-banner-image">
            <img src={FooterBannerPoster} alt="nxtskills laptop offers " />
          </SwiperSlide>
          <SwiperSlide className="footer-banner-image">
            <img src={FooterBannerPoster1} alt="nxtskills laptop offers" />
          </SwiperSlide>
          <SwiperSlide className="footer-banner-image">
            <img src={FooterBannerPoster2} alt="nxtskills laptop offers" />
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default FooterBanner;

import React from "react";
import SubPageHead from "../Components/SubPageHead";

const Careers = () => {
  return (
    <>
      <div className="careers">
        <SubPageHead Header="Careers" SubHeader="Coming Soon......" />
      </div>
      <div className="coming-soon container" style={{ margin: "5rem auto" }}>
        <h3>COMING SOON......</h3>
      </div>
    </>
  );
};

export default Careers;

import React from "react";
import Logo from "../Images/Navbar/Logo.svg";
import Facebook from "../Images/Navbar/Social/Face.svg";
import LinkedIn from "../Images/Navbar/Social/linkedin.svg";
import Google from "../Images/Navbar/Social/google.svg";
import { NavLink, Link } from "react-router-dom";
// import NxtPoster from "../Images/Postar/Postart Nxt skills.png";
import FooterBanner from "./FooterBanner";
// import NxtPoster1 from "../Images/Postar/poster img 2.jpg";

const Footer = () => {
  const Social = [
    {
      id: 1,
      name: "Facebook",
      link: "https://www.facebook.com/Nxtskillsitsolutions/",
      icon: Facebook,
      className: "facebook",
    },
    // {
    //   name: "Instagram",
    //   link: "https://www.facebook.com/nxtskills",
    //   icon: Insta,
    //   className: "instagram",
    // },
    // {
    //   name: "Twitter",
    //   link: "https://www.facebook.com/nxtskills",
    //   icon: Twitter,
    //   className: "twitter",
    // },
    {
      id: 2,
      name: "LinkedIn",
      link: "https://www.linkedin.com/company/nxtskills/",
      icon: LinkedIn,
      className: "LinkedIn",
    },
    {
      id: 3,
      name: "Google",
      link: "https://g.co/kgs/mgSRT4",
      icon: Google,
      className: "google",
    },
  ];
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="footer-main-grid">
            <div className="footer-main-grid-1">
              <Link to="/">
                <img src={Logo} alt="NXTSkills Logo" />
              </Link>
              <p>
                Nxtskills is on a mission to set the gold standard for IT
                excellence and security across industries. We're dedicated to
                providing comprehensive support, helping companies centralize
                their IT infrastructure, and ensuring they adhere to the highest
                standards of IT compliance.
              </p>
              <div className="footer-social-icon">
                {Social.map((corr, id) => {
                  const { name, link, icon, className } = corr;
                  return (
                    <>
                      <Link
                        target="_blank"
                        to={link}
                        className={className}
                        key={id}
                      >
                        <button className="Btn">
                          <span className="svgContainer">
                            <img src={icon} alt={name} />
                          </span>
                          <span className="BG"></span>
                        </button>
                      </Link>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="footer-main-grid-2">
              <div className="footer-main-grid-quick-links flex">
                <h4>QUICK LINKS</h4>
                <NavLink to="/"> Home</NavLink>
                <NavLink to="/About-us"> About Us</NavLink>
                <NavLink to="/Products">Products</NavLink>
                <NavLink to="/Services"> Services</NavLink>
                <NavLink to="https://blog.nxtskills.com"> Blog</NavLink>
                <NavLink to="/Gallery"> Gallery</NavLink>
                <NavLink to="/Contact-us"> Contact Us</NavLink>
              </div>
            </div>
            <div className="footer-main-grid-3">
              <div className="footer-main-grid-other-links footer-main-grid-quick-links flex">
                <h4>OTHER LINKS</h4>
                <Link to="/"> Social Work</Link>
                <Link to="/"> Privacy Policy</Link>
                <Link to="/"> Terms & Condition </Link>
                <Link to="/"> Case Studies</Link>
                <NavLink to="/Careers"> Careers</NavLink>
                {/* <Link to="/"> Project’s</Link> */}
              </div>
            </div>
            <div className="footer-main-grid-4">
              <div className="footer-main-grid-address">
                <h4>ADDRESS</h4>
                <p>
                  <span>NXT SKILLS IT SOLUTIONS</span> <br />{" "}
                  <Link
                    target="_blank"
                    to="https://maps.app.goo.gl/VvrvWJ7ZigVrG38A8"
                  >
                    No.1613, 3rd Floor, 2nd Phase, I Stage Chandra Layout
                    Bangalore Karnataka 560040
                  </Link>
                </p>
                <div className="footer-main-grid-address-email">
                  <p>
                    Email:{" "}
                    <Link to="mailto:info@nxtskills.com">
                      info@nxtskills.com
                    </Link>
                  </p>
                  <p>
                    Ph.No:
                    <Link to="tel:+919008353635">+91 9008353635</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container"> */}
        <div className="footer-poster">
          {/* <Link to="/Contact-us">
            <img src={NxtPoster} alt="nxtskills offer poster " />
          </Link> */}
          <FooterBanner />
          {/* </div> */}
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="footer-bottom-link">
              <p>Copyright © 2023</p>
              <p>Design And Developed by VPAtomic</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

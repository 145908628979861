import React from "react";
import SubPageHead from "../Components/SubPageHead";
import { Link } from "react-router-dom";

const Brands = () => {
  return (
    <>
      <div className="brands">
        <div className="brands-header">
          <SubPageHead Header="Product Brands" SubHeader="" />
        </div>
        <div className="container">
          <div className="brand-grid">
            <div className="same-brand-grid brand-grid-sub-1">
              <h3>FAREWELL</h3>
              <ul>
                <Link to="/">
                  <li>Palo Alto</li>
                </Link>
                <Link to="/">
                  <li>Fortinet</li>
                </Link>
                <Link to="/">
                  <li>Sophos</li>
                </Link>
                <Link to="/">
                  <li>Cisco</li>
                </Link>
                <Link to="/">
                  <li>Watch Guard</li>
                </Link>
                <Link to="/">
                  <li>PF Sense</li>
                </Link>
                <Link to="/">
                  <li>ZTNA</li>
                </Link>
              </ul>
            </div>
            <div className="same-brand-grid brand-grid-sub-2">
              <h3>SWITCHES</h3>
              <ul>
                <Link to="/">
                  <li>Cisco</li>
                </Link>
                <Link to="/">
                  <li>HPE - Aruba</li>
                </Link>
                <Link to="/">
                  <li>Juniper</li>
                </Link>
                <Link to="/">
                  <li>Dlink</li>
                </Link>
                <Link to="/">
                  <li>TP Link</li>
                </Link>
                <Link to="/">
                  <li>Ruckus</li>
                </Link>
                <Link to="/">
                  <li>Cambium Networks</li>
                </Link>
                <Link to="/">
                  <li>ARRAY Networks</li>
                </Link>
              </ul>
            </div>
            <div className="same-brand-grid brand-grid-sub-3">
              <h3>Wifi Access Points</h3>
              <ul>
                <Link to="/">
                  <li>Aruba</li>
                </Link>
                <Link to="/">
                  <li>Cisco</li>
                </Link>
                <Link to="/">
                  <li>Sophos</li>
                </Link>
                <Link to="/">
                  <li>Ruckus</li>
                </Link>
                <Link to="/">
                  <li>Ubiquitti</li>
                </Link>
                <Link to="/">
                  <li>Quantum</li>
                </Link>
              </ul>
            </div>
            <div className="same-brand-grid brand-grid-sub-4">
              <h3>Server, Storage and power</h3>
              <ul>
                <Link to="/">
                  <li>Qnap</li>
                </Link>
                <Link to="/">
                  <li>Synology</li>
                </Link>
                <Link to="/">
                  <li>Dell</li>
                </Link>
                <Link to="/">
                  <li>Netapp</li>
                </Link>
                <Link to="/">
                  <li>APC</li>
                </Link>
                <Link to="/">
                  <li>Aruba</li>
                </Link>
                <Link to="/">
                  <li>DDN Storage</li>
                </Link>
                <Link to="/">
                  <li>EATON</li>
                </Link>
                <Link to="/">
                  <li>HP</li>
                </Link>
                <Link to="/">
                  <li>Hitachi</li>
                </Link>
                <Link to="/">
                  <li>Huawei</li>
                </Link>
                <Link to="/">
                  <li>Lenovo</li>
                </Link>
                <Link to="/">
                  <li>Luminous</li>
                </Link>
                <Link to="/">
                  <li>Nutani X</li>
                </Link>
                <Link to="/">
                  <li>Pure storage</li>
                </Link>
              </ul>
            </div>
            <div className="same-brand-grid brand-grid-sub-5">
              <h3>securities</h3>
              <ul>
                <Link to="/">
                  <li>CrowdStrike</li>
                </Link>
                <Link to="/">
                  <li>Sophos</li>
                </Link>
                <Link to="/">
                  <li>Mcafee - Trilx</li>
                </Link>
                <Link to="/">
                  <li>Trend</li>
                </Link>
                <Link to="/">
                  <li>Sentinel One</li>
                </Link>
                <Link to="/">
                  <li>Palo Alto</li>
                </Link>
                <Link to="/">
                  <li>VPN solutions</li>
                </Link>
                <Link to="/">
                  <li>Archer</li>
                </Link>
                <Link to="/">
                  <li>Arcserve</li>
                </Link>
                <Link to="/">
                  <li>Barracuda</li>
                </Link>
                <Link to="/">
                  <li>Check point</li>
                </Link>
                <Link to="/">
                  <li>Everest IMS</li>
                </Link>
                <Link to="/">
                  <li>Fortinet</li>
                </Link>
                <Link to="/">
                  <li>Gajshield</li>
                </Link>
                <Link to="/">
                  <li>Infoblox</li>
                </Link>
                <Link to="/">
                  <li>Netwitness</li>
                </Link>
                <Link to="/">
                  <li>Outseer</li>
                </Link>
                <Link to="/">
                  <li>Quest</li>
                </Link>
                <Link to="/">
                  <li>RSA</li>
                </Link>
                <Link to="/">
                  <li>Skyhigh Security</li>
                </Link>
                <Link to="/">
                  <li>Sonic Wall</li>
                </Link>
                <Link to="/">
                  <li>Trellix</li>
                </Link>
                <Link to="/">
                  <li>Trend Micro</li>
                </Link>
                <Link to="/">
                  <li>Vicarius</li>
                </Link>
              </ul>
            </div>
            <div className="same-brand-grid brand-grid-sub-6">
              <h3>DLP</h3>
              <ul>
                <Link to="/">
                  <li>Trellix</li>
                </Link>
                <Link to="/">
                  <li>Symantec</li>
                </Link>
                <Link to="/">
                  <li>Manage Engine</li>
                </Link>
                <Link to="/">
                  <li>Sophos</li>
                </Link>
                <Link to="/">
                  <li>Endpoint Protector</li>
                </Link>
                <Link to="/">
                  <li>Teramind</li>
                </Link>
              </ul>
            </div>
            <div className="same-brand-grid brand-grid-sub-8">
              <h3>Device Management</h3>
              <ul>
                <Link to="/">
                  <li>Jamf</li>
                </Link>
                <Link to="/">
                  <li>Manage Engine</li>
                </Link>
                <Link to="/">
                  <li>Sure MDM</li>
                </Link>
                <Link to="/">
                  <li>Microsoft Intune</li>
                </Link>
              </ul>
            </div>
            <div className="same-brand-grid brand-grid-sub-9">
              <h3>Fiber and Cables</h3>
              <ul>
                <Link to="/">
                  <li>Cisco</li>
                </Link>
                <Link to="/">
                  <li>AMP</li>
                </Link>
                <Link to="/">
                  <li>Dlink</li>
                </Link>
                <Link to="/">
                  <li>Belden</li>
                </Link>
                <Link to="/">
                  <li>Finolex</li>
                </Link>
              </ul>
            </div>
            <div className="same-brand-grid brand-grid-sub-10">
              <h3>CCTV & Biometric</h3>
              <ul>
                <Link to="/">
                  <li>Hikvision</li>
                </Link>
                <Link to="/">
                  <li>CP Plus</li>
                </Link>
                <Link to="/">
                  <li>Honeywell</li>
                </Link>
                <Link to="/">
                  <li>DAHUA</li>
                </Link>
                <Link to="/">
                  <li>Godrej</li>
                </Link>
                <Link to="/">
                  <li>ESSL</li>
                </Link>
                <Link to="/">
                  <li>Secure Eye</li>
                </Link>
              </ul>
            </div>
            <div className="same-brand-grid brand-grid-sub-11">
              <h3>Email Solution</h3>
              <ul>
                <Link to="/">
                  <li>Gsuite</li>
                </Link>
                <Link to="/">
                  <li>Microsoft</li>
                </Link>
                <Link to="/">
                  <li>Zoho</li>
                </Link>
              </ul>
            </div>
            <div className="same-brand-grid brand-grid-sub-12">
              <h3>Laptops and DesktopsS</h3>
              <ul>
                <Link to="/">
                  <li>Lenovo</li>
                </Link>
                <Link to="/">
                  <li>Dell</li>
                </Link>
                <Link to="/">
                  <li>HP</li>
                </Link>
                <Link to="/">
                  <li>Apple</li>
                </Link>
                <Link to="/">
                  <li>ASUS</li>
                </Link>
              </ul>
            </div>
            <div className="same-brand-grid brand-grid-sub-12">
              <h3>Enterprise softwares</h3>
              <ul>
                <Link to="/">
                  <li>Esri</li>
                </Link>
                <Link to="/">
                  <li>IBM</li>
                </Link>
                <Link to="/">
                  <li>Micro Focus</li>
                </Link>
                <Link to="/">
                  <li>Oracle</li>
                </Link>
                <Link to="/">
                  <li>PTC</li>
                </Link>
                <Link to="/">
                  <li>Rubrik</li>
                </Link>
                <Link to="/">
                  <li>Red Hat</li>
                </Link>
                <Link to="/">
                  <li>SAS</li>
                </Link>
                <Link to="/">
                  <li>Splunk</li>
                </Link>
                <Link to="/">
                  <li>SUSE</li>
                </Link>
                <Link to="/">
                  <li>Veeam</li>
                </Link>
                <Link to="/">
                  <li>Veritas</li>
                </Link>
              </ul>
            </div>
            <div className="same-brand-grid brand-grid-sub-12">
              <h3>Licences & Subscriptions</h3>
              <ul>
                <Link to="/">
                  <li>Autodesk</li>
                </Link>
                <Link to="/">
                  <li>Freshworks</li>
                </Link>
                <Link to="/">
                  <li>Gitlab</li>
                </Link>
                <Link to="/">
                  <li>Microsoft 365</li>
                </Link>
                <Link to="/">
                  <li>Onshape</li>
                </Link>
                <Link to="/">
                  <li>SalesFroce</li>
                </Link>
                <Link to="/">
                  <li>SAS</li>
                </Link>
              </ul>
            </div>
            <div className="same-brand-grid brand-grid-sub-12">
              <h3>Cloud</h3>
              <ul>
                <Link to="/">
                  <li>AWS</li>
                </Link>
                <Link to="/">
                  <li>Azure</li>
                </Link>
                <Link to="/">
                  <li>Google Cloud</li>
                </Link>
                <Link to="/">
                  <li>IBM cloud</li>
                </Link>
                <Link to="/">
                  <li>Oracle Cloud</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Brands;

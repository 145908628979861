import React from "react";
import ProductData from "./ProductData";
import { useParams } from "react-router-dom";
import SubPageHead from "../Components/SubPageHead";
// import ImageScroll from "../Components/ImageScroll";

const SingleProduct = () => {
  const { productId } = useParams();
  const products = ProductData.find((product) => product.id === productId);
  const { productname, productclassname, description, img, logoimg } = products;
  return (
    <>
      <div className={`single-product ${productclassname}`}>
        <div className="single-product-heading">
          <SubPageHead
            Header="Products"
            SubHeader={productname}
            HeaderClass="single-product-header"
          />
        </div>
        <div className="container">
          <div className="single-product-detail">
            <div className="single-product-detail-sub">
              <h3>{productname}</h3>
              <p>{description}</p>
            </div>
            <div className="single-product-detail-img">
              {/* <img src={img} alt={productname} /> */}
              {img}
            </div>
          </div>
          <div className="single-products-brands">
            <img src={logoimg} alt={productname} />
            {/* <ImageScroll
              ImageScrollImage={logoimg}
              ImgAlt={productname}
              ImgclassName="single-products-brands-image-single"
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleProduct;
